import { LookUp, UUID } from "../common.public-types";

export const generateLookUp = <T extends object>(
  entities: T[],
  idGetter: (entity: T) => string,
) => {
  return entities.reduce((acc, curr) => {
    acc[idGetter(curr)] = curr;
    return acc;
  }, {} as LookUp<T>);
};

export const generateEntityLookUp = <T extends { id: UUID }>(entities: T[]) => {
  return entities.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as LookUp<T>);
};
