import { Box, Collapse, Link, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SIDE_BAR_TRANSITION_DURATION } from "../components/side-bar.component";

export interface ISideBarNavigationItem {
  id: string;
  name: string;
  path: string;
  Icon: FC;
}

export interface ISideBarNavigationItemTopLevel extends ISideBarNavigationItem {
  children?: ISideBarNavigationItem[];
}

export interface INavigationItemsSidebarSectionProps {
  activePath: string;
  items: ISideBarNavigationItemTopLevel[];
  expanded: boolean;
}

/**
 * This component renders a list of navigation items in the side bar.
 * The NavigationItem Icons are rendered using the Iconify library.
 * (Therefore you can use any icon from https://iconify.design/ by providing the icon name as a string)
 */
export const NavigationItemsSideBarSection: FC<INavigationItemsSidebarSectionProps> = ({
  activePath,
  items,
  expanded,
}) => {
  const bestMatchItem = useMemo(
    () =>
      items
        .flatMap((item) => [item, ...(expanded ? item.children ?? [] : [])])
        .filter((item) => activePath.startsWith(item.path))
        .reduce(
          (prev, curr) => (prev === null || curr.path.length > prev.path.length ? curr : prev),
          null as ISideBarNavigationItem | null,
        ),
    [activePath, items, expanded],
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {items.map((topLevelItem) => (
        <Box key={topLevelItem.id}>
          <Link to={topLevelItem.path} underline="none" color="inherit" component={RouterLink}>
            <Box
              key={topLevelItem.id}
              display="flex"
              alignItems="center"
              borderRadius={2}
              p={1}
              pl={1.5}
              sx={{
                backgroundColor: bestMatchItem?.id === topLevelItem.id ? "primary.main" : undefined,
                transition: "background-color 200ms",
                transitionTimingFunction: "ease-in-out",
                ":hover": {
                  transition: "background-color 200ms",
                  transitionTimingFunction: "ease-in-out",
                  backgroundColor: "primary.main",
                },
              }}
            >
              <SvgIcon
                sx={{
                  height: "2rem",
                }}
              >
                <topLevelItem.Icon />
              </SvgIcon>
              <Typography
                variant="body1"
                color="inherit"
                overflow="hidden"
                whiteSpace="nowrap"
                width={`${expanded ? 180 : 0}px`}
                sx={{
                  opacity: expanded ? 1 : 0,
                  transition: `width ${SIDE_BAR_TRANSITION_DURATION}ms, opacity ${SIDE_BAR_TRANSITION_DURATION}ms`,
                }}
                pl={1}
              >
                {topLevelItem.name}
              </Typography>
            </Box>
          </Link>
          {!!topLevelItem.children?.length && (
            <Collapse in={expanded}>
              <Box
                display="flex"
                flexDirection="column"
                pl={2}
                pt={0.5}
                width={240}
                sx={{
                  opacity: expanded ? 1 : 0,
                  transition: `opacity ${SIDE_BAR_TRANSITION_DURATION}ms`,
                }}
                gap={0.5}
              >
                {topLevelItem.children?.map((childItem) => (
                  <Link
                    key={childItem.id}
                    to={childItem.path}
                    underline="none"
                    color="inherit"
                    component={RouterLink}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      borderRadius={2}
                      p={1}
                      pl={2}
                      sx={{
                        backgroundColor:
                          bestMatchItem?.id === childItem.id ? "primary.main" : undefined,
                        transition: "background-color 200ms",
                        transitionTimingFunction: "ease-in-out",
                        ":hover": {
                          transition: "background-color 200ms",
                          transitionTimingFunction: "ease-in-out",
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      <SvgIcon
                        sx={{
                          height: "1.75rem",
                        }}
                      >
                        <childItem.Icon />
                      </SvgIcon>
                      <Typography variant="body2" color="inherit" whiteSpace="nowrap">
                        {childItem.name}
                      </Typography>
                    </Box>
                  </Link>
                ))}
              </Box>
            </Collapse>
          )}
        </Box>
      ))}
    </Box>
  );
};
