/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IAddFinancialEffect400Response } from '../models';
// @ts-ignore
import { IAddFinancialEffect404Response } from '../models';
// @ts-ignore
import { IAddFinancialEffect409Response } from '../models';
// @ts-ignore
import { IBaseDMAFinancialEffectData } from '../models';
// @ts-ignore
import { IDMAFinancialEffectWithCalculatedValues } from '../models';
// @ts-ignore
import { IDeleteFinancialEffect404Response } from '../models';
// @ts-ignore
import { IDeleteFinancialEffect409Response } from '../models';
// @ts-ignore
import { ISetIROStateRequest } from '../models';
// @ts-ignore
import { IUpdateFinancialEffect404Response } from '../models';
// @ts-ignore
import { IUpdateFinancialEffect409Response } from '../models';
// @ts-ignore
import { IUpdateFinancialEffectIroState403Response } from '../models';
// @ts-ignore
import { IUpdateFinancialEffectIroState409Response } from '../models';
/**
 * DoubleMaterialityAssessmentFinancialEffectsApi - axios parameter creator
 * @export
 */
export const DoubleMaterialityAssessmentFinancialEffectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a new financial effect for the specified data entry object and double materiality assessment category.
         * @summary Adds a new financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFinancialEffect: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('addFinancialEffect', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('addFinancialEffect', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('addFinancialEffect', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('addFinancialEffect', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('addFinancialEffect', 'dmaCategoryId', dmaCategoryId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/financial-effects`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseDMAFinancialEffectData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
         * @summary Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFinancialEffect: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteFinancialEffect', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('deleteFinancialEffect', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('deleteFinancialEffect', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('deleteFinancialEffect', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('deleteFinancialEffect', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'financialEffectId' is not null or undefined
            assertParamExists('deleteFinancialEffect', 'financialEffectId', financialEffectId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/financial-effects/{financialEffectId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
                .replace(`{${"financialEffectId"}}`, encodeURIComponent(String(financialEffectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing financial effect for the specified data entry object and double materiality assessment category.
         * @summary Updates an existing financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinancialEffect: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateFinancialEffect', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateFinancialEffect', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updateFinancialEffect', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('updateFinancialEffect', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('updateFinancialEffect', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'financialEffectId' is not null or undefined
            assertParamExists('updateFinancialEffect', 'financialEffectId', financialEffectId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/financial-effects/{financialEffectId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
                .replace(`{${"financialEffectId"}}`, encodeURIComponent(String(financialEffectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseDMAFinancialEffectData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the IRO state of a given financial effect.
         * @summary Updates the IRO state of a given financial effect.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinancialEffectIroState: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iSetIROStateRequest: ISetIROStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateFinancialEffectIroState', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateFinancialEffectIroState', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updateFinancialEffectIroState', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('updateFinancialEffectIroState', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('updateFinancialEffectIroState', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'financialEffectId' is not null or undefined
            assertParamExists('updateFinancialEffectIroState', 'financialEffectId', financialEffectId)
            // verify required parameter 'iSetIROStateRequest' is not null or undefined
            assertParamExists('updateFinancialEffectIroState', 'iSetIROStateRequest', iSetIROStateRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/financial-effects/{financialEffectId}/iro-state`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
                .replace(`{${"financialEffectId"}}`, encodeURIComponent(String(financialEffectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSetIROStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoubleMaterialityAssessmentFinancialEffectsApi - functional programming interface
 * @export
 */
export const DoubleMaterialityAssessmentFinancialEffectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoubleMaterialityAssessmentFinancialEffectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a new financial effect for the specified data entry object and double materiality assessment category.
         * @summary Adds a new financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAFinancialEffectWithCalculatedValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iBaseDMAFinancialEffectData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
         * @summary Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing financial effect for the specified data entry object and double materiality assessment category.
         * @summary Updates an existing financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAFinancialEffectWithCalculatedValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, iBaseDMAFinancialEffectData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the IRO state of a given financial effect.
         * @summary Updates the IRO state of a given financial effect.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFinancialEffectIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iSetIROStateRequest: ISetIROStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAFinancialEffectWithCalculatedValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFinancialEffectIroState(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, iSetIROStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoubleMaterialityAssessmentFinancialEffectsApi - factory interface
 * @export
 */
export const DoubleMaterialityAssessmentFinancialEffectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoubleMaterialityAssessmentFinancialEffectsApiFp(configuration)
    return {
        /**
         * Adds a new financial effect for the specified data entry object and double materiality assessment category.
         * @summary Adds a new financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: any): AxiosPromise<IDMAFinancialEffectWithCalculatedValues> {
            return localVarFp.addFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iBaseDMAFinancialEffectData, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
         * @summary Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing financial effect for the specified data entry object and double materiality assessment category.
         * @summary Updates an existing financial effect for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: any): AxiosPromise<IDMAFinancialEffectWithCalculatedValues> {
            return localVarFp.updateFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, iBaseDMAFinancialEffectData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the IRO state of a given financial effect.
         * @summary Updates the IRO state of a given financial effect.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} financialEffectId The id of the financial effect to use.
         * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFinancialEffectIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iSetIROStateRequest: ISetIROStateRequest, options?: any): AxiosPromise<IDMAFinancialEffectWithCalculatedValues> {
            return localVarFp.updateFinancialEffectIroState(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, iSetIROStateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoubleMaterialityAssessmentFinancialEffectsApi - interface
 * @export
 * @interface DoubleMaterialityAssessmentFinancialEffectsApi
 */
export interface DoubleMaterialityAssessmentFinancialEffectsApiInterface {
    /**
     * Adds a new financial effect for the specified data entry object and double materiality assessment category.
     * @summary Adds a new financial effect for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApiInterface
     */
    addFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: AxiosRequestConfig): AxiosPromise<IDMAFinancialEffectWithCalculatedValues>;

    /**
     * Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
     * @summary Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} financialEffectId The id of the financial effect to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApiInterface
     */
    deleteFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates an existing financial effect for the specified data entry object and double materiality assessment category.
     * @summary Updates an existing financial effect for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} financialEffectId The id of the financial effect to use.
     * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApiInterface
     */
    updateFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: AxiosRequestConfig): AxiosPromise<IDMAFinancialEffectWithCalculatedValues>;

    /**
     * Updates the IRO state of a given financial effect.
     * @summary Updates the IRO state of a given financial effect.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} financialEffectId The id of the financial effect to use.
     * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApiInterface
     */
    updateFinancialEffectIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iSetIROStateRequest: ISetIROStateRequest, options?: AxiosRequestConfig): AxiosPromise<IDMAFinancialEffectWithCalculatedValues>;

}

/**
 * DoubleMaterialityAssessmentFinancialEffectsApi - object-oriented interface
 * @export
 * @class DoubleMaterialityAssessmentFinancialEffectsApi
 * @extends {BaseAPI}
 */
export class DoubleMaterialityAssessmentFinancialEffectsApi extends BaseAPI implements DoubleMaterialityAssessmentFinancialEffectsApiInterface {
    /**
     * Adds a new financial effect for the specified data entry object and double materiality assessment category.
     * @summary Adds a new financial effect for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApi
     */
    public addFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentFinancialEffectsApiFp(this.configuration).addFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iBaseDMAFinancialEffectData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
     * @summary Deletes an existing financial effect for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} financialEffectId The id of the financial effect to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApi
     */
    public deleteFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentFinancialEffectsApiFp(this.configuration).deleteFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing financial effect for the specified data entry object and double materiality assessment category.
     * @summary Updates an existing financial effect for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} financialEffectId The id of the financial effect to use.
     * @param {IBaseDMAFinancialEffectData} [iBaseDMAFinancialEffectData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApi
     */
    public updateFinancialEffect(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iBaseDMAFinancialEffectData?: IBaseDMAFinancialEffectData, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentFinancialEffectsApiFp(this.configuration).updateFinancialEffect(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, iBaseDMAFinancialEffectData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the IRO state of a given financial effect.
     * @summary Updates the IRO state of a given financial effect.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} financialEffectId The id of the financial effect to use.
     * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentFinancialEffectsApi
     */
    public updateFinancialEffectIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, financialEffectId: string, iSetIROStateRequest: ISetIROStateRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentFinancialEffectsApiFp(this.configuration).updateFinancialEffectIroState(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, financialEffectId, iSetIROStateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
