import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";
import { ConfirmDialogComponent } from "../../common/components/confirm-dialog.component";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  UndoIcon,
} from "../../common/constants/tabler-icon.constants";
import { useDialogStateWithoutData } from "../../common/hooks/dialog-state.hook";
import { IUpdateDistributionCriteriaValueData } from "../applied-distribution-criteria.mutations";
import { AppliedDistributionCriteriaUtilities } from "../utilities/applied-distribution-criteria.utilities";
import { AppliedDistributionCriteriaTableRowComponent } from "./applied-distribution-criteria-table-row.component";

export interface IAppliedDistributionCriteriaTableComponentProps {
  rootDEO: IDataEntryObject;
  currentCriteria: IDistributionCriterionWithApplicationStatus;
  onUpdateValue: (
    values: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId" | "payload">,
  ) => void;
  onDeleteValue: (values: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId">) => void;
  onRemoveConfiguration: () => void;
  allFieldsPopulated: boolean;
  onApplyConfiguration: () => void;
  onRemoveConfigurationApplication: () => void;
}

export const AppliedDistributionCriteriaTableComponent: FC<
  IAppliedDistributionCriteriaTableComponentProps
> = ({
  rootDEO,
  currentCriteria,
  onUpdateValue,
  onDeleteValue,
  onRemoveConfiguration,
  allFieldsPopulated,
  onApplyConfiguration,
  onRemoveConfigurationApplication,
}) => {
  const { t } = useTranslation("applied_distribution_criteria_table_component");
  const [expanded, setExpanded] = useImmer<string[]>([]);

  const handleExpandAll = useCallback(() => {
    setExpanded(AppliedDistributionCriteriaUtilities.getAllIdsInTree(rootDEO));
  }, [setExpanded, rootDEO]);

  const handleCollapseAll = useCallback(() => {
    setExpanded([]);
  }, [setExpanded]);

  // Remove configuration handling / dialog
  const {
    isOpen: isConfirmOpen,
    openDialog: openConfirm,
    closeDialog: closeConfirm,
  } = useDialogStateWithoutData();
  const handleCloseRemoveConfigurationConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        onRemoveConfiguration();
      }
      closeConfirm();
    },
    [closeConfirm, onRemoveConfiguration],
  );

  return (
    <>
      {/* Confirm dialog for configuration reset */}
      <ConfirmDialogComponent
        open={isConfirmOpen}
        onClose={handleCloseRemoveConfigurationConfirm}
        text={{
          buttons: {
            confirm: t("confirm_dialog.button_confirm"),
            cancel: t("confirm_dialog.button_cancel"),
          },
          content: { body: t("confirm_dialog.body"), title: t("confirm_dialog.title") },
        }}
      />
      {/* Actions & Table */}
      <Box display="flex" flexDirection="column" maxHeight="90vh">
        {/* Actions */}
        <Box display="flex" gap={1} my={2}>
          {/* Expand all */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChevronDownIcon />}
            onClick={handleExpandAll}
          >
            {t("buttons.expand_all")}
          </Button>
          {/* Collapse all */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChevronUpIcon />}
            onClick={handleCollapseAll}
          >
            {t("buttons.collapse_all")}
          </Button>
          {/* Remove configuration */}
          {!currentCriteria.applied && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => openConfirm()}
            >
              {t("buttons.remove_configuration")}
            </Button>
          )}
          {/* Apply configuration */}
          {!currentCriteria.applied && (
            <Tooltip
              title={
                allFieldsPopulated
                  ? t("buttons.tooltip_populated")
                  : t("buttons.tooltip_not_populated")
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CheckIcon />}
                  onClick={() => onApplyConfiguration()}
                  disabled={!allFieldsPopulated}
                >
                  {t("buttons.apply_configuration")}
                </Button>
              </span>
            </Tooltip>
          )}
          {/* Remove configuration application */}
          {currentCriteria.applied && (
            <Tooltip
              title={
                currentCriteria.hasValues
                  ? t("buttons.tooltip_remove_application_disabled")
                  : t("buttons.tooltip_remove_application")
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<UndoIcon />}
                  onClick={() => onRemoveConfigurationApplication()}
                  disabled={currentCriteria.hasValues}
                >
                  {t("buttons.remove_application")}
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
        {/* Table */}
        <TableContainer sx={{ border: "1px solid", borderColor: "divider", borderRadius: "8px" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "50%" }}>
                  {t("table_header.data_entry_object_name")}
                </TableCell>
                <TableCell sx={{ width: "50%" }} align="center">
                  {t("table_header.value")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                // Remove Bottom border of all cells in last row (interferes with container border)
                "tr:last-child": {
                  td: { borderBottom: "none" },
                },
              }}
            >
              <AppliedDistributionCriteriaTableRowComponent
                data={rootDEO}
                expanded={expanded}
                currentCriteria={currentCriteria}
                onExpand={(id) => {
                  setExpanded((draft) => {
                    draft.push(id);
                  });
                }}
                onCollapse={(id) => {
                  // TODO: in the future, we might also want to collapse all children when the
                  // parent is collapsed, but for now, it is not necessary
                  setExpanded((draft) => {
                    draft.splice(draft.indexOf(id), 1);
                  });
                }}
                onUpdateValue={onUpdateValue}
                onDeleteValue={onDeleteValue}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
