export enum AppEnvironments {
  Local = "local",
  Development = "development",
  Staging = "staging",
  Production = "production",
  Demo = "demo",
}

export class EnvironmentUtilities {
  // API Base URL
  public static API_BASE_URL = import.meta.env.API_BASE_URL;

  // Keycloak
  public static KEYCLOAK_URL = import.meta.env.KEYCLOAK_URL;
  public static KEYCLOAK_REALM = import.meta.env.KEYCLOAK_REALM;
  public static KEYCLOAK_CLIENT_ID = import.meta.env.KEYCLOAK_CLIENT_ID;

  // General Application Flags

  // Environment can be one of the following values:
  // - local (for local development)
  // - development (for development environment)
  // - staging (for staging environment)
  // - production (for production environment)
  // - demo (for demo environment)
  public static ENVIRONMENT = import.meta.env.ENVIRONMENT;

  public static IS_LOCAL = EnvironmentUtilities.ENVIRONMENT === AppEnvironments.Local;

  public static IS_DEVELOPMENT = EnvironmentUtilities.ENVIRONMENT === AppEnvironments.Development;
  public static IS_STAGING = EnvironmentUtilities.ENVIRONMENT === AppEnvironments.Staging;
  /** Production flag (some still in dev features may/will be disabled) **/
  public static IS_PRODUCTION = EnvironmentUtilities.ENVIRONMENT === AppEnvironments.Production;
  public static IS_DEMO = EnvironmentUtilities.ENVIRONMENT === AppEnvironments.Demo;

  // Intercom
  public static IS_INTERCOM_ENABLED = import.meta.env.INTERCOM_ENABLED === "true";
  public static INTERCOM_APP_ID = import.meta.env.INTERCOM_APP_ID;
  public static INTERCOM_SECRET_KEY = import.meta.env.INTERCOM_SECRET_KEY;
}

// Throw error if environment is not set or is invalid
if (!Object.values(AppEnvironments).includes(EnvironmentUtilities.ENVIRONMENT)) {
  throw new Error(`Invalid environment: ${EnvironmentUtilities.ENVIRONMENT}`);
}
