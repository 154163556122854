import { useParams } from "react-router-dom";
import { useOrganizationsQuery } from "./organizations.queries";
import { useMemo } from "react";

export const useCurrentOrganization = () => {
  const organizationsQuery = useOrganizationsQuery();

  const { organizationId } = useParams();

  return useMemo(() => {
    if (organizationsQuery.isSuccess && organizationsQuery.data) {
      return (
        organizationsQuery.data.organizations.find(
          (organization) => organization.id === organizationId,
        ) ?? null
      );
    } else {
      return null;
    }
  }, [organizationsQuery.isSuccess, organizationsQuery.data, organizationId]);
};
