/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IFinancialEffectCategoryEnum = {
    Market: 'market',
    Technology: 'technology',
    PolicyAndLegal: 'policy_and_legal',
    Reputation: 'reputation',
    AcutePhysical: 'acute_physical',
    ChronicPhysical: 'chronic_physical',
    ResourceEfficiency: 'resource_efficiency',
    ProductsServices: 'products_services',
    FinancialIncentives: 'financial_incentives',
    Others: 'others'
} as const;

export type IFinancialEffectCategoryEnum = typeof IFinancialEffectCategoryEnum[keyof typeof IFinancialEffectCategoryEnum];



