import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ILocalDataEntryObjectInputParameterValueData } from "../../interfaces/local-data-entry-object-values.interfaces";
import {
  DataEntryObjectValuesUtilities,
  IValuesErrorsPerKey,
} from "../../utilities/data-entry-object-values.utilities";
import { useManageDataEntryObjectValue } from "../../hooks/manage-data-entry-object-value.hook";
import { useTranslatedValuesErrors } from "../../hooks/translate-values-errors.hooks";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DataEntryObjectValueInputFieldsDirectComponent } from "../../input-components/direct/data-entry-object-value-input-fields-direct.component";
import { ESRS_VALUE_INPUT_STYLES } from "./esrs-value-input.constants";
import { IDrValueEditProps } from "./dr-value-editor.component";

export const DrValueEditorDirect: FC<IDrValueEditProps> = ({
  deoInputParameter,
  organizationId,
  recordingPeriod,
  rootDataEntryObjectId,
  dataEntryObjectId,
  availableDistributionCriteria,
  onCreate,
  onUpdate,
  onDelete,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_common");

  const handleManageValuesSave = useCallback(
    async (data: ILocalDataEntryObjectInputParameterValueData) => {
      if (deoInputParameter.recordedValues.length === 0) {
        return onCreate(data);
      } else {
        return onUpdate(deoInputParameter.recordedValues[0].id, data);
      }
    },
    [deoInputParameter.recordedValues, onCreate, onUpdate],
  );

  const manageValuesData = useMemo(
    () => ({
      recordedValue:
        deoInputParameter.recordedValues[0] ??
        DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(
          deoInputParameter.inputParameter,
        ),
      inputParameter: deoInputParameter.inputParameter,
      recordingPeriod,
      availableDistributionCriteria,
      subscribeToChanges: false,
      onSave: handleManageValuesSave,
    }),
    [
      deoInputParameter.recordedValues,
      deoInputParameter.inputParameter,
      recordingPeriod,
      availableDistributionCriteria,
      handleManageValuesSave,
    ],
  );

  // Use Value Input Handling
  const { errors, handlers, value, hasChanges } = useManageDataEntryObjectValue(manageValuesData);

  const filteredValuesErrors = useMemo(() => {
    const newErrors: IValuesErrorsPerKey = {};

    Object.entries(errors.errors).forEach(([key, error]) => {
      // Allow deletion of dr entry when no note is present (no errors)
      if (!value.note) {
        // Remove Error if it is "required"
        if (error === "required") {
          return;
        }
      }
      newErrors[key] = error;
    });

    return newErrors;
  }, [errors.errors, value.note]);
  const hasError = useMemo(
    () => Object.keys(filteredValuesErrors).length > 0,
    [filteredValuesErrors],
  );
  const translatedErrors = useTranslatedValuesErrors(filteredValuesErrors);

  const handleClickCommentButton = useCallback(() => {
    handlers.handleNoteChanged(value.note === undefined ? "" : undefined);
  }, [handlers, value.note]);

  const handleClickSaveButton = useCallback(async () => {
    // When filtered Errors empty but errors has value --> Delete Value
    if (!hasError && errors.hasError) {
      return onDelete(deoInputParameter.recordedValues[0].id);
    } else {
      return handlers.handleSave();
    }
  }, [hasError, errors.hasError, onDelete, deoInputParameter.recordedValues, handlers]);

  const showActions = useMemo(() => {
    // Hide Actions when Phase In
    if (
      deoInputParameter.inputParameter.values[0]?.valueConfiguration.type === "esrs-topic-phase-in"
    ) {
      return false;
    }

    return true;
  }, [deoInputParameter.inputParameter.values]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Value Input */}
      <DataEntryObjectValueInputFieldsDirectComponent
        variant="esrs"
        inputParameter={deoInputParameter.inputParameter}
        currentValue={value}
        onInputValueChanged={handlers.handleInputChanged}
        disabled={disabled}
        errors={hasChanges ? translatedErrors : {}}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriod.id}
        rootDataEntryObjectId={rootDataEntryObjectId}
        dataEntryObjectId={dataEntryObjectId}
      />
      {/* Comment Input */}
      {value.note !== undefined && (
        <Box>
          <Typography variant="body2" fontWeight="bold">
            {t("comment_label")}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={value.note}
            onChange={(evt) => handlers.handleNoteChanged(evt.currentTarget.value)}
            fullWidth
            multiline
            minRows={1}
            maxRows={5}
            InputProps={{
              sx: ESRS_VALUE_INPUT_STYLES,
            }}
            disabled={disabled}
          />
        </Box>
      )}
      {/* Actions Row */}
      {showActions && (
        <Box display="flex" alignItems="center" gap={2}>
          <Button color="inherit" onClick={handleClickCommentButton}>
            {t(value.note === undefined ? "add_comment" : "remove_comment", { ns: "buttons" })}
          </Button>
          {/* Spacer */}
          <Box flex={1} />
          {/* Save / Cancel Buttons */}
          <Button color="inherit" onClick={handlers.handleReset} disabled={disabled || !hasChanges}>
            {t("cancel", { ns: "buttons" })}
          </Button>
          <Button
            variant="contained"
            onClick={handleClickSaveButton}
            disabled={disabled || hasError || !hasChanges}
          >
            {t("save", { ns: "buttons" })}
          </Button>
        </Box>
      )}
    </Box>
  );
};
