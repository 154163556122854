import { useMemo } from "react";
import { useSearchUsersQueryInternal } from "../users.queries";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UseQueryResult } from "@tanstack/react-query";

export function useMapUserIdsToUsers(
  organizationId: string,
  userIds: string[],
  search?: string,
): [IUserRepresentation[] | null, UseQueryResult<IUserRepresentation[], Error>] {
  const usersQuery = useSearchUsersQueryInternal(organizationId, search);

  const users = useMemo(() => {
    if (!usersQuery.data) {
      return null;
    }
    return (
      userIds
        .map((userId) => usersQuery.data.find((user) => user.id === userId))
        // Remove undefined (not found) users
        .filter((u) => u) as IUserRepresentation[]
    );
  }, [userIds, usersQuery.data]);

  return [users, usersQuery];
}
