import {
  CountryCodeAlpha2Enum,
  generateDMACategoryTranslations,
  generateESRSTopicTranslations,
  ScopeKeys,
} from "@netcero/netcero-common";
import {
  IContentLanguage,
  IDataEntryObjectInputParameterExclusionReason,
  IDataEntryObjectInputParameterStatus,
  IDEOIPValueDataQuality,
  IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum,
  ITargetPathGoalTemplateType,
  ITargetPathState,
} from "@netcero/netcero-core-api-client";
import countryTranslations from "i18n-iso-countries";
import englishCountryTranslation from "i18n-iso-countries/langs/en.json";
import { TRANSLATIONS_DE } from "./de.translations";
import { CalculationErrorCodes } from "@netcero/netcero-calculation";
import {
  BasicSnackbarApiActionType,
  SnackbarSuccessMassageType,
} from "../modules/app-snackbar/app-snackbar.interfaces";
import { IROType } from "../modules/policies/policies.types";
import { ELIGIBLE_PHASE_IN_DRs } from "../modules/input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { ESRSInputParameterType } from "../modules/data-entry-object-values/esrs/esrs-input-parameter-type-indicator.component";

countryTranslations.registerLocale(englishCountryTranslation);
const CountryNamesByCode = Object.values(CountryCodeAlpha2Enum).reduce((res, code) => {
  const name = countryTranslations.getName(code, "en");
  if (name) {
    res[code] = name;
  }
  return res;
}, {} as Record<CountryCodeAlpha2Enum, string>);

const actionsTranslations: Record<SnackbarSuccessMassageType, string> = {
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_VALUE]:
    "Update disclosure requirement value",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS]:
    "Update contributors",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER]:
    "Update responsible person",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE]:
    "Update value inside disclosure requirement",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS]:
    "Update contributors",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER]:
    "Update responsible person",
  [BasicSnackbarApiActionType.UPDATE_PHASE_IN_DECISION]: "Update Phase-In",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT]: "Submit disclosure requirement",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT]: "Approve disclosure requirement",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT]: "Reject disclosure requirement",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Submit value inside disclosure requirement",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Approve value inside disclosure requirement",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Reject value inside disclosure requirement",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT]:
    "Disclosure requirement excluded from report",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT]:
    "Disclosure requirement restored to report",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Value in disclosure requirement excluded from report",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Value in disclosure requirement restored to report",
  [BasicSnackbarApiActionType.EXPORT_DMA_IROS]: "Exported impacts, risks and opportunities",
};

const DRs_PHASE_IN_EXPLANATIONS: Record<ELIGIBLE_PHASE_IN_DRs, string> = {
  [ELIGIBLE_PHASE_IN_DRs.SBM_1]:
    "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph, point (ii), of Directive 2013/34/EU.",
  [ELIGIBLE_PHASE_IN_DRs.SBM_3]:
    "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement, if it is impracticable to prepare quantitative disclosures.",
  [ELIGIBLE_PHASE_IN_DRs.E1_6]:
    "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the datapoints on scope 3 emissions and total GHG emissions for the first year of preparation of their sustainability statement.",
  [ELIGIBLE_PHASE_IN_DRs.E1_9]:
    "The undertaking may omit the information prescribed by ESRS E1-9 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E1-9 by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement, if it is impracticable to prepare quantitative disclosures.",
  [ELIGIBLE_PHASE_IN_DRs.E2_6]:
    "The undertaking may omit the information prescribed by ESRS E2-6 for the first year of preparation of its sustainability statement. Except for the information prescribed by paragraph 40 (b) on the operating and capital expenditures occurred in the reporting period in conjunction with major incidents and deposits, the undertaking may comply with ESRS E2-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
  [ELIGIBLE_PHASE_IN_DRs.E3_5]:
    "The undertaking may omit the information prescribed by ESRS E3-5 for the first year of preparation of its sustainability statement.The undertaking may comply with ESRS E3-5 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
};

const inputParameterTypesTranslations: Record<ESRSInputParameterType, string> = {
  text: "Text",
  currency: "Currency",
  number: "Number",
  boolean: "Yes/No",
  date: "Date",
  "date-range": "Date Range",
  options: "Select",
  "nested-options": "Select",
  policy: "Policies",
  table: "Table",
  "phase-in": "Phase-In",
};

// NOTE: Make sure to keep the keys in sync with the German translations
export const TRANSLATIONS_EN: typeof TRANSLATIONS_DE = {
  module_access: {
    error_module_not_accessible_to_organization: "Module not accessible to organization!",
  },
  input_parameter_types: inputParameterTypesTranslations,
  common: {
    logout_button_text: "Logout",
    no_changes: "No changes",
    title_warning: "Warning",
    countries: CountryNamesByCode,
    explanation_dialog_title: "Explanation",
    title_audit_logging: "Activity Protocol",
  },
  snack_bar_messages: {
    error_message: "Error: {{translatedAction}} - {{translatedError}}",
    actions: actionsTranslations,
  },
  user_picker_components: {
    selected_user_not_found: "Unknown",
    no_user_selected: "No user selected",
  },
  buttons: {
    create: "Create",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
    delete: "Delete",
    edit: "Edit",
    confirm: "Confirm",
    show_explanation_button_tooltip: "Click to show explanation",
    add_comment: "Add Comment",
    remove_comment: "Remove Comment",
    tooltip_copy_link_success: "Copied!",
  },
  popup_menu_filter_users_section_component: {
    placeholder_users_search: "Search...",
    users_search_no_results: "No results found for search",
  },
  side_bar_nav_items: {
    value_input: "Data Entry",
    target_paths: "Target Paths",
    target_paths_reference_budgets: "Reference Budgets",
    configuration: "Configuration",
    configuration_organization: "Organization",
    configuration_recording_periods: "Recording Periods",
    configuration_stakeholder: "Stakeholder",
    configuration_distribution_criteria: "Distribution Criteria",
    configuration_sources: "Sources",
  },
  organizations_redirect_page: {
    no_associated_organizations: "Your user is not associated with any organizations.",
  },
  recording_periods_redirect_page: {
    no_recording_periods: "No recording periods have been set up for your organization.",
    button_create_recording_period: "Create Recording Period",
  },
  recording_period_actions_dashboard_section: {
    title_double_materiality_assessment: "Double Materiality Assessment",
    text_double_materiality_assessment:
      "Conduct a double materiality assessment to determine the impacts, risks and opportunities of your organization.",
    button_double_materiality_assessment: "Conduct",
    button_double_materiality_assessment_tooltip:
      "Conduct double materiality assessment according to ESRS",
  },
  recording_period_recording_structures_cards_list: {
    structures_without_recording_model_header: "Without Recording Model",
    add_category_button: "Add Topic",
  },
  evaluation_list_item: {
    evaluation_name: {
      emissions: "Evaluate Emissions",
    },
  },
  add_recording_structure_to_recording_period_dialog: {
    dialog_title: 'Add New Topic for "{{organizationName}}":',
    recording_models_empty: "No recording models available.",
    choose_recording_model_label: "Choose Recording Model",
    choose_recording_model_item_text: "Choose Recording Model",
    template_groups_empty: 'No topics available for recording model "{{recordingModelName}}".',
    choose_templates_group_label: "Choose Recording Structure",
    choose_templates_group_item_text: "Choose Recording Structure",
    choose_template_label: "Choose Template",
    templates_group_empty: 'No templates available for topic "{{templatesGroupName}}".',
    choose_template_item_text: "Choose Template",
    accept_template_button_text: "Accept Template",
    cancel_button_text: "Cancel",
  },
  organization_structure_is_draft_notice: {
    organization_structure_is_draft:
      "You need to define and publish the organizational structure for the current recording period before you can enter values.",
    to_configuration_text: "Go to Configuration",
  },
  data_entry_object_values_overview_thg_component: {
    page_title: "Data Entry",
    edit_data_entry_object_input_parameters: "Adjust Values to be Captured",
    no_results_found: "No values to be captured found",
    tooltip_button_filter: "Filter",
    no_filterable_users_available: "No users assigned yet.",
    redirect_button: "Adjust values to be captured",
  },
  data_entry_object_values_overview_esrs_component: {
    eu_law_label: "ESRS Standard",
    application_requirements_label: "Application Requirements",
    confirm_submit_incomplete_dr_title: 'Associated IPs not "Done"',
    confirm_submit_incomplete_dr_body:
      'Some associated IPs are not marked as "Done". Are you sure you want to set the DR status to "In Review" / "Done"?',
    button_add_table_row: "Add Row",
    dr_eligible_for_phase_in_explanations: DRs_PHASE_IN_EXPLANATIONS,
  },
  data_entry_object_values_overview_common: {
    no_contributing_users: "No contributors assigned",
    add_contributing_user_tooltip: "Assign contributors",
    no_more_contributing_users_to_assign: "No more contributors to assign",
    no_users_available_for_responsible_user: "No users available",
    add_responsible_user_tooltip: "Assign responsible person",
    no_responsible_user_value_text: "No responsible person",
    comment_label: "Comment",
    dependency_mismatch_info:
      "The recording of this data point is dependent on the entry for “{{parameterName}}”. This value does not currently have to be entered, but a value is already stored.",
    copy_link_tooltip: "Copy link",
    buttons: {
      previous_section: "Previous Section",
      next_section: "Next Section",
    },
    indicator_optional_text: "Optional",
  },
  data_entry_object_values_input_table: {
    notice_empty: "No values recorded yet",
  },
  options_input_component: {
    name_empty_option: "No selection",
  },
  data_entry_object_input_parameter_common: {
    status: {
      [IDataEntryObjectInputParameterStatus.Open]: {
        name: "Open",
        description: "No data has been provided.",
        action_needed: "User needs to input data.",
      },
      [IDataEntryObjectInputParameterStatus.InProgress]: {
        name: "In Progress",
        description: "Data has been entered, but not yet submitted.",
        action_needed: "User needs to review and submit data.",
      },
      [IDataEntryObjectInputParameterStatus.InReview]: {
        name: "In Review",
        description: "Data has been submitted and is awaiting validation.",
        action_needed: "Reviewer needs to review and approve/reject the data.",
      },
      [IDataEntryObjectInputParameterStatus.Done]: {
        name: "Done",
        description: "Data has been reviewed and approved by the reviewer.",
        action_needed: "None, data is finalized.",
      },
    },
    excluded_status: "Excluded",
    exclude_transition_name: "Exclude from Report",
    transition_actions: {
      submit_with_reviewer: "Submit for review",
      submit_without_reviewer: "Finalize without review",
      approve: "Approve",
      reject: "Reject",
      notice_only_responsible_person_can_approve_or_reject:
        "Only the responsible person can approve or reject.",
    },
    exclusion_reasons: {
      [IDataEntryObjectInputParameterExclusionReason.NonMaterial]: "Mark as non-material",
      [IDataEntryObjectInputParameterExclusionReason.NoDataAvailable]: "No data available",
      [IDataEntryObjectInputParameterExclusionReason.ApplyPhaseIn]: "Apply phase-in provision",
    },
    notice_exclusion_only_possible_as_responsible:
      "Only the responsible person can exclude values from the report.",
    notice_inclusion_only_possible_as_responsible:
      "Only the responsible person can restore values to the report.",
  },
  data_entry_object_input_parameter_exclusion_dialog: {
    dialog_title_exclude: "Why do you want to exclude the data?",
    dialog_title_already_excluded: "Data is excluded from the report",
    label_exclusion_reason: "Reason",
    label_explanation: "Short Explanation (optional)",
    button_confirm_exclude: "Exclude",
    button_include: "Restore to Report",
    // Include Confirm Dialog
    include_confirm_dialog_title: "Restore to Report",
    include_confirm_dialog_body: "Are you certain that you want restore this value to the report?",
  },
  data_entry_object_input_parameters_dialog: {
    dialog_title: "Values to be Captured:",
    close_dialog: "Close",
  },
  data_entry_object_select_group_component: {
    button_settings: "Actions",
    button_category_select_all: "Select all directly subordinate data entry objects",
    button_category_deselect_all: "Deselect all directly subordinate data entry objects",
    button_select_all: "Select all subordinate data entry objects",
    button_deselect_all: "Deselect all subordinate data entry objects",
  },
  data_entry_object_input_parameters_select_group_component: {
    button_settings: "Actions",
    button_category_select_all: "Select All Values of this Topic and all its Subtopics",
    button_category_deselect_all: "Deselect All Values of this Topic and all its Subtopics",
    button_select_all: "Select All Values of this Topic",
    button_deselect_all: "Deselect All Values of this Topic",
  },
  data_entry_object_values_group: {
    category_empty: "At the moment no values or companies are captured.",
  },
  data_entry_object_values_group_input_parameter_component: {
    no_contributing_users: "No contributors assigned",
    add_contributing_user_tooltip: "Assign contributors",
    no_more_contributing_users_to_assign: "No more contributors to assign",
  },
  associated_iros_tab_component: {
    no_entries: {
      materialImpacts: "You have to create impacts in order to be able to select them here.",
      financialEffects: "You have to risks/opportunities in order to be able to select them here.",
    },
    no_results: {
      materialImpacts: "No impacts were found for the specified search.",
      financialEffects: "No opportunities or risks were found for the specified search.",
    },
  },
  associated_iros_dialog: {
    title: "Link Impacts, Risks and Opportunities",
    search_placeholder: "Search...",
    tab_titles: {
      materialImpacts: "Impacts",
      financialEffects: "Risks and opportunities",
    } as { [key in IROType]: string },
  },
  data_entry_object_policy_component: {
    name: "Name",
    button_category_irrelevant: "No policies adopted",
    tooltip_add_policy: "Create new policy",
    no_policies_defined: "No policies defined",
    present_policies: "I have policies for this topic",
    no_present_policies: "I do not have policies for this topic",
    policies_adopted: "Policies adopted",
    edit_policy: "Edit policy",
    create_policy: "Create new policy",
    iro_dropdown_text: "Impacts, Risks & Opportunities",
    delete_policy_tooltip: "Delete policy",
    confirm_dialog: {
      title: "Delete policy",
      body: "Are you sure you want to remove the policy?",
      button_confirm: "Remove",
      button_cancel: "Cancel",
    },
    section_titles: {
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E1]:
        "Disclosures for climate change",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E2]: "Disclosures for pollution",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E3]:
        "Disclosures for water and marine resources",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E4]:
        "Disclosures for biodiversity and ecosystems",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E5]:
        "Disclosures for circular economy",
      esrs_s: "General Disclosures for social topics",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S1]:
        "Disclosures for own workforce",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S2]:
        "Disclosures for workforce in the value chain",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S3]:
        "Disclosures for affected communities",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S4]:
        "Disclosures for consumers and end-users",
    },
    labels: {
      general: {
        name: "Name or identifier of policy",
        descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory:
          "Disclosure of consideration given to interests of key stakeholders in setting policy",
        descriptionOfKeyContentsOfPolicyExplanatory: "Disclosure of key contents of policy",
        descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory:
          "Disclosure of scope of policy or of its exclusions",
        descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory:
          "Disclosure of most senior level in organisation that is accountable for implementation of policy",
        disclosureOfThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicyExplanatory:
          "Disclosure of third-party standards or initiatives that are respected through implementation of policy",
        explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementItExplanatory:
          "Explanation of how policy is made available to potentially affected stakeholders and stakeholders who need to help implement it",
        policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementIt:
          "Policy is made available to potentially affected stakeholders and stakeholders who need to help implement it",
      },
      e1: {
        addressedMatters: "Sustainability matters addressed by policy for climate change",
      },
      e2: {
        addressedMatters: "Sustainability matters addressed by policy for pollution",
        policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil:
          "Policy addresses mitigating negative impacts related to pollution of air, water and soil",
        disclosureOfHowPolicyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoilExplanatory:
          "Disclosure of how policy addresses mitigating negative impacts related to pollution of air, water and soil",
        policyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcern:
          "Policy addresses substituting and minimising use of substances of concern and phasing out substances of very high concern",
        disclosureOfHowPolicyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcernExplanatory:
          "Disclosure of how policy addresses substituting and minimising use of substances of concern and phasing out substances of very high concern",
        policyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironment:
          "Policy addresses avoiding incidents and emergency situations, and if and when they occur, controlling and limiting their impact on people and environment",
        disclosureOfHowPolicyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironmentExplanatory:
          "Disclosure of how policy addresses avoiding incidents and emergency situations, and if and when they occur, controlling and limiting their impact on people and environment",
      },
      e3: {
        addressedMatters:
          "Sustainability matters addressed by policy for water and marine resources",
        policyAddressesWaterManagement: "Policy addresses water management",
        disclosureOfHowPolicyAddressesWaterManagementExplanatory:
          "Disclosure of how policy addresses water management",
        policyAddressesUseAndSourcingOfWaterAndMarineResourcesInOwnOperations:
          "Policy addresses use and sourcing of water and marine resources in own operations",
        policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater:
          "Policy addresses water treatment as a step towards more sustainable sourcing of water",
        policyAddressesPreventionAndAbatementOfWaterPollutionResultingFromActivities:
          "Policy addresses prevention and abatement of water pollution resulting from activities",
        policyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResources:
          "Policy addresses product and service design in view of addressing water-related issues and preservation of marine resources",
        disclosureOfHowPolicyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResourcesExplanatory:
          "Disclosure of how policy addresses product and service design in view of addressing water-related issues and preservation of marine resources",
        policyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChain:
          "Policy addresses commitment to reduce material water consumption in areas at water risk in own operations and along upstream and downstream value chain",
        disclosureOfHowPolicyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChainExplanatory:
          "Disclosure of how policy addresses commitment to reduce material water consumption in areas at water risk in own operations and along upstream and downstream value chain",
        policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems:
          "Policy prevents further deterioration and protects and enhances the status of water bodies and aquatic ecosystems",
        policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources:
          "Policy promotes sustainable water use based on long-term protection of available water resources",
        policyAimsAtEnhancedProtectionAndImprovementOfAquaticEnvironment:
          "Policy aims at enhanced protection and improvement of the aquatic environment",
        policyPromotesGoodEnvironmentalStatusOfMarineWater:
          "Policy promotes good environmental status of marine water",
        policyPromotesReductionOfWaterWithdrawalsAndWaterDischarges:
          "Policy promotes reduction of water withdrawals and water discharges",
        policyContributesToGoodEcologicalAndChemicalQualityOfSurfaceWaterBodiesAndGoodChemicalQualityAndQuantityOfGroundwaterBodies:
          "Policy contributes to good ecological and chemical quality of surface water bodies and good chemical quality and quantity of groundwater bodies",
        policyMinimisesMaterialImpactsAndRisksAndImplementsMitigationMeasuresThatAimToMaintainValueAndFunctionalityOfPriorityServicesAndToIncreaseResourceEfficiencyOnOwnOperations:
          "Policy minimises material impacts and risks and implements mitigation measures that aim to maintain value and functionality of priority services and to increase resource efficiency on own operations",
        policyAvoidsImpactsOnAffectedCommunities: "Policy avoids impacts on affected communities",
      },
      e4: {
        addressedMatters:
          "Sustainability matters addressed by policy for biodiversity and ecosystems",
        disclosureOfHowPolicyRelatesToSustainabilityMattersForBiodiversityAndEcosystemsExplanatory:
          "Disclosure of how policy relates to sustainability matters for biodiversity and ecosystems",
        disclosureOfHowPolicyRefersToProductionSourcingOrConsumptionOfRawMaterialsExplanatory:
          "Disclosure of how policy refers to production, sourcing, or consumption of raw materials",
        disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory:
          "Disclosure of how policy limits procurement from suppliers that cannot demonstrate that they are not contributing to significant damage to protected areas or key biodiversity areas",
        disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory:
          "Disclosure of how policy refers to recognised standards or third-party certifications overseen by regulators",
        disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
          "Disclosure of how policy addresses raw materials originating from ecosystems that have been managed to maintain or enhance conditions for biodiversity",
        sustainableDevelopmentGoalsToWhichPolicyIsConnectedBiodiversityAndEcosystems:
          "Sustainable development goals to which policy is connected (biodiversity and ecosystems)",
        disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory:
          "Disclosure of how policy enables to avoid negative impacts on biodiversity and ecosystems in own operations and related upstream and downstream value chain",
        disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory:
          "Disclosure of how policy enables to reduce and minimise negative impacts on biodiversity and ecosystems in own operations and throughout upstream and downstream value chain that cannot be avoided",
        disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory:
          "Disclosure of how policy enables to restore and rehabilitate degraded ecosystems or restore cleared ecosystems following exposure to impacts that cannot be completely avoided and/or minimised",
        disclosureOfHowPolicyEnablesToMitigateContributionToMaterialBiodiversityLossDriversExplanatory:
          "Disclosure of how policy enables to mitigate contribution to material biodiversity loss drivers",
        thirdpartyStandardOfConductUsedInPolicyIsObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesAndRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances:
          "Third-party standard of conduct used in policy is objective and achievable based on scientific approach to identifying issues and realistic in assessing how these issues can be addressed under a variety of practical circumstances",
        thirdpartyStandardOfConductUsedInPolicyIsDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsWithNoGroupHoldingUndueAuthorityOrVetoPowerOverContent:
          "Third-party standard of conduct used in policy is developed or maintained through process of ongoing consultation with relevant stakeholders with balanced input from all relevant stakeholder groups with no group holding undue authority or veto power over content",
        thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime:
          "Third-party standard of conduct used in policy encourages stepwise approach and continuous improvement in standard and its application of better management practices and requires establishment of meaningful targets and specific milestones to indicate progress against principles and criteria over time",
        thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008:
          "Third-party standard of conduct used in policy is verifiable through independent certifying or verifying bodies which have defined and rigorous assessment procedures that avoid conflicts of interest and are compliant with ISO guidance on accreditation and verification procedures or Article 52 of Regulation (EC) No 765/2008",
        thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice:
          "Third-party standard of conduct used in policy conforms to ISEAL code of good practice",
        biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpacts:
          "Biodiversity and ecosystems-related policy relates to material biodiversity and ecosystems-related impacts",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpactsExplanatory:
          "Disclosure of how biodiversity and ecosystems-related policy relates to material biodiversity and ecosystems-related impacts",
        biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunities:
          "Biodiversity and ecosystems-related policy relates to material dependencies and material physical and transition risks and opportunities",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunitiesExplanatory:
          "Disclosure of how biodiversity and ecosystems-related policy relates to material dependencies and material physical and transition risks and opportunities",
        biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain:
          "Biodiversity and ecosystems-related policy supports traceability of products, components, and raw materials with material actual or potential impacts on biodiversity and ecosystems along value chain",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChainExplanatory:
          "Disclosure of how biodiversity and ecosystems-related policy supports traceability of products, components, and raw materials with material actual or potential impacts on biodiversity and ecosystems along value chain",
        biodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversity:
          "Biodiversity and ecosystems-related policy addresses production, sourcing, or consumption from ecosystems that are managed to maintain or enhance conditions for biodiversity",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
          "Disclosure of how biodiversity and ecosystems-related policy addresses production, sourcing, or consumption from ecosystems that are managed to maintain or enhance conditions for biodiversity",
        biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpacts:
          "Biodiversity and ecosystems-related policy addresses social consequences of biodiversity and ecosystems-related impacts",
        disclosureOfHowBiodiversityAndEcosystemsRelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpactsExplanatory:
          "Disclosure of how biodiversity and ecosystems-related policy addresses social consequences of biodiversity and ecosystems-related impacts",
      },
      e5: {
        addressedMatters:
          "Sustainability matters addressed by policy for resource use and circular economy",
        layerInWasteHierarchyThatPolicyAddresses: "Layer in waste hierarchy that policy addresses",
        descriptionOfHowPolicyAddressesWasteHierarchyExplanatory:
          "Description of how policy addresses waste hierarchy",
        policyAddressesTransitioningAwayFromUseOfVirginResources:
          "Policy addresses transitioning away from use of virgin resources",
        disclosureOfHowPolicyAddressesTransitioningAwayFromUseOfVirginResourcesExplanatory:
          "Disclosure of how policy addresses transitioning away from use of virgin resources",
        policyAddressesSustainableSourcingAndUseOfRenewableResources:
          "Policy addresses sustainable sourcing and use of renewable resources",
        disclosureOfHowPolicyAddressesSustainableSourcingAndUseOfRenewableResourcesExplanatory:
          "Disclosure of how policy addresses sustainable sourcing and use of renewable resources",
        policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatment:
          "Policy addresses prioritisation of avoidance or minimisation of waste over waste treatment",
        disclosureOfHowPolicyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatmentExplanatory:
          "Disclosure of how policy addresses prioritisation of avoidance or minimisation of waste over waste treatment",
      },
      s: {
        groundsForDiscriminationThatAreSpecificallyCoveredByPolicy:
          "Grounds for discrimination that are specifically covered by policy",
        disclosureOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
          "Disclosure of aspects of policy that satisfy requirements of disclosure of policies related to own workforce, value chain workers, affected communities, consumers, and end-users",
        specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace:
          "Specific policy commitments related to inclusion or positive action for people from groups at particular risk of vulnerability in own workforce are in place",
        disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsExplanatory:
          "Disclosure of communication tools and channels aimed at ensuring that policy is accessible and that different audiences understand its implications",
        disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory:
          "Disclosure of specific policy commitments related to inclusion or positive action for people from groups at particular risk of vulnerability in own workforce",
        s1: {
          addressedMatters: "Sustainability matters addressed by policy for own workforce",
        },
        s2: {
          addressedMatters: "Sustainability matters addressed by policy for value chain workers",
        },
        s3: {
          addressedMatters: "Sustainability matters addressed by policy for affected communities",
        },
        s4: {
          addressedMatters:
            "Sustainability matters addressed by policy for consumers and end-users",
        },
      },
    },
    enums: {
      e1: {
        addressedMatters: {
          "esrs:ClimateChangeAdaptationMember": "Climate change adaptation",
          "esrs:ClimateChangeMitigationMember": "Climate change mitigation",
          "esrs:EnergyEfficiencyMember": "Energy efficiency",
          "esrs:RenewableEnergyDeploymentMember": "Renewable energy deployment",
          "esrs:OtherClimateChangeSubtopicMember": "Other climate change subtopic",
        },
      },
      e2: {
        addressedMatters: {
          "esrs:PollutionOfAirMember": "Pollution of air",
          "esrs:PollutionOfWaterMember": "Pollution of water",
          "esrs:PollutionOfSoilMember": "Pollution of soil",
          "esrs:PollutionOfLivingOrganismsAndFoodResourcesMember":
            "Pollution of living organisms and food resources",
          "esrs:SubstancesOfConcernMember": "Substances of concern",
          "esrs:SubstancesOfVeryHighConcernMember": "Substances of very high concern",
          "esrs:MicroplasticsMember": "Microplastics",
          "esrs:OtherPollutionSubtopicMember": "Other pollution subtopic",
        },
      },
      e3: {
        addressedMatters: {
          "esrs:WaterAndMarineResourcesMember": "Water and marine resources",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterConsumptionMember": "Water consumption",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterWithdrawalsMember": "Water withdrawals",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesMember": "Water discharges",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesInOceansMember":
            "Water discharges in oceans",
          "esrs:WaterAndMarineResourcesMember.esrs:ExtractionAndUseOfMarineResourcesMember":
            "Extraction and use of marine resources",
          "esrs:OtherWaterAndMarineResourcesSubtopicMember":
            "Other water and marine resources subtopic",
        },
      },
      e4: {
        addressedMatters: {
          "esrs:DirectImpactDriversOfBiodiversityLossMember":
            "Direct impact drivers of biodiversity loss",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:ClimateChangeMember":
            "Climate change",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:LandUseChangeMember":
            "Land use change",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:FreshWateruseChangeMember":
            "Freshwater use change",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:SeauseChangeMember":
            "Seause change",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:DirectExploitationMember":
            "Direct exploitation",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:InvasiveAlienSpeciesMember":
            "Invasive alien species",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:PollutionMember": "Pollution",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:OtherDirectImpactDriversOfBiodiversityLossMember":
            "Other direct impact drivers of biodiversity loss",
          "esrs:ImpactsOnStateOfSpeciesMember": "Impacts on state of species",
          "esrs:ImpactsOnExtentAndConditionOfEcosystemsMember":
            "Impacts on extent and condition of ecosystems",
          "esrs:ImpactsAndDependenciesOnEcosystemServicesMember":
            "Impacts and dependencies on ecosystem services",
          "esrs:OtherBiodiversityAndEcosystemsSubtopicMember":
            "Other biodiversity and ecosystems subtopic",
        },
        sustainableDevelopmentGoalsToWhichPolicyIsConnectedBiodiversityAndEcosystems: {
          "esrs:EndPovertyInAllItsFormsEverywhereMember":
            "1 - End poverty in all its forms everywhere",
          "esrs:EndHungerAchieveFoodSecurityAndImprovedNutritionAndPromoteSustainableAgricultureMember":
            "2 - End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
          "esrs:EnsureHealthyLivesAndPromoteWellbeingForAllAtAllAgesMember":
            "3 - Ensure healthy lives and promote wellbeing for all at all ages",
          "esrs:EnsureInclusiveAndEquitableQualityEducationAndPromoteLifelongLearningOpportunitiesForAllMember":
            "4 - Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
          "esrs:AchieveGenderEqualityAndEmpowerAllWomenAndGirlsMember":
            "5 - Achieve gender equality and empower all women and girls",
          "esrs:EnsureAvailabilityAndSustainableManagementOfWaterAndSanitationForAllMember":
            "6 - Ensure availability and sustainable management of water and sanitation for all",
          "esrs:EnsureAccessToAffordableReliableSustainableAndModernEnergyForAllMember":
            "7 - Ensure access to affordable, reliable, sustainable, and modern energy for all",
          "esrs:PromoteSustainedInclusiveAndSustainableEconomicGrowthFullAndProductiveEmploymentAndDecentWorkForAllMember":
            "8 - Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all",
          "esrs:BuildResilientInfrastructurePromoteInclusiveAndSustainableIndustrializationAndFosterInnovationMember":
            "9 - Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation",
          "esrs:ReduceInequalityWithinAndAmongCountriesMember":
            "10 - Reduce inequality within and among countries",
          "esrs:MakeCitiesAndHumanSettlementsInclusiveSafeResilientAndSustainableMember":
            "11 - Make cities and human settlements inclusive, safe, resilient, and sustainable",
          "esrs:EnsureSustainableConsumptionAndProductionPatternsMember":
            "12 - Ensure sustainable consumption and production patterns",
          "esrs:TakeUrgentActionToCombatClimateChangeAndItsImpactsMember":
            "13 - Take urgent action to combat climate change and its impacts",
          "esrs:ConserveAndSustainablyUseOceansSeasAndMarineResourcesForSustainableDevelopmentMember":
            "14 - Conserve and sustainably use the oceans, seas, and marine resources for sustainable development",
          "esrs:ProtectRestoreAndPromoteSustainableUseOfTerrestrialEcosystemsSustainablyManageForestsCombatDesertificationAndHaltAndReverseLandDegradationAndHaltBiodiversityLossMember":
            "15 - Protect, restore, and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation, and halt biodiversity loss",
          "esrs:PromotePeacefulAndInclusiveSocietiesForSustainableDevelopmentProvideAccessToJusticeForAllAndBuildEffectiveAccountableAndInclusiveInstitutionsAtAllLevelsMember":
            "16 - Promote peaceful and inclusive societies for sustainable development, provide access to justice for all, and build effective, accountable, and inclusive institutions at all levels",
          "esrs:StrengthenMeansOfImplementationAndRevitalizeGlobalPartnershipForSustainableDevelopmentMember":
            "17 - Strengthen the means of implementation and revitalize the global partnership for sustainable development",
        },
      },
      e5: {
        addressedMatters: {
          "esrs:ResourceInflowsMember": "Resource inflows",
          "esrs:ResourceOutflowsRelatedToProductsAndServicesMember":
            "Resource outflows related to products and services",
          "esrs:WasteMember": "Waste",
          "esrs:OtherResourceUseAndCircularEconomySubtopicMember":
            "Other resource use and circular economy subtopic",
        },
        layerInWasteHierarchyThatPolicyAddresses: {
          "esrs:PreventionMember": "Prevention",
          "esrs:PreparationForReuseMember": "Preparation for reuse",
          "esrs:RecyclingMember": "Recycling",
          "esrs:OtherRecoveryMember": "Other recovery",
          "esrs:DisposalMember": "Disposal",
        },
      },
      s: {
        groundsForDiscriminationThatAreSpecificallyCoveredByPolicy: {
          "esrs:RacialAndEthnicOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Racial and ethnic origin grounds for discrimination and diversity are specifically covered in policy",
          "esrs:ColourGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Colour grounds for discrimination and diversity are specifically covered in policy",
          "esrs:SexGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Sex grounds for discrimination and diversity are specifically covered in policy",
          "esrs:SexualOrientationGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Sexual orientation grounds for discrimination and diversity are specifically covered in policy",
          "esrs:GenderIdentityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Gender identity grounds for discrimination and diversity are specifically covered in policy",
          "esrs:DisabilityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Disability grounds for discrimination and diversity are specifically covered in policy",
          "esrs:AgeGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Age grounds for discrimination and diversity are specifically covered in policy",
          "esrs:ReligionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Religion grounds for discrimination and diversity are specifically covered in policy",
          "esrs:PoliticalOpinionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Political opinion grounds for discrimination and diversity are specifically covered in policy",
          "esrs:NationalExtractionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "National extraction grounds for discrimination and diversity are specifically covered in policy",
          "esrs:SocialOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Social origin grounds for discrimination and diversity are specifically covered in policy",
          "esrs:OtherFormsOfDiscriminationCoveredByEuRegulationAndNationalLawAreSpecificallyCoveredInPolicyMember":
            "Other forms of discrimination covered by EU regulation and national law are specifically covered in policy",
        },
        s1: {
          addressedMatters: {
            "esrs:WorkingConditionsForOwnWorkforceMember": "Working conditions for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SecureEmploymentForOwnWorkforceMember":
              "Secure employment for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorkingTimeForOwnWorkforceMember":
              "Working time for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:AdequateWagesForOwnWorkforceMember":
              "Adequate wages for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SocialDialogueForOwnWorkforceMember":
              "Social dialogue for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:FreedomOfAssociationForOwnWorkforceMember":
              "Freedom of association for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:ExistenceOfWorksCouncilsForOwnWorkforceMember":
              "Existence of works councils for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:InformationConsultationAndParticipationRightsOfOwnWorkforceMember":
              "Information, consultation, and participation rights of own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:CollectiveBargainingForOwnWorkforceMember":
              "Collective bargaining for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorklifeBalanceForOwnWorkforceMember":
              "Work-life balance for own workforce",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:HealthAndSafetyForOwnWorkforceMember":
              "Health and safety for own workforce",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember":
              "Equal treatment and opportunities for all for own workforce",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkforceMember":
              "Gender equality and equal pay for work of equal value for own workforce",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:TrainingAndSkillsDevelopmentForOwnWorkforceMember":
              "Training and skills development for own workforce",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForOwnWorkforceMember":
              "Employment and inclusion of persons with disabilities for own workforce",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkforceMember":
              "Measures against violence and harassment in the workplace for own workforce",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:DiversityForOwnWorkforceMember":
              "Diversity for own workforce",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember":
              "Other work-related rights for own workforce",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ChildLabourForOwnWorkforceMember":
              "Child labour for own workforce",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ForcedLabourForOwnWorkforceMember":
              "Forced labour for own workforce",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:AdequateHousingForOwnWorkforceMember":
              "Adequate housing for own workforce",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:PrivacyForOwnWorkforceMember":
              "Privacy for own workforce",
            "esrs:OtherOwnWorkforceSubtopicMember": "Other own workforce subtopic",
          },
        },
        s2: {
          addressedMatters: {
            "esrs:WorkingConditionsForValueChainWorkersMember":
              "Working conditions for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SecureEmploymentForValueChainWorkersMember":
              "Secure employment for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorkingTimeForValueChainWorkersMember":
              "Working time for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:AdequateWagesForValueChainWorkersMember":
              "Adequate wages for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SocialDialogueForValueChainWorkersMember":
              "Social dialogue for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:FreedomOfAssociationForValueChainWorkersMember":
              "Freedom of association for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:CollectiveBargainingForValueChainWorkersMember":
              "Collective bargaining for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorklifeBalanceForValueChainWorkersMember":
              "Work-life balance for value chain workers",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:HealthAndSafetyForValueChainWorkersMember":
              "Health and safety for value chain workers",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember":
              "Equal treatment and opportunities for all for value chain workers",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForValueChainWorkersMember":
              "Gender equality and equal pay for work of equal value for value chain workers",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:TrainingAndSkillsDevelopmentForValueChainWorkersMember":
              "Training and skills development for value chain workers",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForValueChainWorkersMember":
              "Employment and inclusion of persons with disabilities for value chain workers",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForValueChainWorkersMember":
              "Measures against violence and harassment in the workplace for value chain workers",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:DiversityForValueChainWorkersMember":
              "Diversity for value chain workers",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember":
              "Other work-related rights for value chain workers",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ChildLabourForValueChainWorkersMember":
              "Child labour for value chain workers",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ForcedLabourForValueChainWorkersMember":
              "Forced labour for value chain workers",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:AdequateHousingForValueChainWorkersMember":
              "Adequate housing for value chain workers",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:WaterAndSanitationForValueChainWorkersMember":
              "Water and sanitation for value chain workers",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:PrivacyForValueChainWorkersMember":
              "Privacy for value chain workers",
            "esrs:OtherWorkersInValueChainSubtopicMember": "Other workers in value chain subtopic",
          },
        },
        s3: {
          addressedMatters: {
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember":
              "Communities' economic, social, and cultural rights",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateHousingForAffectedCommunitiesMember":
              "Adequate housing for affected communities",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateFoodMember":
              "Adequate food",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:WaterAndSanitationForAffectedCommunitiesMember":
              "Water and sanitation for affected communities",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:LandrelatedImpactsMember":
              "Land-related impacts",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:SecurityrelatedImpactsMember":
              "Security-related impacts",
            "esrs:CommunitiesCivilAndPoliticalRightsMember":
              "Communities' civil and political rights",
            "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfExpressionForAffectedCommunitiesMember":
              "Freedom of expression for affected communities",
            "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfAssemblyMember":
              "Freedom of assembly",
            "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:ImpactsOnHumanRightsDefendersMember":
              "Impacts on human rights defenders",
            "esrs:RightsOfIndigenousPeoplesMember": "Rights of indigenous peoples",
            "esrs:RightsOfIndigenousPeoplesMember.esrs:FreePriorAndInformedConsentMember":
              "Free, prior, and informed consent",
            "esrs:RightsOfIndigenousPeoplesMember.esrs:SelfdeterminationMember":
              "Self-determination",
            "esrs:RightsOfIndigenousPeoplesMember.esrs:CulturalRightsMember": "Cultural rights",
            "esrs:OtherAffectedCommunitiesSubtopicMember": "Other affected communities subtopic",
          },
        },
        s4: {
          addressedMatters: {
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember":
              "Information-related impacts for consumers and/or end users",
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:PrivacyForConsumersAndOrEndUsersMember":
              "Privacy for consumers and/or end users",
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:FreedomOfExpressionForConsumersAndOrEndusersMember":
              "Freedom of expression for consumers and/or end users",
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:AccessToQualityInformationMember":
              "Access to quality information",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember":
              "Personal safety of consumers and/or end users",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:HealthAndSafetyForConsumersAndOrEndUsersMember":
              "Health and safety for consumers and/or end users",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:SecurityOfPersonMember":
              "Security of person",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:ProtectionOfChildrenMember":
              "Protection of children",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember":
              "Social inclusion of consumers and/or end users",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:NondiscriminationMember":
              "Non-discrimination",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:AccessToProductsAndServicesMember":
              "Access to products and services",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:ResponsibleMarketingPracticesMember":
              "Responsible marketing practices",
            "esrs:OtherConsumersAndOrEndusersSubtopicMember":
              "Other consumers and/or end users subtopic",
          },
        },
      },
    },
    input_errors: {
      general: {
        name: {
          required: "Please provide a name!",
        },
      },
    },
  },
  data_entry_object_value_edit_component: {
    tooltips: {
      add_note: "Add Note",
      remove_note: "Remove Note",
      add_date: "Capture Date",
      use_distribution_criterion: "Use Distribution Criteria",
      undo_distribution_criterion: "Do Not Use Distribution Criteria",
      data_quality: {
        none: "No specification",
        [IDEOIPValueDataQuality.Estimated]: "Estimated",
        [IDEOIPValueDataQuality.Measured]: "Exact Value/Measured",
        [IDEOIPValueDataQuality.Calculated]: "Calculated",
      },
    },
  },
  data_entry_object_value_actions_component: {
    tooltips: {
      cancel: "Cancel",
      reset: "Discard Changes",
      edit: "Save",
      create: "Save New Entry",
      delete: "Delete",
    },
  },
  data_entry_object_values_edit: {
    remove_date_tooltip: "Remove Date",
    date_error_invalid: "Invalid Date",
    date_error_before_start: "Date cannot be earlier than {{minDate}}!",
    date_error_after_end: "Date cannot be later than {{maxDate}}!",
    date_error_end_before_start: "End date cannot be before start date!",
    value_use_distribution_criterion: "Use Distribution Criteria",
    value_undo_distribution_criterion: "Do Not Use Distribution Criteria",
    no_dc_selected: "No Selection",
    label_dc: "Distribution Criteria",
    criteria_hint_component: {
      labels: {
        value_in_percent: "Specified Value (%):",
        calculated_value: "Calculated Value:",
      },
      hints: {
        general:
          'This value will be distributed to the substructure based on the "{{dcName}}" criteria.',
        value_in_percent:
          'The value specified for <bold>"{{deoName}}"</bold> is <bold>{{keyValue}}</bold> for this criteria, which is <bold>{{valueInPercent}}%</bold> of the total amount of <bold>{{sum}}</bold>.',
      },
    },
  },
  data_entry_object_value_esrs_phase_in_input: {
    use_phase_in_toggle_label:
      "Activate phase-in regulation for companies with up to 750 employees",
    information_box_content:
      "The application of phase-in replaces the disclosure requirements of the selected ESRS topic with a minimal set of new mandatory information.",
    notice_only_root_data_entry_object: "Phase-in is only available in the root data entry object",
    notice_phase_in_in_use: "Phase-in is is in use",
  },
  data_entry_object_parent_values_component: {
    title: "Values from Parent Data Entry Objects:",
    title_own_values: "Own Values:",
  },
  data_entry_object_parent_value_component: {
    from: "From",
  },
  error_dialog: {
    dialog_title: "Error",
    button_close: "Close",
  },
  data_entry_object_edit_dialog: {
    dialog_title: "Edit Data Entry Object {{dataEntryObjectName}}",
    dialog_title_new: "Create New Data Entry Object",
    name_required: "Please enter a name.",
    name_input_label: "Name",
    financially_consolidated_label: "Financially Consolidated?",
    operational_control_label: "Operational Control?",
    part_of_value_chain_label: "Part of the Value Chain?",
    description_input_label: "Description (optional)",
    button_delete_tooltip: "Remove Data Entry Object",
    button_change_parent: "Move to Another Data Entry Object",
    button_cancel: "Cancel",
    button_save: "Save",
    button_create: "Create",
    object_type_select_label: "Type",
    country_select_label: "Country",
    share_held_by_parent_label: "Share held of Data Entry Object",
    object_type_value_location: "Location",
    object_type_value_department: "Department",
    object_type_value_team: "Team",
    object_type_value_business_area: "Business Area",
    object_type_value_grouping: "Grouping",
    object_type_value_organization: "Organization",
  },
  configuration_dashboard_page: {
    title: "Configuration",
    title_recording_periods: "Recording Periods",
    description_recording_periods: "Create and manage recording periods.",
    button_recording_periods: "Recording Periods",
    title_stakeholder: "Stakeholder",
    description_stakeholder:
      "Create and manage stakeholders. External stakeholders can take actions in the platform via a unique share-link.",
    button_stakeholder: "Stakeholder",
    title_distribution_criteria: "Distribution Criteria",
    description_distribution_criteria: "Create and manage distribution criteria.",
    button_distribution_criteria: "Distribution Criteria",
    title_sources: "Sources",
    button_sources: "Sources",
    description_sources: "Create and manage sources.",
  },
  recording_periods_edit_list_component: {
    title: "Recording Periods",
    button_new_recording_period: "Create",
    recording_periods_empty: "No recording periods available.",
    button_edit_organization_structure_tooltip: "Edit organization structure",
    button_edit_distribution_criteria_tooltip: "Edit distribution criteria",
    button_edit_recording_period_tooltip: "Edit recording period",
  },
  organization_configuration_dashboard_page: {
    title: "Configure Organization",
    title_organization_structure: "Organization Structure",
    description_organization_structure: "Manage the organization's structure.",
    button_edit_organization_structure: "Edit Organization Structure",
  },
  organization_structure_configuration_page: {
    title: "Organization Structure",
    button_dc: "Distribution Criteria",
    button_dc_tooltip: "Configure Distribution Criteria",
  },
  organization_structure_configuration_component: {
    recording_period_select_label: "Select Recording Period",
    organization_structure_commit_draft_button_tooltip: "Publish Draft",
    organization_structure_is_draft_notice:
      "The organization structure is currently a draft. You must publish the organization structure " +
      "before you can enter values.",
  },
  organization_structure_tree_editor_component: {
    delete_confirm_dialog_title: "Remove Data Entry Object",
    delete_confirm_dialog_content:
      "Are you sure you want to remove the data entry object {{dataEntryObjectName}} and all its subordinate data entry objects?",
    delete_confirm_dialog_button_delete: "Remove",
    delete_confirm_dialog_button_cancel: "Cancel",
    add_child_tooltip: "Add New Data Entry Object",
    add_child_mode_text: "Select the parent data entry object",
    add_child_cancel_tooltip: "Cancel",
    error_cannot_set_deo_as_its_own_parent: "Data entry object cannot be its own parent!",
    error_selected_deo_is_already_parent_of_deo_to_be_moved:
      "The selected data entry object is already the current parent data entry object!",
    error_selected_new_parent_cannot_be_child_of_deo_to_be_moved:
      "The data entry object cannot be moved into one of its subordinate data entry objects!",
    error_dialog_button_ok: "Accept",
    change_parent_mode_text: "Select New Parent Data Entry Object",
    change_parent_cancel_tooltip: "Cancel",
    deo_count_text: "{{currentCount}} / {{maxCount}} Data Entry Objects",
    deo_limit_reached: "Maximum number of data entry objects reached.",
  },
  organization_structure_tree_component: {
    node_type_location: "Location",
    node_type_department: "Department",
    node_type_team: "Team",
    node_type_business_area: "Business Area",
    node_type_grouping: "Grouping",
    node_type_company: "Organization/Company",
    node_type_organization: "Organization",
  },
  organization_structure_draft_commit_dialog: {
    title: "Publish Organization Structure",
    warning_text:
      "Are you sure you want to publish the current draft? This action cannot be undone.",
    button_cancel: "Cancel",
    button_publish: "Publish",
  },
  recording_period_edit_dialog: {
    title_create: "Create New Recording Period",
    title_edit: "Edit Recording Period {{name}}",
    default_name: "Fiscal Year {{year}}",
    name_label: "Name",
    name_required: "Please enter a name.",
    description_label: "Description (optional)",
    start_date_label: "Start Date",
    start_date_required: "Please enter a start date.",
    start_date_invalid: "Please enter a valid start date.",
    start_date_min: "Start date must be after {{minDate}} (previous period).",
    start_date_max: "Start date must be before end date.",
    end_date_required: "Please enter an end date.",
    end_date_invalid: "Please enter a valid end date.",
    end_date_min: "End date must be after start date.",
    end_date_max: "End date must be before {{maxDate}} (next period).",
    end_date_label: "End Date",
    button_create: "Create",
    button_save: "Save",
    button_cancel: "Cancel",
  },
  // Evaluations
  emissions_evaluation_page: {
    title: "Evaluate Emissions",
    notice: {
      text: "The emissions evaluation is currently under refactoring and is therefore not available.",
      button: "Back to Start",
    },
  },
  emissions_overview: {
    title_organization_unit: "Data Entry Object:",
    notice_unit:
      "All values in the overview are given in the unit <bold>t CO<sub>2</sub>eq</bold>.",
    title_emission_group: "Emission Group:",
    title_total_emissions: "Total Emissions:",
    title_total_emissions_by_recording_group: "By Emission Groups:",
    title_total_emissions_by_child: "By Data Entry Object:",
    title_child_data_entry_objects: "Data Entry Objects:",
    title_scopes: "Scope Distribution:",
  },
  emissions_direct_and_upstream_component: {
    title_direct_emissions: "Direct Emissions",
    title_upstream_emissions: "Upstream Emissions",
  },
  total_emissions_pie_chart_component: {
    empty: "No emissions",
    total_emissions: "Total:",
  },
  total_emissions_bar_percentage_component: {
    empty: "No emissions",
  },
  emissions_by_scope_bar_charts: {
    empty: "No emissions",
    button_filter: "Filter",
    title_scope1: "Scope 1",
    title_scope2: "Scope 2",
    title_scope3: "Scope 3",
    title_total: "Total",
    no_deo_with_emissions_notice:
      "No emissions have been recorded for the selected data entry object and emission group.",
    deo_result_no_emissions: "(no emissions)",
  },
  deo_emissions_by_category_bar_chart: {
    empty: "No emissions",
    button_filter: "Filter",
    no_deo_with_emissions_notice:
      "No emissions have been recorded for the selected data entry object and emission group.",
    deo_result_no_emissions: "(no emissions)",
  },
  emissions_evaluation_error_component: {
    title: "An error has occurred during the evaluation!",
    subtitle_error_message: "Error message:",
    errorMessages: {
      "formula_specific.reference.context_key_not_found":
        "The requested key was not found in the context!",
    } as { [key in CalculationErrorCodes]: string },
    affected: {
      subtitle: "Formula & Input Parameters",
      inputParameter: "Input Parameter: {{inputParameterName}} (ID: {{id}})",
      calculationFormula: "Formula: {{calculationFormulaName}} (ID: {{id}})",
      value: "Value: {{id}}",
    },
    step: {
      subtitle: "Affected Step:",
      subtitle_value_key: "Context Key:",
      subtitle_index: "({{count}}. step within the formula)",
      subtitle_formula: "Calculation formula:",
    },
    context: {
      subtitle: "Context:",
      headers: {
        key: "Context Key:",
        value: "Value",
      },
    },
  },
  // Double Materiality Assessment
  dma_common: {
    // Materiality State
    materiality_chip_tooltip: "Materiality Level / Materiality",
    materiality_true_state: "Yes",
    materiality_false_state: "No",
    // IRO State
    iro_state_draft: "Draft",
    iro_state_shared: "Released",
    // Category State
    category_state_open: "Open",
    category_state_workInProgress: "In Progress",
    category_state_shared: "Released",
    category_state_verified: "Completed",
  },
  dma_configuration: {
    title_threshold: "Assessment",
    description_threshold:
      "This value is used to determine whether an impact, opportunity, or risk is material or not. " +
      "If the calculated materiality level is equal to or greater than this value, the impact, " +
      "opportunity, or risk is considered material.",
    label_threshold: "Threshold",
    error_threshold_max: "The threshold must not be greater than {{max}}.",
    error_threshold_min: "The threshold must not be less than {{min}}.",
    title_potential_extent_steps: "Potential Magnitude",
    description_potential_extent_steps:
      "The potential magnitude of opportunities and risks is assessed in 5 steps. For these steps, " +
      "a monetary value can be assigned, which is then used to calculate the severity in Euros.",
    label_set_potential_extent_steps: "Set Custom Values",
    error_potentialExtentStep_not_ascending: "Values must be ascending.",
    error_potentialExtentStep_required: "Please enter a value.",
    // probability of occurrence material
    title_probability_of_occurrence_steps_material: "Likelihood of the impact",
    description_probability_of_occurrence_steps_material:
      "The likelihood of the impact is assessed in 5 steps. For these steps, a percentage value " +
      "can be assigned, which is then used to calculate the severity/materiality level.",
    // probability of occurrence financial
    title_probability_of_occurrence_steps_financial:
      "Likelihood of Occurrence of Financial Effects",
    description_probability_of_occurrence_steps_financial:
      "The likelihood of occurrence is assessed in 5 steps. For these steps, a percentage value " +
      "can be assigned, which is then used to calculate the severity/materiality level.",
    // probability of occurrence common
    label_set_probability_of_occurrence_steps: "Set Custom Values",
    // errors probability of occurrence
    error_probabilityOfOccurrence_step_not_ascending: "Values must be ascending.",
    error_probabilityOfOccurrence_step_required: "Please enter a value.",
    error_probabilityOfOccurrence_step_min: "The value must be greater than or equal to 0.",
    error_probabilityOfOccurrence_step_max: "The value must not be greater than 100.",
    // Export
    title_export: "Export",
  },
  dma_export_button_component: {
    button_content_iros: "Export Impacts, Risks and Opportunities",
    "button_content_stakeholder-feedback": "Export Stakeholder Feedback",
    button_tooltip_iros:
      "Export impacts, risks and opportunities in the currently chosen language ({{currentLanguage}})",
    "button_tooltip_stakeholder-feedback":
      "Export stakeholder feedback in the currently chosen language ({{currentLanguage}})",
  },
  double_materiality_assessment_page: {
    categories_empty: "No topics defined yet!",
    no_dma_configuration_present:
      "No DMA configuration has been added yet for this recording period.",
  },
  double_materiality_assessment_stepper_component: {
    material_impacts_checkbox_label: "Impacts",
    financial_effects_checkbox_label: "Risks and Opportunities",
    filter_by_user_label: "Filter by User",
    previous_step_button: "Previous",
    next_step_button: "Next",
    complete_dma_button: "Complete",
    can_not_complete_dma:
      "The DMA cannot be completed at the moment because at least one topic is incomplete. " +
      "Double checkmarks will be displayed next to all complete categories.",
    label_dma_dashboard: "Dashboard",
    button_filter: "Filter",
    no_filterable_users_available: "No responsible or assigned persons added yet.",
    selected_category_empty:
      "The selected main topic does not contain subcategories for which impacts can be recorded.",
    dma_incomplete_notice_title: "Double Materiality Incomplete!",
    dma_incomplete_notice_content:
      "The double materiality assessment can only be completed when you have provided at least one impact and one " +
      "risk or opportunity for each topic that does not have subcategories or confirmed that you do not want to " +
      "provide any impacts or risks and opportunities for it.\n\nPlease complete the double materiality assessment before proceeding!",
    dma_incomplete_notice_button_accept: "Complete",
    dma_open_topic:
      "This topic is still open because no impacts or risks/opportunities have been recorded and the topic has not been marked as not relevant.",
    dma_shared_topic:
      "This topic lacks validation by stakeholders and the subsequent completion of all subtopics.",
    dma_verified_topic: "This topic is completed.",
    title_dma_configuration: "Configuration",
    label_step_dma_configuration: "Configuration",
    button_create_category: "Add Topic",
  },
  execute_dma_confirm_dialog: {
    title: "Complete Double Materiality Assessment?",
    // drafts_warning: "There are still unreleased impacts or opportunities and risks!",
    content_text:
      "Are you sure you want to complete the double materiality assessment?\n\n" +
      "This action cannot be undone.",
    button_cancel: "Cancel",
    button_confirm: "Complete",
  },
  double_materiality_assessment_sub_category_component: {
    dma_category_actions_component: {
      tooltip_edit: "Edit Topic",
      tooltip_add_child: "Add Sub-Topic",
      button_category_irrelevant: "Not Relevant",
      tooltip_stakeholder_feedback: "Stakeholder Feedback",
    },
    material_impacts_header: "Impacts",
    material_impacts_empty_message: "No impacts yet",
    category_opted_out_notice: "This topic has been marked as not relevant.",
    label_category_opted_out_reason: "Reason:",
    button_edit_opt_out_reason_tooltip: "Edit Reason",
    button_opt_back_into_category: "Reset",
    create_material_impact_tooltip: "Add Impact",
    edit_material_impact_tooltip: "Edit Impact",
    financial_effects_header: "Risks and Opportunities",
    financial_effects_empty_message: "No risks or opportunities yet",
    create_financial_effect_tooltip: "Add Risk or Opportunity",
    edit_financial_effect_tooltip: "Edit Risk or Opportunity",
    "downstream-value-chain_name": "Downstream Value Chain",
    "own-organization_name": "Own Organization",
    "upstream-value-chain_name": "Upstream Value Chain",
    show_actions: "Show actions",
    hide_actions: "Hide actions",
  },
  dma_financial_effect_general: {
    effect_type: {
      investment_costs: "Investment Costs",
      operations_costs: "Operating Costs",
      capital_costs: "Capital Costs",
      assets_value_change: "Change in Asset Value",
      revenue: "Revenue",
      access_to_capital: "Access to Capital",
    },
  },
  multiple_chip_component: {
    only_more_items: "{{count}} selected",
    more_items: "+{{count}} more",
  },
  dma_impact_or_effect_edit_dialog_common: {
    // Inputs
    label_title: "Title",
    error_title_required: "Please enter a title.",
    label_description: "Description and Background (optional)",
    description_explanation:
      "Explanations of the situation, description of underlying data, assumptions, and " +
      "methods for assessment/calculation, as well as listing of involved areas.",
    label_assessmentExplanation: "Assessment Explanation",
    assessmentExplanation_explanation: "Explanation of the assessment and underlying assumptions.",
    error_assessmentExplanation_required: "Please provide an explanation.",
    label_internalCommunication: "Comment",
    internalCommunication_info:
      'Use this field for internal communication to ask your colleagues questions, give advice or feedback.\n Tip: Start your comment with: "My Name: My Message".',
    // Evaluation Section
    header_horizon_evaluation: "Assessment",
    header_total_evaluation: "Total Assessment Across All Three Time Horizons",
    severity_label: "Severity",
    severity_currency_label: "Severity in Euros",
    no_equivalent_set_1: "No individual values set in",
    no_equivalent_set_2: "configuration",
    no_equivalent_set_3: "",
    materiality_label: "Material",
    materiality_degree_label: "Materiality Level",
    materiality_true_state: "Yes",
    materiality_false_state: "No",
    edit_materiality_tooltip: "Manually override calculated materiality",
    calculated_materiality_label: "Calculated Materiality",
    custom_materiality_label: "Custom Materiality",
    cancel_edit_materiality_tooltip: "Cancel and use calculated materiality",
    label_user_materiality_reason: "Reason for Custom Materiality",
    error_user_materiality_reason_required: "Please provide a reason.",
    inherit_form_horizon_select_label: "Time Horizon Assessment",
    inherit_from_shortTerm: "Inherit values from Short-Term",
    inherit_from_mediumTerm: "Inherit values from Medium-Term",
    inherit_from_longTerm: "Inherit values from Long-Term",
    // inherits_values_itself: "Time horizon adopts values itself",
    horizon_shortTerm_name: "Short-Term",
    horizon_shortTerm_time_span: "1 Year",
    horizon_mediumTerm_name: "Medium-Term",
    horizon_mediumTerm_time_span: "2 - 5 Years",
    horizon_longTerm_name: "Long-Term",
    horizon_longTerm_time_span: "> 5 Years",
    no_inheritance_name: "Manual",
    in_use_by_other_horizon: "Other time horizons inherit values from this time horizon.",
    horizons_comment_label:
      "Explanation for the different assessment of the time horizons (optional)",
    horizons_comment_explanation:
      "Explain why there are differences in the assessment of the individual time horizons " +
      "(short-, medium-, and long-term) and briefly explain the deviations.",
    // Areas
    areas_select_label: "Affected Areas",
    error_effect_area_required: "Please select at least one area.",
    "downstream-value-chain_name": "Downstream Value Chain",
    "own-organization_name": "Own Organization",
    "upstream-value-chain_name": "Upstream Value Chain",
    // Slider Values
    slider_value_very_low: "Very Low",
    slider_value_low: "Rather Low",
    slider_value_average: "Medium",
    slider_value_high: "Rather High",
    slider_value_very_high: "Very High",
    // Slider Values Extent
    slider_value_extent_very_low: "Minimal",
    slider_value_extent_low: "Low",
    slider_value_extent_average: "Medium",
    slider_value_extent_high: "High",
    slider_value_extent_very_high: "Very High/Absolute",
    // Slider Values Scale
    slider_value_scale_very_low: "Limited",
    slider_value_scale_low: "Concentrated",
    slider_value_scale_average: "Medium",
    slider_value_scale_high: "Widespread",
    slider_value_scale_very_high: "Global/Total",
    // Slider Values Irreversibility
    slider_value_irreversibility_very_low: "Relatively Easy/Short-Term Reversible",
    slider_value_irreversibility_low: "Reversible with Effort",
    slider_value_irreversibility_average: "Difficult/Mid-Term Reversible",
    slider_value_irreversibility_high: "Very Difficult/Long-Term Reversible",
    slider_value_irreversibility_very_high: "Not Reversible",
    // Responsible
    label_responsible_user: "Responsible Person",
    // Assigned Users
    label_assigned_users: "Assigned Persons",
    explanation_assigned_users:
      "Assigned Persons have access to this IRO and can enter data as well as modify the evaluation as long as the data has not been released.",
    no_assigned_users: "No persons assigned",
    add_assigned_user_tooltip: "Assign Person",
    no_more_users_to_assign: "No more users available",
    confirm_dialog: {
      button_confirm: "$t(buttons:confirm)",
      button_cancel: "$t(buttons:cancel)",
    },
  },
  dma_material_impact_edit_dialog: {
    title_create: "Create Impact",
    title_edit: 'Edit Impact <italic>"{{title}}"</italic>',
    delete_tooltip: "Remove Impact",
    // Impact Inputs
    subtitle_type: "Type of Impact",
    type_actual: "Actual Impact",
    type_potential: "Potential Impact",
    label_hasNegativeEffectOnHumanRights: "Potentially Negative Impact on Human Rights",
    label_probability: "Likelihood",
    explanation_probability: "How likely is it that the impact will occur?",
    subtitle_result: "Impact Result",
    result_positive: "Positive",
    result_negative: "Negative",
    label_irreversibility: "Irremediable Character",
    explanation_irreversibility: `Describe how difficult it is to compensate for or reverse the negative impact afterwards.
      If it is very easy to restore the original state, then the irremediable character is very low.
      If it is particularly difficult or even impossible to restore the original state, then the irremediable character should be rated as very high.`,
    label_extent: "Scale",
    explanation_extent: `Assess the scale / extent of the impact on the significantly affected scope.
    First determine the scope of the significantly affected and then assess the scale of the impact on this group.
    Example: Elevator renovation at one of your locations:
    If there is an elevator in the connected neighboring building and only a few floors are step-free, then the scale for, e.g., wheelchair users can be rated as "rather high".`,
    label_scope: "Scope",
    explanation_scope: `Indicate the scope of how far-reaching the impact is in relation to the totality. Ask yourself the following three questions:
      1. Who could potentially be affected by the impact?
      2. Who is significantly affected?
      3. What is the proportion/scope of those significantly affected in relation to those potentially affected?\n
      Example: Elevator renovation at one of your locations:
      1. Only employees, customers, and people who are on site.
      2. Only people who rely on the elevator
      3. If there is a staircase and the location is only frequented by a few people with limited mobility, the scope can be rated as "very low".`,
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Impact Incomplete!",
    incomplete_for_shared_notice:
      "To release an impact, an explanation of the assessment must be provided.",
    // Buttons
    button_cancel: "Cancel",
    button_close: "Close",
    button_save: "Save",
    button_create: "Create",
    explanations: {
      esrs_e1: {
        extent:
          "How strong is the impact on climate change / adaptation to climate change? How much CO2e is emitted? How much energy is consumed?\n\nvery high/ absolute \n... Very strong impacts or emissions and damage or complete destruction \nhigh \n ...High impacts or emissions and damage \nmedium \n ...Medium impacts or emissions and damage \nlow \n ...Low impacts or emissions and damage \nminimal \n ...Minimal impacts or emissions and damage for those affected, the economy or the environment",
        scope:
          "How widespread is the impact? Where does it occur and how widespread is it? \n\nglobal / total \nwidespread \nmedium \nconcentrated \nlimited ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_e2: {
        extent:
          "How strong is the impact on the affected soils/water bodies/air? \n\nvery high / absolute \n... Very strong impacts or emissions and damage or complete destruction \nhigh \n ...High impacts or emissions and damage \nmedium \n ...Moderate impacts or emissions and damage \nlow \n ...Low impacts or emissions and damage \nminimal \n ...Minimal impacts or emissions and damage for those affected, the economy or the environment",
        scope:
          "How widespread is the impact? Where does it occur in relation to the entire area in which the company operates? How widespread is it? ( e.g. local soil pollution vs. river pollution that is regionally widespread)\n\nglobal / total \nwidespread \nmedium \nconcentrated \nlimited ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_e3: {
        extent:
          "How strong is the impact on the affected water bodies or marine resources? (consumption x scarcity) \n\nvery high / absolute \n ...Very strong impacts or emissions and damage or complete destruction \nhigh \n ...High impacts or emissions and damage \nmedium \n ...Medium impacts or emissions and damage \nlow \n ...Low impacts or emissions and damage \nminimal \n ...Minimal impacts or emissions and damage for those affected, the economy or the environment",
        scope:
          "How widespread is the impact? Where does it occur in relation to the entire area in which the company operates? How widespread is it? ( e.g. local soil pollution vs. river pollution that is regionally widespread)\n\nglobal / total \nwidespread \nmedium \nconcentrated \nlimited ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_e4: {
        extent:
          "How strong is the impact on the affected species or ecosystems?\n\nvery high/ absolute \n... Very strong impacts or emissions and damage or complete destruction \nhigh \n ...High impacts or emissions and damage \nmedium \n ...Medium impacts or emissions and damage \nlow \n ...Low impacts or emissions and damage \nminimal \n ...Minimal impacts or emissions and damage for those affected, the economy or the environment",
        scope:
          "How widespread is the impact? Where does it occur in relation to the entire area in which the company operates? How widespread is it? ( e.g. local soil pollution vs. river pollution that is regionally widespread)\n\nglobal / total \nwidespread \nmedium \nconcentrated \nlimited ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_e5: {
        extent:
          "How 'critical' are the resources or waste used? e.g. in terms of scarcity, renewable vs. non-renewable raw materials and recyclability? What quantity of resources is used (also in relation to the total available quantity)? How large is the volume of waste?\n\nvery high/ absolute . . . Very high resource consumption or no recycling of these resources \nhigh . . . High resource use or only low recycling of these resources \nmedium . . . Medium utilization of resources or partial reduction of these resources \nlow . . . Low resource use or extensive recycling of these resources \nminimal . . . Very low resource use or complete recycling of these resources",
        scope:
          "How widespread is the impact? Where does it occur and how widespread is it?\n\nglobal / total \nwidespread \nmedium \nconcentrated \nlimited ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_s1: {
        extent:
          "How severe is the impact on the people affected? For example, are fundamental human rights violated/restricted? Or is physical and/or mental health (significantly) impaired?\n\nvery high / absolute . . . Leads to death or health impairments that can lead to a significant reduction in quality of life and/or life expectancy \nhigh . . . Significant impacts on working conditions or critical human rights violations; significant restrictions on access to basic needs (including education, livelihood, etc.) \nmedium . . . Relevant impacts on working conditions, well-being and health or concrete human rights violations in access to basic needs (including education, livelihood, etc.) \nlow . . . Low impacts on workers' well-being, low impacts on human rights, health and low restriction/impairment of access to basic needs (including education, livelihood, etc.) \nminimal . . . No significant impact on workers and no impact that violates the right to life, health or access to basic necessities of life",
        scope:
          "What is the proportion of people affected in relation to the respective population? Ie, for example, \nWhat is the proportion of employees / consumers affected by this impact ? \nHow many workers in the value chain are affected, taking into account the risk profile of suppliers? \nHow many people in the communities around operations or mining sites are affected by impacts?\n\nglobal / total . . . High number of employees affected: >50 percent of the total workforce \nwidespread . . . Moderately high number of people affected: 30-50 percent of the total workforce \nmedium . . . Moderate number of people affected: 15-30 percent of the total workforce \nconcentrated . . . Low to moderate number of people affected: 5-15 percent of the total workforce \nlimited . . . No or only a small number of persons affected: <5 percent of the total workforce",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_s2: {
        extent:
          "How serious is the impact on the people affected? For example, are fundamental human rights violated/restricted? Or is physical and/or mental health (significantly) impaired?\n\nvery high/ absolute . . . Leads to death or health impairments that may result in a significant reduction in the quality of life and/or life expectancy \nhigh . . . Significant impacts on working conditions or critical human rights violations; significant restrictions on access to basic needs (including education, livelihood, etc.) \nmedium . . . Relevant impacts on working conditions, well-being and health or concrete human rights violations in access to basic needs (including education, livelihood, etc.) \nlow . . . Low impacts on workers' well-being, low impacts on human rights, health and low restriction/impairment of access to basic needs (including education, livelihood, etc.) \nminimal . . . No significant impacts on workers and no impacts that violate the right to life, health or access to basic needs of life",
        scope:
          "What is the proportion of people affected in relation to the respective population? Ie, for example, \nWhat is the proportion of employees / consumers affected by this impact ? \nHow many workers in the value chain are affected, taking into account the risk profile of suppliers? \nHow many people in the communities around operations or mining sites are affected by impacts? \n\nglobal / total . . . High number of employees affected: >50% of the total workforce \nwidespread . . . Moderately high number of people affected: 30-50% of the total workforce \nmedium . . . Moderate number of people affected: 15-30% of the total workforce \nconcentrated . . . Low to moderate number of people affected: 5-15% of the total workforce \nlimited . . . No or only a small Number of people affected: <5% of total workforce ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_s3: {
        extent:
          "How severe is the impact on the people affected? For example, are fundamental human rights violated/restricted? Or is physical and/or mental health (significantly) impaired?\n\nvery high/ absolute . . . Leads to death or health impairments that can significantly reduce the quality of life and/or life expectancy \nhigh . . . Significant impacts on working conditions or critical human rights violations; significant restrictions on access to basic needs (including education, livelihood, etc.) \nmedium . . . Relevant impacts on well-being and health or noticeable human rights violations in access to basic needs (including education, livelihood, etc.) \nlow . . . Low impacts on people's well-being, low impacts on human rights, health and low restriction/impairment of access to basic needs (including education, livelihood, etc.) \nminimal . . . No significant impact on workers and no impact that violates the right to life, health or access to basic necessities of life",
        scope:
          "What is the proportion of people affected in relation to the respective population? Ie, for example, \nWhat is the proportion of employees / consumers affected by this impact ? \nHow many workers in the value chain are affected, taking into account the risk profile of suppliers? \nHow many people in the communities around operations or mining sites are affected by impacts? \n\nglobal / total . . . High number of employees affected: >50% of the total workforce \nwidespread . . . Moderately high number of people affected: 30-50% of the total workforce \nmedium . . . Moderate number of people affected: 15-30% of the total workforce \nconcentrated . . . Low to moderate number of people affected: 5-15% of the total workforce \nlimited . . . No or only a small Number of people affected: <5% of total workforce ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_s4: {
        extent:
          "How severe is the impact on the people affected? For example, are fundamental human rights violated/restricted? Or is physical and/or mental health (significantly) impaired?\n\nvery high/ absolute . . . Leads to death or health impairments that can significantly reduce the quality of life and/or life expectancy \nhigh . . . Significant impacts on working conditions or critical human rights violations; significant restrictions on access to basic needs (including education, livelihood, etc.) \nmedium . . . Relevant impacts on well-being and health or noticeable human rights violations in access to basic needs (including education, livelihood, etc.) \nlow . . . Low impacts on people's well-being, low impacts on human rights, health and low restriction/impairment of access to basic needs (including education, livelihood, etc.) \nminimal . . . No significant impact on workers and no impact that violates the right to life, health or access to basic necessities of life",
        scope:
          "What is the proportion of people affected in relation to the respective population? Ie, for example, \nWhat is the proportion of employees / consumers affected by this impact ? \nHow many workers in the value chain are affected, taking into account the risk profile of suppliers? \nHow many people in the communities around operations or mining sites are affected by impacts? \n\nglobal / total . . . High number of employees affected: >50% of the total workforce \nwidespread . . . Moderately high number of people affected: 30-50% of the total workforce \nmedium . . . Moderate number of people affected: 15-30% of the total workforce \nconcentrated . . . Low to moderate number of people affected: 5-15% of the total workforce \nlimited . . . No or only a small Number of people affected: <5% of total workforce ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_g1: {
        extent:
          "How serious is the impact on the public or the affected suppliers/business partners/competitors?\n\nvery high/ absolute \nhigh \nmedium \nlow \nminimal ",
        scope:
          "How widespread is the impact in relation to the entire area in which the company operates or what is the proportion of stakeholders affected ( e.g. within suppliers)?\n\nglobal / total . . . High number of people affected, in relation to the entire company \nwidespread . . . Moderately high number of people affected \nmedium . . . Moderate number of people affected \nconcentrated . . . Low to moderate number of people affected \nlimited . . . No to low number of people affected ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
      esrs_o: {
        extent:
          "How severe is the impact on the respective topic?\n\nvery high/ absolute \nhigh \nmedium \nlow \nminimal ",
        scope:
          "How widespread is the impact? Where does it occur and how widespread is it?\n\nglobal / total \nwidespread \nmedium \nconcentrated \nlimited ",
        irreversibility:
          "How difficult or easy are the effects to reverse ? \n\nirreversible \nvery difficult to change or reversible in the long term \ndifficult to reverse or reversible in the medium term \nchangeable with effort (time and cost) \nrelatively easy to change or reversible in the short term",
      },
    },
  },
  dma_financial_effect_common: {
    effect_category: {
      select_label: "Category",
      market: "Market",
      technology: "Technology",
      policy_and_legal: "Policy and legal",
      reputation: "Reputation",
      acute_physical: "Acute physical",
      chronic_physical: "Chronic physical",
      resource_efficiency: "Resource efficiency",
      products_services: "Products / services",
      financial_incentives: "Financial incentives",
      others: "Others",
    },
  },
  dma_financial_effect_edit_dialog: {
    title_create: "Create Opportunity or Risk",
    title_edit: 'Edit Opportunity or Risk <italic>"{{title}}"</italic>',
    delete_tooltip: "Delete Opportunity or Risk",
    // Effect Inputs
    effect_type_select_label: "Opportunity or Risk",
    risk_name: "Risk",
    chance_name: "Opportunity",
    indefinable_name: "No Assignment",
    subtitle_effect_type: "Financial Impact on",
    subtitle_effect_type_info:
      "<p>A sustainability aspect is material from a financial perspective if it has significant financial " +
      "effects for the company or if such effects are reasonably expected.</p> " +
      "<p>This is the case if a sustainability aspect results in risks or opportunities that, " +
      "within short-, medium-, or long-term time horizons, have a significant influence on the company's " +
      "development, financial position, financial performance, cash flows, access to finance, or cost of capital " +
      "(or if such influence can reasonably be expected).</p> " +
      "<p>Risks and opportunities may arise from past or future events. The financial materiality of a sustainability " +
      "aspect is not limited to aspects under the company's control, but also includes information about significant " +
      "risks and opportunities that are attributable to business relationships outside the consolidation scope used " +
      "in financial statement preparation.</p>",
    subtitle_additional_effect_types: "Other Types:",
    error_effect_type_required: "Please select at least one type for the impact.",
    label_potential_extent: "Potential Magnitude",
    label_probability_of_occurrence: "Likelihood of Occurrence",
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Opportunity/Risk Incomplete!",
    incomplete_for_shared_notice:
      "To release an opportunity/risk, an explanation of the assessment must be provided.",
    incomplete_points: {
      // These don't follow naming convention because they have to match the names
      // of the associated input fields :)
      assessmentExplanation: "An explanation for the rating has to be provided.",
      effectType: "At least one financial effect has to be selected.",
    },
    // Buttons
    button_cancel: "Cancel",
    button_close: "Close",
    button_save: "Save",
    button_create: "Create",
  },
  delete_impact_or_effect_confirm_dialog: {
    title_material_impact: 'Delete Impact <italic>"{{name}}"</italic> ?',
    title_financial_effect: 'Delete Opportunity or Risk <italic>"{{name}}"</italic> ?',
    content_text_material_impact: "Are you sure you want to delete the impact {{name}}?",
    content_text_financial_effect:
      "Are you sure you want to delete the opportunity or risk {{name}}?",
    button_cancel: "Cancel",
    button_delete: "Delete",
  },
  esrs_topic_opt_out_confirm_dialog: {
    title_opt_out: "Record no impacts for this ESRS topic?",
    title_edit: "Update Reason",
    content_text:
      'Are you sure you do not want to record any impacts, opportunities, or risks for the ESRS topic "{{topic}}" ({{name}})? ' +
      "You can undo your decision at any time.",
    opt_out_reason_label: "Reason",
    opt_out_reason_required: "Please provide a reason.",
    button_cancel: "Cancel",
    button_opt_out: "Confirm",
    button_edit: "Save",
  },
  // DMA Dashboard
  dma_dashboard: {
    title: "Double Materiality Assessment Dashboard",
    button_back_to_dma: "Back to Input",
    title_materiality_matrix: "Materiality Matrix",
    // Breadcrumbs
    breadcrumbs_item_total: "Total",
    // ### DMA Matrix
    title_overview: "Overview",
    // axis labels
    label_axis_financial_materiality: "Financial Materiality Level",
    label_axis_material_materiality: "Impact Materiality Level",
    // topics
    label_topic_environmental: "Environmental",
    label_topic_social: "Social",
    label_topic_governance: "Governance",
    label_topic_other: "Other",
    // categories
    no_categories_to_display_notice: "No topics to display",
    label_matrix_children: "Subtopics",
    // ### Overview Table
    title_overview_table: "Overview Table",
    label_show_impacts: "Impacts",
    label_show_effects: "Opportunities/Risks",
    button_expand_all: "Expand All",
    button_collapse_all: "Collapse All",
    // Headers
    table_header_material_impacts_group: "Impacts",
    table_header_financial_effects_group: "Opportunities/Risks",
    header_name: "Name",
    header_materiality_short_term: "Short Term",
    header_materiality_medium_term: "Medium Term",
    header_materiality_long_term: "Long Term",
    header_materiality_max: "Maximum",
    header_max_materiality_degree: "Maximum",
    header_material: "Materiality",
    // Content
    tooltip_opt_out: "Marked as not material",
    tooltip_no_children: "No subtopics",
    materiality_value_true: "Material",
    materiality_value_false: "Not Material",
    // Translations for TableCells
    cells: {
      value_exceeds_threshold: "This value exceeds the threshold of {{threshold}}.",
    },
    // IRO List
    title_iro_overview_list: "Impacts, Opportunities and Risks",
  },
  // Common DMA Components
  iros_overview_list_component: {
    table_header_title: "Titel",
    table_header_esrs_topic: "Topic",
    table_header_materiality_degree: "Materiality level",
    table_header_result: "Result",
    table_header_result_tooltip: "Short-Term Medium-Term Long-Term",
    table_header_type: "Type",
    iro_types: {
      impact: "Impact",
      opportunity: "Opportunity",
      risk: "Risk",
      ro_indefinable: "Opportunity/Risk",
    },
    iro_types_plural: {
      impact: "Impacts",
      opportunity: "Opportunities",
      risk: "Risks",
      ro_indefinable: "Opportunities/Risks",
    },
    // Notices
    notice_no_iros_at_all: "No impacts, opportunities or risks recorded.",
    notice_no_iros_for_filters:
      "No impacts, opportunities or risks were found for the set filters.",
  },
  fill_in_helper_iros_list_component: {
    title: "Impacts, Opportunities or Risks",
    filter_type: "Filter",
    type_hints: {
      "only-material": "Only material impacts, opportunities and risks are being shown",
      "topic-specific":
        "Only impacts, opportunities and risks related to the current topic are listed",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_common: {
    header_stakeholder: "Stakeholder",
    header_organization: "Organization",
    header_material: "Impacts",
    header_financial: "Opportunities/Risks",
    title_material: "Impacts",
    title_financial: "Opportunities/Risks",
    tooltip_feedback_not_requested: "Feedback not requested",
    tooltip_feedback_accepted: "Accepted",
    tooltip_feedback_accepted_with_comment: "Accepted with comment",
    tooltip_feedback_changes_requested: "Not accepted, changes requested",
    tooltip_feedback_pending: "Pending feedback",
    notice_feedback_pending: "No feedback received yet",
    tooltip_copy_link: "Copy the stakeholder's access-link for the feedback request",
    // Warnings
    category_open_warning:
      "The topic is neither marked as not relevant nor have impacts or opportunities/risks been captured. " +
      "If you request stakeholder feedback now, it may distort your results and have legal consequences for you.",
    financial_effects_drafts_warning:
      "Not all opportunities/risks have been released yet! If you request " +
      "stakeholder feedback now, it may distort your results and have legal consequences for you.",
    material_impacts_drafts_warning:
      "Not all impacts have been released yet! If you request " +
      "stakeholder feedback now, it may distort your results and have legal consequences for you.",
  },
  dma_category_stakeholder_feedback_dialog: {
    title: "Stakeholder Feedback for Topic {{name}}",
    header_validation: "Validate Feedback",
    button_validate_material: "Complete Impacts",
    button_validate_financial: "Complete Opportunities/Risks",
    material_verified: "Impacts Completed",
    financial_verified: "Opportunities/Risks Completed",
    tooltip_undo_verified: "Undo Completion",
    tooltip_steps_to_verify_material:
      'Set all impacts (including in subtopics) to "Released" and receive feedback from at least one stakeholder, ' +
      "or provide manual feedback to complete impacts.",
    tooltip_steps_to_verify_financial:
      'Set all opportunities and risks (including in subtopics) to "Released" and receive feedback from at least one stakeholder, ' +
      "or provide manual feedback to complete impacts.",
    header_feedback: "Stakeholder Feedback",
    title_validation_help: "Explanation of completing Feedback",
    content_validation_help:
      "Once you have received feedback from at least one stakeholder for both impacts and opportunities/risks, " +
      "or provided manual feedback, and all impacts and opportunities/risks are released, you can complete the topic " +
      "in the respective area. " +
      "The topic and all subtopics will then be completed and cannot be edited further. Only when you have completed " +
      "all topics can you complete the double materiality.",
    empty_notice: "No feedback requested yet",
    button_request_feedback: "Request Feedback",
    header_manual_feedback: "Manual Feedback",
    manual_feedback_empty_notice: "No manual feedback provided yet",
    button_add_manual_feedback: "Add Manual Feedback",
  },
  dma_category_stakeholder_feedback_info_dialog: {
    title: "Stakeholder Explanation",
    content_intro:
      "Please consider representatives from all stakeholder groups that you consider relevant for this " +
      "topic. A list of typical stakeholder groups:",
    item_employees: "Employees and other workers",
    item_suppliers: "Suppliers",
    item_consumer: "Consumers",
    item_customers: "Customers",
    item_end_users: "End users",
    item_local_communities: "Local communities",
    item_protection_needing_groups: "Protection needing groups",
    item_ngos: "NGOs",
    item_authorities:
      "Authorities, including regulatory authorities, supervisory authorities, and central banks",
    item_investors: "Existing and potential investors",
    item_scientific_constitutions: "Scientific institutions",
    item_creditors: "Creditors and other lenders, including asset managers",
    item_credit_institutions: "Credit institutions",
    item_insurers: "Insurance companies",
    item_nature: "Nature can be included as a silent stakeholder through environmental data",
  },
  dma_category_stakeholder_feedback_request_dialog: {
    title: "Request Feedback",
    no_stakeholders_notice: "No stakeholders created for organization yet.",
    tooltip_request_feedback: "Request Feedback",
    tooltip_revoke_feedback_request: "Revoke Request",
    tooltip_feedback_delivered: "Feedback received - cannot be revoked",
    button_manage_stakeholders: "Manage Stakeholders",
    no_request_due_to_verified_material:
      "Feedback for impacts cannot be requested as they are already completed.",
    no_request_due_to_verified_financial:
      "Feedback for opportunities and risks cannot be requested as they are already completed.",
  },
  dma_stakeholder_feedback_view: {
    prefix_last_changed: "Last Changed:",
    prefix_assessment: "Assessment:",
    prefix_comment: "Comment:",
  },
  // Manual Stakeholder-Feedback
  manual_stakeholder_feedback_common: {
    type_material: "Impacts",
    type_financial: "Opportunities/Risks",
    source_none: "No Source",
    source_interview: "Interview",
    source_workshop: "Workshop",
    source_survey: "Survey",
    source_discussion: "Discussion",
  },
  dma_category_manual_feedback_list: {
    header_stakeholder: "Stakeholder",
    header_type: "Feedback Type",
    header_source: "Source",
    title_confirm_delete_dialog: "Delete Manual Feedback?",
    content_confirm_delete_dialog: "Are you sure you want to delete the manual feedback?",
    title_feedback: "Feedback",
  },
  dma_category_manual_feedback_edit_dialog: {
    title_create: "Create Manual Feedback",
    title_edit: "Edit Manual Feedback",
    label_feedback_type: "Feedback Type",
    label_feedback_source: "Source",
    label_stakeholders: "Stakeholders:",
    error_stakeholders_required: "Please specify at least one stakeholder.",
    no_stakeholders_selected_notice: "No stakeholders selected",
    label_feedback: "Feedback",
    error_feedback_required: "Please provide feedback.",
    button_manage_stakeholders: "Manage Stakeholders",
    no_stakeholders_available: "No stakeholders available",
  },
  dma_language_picker_component: {
    title: "Double Materiality Assessment",
    subtext:
      "Before you can start with the DMA, you have to pick a language in which all content will be written:",
    button: "Continue",
    confirm: {
      title: "Set language?",
      content:
        'Are you sure you want to set the language to "{{language}}"?<br/>' +
        "<br/>" +
        "<strong>The selected language CANNOT be changed once it is set!</strong>",
    },
  },
  // Content Translation
  content_language_select_component: {
    label: "Language",
    translationsPerLanguage: {
      [IContentLanguage.De]: "German",
      [IContentLanguage.En]: "English",
    } as { [key in IContentLanguage]: string },
  },
  // Audit Logging
  audit_logging_configuration: {
    title: "Activity Protocol",
    empty_notice: "No audit logs found.",
    header_name: "Name",
    header_organization: "Organization",
  },
  audit_logging_edit_list_component: {
    headers: {
      timeStamp: "Timestamp",
      userName: "Username",
      stakeholderName: "Stakeholder name",
      action: "Action",
    },
    events: {
      recording_period_create: "A new recording period has been created.",
      recording_period_update: "A recording period has been edited.",
      recorded_esrs_topic_opt_in: "Recording has started for the topic.",
      recorded_esrs_topic_opt_out: "Recording for this topic has been skipped.",
      dma_configuration_update: "DMA configuration has been edited.",
      dma_category_create: "A DMA category has been created.",
      dma_category_update: "A DMA category has been edited.",
      dma_category_delete: "A DMA category has been deleted.",
      dma_category_opt_in: "Recording has started for the category.",
      dma_category_opt_out: 'This category has been marked as "not essential".',
      stakeholder_feedback_request_for_category: "Feedback has been requested for this category.",
      stakeholder_feedback_revoke_request_for_category:
        "Feedback request has been revoked for this category.",
      dma_category_verify_material: "Impacts have been verified for this category.",
      dma_category_verify_financial: "Opportunities/risks have been verified for this category.",
      dma_category_unverify_material: "Impact verification has been removed for this category.",
      dma_category_unverify_financial:
        "Opportunity/risk verification has been removed for this category.",
      dma_material_impact_create: "A new impact has been created.",
      dma_material_impact_update: "An impact has been edited.",
      dma_material_impact_delete: "An impact has been deleted.",
      dma_material_impact_state_update: "The status of the impact has been changed.",
      dma_financial_effect_create: "A new opportunity/risk has been created.",
      dma_financial_effect_update: "An opportunity/risk has been edited.",
      dma_financial_effect_delete: "An opportunity/risk has been deleted.",
      dma_financial_effect_state_update: "The status of the opportunity/risk has been updated.",
      manual_stakeholder_feedback_create: "Manual feedback has been created for the category.",
      manual_stakeholder_feedback_update: "Manual feedback has been edited.",
      manual_stakeholder_feedback_delete: "Manual stakeholder feedback delete",
      dma_stakeholder_submit_feedback: "A stakeholder has submitted their feedback.",
      dma_stakeholder_update_feedback: "A stakeholder has edited their feedback.",
      dma_stakeholder_create: "A new stakeholder has been created.",
      dma_stakeholder_update: "A stakeholder has been edited.",
      dma_stakeholder_delete: "A stakeholder has been deleted.",
      organization_structure_create: "A new organizational structure has been created.",
      organization_structure_update: "An organizational structure has been edited.",
      data_entry_object_create: "A new data entry object has been created.",
      data_entry_object_update: "A data entry object has been edited.",
      root_data_entry_object_create: "A new root data entry object has been created.",
      data_entry_object_input_parameter_create:
        "A new data entry parameter has been created for a data entry object.",
    },
  },
  audit_logging_comparison_view_component: {
    headers: {
      before: "Before",
      after: "After",
    },
    no_data_available: "No data available.",
  },
  audit_logging_expand_component: {
    changelog: "Changelog",
    headers: {
      value_name: "Value Name",
      before_value: "Before",
      after_value: "After",
    },
  },
  // Stakeholder
  stakeholders_configuration: {
    title: "Stakeholders",
    empty_notice: "No stakeholders created for organization yet.",
    header_name: "Name",
    header_organization: "Organization",
    button_create_stakeholder: "Add Stakeholder",
    button_create: "Create",
    delete_dialog_cancel: "$t(buttons:cancel)",
    delete_dialog_confirm: "$t(buttons:delete)",
    delete_dialog_title: "Delete Stakeholder?",
    delete_dialog_body:
      "Are you sure you want to delete the stakeholder? This action cannot be undone.",
  },
  stakeholder_edit_dialog: {
    title_create: "Create Stakeholder",
    title_edit: "Edit Stakeholder",
    label_name: "Name",
    error_name_required: "Please enter a name.",
    label_organization: "Organization (optional)",
    create_stakeholder: "Create Stakeholder",
  },
  // Distribution Criteria
  distribution_criteria_configuration: {
    title: "Distribution Criteria",
    empty_notice: "No distribution criteria configured for organization yet.",
    header_name: "Name",
    button_create_stakeholder: "Add Distribution Criteria",
    button_create: "Create",
    delete_dialog_cancel: "$t(buttons:cancel)",
    delete_dialog_confirm: "$t(buttons:delete)",
    delete_dialog_title: "Delete Distribution Criteria?",
    delete_dialog_body:
      "Are you sure you want to delete the distribution criteria? This action cannot be undone.",
  },
  distribution_criteria_edit_dialog: {
    title_create: "Create Distribution Criteria",
    title_edit: "Edit Distribution Criteria",
    label_name: "Name",
    error_name_required: "Please enter a name.",
  },
  // Applied Distribution criteria
  applied_distribution_criteria_configuration_page: {
    title: "Applied Distribution Criteria",
  },
  applied_distribution_criteria_configuration_component: {
    title: "Applied Distribution Criteria",
    dropdowns: {
      recording_period_dropdown: {
        recording_period_select_label: "Recording Period",
      },
      distribution_criterion_dropdown: {
        label: "Distribution Criteria",
      },
      organization_structure_is_draft_notice:
        "The organization structure is currently a draft. You must publish the organization structure " +
        "before applying distribution criteria.",
    },
    no_criteria_configured: {
      text: "No distribution criteria configured for the organization.",
      button_text: "Configure Now",
    },
    configure_criterion: {
      text: "This distribution criteria has not been configured for the selected recording period yet.",
      button_text: "Configure Now",
    },
    alert_info: {
      body: "Changes will be automatically saved when leaving the input field!",
      info_already_applied:
        "The distribution criteria has already been applied. This means that values can still be modified but cannot be completely removed.",
    },
  },
  applied_distribution_criteria_table_component: {
    buttons: {
      expand_all: "Expand All",
      collapse_all: "Collapse All",
      remove_configuration: "Remove Configuration",
      // Apply
      apply_configuration: "Apply Configuration",
      tooltip_not_populated:
        "The configuration cannot be applied yet as not all values have been entered.",
      tooltip_populated: "Apply Configuration",
      // Remove application
      remove_application: "Remove Application",
      tooltip_remove_application: "Remove Application",
      tooltip_remove_application_disabled:
        "The application cannot be removed as values have already been entered based on it.",
    },
    table_header: {
      data_entry_object_name: "Name",
      value: "Value",
    },
    confirm_dialog: {
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "$t(buttons:confirm)",
      title: "Remove Configuration?",
      body: "Are you sure you want to remove the configuration? All values will also be deleted. This action cannot be undone.",
    },
  },
  applied_distribution_criteria_table_row_component: {},
  applied_distribution_criteria_table_cell_component: {
    error_required: "Since the criteria has been applied, a value must be entered.",
  },
  // Sources
  sources: {
    source_type: {
      data_source: "Data Source",
      calculation_source: "Calculation Source",
      methodology_source: "Methodology Source",
    },
  },
  sources_configuration_page: {
    title: "Sources",
  },
  sources_configuration_component: {
    empty_notice: "No sources created for the organization yet.",
    buttons: {
      create: "Create",
    },
    confirm: {
      title: "Remove Source?",
      body: "Are you sure you want to remove this source? This action cannot be undone!",
    },
  },
  sources_edit_dialog: {
    title_create: "Create Source",
    title_edit: "Edit Source",
    labels: {
      name: "Name",
      description: "Description or link to source",
      source_type: "Source Type",
    },
    errors: {
      name_required: "Please enter a name",
      source_type_required: "Please choose an option",
    },
  },
  sources_list_component: {
    headers: {
      name: "Name",
      description: "Description",
      source_type: "Source Type",
    },
    warning_icon:
      "This source cannot currently be assigned to a data point because it does not contain a description or no files have been uploaded.",
  },
  sources_files_dialog: {
    title: "Edit Files",
    name: "File",
    type: "File type",
    delete_title: "Remove file from source {{sourceName}}?",
    delete_body: "Are you sure you want to remove this file? This action cannot be undone!",
    upload_file: "Upload",
    no_files_uploaded: "No files have been uploaded yet.",
  },
  // DMA Categories
  dma_category_delete_confirm_dialog: {
    title: 'Delete Topic "{{name}}"?',
    content_text: 'Are you sure you want to delete the topic "{{name}}"?',
    button_cancel: "Cancel",
    button_delete: "Delete",
  },
  dma_category_edit_dialog: {
    title_create: "Create New Topic",
    title_edit: "Edit Topic {{name}}",
    delete_tooltip: "Remove Topic",
    cannot_delete_tooltip:
      "A topic can only be deleted if it has no subtopics, impacts, risks, or opportunities, " +
      "and if no feedback has been requested or manually provided yet.",
    error_name_required: "Please enter a name.",
    name_label: "Name",
    button_cancel: "Cancel",
    button_create: "Create",
    button_save: "Save",
  },
  dma_category_opt_out_dialog: {
    title_opt_out: "Mark this topic as not relevant?",
    title_edit: "Update Reason",
    content_text:
      'Are you sure you want to mark the topic "{{name}}" and all its subtopics as not relevant? ' +
      'Provide a reason for why "{{name}}" and its subtopics are not relevant for your organization. ' +
      "You can change your decision at any time.",
    label_opt_out_reason: "Reason",
    error_opt_out_reason_required: "Please provide a reason.",
    button_cancel: "Cancel",
    button_opt_out: "Confirm",
    button_edit: "Save",
  },
  dma_category_opt_back_in_confirm_dialog: {
    title: "Reset Topic to Relevant?",
    content:
      // eslint-disable-next-line no-useless-concat
      'Are you sure you want to reset the topic "{{name}}" and all its subtopics ' + "to relevant?",
    button_confirm: "Reset",
  },
  // ESRS Topic
  dma_esrs_topic_component: {
    create_category_button: "Create Topic",
    create_category_button_tooltip:
      'Create a new topic under the parent topic "{{esrsTopicName}}".',
  },
  dma_esrs_topic_not_yet_recorded_component: {
    header: "Nothing has been recorded for this topic yet.",
    start_collection: "You can now either",
    start_collection_button: "Use Template",
    start_collection_button_tooltip:
      "Use standard template for recording impacts or opportunities/risks for this topic. " +
      "Alternatively, you can create topics yourself via the following button",
    skip_collection: "or",
    skip_collection_button: "Skip Impacts",
    skip_collection_button_tooltip: "Do not record impacts or opportunities/risks for this topic",
  },
  dma_esrs_topic_opted_out_component: {
    header: "You have opted out of recording for this topic.",
    opt_out_reason_prefix: "Reason:",
    start_collection_button: "Use Template",
    start_collection_button_tooltip:
      "Use standard template for recording impacts or opportunities/risks for this topic. " +
      "Alternatively, you can create topics yourself via the following button",
    edit_opt_out_reason_button_tooltip: "Edit Reason",
  },
  // Tabular Data
  tabular_input_component: {
    header_dynamic_table: "Actions",
    header_required_tooltip: "Values in this column must be filled in.",
  },
  dynamic_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Add New Row",
      button_add_row: "Add",
      tooltip_save: "Save Changes",
      button_save: "Save",
    },
    row: {
      tooltip_remove_row: "Remove Row",
      tooltip_save_row: "Save Row",
      tooltip_undo_row: "Undo Row",
      tooltip_add_note: "Add Note",
      tooltip_remove_note: "Remove Note",
    },
  },
  static_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Add New Row",
      button_add_row: "Add",
      tooltip_save: "Save Table",
      tooltip_reset_table: "Reset Table",
      button_save: "Save",
      tooltip_remove_note: "Remove Note",
      tooltip_add_note: "Add Note",
    },
    confirm_dialog: {
      title: "Reset Table?",
      body: "Are you sure you want to reset the table? All data will be lost. This action cannot be undone.",
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "Delete",
    },
    row: {
      tooltip_undo_row: "Undo Row",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_overview_component: {
    title: 'Feedback for "{{name}}"',
    subtitle_no_rps:
      'No recording periods have been configured yet for the organization "{{organizationName}}". Therefore, submitting feedback is not possible yet.',
  },
  stakeholder_feedback_details_component: {
    select_title: "Recording Period:",
  },
  stakeholder_feedbacks_component: {
    no_feedback_requested: "No feedback has been requested for the chosen recording period yet.",
  },
  stakeholder_feedback_category_component: {
    material_impacts_header: "Material Impacts",
    financial_effects_header: "Financial Effects",
    button_add_feedback: "Provide Feedback",
    button_edit_feedback: "Edit Feedback",
    alert_not_yet_shared: {
      title: "Topic Not Yet Released",
      content:
        "The topic has not yet been released, so there may still be changes in the data " +
        "after you provide your feedback. Please confirm the data status with your contact person " +
        "before providing feedback.",
    },
    alert_category_open: {
      title: "Topic Not Yet Recorded",
      content: "No impacts or financial effects have been recorded for this topic yet.",
    },
    alert_no_iro_material: "No impacts have been identified for this topic.",
    alert_no_iro_financial: "No financial effects have been identified for this topic.",
    category_verified_notice_material: "Material impacts completed - feedback cannot be changed.",
    category_verified_notice_financial: "Financial effects completed - feedback cannot be changed.",
    feedback_indicator: {
      status_submitted: "Feedback Provided",
      "status_not-submitted": "Feedback Pending",
      "status_parent-submitted": "Topic has been considered in the feedback of the parent topic.",
      "status_parent-not-submitted":
        "Topic needs to be considered in the feedback of the parent topic.",
    },
  },
  stakeholder_feedback_dialog: {
    title_material: "Feedback for Material Impacts - {{name}}",
    title_financial: "Feedback for Financial Effects - {{name}}",
    label_agreed: "I agree with the assessment.",
    label_feedback: "Feedback",
    label_assessment: "Assessment",
    label_add_divergent_assessment: "Propose Alternative Assessment",
    explanation_material_assessment: "How do you assess this impact?",
    explanation_financial_assessment: "How do you assess this opportunity / risk?",
    // Slider values
    slider_value_very_low: "Very Low",
    slider_value_low: "Low",
    slider_value_average: "Average",
    slider_value_high: "High",
    slider_value_very_high: "Very High",
    // Buttons
    button_cancel: "$t(buttons:cancel)",
    button_save: "$t(buttons:save)",
    button_create: "Provide Feedback",
    button_close: "$t(buttons:close)",
    // Validation
    error_at_least_one_form_of_feedback_required:
      "At least one form of feedback (feedback or divergent assessment) must be provided.",
    // Materiality details
    materiality_materiality_degree_label: "Materiality Level",
    materiality_material_label: "Material?",
    material_yes: "$t(dma_common:materiality_true_state)",
    material_no: "$t(dma_common:materiality_false_state)",
    materiality_opt_out_title: "Topic Marked as Not Material:",
    materiality_tooltip_materiality_degree: "Materiality Level (Maximum Value: 5)",
  },
  // Reference Budgets
  reference_budgets_page: {
    title: "Reference Budgets",
  },
  reference_budgets_component: {
    empty_notice: "No reference budgets have been created for the organization.",
    buttons: {
      create: "Create",
    },
    confirm: {
      title: "Remove Reference Budget?",
      body: "Are you sure you want to remove this reference budget? This action cannot be undone.",
    },
    refresh: {
      title: "Refresh Data for Reference Budget?",
      body: "Are you sure you want to refresh the data for this reference budget? The old data will be overwritten and will no longer be accessible!",
    },
  },
  reference_budgets_edit_list_component: {
    headers: {
      name: "Name",
      values_fetched_at: "Values fetched at",
      reference_year: "Reference Year",
      reference_revenue: "Reference Revenue",
      budget_per_year: "Annual Budget",
    },
    action_tooltips: {
      edit: "Edit Reference Budget",
      delete: "Delete Reference Budget",
      refresh: "Refresh Data for Reference Budget",
    },
  },
  reference_budget_edit_dialog: {
    title_create: "Create Reference Budget",
    title_edit: "Edit Reference Budget",
    labels: {
      name: "Name",
      recording_periods: "Recording Periods for Reference Budget",
      reference_revenue: "Reference Revenue",
      reference_year: "Reference Year",
    },
    errors: {
      name_required: "Please enter a name.",
      recording_periods_required: "At least one recording period must be selected.",
    },
    notices: {
      recording_periods_have_changed:
        "The recording periods have been adjusted - therefore the budget will be recalculated. This cannot be undone!",
    },
  },
  // Target Paths
  target_paths_configuration_page: {
    title: "Target Paths",
  },
  target_paths_configuration_component: {
    empty_notice: "No target paths have been created for the organization.",
    notice_no_reference_budget: {
      text: "You must create at least one reference budget before configuring target paths.",
      button_text: "Go to Reference Budgets",
    },
    subheaders: {
      current_organization_path: "Set Corporate Target Path",
      paths_in_progress: "Target Paths in Progress",
    },
    buttons: {
      create: "Create",
    },
    tooltips: {},
  },
  target_path_dialogs: {
    confirm: {
      title: "Remove Target Path?",
      body: "Are you sure you want to remove this target path? This action cannot be undone.",
    },
    confirm_toggle: {
      for_state: {
        [ITargetPathState.Committed]: {
          title: "Downgrade Target Path to Draft?",
          body: "Are you sure you want to downgrade the target path to draft? This action can be undone at any time.",
        },
        [ITargetPathState.Draft]: {
          title: "Set Target Path as Corporate Target Path?",
          body_1:
            'Are you sure you want to set "{{targetPathName}}" as the organizational target path?',
          body_2: 'This action will downgrade the following paths to "Draft":',
        },
      },
    },
  },
  target_path_progress_component: {
    header: "Progress",
    labels: {
      recording_structures_for_evaluation: "Recording Structures for Evaluation",
    },
  },
  target_paths_edit_list_component: {
    headers: {
      name: "Name",
      reference_budget_name: "Selected Reference Budget",
      state: "Status",
    },
    action_tooltips: {
      edit: "Edit Target Path",
      edit_disabled:
        "The target path cannot be edited anymore as it has already been set / goals have already been achieved.",
      delete: "Delete Target Path",
      delete_disabled:
        "The target path cannot be deleted anymore as it has already been set / goals have already been achieved.",
      commit: "Set as Corporate Target Path",
      commit_disabled:
        "This path cannot be set as an organizational path as no goals have been set!",
      cease: "Downgrade to Draft",
      clone: "Clone Target Path",
    },
  },
  target_path_actions_component: {
    action_tooltips: {
      edit: "Edit Target Path",
      edit_disabled:
        "The target path cannot be edited anymore as it has already been set / goals have already been achieved.",
      delete: "Delete Target Path",
      delete_disabled:
        "The target path cannot be deleted anymore as it has already been set / goals have already been achieved.",
      commit: "Set as Corporate Target Path",
      commit_disabled:
        "This path cannot be set as an organizational path as no goals have been set!",
      cease: "Downgrade to Draft",
      clone: "Clone Target Path",
    },
  },
  target_path_edit_dialog: {
    title_create: "Create Target Path",
    title_edit: "Edit Target Path",
    labels: {
      name: "Name",
      reference_budget: "Reference Budget for Target Path",
      use_template: "Use Template for Goals?",
      template: "Goal Template",
    },
    errors: {
      name_required: "Please enter a name.",
      name_unique: "The name must be unique within the organization.",
    },
    tooltips: {
      use_template:
        "Example goals will be automatically generated to assist you in the configuration.",
    },
    goals: {
      [ITargetPathGoalTemplateType.ParisConform]: "Paris-Compliant Standard Target Path",
    },
  },
  target_path_clone_dialog: {
    title: "Clone Target Path",
    labels: {
      name: "New Name",
    },
    errors: {
      name_required: "Please enter a name.",
      name_unique: "The name must be unique within the organization.",
    },
  },
  target_path_state_chip_component: {
    text_by_state: {
      [ITargetPathState.Draft]: "Draft",
      [ITargetPathState.Committed]: "Set",
      [ITargetPathState.Fulfilled]: "Fulfilled",
    },
    tooltip_text_by_state: {
      [ITargetPathState.Draft]:
        "This target path is still in the development stage. Changes can be made at any time.",
      [ITargetPathState.Committed]:
        "This target path has been chosen as a direction for the organization and therefore cannot be edited.",
      [ITargetPathState.Fulfilled]:
        "The goals derived from this path have already been achieved. It cannot be edited anymore.",
    },
  },
  target_path_is_committed_info_component: {
    title: "Info",
    text: "This target path has been set as the corporate target path. Therefore, it cannot be edited.",
  },
  // Target Path Goals
  target_path_details_component: {
    title: 'Target Path "{{targetPathName}}"',
  },
  target_path_goals_edit_list_component: {
    title: "Goals",
    headers: {
      target_year: "Target Year",
      reduction_to_reference_year: "Reduction/Increase to Reference Year",
      emissions_in_target_year: "Emissions in Target Year (Total)",
    },
    buttons: {
      create: "Add",
    },
    confirm: {
      title: "Remove Goal?",
      body: "Are you sure you want to remove this goal? This action cannot be undone.",
    },
    action_tooltips: {
      edit: "Edit Goal",
      delete: "Delete Goal",
    },
  },
  target_path_goal_edit_dialog_component: {
    title_create: "Create Goal",
    title_edit: "Edit Goal",
    labels: {
      target_year: "Target Year",
    },
    errors: {
      date: {
        minDate:
          "The selected target year cannot be before the reference year ({{referenceYear}}).",
        targetForYearAlreadyDefined:
          "A goal for the target year {{targetYear}} has already been set.",
      },
    },
  },
  target_path_goal_edit_component: {
    toggle: {
      text_absolute: "Absolute",
      text_relative: "Relative",
    },
    confirm_dialog: {
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "$t(buttons:confirm)",
      title: "Change Recording Method?",
      body: "Are you sure you want to change the recording method? All values entered so far will be deleted. This action cannot be undone.",
    },
  },
  target_path_goal_value_change_input_component: {
    labels: {
      change: "Reduction",
      change_type: "Change Type",
    },
  },
  absolute_target_path_goal_edit_component: {
    translations: {
      in_percent: "Reduction in [%]",
      by_value: "Reduction by [tCO\u2082eq]",
      to_value: "Reduction to [tCO\u2082eq]",
    },
    helper_text: "Reference Budget: {{referenceBudget}}",
    helper_text_no_value: "No reference budget available",
    scopes: {
      scope_reductionScope1: "Reduction Scope 1",
      scope_reductionScope2: "Reduction Scope 2",
      scope_reductionScope3: "Reduction Scope 3",
    },
    tooltips: {
      reduction_as_percent: "This corresponds to a {{reductionTerm}} of {{percentage}} %.",
      reduction_as_by_value: "This corresponds to a {{reductionTerm}} of {{value}} t CO\u2082eq.",
      no_value: "No value can be entered as there is no reference budget for this scope.",
    },
  },
  relative_target_path_goal_edit_component: {
    translations: {
      in_percent: "Annual Revenue Growth in [%]",
      by_value: "Growth by [€]",
      to_value: "Expected Revenue in Target Year",
    },
    headings: {
      revenue: "Revenue",
      intensity: "Intensity",
    },
    revenue: {
      textfield_label_growth: "Revenue Growth",
      textfield_label_absolute: "Target Revenue",
      helper_text: "Reference Revenue: {{referenceRevenue}}",
      tooltip_dropdown_no_revenue:
        "Only a concrete revenue target (and no change) can be entered as no reference revenue has been set.",
    },
  },
  target_intensity_input_component: {
    helper_text_no_value: "No reference budget available",
    tooltip_no_value: "No value can be entered as there is no reference budget for this scope.",
    tooltip_value:
      "This corresponds to emissions of {{absoluteValue}} t CO\u2082eq or a {{reductionTerm}} of {{relativeReduction}} %.",
    scopes: {
      intensityScope1: "Target Intensity Scope 1",
      intensityScope2: "Target Intensity Scope 2",
      intensityScope3: "Target Intensity Scope 3",
    },
  },
  reference_intensity_component: {
    content: "The {{textSum}} reference intensity is: {{referenceIntensity}} tCO\u2082eq / €.",
  },
  target_path_diagram_component: {
    title: "Diagram",
    title_for_organization_path: "Corporate Target Path",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    } as const,
  },
  target_path_general: {
    terms: {
      reduction: "Reduction",
      increase: "Increase",
    },
  },
  target_path_progress_diagram_component: {
    title_for_organization_path: "Corporate Target Path",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    },
  },
  // Input Components
  list_value_input_component: {
    empty_option: "No Value",
  },
  nested_options_input_component: {
    buttons: {
      close: "Close",
      clear: "Reset choice",
    },
    search: {
      placeholder: "Search...",
    },
  },
  // Input Validation
  value_validation_errors: {
    required: "Please enter a value for this field.",
    number: {
      base: "Please enter a number.",
      precision: "The value can have at most {{limit}} decimal places.",
      min: "The value must be greater than or equal to {{limit}}.",
      max: "The value must be less than or equal to {{limit}}.",
      unsafe: "The value must be a safe number.",
    },
    boolean: {
      base: "Please select a value.",
    },
    string: {
      empty: "Please enter some text.",
    },
    array: {
      includesRequiredUnknowns: "At least one option must be selected.",
      sparse: "Please enter a value for every field.",
    },
    date: {
      format: "Please enter a valid date.",
      min: "The date must be after {{limit}}.",
      max: "The date must be before {{limit}}.",
      greater: "The start date must be before the end date.",
    },
    any: {
      only: "Please select an option.",
    },
  },
  number_input: {
    placeholder: "Enter Number",
  },
  currency_input: {
    placeholder: "Enter Amount",
  },
  text_input: {
    placeholder: "Enter Text",
  },
  boolean_input: {
    value_true: "Yes",
    value_false: "No",
  },
  multi_text_input_component: {
    add_entry: "Add Entry",
    remove_entry: "Remove Entry",
  },
  date_input_component: {
    remove_date_tooltip: "Remove Date",
  },
  // Intercom
  intercom_reference_widget_component: {
    tooltip: "Open help",
  },
  // Errors
  error: {
    axios: {
      connection: "Connection error - Please check your internet connection",
    },
    generic: {
      "400": "Faulty request",
      "401": "Unauthorized",
      "403": "No permission",
      "404": "Not found",
      "409": "Conflict",
      "500": "Internal server error",
      "502": "API not available",
    },
    general: {
      entity_not_found: "Not found.",
      internal_server_error: "Internal server error.",
    },
    auth: {
      unauthorized:
        "Your session has expired or your credentials are invalid. Please log in again.",
    },
    input_parameter: {
      not_found: "The value could not be found.",
    },
    organization: {
      not_found: "The organization could not be found.",
    },
    recording_period: {
      not_found: "The recording period could not be found.",
      previous_structure_is_draft:
        "The previous organizational structure is a draft. It must be published before a new recording period can be created.",
      value_would_move_outside_scope:
        "Values have already been specified for the recording period outside the new period!",
      overlaps_with_existing_recording_period:
        "The specified period overlaps with an existing recording period!",
    },
    recording_model: {
      not_found: "The recording model could not be found.",
    },
    recording_model_templates_group: {
      not_found: "The topic could not be found.",
    },
    input_parameter_recording_structure_template: {
      not_found: "The template could not be found.",
    },
    input_parameter_recording_structure: {
      not_found: "The recording structure could not be found.",
      input_parameter_used_more_than_once:
        "A value can only be recorded once within the recording structure!",
      input_parameter_not_found:
        "At least one unknown value for recording was specified in the recording structure!",
      order_in_use:
        "The specified order of the recording structure within the recording model is already in use!",
    },
    organization_structure: {
      not_found: "The organization structure could not be found.",
      not_yet_committed: "The organization structure has not yet been published.",
      already_committed: "The draft has already been published.",
      duplicate_ids:
        "Unexpected error! The values of a past data entry object are inherited by multiple " +
        "new data entry objects!",
      id_doesnt_belong_to_previous_structure:
        "Unexpected error! An attempt was made to inherit an data entry object that was not " +
        "part of the previous organizational structure!",
    },
    input_parameter_recording_method: {
      not_found: "Recording method used for value not found.",
    },
    data_entry_object: {
      not_found: "The data object could not be found.",
      next_already_set:
        "The data object is already being used by another data object as a predecessor (recorded " +
        "values are inherited).",
    },
    data_entry_object_input_parameter_value: {
      bad_value: "Faulty value!",
      value_already_recorded_for_period:
        "Value has already been recorded for the given recording period.",
      not_found: "Value is not recorded for the given data entry object.",
      value_outside_recording_period:
        "The date for the value is outside the recording period for which it was recorded!",
    },

    dma_category: {
      not_found: "The topic could not be found.",
      has_children: "Action not possible: The topic has subordinate topics!",
      has_associated_financial_effects:
        "Action not possible: The topic has subordinate opportunities and risks!",
      has_associated_material_impacts: "Action not possible: The topic has subordinate impacts!",
      opt_out_of_category_with_iros:
        "Opt out not possible: The topic has impacts, opportunities, or risks subordinate!",
      opt_out_without_reason: "Marking as not relevant without reason is not possible",
      opt_in_with_reason: "Undo marking as not relevant with reason is not possible",
      has_opted_out: "Already marked as not relevant",
      no_feedback_found: "No stakeholder feedback found",
      in_invalid_state: "Status of the topic for completion is invalid",
      already_verified: "Topic is already completed",
    },
    dma_effect: {
      reason_was_not_provided: "",
      reason_was_provided_without_override: "",
    },
    dma_financial_effect: {
      not_found: "Opportunity/Risk not found",
      not_responsible_user: "Your user is not responsible for the opportunity/risk",
      already_published: "Opportunity/Risk is already released and cannot be changed anymore",
      incomplete_for_shared:
        "To release an opportunity/risk, you must provide an explanation of the assessment.",
      referenced_in_policies:
        "The risk/chance is referenced in a policy. Therefore, its state cannot be changed back to 'draft'.",
    },
    dma_material_impact: {
      not_found: "Impact not found.",
      not_responsible_user: "No responsible person specified for the impact.",
      already_published: "Already released.",
      incomplete_for_shared:
        "To release an opportunity/risk, you must provide an explanation of the assessment.",
      referenced_in_policies:
        "The impact is referenced in a policy. Therefore, its state cannot be changed back to 'draft'.",
    },
    esrs_topic: {
      not_found: "ESRS topic not found",
      does_not_support_custom_categories:
        "Custom topics cannot be created for the selected ESRS topic",
    },
    recorded_esrs_topic: {
      opt_out_of_topic_with_categories:
        "Cannot set no impacts for this topic as sub-topics have already been created",
      does_not_exist: "The specified ESRS topic or the used data entry object does not exist",
      category_is_not_child: "The specified topic is not part of the specified ESRS topic",
      opt_out_without_reason: "To not select topics for an ESRS topic, you must provide a reason",
      opt_in_with_reason: "To undo topic selection, no reason must be provided",
    },
    dma_execution: {
      invalid_category: "Invalid topic",
      feedback_missing: "Feedback missing",
    },
    data_entry_object_input_parameter: {
      attribution_reason_not_present: "",
    },
    stakeholder: {
      not_found: "Stakeholder not found",
      has_associated_feedback:
        "The stakeholder could not be deleted because they have provided feedback.",
    },
    stakeholder_feedback: {
      not_found: "Stakeholder feedback not found",
      invalid:
        "Feedback invalid - either agreement and only one comment (without any other suggested " +
        "rating) or, if the rating is not released, at least one other suggested rating or a feedback " +
        "text must be provided.",
      already_submitted: "Feedback already submitted",
    },
    manual_stakeholder_feedback: {
      not_found: "Manual stakeholder feedback not found",
    },
    // Distribution Criterion
    distribution_criterion: {
      not_found: "Distribution criteria not found",
      already_in_use: "The distribution criteria is already in use and cannot be deleted.",
    },
    // Reference Budget
    reference_budget: {
      is_used_in_target_path:
        "The reference budget is used in a target path and therefore cannot be deleted.",
      not_all_target_paths_in_draft_state:
        "The budget is already referenced by target paths that have been set as corporate target paths. The calculated CO\u2082 budget cannot be changed anymore.",
    },
    unknown: "Unknown error",
  },
  not_found: {
    title: "This page was not found",
    go_to_dashboard: "Back to Home",
  },
  dma_categories: generateDMACategoryTranslations(IContentLanguage.En),
  esrs_topics: generateESRSTopicTranslations(IContentLanguage.En),
};
