import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, TableCell, TableCellProps, TableRow } from "@mui/material";
import {
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameterUIConfigurationColumnItemInput,
  IInputParameterValue,
} from "@netcero/netcero-core-api-client";
import { DataEntryObjectValueInputComponent } from "../data-entry-object-value-input.component";
import {
  DataEntryObjectInputParameterValueType,
  InputParameterValuesPerKey,
} from "@netcero/netcero-common";
import { DeleteIcon } from "../../../common/constants/tabler-icon.constants";
import {
  IValuesErrorsEntry,
  IValuesErrorsPerKey,
} from "../../utilities/data-entry-object-values.utilities";
import { useTranslateValueError } from "../../hooks/translate-values-errors.hooks";
import { RenderTableValueReadonly } from "../render-value-readonly/render-table-value-readonly.component";
import { Property } from "csstype";

function getCursorForInputType(type: DataEntryObjectInputParameterValueType): Property.Cursor {
  switch (type) {
    case "text":
      return "text";
    case "number":
      return "text";
    default:
      return "pointer";
  }
}

interface ICommonProps {
  editMode: boolean;
  disabled: boolean;
  onValueChange: (
    valueKey: string,
    newValue: IDataEntryObjectInputParameterValueValueForKey,
  ) => void;
  onStartEditing: VoidFunction;
}

interface IDataEntryObjectValuesInputTableEnteredValueRowProps extends ICommonProps {
  inputParameterValuesPerKey: InputParameterValuesPerKey;
  inputs: IInputParameterUIConfigurationColumnItemInput[];
  /** Ordered list of alignments for each input - same order as in `ìnputs` property */
  inputAlignments: TableCellProps["align"][];
  enteredValue:
    | ILocalDataEntryObjectInputParameterValue
    | ILocalDataEntryObjectInputParameterValueData;
  rowErrors: IValuesErrorsPerKey;
  onDelete: VoidFunction;
}

export const DataEntryObjectValuesInputTableEnteredValueRow: FC<
  IDataEntryObjectValuesInputTableEnteredValueRowProps
> = ({
  inputParameterValuesPerKey,
  inputs,
  inputAlignments,
  enteredValue,
  disabled,
  rowErrors,
  editMode,
  onDelete,
  onValueChange,
  onStartEditing,
}) => {
  return (
    <TableRow
      sx={{
        ":hover .table-row-actions-cell": {
          opacity: 1,
        },
      }}
    >
      {inputs.map((input, index) => (
        <RowCell
          input={input}
          alignment={inputAlignments[index]}
          config={inputParameterValuesPerKey[input.valueKey]}
          value={enteredValue.valuesPerKey[input.valueKey]}
          error={rowErrors[input.valueKey]}
          editMode={editMode}
          onStartEditing={onStartEditing}
          onValueChange={onValueChange}
          disabled={disabled}
        />
      ))}
      {/* Actions Cell */}
      <TableCell
        className="table-row-actions-cell"
        align="right"
        sx={{ opacity: editMode ? 1 : 0, transition: "all 150ms ease" }}
      >
        <IconButton size="small" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface IRowCellProps extends ICommonProps {
  input: IInputParameterUIConfigurationColumnItemInput;
  alignment: TableCellProps["align"];
  config: IInputParameterValue;
  value: IDataEntryObjectInputParameterValueValueForKey | undefined;
  error: IValuesErrorsEntry | undefined;
}

const RowCell: FC<IRowCellProps> = ({
  input,
  editMode,
  alignment,
  config,
  value,
  error,
  onStartEditing,
  onValueChange,
  disabled,
}) => {
  const translateValueError = useTranslateValueError();

  // Autofocus used when cell is clicked --> so input is automatically focused and ready to edit
  const [initialAutoFocus, setInitialAutoFocus] = useState(false);
  // Reset autofocus when immediately after the first rerender
  useEffect(() => {
    if (initialAutoFocus) {
      setInitialAutoFocus(false);
    }
  }, [initialAutoFocus]);

  const cursorType = useMemo(
    () =>
      config.valueConfiguration.type === "simple"
        ? getCursorForInputType(config.valueConfiguration.configuration.type)
        : undefined,
    [config.valueConfiguration],
  );

  const handleClickCell = useCallback(() => {
    if (!editMode) {
      setInitialAutoFocus(true);
      onStartEditing();
    }
  }, [editMode, onStartEditing]);

  if (config.valueConfiguration.type === "esrs-topic-phase-in") {
    return "Unsupported";
  }

  return (
    <TableCell
      key={input.valueKey}
      align={alignment}
      onClick={handleClickCell}
      sx={{
        cursor: cursorType,
        transition: "background-color 150ms ease",
        ":hover": !editMode
          ? {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            }
          : undefined,
      }}
    >
      {editMode ? (
        <DataEntryObjectValueInputComponent
          key={input.valueKey}
          variant="table"
          valueMetaData={config.valueConfiguration.configuration}
          value={value?.value}
          onChange={(value) => onValueChange(input.valueKey, { type: "simple", value })}
          // No labels for table
          required={config.required}
          disabled={disabled}
          error={error ? translateValueError(error) : undefined}
          autoFocus={initialAutoFocus}
        />
      ) : (
        <RenderTableValueReadonly
          valueMetaData={config.valueConfiguration.configuration}
          value={value?.value}
        />
      )}
    </TableCell>
  );
};
