import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IBaseDMAMaterialImpactData,
  IDataEntryObject,
  IDMACategoryState,
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IDMAConfiguration,
  IDMAMaterialImpact,
  IDMAMaterialImpactHorizonData,
  IESRSTopic,
  IHorizonsEnum,
  IIROState,
} from "@netcero/netcero-core-api-client";
import {
  DMAUtilities,
  MaterialImpactCalculator,
  MaterialImpactHorizonsCalculator,
} from "@netcero/netcero-dma";
import { FC, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { OptionsDialog } from "../../common/components/options.dialog";
import { DeleteIcon, IconSize, InfoIcon } from "../../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { OrganizationUserPicker } from "../../user/components/organization-user-picker.component";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";
import { useUserContext } from "../../user/user.context";
import { HORIZONS_VALUES, ORDERED_HORIZONS_VALUES } from "../common/dma-horizons.constants";
import {
  IInheritFromHorizonsState,
  IInheritFromHorizonsStateItem,
} from "../common/dma-horizons.interfaces";
import {
  DEFAULT_DMA_PROBABILITY_SLIDER_VALUE,
  DEFAULT_DMA_SLIDER_VALUE,
} from "../common/dma.constants";
import { EditDialogHorizonTabContent } from "../common/edit-dialog-horizon-tab-content.component";
import { IROStateIndicator } from "../common/iro-state-indicator.component";
import { IROStateSelect } from "../common/iro-state-select.component";
import { useIroEditIsDirtyHook } from "../common/use-iro-edit-is-dirty.hook";
import { IROAreasCheckboxesComponent } from "../common/iro-areas-checkboxes.component";
import { DMAMaterialImpactHorizonEditSection } from "./dma-material-impact-horizon-edit-section.component";
import { DMABreadcrumbsComponent } from "../common/dma-breadcrumbs.component";

export interface IInternalMaterialImpactData
  extends Omit<IBaseDMAMaterialImpactData, "horizons" | "responsibleUserId"> {
  responsibleUserId: string | null;
  horizons: {
    shortTerm: IInternalMaterialImpactHorizonData;
    mediumTerm: IInternalMaterialImpactHorizonData;
    longTerm: IInternalMaterialImpactHorizonData;
  };
}

export interface IInternalMaterialImpactHorizonData
  extends Omit<IDMAMaterialImpactHorizonData, "inheritsFromHorizon"> {
  inheritsFromHorizon: IHorizonsEnum | null;
  // Special/Internal Properties
  type: "actual" | "potential";
  result: "positive" | "negative";
  // Custom Materiality
  overrideMateriality: boolean;
}

const getDefaultValuesForHorizon = (
  horizonData?: IDMAMaterialImpactHorizonData | null,
  defaultInheritFromHorizon: IHorizonsEnum | null = null,
): IInternalMaterialImpactHorizonData => {
  return {
    inheritsFromHorizon: horizonData?.inheritsFromHorizon ?? defaultInheritFromHorizon,
    extent: horizonData?.extent ?? DEFAULT_DMA_SLIDER_VALUE, // Ausmaß der Auswirkung
    scope: horizonData?.scope ?? DEFAULT_DMA_SLIDER_VALUE, // Umfang der Auswirkung
    // Special/Internal Properties
    type: !horizonData ? "actual" : horizonData.probability ? "potential" : "actual",
    probability: horizonData?.probability ?? DEFAULT_DMA_PROBABILITY_SLIDER_VALUE,
    result: !horizonData ? "negative" : horizonData.irreversibility ? "negative" : "positive",
    irreversibility: horizonData?.irreversibility ?? DEFAULT_DMA_SLIDER_VALUE,
    hasNegativeEffectOnHumanRights: horizonData?.hasNegativeEffectOnHumanRights ?? false,
    // Values for override of automatic materiality
    overrideMateriality: horizonData?.userMaterialityReason !== undefined,
    materialityUser: horizonData?.materialityUser ?? false,
    userMaterialityReason: horizonData?.userMaterialityReason ?? "",
  };
};

const getDefaultValuesForInputs = (
  materialImpact?: IDMAMaterialImpact | null,
): IInternalMaterialImpactData => {
  const defaultInheritFromHorizon = !materialImpact ? IHorizonsEnum.ShortTerm : null;

  return {
    title: materialImpact?.title ?? "",
    responsibleUserId: materialImpact?.responsibleUserId ?? null,
    description: materialImpact?.description ?? "",
    assessmentExplanation: materialImpact?.assessmentExplanation ?? "",
    internalCommunication: materialImpact?.internalCommunication ?? "",
    horizonsComment: materialImpact?.horizonsComment ?? "",
    areas: materialImpact?.areas ?? [],
    associatedDataEntryObjects: materialImpact?.associatedDataEntryObjects ?? [],
    horizons: {
      shortTerm: getDefaultValuesForHorizon(materialImpact?.horizons?.shortTerm, null),
      mediumTerm: getDefaultValuesForHorizon(
        materialImpact?.horizons?.mediumTerm,
        defaultInheritFromHorizon,
      ),
      longTerm: getDefaultValuesForHorizon(
        materialImpact?.horizons?.longTerm,
        defaultInheritFromHorizon,
      ),
    },
    assignedUserIds: materialImpact?.assignedUserIds ?? [],
  };
};

function convertFormHorizonToAPIPayload(
  formData: IInternalMaterialImpactHorizonData,
): IDMAMaterialImpactHorizonData {
  return {
    extent: formData.extent,
    scope: formData.scope,
    // Properties only set when type is potential
    probability: formData.type === "potential" ? formData.probability : undefined,
    // Properties only set when result is negative
    irreversibility: formData.result === "negative" ? formData.irreversibility : undefined,
    materialityUser: formData.overrideMateriality ? formData.materialityUser : undefined,
    hasNegativeEffectOnHumanRights:
      formData.result === "negative" ? formData.hasNegativeEffectOnHumanRights : undefined,
    // User Override Materiality
    userMaterialityReason: formData.overrideMateriality
      ? formData.userMaterialityReason || undefined
      : undefined,
  };
}

function createFormHorizonAPIPayload(
  horizons: IInternalMaterialImpactData["horizons"],
  horizon: IHorizonsEnum,
): IDMAMaterialImpactHorizonData {
  const inheritsFrom = horizons[horizon].inheritsFromHorizon ?? undefined;
  return {
    ...convertFormHorizonToAPIPayload(horizons[inheritsFrom ?? horizon]),
    inheritsFromHorizon: inheritsFrom,
  };
}

interface IDMAMaterialImpactEditDialogProps {
  open: boolean;
  organizationId: string;
  materialImpact?: IDMAMaterialImpact | null;
  dmaCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren | null;
  dmaConfiguration: IDMAConfiguration;
  dataEntryObject: IDataEntryObject;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseDMAMaterialImpactData | null) => void;
  onUpdateIROState?: (newState: IIROState) => void;
  onDelete?: () => void;
  esrsTopic: IESRSTopic | null;
  readOnly?: boolean;
}

/**
 * Dialog to create or edit a material impact
 * @param open Whether the dialog is open or not
 * @param organizationId The organization id to use for the responsible user picker
 * @param materialImpact The material impact to edit. If null or undefined, the dialog will be in create mode
 * @param dmaCategory The DMA category the material impact belongs to
 * @param dmaConfiguration The DMA configuration to use for the materiality calculation
 * @param dataEntryObject The data entry object the dma is being executed for
 * @param loading Whether to display a loading indicator or not
 * @param error Error to display
 * @param disabled Whether the dialog is disabled or not (all inputs and buttons will be disabled)
 * @param onClose Callback to be called when the dialog is closed. The callback will receive the data of the financial effect created or edited, or null if the dialog was cancelled
 * @param onUpdateIROState Callback to be called when the IRO state is updated. If not provided, the IRO state will not be displayed
 * @param onDelete Callback for click event of delete button. If not provided, the delete button will not be displayed
 * @param esrsTopic The ESRSTopic to use for the topic filter
 * @param readOnly Whether the dialog is read only or not
 */
export const DMAMaterialImpactEditDialog: FC<IDMAMaterialImpactEditDialogProps> = ({
  open,
  organizationId,
  materialImpact,
  dmaCategory,
  dmaConfiguration,
  dataEntryObject,
  loading,
  error,
  disabled: parentDisabled,
  onClose,
  onUpdateIROState,
  onDelete,
  esrsTopic,
  readOnly,
}) => {
  const internalDisabled =
    readOnly ||
    parentDisabled ||
    materialImpact?.state === IIROState.Shared ||
    // Should already be disabled due to effectState (always Shared) but just to be sure
    (dmaCategory !== null && dmaCategory.materialState === IDMACategoryState.Verified);

  const iroStateReadOnly =
    dmaCategory !== null && dmaCategory.materialState === IDMACategoryState.Verified;

  const { t } = useTranslation("dma_material_impact_edit_dialog");

  const [showIncompleteForSharedNoticeDialog, setShowIncompleteForSharedNoticeDialog] =
    useState(false);

  const [viewedHorizon, setViewedHorizon] = useState<IHorizonsEnum>(IHorizonsEnum.ShortTerm);

  const formMethods = useForm<IInternalMaterialImpactData>({
    defaultValues: getDefaultValuesForInputs(materialImpact),
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = formMethods;

  // Reset Dialog when opened
  useEffect(() => {
    if (open) {
      reset(getDefaultValuesForInputs(materialImpact));
      setViewedHorizon(IHorizonsEnum.ShortTerm);
    }
  }, [open, materialImpact, reset]);

  // Calculate Severity and Materiality

  const currentHorizons = useMemo(
    () => {
      const getHorizonValues = (horizon: IHorizonsEnum) => ({
        inheritsFromHorizon: watch(`horizons.${horizon}.inheritsFromHorizon`),
        extent: watch(`horizons.${horizon}.extent`),
        scope: watch(`horizons.${horizon}.scope`),
        probability:
          watch(`horizons.${horizon}.type`) === "potential"
            ? watch(`horizons.${horizon}.probability`)
            : null,
        irreversibility:
          watch(`horizons.${horizon}.result`) === "negative"
            ? watch(`horizons.${horizon}.irreversibility`)
            : null,
        materialityUser: watch(`horizons.${horizon}.overrideMateriality`)
          ? watch(`horizons.${horizon}.materialityUser`)
          : null,
      });

      return {
        shortTerm: getHorizonValues(IHorizonsEnum.ShortTerm),
        mediumTerm: getHorizonValues(IHorizonsEnum.MediumTerm),
        longTerm: getHorizonValues(IHorizonsEnum.LongTerm),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    HORIZONS_VALUES.map((horizon) => [
      watch(`horizons.${horizon}.inheritsFromHorizon`),
      watch(`horizons.${horizon}.extent`),
      watch(`horizons.${horizon}.scope`),
      watch(`horizons.${horizon}.result`),
      watch(`horizons.${horizon}.irreversibility`),
      watch(`horizons.${horizon}.type`),
      watch(`horizons.${horizon}.probability`),
      watch(`horizons.${horizon}.overrideMateriality`),
      watch(`horizons.${horizon}.materialityUser`),
    ]).flat(),
  );

  // isDirty

  const isDirty = useIroEditIsDirtyHook(control, currentHorizons);

  // Severity Calculation

  const [calculatedTotalSeverity, calculatedTotalMateriality] = useMemo(() => {
    const getValuesForHorizon = (horizon: IHorizonsEnum) => {
      const horizonKeyToUse = currentHorizons[horizon].inheritsFromHorizon ?? horizon;

      const horizonToUse = currentHorizons[horizonKeyToUse];

      return {
        extent: currentHorizons[horizonKeyToUse].extent,
        scope: currentHorizons[horizonKeyToUse].scope,
        irreversibility: currentHorizons[horizonKeyToUse].irreversibility ?? undefined,
        probability:
          horizonToUse.probability === null || horizonToUse.probability === undefined
            ? undefined
            : DMAUtilities.getProbabilitySliderValue(
                horizonToUse.probability,
                dmaConfiguration.probabilityOfOccurrenceStepsMaterial ?? null,
              ),
        materialityThreshold: dmaConfiguration.materialityThreshold,
      };
    };

    // Get Severity Calculations
    const calculator = new MaterialImpactHorizonsCalculator({
      shortTerm: getValuesForHorizon(IHorizonsEnum.ShortTerm),
      mediumTerm: getValuesForHorizon(IHorizonsEnum.MediumTerm),
      longTerm: getValuesForHorizon(IHorizonsEnum.LongTerm),
    });

    // Calculate Materiality (either user override or automatic calculation)
    const materiality = HORIZONS_VALUES.map((horizon) => {
      if (currentHorizons[horizon].materialityUser !== null) {
        return currentHorizons[horizon].materialityUser;
      }

      return new MaterialImpactCalculator(getValuesForHorizon(horizon)).calculateMateriality();
    }).some((isMaterial) => isMaterial);

    return [calculator.calculateSeverity(), materiality];
  }, [
    currentHorizons,
    dmaConfiguration.probabilityOfOccurrenceStepsMaterial,
    dmaConfiguration.materialityThreshold,
  ]);

  const horizonsWithOwnValues = useMemo(() => {
    return HORIZONS_VALUES.filter((horizon) => !currentHorizons[horizon].inheritsFromHorizon);
  }, [currentHorizons]);

  const hasHorizonsComment = useMemo(
    () => horizonsWithOwnValues.length > 1,
    [horizonsWithOwnValues],
  );

  // Inheritance State

  const horizonsInheritanceState: IInheritFromHorizonsState = useMemo(
    () => {
      function getInheritanceState(horizon: IHorizonsEnum): IInheritFromHorizonsStateItem {
        const inheritsFromHorizon = watch(`horizons.${horizon}.inheritsFromHorizon`);
        return {
          inUse: HORIZONS_VALUES.map((horizon) =>
            watch(`horizons.${horizon}.inheritsFromHorizon`),
          ).some((inheritsFromHorizon) => inheritsFromHorizon === horizon),
          inheritsValues: !!inheritsFromHorizon,
        };
      }

      return {
        shortTerm: getInheritanceState(IHorizonsEnum.ShortTerm),
        mediumTerm: getInheritanceState(IHorizonsEnum.MediumTerm),
        longTerm: getInheritanceState(IHorizonsEnum.LongTerm),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    HORIZONS_VALUES.map((horizon) => watch(`horizons.${horizon}.inheritsFromHorizon`)),
  );

  // Close Handling (Data Emission)

  const handleEmitData = (data: IInternalMaterialImpactData) => {
    // Setup basic result
    const result: IBaseDMAMaterialImpactData = {
      title: data.title.trim(),
      responsibleUserId: data.responsibleUserId ?? undefined,
      description: data.description?.trim() || undefined,
      assessmentExplanation: data.assessmentExplanation?.trim() || undefined,
      internalCommunication: data.internalCommunication?.trim() || undefined,
      areas: data.areas,
      horizonsComment: hasHorizonsComment ? data.horizonsComment?.trim() || undefined : undefined,
      horizons: {
        shortTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.ShortTerm),
        mediumTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.MediumTerm),
        longTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.LongTerm),
      },
      assignedUserIds: data.assignedUserIds,
      associatedDataEntryObjects: data.associatedDataEntryObjects,
    };

    // Clear horizonsComment if all horizons have the same value (input not shown in this case)
    if (horizonsWithOwnValues.length <= 1) {
      result.horizonsComment = undefined;
    }

    onClose(result);
  };

  const handleChangeState = (newState: IIROState) => {
    // Skip if the same
    if (newState === materialImpact?.state) {
      return;
    }
    // Open incomplete Notice Dialog if not complete yet
    if (newState === IIROState.Shared && !materialImpact?.assessmentExplanation) {
      setShowIncompleteForSharedNoticeDialog(true);
      return;
    }
    // Trigger Update
    onUpdateIROState?.(newState);
  };

  const handleCloseIncompleteForSharedNoticeDialog = () => {
    setShowIncompleteForSharedNoticeDialog(false);
    setError(
      "assessmentExplanation",
      {
        message: t("error_assessmentExplanation_required", {
          ns: "dma_impact_or_effect_edit_dialog_common",
        }),
      },
      { shouldFocus: true },
    );
  };

  // Render Dialog

  const { user } = useUserContext();

  const isResponsibleUser =
    materialImpact?.responsibleUserId && user?.userProfile.id === materialImpact?.responsibleUserId;

  return (
    <FormProvider {...formMethods}>
      <OptionsDialog
        open={showIncompleteForSharedNoticeDialog}
        title={t("incomplete_for_shared_notice_dialog_title")}
        content={<FormatTranslation t={t} i18nKey="incomplete_for_shared_notice" />}
        options={[
          {
            label: t("close", { ns: "buttons" }),
            onClick: handleCloseIncompleteForSharedNoticeDialog,
          },
        ]}
      />

      <Dialog
        open={open}
        onClose={!isDirty ? () => onClose(null) : undefined}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle display="flex" alignItems="center" gap={2}>
          <Box flex={1} component="span">
            {/* Breadcrumbs */}
            <DMABreadcrumbsComponent esrsTopic={esrsTopic} dmaCategory={dmaCategory} />

            {/* Title Text */}
            {readOnly && materialImpact?.title}
            {!readOnly && // Only show when NOT readOnly
              (materialImpact ? (
                <FormatTranslation
                  i18nKey="title_edit"
                  t={t}
                  values={{ title: materialImpact.title }}
                />
              ) : (
                t("title_create")
              ))}
          </Box>
          {/* Status Indicator */}
          {materialImpact && onUpdateIROState && (
            <>
              {/* Allow any Changes by Responsible User AND Changes down from Shared */}
              {isResponsibleUser || materialImpact.state === IIROState.Shared ? (
                <IROStateSelect
                  value={materialImpact.state}
                  onChange={handleChangeState}
                  disabled={isDirty || parentDisabled}
                  readOnly={iroStateReadOnly}
                />
              ) : (
                <IROStateIndicator state={materialImpact.state} />
              )}
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {/* Delete Button */}
          {onDelete && (
            <Tooltip title={t("delete_tooltip")}>
              <span>
                <IconButton size="small" onClick={onDelete} disabled={internalDisabled}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </DialogTitle>
        {loading && <LinearProgress />}
        <DialogContent>
          {error && <ErrorTextComponent error={error} />}

          <Box display="flex" flexDirection="column" gap={2} py={1}>
            {/* Basic Properties Inputs */}

            {/* Responsible User */}
            <Box>
              <Controller
                control={control}
                name="responsibleUserId"
                render={({ field }) => (
                  <OrganizationUserPicker
                    label={t("label_responsible_user", {
                      ns: "dma_impact_or_effect_edit_dialog_common",
                    })}
                    value={field.value}
                    onChange={(user) => field.onChange(user?.id || null)}
                    organizationId={organizationId}
                    disabled={internalDisabled}
                  />
                )}
              />
            </Box>

            {/* Assigned Users */}
            <Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="subtitle1">
                  {t("label_assigned_users", { ns: "dma_impact_or_effect_edit_dialog_common" })}
                </Typography>
                <Tooltip
                  title={t("explanation_assigned_users", {
                    ns: "dma_impact_or_effect_edit_dialog_common",
                  })}
                  placement="right"
                >
                  <InfoIcon size={IconSize.Smaller} />
                </Tooltip>
              </Box>
              <Box pt={1}>
                <Controller
                  control={control}
                  name="assignedUserIds"
                  render={({ field }) => (
                    <OrganizationUsersComponent
                      values={field.value}
                      organizationId={organizationId}
                      onChange={field.onChange}
                      disabled={internalDisabled}
                      emptyMessageAddButton={t("no_more_users_to_assign", {
                        ns: "dma_impact_or_effect_edit_dialog_common",
                      })}
                      emptyMessage={t("no_assigned_users", {
                        ns: "dma_impact_or_effect_edit_dialog_common",
                      })}
                      tooltipAddButton={t("add_assigned_user_tooltip", {
                        ns: "dma_impact_or_effect_edit_dialog_common",
                      })}
                    />
                  )}
                />
              </Box>
            </Box>

            <Divider sx={{ mx: -3 }} />

            {/* Title */}
            <Controller
              control={control}
              name="title"
              rules={{
                required: t("error_title_required", {
                  ns: "dma_impact_or_effect_edit_dialog_common",
                }),
                validate: (value) => {
                  if (value.trim() === "") {
                    return t("error_title_required", {
                      ns: "dma_impact_or_effect_edit_dialog_common",
                    });
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("label_title", { ns: "dma_impact_or_effect_edit_dialog_common" })}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  fullWidth
                  multiline
                  maxRows={3}
                  disabled={internalDisabled}
                />
              )}
            />

            {/* Description */}
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("label_description", { ns: "dma_impact_or_effect_edit_dialog_common" })}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  multiline
                  fullWidth
                  disabled={internalDisabled}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title={t("description_explanation", {
                          ns: "dma_impact_or_effect_edit_dialog_common",
                        })}
                      >
                        <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                          <InfoIcon />
                        </SvgIcon>
                      </Tooltip>
                    ),
                  }}
                />
              )}
            />

            {/* Assessment Explanation */}
            <Controller
              control={control}
              name="assessmentExplanation"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("label_assessmentExplanation", {
                    ns: "dma_impact_or_effect_edit_dialog_common",
                  })}
                  error={!!errors.assessmentExplanation}
                  helperText={errors.assessmentExplanation?.message}
                  multiline
                  minRows={3}
                  fullWidth
                  disabled={internalDisabled}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title={t("assessmentExplanation_explanation", {
                          ns: "dma_impact_or_effect_edit_dialog_common",
                        })}
                      >
                        <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                          <InfoIcon />
                        </SvgIcon>
                      </Tooltip>
                    ),
                  }}
                />
              )}
            />

            {/* Internal communication */}
            <Controller
              control={control}
              name="internalCommunication"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("label_internalCommunication", {
                    ns: "dma_impact_or_effect_edit_dialog_common",
                  })}
                  error={!!errors.internalCommunication}
                  helperText={errors.internalCommunication?.message}
                  multiline
                  minRows={3}
                  fullWidth
                  disabled={internalDisabled}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title={
                          <span style={{ whiteSpace: "pre-line" }}>
                            {t("internalCommunication_info", {
                              ns: "dma_impact_or_effect_edit_dialog_common",
                            })}
                          </span>
                        }
                      >
                        <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                          <InfoIcon />
                        </SvgIcon>
                      </Tooltip>
                    ),
                  }}
                />
              )}
            />

            {/* Areas Checkboxes */}
            <IROAreasCheckboxesComponent
              dataEntryObject={dataEntryObject}
              disabled={internalDisabled}
            />

            {/* Horizons */}
            <Box mt={2}>
              <Divider sx={{ mx: -3 }} />

              {/* Horizons Tabs */}
              <Tabs
                variant="fullWidth"
                value={viewedHorizon}
                onChange={(_, newValue) => setViewedHorizon(newValue)}
                sx={{ mx: -3 }}
              >
                {ORDERED_HORIZONS_VALUES.map((horizon) => (
                  <Tab
                    key={horizon}
                    value={horizon}
                    label={
                      <EditDialogHorizonTabContent
                        horizon={horizon}
                        error={!!errors.horizons?.[horizon]}
                      />
                    }
                  />
                ))}
              </Tabs>
              <Divider sx={{ mx: -3, mb: 3 }} />

              {/*
              Horizons Sections.
              Have to always be mounted since validation otherwise won't trigger on submit
            */}
              {ORDERED_HORIZONS_VALUES.map((horizon) => (
                <Box
                  key={horizon}
                  height={viewedHorizon === horizon ? "auto" : 0}
                  overflow="hidden"
                  mx={-3}
                  px={3}
                >
                  <DMAMaterialImpactHorizonEditSection
                    // Fix change of inheritance value does not trigger update of inputs and severity/materiality preview
                    key={currentHorizons[horizon].inheritsFromHorizon}
                    control={control}
                    horizon={horizon}
                    inheritFromHorizonsState={horizonsInheritanceState}
                    dmaConfiguration={dmaConfiguration}
                    setValue={setValue}
                    showHorizonsCommentInput={hasHorizonsComment}
                    disabled={internalDisabled}
                    esrsTopic={esrsTopic}
                  />
                </Box>
              ))}
            </Box>

            <Divider sx={{ mx: -3 }} />

            {/* Calculated Severity */}

            <Typography variant="h6">
              {t("header_total_evaluation", { ns: "dma_impact_or_effect_edit_dialog_common" })}
            </Typography>

            <Box display="flex" gap={2}>
              <Typography sx={{ minWidth: 150 }}>
                {t("severity_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
              </Typography>
              <Typography fontWeight="bold">
                {FormatUtilities.formatPercentage(calculatedTotalSeverity * 100, 0)}%
              </Typography>
            </Box>

            {/* Calculated Materiality */}
            <Box display="flex" gap={2}>
              <Typography sx={{ minWidth: 150 }}>
                {t("materiality_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
              </Typography>
              <Typography fontWeight="bold">
                {calculatedTotalMateriality
                  ? t("materiality_true_state", { ns: "dma_common" })
                  : t("materiality_false_state", { ns: "dma_common" })}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose(null)} disabled={parentDisabled}>
            {isDirty ? t("button_cancel") : t("button_close")}
          </Button>
          {!readOnly && (
            <Button
              variant="contained"
              onClick={handleSubmit(handleEmitData)}
              disabled={!isDirty || internalDisabled}
            >
              {materialImpact ? t("button_save") : t("button_create")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
