import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IDMAConfiguration, IESRSTopic, IHorizonsEnum } from "@netcero/netcero-core-api-client";
import { DMAUtilities, MaterialImpactCalculator } from "@netcero/netcero-dma";
import { FC, useMemo } from "react";
import { Control, Controller, UseFormSetValue, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CancelIcon, EditIcon, InfoIcon } from "../../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { ORDERED_HORIZONS_VALUES } from "../common/dma-horizons.constants";
import { IInheritFromHorizonsState } from "../common/dma-horizons.interfaces";
import { DMAProbabilitySlider } from "../common/dma-probability-slider.component";
import { DMASlider } from "../common/dma-slider.component";
import { IInternalMaterialImpactData } from "./dma-material-impact-edit.dialog";

const MIN_WIDTH_SLIDER_LABEL = 240;

const SliderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: { flexWrap: "wrap" },
  [theme.breakpoints.up("md")]: { flexWrap: "nowrap" },
  paddingRight: theme.spacing(4),
}));

interface IDMAMaterialImpactHorizonEditSectionProps {
  horizon: IHorizonsEnum;
  inheritFromHorizonsState: IInheritFromHorizonsState;
  dmaConfiguration: IDMAConfiguration;
  control: Control<IInternalMaterialImpactData>;
  showHorizonsCommentInput: boolean;
  disabled?: boolean;
  setValue: UseFormSetValue<IInternalMaterialImpactData>;
  esrsTopic: IESRSTopic | null;
}

export const DMAMaterialImpactHorizonEditSection: FC<IDMAMaterialImpactHorizonEditSectionProps> = ({
  horizon,
  inheritFromHorizonsState,
  dmaConfiguration,
  control,
  showHorizonsCommentInput,
  disabled: parentDisabled,
  setValue,
  esrsTopic,
}) => {
  const { t } = useTranslation("dma_material_impact_edit_dialog");

  const horizonIsInUseByOtherHorizon = useMemo(
    () => inheritFromHorizonsState[horizon].inUse,
    [inheritFromHorizonsState, horizon],
  );
  const inheritsFromHorizon = useWatch({
    control,
    name: `horizons.${horizon}.inheritsFromHorizon`,
  });

  const displayHorizon = inheritsFromHorizon ?? horizon;

  const internalDisabled = parentDisabled || inheritsFromHorizon !== null;

  // Calculate Preview Values

  const currentExtent = useWatch({ control, name: `horizons.${displayHorizon}.extent` });
  const currentScope = useWatch({ control, name: `horizons.${displayHorizon}.scope` });

  const currentType = useWatch({ control, name: `horizons.${displayHorizon}.type` });
  const currentProbabilityValue = useWatch({
    control,
    name: `horizons.${displayHorizon}.probability`,
  });
  const currentProbability = currentType === "potential" ? currentProbabilityValue! : null;

  const currentResult = useWatch({ control, name: `horizons.${displayHorizon}.result` });
  const currentIrreversibilityValue = useWatch({
    control,
    name: `horizons.${displayHorizon}.irreversibility`,
  });
  const currentIrreversibility = currentResult === "negative" ? currentIrreversibilityValue! : null;

  const currentHasNegativeEffectOnHumanRightsValue = useWatch({
    control,
    name: `horizons.${displayHorizon}.hasNegativeEffectOnHumanRights`,
  });
  const currentHasNegativeEffectOnHumanRights =
    currentResult === "negative" ? currentHasNegativeEffectOnHumanRightsValue! : null;

  const [calculatedSeverity, calculatedMaterialityDegree, calculatedMateriality] = useMemo(() => {
    const calculator = new MaterialImpactCalculator({
      extent: currentExtent,
      scope: currentScope,
      probability:
        currentProbability !== null
          ? DMAUtilities.getProbabilitySliderValue(
              currentProbability,
              dmaConfiguration.probabilityOfOccurrenceStepsMaterial ?? null,
            )
          : undefined,
      irreversibility: currentIrreversibility ?? undefined,
      hasNegativeEffectOnHumanRights: currentHasNegativeEffectOnHumanRights ?? undefined,
      materialityThreshold: dmaConfiguration.materialityThreshold,
    });
    return [
      calculator.calculateSeverity(),
      calculator.calculateMaterialityDegree(),
      calculator.calculateMateriality(),
    ];
  }, [
    dmaConfiguration,
    currentExtent,
    currentScope,
    currentProbability,
    currentIrreversibility,
    currentHasNegativeEffectOnHumanRights,
  ]);

  // Custom Materiality

  const overrideMateriality = useWatch({
    control,
    name: `horizons.${displayHorizon}.overrideMateriality`,
  });

  const handleEditMateriality = () => {
    setValue(`horizons.${displayHorizon}.overrideMateriality`, true, { shouldDirty: true });
  };

  const handleCancelUserMateriality = () => {
    setValue(`horizons.${displayHorizon}.overrideMateriality`, false, { shouldDirty: true });
  };

  // Render Component

  return (
    <Box display="flex" flexDirection="column" gap={2} minHeight={406} px={1}>
      {/* Inherit from Horizon */}
      <Box display="flex" alignItems="center" gap={2}>
        <FormLabel>
          {t("inherit_form_horizon_select_label", {
            ns: "dma_impact_or_effect_edit_dialog_common",
          })}
        </FormLabel>
        <Controller
          control={control}
          name={`horizons.${horizon}.inheritsFromHorizon`}
          render={({ field }) => (
            <Tooltip
              title={
                horizonIsInUseByOtherHorizon
                  ? t("in_use_by_other_horizon", { ns: "dma_impact_or_effect_edit_dialog_common" })
                  : ""
              }
              placement="right"
            >
              <span>
                <FormControl>
                  <Select
                    variant="standard"
                    disableUnderline
                    value={field.value ?? "no_inheritance"}
                    onChange={(evt) =>
                      field.onChange(
                        evt.target.value === "no_inheritance" ? null : evt.target.value,
                      )
                    }
                    disabled={parentDisabled || horizonIsInUseByOtherHorizon}
                  >
                    <MenuItem value="no_inheritance">
                      {t("no_inheritance_name", { ns: "dma_impact_or_effect_edit_dialog_common" })}
                    </MenuItem>
                    {ORDERED_HORIZONS_VALUES.filter(
                      (inherit_horizon_value) => inherit_horizon_value !== horizon,
                    ).map((inherit_horizon_value) => {
                      const inheritsValuesItself =
                        inheritFromHorizonsState[inherit_horizon_value].inheritsValues;

                      return (
                        <MenuItem
                          key={inherit_horizon_value}
                          value={inherit_horizon_value}
                          disabled={inheritsValuesItself}
                        >
                          {t(`inherit_from_${inherit_horizon_value}`, {
                            ns: "dma_impact_or_effect_edit_dialog_common",
                          })}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </span>
            </Tooltip>
          )}
        />
      </Box>

      {/* Horizons Comment - only shown if horizons have different values */}
      {showHorizonsCommentInput && (
        <Controller
          control={control}
          name="horizonsComment"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label={t("horizons_comment_label", {
                ns: "dma_impact_or_effect_edit_dialog_common",
              })}
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={t("horizons_comment_explanation", {
                      ns: "dma_impact_or_effect_edit_dialog_common",
                    })}
                  >
                    <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                      <InfoIcon />
                    </SvgIcon>
                  </Tooltip>
                ),
              }}
              error={!!error}
              helperText={error?.message}
              multiline
              minRows={1}
              fullWidth
              disabled={parentDisabled}
            />
          )}
        />
      )}

      {/* Inputs with actual used values */}
      <Box display="flex" flexDirection="column" gap={2}>
        {/* Type */}
        <Controller
          control={control}
          name={`horizons.${displayHorizon}.type`}
          render={({ field }) => (
            <FormControl>
              <Typography variant="subtitle1">{t("subtitle_type")}</Typography>
              <RadioGroup row value={field.value} onChange={(_, value) => field.onChange(value)}>
                <FormControlLabel
                  value="actual"
                  control={<Radio disabled={internalDisabled} />}
                  label={t("type_actual")}
                  disabled={internalDisabled}
                />
                <FormControlLabel
                  value="potential"
                  control={<Radio disabled={internalDisabled} />}
                  label={t("type_potential")}
                  disabled={internalDisabled}
                />
              </RadioGroup>
            </FormControl>
          )}
        />

        {/* Probability Slider */}
        {currentType === "potential" && (
          <SliderContainer>
            <FormLabel
              sx={{
                minWidth: MIN_WIDTH_SLIDER_LABEL,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {t("label_probability")}
              <Tooltip title={t("explanation_probability")}>
                <SvgIcon fontSize="small" color="action" sx={{ mt: 0.5 }}>
                  <InfoIcon />
                </SvgIcon>
              </Tooltip>
            </FormLabel>
            <Controller
              control={control}
              name={`horizons.${displayHorizon}.probability`}
              render={({ field }) => (
                <DMAProbabilitySlider
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  aria-label={t("label_probability")}
                  disabled={internalDisabled}
                />
              )}
            />
          </SliderContainer>
        )}

        <Box display="flex" flexWrap="wrap" alignItems="end" mt={1}>
          {/* Result of Impact */}
          <Controller
            control={control}
            name={`horizons.${displayHorizon}.result`}
            render={({ field }) => (
              <FormControl sx={{ minWidth: MIN_WIDTH_SLIDER_LABEL }}>
                <Typography variant="subtitle1">{t("subtitle_result")}</Typography>
                <RadioGroup row value={field.value} onChange={(_, value) => field.onChange(value)}>
                  <FormControlLabel
                    value="positive"
                    control={<Radio disabled={internalDisabled} />}
                    label={t("result_positive")}
                    disabled={internalDisabled}
                  />
                  <FormControlLabel
                    value="negative"
                    control={<Radio disabled={internalDisabled} />}
                    label={t("result_negative")}
                    disabled={internalDisabled}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          {/* Properties only set for negative Result */}
          {currentResult === "negative" && (
            <>
              {/* Negative Effect on Human Rights */}
              <Controller
                control={control}
                name={`horizons.${displayHorizon}.hasNegativeEffectOnHumanRights`}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(_, checked) => field.onChange(checked)}
                        disabled={internalDisabled}
                      />
                    }
                    label={t("label_hasNegativeEffectOnHumanRights")}
                    disabled={internalDisabled}
                    sx={{ pl: 2 }}
                  />
                )}
              />
            </>
          )}
        </Box>

        {/* Scope */}
        <SliderContainer>
          <FormLabel
            sx={{
              minWidth: MIN_WIDTH_SLIDER_LABEL,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {t("label_scope")}
            {/* Make tooltip display line breaks */}
            <Tooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  {t(`explanations.${esrsTopic?.identifier}.scope`)}
                </div>
              }
            >
              <SvgIcon fontSize="small" color="action" sx={{ mt: 0.5 }}>
                <InfoIcon />
              </SvgIcon>
            </Tooltip>
          </FormLabel>
          <Controller
            control={control}
            name={`horizons.${displayHorizon}.scope`}
            render={({ field }) => (
              <DMASlider
                valueNameTranslationPrefix="slider_value_scale_"
                value={field.value}
                onChange={(_, value) => field.onChange(value as number)}
                aria-label={t("label_scope")}
                disabled={internalDisabled}
              />
            )}
          />
        </SliderContainer>
      </Box>

      {/* Extent */}
      <SliderContainer>
        <FormLabel
          sx={{
            minWidth: MIN_WIDTH_SLIDER_LABEL,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {t("label_extent")}
          {/* Make tooltip display line breaks */}
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                {t(`explanations.${esrsTopic?.identifier}.extent`)}
              </div>
            }
          >
            <SvgIcon fontSize="small" color="action" sx={{ mt: 0.5 }}>
              <InfoIcon />
            </SvgIcon>
          </Tooltip>
        </FormLabel>
        <Controller
          control={control}
          name={`horizons.${displayHorizon}.extent`}
          render={({ field }) => (
            <DMASlider
              valueNameTranslationPrefix="slider_value_extent_"
              value={field.value}
              onChange={(_, value) => field.onChange(value as number)}
              aria-label={t("label_extent")}
              disabled={internalDisabled}
            />
          )}
        />
      </SliderContainer>

      {/* Irreversibility Slider */}
      {currentResult === "negative" && (
        <SliderContainer>
          <FormLabel
            sx={{
              minWidth: MIN_WIDTH_SLIDER_LABEL,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {t("label_irreversibility")}
            {/* Make tooltip display line breaks */}
            <Tooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  {t(`explanations.${esrsTopic?.identifier}.irreversibility`)}
                </div>
              }
            >
              <SvgIcon fontSize="small" color="action" sx={{ mt: 0.5 }}>
                <InfoIcon />
              </SvgIcon>
            </Tooltip>
          </FormLabel>
          <Controller
            control={control}
            name={`horizons.${displayHorizon}.irreversibility`}
            render={({ field }) => (
              <DMASlider
                valueNameTranslationPrefix="slider_value_irreversibility_"
                value={field.value}
                onChange={(_, value) => field.onChange(value as number)}
                aria-label={t("label_irreversibility")}
                disabled={internalDisabled}
              />
            )}
          />
        </SliderContainer>
      )}

      {/* Evaluation */}

      <Typography variant="subtitle1" component="h3">
        {t("header_horizon_evaluation", { ns: "dma_impact_or_effect_edit_dialog_common" })}
      </Typography>

      {/* Severity */}
      <Box display="flex" gap={2}>
        <Typography sx={{ minWidth: 180 }}>
          {t("severity_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
        </Typography>
        <Typography fontWeight="bold">
          {FormatUtilities.formatPercentage(calculatedSeverity * 100, 0)}%
        </Typography>
      </Box>

      {/* Materiality Degree */}
      <Box display="flex" gap={2}>
        <Typography sx={{ minWidth: 180 }}>
          {t("materiality_degree_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
        </Typography>
        <Typography fontWeight="bold">
          {FormatUtilities.formatFixedDecimal(calculatedMaterialityDegree, 1)}
        </Typography>
      </Box>

      {/* Materiality */}
      {!overrideMateriality ? (
        /* Default Materiality (calculated) */
        <Box display="flex" alignItems="center" gap={2}>
          <Typography sx={{ minWidth: 180 }}>
            {t("materiality_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
          </Typography>
          <Typography fontWeight="bold">
            {calculatedMateriality
              ? t("materiality_true_state", { ns: "dma_common" })
              : t("materiality_false_state", { ns: "dma_common" })}
          </Typography>
          <Tooltip
            title={t("edit_materiality_tooltip", {
              ns: "dma_impact_or_effect_edit_dialog_common",
            })}
          >
            <span>
              <IconButton size="small" onClick={handleEditMateriality} disabled={internalDisabled}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : (
        /* User Materiality (override value) */
        <Box>
          <Box display="flex" gap={2}>
            <Typography sx={{ minWidth: 150 }}>
              {t("calculated_materiality_label", {
                ns: "dma_impact_or_effect_edit_dialog_common",
              })}
              :
            </Typography>
            <Typography>
              {calculatedMateriality
                ? t("materiality_true_state", { ns: "dma_common" })
                : t("materiality_false_state", {
                    ns: "dma_common",
                  })}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2} my={1}>
            <Typography fontWeight="bold" sx={{ minWidth: 150 }}>
              {t("custom_materiality_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
            </Typography>
            <Controller
              control={control}
              name={`horizons.${displayHorizon}.materialityUser`}
              render={({ field }) => (
                <Box display="flex" alignItems="center">
                  <Typography fontWeight={!field.value ? "bold" : undefined}>
                    {t("materiality_false_state", {
                      ns: "dma_common",
                    })}
                  </Typography>
                  <Switch
                    checked={field.value!}
                    onChange={(_, checked) => field.onChange(checked)}
                    disabled={internalDisabled}
                  />
                  <Typography fontWeight={field.value ? "bold" : undefined}>
                    {t("materiality_true_state", { ns: "dma_common" })}
                  </Typography>
                </Box>
              )}
            />
            <Tooltip
              title={t("cancel_edit_materiality_tooltip", {
                ns: "dma_impact_or_effect_edit_dialog_common",
              })}
            >
              <span>
                <IconButton
                  size="small"
                  onClick={handleCancelUserMateriality}
                  disabled={internalDisabled}
                >
                  <CancelIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Controller
            control={control}
            name={`horizons.${displayHorizon}.userMaterialityReason`}
            rules={{
              required: t("error_user_materiality_reason_required", {
                ns: "dma_impact_or_effect_edit_dialog_common",
              }),
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("label_user_materiality_reason", {
                  ns: "dma_impact_or_effect_edit_dialog_common",
                })}
                error={!!error}
                helperText={error?.message}
                fullWidth
                disabled={internalDisabled}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};
