import { DMASliderValues } from "../enums/slider-values.enum";
import { IMaterialImpactCalculatorData } from "../types/material-impact-calculator-data.interface";
import { IBaseCalculator } from "./base-calculator.interface";
import { DMA_MATERIALITY_MULTIPLIER } from "../constants/dma.constants";

const MAX_VALUE = DMASliderValues.VeryHigh;

export class MaterialImpactCalculator implements IBaseCalculator {
  private static weightingSeverity = {
    extent: 1 / 3,
    scope: 1 / 3,
    irreversibility: 1 / 3,
  };

  private static weightingMaterialityDegree = {
    severity: (hasNegativeEffectOnHumanRights?: boolean) =>
      hasNegativeEffectOnHumanRights === true ? 3 : 1,
    probability: 1,
  };

  private readonly extent: number;
  private readonly scope: number;
  private readonly irreversibility?: number;
  private readonly probability?: number;

  private readonly hasNegativeEffectOnHumanRights?: boolean;

  private readonly materialityMultiplier: number = DMA_MATERIALITY_MULTIPLIER;
  private readonly materialityThreshold: number;

  /**
   * @param extent
   * @param scope
   * @param irreversibility
   * @param probability
   * @param hasNegativeEffectOnHumanRights If the effect is relevant to human rights, the probability is always set to 1.
   * @param materialityMultiplier The multiplier is the maximum value for the materialityDegree. The calculated severity is multiplied by this value.
   * Example: severity of 1.0 (maximum) --> materialityDegree of 5.0 (maximum), severity of 0.5 --> materialityDegree of 2.5
   * Default: 5
   * @param materialityThreshold The threshold for the materiality of the effect.
   * When the materialityDegree is greater than or equal to this threshold, the effect is considered material.
   * Default: 3
   */
  constructor(effect: IMaterialImpactCalculatorData) {
    Object.assign(this, effect);
  }

  public calculateSeverity(): number {
    let result: number;
    if (this.irreversibility === undefined) {
      result =
        (this.extent * MaterialImpactCalculator.weightingSeverity.extent +
          this.scope * MaterialImpactCalculator.weightingSeverity.scope) /
        (MaterialImpactCalculator.weightingSeverity.extent +
          MaterialImpactCalculator.weightingSeverity.scope);
    } else {
      result =
        (this.extent * MaterialImpactCalculator.weightingSeverity.extent +
          this.scope * MaterialImpactCalculator.weightingSeverity.scope +
          this.irreversibility * MaterialImpactCalculator.weightingSeverity.irreversibility) /
        (MaterialImpactCalculator.weightingSeverity.extent +
          MaterialImpactCalculator.weightingSeverity.scope +
          MaterialImpactCalculator.weightingSeverity.irreversibility);
    }

    // Workaround to fix floating point precision issue
    // TODO: Investigate if this is enough or if we need something more sophisticated
    return +result.toPrecision(8);
  }

  public calculateMaterialityDegree(): number {
    const severity = this.calculateSeverity();
    if (this.probability === undefined) {
      return severity * this.materialityMultiplier;
    }

    const calculatedMateriality =
      (severity *
        MaterialImpactCalculator.weightingMaterialityDegree.severity(
          this.hasNegativeEffectOnHumanRights,
        ) +
        this.probability * MaterialImpactCalculator.weightingMaterialityDegree.probability) /
      (MaterialImpactCalculator.weightingMaterialityDegree.severity(
        this.hasNegativeEffectOnHumanRights,
      ) +
        MaterialImpactCalculator.weightingMaterialityDegree.probability);

    const factorForMultiplication = Math.min(severity, calculatedMateriality);
    return +(factorForMultiplication * this.materialityMultiplier).toPrecision(8);
  }

  public calculateMateriality(): boolean {
    // "MAX-Wert-Regel"
    if ([this.extent, this.scope, this.irreversibility || -1].some((v) => v >= MAX_VALUE)) {
      return true;
    }

    return this.calculateMaterialityDegree() >= this.materialityThreshold;
  }
}
