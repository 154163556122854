import { DMAUtilities } from "./dma.utilities";
import { IMaterialImpactCalculatorData } from "../types/material-impact-calculator-data.interface";
import {
  IDMAConfigurationProbabilityOfOccurrenceSteps,
  IDMAMaterialImpactHorizonData,
} from "@netcero/netcero-core-api-client";
import { mapIfNotUndefined } from "@netcero/netcero-common";

export class DMAMaterialImpactUtils {
  public static convertHorizonForCalculation(
    materialityThreshold: number,
    horizon: IDMAMaterialImpactHorizonData,
    steps: IDMAConfigurationProbabilityOfOccurrenceSteps | null,
  ): IMaterialImpactCalculatorData {
    return {
      materialityThreshold,
      ...horizon,
      probability: mapIfNotUndefined(horizon.probability, (p) => {
        return DMAUtilities.getProbabilitySliderValue(p, steps);
      }),
    };
  }

  public static convertHorizonForCalculationOptionalSteps(
    materialityThreshold: number,
    horizon: IDMAMaterialImpactHorizonData,
    steps: IDMAConfigurationProbabilityOfOccurrenceSteps | undefined,
  ): IMaterialImpactCalculatorData {
    return DMAMaterialImpactUtils.convertHorizonForCalculation(
      materialityThreshold,
      horizon,
      steps ?? null,
    );
  }
}
