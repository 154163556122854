import { Box, Typography } from "@mui/material";
import {
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseChart,
  EChartClickHandler,
  EChartsOption,
} from "../../common/charts/base-chart.component";
import { DMAMatrixSeriesElement, DMAMatrixUtilities } from "./dma-matrix.utilities";
import { useRenderDMACategoryName } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";

interface IDMAMatrixChartTopicProps {
  esrsTopic: IESRSTopic;
  dmaCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren;
  height?: string;
  onSelectCategory: (categoryId: string) => void;
}

export const DMAMatrixChartCategory: FC<IDMAMatrixChartTopicProps> = ({
  esrsTopic,
  dmaCategory,
  height,
  onSelectCategory,
}) => {
  const { t } = useTranslation("dma_dashboard");
  const renderName = useRenderDMACategoryName();

  const chartData: EChartsOption | null = useMemo(() => {
    const data = DMAMatrixUtilities.filterSeriesToDisplayableSeries(
      dmaCategory.children.map((c) =>
        DMAMatrixUtilities.convertCategoryToSeriesData(c, renderName),
      ),
    );
    const categoryItselfData = DMAMatrixUtilities.filterSeriesToDisplayableSeries([
      DMAMatrixUtilities.convertCategoryToSeriesData(dmaCategory, renderName),
    ]);

    // If empty, return null
    if (data.length === 0 && categoryItselfData.length === 0) {
      return null;
    }

    const maxMaterialityDegreeSum = Math.max(
      ...[...data, ...categoryItselfData].map((data) => data[2]),
    );

    return {
      // title: {
      //   text: dmaCategory.name,
      // },
      legend: {
        data: [t("label_matrix_children"), renderName(dmaCategory)],
      },
      xAxis: {
        ...DMAMatrixUtilities.DEFAULT_CHART_PROPS.AXIS_PROPS,
        name: t("label_axis_financial_materiality"),
      },
      yAxis: {
        ...DMAMatrixUtilities.DEFAULT_CHART_PROPS.AXIS_PROPS,
        name: t("label_axis_material_materiality"),
      },
      series: [
        DMAMatrixUtilities.getScatterChartSeriesData(
          renderName(dmaCategory),
          categoryItselfData,
          maxMaterialityDegreeSum,
          DMAMatrixUtilities.COLORS.CATEGORY_SELF,
        ),
        DMAMatrixUtilities.getScatterChartSeriesData(
          t("label_matrix_children"),
          data,
          maxMaterialityDegreeSum,
          DMAMatrixUtilities.getColorForTopic(esrsTopic),
        ),
      ],
    } as EChartsOption;
  }, [dmaCategory, renderName, t, esrsTopic]);

  if (chartData === null) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Typography textAlign="center">{t("no_categories_to_display_notice")}</Typography>
      </Box>
    );
  }

  const handleChartClick: EChartClickHandler = (params) => {
    const data = params.data as DMAMatrixSeriesElement;
    const id = data[5];

    // Do nothing if the same category is clicked
    if (id === dmaCategory.id) {
      return;
    }

    onSelectCategory(id);
  };

  return (
    <Box flex={1}>
      <Typography variant="h5">{renderName(dmaCategory)}</Typography>
      <BaseChart options={chartData} height={height} onClick={handleChartClick} />
    </Box>
  );
};
