import { FC, useEffect, useMemo } from "react";
import { IStakeholderRecordingPeriods } from "@netcero/netcero-core-api-client";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TypographySelectComponent } from "../../common/components/typography-select.component";
import { useSearchParams } from "react-router-dom";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useStakeholderFeedbackQuery } from "../stakeholder-feedbacks.queries";
import { StakeholderFeedbacksComponent } from "./stakeholder-feedbacks.component";

const SEARCH_PARAM_KEY = "recordingPeriodId";

interface IStakeholderFeedbackOverviewComponentProps {
  stakeholderRecordingPeriods: IStakeholderRecordingPeriods;
  stakeholderId: string;
}

export const StakeholderFeedbackOverviewComponent: FC<
  IStakeholderFeedbackOverviewComponentProps
> = ({ stakeholderRecordingPeriods, stakeholderId }) => {
  const { t } = useTranslation("stakeholder_feedback_overview_component");

  return (
    <Box width={1200} maxWidth="100%" mx="auto">
      {/* Page title */}
      <Typography variant="h1">
        {t("title", { name: stakeholderRecordingPeriods.organizationName })}
      </Typography>

      {/* Display message if no RPs could be found*/}
      {stakeholderRecordingPeriods.recordingPeriods.length === 0 && (
        <Typography sx={{ mt: 2 }}>
          {t("subtitle_no_rps", { organizationName: stakeholderRecordingPeriods.organizationName })}
        </Typography>
      )}

      {/* There is at least a single recording period --> render dropdown + everything else */}
      {stakeholderRecordingPeriods.recordingPeriods.length > 0 && (
        <StakeholderFeedbackDetailsComponent
          stakeholderRecordingPeriods={stakeholderRecordingPeriods}
          stakeholderId={stakeholderId}
        />
      )}
    </Box>
  );
};

interface IStakeholderFeedbackDetailsComponentProps {
  stakeholderRecordingPeriods: IStakeholderRecordingPeriods;
  stakeholderId: string;
}

export const StakeholderFeedbackDetailsComponent: FC<IStakeholderFeedbackDetailsComponentProps> = ({
  stakeholderRecordingPeriods,
  stakeholderId,
}) => {
  const { t } = useTranslation("stakeholder_feedback_details_component");

  // Search param handling
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchParamValue = useMemo(
    () => searchParams.get(SEARCH_PARAM_KEY) ?? null,
    [searchParams],
  );

  const isValidSearchParam = useMemo(
    () =>
      stakeholderRecordingPeriods.recordingPeriods.some((rp) => rp.id === currentSearchParamValue),
    [stakeholderRecordingPeriods.recordingPeriods, currentSearchParamValue],
  );

  useEffect(() => {
    // not a valid search param -> default to first id
    // no need to check whether RPs are available as this will only be rendered if there is at least one :)
    if (!isValidSearchParam) {
      setSearchParams(
        (params) => {
          params.set(SEARCH_PARAM_KEY, stakeholderRecordingPeriods.recordingPeriods[0].id);
          return params;
        },
        { replace: true },
      );
    }
  }, [stakeholderRecordingPeriods.recordingPeriods, isValidSearchParam, setSearchParams]);

  const selectOptions = useMemo(
    () =>
      stakeholderRecordingPeriods.recordingPeriods.map((rp) => ({
        value: rp.id,
        display: rp.name,
      })),
    [stakeholderRecordingPeriods.recordingPeriods],
  );

  // Query for feedback (only enabled if search param is valid)
  const feedbackQuery = useStakeholderFeedbackQuery(
    stakeholderId,
    currentSearchParamValue!,
    isValidSearchParam,
  );

  return (
    <>
      {/* RP select */}
      <Box display="flex" flexDirection="row" gap={2} alignItems="center" mt={1}>
        <Typography variant="h6">{t("select_title")}</Typography>
        <TypographySelectComponent
          onValueChange={(newValue) => {
            setSearchParams(
              (params) => {
                params.set(SEARCH_PARAM_KEY, newValue);
                return params;
              },
              { replace: true },
            );
          }}
          options={selectOptions}
          typographyVariant="h6"
          value={currentSearchParamValue ?? ""}
        />
      </Box>

      {/* Render feedback if RP is valid */}
      {isValidSearchParam && (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <QueryWrapper
            query={feedbackQuery}
            build={(stakeholderFeedback) => (
              <StakeholderFeedbacksComponent
                stakeholderId={stakeholderId}
                recordingPeriodId={currentSearchParamValue!}
                details={stakeholderFeedback}
              />
            )}
          />
        </Box>
      )}
    </>
  );
};
