/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IFinancialEffectEffectTypeEnum = {
    InvestmentCosts: 'investment_costs',
    OperationsCosts: 'operations_costs',
    CapitalCosts: 'capital_costs',
    AssetsValueChange: 'assets_value_change',
    Revenue: 'revenue',
    AccessToCapital: 'access_to_capital'
} as const;

export type IFinancialEffectEffectTypeEnum = typeof IFinancialEffectEffectTypeEnum[keyof typeof IFinancialEffectEffectTypeEnum];



