import { DMAUtilities } from "./dma.utilities";
import {
  IDMAConfigurationProbabilityOfOccurrenceSteps,
  IDMAFinancialEffectHorizonData,
} from "@netcero/netcero-core-api-client";
import { IFinancialEffectCalculatorData } from "../types/financial-effect-calculator-data.interface";

export class DMAFinancialEffectUtils {
  public static convertHorizonForCalculation(
    materialityThreshold: number,
    horizon: IDMAFinancialEffectHorizonData,
    steps: IDMAConfigurationProbabilityOfOccurrenceSteps | null,
  ): IFinancialEffectCalculatorData {
    return {
      ...horizon,
      probabilityOfOccurrence: DMAUtilities.getProbabilitySliderValue(
        horizon.probabilityOfOccurrence,
        steps,
      ),
      materialityThreshold,
    };
  }

  public static convertHorizonForCalculationOptionalSteps(
    materialityThreshold: number,
    horizon: IDMAFinancialEffectHorizonData,
    steps: IDMAConfigurationProbabilityOfOccurrenceSteps | undefined,
  ): IFinancialEffectCalculatorData {
    return DMAFinancialEffectUtils.convertHorizonForCalculation(
      materialityThreshold,
      horizon,
      steps ?? null,
    );
  }
}
