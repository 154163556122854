import { IJwtPayload } from "@netcero/netcero-common";

export class AuthenticationUtilities {
  /**
   * Extracts the Keycloak organization ID from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   * @returns The Keycloak organization ID or null if not found.
   */
  static findKeycloakOrganizationIdForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ): string | null {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    for (const [keycloakOrganizationId, mappingData] of Object.entries(organizationMapping)) {
      if (mappingData.attributes.netcero_id.includes(netCeroOrganizationId)) {
        return keycloakOrganizationId;
      }
    }

    return null;
  }

  /**
   * Extracts the organization data from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   */
  static findOrganizationDataForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    return (
      Object.values(organizationMapping).find((mappingData) =>
        mappingData.attributes.netcero_id.includes(netCeroOrganizationId),
      ) ?? null
    );
  }
}
