/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ICommitOrganizationStructureDraftForRecordingPeriod404Response } from '../models';
// @ts-ignore
import { ICommitOrganizationStructureDraftForRecordingPeriod409Response } from '../models';
// @ts-ignore
import { IListDistributionCriteria404Response } from '../models';
// @ts-ignore
import { IOrganizationStructureDetailed } from '../models';
// @ts-ignore
import { IOrganizationStructurePayload } from '../models';
// @ts-ignore
import { IUpdateOrganizationStructureForRecordingPeriod400Response } from '../models';
// @ts-ignore
import { IUpdateOrganizationStructureForRecordingPeriod404Response } from '../models';
/**
 * OrganizationStructureApi - axios parameter creator
 * @export
 */
export const OrganizationStructureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Commits the current draft of the organization structure. Editing the draft is not possible after this.
         * @summary Commit organization structure draft.
         * @param {string} organizationId The id of the organization the organization structures belongs to.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitOrganizationStructureDraftForRecordingPeriod: async (organizationId: string, recordingPeriodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('commitOrganizationStructureDraftForRecordingPeriod', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('commitOrganizationStructureDraftForRecordingPeriod', 'recordingPeriodId', recordingPeriodId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/organization-structure/commit`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the Organization Structure for a given recording period.
         * @summary Get Organization Structure for recording period.
         * @param {string} organizationId The id of the organization to get the organization structures for.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationStructureForRecordingPeriod: async (organizationId: string, recordingPeriodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationStructureForRecordingPeriod', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('getOrganizationStructureForRecordingPeriod', 'recordingPeriodId', recordingPeriodId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/organization-structure`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Overwrites the current organization structure data.
         * @summary Update an organization structure.
         * @param {string} organizationId The id of the organization the organization structures belongs to.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {IOrganizationStructurePayload} [iOrganizationStructurePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationStructureForRecordingPeriod: async (organizationId: string, recordingPeriodId: string, iOrganizationStructurePayload?: IOrganizationStructurePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationStructureForRecordingPeriod', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateOrganizationStructureForRecordingPeriod', 'recordingPeriodId', recordingPeriodId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/organization-structure`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrganizationStructurePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationStructureApi - functional programming interface
 * @export
 */
export const OrganizationStructureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationStructureApiAxiosParamCreator(configuration)
    return {
        /**
         * Commits the current draft of the organization structure. Editing the draft is not possible after this.
         * @summary Commit organization structure draft.
         * @param {string} organizationId The id of the organization the organization structures belongs to.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitOrganizationStructureDraftForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizationStructureDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitOrganizationStructureDraftForRecordingPeriod(organizationId, recordingPeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the Organization Structure for a given recording period.
         * @summary Get Organization Structure for recording period.
         * @param {string} organizationId The id of the organization to get the organization structures for.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizationStructureDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationStructureForRecordingPeriod(organizationId, recordingPeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Overwrites the current organization structure data.
         * @summary Update an organization structure.
         * @param {string} organizationId The id of the organization the organization structures belongs to.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {IOrganizationStructurePayload} [iOrganizationStructurePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, iOrganizationStructurePayload?: IOrganizationStructurePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizationStructureDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationStructureForRecordingPeriod(organizationId, recordingPeriodId, iOrganizationStructurePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationStructureApi - factory interface
 * @export
 */
export const OrganizationStructureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationStructureApiFp(configuration)
    return {
        /**
         * Commits the current draft of the organization structure. Editing the draft is not possible after this.
         * @summary Commit organization structure draft.
         * @param {string} organizationId The id of the organization the organization structures belongs to.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitOrganizationStructureDraftForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: any): AxiosPromise<IOrganizationStructureDetailed> {
            return localVarFp.commitOrganizationStructureDraftForRecordingPeriod(organizationId, recordingPeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the Organization Structure for a given recording period.
         * @summary Get Organization Structure for recording period.
         * @param {string} organizationId The id of the organization to get the organization structures for.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: any): AxiosPromise<IOrganizationStructureDetailed> {
            return localVarFp.getOrganizationStructureForRecordingPeriod(organizationId, recordingPeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * Overwrites the current organization structure data.
         * @summary Update an organization structure.
         * @param {string} organizationId The id of the organization the organization structures belongs to.
         * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
         * @param {IOrganizationStructurePayload} [iOrganizationStructurePayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, iOrganizationStructurePayload?: IOrganizationStructurePayload, options?: any): AxiosPromise<IOrganizationStructureDetailed> {
            return localVarFp.updateOrganizationStructureForRecordingPeriod(organizationId, recordingPeriodId, iOrganizationStructurePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationStructureApi - interface
 * @export
 * @interface OrganizationStructureApi
 */
export interface OrganizationStructureApiInterface {
    /**
     * Commits the current draft of the organization structure. Editing the draft is not possible after this.
     * @summary Commit organization structure draft.
     * @param {string} organizationId The id of the organization the organization structures belongs to.
     * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationStructureApiInterface
     */
    commitOrganizationStructureDraftForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): AxiosPromise<IOrganizationStructureDetailed>;

    /**
     * Gets the Organization Structure for a given recording period.
     * @summary Get Organization Structure for recording period.
     * @param {string} organizationId The id of the organization to get the organization structures for.
     * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationStructureApiInterface
     */
    getOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig): AxiosPromise<IOrganizationStructureDetailed>;

    /**
     * Overwrites the current organization structure data.
     * @summary Update an organization structure.
     * @param {string} organizationId The id of the organization the organization structures belongs to.
     * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
     * @param {IOrganizationStructurePayload} [iOrganizationStructurePayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationStructureApiInterface
     */
    updateOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, iOrganizationStructurePayload?: IOrganizationStructurePayload, options?: AxiosRequestConfig): AxiosPromise<IOrganizationStructureDetailed>;

}

/**
 * OrganizationStructureApi - object-oriented interface
 * @export
 * @class OrganizationStructureApi
 * @extends {BaseAPI}
 */
export class OrganizationStructureApi extends BaseAPI implements OrganizationStructureApiInterface {
    /**
     * Commits the current draft of the organization structure. Editing the draft is not possible after this.
     * @summary Commit organization structure draft.
     * @param {string} organizationId The id of the organization the organization structures belongs to.
     * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationStructureApi
     */
    public commitOrganizationStructureDraftForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig) {
        return OrganizationStructureApiFp(this.configuration).commitOrganizationStructureDraftForRecordingPeriod(organizationId, recordingPeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the Organization Structure for a given recording period.
     * @summary Get Organization Structure for recording period.
     * @param {string} organizationId The id of the organization to get the organization structures for.
     * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationStructureApi
     */
    public getOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, options?: AxiosRequestConfig) {
        return OrganizationStructureApiFp(this.configuration).getOrganizationStructureForRecordingPeriod(organizationId, recordingPeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Overwrites the current organization structure data.
     * @summary Update an organization structure.
     * @param {string} organizationId The id of the organization the organization structures belongs to.
     * @param {string} recordingPeriodId The id of the recording period to get the organization structure for.
     * @param {IOrganizationStructurePayload} [iOrganizationStructurePayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationStructureApi
     */
    public updateOrganizationStructureForRecordingPeriod(organizationId: string, recordingPeriodId: string, iOrganizationStructurePayload?: IOrganizationStructurePayload, options?: AxiosRequestConfig) {
        return OrganizationStructureApiFp(this.configuration).updateOrganizationStructureForRecordingPeriod(organizationId, recordingPeriodId, iOrganizationStructurePayload, options).then((request) => request(this.axios, this.basePath));
    }
}
