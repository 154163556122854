import { Box, Card, Fab, Fade, IconButton, SvgIcon, Tooltip, Typography } from "@mui/material";
import {
  IDataEntryObject,
  IDataEntryObjectData,
  IOrganizationStructureDetailedOneOf,
  IOrganizationStructureDetailedOneOf1,
} from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionsDialog } from "../common/components/options.dialog";
import { CloseIcon, IconSize, PlusIcon } from "../common/constants/tabler-icon.constants";
import { useResetMutationsOnOpen } from "../common/hooks/use-reset-mutations-on-open.hook";
import { CoreApiService } from "../core-api/core-api.service";
import { DataEntryObjectEditDialog } from "../data-entry-objects/data-entry-object-edit.dialog";
import { ILocalOrganization } from "../organizations/local-organization.interface";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import { OrganizationStructureTree } from "./organization-structure-tree/organization-structure-tree.component";
import { useUpdateOrganizationStructureMutation } from "./organization-structures.mutations";
import { getRecordingPeriodOrganizationStructureQueryKey } from "./organization-structures.queries";
import { OrganizationStructuresUtilities } from "./organization-structures.utilities";
import { TreeUtilities } from "../common/utilities/tree.utilities";
import { useKeycloakOrganizationData } from "../user/hooks/use-keycloak-organization-data.hook";

enum OrganizationStructureTreeEditorMode {
  View,
  ChooseParentOfNewDEO,
  ChangeParentOfDEO,
}

interface IOrganizationStructureTreeEditorProps {
  organization: ILocalOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  organizationStructure: IOrganizationStructureDetailedOneOf | IOrganizationStructureDetailedOneOf1;
}

export const OrganizationStructureTreeEditor: FC<IOrganizationStructureTreeEditorProps> = ({
  organization,
  recordingPeriod,
  organizationStructure,
}) => {
  const { t } = useTranslation("organization_structure_tree_editor_component");

  const isDraft = useMemo(() => organizationStructure.isDraft, [organizationStructure]);

  const organizationData = useKeycloakOrganizationData(organization.id);

  const maxAllowedDEOCount = useMemo(
    () => organizationData?.attributes.deo_count_max ?? 0,
    [organizationData],
  );
  const deoCount = useMemo(
    () =>
      TreeUtilities.countNodes(
        organizationStructure.isDraft
          ? (organizationStructure as IOrganizationStructureDetailedOneOf1).structureDraft
          : (organizationStructure as IOrganizationStructureDetailedOneOf).structure,
        (node) => node.children,
      ),
    [organizationStructure],
  );

  const [editorMode, setEditorMode] = useState(OrganizationStructureTreeEditorMode.View);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editDialogDataEntryObject, setEditDialogDataEntryObject] =
    useState<IDataEntryObject | null>(null);

  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  const rootDataEntryObject = useMemo(() => {
    if (!organizationStructure.isDraft) {
      return (organizationStructure as IOrganizationStructureDetailedOneOf).structure;
    }

    // Needed since OrgTree requires ids to be set
    return OrganizationStructuresUtilities.convertDraftDataEntryObjectsIntoDataEntryObjects(
      (organizationStructure as IOrganizationStructureDetailedOneOf1).structureDraft,
    );
  }, [organizationStructure]);

  const queryClient = useQueryClient();

  const updateDataEntryObjectMutation = useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      payload,
    }: {
      organizationId: string;
      recordingPeriodId: string;
      dataEntryObjectId: string;
      payload: IDataEntryObjectData;
    }) => {
      return CoreApiService.DataEntryObjectApi.updateDataEntryObject(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        {
          name: payload.name,
          description: payload.description || undefined,
          inheritsValuesFrom: payload.inheritsValuesFrom,
          objectType: payload.objectType,
          partOfValueChain: payload.partOfValueChain,
          operationalControl: payload.operationalControl,
          financiallyConsolidated: payload.financiallyConsolidated,
          country: payload.country,
          shareHeldByParent: payload.shareHeldByParent,
        },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getRecordingPeriodOrganizationStructureQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });

  const updateDraftMutation = useUpdateOrganizationStructureMutation();

  // Reset Mutations on Dialog Open
  useResetMutationsOnOpen(showEditDialog, updateDataEntryObjectMutation, updateDraftMutation);

  const handleCloseEditDialog = async (dataEntryObject: IDataEntryObjectData | null) => {
    // Data Entry Object was changed
    if (dataEntryObject !== null) {
      if (isDraft) {
        const newTree = OrganizationStructuresUtilities.updateDataEntryObjectInDraftTree(
          editDialogDataEntryObject!.id,
          dataEntryObject,
          rootDataEntryObject,
        );

        await updateDraftMutation.mutateAsync({
          organizationId: organization.id,
          recordingPeriodId: recordingPeriod.id,
          payload: {
            structureDraft: OrganizationStructuresUtilities.transformTreeToApiPayload(newTree),
          },
        });
      } else {
        // Handle Update Published
        await updateDataEntryObjectMutation.mutateAsync({
          organizationId: organization.id,
          recordingPeriodId: recordingPeriod.id,
          dataEntryObjectId: editDialogDataEntryObject!.id,
          payload: dataEntryObject,
        });
      }
    }
    setShowEditDialog(false);
  };

  const isDEOCountLimitReached = useMemo(
    () => deoCount >= maxAllowedDEOCount,
    [deoCount, maxAllowedDEOCount],
  );
  const canAddDataEntryObject = isDraft;
  const handleClickAddDataEntryObject = () => {
    setEditorMode(OrganizationStructureTreeEditorMode.ChooseParentOfNewDEO);
  };
  const handleCancelAddDataEntryObject = () => {
    setEditorMode(OrganizationStructureTreeEditorMode.View);
  };

  const newDataEntryObjectParentRef = useRef<IDataEntryObject | null>(null);
  const handleClickDataEntryObject = async (dataEntryObject: IDataEntryObject) => {
    switch (editorMode) {
      case OrganizationStructureTreeEditorMode.View:
        setEditDialogDataEntryObject(dataEntryObject);
        setShowEditDialog(true);
        break;
      case OrganizationStructureTreeEditorMode.ChooseParentOfNewDEO:
        newDataEntryObjectParentRef.current = dataEntryObject;
        setShowCreateDialog(true);
        break;
      case OrganizationStructureTreeEditorMode.ChangeParentOfDEO:
        await handleChooseNewParent(dataEntryObject);
        break;
    }
  };

  const handleCloseCreateChildDialog = async (dataEntryObject: IDataEntryObjectData | null) => {
    // Handle Create New Child
    if (dataEntryObject) {
      if (!isDraft) {
        throw new Error("Cannot create child on published organization structure!");
      }

      const newTree = OrganizationStructuresUtilities.addDataEntryObjectToDraftTree(
        newDataEntryObjectParentRef.current!.id,
        {
          ...dataEntryObject,
          children: [],
        },
        rootDataEntryObject,
      );

      await updateDraftMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: recordingPeriod.id,
        payload: {
          structureDraft: OrganizationStructuresUtilities.transformTreeToApiPayload(newTree),
        },
      });
      // Reset Editor State on successful creation
      setEditorMode(OrganizationStructureTreeEditorMode.View);
    }
    // Reset State
    newDataEntryObjectParentRef.current = null;
    setShowCreateDialog(false);
  };

  const canDeleteDataEntryObject =
    isDraft &&
    editDialogDataEntryObject !== null &&
    // Root Data Entry Object cannot be deleted/removed!
    editDialogDataEntryObject?.id !== rootDataEntryObject?.id;
  const handleDeleteDataEntryObject = async () => {
    setShowConfirmDeleteDialog(true);
  };

  const handleConfirmDeleteDataEntryObject = async () => {
    const newTree = OrganizationStructuresUtilities.removeDataEntryObjectFromDraftTree(
      editDialogDataEntryObject!.id,
      rootDataEntryObject,
    )!; // Will never be null since root node cannot be deleted

    await updateDraftMutation.mutateAsync({
      organizationId: organization.id,
      recordingPeriodId: recordingPeriod.id,
      payload: {
        structureDraft: OrganizationStructuresUtilities.transformTreeToApiPayload(newTree),
      },
    });

    setShowConfirmDeleteDialog(false);
    setShowEditDialog(false);
  };

  const canChangeDataEntryObjectParent =
    isDraft &&
    editDialogDataEntryObject !== null &&
    // Root Data Entry Object cannot be moved!
    editDialogDataEntryObject?.id !== rootDataEntryObject?.id;
  const handleChangeDataEntryObjectParent = () => {
    setEditorMode(OrganizationStructureTreeEditorMode.ChangeParentOfDEO);
    setShowEditDialog(false);
  };
  const handleCancelChangeDataEntryObjectParent = () => {
    setEditorMode(OrganizationStructureTreeEditorMode.View);
    setShowEditDialog(true);
  };

  const handleChooseNewParent = async (newParent: IDataEntryObject) => {
    // Handle Parent is DataEntryObject itself
    if (newParent.id === editDialogDataEntryObject?.id) {
      displayErrorDialog("error_cannot_set_deo_as_its_own_parent");
      return;
    }

    // Handle Parent is current Parent
    if (
      OrganizationStructuresUtilities.isDataEntryObjectDirectChildOfDataEntryObject(
        newParent,
        editDialogDataEntryObject!.id,
      )
    ) {
      displayErrorDialog("error_selected_deo_is_already_parent_of_deo_to_be_moved");
      return;
    }

    // Handle new Parent is Child of DataEntryObject to move
    if (
      OrganizationStructuresUtilities.isDataEntryObjectChildOfDataEntryObject(
        editDialogDataEntryObject!,
        newParent.id,
      )
    ) {
      displayErrorDialog("error_selected_new_parent_cannot_be_child_of_deo_to_be_moved");
      return;
    }

    // Move DEO in Draft Tree
    const newTree = OrganizationStructuresUtilities.moveDataEntryObjectInDraftTree(
      editDialogDataEntryObject!,
      newParent.id,
      rootDataEntryObject,
    );

    await updateDraftMutation.mutateAsync({
      organizationId: organization.id,
      recordingPeriodId: recordingPeriod.id,
      payload: {
        structureDraft: OrganizationStructuresUtilities.transformTreeToApiPayload(newTree),
      },
    });

    // Reset Editor State on successful creation
    setEditorMode(OrganizationStructureTreeEditorMode.View);
    setShowEditDialog(false);
    setEditDialogDataEntryObject(null);
  };

  // #### Error Dialog ####
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorDialogMessageKey, setErrorDialogMessageKey] = useState("");

  const displayErrorDialog = (messageKey: string) => {
    setErrorDialogMessageKey(messageKey);
    setShowErrorDialog(true);
  };

  const disabled = updateDataEntryObjectMutation.isPending || updateDraftMutation.isPending;

  return (
    <>
      {/* Edit DataEntryObject Dialog */}
      <DataEntryObjectEditDialog
        open={showEditDialog}
        loading={updateDataEntryObjectMutation.isPending || updateDraftMutation.isPending}
        error={
          updateDataEntryObjectMutation.isError
            ? updateDataEntryObjectMutation.error
            : updateDraftMutation.isError
            ? updateDraftMutation.error
            : undefined
        }
        dataEntryObject={editDialogDataEntryObject ?? undefined}
        onClose={handleCloseEditDialog}
        onChangeParent={
          canChangeDataEntryObjectParent ? handleChangeDataEntryObjectParent : undefined
        }
        onDelete={canDeleteDataEntryObject ? handleDeleteDataEntryObject : undefined}
        disabled={disabled}
      />

      {/* Create DataEntryObject Dialog */}
      <DataEntryObjectEditDialog
        open={showCreateDialog}
        loading={updateDraftMutation.isPending}
        error={updateDraftMutation.isError ? updateDraftMutation.error : undefined}
        onClose={handleCloseCreateChildDialog}
        disabled={disabled}
      />

      {/* Confirm Delete DataEntryObject Dialog */}
      <OptionsDialog
        open={showConfirmDeleteDialog}
        title={t("delete_confirm_dialog_title")}
        content={t("delete_confirm_dialog_content", {
          dataEntryObjectName: editDialogDataEntryObject?.name,
        })}
        options={[
          {
            label: t("delete_confirm_dialog_button_cancel"),
            onClick: () => setShowConfirmDeleteDialog(false),
          },
          {
            label: t("delete_confirm_dialog_button_delete"),
            onClick: handleConfirmDeleteDataEntryObject,
            buttonProps: {
              variant: "contained",
            },
          },
        ]}
      />

      {/* Error Dialog */}
      <OptionsDialog
        open={showErrorDialog}
        content={<Box textAlign="center">{t(errorDialogMessageKey)}</Box>}
        options={[
          {
            label: t("error_dialog_button_ok"),
            onClick: () => setShowErrorDialog(false),
          },
        ]}
      />

      {/* Content */}
      <Box>
        <Card sx={{ position: "relative" }}>
          {/* Top Right Corner Actions */}
          {canAddDataEntryObject &&
            (editorMode === OrganizationStructureTreeEditorMode.View ||
              editorMode === OrganizationStructureTreeEditorMode.ChooseParentOfNewDEO) && (
              <Box position="absolute" p={2}>
                {editorMode === OrganizationStructureTreeEditorMode.ChooseParentOfNewDEO && (
                  <Fade key="cancel-add-child" in appear>
                    <Card
                      variant="elevation"
                      elevation={4}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        p: 1,
                        pl: 2,
                      }}
                    >
                      {t("add_child_mode_text")}
                      <Tooltip title={t("add_child_cancel_tooltip")}>
                        <IconButton
                          color="error"
                          size="small"
                          onClick={handleCancelAddDataEntryObject}
                        >
                          <SvgIcon>
                            <CloseIcon size={IconSize.Medium} />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </Card>
                  </Fade>
                )}
                {editorMode === OrganizationStructureTreeEditorMode.View && (
                  <Fade key="add-child" in appear>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Tooltip
                        title={t(
                          !isDEOCountLimitReached ? "add_child_tooltip" : "deo_limit_reached",
                        )}
                        placement="right"
                      >
                        <span>
                          <Fab
                            size="small"
                            color="primary"
                            onClick={handleClickAddDataEntryObject}
                            aria-label="add"
                            disabled={isDEOCountLimitReached}
                          >
                            <SvgIcon>
                              <PlusIcon size={IconSize.Medium} />
                            </SvgIcon>
                          </Fab>
                        </span>
                      </Tooltip>
                      <Typography variant="body2">
                        {t("deo_count_text", {
                          currentCount: deoCount,
                          maxCount: maxAllowedDEOCount,
                        })}
                      </Typography>
                    </Box>
                  </Fade>
                )}
              </Box>
            )}

          {/* Choose New Parent Notification */}
          {editorMode === OrganizationStructureTreeEditorMode.ChangeParentOfDEO && (
            <Fade key="change-parent" in appear>
              <Card
                variant="elevation"
                elevation={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  p: 1,
                  pl: 2,
                  position: "absolute",
                  m: 2,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {t("change_parent_mode_text")}
                <Tooltip title={t("change_parent_cancel_tooltip")}>
                  <IconButton
                    color="error"
                    size="small"
                    onClick={handleCancelChangeDataEntryObjectParent}
                  >
                    <SvgIcon>
                      <CloseIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </Card>
            </Fade>
          )}

          {/* Display Tree */}
          <Box height="calc(100vh - 330px)">
            <OrganizationStructureTree
              rootDataEntryObject={rootDataEntryObject}
              onSelectDataEntryObject={handleClickDataEntryObject}
            />
          </Box>
        </Card>
      </Box>
    </>
  );
};
