/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IAddressedMatterE4Enum = {
    DirectImpactDriversOfBiodiversityLossMemberEsrsClimateChangeMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:ClimateChangeMember',
    DirectImpactDriversOfBiodiversityLossMemberEsrsLandUseChangeMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:LandUseChangeMember',
    DirectImpactDriversOfBiodiversityLossMemberEsrsFreshWateruseChangeMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:FreshWateruseChangeMember',
    DirectImpactDriversOfBiodiversityLossMemberEsrsSeauseChangeMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:SeauseChangeMember',
    DirectImpactDriversOfBiodiversityLossMemberEsrsDirectExploitationMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:DirectExploitationMember',
    DirectImpactDriversOfBiodiversityLossMemberEsrsInvasiveAlienSpeciesMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:InvasiveAlienSpeciesMember',
    DirectImpactDriversOfBiodiversityLossMemberEsrsPollutionMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:PollutionMember',
    DirectImpactDriversOfBiodiversityLossMemberEsrsOtherDirectImpactDriversOfBiodiversityLossMember: 'esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:OtherDirectImpactDriversOfBiodiversityLossMember',
    ImpactsOnStateOfSpeciesMember: 'esrs:ImpactsOnStateOfSpeciesMember',
    ImpactsOnExtentAndConditionOfEcosystemsMember: 'esrs:ImpactsOnExtentAndConditionOfEcosystemsMember',
    ImpactsAndDependenciesOnEcosystemServicesMember: 'esrs:ImpactsAndDependenciesOnEcosystemServicesMember',
    OtherBiodiversityAndEcosystemsSubtopicMember: 'esrs:OtherBiodiversityAndEcosystemsSubtopicMember'
} as const;

export type IAddressedMatterE4Enum = typeof IAddressedMatterE4Enum[keyof typeof IAddressedMatterE4Enum];



