import { getIcon } from "@iconify/react";
import { Box } from "@mui/material";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { useQuery } from "@tanstack/react-query";
import { OrgChart } from "d3-org-chart";
import { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useObserveSize } from "../../common/hooks/use-observe-size.hook";
import { DataEntryObjectsAppUtilities } from "../../data-entry-objects/data-entry-objects-app.utilities";
import "./organization-structure-tree.component.css";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { DataEntryObjectsCommonUtilities } from "@netcero/netcero-common";

interface ITreeNode {
  id: string;
  parentNodeId: string | null;
  dataEntryObject: IDataEntryObject;
}

export interface IOrganizationStructureTreeProps {
  rootDataEntryObject: IDataEntryObject;
  compactLayout?: boolean;
  onSelectDataEntryObject: (dataEntryObject: IDataEntryObject) => void;
}

/**
 * Component that renders an organization structure tree.
 * @param rootDataEntryObject The root data entry object.
 * @param verticalDifference Will be subtracted from the viewport height to calculate the height of the tree container.
 * @param compactLayout True by default. Whether the tree should be rendered in compact mode.
 * @param onSelectDataEntryObject Callback that will be called when a data entry object is clicked.
 */
export const OrganizationStructureTree: FC<IOrganizationStructureTreeProps> = ({
  rootDataEntryObject,
  compactLayout = true,
  onSelectDataEntryObject,
}) => {
  const { t } = useTranslation("organization_structure_tree_component");

  const d3Container = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef(new OrgChart<ITreeNode>());

  const iconsQuery = useQuery({
    queryKey: ["data-entry-node-icons"],
    queryFn: () => {
      return DataEntryObjectsAppUtilities.loadIcons();
    },
  });

  const treeNodes = useMemo(() => {
    const treeNodes: ITreeNode[] = [
      { id: rootDataEntryObject.id, parentNodeId: null, dataEntryObject: rootDataEntryObject },
    ];
    for (let i = 0; i < treeNodes.length; i++) {
      const treeNode = treeNodes[i];
      if (treeNode.dataEntryObject.children) {
        treeNodes.push(
          ...treeNode.dataEntryObject.children.map((child) => ({
            id: child.id,
            parentNodeId: treeNode.id,
            dataEntryObject: child,
          })),
        );
      }
    }

    return treeNodes;
  }, [rootDataEntryObject]);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const handleResize = useCallback((entry: ResizeObserverEntry[]) => {
    setDimensions({
      width: entry[0]?.contentRect.width ?? 0,
      height: entry[0]?.contentRect.height ?? 0,
    });
  }, []);
  useObserveSize(d3Container, handleResize);

  useLayoutEffect(() => {
    if (iconsQuery.isFetched && treeNodes && d3Container.current) {
      chartRef.current
        .container(d3Container.current as unknown as string)
        .data(treeNodes)
        .nodeWidth(() => 320)
        .nodeHeight(
          (node) =>
            120 +
            (DataEntryObjectsCommonUtilities.doesShareHeldByParentDeviate(node.data.dataEntryObject)
              ? 30
              : 0),
        )
        .svgHeight(Math.max(dimensions.height, 0))
        .expandAll()
        .compact(compactLayout)
        .nodeContent(({ data }) => {
          const { objectType } = data.dataEntryObject;

          const iconName = DataEntryObjectsAppUtilities.getIconForObjectType(objectType);
          const icon = getIcon(iconName);

          const typeName = t(`node_type_${objectType}`);

          let shareHeldByParentSection = "";

          if (DataEntryObjectsCommonUtilities.doesShareHeldByParentDeviate(data.dataEntryObject)) {
            shareHeldByParentSection = `
              <div class="data-entry-object-share-held-by-parent">
                <span>${FormatUtilities.formatPercentage(
                  data.dataEntryObject.shareHeldByParent * 100,
                  0,
                )} %</span>
              </div>
            `;
          }

          return `
            <div class="data-entry-object-container ${objectType}">
              ${shareHeldByParentSection}
              <div class="data-entry-object-icon-container">
                ${
                  icon
                    ? `<svg height="${icon.height}" width="${icon.width}">${icon.body}</svg>`
                    : ""
                }
                <p>${typeName}</p>
              </div>
              <h3>${data.dataEntryObject.name}</h3>
            </div>
          `;
        })
        .onNodeClick((node) => {
          onSelectDataEntryObject(node.data.dataEntryObject);
        })
        .render();
    }
  }, [iconsQuery.isFetched, treeNodes, onSelectDataEntryObject, dimensions, compactLayout, t]);

  return <Box ref={d3Container} style={{ width: "100%", height: "100%", overflow: "hidden" }} />;
};
