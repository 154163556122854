import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { useCallback } from "react";
import { IPolicy } from "@netcero/netcero-core-api-client";

export const getPoliciesQueryKey = (organizationId: string, recordingPeriodId: string) => [
  "policies",
  organizationId,
  recordingPeriodId,
];

export const usePoliciesQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => {
  const filterPoliciesByDEO = useCallback(
    // no need to check other properties as they are contained in the initial query
    (policies: IPolicy[]) => policies.filter((p) => p.dataEntryObjectId === dataEntryObjectId),
    [dataEntryObjectId],
  );

  return useQuery({
    queryKey: getPoliciesQueryKey(organizationId, recordingPeriodId),
    queryFn: () =>
      CoreApiService.PoliciesApi.getPolicies(organizationId, recordingPeriodId)
        .then((req) => req())
        .then((res) => res.data.policies),
    select: filterPoliciesByDEO,
  });
};
