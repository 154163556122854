import { Icon } from "@iconify/react";
import { Box, Breadcrumbs, IconButton, Menu, MenuItem } from "@mui/material";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { FC, useMemo, useRef, useState } from "react";
import { Predicate } from "../../common/interfaces/predicate.type";
import { DataEntryObjectsAppUtilities } from "../../data-entry-objects/data-entry-objects-app.utilities";
import { OrganizationStructureNavigationUtilities } from "./organization-structur-navigation.utilities";
import { ChevronDownIcon } from "../../common/constants/tabler-icon.constants";

interface IOrganizationStructureBreadcrumbsProps {
  organizationStructure: IDataEntryObject;
  dataEntryObjectId: string;
  onChange: (dataEntryObjectId: string) => void;
  isDataEntryObjectSelectable?: Predicate<IDataEntryObject>;
}

export const OrganizationStructureBreadcrumbs: FC<IOrganizationStructureBreadcrumbsProps> = ({
  organizationStructure,
  dataEntryObjectId,
  onChange,
  isDataEntryObjectSelectable,
}) => {
  const pathToDataEntryObject = useMemo(() => {
    return OrganizationStructureNavigationUtilities.getDEOsPathForTreeToChild(
      organizationStructure,
      dataEntryObjectId,
    );
  }, [organizationStructure, dataEntryObjectId]);

  const [hasSelectableChildren, children] = useMemo(() => {
    const children = pathToDataEntryObject?.[pathToDataEntryObject.length - 1]?.children;
    if (!children) {
      return [false, []];
    }

    const result = children.map((child) => isDataEntryObjectSelectable?.(child) ?? true);
    return [result.some((isSelectable) => isSelectable), children];
  }, [pathToDataEntryObject, isDataEntryObjectSelectable]);

  return (
    <Breadcrumbs>
      {pathToDataEntryObject
        ? pathToDataEntryObject.map((dataEntryObject, index) => (
            <OrganizationStructureBreadcrumb
              key={dataEntryObject.id}
              onSelectChild={(selectedChild) => onChange(selectedChild.id)}
              onSelectParent={() => onChange(dataEntryObject.id)}
              dataEntryObject={dataEntryObject}
              dropdownElements={pathToDataEntryObject[index - 1]?.children}
              isSiblingSelectable={isDataEntryObjectSelectable}
            />
          ))
        : "ERROR"}
      {hasSelectableChildren && (
        <OrganizationStructureBreadcrumb
          onSelectChild={(selectedChild) => onChange(selectedChild.id)}
          dropdownElements={children}
          isSiblingSelectable={isDataEntryObjectSelectable}
        />
      )}
    </Breadcrumbs>
  );
};

// Breadcrumb Item

interface IOrganizationStructureBreadcrumbProps {
  dataEntryObject?: IDataEntryObject | null;
  dropdownElements?: IDataEntryObject[] | null;
  onSelectChild: (selectedChild: IDataEntryObject) => void;
  onSelectParent?: () => void;
  isSiblingSelectable?: Predicate<IDataEntryObject>;
}

const OrganizationStructureBreadcrumb: FC<IOrganizationStructureBreadcrumbProps> = ({
  dataEntryObject,
  dropdownElements,
  onSelectChild,
  onSelectParent,
  isSiblingSelectable,
}) => {
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  const [showMenu, setShowMenu] = useState(false);

  const handleShowDropdown = () => {
    setShowMenu(true);
  };

  const handleClose = (selectedChild: IDataEntryObject | null) => {
    if (selectedChild) {
      onSelectChild(selectedChild);
    }
    setShowMenu(false);
  };

  const [hasSelectableSiblings, selectableSiblingsLookup] = useMemo(() => {
    // Handle no siblings
    if (!dropdownElements) {
      return [false, []];
    }

    const result = dropdownElements.map((child) => isSiblingSelectable?.(child) ?? true);
    return [result.some((isSelectable) => isSelectable), result];
  }, [dropdownElements, isSiblingSelectable]);

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        {dataEntryObject && (
          <>
            <Icon
              icon={DataEntryObjectsAppUtilities.getIconForObjectType(dataEntryObject.objectType)}
            />
            <Box
              onClick={onSelectParent}
              sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
            >
              {dataEntryObject.name}
            </Box>
          </>
        )}
        {hasSelectableSiblings && (
          <IconButton size="small" onClick={handleShowDropdown} ref={dropdownButtonRef}>
            <ChevronDownIcon />
          </IconButton>
        )}
      </Box>

      <Menu
        anchorEl={dropdownButtonRef.current}
        open={showMenu}
        onClose={() => handleClose(null)}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        {dropdownElements?.map((dropdownDeo, index) => (
          <MenuItem
            key={dropdownDeo.id}
            selected={dropdownDeo.id === dataEntryObject?.id}
            onClick={() => onSelectChild(dropdownDeo)}
            disabled={!selectableSiblingsLookup[index]}
            sx={{ display: "flex", gap: 1 }}
          >
            <Icon
              icon={DataEntryObjectsAppUtilities.getIconForObjectType(dropdownDeo.objectType)}
            />
            {dropdownDeo.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
