import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { useSortedOrganizationUsersHook } from "../hooks/use-sorted-organization-users.hook";
import { UserAvatar } from "./user-avatar.component";
import { UserItem } from "./user-item.component";
import { NoUserAvatar } from "./no-user-selected.avatar.component";

interface IOrganizationUserPickerProps {
  organizationId: string;
  value: string | null;
  includeUserIds?: string[];
  excludeUserIds?: string[];
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  variant?: "standard" | "outlined" | "filled";
  onChange: (userData: IUserRepresentation | null) => void;
}

export const OrganizationUserPicker: FC<IOrganizationUserPickerProps> = ({
  organizationId,
  value,
  label,
  placeholder,
  includeUserIds,
  excludeUserIds,
  helperText,
  error,
  disabled,
  readOnly,
  variant,
  onChange,
}) => {
  const { t } = useTranslation("user_picker_components");

  const [sortedUsers, users] = useSortedOrganizationUsersHook(organizationId);

  const shownUsers = useMemo(() => {
    if (excludeUserIds) {
      return sortedUsers?.filter((user) => !excludeUserIds?.includes(user.id!));
    }
    if (includeUserIds) {
      return sortedUsers?.filter((user) => includeUserIds?.includes(user.id!));
    }

    return sortedUsers;
  }, [sortedUsers, excludeUserIds, includeUserIds]);

  const handleOnChange = (userId: string | null) => {
    const selectedUser = users.data?.find((user) => user.id === userId);
    onChange(selectedUser ?? null);
  };

  if (users.isLoading) {
    return <Skeleton variant="rounded" width={240} height={56} />;
  }

  if (users.isError) {
    return <ErrorTextComponent error={users.error} />;
  }

  return (
    <FormControl error={error} sx={{ minWidth: 240 }}>
      <InputLabel id="user-picker-select-label">{label}</InputLabel>
      <Select
        variant={variant}
        value={value ?? ""}
        onChange={(evt) => handleOnChange(evt.target.value)}
        label={label}
        placeholder={placeholder}
        readOnly={readOnly}
        renderValue={(userId) => {
          if (!userId) {
            return null;
          }

          const selectedUser = users.data?.find((user) => user.id === userId);

          if (!selectedUser) {
            return <Box>{t("selected_user_not_found")}</Box>;
          }

          return (
            <Box display="flex" alignItems="center" gap={1} mt={-0.5} mb={-1}>
              <UserAvatar user={selectedUser} />
              {selectedUser.firstName} {selectedUser.lastName}
            </Box>
          );
        }}
        error={error}
        disabled={disabled}
      >
        <MenuItem value="" sx={{ textAlign: "center" }}>
          <Box display="flex" alignItems="center" gap={1} height={32}>
            <NoUserAvatar />
            {t("no_user_selected")}
          </Box>
        </MenuItem>
        {shownUsers?.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            <UserItem user={user} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
