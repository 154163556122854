import {
  createTheme,
  PaletteColorOptions,
  responsiveFontSizes,
  ThemeOptions,
} from "@mui/material/styles";
import { useMemo } from "react";
import { useCurrentLanguage } from "../modules/content-translation/hooks/current-language.hook";
import { IContentLanguage } from "@netcero/netcero-core-api-client";
import { deDE, Localization } from "@mui/material/locale";

// TODO: Add more styles here

export const CUSTOM_ON_OVERLAY_COLOR = "rgba(255,255,255,0.5)";

export const primary: PaletteColorOptions = {
  main: "#006E79",
  50: "#C2F0FB",
  100: "#A2D8DF",
  200: "#81BDC7",
  300: "#5CA3AE",
  400: "#41919E",
  500: "#187D8B",
  600: "#006E79",
  700: "#005964",
  800: "#004550",
  900: "#00303A",
};

export const secondary: PaletteColorOptions = {
  main: "#2E4959",
  50: "#E6F4FF",
  100: "#C7DCE9",
  200: "#A8C1D0",
  300: "#87A6BA",
  400: "#7194AA",
  500: "#588098",
  600: "#4B7187",
  700: "#3C5D6F",
  800: "#2E4959",
  900: "#1E3441",
  950: "#182832",
};

const grey = {
  "100": "#E1E3E0",
};

export const HOVER_BACKGROUND_COLOR = "#0000000A";

const paletteTheme = createTheme({
  palette: {
    primary,
    secondary,
    background: {
      default: "#FBFBFB",
    },
    grey,
    text: {
      primary: "#393D3F",
    },
  },
});

const DefaultThemeOptions: ThemeOptions = {
  palette: {
    ...paletteTheme.palette,
  },
  typography: {
    htmlFontSize: 10,
    allVariants: {
      fontFamily: '"Montserrat"',
    },
    // TODO: Font sizes need to be made smaller
    h1: {
      fontWeight: "600",
      fontSize: "4rem",
    },
    h2: {
      fontWeight: "600",
      fontSize: "3.5rem",
    },
    h3: {
      fontWeight: "600",
      fontSize: "3rem",
    },
    h4: {
      fontWeight: "600",
      fontSize: "2.5rem",
    },
    h5: {
      fontWeight: "600",
      fontSize: "2rem",
    },
    h6: {
      fontWeight: "600",
      fontSize: "1.75rem",
    },
    subtitle1: {
      fontWeight: "600",
    },
    body1: {
      fontFamily: '"DM Sans"',
    },
    body2: {
      fontFamily: '"DM Sans"',
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          ":hover": {
            transition: "background-color 200ms",
          },
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          padding: 4,
        },
      },
      defaultProps: {
        elevation: 0,
        variant: "outlined",
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ":last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: paletteTheme.palette.primary[800],
          color: paletteTheme.palette.primary.contrastText,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          // Disabled since it breaks other MUI animations (mainly Switch)
          // ":hover": {
          //   transition: "background-color 200ms",
          // },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          transition: "margin-right 0.25s ease-in-out",
        },
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          th: {
            fontWeight: 700,
          },
        },
      },
    },
  },
} as const;

const useLocaleForCurrentLanguage = () => {
  const language = useCurrentLanguage();
  return useMemo((): Localization | null => {
    // TODO: expand with further languages once relevant
    switch (language) {
      case IContentLanguage.De:
        return deDE;
      // No need for translations for EN (be sure to keep this at bottom of switch)
      case IContentLanguage.En:
      default:
        return null;
    }
  }, [language]);
};

export const useNCTheme = () => {
  const locale = useLocaleForCurrentLanguage();
  return useMemo(
    () =>
      responsiveFontSizes(
        locale !== null
          ? createTheme(DefaultThemeOptions, locale)
          : createTheme(DefaultThemeOptions),
      ),
    [locale],
  );
};
