import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "./error-text.component";
import { AxiosError } from "axios";
import { IErrorModel } from "@netcero/netcero-core-api-client";

interface IErrorDialogProps {
  open: boolean;
  onClose: () => void;
  error?: AxiosError | Partial<IErrorModel> | Error;
}
export const ErrorDialog: FC<IErrorDialogProps> = ({ open, onClose, error }) => {
  const { t } = useTranslation("error_dialog");

  // Use internal Error to not hide Error during close animation
  const [internalError, setInternalError] = useState(error);
  useEffect(() => {
    if (error) {
      setInternalError(error);
    }
  }, [error]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("dialog_title")}</DialogTitle>
      <DialogContent>{internalError && <ErrorTextComponent error={internalError} />}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("button_close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
