import { FC, useCallback, useMemo } from "react";
import { ILocalOrganization } from "../organizations/local-organization.interface";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import {
  IDistributionCriterionWithApplicationStatus,
  IOrganizationStructureDetailed,
  IOrganizationStructureDetailedOneOf,
} from "@netcero/netcero-core-api-client";
import { Alert, Box, LinearProgress, SelectChangeEvent } from "@mui/material";
import { useQuerySyncedSelectItem } from "../common/hooks/use-query-synced-select-item.hook";
import { DistributionCriteriaDropdownWrapperComponent } from "./dropdowns/distribution-criteria-dropdown-wrapper.component";
import { NoCriteriaConfiguredComponent } from "./no-criteria-configured.component";
import { AppliedDistributionCriteriaTableComponent } from "./table/applied-distribution-criteria-table.component";
import {
  IUpdateDistributionCriteriaValueData,
  useApplyAppliedDistributionCriterionConfigurationMutation,
  useDeleteDistributionCriteriaValueMutation,
  useRemoveAppliedDistributionCriterionApplicationMutation,
  useRemoveAppliedDistributionCriterionConfigurationMutation,
  useStartAppliedDistributionCriterionConfigurationMutation,
  useUpdateDistributionCriteriaValueMutation,
} from "./applied-distribution-criteria.mutations";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { useTranslation } from "react-i18next";
import { ConfigureCriterionComponent } from "./configure-criterion.component";
import { AppliedDistributionCriteriaUtilities } from "./utilities/applied-distribution-criteria.utilities";

export interface IAppliedDistributionCriteriaConfigurationComponentProps {
  organization: ILocalOrganization;
  recordingPeriods: ILocalRecordingPeriod[];
  selectedRecordingPeriod: ILocalRecordingPeriod;
  organizationStructure: IOrganizationStructureDetailed;
  onChangeRecordingPeriod: (recordingPeriod: ILocalRecordingPeriod) => void;
  appliedDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
}

const CriterionQueryParam = "distributionCriteronId";

export const AppliedDistributionCriteriaConfigurationComponent: FC<
  IAppliedDistributionCriteriaConfigurationComponentProps
> = ({
  recordingPeriods,
  onChangeRecordingPeriod,
  selectedRecordingPeriod,
  organizationStructure,
  organization,
  appliedDistributionCriteria,
}) => {
  const { t } = useTranslation("applied_distribution_criteria_configuration_component");

  // Mutations
  const updateDistributionCriteriaValueMutation = useUpdateDistributionCriteriaValueMutation();
  const deleteDistributionCriteriaValueMutation = useDeleteDistributionCriteriaValueMutation();
  const startConfigurationMutation = useStartAppliedDistributionCriterionConfigurationMutation();
  const removeConfigurationMutation = useRemoveAppliedDistributionCriterionConfigurationMutation();
  const applyConfigurationMutation = useApplyAppliedDistributionCriterionConfigurationMutation();
  const removeAppliedDistributionCriterionApplicationMutation =
    useRemoveAppliedDistributionCriterionApplicationMutation();

  // Handle change in currently selected recording period
  const handleSelectRecordingPeriod = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newId = event.target.value;
      const newRecordingPeriod = recordingPeriods.find((rp) => rp.id === newId);
      onChangeRecordingPeriod(newRecordingPeriod!);
    },
    [onChangeRecordingPeriod, recordingPeriods],
  );

  // State for the currently selected distribution criteria
  const { setCurrentItem: setCurrentCriterionForSelect, currentItem: currentCriterion } =
    useQuerySyncedSelectItem(CriterionQueryParam, appliedDistributionCriteria);

  const setCurrentCriterion = useCallback(
    (criterionId: string) => {
      // be sure to clear previous errors when changing the criterion
      updateDistributionCriteriaValueMutation.reset();
      deleteDistributionCriteriaValueMutation.reset();
      startConfigurationMutation.reset();
      removeConfigurationMutation.reset();
      applyConfigurationMutation.reset();
      removeAppliedDistributionCriterionApplicationMutation.reset();

      // update select value
      setCurrentCriterionForSelect(criterionId);
    },
    [
      applyConfigurationMutation,
      deleteDistributionCriteriaValueMutation,
      removeAppliedDistributionCriterionApplicationMutation,
      removeConfigurationMutation,
      setCurrentCriterionForSelect,
      startConfigurationMutation,
      updateDistributionCriteriaValueMutation,
    ],
  );

  // Update Value Mutation

  const handleUpdateValue = useCallback(
    async (values: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId" | "payload">) => {
      if (!currentCriterion) {
        // should not happen
        return;
      }
      await updateDistributionCriteriaValueMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: selectedRecordingPeriod.id,
        distributionCriterionId: currentCriterion.id,
        ...values,
      });
    },
    [
      organization,
      selectedRecordingPeriod,
      updateDistributionCriteriaValueMutation,
      currentCriterion,
    ],
  );

  // Delete Value Mutation

  const handleDeleteValue = useCallback(
    async (values: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId">) => {
      if (!currentCriterion) {
        // should not happen
        return;
      }
      await deleteDistributionCriteriaValueMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: selectedRecordingPeriod.id,
        distributionCriterionId: currentCriterion.id,
        ...values,
      });
    },
    [
      organization,
      selectedRecordingPeriod,
      deleteDistributionCriteriaValueMutation,
      currentCriterion,
    ],
  );

  // Start configuration
  const handleStartConfiguration = useCallback(async () => {
    if (currentCriterion === null) {
      // should not happen
      return;
    }

    await startConfigurationMutation.mutateAsync({
      organizationId: organization.id,
      distributionCriterionId: currentCriterion.id,
      recordingPeriodId: selectedRecordingPeriod.id,
    });
  }, [currentCriterion, organization.id, selectedRecordingPeriod.id, startConfigurationMutation]);

  // Remove configuration
  const handleRemoveConfiguration = useCallback(async () => {
    if (currentCriterion === null) {
      // should not happen
      return;
    }
    await removeConfigurationMutation.mutateAsync({
      organizationId: organization.id,
      distributionCriterionId: currentCriterion.id,
      recordingPeriodId: selectedRecordingPeriod.id,
    });
  }, [currentCriterion, organization.id, removeConfigurationMutation, selectedRecordingPeriod.id]);

  // Apply configuration
  const handleApplyConfiguration = useCallback(async () => {
    if (!currentCriterion) {
      return;
    }

    await applyConfigurationMutation.mutateAsync({
      organizationId: organization.id,
      distributionCriterionId: currentCriterion.id,
      recordingPeriodId: selectedRecordingPeriod.id,
    });
  }, [applyConfigurationMutation, currentCriterion, organization.id, selectedRecordingPeriod.id]);

  // Remove configuration application
  const handleRemoveConfigurationApplication = useCallback(async () => {
    if (!currentCriterion) {
      return;
    }

    await removeAppliedDistributionCriterionApplicationMutation.mutateAsync({
      organizationId: organization.id,
      distributionCriterionId: currentCriterion.id,
      recordingPeriodId: selectedRecordingPeriod.id,
    });
  }, [
    currentCriterion,
    organization.id,
    removeAppliedDistributionCriterionApplicationMutation,
    selectedRecordingPeriod.id,
  ]);

  // Whether the table should be displayed
  const displayTable = useMemo(
    () =>
      !organizationStructure.isDraft && currentCriterion !== null && currentCriterion.configured,
    [currentCriterion, organizationStructure.isDraft],
  );

  // whether all fields are populated
  const allFieldsPopulated = useMemo(() => {
    // table is not even displayed --> no need to check
    if (!displayTable) {
      return false;
    }

    const rootDeo = (organizationStructure as IOrganizationStructureDetailedOneOf).structure;

    // Keys are only present if a value is set --> check whether that equals the number of all ids / objects in the tree
    return (
      AppliedDistributionCriteriaUtilities.getAllIdsInTree(rootDeo).length ===
      Object.keys(currentCriterion!.application).length
    );
  }, [currentCriterion, displayTable, organizationStructure]);

  return (
    <Box>
      {/* Display Loader */}
      {(updateDistributionCriteriaValueMutation.isPending ||
        deleteDistributionCriteriaValueMutation.isPending ||
        startConfigurationMutation.isPending ||
        removeConfigurationMutation.isPending ||
        applyConfigurationMutation.isPending ||
        removeAppliedDistributionCriterionApplicationMutation.isPending) && (
        <Box mb={2}>
          <LinearProgress />
        </Box>
      )}

      {/* Display Errors */}
      {(updateDistributionCriteriaValueMutation.isError ||
        deleteDistributionCriteriaValueMutation.isError ||
        startConfigurationMutation.isError ||
        removeConfigurationMutation.isError ||
        applyConfigurationMutation.isError ||
        removeAppliedDistributionCriterionApplicationMutation.isError) && (
        <Box mb={2}>
          <ErrorTextComponent
            error={
              (updateDistributionCriteriaValueMutation.error ||
                deleteDistributionCriteriaValueMutation.error ||
                startConfigurationMutation.error ||
                removeConfigurationMutation.error ||
                applyConfigurationMutation.error ||
                removeAppliedDistributionCriterionApplicationMutation.error)!
            }
          />
        </Box>
      )}

      {/* Display Dropdown */}
      {currentCriterion !== null && (
        <DistributionCriteriaDropdownWrapperComponent
          handleSelectRecordingPeriod={handleSelectRecordingPeriod}
          selectedRecordingPeriod={selectedRecordingPeriod}
          recordingPeriods={recordingPeriods}
          organizationStructure={organizationStructure}
          appliedDistributionCriteria={appliedDistributionCriteria}
          currentCriterion={currentCriterion}
          setCurrentCriterion={setCurrentCriterion}
          organization={organization}
        />
      )}

      {/* Display "No Criteria Configured" */}
      {currentCriterion === null && (
        <NoCriteriaConfiguredComponent organizationId={organization.id} />
      )}

      {/* Applied --> Display Table */}
      {displayTable && (
        <>
          {/* Auto save alert */}
          <Alert severity="info" sx={{ mt: 2 }}>
            <ul>
              <li>{t("alert_info.body")}</li>
              {currentCriterion?.applied && (
                <li>
                  <b>{t("alert_info.info_already_applied")}</b>
                </li>
              )}
            </ul>
          </Alert>
          {/* Table */}
          <AppliedDistributionCriteriaTableComponent
            currentCriteria={currentCriterion!}
            rootDEO={(organizationStructure as IOrganizationStructureDetailedOneOf).structure}
            onUpdateValue={handleUpdateValue}
            onDeleteValue={handleDeleteValue}
            onRemoveConfiguration={handleRemoveConfiguration}
            allFieldsPopulated={allFieldsPopulated}
            onApplyConfiguration={handleApplyConfiguration}
            onRemoveConfigurationApplication={handleRemoveConfigurationApplication}
          />
        </>
      )}

      {/* Not yet applied --> Display info to start application */}
      {!organizationStructure.isDraft &&
        currentCriterion !== null &&
        !currentCriterion.configured && (
          <ConfigureCriterionComponent onClick={handleStartConfiguration} />
        )}
    </Box>
  );
};
