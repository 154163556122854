import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePoliciesQuery } from "../policies.queries";
import { PoliciesTable } from "./policies-table.component";
import {
  DataEntryObjectInputParameterValueDefinitionForPolicy,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum,
  IPolicy,
  IPolicyDataS,
} from "@netcero/netcero-core-api-client";

interface IPoliciesListComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForPolicy>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForPolicy) => void;
  topicIdentifier: IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum;
}

export const PoliciesListComponent: FC<IPoliciesListComponentProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  value,
  onChange,
  topicIdentifier,
}) => {
  const policies = usePoliciesQuery(organizationId, recordingPeriodId, dataEntryObjectId);
  const identifierKey = topicIdentifier.split("_")[1];

  function filterPoliciesByTopic(topic: string, nestedTopic?: string) {
    return (
      policies?.data?.filter((policy) => {
        if (policy && nestedTopic && topic in policy) {
          const nestedObject = policy[topic as keyof IPolicy] as unknown as Record<
            string,
            IPolicyDataS
          >;
          return nestedTopic in nestedObject;
        }
        return topic in policy;
      }) ?? []
    );
  }

  const filteredPolicies = ["s1", "s2", "s3", "s4"].includes(identifierKey)
    ? filterPoliciesByTopic("s", identifierKey)
    : filterPoliciesByTopic(identifierKey);

  const { t } = useTranslation("data_entry_object_policy_component");

  if (value === undefined) {
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography>{t("no_policies_defined")}</Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={() => {
              onChange(true);
            }}
            sx={{ minWidth: 124 }}
          >
            {t("present_policies")}
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              onChange(false);
            }}
            sx={{ minWidth: 124 }}
          >
            {t("no_present_policies")}
          </Button>
        </Box>
      </Box>
    );
  }

  return value ? (
    <PoliciesTable
      policies={filteredPolicies}
      topicIdentifier={topicIdentifier}
      organizationId={organizationId}
      recordingPeriodId={recordingPeriodId}
      dataEntryObjectId={dataEntryObjectId}
      onChange={onChange}
    />
  ) : (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography>{t("no_policies_defined")}</Typography>
      <Button
        variant="outlined"
        size="small"
        color="success"
        onClick={() => {
          onChange(true);
        }}
        sx={{ minWidth: 124 }}
      >
        {t("policies_adopted")}
      </Button>
    </Box>
  );
};
