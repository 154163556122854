/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IGetAuditLogs200Response } from '../models';
/**
 * AuditLogApi - axios parameter creator
 * @export
 */
export const AuditLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all Audit Logs for the specified organization.
         * @summary Retrieves all Audit Logs for the specified organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {number} limit The max number of items returned
         * @param {number} offset The number of items before to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs: async (organizationId: string, limit: number, offset: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getAuditLogs', 'organizationId', organizationId)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getAuditLogs', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getAuditLogs', 'offset', offset)
            const localVarPath = `/organizations/{organizationId}/audit-logs`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogApi - functional programming interface
 * @export
 */
export const AuditLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all Audit Logs for the specified organization.
         * @summary Retrieves all Audit Logs for the specified organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {number} limit The max number of items returned
         * @param {number} offset The number of items before to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditLogs(organizationId: string, limit: number, offset: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAuditLogs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditLogs(organizationId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogApi - factory interface
 * @export
 */
export const AuditLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogApiFp(configuration)
    return {
        /**
         * Retrieves all Audit Logs for the specified organization.
         * @summary Retrieves all Audit Logs for the specified organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {number} limit The max number of items returned
         * @param {number} offset The number of items before to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs(organizationId: string, limit: number, offset: number, options?: any): AxiosPromise<IGetAuditLogs200Response> {
            return localVarFp.getAuditLogs(organizationId, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogApi - interface
 * @export
 * @interface AuditLogApi
 */
export interface AuditLogApiInterface {
    /**
     * Retrieves all Audit Logs for the specified organization.
     * @summary Retrieves all Audit Logs for the specified organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {number} limit The max number of items returned
     * @param {number} offset The number of items before to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiInterface
     */
    getAuditLogs(organizationId: string, limit: number, offset: number, options?: AxiosRequestConfig): AxiosPromise<IGetAuditLogs200Response>;

}

/**
 * AuditLogApi - object-oriented interface
 * @export
 * @class AuditLogApi
 * @extends {BaseAPI}
 */
export class AuditLogApi extends BaseAPI implements AuditLogApiInterface {
    /**
     * Retrieves all Audit Logs for the specified organization.
     * @summary Retrieves all Audit Logs for the specified organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {number} limit The max number of items returned
     * @param {number} offset The number of items before to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public getAuditLogs(organizationId: string, limit: number, offset: number, options?: AxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).getAuditLogs(organizationId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}
