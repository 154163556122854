import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, FormHelperText } from "@mui/material";
import { useObserveSizeState } from "../hooks/use-observe-size-state.hook";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
/** The width of a chip without any text */
const CHIP_BASE_WIDTH = 24 + 4; // 32px chip padding + 4px gap
const CHIP_CHARACTER_WIDTH = 7.6; // 7.6px per character

function getChipWidth(textContent: string) {
  return CHIP_BASE_WIDTH + textContent.length * CHIP_CHARACTER_WIDTH;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IChipProps {
  onSelectClick: () => void;
  chipNames: string[];
  label?: string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  fullWidth?: boolean;
  noMargin?: boolean;
}

export default function MultipleSelectChip({
  onSelectClick,
  chipNames,
  label,
  required,
  error,
  disabled,
  fullWidth,
  noMargin,
}: IChipProps) {
  const { t } = useTranslation("multiple_chip_component");

  const { ref: containerRef, size: containerSize } = useObserveSizeState();
  const containerWidth = useMemo(() => containerSize.width, [containerSize]);

  const { visibleChips, extraCount } = useMemo(() => {
    const availableContainerWidth =
      containerWidth - getChipWidth(t("more_items", { count: chipNames.length }));

    const visibleChips: string[] = [];
    let currentUsedContainerWidth = 0;
    while (currentUsedContainerWidth < availableContainerWidth) {
      const nextChip = chipNames[visibleChips.length];
      if (!nextChip) {
        break;
      }

      const nextChipWidth = getChipWidth(nextChip);
      if (currentUsedContainerWidth + nextChipWidth > availableContainerWidth) {
        break;
      }

      visibleChips.push(nextChip);
      currentUsedContainerWidth += nextChipWidth;
    }

    const extraCount = chipNames.length - visibleChips.length;

    return { visibleChips, extraCount };
  }, [chipNames, containerWidth, t]);

  return (
    <FormControl
      sx={{ m: noMargin ? undefined : 1, width: fullWidth ? "100%" : 300 }}
      required={required}
      fullWidth={fullWidth}
      error={!!error}
      disabled={disabled}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        error={!!error}
        disabled={disabled}
        required={required}
        label={label}
        multiple
        value={chipNames}
        open={false}
        onClick={() => {
          if (!disabled) {
            onSelectClick();
          }
        }}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={() => (
          <Box
            ref={containerRef}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              maxHeight: ITEM_HEIGHT,
              overflow: "hidden",
            }}
          >
            {visibleChips.map((value, index) => (
              <Chip key={index} label={value || "N/A"} />
            ))}
            {extraCount > 0 && (
              <Chip
                key="extra"
                label={t(visibleChips.length === 0 ? "only_more_items" : "more_items", {
                  count: extraCount,
                })}
              />
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
