import {
  Box,
  Card,
  CardContent,
  Divider,
  SelectChangeEvent,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  IDistributionCriterionWithApplicationStatus,
  IOrganizationStructureDetailed,
} from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { OrganizationStructureIsDraftNotice } from "../../organization-structures/organization-structure-is-draft-notice.components";
import { ILocalOrganization } from "../../organizations/local-organization.interface";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { DistributionCriteriaRecordingPeriodDropdownComponent } from "./distribution-criteria-recording-period-dropdown.component";
import { DistributionCriterionDropdownComponent } from "./distribution-criterion.dropdown.component";

interface IDistributionCriteriaDropdownWrapperComponent {
  // Recording Period
  handleSelectRecordingPeriod: (event: SelectChangeEvent<string>) => void;
  selectedRecordingPeriod: ILocalRecordingPeriod;
  recordingPeriods: ILocalRecordingPeriod[];

  // Criterion
  currentCriterion: IDistributionCriterionWithApplicationStatus | null;
  appliedDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  setCurrentCriterion: (criterionId: string) => void;

  // Organization
  organization: ILocalOrganization;

  // Information display
  organizationStructure: IOrganizationStructureDetailed;
}

export const DistributionCriteriaDropdownWrapperComponent: FC<
  IDistributionCriteriaDropdownWrapperComponent
> = ({
  handleSelectRecordingPeriod,
  selectedRecordingPeriod,
  recordingPeriods,
  organizationStructure,
  appliedDistributionCriteria,
  setCurrentCriterion,
  currentCriterion,
  organization,
}) => {
  const { t } = useTranslation("applied_distribution_criteria_configuration_component", {
    keyPrefix: "dropdowns",
  });
  return (
    <Card>
      <CardContent>
        {/* Recording Period Select */}
        <DistributionCriteriaRecordingPeriodDropdownComponent
          handleSelectRecordingPeriod={handleSelectRecordingPeriod}
          selectedRecordingPeriod={selectedRecordingPeriod}
          recordingPeriods={recordingPeriods}
        />

        {/* Distribution Criterion Select */}
        <Box mt={3}>
          <DistributionCriterionDropdownComponent
            currentCriterion={currentCriterion}
            setCurrentCriterion={setCurrentCriterion}
            appliedDistributionCriteria={appliedDistributionCriteria}
          />
        </Box>

        {/* Draft Notice */}
        {organizationStructure.isDraft && (
          <OrganizationStructureIsDraftNotice
            organizationId={organization.id}
            recordingPeriodId={selectedRecordingPeriod.id}
            noticeText={t("organization_structure_is_draft_notice")}
          />
        )}

        {/* Description */}
        {selectedRecordingPeriod.description !== undefined && (
          <>
            <Divider sx={{ my: 2, mx: -3 }} />
            <Box display="flex" gap={1}>
              <SvgIcon color="info">
                <InfoIcon />
              </SvgIcon>
              <Typography variant="body1">{selectedRecordingPeriod.description}</Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};
