import {
  IDMACategoryWithChildren,
  IDMACategoryWithEffectsAndChildren,
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useDialogState } from "../../../common/hooks/dialog-state.hook";
import {
  useCreateDMACategoryMutation,
  useDeleteDMACategoryMutation,
  useUpdateDMACategoryMutation,
} from "../../mutations/dma-category.mutations";
import { DeleteDMACategoryConfirmDialog } from "./delete-dma-category-confirm.dialog";
import { EditDMACategoryDialog, IEditDMACategoryFormData } from "./edit-dma-category.dialog";
import { useResetMutationsOnOpen } from "../../../common/hooks/use-reset-mutations-on-open.hook";

export enum DMACategoryDialogMode {
  Create = "create",
  Edit = "edit",
}

export interface IDMACategoryDialogsState {
  mode?: DMACategoryDialogMode | null;
  esrsTopic?: IESRSTopic | null;
  parentCategoryId?: string | null;
  category?: IDMACategoryWithEffectsAndManualFeedbacksAndChildren | null;
}

export interface ICategoryCompleteActionMetadata {
  createdCategory?: IDMACategoryWithChildren;
  updatedCategory?: IDMACategoryWithEffectsAndChildren;
  deletedCategory?: IDMACategoryWithEffectsAndChildren;
}

interface IDMACategoryDialogsProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  dmaCategoryDialogsState: IDMACategoryDialogsState;
  onCompleteAction: (metaData: ICategoryCompleteActionMetadata) => void;
}

export const DMACategoryDialogs: FC<IDMACategoryDialogsProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  dmaCategoryDialogsState,
  onCompleteAction,
}) => {
  // Init Mutations
  const createDMACategoryMutation = useCreateDMACategoryMutation();
  const editDMACategoryMutation = useUpdateDMACategoryMutation();
  const deleteDMACategoryMutation = useDeleteDMACategoryMutation();

  // Create Logic

  const showCreateDialog = dmaCategoryDialogsState.mode === DMACategoryDialogMode.Create;

  useResetMutationsOnOpen(showCreateDialog, createDMACategoryMutation);

  const handleCloseCreateDMACategoryDialog = async (data: IEditDMACategoryFormData | null) => {
    if (data !== null) {
      const result = await createDMACategoryMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: dmaCategoryDialogsState.esrsTopic!.id,
        parentId: dmaCategoryDialogsState.parentCategoryId || undefined,
        name: data.name,
      });
      onCompleteAction({ createdCategory: result.category });
    } else {
      onCompleteAction({});
    }
  };

  const canDeleteCategory = useMemo(
    () =>
      dmaCategoryDialogsState.category?.children.length === 0 &&
      dmaCategoryDialogsState.category?.materialImpacts.length === 0 &&
      dmaCategoryDialogsState.category?.financialEffects.length === 0 &&
      dmaCategoryDialogsState.category?.manualFeedbacks.length === 0 &&
      dmaCategoryDialogsState.category?.feedback.length === 0,
    [
      dmaCategoryDialogsState.category?.children,
      dmaCategoryDialogsState.category?.materialImpacts,
      dmaCategoryDialogsState.category?.financialEffects,
      dmaCategoryDialogsState.category?.manualFeedbacks,
      dmaCategoryDialogsState.category?.feedback,
    ],
  );

  // Edit Logic

  const showEditDialog = dmaCategoryDialogsState.mode === DMACategoryDialogMode.Edit;

  useResetMutationsOnOpen(showEditDialog, editDMACategoryMutation);

  const handleCloseEditDMACategoryDialog = async (data: IEditDMACategoryFormData | null) => {
    if (data !== null) {
      await editDMACategoryMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        dmaCategoryId: dmaCategoryDialogsState.category!.id,
        esrsTopicId: dmaCategoryDialogsState.esrsTopic!.id,
        payload: { name: data.name },
      });
      onCompleteAction({ updatedCategory: dmaCategoryDialogsState.category ?? undefined });
    } else {
      onCompleteAction({});
    }
  };

  // Delete Logic

  const {
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    isOpen: isDeleteDialogOpen,
  } = useDialogState();

  const handleDeleteDMACategory = () => {
    deleteDMACategoryMutation.reset();
    openDeleteDialog();
  };

  const handleCloseDeleteDMACategoryDialog = async (confirm: boolean) => {
    if (confirm) {
      await deleteDMACategoryMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        dmaCategoryId: dmaCategoryDialogsState.category!.id,
        esrsTopicId: dmaCategoryDialogsState.esrsTopic!.id,
      });
      onCompleteAction({ deletedCategory: dmaCategoryDialogsState.category ?? undefined });
    } else {
      onCompleteAction({});
    }
    closeDeleteDialog();
  };

  return (
    <>
      {/* Create Dialog */}
      <EditDMACategoryDialog
        canDelete={false}
        open={showCreateDialog}
        loading={createDMACategoryMutation.isPending}
        error={createDMACategoryMutation.isError ? createDMACategoryMutation.error : null}
        disabled={createDMACategoryMutation.isPending}
        onClose={handleCloseCreateDMACategoryDialog}
      />

      {/* Edit Dialog */}
      <EditDMACategoryDialog
        dmaCategory={dmaCategoryDialogsState.category}
        open={showEditDialog}
        loading={editDMACategoryMutation.isPending}
        disabled={editDMACategoryMutation.isPending}
        error={editDMACategoryMutation.isError ? editDMACategoryMutation.error : null}
        canDelete={canDeleteCategory}
        onDelete={handleDeleteDMACategory}
        onClose={handleCloseEditDMACategoryDialog}
      />

      {/* Delete Dialog */}
      <DeleteDMACategoryConfirmDialog
        open={isDeleteDialogOpen}
        dmaCategory={dmaCategoryDialogsState.category}
        loading={deleteDMACategoryMutation.isPending}
        error={deleteDMACategoryMutation.isError ? deleteDMACategoryMutation.error : null}
        disabled={deleteDMACategoryMutation.isPending}
        onCancel={() => handleCloseDeleteDMACategoryDialog(false)}
        onConfirm={() => handleCloseDeleteDMACategoryDialog(true)}
      />
    </>
  );
};
