import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { IBaseReferenceBudgetData, IEvaluationsEnum } from "@netcero/netcero-core-api-client";
import { DateTime } from "luxon";
import { FC, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { MultiSelectComponent } from "../common/components/multi-select.component";
import { WarningIcon } from "../common/constants/tabler-icon.constants";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import { CurrencyInputComponent } from "../value-acquisition/input-components/currency-input.component";
import { ILocalReferenceBudget } from "./interfaces/reference-budgets.interfaces";
import { ReferenceBudgetUtilities } from "@netcero/netcero-common";
import { useTranslateContent } from "../content-translation/hooks/translate-content.hook";

const getFormDefaultValues = (
  referenceBudget: ILocalReferenceBudget | null,
  recordingPeriods: ILocalRecordingPeriod[],
): IBaseReferenceBudgetData => {
  return {
    name: referenceBudget?.name ?? "",
    referencedRecordingStructureIds: referenceBudget?.referencedRecordingStructureIds ?? [],
    referenceYear:
      referenceBudget?.referenceYear ??
      (recordingPeriods.length > 0 ? ReferenceBudgetUtilities.getMaxYear(recordingPeriods) : 0),
    referenceRevenue: referenceBudget?.referenceRevenue,
  };
};

interface IReferenceBudgetEditDialogBaseProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  availableRecordingPeriods: ILocalRecordingPeriod[];
}

interface ICreateReferenceBudgetEditDialogProps extends IReferenceBudgetEditDialogBaseProps {
  mode: "create";
  referenceBudget: null;
  onClose: (data: IBaseReferenceBudgetData | null) => void;
}

interface IEditReferenceBudgetEditDialogProps extends IReferenceBudgetEditDialogBaseProps {
  mode: "edit";
  referenceBudget: ILocalReferenceBudget;
  onClose: (
    payload: { data: IBaseReferenceBudgetData; haveRecordingPeriodsChanged: boolean } | null,
  ) => void;
}

type IReferenceBudgetEditDialogProps =
  | ICreateReferenceBudgetEditDialogProps
  | IEditReferenceBudgetEditDialogProps;

const areRecordingPeriodsValid = (recordingPeriods: string[]) => recordingPeriods.length > 0;

export const ReferenceBudgetEditDialog: FC<IReferenceBudgetEditDialogProps> = ({
  referenceBudget,
  disabled,
  onClose,
  mode,
  error,
  open,
  loading,
  availableRecordingPeriods,
}) => {
  const { t } = useTranslation("reference_budget_edit_dialog");
  const translateContent = useTranslateContent();

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<IBaseReferenceBudgetData>({
    defaultValues: getFormDefaultValues(referenceBudget, availableRecordingPeriods),
  });

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(referenceBudget, availableRecordingPeriods));
    }
  }, [open, reset, referenceBudget, availableRecordingPeriods]);

  const handleEmitData = (data: IBaseReferenceBudgetData) => {
    const payload: IBaseReferenceBudgetData = {
      name: data.name.trim(),
      referencedRecordingStructureIds: data.referencedRecordingStructureIds,
      referenceYear: data.referenceYear,
      referenceRevenue: data.referenceRevenue,
    };

    if (mode === "create") {
      onClose(payload);
    } else {
      onClose({
        data: payload,
        haveRecordingPeriodsChanged: !!dirtyFields.referencedRecordingStructureIds,
      });
    }
  };

  const currentRecordingStructures = watch("referencedRecordingStructureIds");
  const currentReferenceYear = watch("referenceYear");

  const recordingPeriodsForRecordingStructures = useMemo(() => {
    // Mapping from RS ID --> RP ID
    const result: Record<string, string> = {};
    for (const recordingPeriod of availableRecordingPeriods) {
      for (const rs of recordingPeriod.recordingStructures) {
        result[rs.id] = recordingPeriod.id;
      }
    }
    return result;
  }, [availableRecordingPeriods]);

  // determine minimum / maximum date
  const { minDate, maxDate } = useMemo(() => {
    const recordingPeriods =
      currentRecordingStructures.length > 0
        ? availableRecordingPeriods.filter((rp) =>
            currentRecordingStructures.some(
              (id) => rp.id === recordingPeriodsForRecordingStructures[id],
            ),
          )
        : availableRecordingPeriods;

    if (recordingPeriods.length === 0) {
      return {};
    }

    return {
      minDate: DateTime.now().set({
        year: ReferenceBudgetUtilities.getMinYear(recordingPeriods),
      }),
      maxDate: DateTime.now().set({
        year: ReferenceBudgetUtilities.getMaxYear(recordingPeriods),
      }),
    };
  }, [
    availableRecordingPeriods,
    currentRecordingStructures,
    recordingPeriodsForRecordingStructures,
  ]);

  // Make sure that the reference year is within the range of the selected recording periods
  useEffect(() => {
    if (
      minDate &&
      maxDate &&
      !(currentReferenceYear <= maxDate.year && currentReferenceYear >= minDate.year)
    ) {
      setValue("referenceYear", maxDate.year);
    }
  }, [currentReferenceYear, maxDate, minDate, setValue]);

  return (
    <Dialog open={open} onClose={!isDirty ? () => onClose(null) : undefined} fullWidth>
      <DialogTitle>{t(mode === "edit" ? "title_edit" : "title_create")}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          {/* Display notice if recording periods have changed */}
          {dirtyFields.referencedRecordingStructureIds &&
            mode === "edit" &&
            areRecordingPeriodsValid(currentRecordingStructures) && (
              <Box display="flex" alignItems="center">
                <SvgIcon color="warning">
                  <WarningIcon />
                </SvgIcon>
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {t("notices.recording_periods_have_changed")}
                </Typography>
              </Box>
            )}

          {/* Name Input */}
          <Controller
            control={control}
            name="name"
            rules={{ required: t("errors.name_required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                required
                label={t("labels.name")}
                {...field}
                error={!!error}
                helperText={error?.message}
                disabled={disabled}
              />
            )}
          />

          {/* Recording Periods Select */}
          <Controller
            name="referencedRecordingStructureIds"
            control={control}
            rules={{
              validate: (value) => {
                if (!areRecordingPeriodsValid(value)) {
                  return t("errors.recording_periods_required");
                }
                return undefined;
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <MultiSelectComponent
                required
                options={availableRecordingPeriods}
                label={t("labels.recording_periods")}
                error={!!error}
                errorMessage={error?.message}
                selectedOptions={field.value}
                onValuesChange={(newValues) => field.onChange(newValues)}
                shrinkInputLabel={false}
                disabled={disabled}
                config={{
                  type: "nested",
                  getHeading: (rp) => rp.name,
                  getChildren: (rp) =>
                    rp.recordingStructures
                      // CAREFUL: this might cause issues if a RS used to have type "evaluation" but no longer has it...
                      .filter(
                        (rs) =>
                          // TODO: remove the first part once evaluations are obsolete
                          rs.evaluations.includes(IEvaluationsEnum.Emissions) &&
                          rs.structure.type === "thg",
                      )
                      .map((rs) => ({
                        label: translateContent(rs.name),
                        value: rs.id,
                      })),
                }}
              />
            )}
          />

          {/* Reference Revenue + Reference Year */}
          <Box display="flex" flexDirection="row" gap={2}>
            <Controller
              name="referenceRevenue"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CurrencyInputComponent
                  value={field.value?.toString() ?? undefined}
                  onChange={(newValue) => {
                    // no need to check for "" as that is handled by the underlying input component
                    if (newValue === undefined) {
                      field.onChange(undefined);
                      return;
                    }
                    field.onChange(parseInt(newValue));
                  }}
                  metaData={{ type: "currency", valueLimits: {} }}
                  disableMaxWidth
                  label={t("labels.reference_revenue")}
                  error={error?.message}
                />
              )}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  value={DateTime.now().set({ year: field.value })}
                  onChange={(date) => {
                    if (date !== null) {
                      field.onChange(date.year);
                    }
                  }}
                  label={t("labels.reference_year")}
                  disabled={disabled}
                  slotProps={{
                    textField: {
                      error: !!error,
                      helperText: error?.message,
                      fullWidth: true,
                      required: true,
                    },
                  }}
                  views={["year"]}
                />
              )}
              name="referenceYear"
              control={control}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(null)} disabled={disabled}>
          {t(isDirty ? "cancel" : "close", { ns: "buttons" })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(handleEmitData)}
          disabled={disabled || (mode === "edit" && !isDirty)}
        >
          {t(mode === "edit" ? "save" : "create", { ns: "buttons" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
