/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ICountryCodeAlpha2Enum } from './icountry-code-alpha2-enum';

/**
 * 
 * @export
 * @interface IDataEntryObjectBase
 */
export interface IDataEntryObjectBase {
    /**
     * 
     * @type {string}
     * @memberof IDataEntryObjectBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IDataEntryObjectBase
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDataEntryObjectBase
     */
    'objectType': IDataEntryObjectBaseObjectTypeEnum;
    /**
     * Can only be set if objectType is \"organization\" or \"grouping\"
     * @type {boolean}
     * @memberof IDataEntryObjectBase
     */
    'operationalControl'?: boolean;
    /**
     * Can only be set if operationalControl is \"false\"
     * @type {boolean}
     * @memberof IDataEntryObjectBase
     */
    'partOfValueChain'?: boolean;
    /**
     * Can only be set if objectType is \"organization\" or \"grouping\"
     * @type {boolean}
     * @memberof IDataEntryObjectBase
     */
    'financiallyConsolidated'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IDataEntryObjectBase
     */
    'inheritsValuesFrom': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IDataEntryObjectBase
     */
    'shareHeldByParent': number;
    /**
     * 
     * @type {ICountryCodeAlpha2Enum}
     * @memberof IDataEntryObjectBase
     */
    'country': ICountryCodeAlpha2Enum;
}

export const IDataEntryObjectBaseObjectTypeEnum = {
    Location: 'location',
    Department: 'department',
    Team: 'team',
    BusinessArea: 'business_area',
    Grouping: 'grouping',
    Organization: 'organization'
} as const;

export type IDataEntryObjectBaseObjectTypeEnum = typeof IDataEntryObjectBaseObjectTypeEnum[keyof typeof IDataEntryObjectBaseObjectTypeEnum];


