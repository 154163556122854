import { IUserRepresentation } from "@netcero/phase-two-api-client";

export class UserUtilities {
  /**
   * A list of distinct darker pastel colors for user avatars.
   */
  private static distinctDarkerPastelColors = [
    "#E57373", // darker pastel red
    "#81C784", // darker pastel green
    "#64B5F6", // darker pastel blue
    "#FFB74D", // darker pastel orange
    "#BA68C8", // darker pastel purple
    "#4DB6AC", // darker pastel teal
    "#A1887F", // darker pastel brown
    "#F06292", // darker pastel pink
    "#4FC3F7", // darker pastel sky blue
    "#FF8A65", // darker pastel coral
    "#9575CD", // darker pastel light purple
    "#4DD0E1", // darker pastel light teal
    "#AED581", // darker pastel light green
    "#4A148C", // darker pastel dark purple
    "#1A237E", // darker pastel dark blue
  ];

  /**
   * Hashes a string to a number.
   * @param input - The input string to hash.
   * @returns The hashed number.
   */
  private static hashStringToNumber(input: string): number {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash);
  }

  /**
   * Gets a color for a user based on their name.
   * @param user - The user representation object.
   * @returns A color string.
   */
  public static getColorByUser(user: IUserRepresentation): string {
    const name = `${user.firstName} ${user.lastName}`;
    const hash = this.hashStringToNumber(name);
    return this.distinctDarkerPastelColors[hash % this.distinctDarkerPastelColors.length];
  }
}
