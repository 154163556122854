import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { FC, StrictMode } from "react";
import keycloak from "../keycloak";
import { AuthenticationService } from "../modules/authentication/authentication.service";
import { AppShellWrapper } from "../modules/common/components/app-shell-wrapper.component";
import { IntercomWrapper } from "../modules/intercom/intercom-wrapper.component";
import { UserContext } from "../modules/user/user.context";
import { OrganizationsLayout } from "./organizations/organizations.layout";
import { useNCTheme } from "../theme/theme";

export const RootLayout: FC = () => {
  const defaultTheme = useNCTheme();
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required" }}
      LoadingComponent={
        // Note: @marckornberger told me to
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        </ThemeProvider>
      }
      onTokens={async (tokens) => {
        // TODO: Include error handling here
        AuthenticationService.setBearerTokenOnAxios(tokens.token!);
      }}
    >
      <StrictMode>
        <UserContext
          onLogin={(user) => AuthenticationService.setBearerTokenOnAxios(user.jwt)}
          onLogout={AuthenticationService.clearBearerTokenOnAxios}
        >
          <IntercomWrapper>
            <AppShellWrapper>
              <OrganizationsLayout />
            </AppShellWrapper>
          </IntercomWrapper>
        </UserContext>
      </StrictMode>
    </ReactKeycloakProvider>
  );
};
