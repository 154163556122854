import { FC, useMemo } from "react";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import { ILocalRecordingPeriod } from "../../../recording-periods/recording-periods.utilities";
import { IDistributionCriterionWithApplicationStatus } from "@netcero/netcero-core-api-client";
import { DrValueEditorDirect } from "./dr-value-editor-direct.component";
import { DrValueEditorTable } from "./dr-value-editor-table.component";
import { IManageDataEntryObjectTableValueHandleSaveCallback } from "../../input-components/table/manage-data-entry-object-table-value.hook";

export interface IDrValueEditProps {
  deoInputParameter: ILocalDataEntryObjectInputParameter;
  organizationId: string;
  recordingPeriod: ILocalRecordingPeriod;
  rootDataEntryObjectId: string;
  dataEntryObjectId: string;
  availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  onCreate: (data: ILocalDataEntryObjectInputParameterValueData) => Promise<void>;
  onUpdate: (valueId: string, data: ILocalDataEntryObjectInputParameterValueData) => Promise<void>;
  onDelete: (valueId: string) => Promise<void>;
  onTableUpdate: IManageDataEntryObjectTableValueHandleSaveCallback;
  disabled?: boolean;
}

export const DrValueEditor: FC<IDrValueEditProps> = (props) => {
  const InputComponent = useMemo(() => {
    switch (props.deoInputParameter.inputParameter.uiConfiguration.displayType) {
      case "direct":
        return DrValueEditorDirect;
      case "table":
        return DrValueEditorTable;
    }

    return null;
  }, [props.deoInputParameter.inputParameter.uiConfiguration.displayType]);

  return InputComponent ? <InputComponent {...props} /> : "ERROR: Unsupported display type";
};
