import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IInputParameterValueMetaDataOptions } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import {
  DataEntryObjectInputParameterValueDefinitionForOptions,
  DataEntryObjectInputParameterValueDefinitionForOptionsMultiple,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";

interface IOptionsInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForOptions) => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataOptions;
  disableMaxWidth?: boolean;
  label?: string;
  required?: boolean;
}

export const OptionsInputComponent: FC<IOptionsInputComponentProps> = (props) => {
  const { error, metaData, disableMaxWidth } = props;
  return (
    <FormControl
      variant="outlined"
      size="medium"
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined, minWidth: 200 }}
      required={props.required}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      {metaData.multiple && <MultiSelectInputComponent {...props} />}
      {!metaData.multiple && <SingleSelectInputComponent {...props} />}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const SingleSelectInputComponent: FC<IOptionsInputComponentProps> = ({
  variant,
  value,
  disabled,
  error,
  metaData,
  onChange,
  label,
  required,
}) => {
  const { t } = useTranslation("options_input_component");
  const translateContent = useTranslateContent();
  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);

  return (
    <Select
      {...inputVariantProps}
      value={value ?? ""}
      disabled={disabled}
      error={!!error}
      onChange={(e) => onChange(e.target.value as string)}
      label={label}
      sx={stylesSx}
    >
      {!required && <MenuItem value="">{t("name_empty_option")}</MenuItem>}
      {metaData.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {translateContent(option.name)}
        </MenuItem>
      ))}
    </Select>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const toDefinedMulti = (
  definition: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>,
) =>
  (definition as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptionsMultiple>) ??
  [];

const MultiSelectInputComponent: FC<IOptionsInputComponentProps> = ({
  variant,
  value,
  error,
  disabled,
  onChange,
  metaData,
  label,
}) => {
  const translateContent = useTranslateContent();
  const stylesSx = useVariantSxStyles(variant);

  const generatedOptions = useMemo(() => {
    const allValues = toDefinedMulti(value);
    return metaData.options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={allValues.includes(option.value)} />
          <ListItemText
            primary={
              <LineClampTypographyWithTooltip>
                {translateContent(option.name)}
              </LineClampTypographyWithTooltip>
            }
          />
        </MenuItem>
      );
    });
  }, [metaData.options, translateContent, value]);

  const lookup = useMemo(
    () =>
      metaData.options.reduce((acc, curr) => {
        acc[curr.value] = translateContent(curr.name);
        return acc;
      }, {} as Record<string, string>),
    [metaData.options, translateContent],
  );

  return (
    <Select
      label={label}
      value={toDefinedMulti(value)}
      error={!!error}
      multiple
      variant="outlined"
      size="medium"
      disabled={disabled}
      MenuProps={MenuProps}
      onChange={({ target: { value } }: SelectChangeEvent<string[]>) => {
        const newValue = typeof value === "string" ? value.split(",") : value;
        onChange(newValue);
      }}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={lookup[value] || "ERROR: Unknown Value"} />
          ))}
        </Box>
      )}
      sx={stylesSx}
    >
      {generatedOptions}
    </Select>
  );
};
