/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ICalculateParameterValuesForDataEntryObjectResponse } from '../models';
// @ts-ignore
import { ICreateReferenceBudget400Response } from '../models';
// @ts-ignore
import { IGetRecordingPeriodEvaluation404Response } from '../models';
/**
 * RecordingPeriodEvaluationApi - axios parameter creator
 * @export
 */
export const RecordingPeriodEvaluationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a evaluation of the input parameter values for a recording period.
         * @summary Read evaluation about all input parameter values that are recorded for a recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {Array<string>} keys The keys to be calculated.
         * @param {string} [inputParameterRecordingStructureId] The input parameter recording structure id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingPeriodEvaluation: async (organizationId: string, recordingPeriodId: string, keys: Array<string>, inputParameterRecordingStructureId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getRecordingPeriodEvaluation', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('getRecordingPeriodEvaluation', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getRecordingPeriodEvaluation', 'keys', keys)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/evaluation`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required

            if (keys) {
                localVarQueryParameter['keys'] = keys;
            }

            if (inputParameterRecordingStructureId !== undefined) {
                localVarQueryParameter['inputParameterRecordingStructureId'] = inputParameterRecordingStructureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecordingPeriodEvaluationApi - functional programming interface
 * @export
 */
export const RecordingPeriodEvaluationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecordingPeriodEvaluationApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a evaluation of the input parameter values for a recording period.
         * @summary Read evaluation about all input parameter values that are recorded for a recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {Array<string>} keys The keys to be calculated.
         * @param {string} [inputParameterRecordingStructureId] The input parameter recording structure id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordingPeriodEvaluation(organizationId: string, recordingPeriodId: string, keys: Array<string>, inputParameterRecordingStructureId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICalculateParameterValuesForDataEntryObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordingPeriodEvaluation(organizationId, recordingPeriodId, keys, inputParameterRecordingStructureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecordingPeriodEvaluationApi - factory interface
 * @export
 */
export const RecordingPeriodEvaluationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecordingPeriodEvaluationApiFp(configuration)
    return {
        /**
         * Returns a evaluation of the input parameter values for a recording period.
         * @summary Read evaluation about all input parameter values that are recorded for a recording period.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {Array<string>} keys The keys to be calculated.
         * @param {string} [inputParameterRecordingStructureId] The input parameter recording structure id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingPeriodEvaluation(organizationId: string, recordingPeriodId: string, keys: Array<string>, inputParameterRecordingStructureId?: string, options?: any): AxiosPromise<ICalculateParameterValuesForDataEntryObjectResponse> {
            return localVarFp.getRecordingPeriodEvaluation(organizationId, recordingPeriodId, keys, inputParameterRecordingStructureId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecordingPeriodEvaluationApi - interface
 * @export
 * @interface RecordingPeriodEvaluationApi
 */
export interface RecordingPeriodEvaluationApiInterface {
    /**
     * Returns a evaluation of the input parameter values for a recording period.
     * @summary Read evaluation about all input parameter values that are recorded for a recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {Array<string>} keys The keys to be calculated.
     * @param {string} [inputParameterRecordingStructureId] The input parameter recording structure id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodEvaluationApiInterface
     */
    getRecordingPeriodEvaluation(organizationId: string, recordingPeriodId: string, keys: Array<string>, inputParameterRecordingStructureId?: string, options?: AxiosRequestConfig): AxiosPromise<ICalculateParameterValuesForDataEntryObjectResponse>;

}

/**
 * RecordingPeriodEvaluationApi - object-oriented interface
 * @export
 * @class RecordingPeriodEvaluationApi
 * @extends {BaseAPI}
 */
export class RecordingPeriodEvaluationApi extends BaseAPI implements RecordingPeriodEvaluationApiInterface {
    /**
     * Returns a evaluation of the input parameter values for a recording period.
     * @summary Read evaluation about all input parameter values that are recorded for a recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {Array<string>} keys The keys to be calculated.
     * @param {string} [inputParameterRecordingStructureId] The input parameter recording structure id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodEvaluationApi
     */
    public getRecordingPeriodEvaluation(organizationId: string, recordingPeriodId: string, keys: Array<string>, inputParameterRecordingStructureId?: string, options?: AxiosRequestConfig) {
        return RecordingPeriodEvaluationApiFp(this.configuration).getRecordingPeriodEvaluation(organizationId, recordingPeriodId, keys, inputParameterRecordingStructureId, options).then((request) => request(this.axios, this.basePath));
    }
}
