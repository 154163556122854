import { FC, useCallback, useState } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { IESRSTopic, IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";
import {
  IFilterIROs,
  IROsOverviewList,
} from "../../double-materiality-assessment/impacts-risks-opportunities/components/iros-overview-list.component";
import { useTranslation } from "react-i18next";
import { IIrosListEntry } from "../../double-materiality-assessment/impacts-risks-opportunities/impacts-risks-opportunitites.types";
import { IRO_TYPES, IroType } from "@netcero/netcero-common";
import { IROsESRSTopicsTabs } from "../../data-entry-object-values/esrs/fill-in-helpers/iros-esrs-topics-tabs.component";
import { HideIcon, IconSize, ShowIcon } from "../../common/constants/tabler-icon.constants";

interface IDmaIroOverviewListProps {
  esrsTopics: IESRSTopic[];
}

export const DmaIroOverviewList: FC<IDmaIroOverviewListProps> = ({ esrsTopics }) => {
  const { t } = useTranslation("dma_dashboard");

  const [filterEsrsTopic, setFilterEsrsTopic] = useState<IESRSTopicIdentifier | null>(
    esrsTopics[0]?.identifier ?? null,
  );
  const [filterType, setFilterType] = useState<IroType[]>([...IRO_TYPES]);

  const filterIros: IFilterIROs = useCallback(
    (iro: IIrosListEntry) =>
      iro.topicIdentifier === filterEsrsTopic && filterType.includes(iro.type),
    [filterEsrsTopic, filterType],
  );

  return (
    <Box>
      {/* Title */}
      <Typography variant="h3" component="h2" mb={1}>
        {t("title_iro_overview_list")}
      </Typography>
      {/* Types Checkboxes */}
      <Box display="flex" gap={2} mb={2}>
        {IRO_TYPES.map((type) => (
          <FormControlLabel
            key={type}
            control={
              <Checkbox
                icon={<ShowIcon size={IconSize.Medium} />}
                checkedIcon={<HideIcon size={IconSize.Medium} />}
                color="default"
                checked={filterType.includes(type)}
                onChange={(_, checked) => {
                  if (checked) {
                    setFilterType((prev) => [...prev, type]);
                  } else {
                    setFilterType((prev) => prev.filter((id) => id !== type));
                  }
                }}
              />
            }
            label={t(`iro_types_plural.${type}`, { ns: "iros_overview_list_component" })}
          />
        ))}
      </Box>
      {/* Topics Tabs */}
      <IROsESRSTopicsTabs
        esrsTopics={esrsTopics}
        selectedTopic={filterEsrsTopic}
        onSelectTopic={setFilterEsrsTopic}
      />
      {/* IRO List*/}
      <IROsOverviewList esrsTopics={esrsTopics} filterIROs={filterIros} />
    </Box>
  );
};
