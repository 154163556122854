import { useCallback, useMemo } from "react";

interface IPayload {
  esrsTopicId: string;
  dmaCategoryId: string | null;
}

const STORAGE_KEY = "SELECTED_DMA_CATEGORIES";

// Key is the id of the DEO
type Storage = Record<string, IPayload>;

export const useSyncCategoryToLS = () => {
  const read = useCallback((): Storage => {
    const result = localStorage.getItem(STORAGE_KEY);
    return result ? JSON.parse(result) : {};
  }, []);

  const saveCategoryId = useCallback(
    (dataEntryObjectId: string, payload: IPayload) => {
      const previous = read();
      previous[dataEntryObjectId] = payload;

      localStorage.setItem(STORAGE_KEY, JSON.stringify(previous));
    },
    [read],
  );

  const getStoredCategoryId = useCallback(
    (dataEntryObjectId: string): IPayload | null => {
      const previous = read();
      return previous[dataEntryObjectId] ?? null;
    },
    [read],
  );

  const clearSettingsForDataEntryObject = useCallback(
    (dataEntryObjectId: string) => {
      const previous = read();
      delete previous[dataEntryObjectId];
      localStorage.setItem(STORAGE_KEY, JSON.stringify(previous));
    },
    [read],
  );

  return useMemo(
    () => ({ saveCategoryId, getStoredCategoryId, clearSettingsForDataEntryObject }),
    [clearSettingsForDataEntryObject, getStoredCategoryId, saveCategoryId],
  );
};
