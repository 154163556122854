import { FormatUtilities } from "../../common/utilities/format.utilities";

export class DMAFormatUtilities {
  public static formatMaterialityDegree(materialityDegree: number | null | undefined): string {
    return materialityDegree !== null && materialityDegree !== undefined
      ? `${
          materialityDegree === 0 ? "-" : FormatUtilities.formatFixedDecimal(materialityDegree, 1)
        }`
      : "-";
  }
}
