/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IBaseTargetPathData } from '../models';
// @ts-ignore
import { IBaseTargetPathDataWithoutReferenceBudget } from '../models';
// @ts-ignore
import { ICloneTargetPath404Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget400Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget500Response } from '../models';
// @ts-ignore
import { ICreateTargetPathRequest } from '../models';
// @ts-ignore
import { IDeleteReferenceBudget404Response } from '../models';
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from '../models';
// @ts-ignore
import { IGetAllTargetPathsResponse } from '../models';
// @ts-ignore
import { IGetTargetPathProgressResponse } from '../models';
// @ts-ignore
import { ITargetPath } from '../models';
// @ts-ignore
import { IToggleTargetPathCommitted409Response } from '../models';
// @ts-ignore
import { IUpdateTargetPath404Response } from '../models';
// @ts-ignore
import { IUpdateTargetPath409Response } from '../models';
/**
 * TargetPathsApi - axios parameter creator
 * @export
 */
export const TargetPathsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint clones the target path with the given ID.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {IBaseTargetPathDataWithoutReferenceBudget} [iBaseTargetPathDataWithoutReferenceBudget] Update Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneTargetPath: async (organizationId: string, targetPathId: string, iBaseTargetPathDataWithoutReferenceBudget?: IBaseTargetPathDataWithoutReferenceBudget, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('cloneTargetPath', 'organizationId', organizationId)
            // verify required parameter 'targetPathId' is not null or undefined
            assertParamExists('cloneTargetPath', 'targetPathId', targetPathId)
            const localVarPath = `/organizations/{organizationId}/target-paths/{targetPathId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"targetPathId"}}`, encodeURIComponent(String(targetPathId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseTargetPathDataWithoutReferenceBudget, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint creates a new target path for the given organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {ICreateTargetPathRequest} [iCreateTargetPathRequest] Create Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTargetPath: async (organizationId: string, iCreateTargetPathRequest?: ICreateTargetPathRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createTargetPath', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/target-paths`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCreateTargetPathRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint deletes a given target path.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTargetPath: async (organizationId: string, targetPathId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteTargetPath', 'organizationId', organizationId)
            // verify required parameter 'targetPathId' is not null or undefined
            assertParamExists('deleteTargetPath', 'targetPathId', targetPathId)
            const localVarPath = `/organizations/{organizationId}/target-paths/{targetPathId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"targetPathId"}}`, encodeURIComponent(String(targetPathId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves the progress for the specified recording structures. Be careful to only specify THG structures!
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {Array<string>} recordingStructureIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetPathProgressForRecordingStructures: async (organizationId: string, recordingStructureIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTargetPathProgressForRecordingStructures', 'organizationId', organizationId)
            // verify required parameter 'recordingStructureIds' is not null or undefined
            assertParamExists('getTargetPathProgressForRecordingStructures', 'recordingStructureIds', recordingStructureIds)
            const localVarPath = `/organizations/{organizationId}/target-paths/progress`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required

            if (recordingStructureIds) {
                localVarQueryParameter['recordingStructureIds'] = recordingStructureIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves all target paths for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetPathsForOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTargetPathsForOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/target-paths`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint toggles the committed status of the specified target path.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleTargetPathCommitted: async (organizationId: string, targetPathId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('toggleTargetPathCommitted', 'organizationId', organizationId)
            // verify required parameter 'targetPathId' is not null or undefined
            assertParamExists('toggleTargetPathCommitted', 'targetPathId', targetPathId)
            const localVarPath = `/organizations/{organizationId}/target-paths/{targetPathId}/committed`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"targetPathId"}}`, encodeURIComponent(String(targetPathId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint updates the target path with the given id.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {IBaseTargetPathData} [iBaseTargetPathData] Update Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTargetPath: async (organizationId: string, targetPathId: string, iBaseTargetPathData?: IBaseTargetPathData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateTargetPath', 'organizationId', organizationId)
            // verify required parameter 'targetPathId' is not null or undefined
            assertParamExists('updateTargetPath', 'targetPathId', targetPathId)
            const localVarPath = `/organizations/{organizationId}/target-paths/{targetPathId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"targetPathId"}}`, encodeURIComponent(String(targetPathId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseTargetPathData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TargetPathsApi - functional programming interface
 * @export
 */
export const TargetPathsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TargetPathsApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint clones the target path with the given ID.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {IBaseTargetPathDataWithoutReferenceBudget} [iBaseTargetPathDataWithoutReferenceBudget] Update Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathDataWithoutReferenceBudget?: IBaseTargetPathDataWithoutReferenceBudget, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITargetPath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneTargetPath(organizationId, targetPathId, iBaseTargetPathDataWithoutReferenceBudget, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint creates a new target path for the given organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {ICreateTargetPathRequest} [iCreateTargetPathRequest] Create Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTargetPath(organizationId: string, iCreateTargetPathRequest?: ICreateTargetPathRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITargetPath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTargetPath(organizationId, iCreateTargetPathRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint deletes a given target path.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTargetPath(organizationId: string, targetPathId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTargetPath(organizationId, targetPathId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint retrieves the progress for the specified recording structures. Be careful to only specify THG structures!
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {Array<string>} recordingStructureIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetPathProgressForRecordingStructures(organizationId: string, recordingStructureIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetTargetPathProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetPathProgressForRecordingStructures(organizationId, recordingStructureIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint retrieves all target paths for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetPathsForOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllTargetPathsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetPathsForOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint toggles the committed status of the specified target path.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleTargetPathCommitted(organizationId: string, targetPathId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleTargetPathCommitted(organizationId, targetPathId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint updates the target path with the given id.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {IBaseTargetPathData} [iBaseTargetPathData] Update Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathData?: IBaseTargetPathData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITargetPath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTargetPath(organizationId, targetPathId, iBaseTargetPathData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TargetPathsApi - factory interface
 * @export
 */
export const TargetPathsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TargetPathsApiFp(configuration)
    return {
        /**
         * This endpoint clones the target path with the given ID.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {IBaseTargetPathDataWithoutReferenceBudget} [iBaseTargetPathDataWithoutReferenceBudget] Update Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathDataWithoutReferenceBudget?: IBaseTargetPathDataWithoutReferenceBudget, options?: any): AxiosPromise<ITargetPath> {
            return localVarFp.cloneTargetPath(organizationId, targetPathId, iBaseTargetPathDataWithoutReferenceBudget, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint creates a new target path for the given organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {ICreateTargetPathRequest} [iCreateTargetPathRequest] Create Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTargetPath(organizationId: string, iCreateTargetPathRequest?: ICreateTargetPathRequest, options?: any): AxiosPromise<ITargetPath> {
            return localVarFp.createTargetPath(organizationId, iCreateTargetPathRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint deletes a given target path.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTargetPath(organizationId: string, targetPathId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTargetPath(organizationId, targetPathId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves the progress for the specified recording structures. Be careful to only specify THG structures!
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {Array<string>} recordingStructureIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetPathProgressForRecordingStructures(organizationId: string, recordingStructureIds: Array<string>, options?: any): AxiosPromise<IGetTargetPathProgressResponse> {
            return localVarFp.getTargetPathProgressForRecordingStructures(organizationId, recordingStructureIds, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves all target paths for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetPathsForOrganization(organizationId: string, options?: any): AxiosPromise<IGetAllTargetPathsResponse> {
            return localVarFp.getTargetPathsForOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint toggles the committed status of the specified target path.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleTargetPathCommitted(organizationId: string, targetPathId: string, options?: any): AxiosPromise<void> {
            return localVarFp.toggleTargetPathCommitted(organizationId, targetPathId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint updates the target path with the given id.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} targetPathId The id of the target path to use.
         * @param {IBaseTargetPathData} [iBaseTargetPathData] Update Target Path Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathData?: IBaseTargetPathData, options?: any): AxiosPromise<ITargetPath> {
            return localVarFp.updateTargetPath(organizationId, targetPathId, iBaseTargetPathData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TargetPathsApi - interface
 * @export
 * @interface TargetPathsApi
 */
export interface TargetPathsApiInterface {
    /**
     * This endpoint clones the target path with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {IBaseTargetPathDataWithoutReferenceBudget} [iBaseTargetPathDataWithoutReferenceBudget] Update Target Path Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApiInterface
     */
    cloneTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathDataWithoutReferenceBudget?: IBaseTargetPathDataWithoutReferenceBudget, options?: AxiosRequestConfig): AxiosPromise<ITargetPath>;

    /**
     * This endpoint creates a new target path for the given organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ICreateTargetPathRequest} [iCreateTargetPathRequest] Create Target Path Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApiInterface
     */
    createTargetPath(organizationId: string, iCreateTargetPathRequest?: ICreateTargetPathRequest, options?: AxiosRequestConfig): AxiosPromise<ITargetPath>;

    /**
     * This endpoint deletes a given target path.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApiInterface
     */
    deleteTargetPath(organizationId: string, targetPathId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * This endpoint retrieves the progress for the specified recording structures. Be careful to only specify THG structures!
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {Array<string>} recordingStructureIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApiInterface
     */
    getTargetPathProgressForRecordingStructures(organizationId: string, recordingStructureIds: Array<string>, options?: AxiosRequestConfig): AxiosPromise<IGetTargetPathProgressResponse>;

    /**
     * This endpoint retrieves all target paths for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApiInterface
     */
    getTargetPathsForOrganization(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<IGetAllTargetPathsResponse>;

    /**
     * This endpoint toggles the committed status of the specified target path.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApiInterface
     */
    toggleTargetPathCommitted(organizationId: string, targetPathId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * This endpoint updates the target path with the given id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {IBaseTargetPathData} [iBaseTargetPathData] Update Target Path Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApiInterface
     */
    updateTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathData?: IBaseTargetPathData, options?: AxiosRequestConfig): AxiosPromise<ITargetPath>;

}

/**
 * TargetPathsApi - object-oriented interface
 * @export
 * @class TargetPathsApi
 * @extends {BaseAPI}
 */
export class TargetPathsApi extends BaseAPI implements TargetPathsApiInterface {
    /**
     * This endpoint clones the target path with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {IBaseTargetPathDataWithoutReferenceBudget} [iBaseTargetPathDataWithoutReferenceBudget] Update Target Path Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApi
     */
    public cloneTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathDataWithoutReferenceBudget?: IBaseTargetPathDataWithoutReferenceBudget, options?: AxiosRequestConfig) {
        return TargetPathsApiFp(this.configuration).cloneTargetPath(organizationId, targetPathId, iBaseTargetPathDataWithoutReferenceBudget, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint creates a new target path for the given organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ICreateTargetPathRequest} [iCreateTargetPathRequest] Create Target Path Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApi
     */
    public createTargetPath(organizationId: string, iCreateTargetPathRequest?: ICreateTargetPathRequest, options?: AxiosRequestConfig) {
        return TargetPathsApiFp(this.configuration).createTargetPath(organizationId, iCreateTargetPathRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint deletes a given target path.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApi
     */
    public deleteTargetPath(organizationId: string, targetPathId: string, options?: AxiosRequestConfig) {
        return TargetPathsApiFp(this.configuration).deleteTargetPath(organizationId, targetPathId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves the progress for the specified recording structures. Be careful to only specify THG structures!
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {Array<string>} recordingStructureIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApi
     */
    public getTargetPathProgressForRecordingStructures(organizationId: string, recordingStructureIds: Array<string>, options?: AxiosRequestConfig) {
        return TargetPathsApiFp(this.configuration).getTargetPathProgressForRecordingStructures(organizationId, recordingStructureIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves all target paths for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApi
     */
    public getTargetPathsForOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return TargetPathsApiFp(this.configuration).getTargetPathsForOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint toggles the committed status of the specified target path.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApi
     */
    public toggleTargetPathCommitted(organizationId: string, targetPathId: string, options?: AxiosRequestConfig) {
        return TargetPathsApiFp(this.configuration).toggleTargetPathCommitted(organizationId, targetPathId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint updates the target path with the given id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {IBaseTargetPathData} [iBaseTargetPathData] Update Target Path Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TargetPathsApi
     */
    public updateTargetPath(organizationId: string, targetPathId: string, iBaseTargetPathData?: IBaseTargetPathData, options?: AxiosRequestConfig) {
        return TargetPathsApiFp(this.configuration).updateTargetPath(organizationId, targetPathId, iBaseTargetPathData, options).then((request) => request(this.axios, this.basePath));
    }
}
