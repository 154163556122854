import { ESRSTopicIdentifier, IESRSTopicMetadata } from "./esrs-topic.public-types";

export const ORDERED_ESRS_TOPICS: ESRSTopicIdentifier[] = [
  ESRSTopicIdentifier.ESRS_2,
  ESRSTopicIdentifier.ESRS_E1,
  ESRSTopicIdentifier.ESRS_E2,
  ESRSTopicIdentifier.ESRS_E3,
  ESRSTopicIdentifier.ESRS_E4,
  ESRSTopicIdentifier.ESRS_E5,
  ESRSTopicIdentifier.ESRS_S1,
  ESRSTopicIdentifier.ESRS_S2,
  ESRSTopicIdentifier.ESRS_S3,
  ESRSTopicIdentifier.ESRS_S4,
  ESRSTopicIdentifier.ESRS_G1,
  ESRSTopicIdentifier.ESRS_O,
];

export const ESRS_TOPIC_METADATA: { [key in ESRSTopicIdentifier]: IESRSTopicMetadata } = {
  [ESRSTopicIdentifier.ESRS_2]: {
    supportsPhaseIn: false,
  },
  [ESRSTopicIdentifier.ESRS_E1]: {
    supportsPhaseIn: false,
  },
  [ESRSTopicIdentifier.ESRS_E2]: {
    supportsPhaseIn: false,
  },
  [ESRSTopicIdentifier.ESRS_E3]: {
    supportsPhaseIn: false,
  },
  [ESRSTopicIdentifier.ESRS_E4]: {
    supportsPhaseIn: true,
  },
  [ESRSTopicIdentifier.ESRS_E5]: {
    supportsPhaseIn: false,
  },
  [ESRSTopicIdentifier.ESRS_S1]: {
    supportsPhaseIn: true,
  },
  [ESRSTopicIdentifier.ESRS_S2]: {
    supportsPhaseIn: true,
  },
  [ESRSTopicIdentifier.ESRS_S3]: {
    supportsPhaseIn: true,
  },
  [ESRSTopicIdentifier.ESRS_S4]: {
    supportsPhaseIn: true,
  },
  [ESRSTopicIdentifier.ESRS_G1]: {
    supportsPhaseIn: false,
  },
  [ESRSTopicIdentifier.ESRS_O]: {
    supportsPhaseIn: false,
  },
};

export const ESRS_TOPICS_THAT_SUPPORT_PHASE_IN: ESRSTopicIdentifier[] = Object.entries(
  ESRS_TOPIC_METADATA,
)
  .filter(([, value]) => value.supportsPhaseIn)
  .map(([key]) => key as ESRSTopicIdentifier);

export const ORDERED_ESRS_TOPICS_THAT_SUPPORT_PHASE_IN = ORDERED_ESRS_TOPICS.filter((topic) =>
  ESRS_TOPICS_THAT_SUPPORT_PHASE_IN.includes(topic),
);
