import { Box, Button, Card, CardContent, Tooltip, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { QueryWrapper } from "../common/components/query-wrapper.component";
import { useRecordingPeriodRecordingStructuresQuery } from "../recording-structures/recording-structures.queries";
import { useHasOrganizationModuleAccess } from "../organization-module-access/has-organization-module-access.hook";
import { ModulesEnum } from "../organization-module-access/modules.enum";

interface IRecordingPeriodActionsDashboardSectionProps {
  organizationId: string;
  recordingPeriodId: string;
}

export const RecordingPeriodActionsDashboardSection: FC<
  IRecordingPeriodActionsDashboardSectionProps
> = ({ organizationId, recordingPeriodId }) => {
  const theme = useTheme();
  const { t } = useTranslation("recording_period_actions_dashboard_section");

  const hasDMAAccess = useHasOrganizationModuleAccess(organizationId, ModulesEnum.DMA);

  const recordingStructuresQuery = useRecordingPeriodRecordingStructuresQuery(
    organizationId,
    recordingPeriodId,
  );

  return (
    <QueryWrapper
      query={recordingStructuresQuery}
      loadingOverride={() => false} // Do not show loading indicator
      build={(data) => {
        const showDMARecommendation =
          // Only show DMA recommendation if user has access to DMA
          hasDMAAccess &&
          // Check if DMA hasn't been executed already (no esrs recording structure can be present)
          !data.recordingStructures.some((recordingStructure) =>
            recordingStructure.categoryIdentifier.startsWith("esrs"),
          );

        const hasAnyRecommendations = showDMARecommendation;

        return (
          hasAnyRecommendations && (
            <Box display="flex" flexDirection="column" pt="7vh" mb={4}>
              {showDMARecommendation && (
                <Card
                  sx={{
                    width: 580,
                    maxWidth: "100%",
                    mx: "auto",
                    border: "none",
                    borderRadius: 8,
                    p: 1,
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 30px 2px",
                    backgroundColor: `${theme.palette.primary.main}10`,
                    ":hover": {
                      transition: "box-shadow 300ms",
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 30px 5px",
                    },
                  }}
                >
                  <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Typography variant="h3">{t("title_double_materiality_assessment")}</Typography>
                    <Typography variant="body1" whiteSpace="pre-line">
                      {t("text_double_materiality_assessment")}
                    </Typography>
                    <Tooltip
                      title={t("button_double_materiality_assessment_tooltip")}
                      placement="right"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to={`/organizations/${organizationId}/recording-periods/${recordingPeriodId}/double-materiality-assessment`}
                        sx={{ mx: "auto" }}
                      >
                        {t("button_double_materiality_assessment")}
                      </Button>
                    </Tooltip>
                  </CardContent>
                </Card>
              )}
            </Box>
          )
        );
      }}
    />
  );
};
