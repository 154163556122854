import {
  IDataEntryObjectInputParameter,
  IDataEntryObjectInputParameterValue,
  IDataEntryObjectInputParameterValueData,
  IDataEntryObjectInputParameterValueValueForKey,
  IDEOIPValueDataQuality,
  IGetDataEntryObjectInputParametersResponse,
  IInputParameter,
  ITranslatedProperty,
} from "@netcero/netcero-core-api-client";
import {
  CalculatedIcon,
  EstimatedIcon,
  MeasuredIcon,
} from "../../common/constants/tabler-icon.constants";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
  ILocalGetDataEntryObjectInputParametersResponse,
} from "../interfaces/local-data-entry-object-values.interfaces";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { TablerIconsProps } from "@tabler/icons-react";
import {
  DataEntryObjectInputParameterValuesVerification,
  DataEntryObjectInputParameterValueType,
  PartialRecord,
} from "@netcero/netcero-common";
import Joi from "joi";

const DATA_QUALITY_ICONS: {
  [key in IDEOIPValueDataQuality]: ForwardRefExoticComponent<
    Omit<TablerIconsProps, "ref"> & RefAttributes<unknown>
  >;
} = {
  [IDEOIPValueDataQuality.Calculated]: CalculatedIcon,
  [IDEOIPValueDataQuality.Measured]: MeasuredIcon,
  [IDEOIPValueDataQuality.Estimated]: EstimatedIcon,
};

export type IDependencyMap = Record<
  string,
  { shouldRender: boolean; showAlert: boolean; name: ITranslatedProperty | undefined }
>;

export const ORDERED_DATA_QUALITY: IDEOIPValueDataQuality[] = [
  IDEOIPValueDataQuality.Measured,
  IDEOIPValueDataQuality.Calculated,
  IDEOIPValueDataQuality.Estimated,
];

export interface IValueJoiErrorInformation {
  error: Joi.ValidationError;
  inputType: DataEntryObjectInputParameterValueType;
}

export type IValuesErrorsEntry = string | IValueJoiErrorInformation;
export type IValuesErrorsPerKey = Partial<Record<string, IValuesErrorsEntry>>;

export class DataEntryObjectValuesUtilities {
  public static getIconForDataQuality(dataQuality: IDEOIPValueDataQuality) {
    return DATA_QUALITY_ICONS[dataQuality];
  }

  public static convertApiDataEntryObjectInputParametersResponseToLocal(
    response: IGetDataEntryObjectInputParametersResponse,
  ): ILocalGetDataEntryObjectInputParametersResponse {
    return {
      ...response,
      inputParameters: response.inputParameters.map(
        DataEntryObjectValuesUtilities.convertApiDataEntryObjectInputParameterToLocal,
      ),
    };
  }

  public static convertApiDataEntryObjectInputParameterToLocal(
    apiDataEntryObjectInputParameter: IDataEntryObjectInputParameter,
  ): ILocalDataEntryObjectInputParameter {
    return {
      ...apiDataEntryObjectInputParameter,
      recordedValues: apiDataEntryObjectInputParameter.recordedValues.map(
        DataEntryObjectValuesUtilities.convertApiDataEntryObjectInputParameterValueToLocal,
      ),
    };
  }

  public static convertApiDataEntryObjectInputParameterValueToLocal(
    apiDataEntryObjectInputParameterValue: IDataEntryObjectInputParameterValue,
  ): ILocalDataEntryObjectInputParameterValue {
    return {
      ...apiDataEntryObjectInputParameterValue,
      enteredForDateStart: apiDataEntryObjectInputParameterValue.enteredForDateStart
        ? new Date(apiDataEntryObjectInputParameterValue.enteredForDateStart)
        : null,
      enteredForDateEnd: apiDataEntryObjectInputParameterValue.enteredForDateEnd
        ? new Date(apiDataEntryObjectInputParameterValue.enteredForDateEnd)
        : null,
      dataQuality: apiDataEntryObjectInputParameterValue.dataQuality ?? null,
    };
  }

  public static convertLocalDataEntryObjectInputParametersValueDataToApi(
    localDataEntryObjectInputParameterValue: ILocalDataEntryObjectInputParameterValueData,
  ): IDataEntryObjectInputParameterValueData {
    return {
      ...localDataEntryObjectInputParameterValue,
      enteredForDateStart:
        localDataEntryObjectInputParameterValue.enteredForDateStart?.toISOString(),
      enteredForDateEnd: localDataEntryObjectInputParameterValue.enteredForDateEnd?.toISOString(),
      dataQuality: localDataEntryObjectInputParameterValue.dataQuality ?? undefined,
    };
  }

  public static createEmptyValueFromInputParameter(
    inputParameter: IInputParameter,
  ): ILocalDataEntryObjectInputParameterValueData {
    const valuesPerKey: PartialRecord<string, IDataEntryObjectInputParameterValueValueForKey> = {};

    for (const value of inputParameter.values) {
      valuesPerKey[value.key] = {
        type: "simple",
        value: undefined,
      };
    }

    return {
      enteredForDateStart: null,
      enteredForDateEnd: null,
      dataQuality: null,
      sourceIds: [],
      valuesPerKey,
    };
  }

  public static getErrorsForValues(
    inputParameter: IInputParameter,
    values: Record<string, IDataEntryObjectInputParameterValueValueForKey | undefined>,
  ) {
    const errors: Record<string, IValuesErrorsEntry> = {};

    for (const ipValue of inputParameter.values) {
      const enteredValue = values[ipValue.key];

      // no value was found / the value was required but not provided
      if (enteredValue === undefined || (ipValue.required && enteredValue.value === undefined)) {
        errors[ipValue.key] = "required";
        continue;
      }

      // value was not required + not provided --> fine, continue
      if (enteredValue.value === undefined) {
        continue;
      }

      // not a "simple" value --> no validation necessary
      if (ipValue.valueConfiguration.type !== "simple") {
        continue;
      }

      const validationError = DataEntryObjectInputParameterValuesVerification.verifyValue(
        enteredValue.value,
        ipValue.valueConfiguration.configuration,
      );

      // set error in case one was returned
      if (validationError !== undefined) {
        errors[ipValue.key] = {
          error: validationError,
          inputType: ipValue.valueConfiguration.configuration.type,
        };
      }
    }

    return errors;
  }
}
