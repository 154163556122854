import { IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";

export class ESRSTopicUtilities {
  public static isTopicRecorded(topic: { recordings: unknown[]; templates: unknown[] }): boolean {
    return topic.recordings.length === 0 && topic.templates.length > 0;
  }

  public static convertTopicToDisplayTopic(topic: IESRSTopicIdentifier) {
    return topic.replace("_", " ").toLocaleUpperCase();
  }
}
