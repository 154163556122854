import { IInputParameterValue } from "@netcero/netcero-core-api-client";
import { InputParameterValuesPerKey } from "./input-parameters.public-types";

export class InputParametersUtilities {
  public static getInputParameterValuesConfigurationLookup(values: IInputParameterValue[]) {
    return values.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.key]: curr,
      }),
      {} as InputParameterValuesPerKey,
    );
  }
}
