import { RefObject, useEffect } from "react";

export const useObserveSize = (ref: RefObject<HTMLElement>, cb: ResizeObserverCallback) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(cb);

    // Subscribe to resize events
    if (ref.current) {
      resizeObserver.observe(ref.current);
    } else {
      console.warn("Ref is not available for observing size!");
    }

    // Destroy on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, cb]);
};
