/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IBaseSourceData } from '../models';
// @ts-ignore
import { ICreateReferenceBudget404Response } from '../models';
// @ts-ignore
import { IDeleteSource404Response } from '../models';
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from '../models';
// @ts-ignore
import { IGetAllSourceTagsResponse } from '../models';
// @ts-ignore
import { IGetAllSourcesResponse } from '../models';
// @ts-ignore
import { ISource } from '../models';
// @ts-ignore
import { IUpdateSource404Response } from '../models';
// @ts-ignore
import { IUpdateSource409Response } from '../models';
/**
 * SourcesApi - axios parameter creator
 * @export
 */
export const SourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint creates a new source.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseSourceData} [iBaseSourceData] Create Source Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSource: async (organizationId: string, iBaseSourceData?: IBaseSourceData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createSource', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/sources`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSourceData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint deletes a given source.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} sourceId The id of the source to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSource: async (organizationId: string, sourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteSource', 'organizationId', organizationId)
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('deleteSource', 'sourceId', sourceId)
            const localVarPath = `/organizations/{organizationId}/sources/{sourceId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"sourceId"}}`, encodeURIComponent(String(sourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves all source tags for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceTagsForOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getSourceTagsForOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/sources/tags`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves all sources for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesForOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getSourcesForOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/sources`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint updates the source with the given ID.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} sourceId The id of the source to use.
         * @param {IBaseSourceData} [iBaseSourceData] Update Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSource: async (organizationId: string, sourceId: string, iBaseSourceData?: IBaseSourceData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateSource', 'organizationId', organizationId)
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('updateSource', 'sourceId', sourceId)
            const localVarPath = `/organizations/{organizationId}/sources/{sourceId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"sourceId"}}`, encodeURIComponent(String(sourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSourceData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SourcesApi - functional programming interface
 * @export
 */
export const SourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint creates a new source.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseSourceData} [iBaseSourceData] Create Source Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSource(organizationId: string, iBaseSourceData?: IBaseSourceData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSource(organizationId, iBaseSourceData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint deletes a given source.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} sourceId The id of the source to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSource(organizationId: string, sourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSource(organizationId, sourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint retrieves all source tags for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourceTagsForOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllSourceTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourceTagsForOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint retrieves all sources for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourcesForOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllSourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourcesForOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint updates the source with the given ID.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} sourceId The id of the source to use.
         * @param {IBaseSourceData} [iBaseSourceData] Update Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSource(organizationId: string, sourceId: string, iBaseSourceData?: IBaseSourceData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSource(organizationId, sourceId, iBaseSourceData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SourcesApi - factory interface
 * @export
 */
export const SourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SourcesApiFp(configuration)
    return {
        /**
         * This endpoint creates a new source.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseSourceData} [iBaseSourceData] Create Source Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSource(organizationId: string, iBaseSourceData?: IBaseSourceData, options?: any): AxiosPromise<ISource> {
            return localVarFp.createSource(organizationId, iBaseSourceData, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint deletes a given source.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} sourceId The id of the source to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSource(organizationId: string, sourceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSource(organizationId, sourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves all source tags for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceTagsForOrganization(organizationId: string, options?: any): AxiosPromise<IGetAllSourceTagsResponse> {
            return localVarFp.getSourceTagsForOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves all sources for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesForOrganization(organizationId: string, options?: any): AxiosPromise<IGetAllSourcesResponse> {
            return localVarFp.getSourcesForOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint updates the source with the given ID.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} sourceId The id of the source to use.
         * @param {IBaseSourceData} [iBaseSourceData] Update Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSource(organizationId: string, sourceId: string, iBaseSourceData?: IBaseSourceData, options?: any): AxiosPromise<ISource> {
            return localVarFp.updateSource(organizationId, sourceId, iBaseSourceData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SourcesApi - interface
 * @export
 * @interface SourcesApi
 */
export interface SourcesApiInterface {
    /**
     * This endpoint creates a new source.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseSourceData} [iBaseSourceData] Create Source Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApiInterface
     */
    createSource(organizationId: string, iBaseSourceData?: IBaseSourceData, options?: AxiosRequestConfig): AxiosPromise<ISource>;

    /**
     * This endpoint deletes a given source.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} sourceId The id of the source to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApiInterface
     */
    deleteSource(organizationId: string, sourceId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * This endpoint retrieves all source tags for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApiInterface
     */
    getSourceTagsForOrganization(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<IGetAllSourceTagsResponse>;

    /**
     * This endpoint retrieves all sources for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApiInterface
     */
    getSourcesForOrganization(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<IGetAllSourcesResponse>;

    /**
     * This endpoint updates the source with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} sourceId The id of the source to use.
     * @param {IBaseSourceData} [iBaseSourceData] Update Reference Budget Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApiInterface
     */
    updateSource(organizationId: string, sourceId: string, iBaseSourceData?: IBaseSourceData, options?: AxiosRequestConfig): AxiosPromise<ISource>;

}

/**
 * SourcesApi - object-oriented interface
 * @export
 * @class SourcesApi
 * @extends {BaseAPI}
 */
export class SourcesApi extends BaseAPI implements SourcesApiInterface {
    /**
     * This endpoint creates a new source.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseSourceData} [iBaseSourceData] Create Source Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public createSource(organizationId: string, iBaseSourceData?: IBaseSourceData, options?: AxiosRequestConfig) {
        return SourcesApiFp(this.configuration).createSource(organizationId, iBaseSourceData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint deletes a given source.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} sourceId The id of the source to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public deleteSource(organizationId: string, sourceId: string, options?: AxiosRequestConfig) {
        return SourcesApiFp(this.configuration).deleteSource(organizationId, sourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves all source tags for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getSourceTagsForOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return SourcesApiFp(this.configuration).getSourceTagsForOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves all sources for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getSourcesForOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return SourcesApiFp(this.configuration).getSourcesForOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint updates the source with the given ID.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} sourceId The id of the source to use.
     * @param {IBaseSourceData} [iBaseSourceData] Update Reference Budget Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public updateSource(organizationId: string, sourceId: string, iBaseSourceData?: IBaseSourceData, options?: AxiosRequestConfig) {
        return SourcesApiFp(this.configuration).updateSource(organizationId, sourceId, iBaseSourceData, options).then((request) => request(this.axios, this.basePath));
    }
}
