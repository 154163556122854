import {
  AppliedDistributionCriteriaApiFp,
  AuditLogApiFp,
  Configuration,
  DataEntryObjectApiFp,
  DataEntryObjectDistributionCriteriaApiFp,
  DataEntryObjectInputParameterRecordingApiFp,
  DataEntryObjectInputParameterValueApiFp,
  DistributionCriteriaApiFp,
  DoubleMaterialityAssessmentApiFp,
  DoubleMaterialityAssessmentCategoryApiFp,
  DoubleMaterialityAssessmentFinancialEffectsApiFp,
  DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp,
  DoubleMaterialityAssessmentMaterialImpactsApiFp,
  ESRSTopicPhaseInDecisionsApiFp,
  ESRSTopicsApiFp,
  InputParameterRecordingStructureApiFp,
  InputParameterRecordingStructureTemplateApiFp,
  InputParameterValueListValuesApiFp,
  IntercomReferencesApiFp,
  OrganizationApiFp,
  OrganizationStructureApiFp,
  PoliciesApiFp,
  RecordingModelApiFp,
  RecordingModelTemplateGroupApiFp,
  RecordingPeriodApiFp,
  RecordingPeriodEvaluationApiFp,
  ReferenceBudgetsApiFp,
  SourceFilesApiFp,
  SourcesApiFp,
  StakeholdersApiFp,
  TargetPathGoalsApiFp,
  TargetPathsApiFp,
} from "@netcero/netcero-core-api-client";
import { Axios, AxiosError } from "axios";
import { EnvironmentUtilities } from "../common/utilities/environment.utilities";

export const API_BASE_PATH = EnvironmentUtilities.API_BASE_URL || "/api/v1";
if (EnvironmentUtilities.API_BASE_URL === undefined) {
  console.warn("API_BASE_PATH environment variable is not set. Using default value: /api/v1");
}

const APP_API_CONFIGURATION: Configuration = {
  basePath: API_BASE_PATH,
  isJsonMime: (mime: string) => mime === "application/json",
};

export class CoreApiService {
  static OrganizationApi = OrganizationApiFp(APP_API_CONFIGURATION);
  static AuditApi = AuditLogApiFp(APP_API_CONFIGURATION);
  static InputParameterRecordingStructureApi =
    InputParameterRecordingStructureApiFp(APP_API_CONFIGURATION);
  static RecordingModelApi = RecordingModelApiFp(APP_API_CONFIGURATION);
  static RecordingModelTemplateGroupApi = RecordingModelTemplateGroupApiFp(APP_API_CONFIGURATION);
  static InputParameterRecordingStructureTemplateApi =
    InputParameterRecordingStructureTemplateApiFp(APP_API_CONFIGURATION);
  static DataEntryObjectApi = DataEntryObjectApiFp(APP_API_CONFIGURATION);
  static DataEntryObjectInputParameterRecordingApi =
    DataEntryObjectInputParameterRecordingApiFp(APP_API_CONFIGURATION);
  static OrganizationStructureApi = OrganizationStructureApiFp(APP_API_CONFIGURATION);
  static DataEntryObjectInputParameterValueApi =
    DataEntryObjectInputParameterValueApiFp(APP_API_CONFIGURATION);
  static RecordingPeriodEvaluationApi = RecordingPeriodEvaluationApiFp(APP_API_CONFIGURATION);
  static RecordingPeriodApi = RecordingPeriodApiFp(APP_API_CONFIGURATION);
  static DMAApi = DoubleMaterialityAssessmentApiFp(APP_API_CONFIGURATION);
  static ESRSTopicsApi = ESRSTopicsApiFp(APP_API_CONFIGURATION);
  static DMACategoryApi = DoubleMaterialityAssessmentCategoryApiFp(APP_API_CONFIGURATION);
  static DMAMaterialImpactsApi =
    DoubleMaterialityAssessmentMaterialImpactsApiFp(APP_API_CONFIGURATION);
  static DMAFinancialEffectsApi =
    DoubleMaterialityAssessmentFinancialEffectsApiFp(APP_API_CONFIGURATION);
  static ListValuesApi = InputParameterValueListValuesApiFp(APP_API_CONFIGURATION);
  static StakeholdersApi = StakeholdersApiFp(APP_API_CONFIGURATION);
  static DistributionCriteriaApi = DistributionCriteriaApiFp(APP_API_CONFIGURATION);
  static AppliedDistributionCriteriaApi = AppliedDistributionCriteriaApiFp(APP_API_CONFIGURATION);
  static DataEntryObjectDistributionCriteriaApi =
    DataEntryObjectDistributionCriteriaApiFp(APP_API_CONFIGURATION);
  static DMACategoryManualFeedbackApi =
    DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp(APP_API_CONFIGURATION);
  static ReferenceBudgetsApi = ReferenceBudgetsApiFp(APP_API_CONFIGURATION);
  static TargetPathsApi = TargetPathsApiFp(APP_API_CONFIGURATION);
  static TargetPathGoalsApi = TargetPathGoalsApiFp(APP_API_CONFIGURATION);
  static SourcesApi = SourcesApiFp(APP_API_CONFIGURATION);
  static SourceFilesApi = SourceFilesApiFp(APP_API_CONFIGURATION);
  static PoliciesApi = PoliciesApiFp(APP_API_CONFIGURATION);
  static ESRSTopicPhaseInDecisionsApi = ESRSTopicPhaseInDecisionsApiFp(APP_API_CONFIGURATION);
  static IntercomReferencesApi = IntercomReferencesApiFp(APP_API_CONFIGURATION);

  static registerAxiosApiErrorTransformerInterceptor(axiosInstance: Axios) {
    axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
      return Promise.reject(CoreApiService.handleApiError(error));
    });
  }

  static handleApiError(axiosError: AxiosError) {
    if (axiosError.response?.data && axiosError.response?.data instanceof Object) {
      return axiosError.response.data;
    } else {
      return axiosError;
    }
  }
}
