import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useRef, useState, MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TreeGroupContainer } from "../../../common/components/tree-group-container.component";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import {
  CategoryMinusIcon,
  CategoryPlusIcon,
  IconSize,
  ChevronDownIcon,
  SquarePlusIcon,
  SquareMinusIcon,
  DotsIcon,
} from "../../../common/constants/tabler-icon.constants";
import { RecursiveUtilities, deduplicatePrimArray } from "@netcero/netcero-common";

interface IDataEntryObjectSelectGroupComponent {
  dataEntryGroup: IDataEntryObject;
  onChange?: () => void;
  disabled?: boolean;
  initialExpanded?: boolean;
  prefix?: string;
  checkedIds: string[];
  fieldChange: (event: string[]) => void;
  handleCheckBoxChange: (id: string) => void;
}

export const DataEntryObjectSelectGroupComponent: FC<IDataEntryObjectSelectGroupComponent> = ({
  dataEntryGroup,
  onChange,
  disabled,
  prefix = "",
  initialExpanded = false,
  checkedIds,
  fieldChange,
  handleCheckBoxChange,
}) => {
  const { t } = useTranslation("data_entry_object_select_group_component");

  const { name, children } = dataEntryGroup;

  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isLastInHierarchy = children.length === 0;

  const [expanded, setExpanded] = useState(initialExpanded ?? false);

  const checkedCheckboxes = useMemo(() => {
    const memoizedCheckedIds = deduplicatePrimArray(checkedIds);
    return memoizedCheckedIds.includes(dataEntryGroup.id);
  }, [checkedIds, dataEntryGroup.id]);

  const handleToggleExpansion = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setExpanded((curr) => !curr);
  };

  const handleCloseMenu = () => setMenuOpen(false);

  const handleSelectAll = () => {
    fieldChange(
      deduplicatePrimArray([
        ...RecursiveUtilities.flattenRecursiveStructureDown(dataEntryGroup).map((d) => d.id),
        ...checkedIds,
      ]),
    );

    handleCloseMenu();
  };

  const handleDeselectAll = () => {
    const allIds = deduplicatePrimArray(
      RecursiveUtilities.flattenRecursiveStructureDown(dataEntryGroup).map((d) => d.id),
    );

    fieldChange(checkedIds.filter((id) => !allIds.includes(id)));

    handleCloseMenu();
  };

  const handleSelectAllCategory = () => {
    const directIds = dataEntryGroup.children.map((child: IDataEntryObject) => child.id);
    fieldChange(deduplicatePrimArray([...checkedIds, ...directIds]));

    handleCloseMenu();
  };

  const handleDeselectAllCategory = () => {
    const directIds = deduplicatePrimArray(
      dataEntryGroup.children.map((child: IDataEntryObject) => child.id),
    );
    fieldChange(checkedIds.filter((id) => !directIds.includes(id)));

    handleCloseMenu();
  };

  return (
    <>
      {/* Menu Definition */}
      {!isLastInHierarchy && (
        <Menu anchorEl={menuRef.current} open={menuOpen} onClose={handleCloseMenu}>
          <MenuItem onClick={handleSelectAll}>
            <ListItemIcon>
              <SquarePlusIcon size={IconSize.Small} />
            </ListItemIcon>
            <ListItemText>{t("button_select_all")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDeselectAll}>
            <ListItemIcon>
              <SquareMinusIcon size={IconSize.Small} />
            </ListItemIcon>
            <ListItemText>{t("button_deselect_all")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleSelectAllCategory}>
            <ListItemIcon>
              <CategoryPlusIcon size={IconSize.Small} />
            </ListItemIcon>
            <ListItemText>{t("button_category_select_all")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDeselectAllCategory}>
            <ListItemIcon>
              <CategoryMinusIcon size={IconSize.Small} />
            </ListItemIcon>
            <ListItemText>{t("button_category_deselect_all")}</ListItemText>
          </MenuItem>
        </Menu>
      )}

      {/* Box with actual content */}
      <Box>
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
          <Checkbox
            size="medium"
            checked={checkedCheckboxes}
            onChange={() => handleCheckBoxChange(dataEntryGroup.id)}
            disabled={disabled}
          />
          <Typography
            variant="subtitle1"
            onClick={
              disabled
                ? undefined
                : isLastInHierarchy
                ? () => handleCheckBoxChange(dataEntryGroup.id)
                : handleToggleExpansion
            }
            flex={1}
          >
            {prefix} {name}
          </Typography>
          {!isLastInHierarchy && (
            <>
              <Tooltip title={t("button_settings")}>
                <span>
                  <IconButton
                    ref={menuRef}
                    size="medium"
                    onClick={() => setMenuOpen(true)}
                    disabled={disabled}
                  >
                    <DotsIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <IconButton
                size="medium"
                sx={{ rotate: expanded ? "0" : "180deg", transition: "0.3s ease" }}
                onClick={handleToggleExpansion}
              >
                <ChevronDownIcon />
              </IconButton>
            </>
          )}
        </Box>
        {!isLastInHierarchy && <Divider />}

        <Collapse in={!isLastInHierarchy ? expanded : false}>
          <TreeGroupContainer py={2}>
            {!isLastInHierarchy && (
              <Box pl={1}>
                {children.map((childGroup, index) => {
                  return (
                    <DataEntryObjectSelectGroupComponent
                      key={index} // Index is fine here, since order will not change
                      dataEntryGroup={childGroup}
                      onChange={onChange}
                      disabled={disabled}
                      prefix={prefix && `${prefix ? `${prefix}.` : ""}${index + 1}`}
                      initialExpanded={initialExpanded}
                      checkedIds={checkedIds}
                      fieldChange={fieldChange}
                      handleCheckBoxChange={handleCheckBoxChange}
                    />
                  );
                })}
              </Box>
            )}
          </TreeGroupContainer>
        </Collapse>
      </Box>
    </>
  );
};
