/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface IInputParameterValueMetaDataPolicy
 */
export interface IInputParameterValueMetaDataPolicy {
    /**
     * 
     * @type {string}
     * @memberof IInputParameterValueMetaDataPolicy
     */
    'type': IInputParameterValueMetaDataPolicyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IInputParameterValueMetaDataPolicy
     */
    'esrsTopicIdentifier': IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum;
}

export const IInputParameterValueMetaDataPolicyTypeEnum = {
    Policy: 'policy'
} as const;

export type IInputParameterValueMetaDataPolicyTypeEnum = typeof IInputParameterValueMetaDataPolicyTypeEnum[keyof typeof IInputParameterValueMetaDataPolicyTypeEnum];
export const IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum = {
    E1: 'esrs_e1',
    E2: 'esrs_e2',
    E3: 'esrs_e3',
    E4: 'esrs_e4',
    E5: 'esrs_e5',
    S1: 'esrs_s1',
    S2: 'esrs_s2',
    S3: 'esrs_s3',
    S4: 'esrs_s4'
} as const;

export type IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum = typeof IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum[keyof typeof IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum];


