import {
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useObserveSize } from "../hooks/use-observe-size.hook";

const BASE_STYLES = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};

interface ILineClampTextProps extends TypographyProps {
  maxLines?: number;
  onOverflowChange?: (isOverflowing: boolean) => void; // Callback to inform parent
}

export const LineClampTypography = forwardRef<HTMLSpanElement, ILineClampTextProps>(
  function LineClampTypography(
    { maxLines, children, onOverflowChange, ...typographyProps },
    outerRef,
  ) {
    const lineClampStyles: SxProps<Theme> = useMemo(() => {
      if (!maxLines) {
        return BASE_STYLES;
      }

      return {
        ...BASE_STYLES,
        display: "-webkit-box",
        WebkitLineClamp: maxLines.toString(),
        WebkitBoxOrient: "vertical", // deprecated prop but still required (no modern alternative)
      };
    }, [maxLines]);

    const typographyRef = useRef<HTMLSpanElement>(null);
    // Sync to outerRef
    useImperativeHandle(outerRef, () => typographyRef.current!);

    const handleOverflowChange = useRef<(isOverflowing: boolean) => void>();
    useEffect(() => {
      handleOverflowChange.current = onOverflowChange;
    }, [onOverflowChange]);

    const checkOverflow = useCallback(() => {
      if (typographyRef.current) {
        const isOverflow =
          typographyRef.current.clientHeight < typographyRef.current.scrollHeight ||
          typographyRef.current.clientWidth < typographyRef.current.scrollWidth;
        handleOverflowChange.current?.(isOverflow);
      }
    }, []);
    // Trigger on prop changes
    useEffect(() => {
      checkOverflow();
    }, [checkOverflow, maxLines]);

    useObserveSize(typographyRef, checkOverflow);

    return (
      <Typography
        ref={typographyRef}
        {...typographyProps}
        sx={{
          ...lineClampStyles,
          ...typographyProps.sx,
        }}
      >
        {children}
      </Typography>
    );
  },
);

interface ILineClampTypographyWithTooltipProps extends ILineClampTextProps {
  tooltipOverride?: ReactNode;
}

export const LineClampTypographyWithTooltip: FC<ILineClampTypographyWithTooltipProps> = ({
  tooltipOverride,
  children,
  onOverflowChange,
  ...lineClampProps
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOverFlowChange = useCallback(
    (isOverflowing: boolean) => {
      setShowTooltip(isOverflowing);
      onOverflowChange?.(isOverflowing);
    },
    [onOverflowChange],
  );

  return (
    <Tooltip title={showTooltip ? tooltipOverride ?? children : null}>
      <LineClampTypography {...lineClampProps} onOverflowChange={handleOverFlowChange}>
        {children}
      </LineClampTypography>
    </Tooltip>
  );
};
