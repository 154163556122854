/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IAddFinancialEffect404Response } from '../models';
// @ts-ignore
import { IAddFinancialEffect409Response } from '../models';
// @ts-ignore
import { IAddMaterialImpact400Response } from '../models';
// @ts-ignore
import { IBaseDMAMaterialImpactData } from '../models';
// @ts-ignore
import { IDMAMaterialImpactWithCalculatedValues } from '../models';
// @ts-ignore
import { IDeleteMaterialImpact409Response } from '../models';
// @ts-ignore
import { ISetIROStateRequest } from '../models';
// @ts-ignore
import { IUpdateMaterialImpact400Response } from '../models';
// @ts-ignore
import { IUpdateMaterialImpact404Response } from '../models';
// @ts-ignore
import { IUpdateMaterialImpact409Response } from '../models';
// @ts-ignore
import { IUpdateMaterialImpactIroState403Response } from '../models';
// @ts-ignore
import { IUpdateMaterialImpactIroState404Response } from '../models';
// @ts-ignore
import { IUpdateMaterialImpactIroState409Response } from '../models';
/**
 * DoubleMaterialityAssessmentMaterialImpactsApi - axios parameter creator
 * @export
 */
export const DoubleMaterialityAssessmentMaterialImpactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a new material impact for the specified data entry object and double materiality assessment category.
         * @summary Adds a new material impact  for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMaterialImpact: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('addMaterialImpact', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('addMaterialImpact', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('addMaterialImpact', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('addMaterialImpact', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('addMaterialImpact', 'dmaCategoryId', dmaCategoryId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/material-impacts`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseDMAMaterialImpactData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing material impact for the specified data entry object and double materiality assessment category.
         * @summary Deletes an existing material impact for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterialImpact: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteMaterialImpact', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('deleteMaterialImpact', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('deleteMaterialImpact', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('deleteMaterialImpact', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('deleteMaterialImpact', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'materialImpactId' is not null or undefined
            assertParamExists('deleteMaterialImpact', 'materialImpactId', materialImpactId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/material-impacts/{materialImpactId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
                .replace(`{${"materialImpactId"}}`, encodeURIComponent(String(materialImpactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing material impact for the specified data entry object and double materiality assessment category.
         * @summary Updates an existing material impact for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterialImpact: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateMaterialImpact', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateMaterialImpact', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updateMaterialImpact', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('updateMaterialImpact', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('updateMaterialImpact', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'materialImpactId' is not null or undefined
            assertParamExists('updateMaterialImpact', 'materialImpactId', materialImpactId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/material-impacts/{materialImpactId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
                .replace(`{${"materialImpactId"}}`, encodeURIComponent(String(materialImpactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseDMAMaterialImpactData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the IRO state of a given material impact.
         * @summary Updates the IRO state of a given material impact.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterialImpactIroState: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iSetIROStateRequest: ISetIROStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateMaterialImpactIroState', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateMaterialImpactIroState', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updateMaterialImpactIroState', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('updateMaterialImpactIroState', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('updateMaterialImpactIroState', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'materialImpactId' is not null or undefined
            assertParamExists('updateMaterialImpactIroState', 'materialImpactId', materialImpactId)
            // verify required parameter 'iSetIROStateRequest' is not null or undefined
            assertParamExists('updateMaterialImpactIroState', 'iSetIROStateRequest', iSetIROStateRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/material-impacts/{materialImpactId}/iro-state`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
                .replace(`{${"materialImpactId"}}`, encodeURIComponent(String(materialImpactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSetIROStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoubleMaterialityAssessmentMaterialImpactsApi - functional programming interface
 * @export
 */
export const DoubleMaterialityAssessmentMaterialImpactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoubleMaterialityAssessmentMaterialImpactsApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a new material impact for the specified data entry object and double materiality assessment category.
         * @summary Adds a new material impact  for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAMaterialImpactWithCalculatedValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iBaseDMAMaterialImpactData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing material impact for the specified data entry object and double materiality assessment category.
         * @summary Deletes an existing material impact for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing material impact for the specified data entry object and double materiality assessment category.
         * @summary Updates an existing material impact for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAMaterialImpactWithCalculatedValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, iBaseDMAMaterialImpactData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the IRO state of a given material impact.
         * @summary Updates the IRO state of a given material impact.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMaterialImpactIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iSetIROStateRequest: ISetIROStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAMaterialImpactWithCalculatedValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMaterialImpactIroState(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, iSetIROStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoubleMaterialityAssessmentMaterialImpactsApi - factory interface
 * @export
 */
export const DoubleMaterialityAssessmentMaterialImpactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoubleMaterialityAssessmentMaterialImpactsApiFp(configuration)
    return {
        /**
         * Adds a new material impact for the specified data entry object and double materiality assessment category.
         * @summary Adds a new material impact  for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: any): AxiosPromise<IDMAMaterialImpactWithCalculatedValues> {
            return localVarFp.addMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iBaseDMAMaterialImpactData, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing material impact for the specified data entry object and double materiality assessment category.
         * @summary Deletes an existing material impact for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing material impact for the specified data entry object and double materiality assessment category.
         * @summary Updates an existing material impact for the specified data entry object and double materiality assessment category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: any): AxiosPromise<IDMAMaterialImpactWithCalculatedValues> {
            return localVarFp.updateMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, iBaseDMAMaterialImpactData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the IRO state of a given material impact.
         * @summary Updates the IRO state of a given material impact.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {string} materialImpactId The id of the material impact to use.
         * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterialImpactIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iSetIROStateRequest: ISetIROStateRequest, options?: any): AxiosPromise<IDMAMaterialImpactWithCalculatedValues> {
            return localVarFp.updateMaterialImpactIroState(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, iSetIROStateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoubleMaterialityAssessmentMaterialImpactsApi - interface
 * @export
 * @interface DoubleMaterialityAssessmentMaterialImpactsApi
 */
export interface DoubleMaterialityAssessmentMaterialImpactsApiInterface {
    /**
     * Adds a new material impact for the specified data entry object and double materiality assessment category.
     * @summary Adds a new material impact  for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApiInterface
     */
    addMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: AxiosRequestConfig): AxiosPromise<IDMAMaterialImpactWithCalculatedValues>;

    /**
     * Deletes an existing material impact for the specified data entry object and double materiality assessment category.
     * @summary Deletes an existing material impact for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} materialImpactId The id of the material impact to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApiInterface
     */
    deleteMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates an existing material impact for the specified data entry object and double materiality assessment category.
     * @summary Updates an existing material impact for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} materialImpactId The id of the material impact to use.
     * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApiInterface
     */
    updateMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: AxiosRequestConfig): AxiosPromise<IDMAMaterialImpactWithCalculatedValues>;

    /**
     * Updates the IRO state of a given material impact.
     * @summary Updates the IRO state of a given material impact.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} materialImpactId The id of the material impact to use.
     * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApiInterface
     */
    updateMaterialImpactIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iSetIROStateRequest: ISetIROStateRequest, options?: AxiosRequestConfig): AxiosPromise<IDMAMaterialImpactWithCalculatedValues>;

}

/**
 * DoubleMaterialityAssessmentMaterialImpactsApi - object-oriented interface
 * @export
 * @class DoubleMaterialityAssessmentMaterialImpactsApi
 * @extends {BaseAPI}
 */
export class DoubleMaterialityAssessmentMaterialImpactsApi extends BaseAPI implements DoubleMaterialityAssessmentMaterialImpactsApiInterface {
    /**
     * Adds a new material impact for the specified data entry object and double materiality assessment category.
     * @summary Adds a new material impact  for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApi
     */
    public addMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentMaterialImpactsApiFp(this.configuration).addMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iBaseDMAMaterialImpactData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing material impact for the specified data entry object and double materiality assessment category.
     * @summary Deletes an existing material impact for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} materialImpactId The id of the material impact to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApi
     */
    public deleteMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentMaterialImpactsApiFp(this.configuration).deleteMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing material impact for the specified data entry object and double materiality assessment category.
     * @summary Updates an existing material impact for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} materialImpactId The id of the material impact to use.
     * @param {IBaseDMAMaterialImpactData} [iBaseDMAMaterialImpactData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApi
     */
    public updateMaterialImpact(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iBaseDMAMaterialImpactData?: IBaseDMAMaterialImpactData, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentMaterialImpactsApiFp(this.configuration).updateMaterialImpact(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, iBaseDMAMaterialImpactData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the IRO state of a given material impact.
     * @summary Updates the IRO state of a given material impact.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {string} materialImpactId The id of the material impact to use.
     * @param {ISetIROStateRequest} iSetIROStateRequest Used to set the IRO state of a financial effect / material impact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentMaterialImpactsApi
     */
    public updateMaterialImpactIroState(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, materialImpactId: string, iSetIROStateRequest: ISetIROStateRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentMaterialImpactsApiFp(this.configuration).updateMaterialImpactIroState(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, materialImpactId, iSetIROStateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
