import {
  Box,
  Button,
  Chip,
  DialogContentText,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IStakeholderFeedbackType,
  IVerifyStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionsDialog } from "../../../common/components/options.dialog";
import { CheckAllIcon, InfoIcon, UndoIcon } from "../../../common/constants/tabler-icon.constants";
import { DMACategoryFeedbackUtilities } from "../../common/dma-category-feedback.utilities";

interface IDMACategoryValidationButtonsProps {
  dmaCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren;
  disabled?: boolean;
  onVerify: (payload: IVerifyStakeholderFeedbackRequest) => void;
}
export const DMACategoryValidationButtons: FC<IDMACategoryValidationButtonsProps> = ({
  dmaCategory,
  disabled,
  onVerify,
}) => {
  const { t } = useTranslation("dma_category_stakeholder_feedback_dialog");

  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const canValidateMaterial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.doesCategoryHaveSubmittedFeedbackForType(
        dmaCategory,
        IStakeholderFeedbackType.Material,
      ) && dmaCategory.materialState === IDMACategoryState.Shared,
    [dmaCategory],
  );
  const canValidateFinancial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.doesCategoryHaveSubmittedFeedbackForType(
        dmaCategory,
        IStakeholderFeedbackType.Financial,
      ) && dmaCategory.financialState === IDMACategoryState.Shared,
    [dmaCategory],
  );

  return (
    <>
      <OptionsDialog
        open={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
        title={t("title_validation_help")}
        content={<DialogContentText>{t("content_validation_help")}</DialogContentText>}
        options={[
          {
            label: t("close", { ns: "buttons" }),
            onClick: () => setShowHelpDialog(false),
            buttonProps: { variant: "outlined" },
          },
        ]}
      />

      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h6">{t("header_validation")}</Typography>
        <IconButton size="small" onClick={() => setShowHelpDialog(true)}>
          <InfoIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
        {/* Material State */}
        {dmaCategory.materialState === IDMACategoryState.Verified ? (
          <Tooltip title={t("tooltip_undo_verified")}>
            <span>
              <Chip
                variant="outlined"
                label={t("material_verified")}
                color="success"
                deleteIcon={<UndoIcon />}
                disabled={disabled}
                onClick={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Material,
                  });
                }}
                onDelete={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Material,
                  });
                }}
                sx={{ pl: 0.75 }}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={!canValidateMaterial ? t("tooltip_steps_to_verify_material") : ""}>
            <span>
              <Button
                variant="outlined"
                color="success"
                startIcon={<CheckAllIcon />}
                onClick={() =>
                  onVerify({
                    verified: true,
                    feedbackType: IStakeholderFeedbackType.Material,
                  })
                }
                disabled={!canValidateMaterial || disabled}
              >
                {t("button_validate_material")}
              </Button>
            </span>
          </Tooltip>
        )}

        {dmaCategory.financialState === IDMACategoryState.Verified ? (
          <Tooltip title={t("tooltip_undo_verified")}>
            <span>
              <Chip
                variant="outlined"
                label={t("financial_verified")}
                color="success"
                deleteIcon={<UndoIcon />}
                disabled={disabled}
                onClick={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Financial,
                  });
                }}
                onDelete={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Financial,
                  });
                }}
                sx={{ pl: 0.75 }}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={!canValidateFinancial ? t("tooltip_steps_to_verify_financial") : ""}>
            <span>
              <Button
                variant="outlined"
                color="success"
                startIcon={<CheckAllIcon />}
                onClick={() =>
                  onVerify({
                    verified: true,
                    feedbackType: IStakeholderFeedbackType.Financial,
                  })
                }
                disabled={!canValidateFinancial || disabled}
              >
                {t("button_validate_financial")}
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  );
};
