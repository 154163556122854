import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { IDataEntryObjectRecordingInputParameter } from "./data-entry-object-input-parameters.utilities";
import { CoreApiService } from "../core-api/core-api.service";
import { getDataEntryObjectValuesQueryKey } from "../data-entry-object-values/queries/data-entry-object-values.queries";
import { getEvaluationForRecordingPeriodQueryKey } from "../evaluation/evaluation.queries";
import { IDataEntryObjectInputParameterExclusion } from "@netcero/netcero-core-api-client";

interface IUpdateInputParameterConfigurationMutation {
  updates: IDataEntryObjectRecordingInputParameter[] | IDataEntryObjectRecordingInputParameter;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
}

async function invalidateDataEntryObjectInputParametersQueries(
  queryClient: QueryClient,
  variables: {
    organizationId: string;
    recordingPeriodId: string;
    dataEntryObjectId: string;
    recordingStructureId: string;
  },
  invalidateEvaluation: boolean,
) {
  // Invalidate Cached Entered Values
  await queryClient.invalidateQueries({
    queryKey: getDataEntryObjectValuesQueryKey(
      variables.organizationId,
      variables.recordingPeriodId,
      variables.dataEntryObjectId,
      variables.recordingStructureId,
    ),
  });

  if (invalidateEvaluation) {
    // Invalidate Cached Evaluations
    void queryClient.invalidateQueries({
      queryKey: getEvaluationForRecordingPeriodQueryKey(
        variables.organizationId,
        variables.recordingPeriodId,
        variables.recordingStructureId,
      ),
    });
  }
}

export const useUpdateInputParameterConfigurationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      updates,
      recordingPeriodId,
      dataEntryObjectId,
      organizationId,
    }: IUpdateInputParameterConfigurationMutation) => {
      if (!Array.isArray(updates)) {
        updates = [updates];
      }

      return Promise.all(
        updates.map((update) =>
          CoreApiService.DataEntryObjectInputParameterRecordingApi.setDataEntryObjectInputParameterData(
            organizationId,
            recordingPeriodId,
            dataEntryObjectId,
            update.inputParameter.id,
            {
              isActive: update.isActive,
              contributingUserIds: update.contributingUserIds,
              responsibleUserId: update.responsibleUserId,
            },
          ).then((req) => req()),
        ),
      );
    },
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, true),
  });
};

interface IDEOIPIdentityData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
  inputParameterId: string;
}

export const useSubmitDataEntryObjectInputParameter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    }: IDEOIPIdentityData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.submitInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false),
  });
};

export const useApproveDataEntryObjectInputParameter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    }: IDEOIPIdentityData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.approveInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false),
  });
};

export const useRejectDataEntryObjectInputParameter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    }: IDEOIPIdentityData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.rejectInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false),
  });
};

interface IExclusionMutationData extends IDEOIPIdentityData {
  payload: IDataEntryObjectInputParameterExclusion;
}

export const useExcludeDataEntryObjectInputParameter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
      payload,
    }: IExclusionMutationData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.excludeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        payload,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false),
  });
};

export const useIncludeDataEntryObjectInputParameter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    }: IDEOIPIdentityData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.includeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false),
  });
};
