import { FC } from "react";
import { Box } from "@mui/material";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { OrganizationStructureBreadcrumbs } from "../../organization-structures/navigation/organization-structure-breadcrumbs.component";

interface IDeoEsrsFilterBarComponentProps {
  organizationStructure: IDataEntryObject;
  dataEntryObject: IDataEntryObject;
  onChangeDataEntryObject: (dataEntryObjectId: string) => void;
}

export const DeoEsrsFilterBarComponent: FC<IDeoEsrsFilterBarComponentProps> = ({
  organizationStructure,
  dataEntryObject,
  onChangeDataEntryObject,
}) => {
  return (
    <Box>
      {/* DEO Picker */}
      <OrganizationStructureBreadcrumbs
        organizationStructure={organizationStructure}
        onChange={onChangeDataEntryObject}
        dataEntryObjectId={dataEntryObject.id}
      />
    </Box>
  );
};
