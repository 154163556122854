import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameter,
} from "@netcero/netcero-core-api-client";
import {
  DataEntryObjectValuesUtilities,
  IValuesErrorsPerKey,
} from "../../utilities/data-entry-object-values.utilities";
import { DataEntryObjectInputParameterValuesVerification } from "@netcero/netcero-common";

interface IManageDataEntryObjectTableValueSettings {
  initialIsEditing?: boolean;
  inputParameter: IInputParameter;
  recordedValues: ILocalDataEntryObjectInputParameterValue[];
  subscribeToRecordedValues?: boolean;
}

export type IManageDataEntryObjectTableValueHandleSaveCallback = (
  createdRows: ILocalDataEntryObjectInputParameterValueData[],
  updatedRows: ILocalDataEntryObjectInputParameterValue[],
  deleteRows: ILocalDataEntryObjectInputParameterValue[],
) => Promise<unknown>;

export const useManageDataEntryObjectTableValue = ({
  initialIsEditing,
  inputParameter,
  recordedValues,
  subscribeToRecordedValues = false,
}: IManageDataEntryObjectTableValueSettings) => {
  // State
  const [isEditing, setIsEditing] = useState(initialIsEditing ?? false);
  const handleStartEditing = useCallback(() => setIsEditing(true), []);

  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const showErrors = useMemo(() => triedSubmitting, [triedSubmitting]);

  // Helpers

  const sanitizeRowValue = useCallback(
    (valuesPerKey: ILocalDataEntryObjectInputParameterValueData["valuesPerKey"]) =>
      DataEntryObjectInputParameterValuesVerification.sanitizeValues(
        valuesPerKey,
        inputParameter.values,
      ),
    [inputParameter.values],
  );

  // Value Handling

  const [values, setValues] =
    useState<
      (ILocalDataEntryObjectInputParameterValue | ILocalDataEntryObjectInputParameterValueData)[]
    >(recordedValues);
  useEffect(() => {
    if (subscribeToRecordedValues) {
      setValues(recordedValues);
    }
  }, [subscribeToRecordedValues, recordedValues]);

  const handleValueChange = useCallback(
    (
      rowIndex: number,
      valueKey: string,
      newValue: IDataEntryObjectInputParameterValueValueForKey,
    ) => {
      setValues((prevValues) => {
        const newValues = [...prevValues];

        newValues[rowIndex] = {
          ...newValues[rowIndex],
          valuesPerKey: {
            ...newValues[rowIndex].valuesPerKey,
            [valueKey]: newValue,
          },
        };

        return newValues;
      });
    },
    [],
  );

  /** Beware this ONLY checks for actual values in the IP - not for date, note etc */
  const checkIfRowHasChanges = useCallback(
    (
      row: ILocalDataEntryObjectInputParameterValue | ILocalDataEntryObjectInputParameterValueData,
    ) => {
      // New rows always have changes
      if ((row as ILocalDataEntryObjectInputParameterValue).id === undefined) {
        return true;
      }
      const recordedRow = recordedValues.find(
        (recordedRow) => recordedRow.id === (row as ILocalDataEntryObjectInputParameterValue).id,
      );
      if (!recordedRow) {
        console.error(
          "Unexpected state encountered! Recorded row not found for row with id",
          (row as ILocalDataEntryObjectInputParameterValue).id,
        );
        return true;
      }

      const sanitizedRowValues = sanitizeRowValue(row.valuesPerKey);
      return Object.keys(sanitizedRowValues).some(
        (key) => sanitizedRowValues[key]?.value !== recordedRow.valuesPerKey[key]?.value,
      );
    },
    [recordedValues, sanitizeRowValue],
  );

  const hasChanges = useMemo(() => {
    if (values.length !== recordedValues.length) {
      return true;
    }
    return values.some((rowValues, rowIndex) => {
      const recordedRowValues = recordedValues[rowIndex];
      const sanitizedRowValues = sanitizeRowValue(rowValues.valuesPerKey);
      return Object.keys(sanitizedRowValues).some(
        (key) => rowValues.valuesPerKey[key] !== recordedRowValues.valuesPerKey[key],
      );
    });
  }, [recordedValues, sanitizeRowValue, values]);

  // Error Handling

  const [rowsErrors, setRowsErrors] = useState<Partial<IValuesErrorsPerKey[]>>([]);
  const hasErrors = useMemo(
    () => rowsErrors.some((rowErrors) => rowErrors && Object.keys(rowErrors).length > 0),
    [rowsErrors],
  );

  const verifyAndSetRowErrors = useCallback(() => {
    const rowsErrors = values.map((rowValues) => {
      const sanitizedValues = sanitizeRowValue(rowValues.valuesPerKey);
      return DataEntryObjectValuesUtilities.getErrorsForValues(inputParameter, sanitizedValues);
    });
    setRowsErrors(rowsErrors);
    return !rowsErrors.some((rowsErrors) => Object.keys(rowsErrors).length > 0);
  }, [inputParameter, sanitizeRowValue, values]);

  // Run Validation on every change when triedSubmitting
  useEffect(() => {
    if (triedSubmitting) {
      verifyAndSetRowErrors();
    }
  }, [triedSubmitting, verifyAndSetRowErrors]);

  // General Handling

  const handleAddRow = useCallback(() => {
    setValues((prevValues) => [
      ...prevValues,
      DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(inputParameter),
    ]);
    handleStartEditing();
  }, [handleStartEditing, inputParameter]);

  const handleDeleteRow = useCallback((rowIndex: number) => {
    setIsEditing(true);
    setValues((prevValues) => prevValues.filter((_, index) => index !== rowIndex));
  }, []);

  const handleSubmit = useCallback(
    (cb: IManageDataEntryObjectTableValueHandleSaveCallback) => {
      return async () => {
        setTriedSubmitting(true);
        if (verifyAndSetRowErrors()) {
          // Find newly created rows
          const createdRows: ILocalDataEntryObjectInputParameterValueData[] = values
            .filter((value) => (value as ILocalDataEntryObjectInputParameterValue).id === undefined)
            .map((value) => ({
              ...value,
              valuesPerKey: sanitizeRowValue(value.valuesPerKey),
            }));
          // Find updated rows
          const preExistingValues = values.filter(
            (value) => (value as ILocalDataEntryObjectInputParameterValue).id !== undefined,
          ) as ILocalDataEntryObjectInputParameterValue[]; // This is fine since the check if an id exists is done above
          const updatedRows = preExistingValues.filter((value) => checkIfRowHasChanges(value));
          // Find deleted Rows
          const deletedRows = recordedValues.filter(
            (recordedRow) =>
              values.findIndex(
                (value) =>
                  recordedRow.id === (value as ILocalDataEntryObjectInputParameterValue).id,
              ) === -1,
          );
          await cb(createdRows, updatedRows, deletedRows);
          setIsEditing(false);
          setTriedSubmitting(false);
        }
      };
    },
    [verifyAndSetRowErrors, values, recordedValues, sanitizeRowValue, checkIfRowHasChanges],
  );

  const handleReset = useCallback(() => {
    setValues(recordedValues);
    setIsEditing(false);
    setTriedSubmitting(false);
  }, [recordedValues]);

  // Return Result

  return useMemo(
    () => ({
      state: { isEditing, values, rowsErrors: showErrors ? rowsErrors : [] },
      formState: {
        hasErrors: showErrors ? hasErrors : false,
        hasChanges,
      },
      handlers: {
        handleStartEditing,
        handleValueChange,
        handleAddRow,
        handleDeleteRow,
        handleSubmit,
        handleReset,
      },
    }),
    [
      isEditing,
      values,
      showErrors,
      rowsErrors,
      hasErrors,
      hasChanges,
      handleStartEditing,
      handleValueChange,
      handleAddRow,
      handleDeleteRow,
      handleSubmit,
      handleReset,
    ],
  );
};
