/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IAddressedMatterE2Enum = {
    PollutionOfAirMember: 'esrs:PollutionOfAirMember',
    PollutionOfWaterMember: 'esrs:PollutionOfWaterMember',
    PollutionOfSoilMember: 'esrs:PollutionOfSoilMember',
    PollutionOfLivingOrganismsAndFoodResourcesMember: 'esrs:PollutionOfLivingOrganismsAndFoodResourcesMember',
    SubstancesOfConcernMember: 'esrs:SubstancesOfConcernMember',
    SubstancesOfVeryHighConcernMember: 'esrs:SubstancesOfVeryHighConcernMember',
    MicroplasticsMember: 'esrs:MicroplasticsMember',
    OtherPollutionSubtopicMember: 'esrs:OtherPollutionSubtopicMember'
} as const;

export type IAddressedMatterE2Enum = typeof IAddressedMatterE2Enum[keyof typeof IAddressedMatterE2Enum];



