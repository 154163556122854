import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IStakeholderFeedback,
} from "@netcero/netcero-core-api-client";

export enum IFeedbackState {
  NotRequested = "not_requested",
  Pending = "pending",
  Accepted = "accepted",
  AcceptedWithComment = "accepted_with_comment",
  ChangesRequested = "changes_requested",
}

export const DMA_CATEGORY_STATES_ORDERED = [
  IDMACategoryState.Open,
  IDMACategoryState.WorkInProgress,
  IDMACategoryState.Shared,
  IDMACategoryState.Verified,
];

export class DMACategoryUtilities {
  /**
   * Get the feedback state
   * @param feedback The feedback to check
   * @returns The feedback state
   */
  public static getFeedbackState(feedback: IStakeholderFeedback | null): IFeedbackState {
    if (feedback === null) {
      return IFeedbackState.NotRequested;
    }
    if (feedback.agreed) {
      return feedback.feedback ? IFeedbackState.AcceptedWithComment : IFeedbackState.Accepted;
    }
    if (!feedback.agreed && (feedback.feedback || feedback.assessment)) {
      return IFeedbackState.ChangesRequested;
    }
    return IFeedbackState.Pending;
  }

  /**
   * Check if a state is above or equal to another state (in natural state order)
   * @param state The state to check
   * @param overOrEqualTo The state to compare to
   * @returns True if the state is above or equal to the other state
   */
  public static isCategoryStateAbove(
    state: IDMACategoryState,
    overOrEqualTo: IDMACategoryState,
  ): boolean {
    return (
      DMA_CATEGORY_STATES_ORDERED.indexOf(state) >=
      DMA_CATEGORY_STATES_ORDERED.indexOf(overOrEqualTo)
    );
  }

  /**
   * Check if a category or any of its child categories contains IROs
   * @param dmaCategory The category to check
   * @returns True if the category or any of its children contains IROs
   */
  public static doesCategoryOrChildrenContainIROs(
    dmaCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ): boolean {
    if (dmaCategory.financialEffects.length > 0 || dmaCategory.materialImpacts.length > 0) {
      return true;
    }

    // Check children
    if (
      dmaCategory.children.some((child) =>
        DMACategoryUtilities.doesCategoryOrChildrenContainIROs(child),
      )
    ) {
      return true;
    }

    return false;
  }

  /**
   * Gets the total state for a category ("smallest" state between material and financial state)
   * @param category The category to check
   * @returns The smallest state between material and financial state
   */
  public static getCategoryTotalState(
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) {
    const trim = trimStatusDownwards(IDMACategoryState.WorkInProgress);

    const result = DMACategoryUtilities.getLowestState([
      category.materialState,
      category.financialState,
    ]);

    // if at least one is WIP, ensure that the result is at least WIP
    return [category.financialState, category.materialState].some(
      (state) => state === IDMACategoryState.WorkInProgress,
    )
      ? trim(result)
      : result;
  }

  /**
   * Returns the lowest state from a list of states
   * @param states states to check
   * @returns The lowest state
   */
  public static getLowestState(states: IDMACategoryState[]): IDMACategoryState {
    return states.sort((a, b) => {
      return DMA_CATEGORY_STATES_ORDERED.indexOf(a) - DMA_CATEGORY_STATES_ORDERED.indexOf(b);
    })[0];
  }
}

const trimStatusDownwards = (notLowerThan: IDMACategoryState) => {
  return (current: IDMACategoryState) => {
    if (!DMACategoryUtilities.isCategoryStateAbove(current, notLowerThan)) {
      return notLowerThan;
    }
    return current;
  };
};
