import { FC, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { requiresAuthentication } from "../../modules/authentication/requires-authentication.hoc";
import { useOrganizationsQuery } from "../../modules/organizations/organizations.queries";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";

export const OrganizationsLayout: FC = requiresAuthentication(() => {
  const organizations = useOrganizationsQuery();
  const currentOrganization = useCurrentOrganization();

  const { organizationId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!organizations.data || !organizationId) {
      return;
    }

    const organizationIds = organizations.data.organizations.map((org) => org.id);
    if (!organizationIds.includes(organizationId)) {
      console.error(`User is not allowed to access organization ${organizationId}`);
      navigate("/organizations");
      return;
    }

    // If the organization was found, set the document title to NetCero | {current organization display name}
    if (currentOrganization) {
      document.title = `NetCero | ${currentOrganization.name}`;
    }
  }, [currentOrganization, navigate, organizationId, organizations.data]);

  return <Outlet />;
});
