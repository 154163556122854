import { Chip, Tooltip } from "@mui/material";
import { FC } from "react";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { useTranslation } from "react-i18next";
import { DMAFormatUtilities } from "../utilities/dma-format.utilities";

interface IMaterialityChipProps {
  materialityDegree: number | null | undefined;
  materiality: boolean | null | undefined;
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  variant?: "filled" | "outlined";
}

export const MaterialityChip: FC<IMaterialityChipProps> = ({
  materialityDegree,
  materiality,
  color = "primary",
  variant = "filled",
}) => {
  const { t } = useTranslation("dma_common");

  const percentageText = DMAFormatUtilities.formatMaterialityDegree(materialityDegree);
  const materialityText = FormatUtilities.formatBoolean(
    materiality,
    t("materiality_true_state", { ns: "dma_common" }),
    t("materiality_false_state", { ns: "dma_common" }),
  );

  return (
    <Tooltip title={t("materiality_chip_tooltip")}>
      <Chip
        variant={variant}
        color={color}
        size="small"
        label={`${percentageText} / ${materialityText}`}
      />
    </Tooltip>
  );
};
