import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import { IBaseStakeholderData, IStakeholder } from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../common/components/error-text.component";

const getFormDefaultValues = (stakeholder?: IStakeholder | null): IBaseStakeholderData => {
  return {
    name: stakeholder?.name ?? "",
    organization: stakeholder?.organization ?? "",
  };
};

interface IStakeholderEditDialogProps {
  open: boolean;
  stakeholder?: IStakeholder | null;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseStakeholderData | null) => void;
}

export const StakeholderEditDialog: FC<IStakeholderEditDialogProps> = ({
  open,
  stakeholder,
  loading,
  error,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation("stakeholder_edit_dialog");

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<IBaseStakeholderData>({
    defaultValues: getFormDefaultValues(stakeholder),
  });

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(stakeholder));
    }
  }, [open, stakeholder, reset]);

  const handleEmitData = (data: IBaseStakeholderData) => {
    onClose({
      name: data.name.trim(),
      organization: data.organization?.trim() || undefined,
    });
  };

  return (
    <Dialog open={open} onClose={!isDirty ? () => onClose(null) : undefined} fullWidth>
      <DialogTitle>{t(stakeholder ? "title_edit" : "title_create")}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          {/* Name Input */}
          <Controller
            control={control}
            name="name"
            rules={{ required: t("error_name_required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t("label_name")}
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          {/* Organization Input */}
          <Controller
            control={control}
            name="organization"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t("label_organization")}
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(null)} disabled={disabled}>
          {t(isDirty ? "cancel" : "close", { ns: "buttons" })}
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleEmitData)} disabled={disabled}>
          {t("save", { ns: "buttons" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
