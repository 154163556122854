/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IDMAFinancialEffectType = {
    Chance: 'chance',
    Risk: 'risk',
    Indefinable: 'indefinable'
} as const;

export type IDMAFinancialEffectType = typeof IDMAFinancialEffectType[keyof typeof IDMAFinancialEffectType];



