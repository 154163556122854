import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  IRecordingModel,
  IRecordingModelsResponse,
  IRecordingModelTemplatesGroup,
  IRecordingModelTemplatesGroupsResponse,
} from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { QueryWrapper } from "../common/components/query-wrapper.component";
import { CoreApiService } from "../core-api/core-api.service";
import { useInputParameterRecordingStructureTemplatesQuery } from "../input-parameter-recording-structures/input-parameter-recording-structures.queries";
import { ILocalOrganization } from "../organizations/local-organization.interface";
import { useRecordingModelTemplateGroups } from "../recording-model-template-groups/recording-model-template-groups.queries";
import { useRecordingModelsQuery } from "../recording-models/recording-models.queries";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import { getRecordingPeriodRecordingStructuresQueryKey } from "./recording-structures.queries";
import { useTranslateContent } from "../content-translation/hooks/translate-content.hook";
import { CenteredCircularProgress } from "../common/components/centered-circular-progress.component";
import { useHasOrganizationModuleAccess } from "../organization-module-access/has-organization-module-access.hook";
import { ModulesEnum } from "../organization-module-access/modules.enum";
import { useHasOrganizationFullAccess } from "../organization-module-access/has-organization-full-access.hook";
import { EnvironmentUtilities } from "../common/utilities/environment.utilities";

const ALLOW_ADDING_SAME_MODEL_STRUCTURES_MULTIPLE_TIMES =
  EnvironmentUtilities.IS_LOCAL || EnvironmentUtilities.IS_DEVELOPMENT;

interface IAddRecordingStructureToRecordingPeriodDialog {
  open: boolean;
  onClose: () => void;
  organization: ILocalOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  categoryIdentifiersInUse: Set<string>;
  orderNumbersInUse: Map<string | null, Set<number>>;
  initiallySelectedRecordingModelId: string | null;
}

export const AddRecordingStructureToRecordingPeriodDialog: FC<
  IAddRecordingStructureToRecordingPeriodDialog
> = ({
  open,
  onClose,
  organization,
  recordingPeriod,
  categoryIdentifiersInUse,
  orderNumbersInUse,
  initiallySelectedRecordingModelId,
}) => {
  const { t } = useTranslation("add_recording_structure_to_recording_period_dialog");
  const translateContent = useTranslateContent();

  const queryClient = useQueryClient();

  const [recordingModelId, setRecordingModelId] = useState<string>("");
  const [templatesGroupId, setTemplatesGroupId] = useState<string>("");
  const [templateId, setTemplateId] = useState<string>("");

  const hasFullAccess = useHasOrganizationFullAccess(organization.id);
  const hasAccessToESRS = useHasOrganizationModuleAccess(organization.id, ModulesEnum.ESRS);
  const selectRecordingModels = useCallback(
    (data: IRecordingModelsResponse) => {
      const result: IRecordingModel[] = [];
      if (hasFullAccess) {
        return data.recordingModels;
      }

      if (hasAccessToESRS) {
        result.push(...data.recordingModels.filter((rm) => rm.identifier === "ESRS"));
      }

      return result;
    },
    [hasFullAccess, hasAccessToESRS],
  );

  const selectTemplateGroups = useCallback(
    (data: IRecordingModelTemplatesGroupsResponse) => {
      // If allowed return all groups
      if (ALLOW_ADDING_SAME_MODEL_STRUCTURES_MULTIPLE_TIMES) {
        return data.groups;
      }
      // Otherwise return only groups that are not already in use
      return data.groups.filter((group) => !categoryIdentifiersInUse.has(group.categoryIdentifier));
    },
    [categoryIdentifiersInUse],
  );

  const recordingModelsQuery = useRecordingModelsQuery(selectRecordingModels);
  const selectedRecordingModel = recordingModelsQuery.data?.find(
    (rm) => rm.id === recordingModelId,
  );

  const templateGroupsQuery = useRecordingModelTemplateGroups(
    selectedRecordingModel?.id ?? "",
    selectTemplateGroups,
  );
  const selectedTemplatesGroup = templateGroupsQuery.data?.find(
    (grp) => grp.id === templatesGroupId,
  );

  const templatesQuery = useInputParameterRecordingStructureTemplatesQuery(
    selectedRecordingModel?.id ?? "",
    selectedTemplatesGroup?.id || null,
  );

  const createInputParameterRecordingStructureMutation = useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      recordingModelId,
      templatesGroup,
      templateId,
    }: {
      organizationId: string;
      recordingPeriodId: string;
      recordingModelId: string;
      templatesGroup: IRecordingModelTemplatesGroup;
      templateId: string;
    }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const templateDetails =
        await CoreApiService.InputParameterRecordingStructureTemplateApi.getTemplateDetails(
          recordingModelId,
          templatesGroup.id,
          templateId,
        )
          .then((req) => req())
          .then((res) => res.data);

      // figure out order number to use
      const orderNumbersForRecordingModel = orderNumbersInUse.get(recordingModelId);
      const orderNumber = orderNumbersForRecordingModel?.has(templatesGroup.order)
        ? Math.max(...orderNumbersForRecordingModel) + 1 // Use next available if already in use
        : templatesGroup.order; // Use original order if not in use

      return CoreApiService.InputParameterRecordingStructureApi.createInputParameterRecordingStructure(
        organizationId,
        recordingPeriodId,
        {
          name: templatesGroup.name,
          description: templatesGroup.description,
          categoryIdentifier: templatesGroup.categoryIdentifier,
          evaluations: templatesGroup.evaluations,
          icon: templatesGroup.icon,
          recordingModelId: templatesGroup.recordingModelId,
          recordingModelOrder: orderNumber,
          templateUsedId: templateId,
          structure: templateDetails.structure,
        },
      ).then((req) => req());
    },
    onSuccess: async (_, { organizationId, recordingPeriodId }) => {
      await queryClient.invalidateQueries({
        queryKey: getRecordingPeriodRecordingStructuresQueryKey(organizationId, recordingPeriodId),
      });
      onClose();
    },
  });

  // Reset on open
  useEffect(() => {
    if (open) {
      setRecordingModelId(initiallySelectedRecordingModelId ?? "");
      setTemplatesGroupId("");
      setTemplateId("");
    }
  }, [open, initiallySelectedRecordingModelId]);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    createInputParameterRecordingStructureMutation.mutate({
      organizationId: organization.id,
      recordingPeriodId: recordingPeriod.id,
      recordingModelId: recordingModelId,
      templatesGroup: selectedTemplatesGroup!,
      templateId: templateId,
    });
  };

  const handleSelectRecordingModel = (id: string) => {
    setTemplateId("");
    setTemplatesGroupId("");
    setRecordingModelId(id);
  };

  const handleSelectParameterModel = (id: string) => {
    setTemplateId("");
    setTemplatesGroupId(id);
  };

  const loading = createInputParameterRecordingStructureMutation.isPending;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("dialog_title", { organizationName: organization.name })}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          pt: 1,
        }}
      >
        {createInputParameterRecordingStructureMutation.isError && (
          <Box mb={2} textAlign="center">
            <ErrorTextComponent error={createInputParameterRecordingStructureMutation.error} />
          </Box>
        )}
        <QueryWrapper
          query={recordingModelsQuery}
          loadingOverride={() => <CenteredCircularProgress />}
          build={(recordingModelsData) => (
            <>
              {/* Recording Model Select */}
              {!recordingModelsData.length ? (
                <Typography>{t("recording_models_empty")}</Typography>
              ) : (
                <FormControl variant="outlined" sx={{ mt: 1 }} fullWidth>
                  <InputLabel>{t("choose_recording_model_label")}</InputLabel>
                  <Select
                    value={recordingModelId ?? undefined}
                    onChange={(evt) => handleSelectRecordingModel(evt.target?.value)}
                    label={t("choose_recording_model_label")}
                    disabled={loading}
                  >
                    <MenuItem value="">{t("choose_recording_model_item_text")}</MenuItem>
                    {recordingModelsData
                      .filter((rm) => rm.canStructureBeAddedByUser)
                      .map((model) => (
                        <MenuItem key={model.id} value={model.id}>
                          {translateContent(model.name)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              {selectedRecordingModel && (
                <QueryWrapper
                  query={templateGroupsQuery}
                  loadingOverride={() => <CenteredCircularProgress />}
                  build={(templateGroupsData) => (
                    <>
                      {/* Templates Group Select */}
                      {!templateGroupsData.length ? (
                        <Typography>
                          {t("template_groups_empty", {
                            recordingModelName: translateContent(selectedRecordingModel.name),
                          })}
                        </Typography>
                      ) : (
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel>{t("choose_templates_group_label")}</InputLabel>
                          <Select
                            value={templatesGroupId ?? undefined}
                            onChange={(evt) => handleSelectParameterModel(evt.target?.value)}
                            label={t("choose_templates_group_label")}
                            disabled={loading}
                          >
                            <MenuItem value="">{t("choose_templates_group_item_text")}</MenuItem>
                            {templateGroupsData.map((group) => (
                              <MenuItem key={group.id} value={group.id}>
                                {translateContent(group.name)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {/* Template Select */}
                      {selectedTemplatesGroup && (
                        <QueryWrapper
                          query={templatesQuery}
                          loadingOverride={() => <CenteredCircularProgress />}
                          build={(templatesData) =>
                            !templatesData.structureTemplates.length ? (
                              <Typography>
                                {t("templates_group_empty", {
                                  templatesGroupName: translateContent(selectedTemplatesGroup.name),
                                })}
                              </Typography>
                            ) : (
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t("choose_template_label")}</InputLabel>
                                <Select
                                  value={templateId ?? undefined}
                                  onChange={(evt) => setTemplateId(evt.target?.value)}
                                  label={t("choose_template_label")}
                                  disabled={loading}
                                >
                                  <MenuItem value="">{t("choose_template_item_text")}</MenuItem>
                                  {templatesData.structureTemplates?.map((template) => (
                                    <MenuItem key={template.id} value={template.id}>
                                      {translateContent(template.name)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )
                          }
                        />
                      )}
                    </>
                  )}
                />
              )}
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          {t("cancel_button_text")}
        </Button>
        <Button variant="contained" onClick={handleConfirm} disabled={!templateId || loading}>
          {t("accept_template_button_text")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
