/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IGetAllInputParameterRecordingStructureTemplatesResponse } from '../models';
// @ts-ignore
import { IGetAllTemplates404Response } from '../models';
// @ts-ignore
import { IGetTemplateDetails404Response } from '../models';
// @ts-ignore
import { IInputParameterRecordingStructureTemplateDetails } from '../models';
/**
 * InputParameterRecordingStructureTemplateApi - axios parameter creator
 * @export
 */
export const InputParameterRecordingStructureTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all templates for the given template group.
         * @summary Get all templates for the given template group.
         * @param {string} recordingModelId The id of the recording model to use.
         * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplates: async (recordingModelId: string, recordingModelTemplatesGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingModelId' is not null or undefined
            assertParamExists('getAllTemplates', 'recordingModelId', recordingModelId)
            // verify required parameter 'recordingModelTemplatesGroupId' is not null or undefined
            assertParamExists('getAllTemplates', 'recordingModelTemplatesGroupId', recordingModelTemplatesGroupId)
            const localVarPath = `/recording-models/{recordingModelId}/template-groups/{recordingModelTemplatesGroupId}/templates`
                .replace(`{${"recordingModelId"}}`, encodeURIComponent(String(recordingModelId)))
                .replace(`{${"recordingModelTemplatesGroupId"}}`, encodeURIComponent(String(recordingModelTemplatesGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all details for the given template.
         * @summary Get template details.
         * @param {string} recordingModelId The id of the recording model to use.
         * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
         * @param {string} inputParameterRecordingStructureTemplateId The id of the template to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDetails: async (recordingModelId: string, recordingModelTemplatesGroupId: string, inputParameterRecordingStructureTemplateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingModelId' is not null or undefined
            assertParamExists('getTemplateDetails', 'recordingModelId', recordingModelId)
            // verify required parameter 'recordingModelTemplatesGroupId' is not null or undefined
            assertParamExists('getTemplateDetails', 'recordingModelTemplatesGroupId', recordingModelTemplatesGroupId)
            // verify required parameter 'inputParameterRecordingStructureTemplateId' is not null or undefined
            assertParamExists('getTemplateDetails', 'inputParameterRecordingStructureTemplateId', inputParameterRecordingStructureTemplateId)
            const localVarPath = `/recording-models/{recordingModelId}/template-groups/{recordingModelTemplatesGroupId}/templates/{inputParameterRecordingStructureTemplateId}`
                .replace(`{${"recordingModelId"}}`, encodeURIComponent(String(recordingModelId)))
                .replace(`{${"recordingModelTemplatesGroupId"}}`, encodeURIComponent(String(recordingModelTemplatesGroupId)))
                .replace(`{${"inputParameterRecordingStructureTemplateId"}}`, encodeURIComponent(String(inputParameterRecordingStructureTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InputParameterRecordingStructureTemplateApi - functional programming interface
 * @export
 */
export const InputParameterRecordingStructureTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InputParameterRecordingStructureTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all templates for the given template group.
         * @summary Get all templates for the given template group.
         * @param {string} recordingModelId The id of the recording model to use.
         * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTemplates(recordingModelId: string, recordingModelTemplatesGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllInputParameterRecordingStructureTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTemplates(recordingModelId, recordingModelTemplatesGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all details for the given template.
         * @summary Get template details.
         * @param {string} recordingModelId The id of the recording model to use.
         * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
         * @param {string} inputParameterRecordingStructureTemplateId The id of the template to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateDetails(recordingModelId: string, recordingModelTemplatesGroupId: string, inputParameterRecordingStructureTemplateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInputParameterRecordingStructureTemplateDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateDetails(recordingModelId, recordingModelTemplatesGroupId, inputParameterRecordingStructureTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InputParameterRecordingStructureTemplateApi - factory interface
 * @export
 */
export const InputParameterRecordingStructureTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InputParameterRecordingStructureTemplateApiFp(configuration)
    return {
        /**
         * Returns all templates for the given template group.
         * @summary Get all templates for the given template group.
         * @param {string} recordingModelId The id of the recording model to use.
         * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplates(recordingModelId: string, recordingModelTemplatesGroupId: string, options?: any): AxiosPromise<IGetAllInputParameterRecordingStructureTemplatesResponse> {
            return localVarFp.getAllTemplates(recordingModelId, recordingModelTemplatesGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all details for the given template.
         * @summary Get template details.
         * @param {string} recordingModelId The id of the recording model to use.
         * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
         * @param {string} inputParameterRecordingStructureTemplateId The id of the template to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDetails(recordingModelId: string, recordingModelTemplatesGroupId: string, inputParameterRecordingStructureTemplateId: string, options?: any): AxiosPromise<IInputParameterRecordingStructureTemplateDetails> {
            return localVarFp.getTemplateDetails(recordingModelId, recordingModelTemplatesGroupId, inputParameterRecordingStructureTemplateId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InputParameterRecordingStructureTemplateApi - interface
 * @export
 * @interface InputParameterRecordingStructureTemplateApi
 */
export interface InputParameterRecordingStructureTemplateApiInterface {
    /**
     * Returns all templates for the given template group.
     * @summary Get all templates for the given template group.
     * @param {string} recordingModelId The id of the recording model to use.
     * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputParameterRecordingStructureTemplateApiInterface
     */
    getAllTemplates(recordingModelId: string, recordingModelTemplatesGroupId: string, options?: AxiosRequestConfig): AxiosPromise<IGetAllInputParameterRecordingStructureTemplatesResponse>;

    /**
     * Returns all details for the given template.
     * @summary Get template details.
     * @param {string} recordingModelId The id of the recording model to use.
     * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
     * @param {string} inputParameterRecordingStructureTemplateId The id of the template to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputParameterRecordingStructureTemplateApiInterface
     */
    getTemplateDetails(recordingModelId: string, recordingModelTemplatesGroupId: string, inputParameterRecordingStructureTemplateId: string, options?: AxiosRequestConfig): AxiosPromise<IInputParameterRecordingStructureTemplateDetails>;

}

/**
 * InputParameterRecordingStructureTemplateApi - object-oriented interface
 * @export
 * @class InputParameterRecordingStructureTemplateApi
 * @extends {BaseAPI}
 */
export class InputParameterRecordingStructureTemplateApi extends BaseAPI implements InputParameterRecordingStructureTemplateApiInterface {
    /**
     * Returns all templates for the given template group.
     * @summary Get all templates for the given template group.
     * @param {string} recordingModelId The id of the recording model to use.
     * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputParameterRecordingStructureTemplateApi
     */
    public getAllTemplates(recordingModelId: string, recordingModelTemplatesGroupId: string, options?: AxiosRequestConfig) {
        return InputParameterRecordingStructureTemplateApiFp(this.configuration).getAllTemplates(recordingModelId, recordingModelTemplatesGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all details for the given template.
     * @summary Get template details.
     * @param {string} recordingModelId The id of the recording model to use.
     * @param {string} recordingModelTemplatesGroupId The id of the recording model templates group to use.
     * @param {string} inputParameterRecordingStructureTemplateId The id of the template to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputParameterRecordingStructureTemplateApi
     */
    public getTemplateDetails(recordingModelId: string, recordingModelTemplatesGroupId: string, inputParameterRecordingStructureTemplateId: string, options?: AxiosRequestConfig) {
        return InputParameterRecordingStructureTemplateApiFp(this.configuration).getTemplateDetails(recordingModelId, recordingModelTemplatesGroupId, inputParameterRecordingStructureTemplateId, options).then((request) => request(this.axios, this.basePath));
    }
}
