import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { IDMAFinancialEffect, IDMAMaterialImpact } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { FormatTranslation } from "../../common/components/format-translation.component";

interface IDeleteImpactOrEffectConfirmDialogProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  type: "material_impact" | "financial_effect";
  effectOrImpact: IDMAMaterialImpact | IDMAFinancialEffect | null;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DeleteImpactOrEffectConfirmDialog: FC<IDeleteImpactOrEffectConfirmDialogProps> = ({
  open,
  loading,
  error,
  disabled,
  type,
  effectOrImpact,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation("delete_impact_or_effect_confirm_dialog");

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormatTranslation
          i18nKey={`title_${type}`}
          t={t}
          values={{ name: effectOrImpact?.title }}
        />
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        <Typography>{t(`content_text_${type}`, { name: effectOrImpact?.title })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} disabled={disabled}>
          {t("button_cancel")}
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={disabled}>
          {t("button_delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
