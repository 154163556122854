import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ErrorTextComponent } from "../../../common/components/error-text.component";
import { useTranslation } from "react-i18next";
import { IDMACategory } from "@netcero/netcero-core-api-client";
import { IConfirmDialogProps } from "../../../common/interfaces/confirm-dialog-props.interface";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";

interface IDeleteDMACategoryConfirmDialogProps extends IConfirmDialogProps {
  dmaCategory?: IDMACategory | null;
}
export const DeleteDMACategoryConfirmDialog: FC<IDeleteDMACategoryConfirmDialogProps> = ({
  onConfirm,
  open,
  loading,
  onCancel,
  error,
  disabled,
  dmaCategory,
}) => {
  const { t } = useTranslation("dma_category_delete_confirm_dialog");
  const renderName = useRenderDMACategoryName();

  return (
    <Dialog open={open}>
      <DialogTitle>{t("title", { name: renderName(dmaCategory) })}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        <Typography>{t("content_text", { name: renderName(dmaCategory) })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} disabled={disabled}>
          {t("button_cancel")}
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={disabled}>
          {t("button_delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
