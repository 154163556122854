import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";
import { DataEntryObjectValuesUtilities } from "../utilities/data-entry-object-values.utilities";

export const getDataEntryObjectValuesQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  recordingStructureId?: string,
) => [
  "dataEntryObjectValues",
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  recordingStructureId,
];
export const useDataEntryObjectValuesQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  recordingStructureId?: string,
) => {
  return useQuery({
    queryKey: getDataEntryObjectValuesQueryKey(
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      recordingStructureId,
    ),
    queryFn: () =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.getDataEntryObjectInputParameters(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        recordingStructureId,
      )
        .then((req) => req())
        .then((res) => res.data)
        .then(
          DataEntryObjectValuesUtilities.convertApiDataEntryObjectInputParametersResponseToLocal,
        ),
  });
};
