import { FC, useCallback } from "react";
import {
  IBaseTargetPathGoalData,
  ITargetPath,
  ITargetPathGoal,
  ITargetPathGoalValues,
} from "@netcero/netcero-core-api-client";
import { AbsoluteTargetPathGoalEditComponent } from "./absolute-target-path-goal-edit.component";
import { RelativeTargetPathGoalEditComponent } from "./relative-target-path-goal-edit.component";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  TargetPathGoalsCalculations,
  TargetPathGoalsDefaults,
  TargetPathGoalType,
} from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { ConfirmDialogComponent } from "../../common/components/confirm-dialog.component";
import { useDialogState } from "../../common/hooks/dialog-state.hook";

export interface ITargetPathGoalEditComponentProps {
  goal: ITargetPathGoal;
  targetPath: ITargetPath;
  onTargetPathGoalChange: (goal: IBaseTargetPathGoalData) => void;
  disabled?: boolean;
}

interface IDialogState {
  newType: TargetPathGoalType;
}

export const TargetPathGoalEditComponent: FC<ITargetPathGoalEditComponentProps> = ({
  goal,
  onTargetPathGoalChange,
  disabled,
  targetPath,
}) => {
  // Component state
  const {
    isOpen: isToggleOpen,
    openDialog: openToggle,
    data: toggleData,
    closeDialog: closeToggle,
  } = useDialogState<IDialogState>();

  // Event handlers
  const handleEditToggleChanged = useCallback(
    (newType: TargetPathGoalType) => {
      // values might be lost --> force user to confirm action
      if (newType === "relative") {
        openToggle({ newType });
        return;
      }

      if (goal.targetValues.type !== "relative") {
        // can't happen; this is just to ensure that the types are valid for the next step
        return;
      }

      // no values will be lost --> fine; no dialog
      onTargetPathGoalChange({
        targetYear: goal.targetYear,
        targetValues: TargetPathGoalsCalculations.convertRelativeToAbsoluteGoal(
          goal.targetValues,
          // if no reference budget is present, the value is already absolute, i.e. no transformation
          // will be performed. Therefore, 0 is a acceptable value for the reference revenue in that case
          targetPath.referenceBudget.referenceRevenue ?? 0,
          goal.targetYear,
          targetPath.referenceBudget.referenceYear,
        ),
      });
    },
    [
      goal.targetValues,
      goal.targetYear,
      onTargetPathGoalChange,
      openToggle,
      targetPath.referenceBudget.referenceRevenue,
      targetPath.referenceBudget.referenceYear,
    ],
  );

  const handleValueChange = useCallback(
    (targetValues: ITargetPathGoalValues) => {
      onTargetPathGoalChange({
        targetYear: goal.targetYear,
        targetValues,
      });
    },
    [goal.targetYear, onTargetPathGoalChange],
  );

  const handleCloseToggle = useCallback(
    (confirm: boolean) => {
      // change was confirmed --> update
      if (confirm && toggleData !== undefined) {
        onTargetPathGoalChange({
          targetYear: goal.targetYear,
          targetValues: TargetPathGoalsDefaults.getDefaultsForGoalType(toggleData.newType),
        });
      }
      closeToggle();
    },
    [toggleData, onTargetPathGoalChange, goal.targetYear, closeToggle],
  );

  const { t } = useTranslation("target_path_goal_edit_component");

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      {/* Confirm Dialog for Toggle */}
      <ConfirmDialogComponent
        open={isToggleOpen}
        text={{
          content: { title: t("confirm_dialog.title"), body: t("confirm_dialog.body") },
          buttons: {
            confirm: t("confirm_dialog.button_confirm"),
            cancel: t("confirm_dialog.button_cancel"),
          },
        }}
        onClose={handleCloseToggle}
      />

      {/* toggle between absolute and relative */}
      <TargetPathGoalEditToggle
        disabled={disabled}
        currentType={goal.targetValues.type}
        onTypeChange={handleEditToggleChanged}
      />

      {/* Editor for absolute goals */}
      {goal.targetValues.type === "absolute" && (
        <AbsoluteTargetPathGoalEditComponent
          disabled={disabled}
          values={goal.targetValues}
          onValueChange={handleValueChange}
          referenceBudget={targetPath.referenceBudget}
        />
      )}
      {/* Editor for relative goals */}
      {goal.targetValues.type === "relative" && (
        <RelativeTargetPathGoalEditComponent
          onValueChange={handleValueChange}
          disabled={disabled}
          values={goal.targetValues}
          referenceBudget={targetPath.referenceBudget}
          targetYear={goal.targetYear}
        />
      )}
    </Box>
  );
};

interface ITargetPathGoalEditToggleProps {
  currentType: TargetPathGoalType;
  onTypeChange: (newType: TargetPathGoalType) => void;
  disabled?: boolean;
}

const TargetPathGoalEditToggle: FC<ITargetPathGoalEditToggleProps> = ({
  currentType,
  onTypeChange,
  disabled,
}) => {
  const { t } = useTranslation("target_path_goal_edit_component", { keyPrefix: "toggle" });
  return (
    <Box display="flex" justifyContent="center" flexDirection="row">
      <ToggleButtonGroup
        disabled={disabled}
        size="small"
        color="primary"
        value={currentType}
        onChange={(event, value) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onTypeChange((event.currentTarget as any)["value"] as TargetPathGoalType);
        }}
      >
        <ToggleButton value="absolute">
          {/* TODO: Migrate to tabler icons at some point */}
          <Box display="flex" gap={0.2} alignItems="center">
            <Icon fontSize={16} icon="mdi:math-norm" />
            <Box>{t("text_absolute")}</Box>
          </Box>
        </ToggleButton>
        <ToggleButton value="relative">
          <Box display="flex" gap={0.2} alignItems="center">
            <Box>{t("text_relative")}</Box>
            <Icon fontSize={16} icon="mdi:distribute-horizontal-center" />
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
