import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";

interface IExecuteDMAConfirmDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  // showDraftsWarning?: boolean;
  onClose: (confirm: boolean) => void;
}
export const ExecuteDMAConfirmDialog: FC<IExecuteDMAConfirmDialogProps> = ({
  open,
  loading,
  error,
  disabled,
  // showDraftsWarning,
  onClose,
}) => {
  const { t } = useTranslation("execute_dma_confirm_dialog");

  return (
    <Dialog open={open}>
      <DialogTitle>{t("title")}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        {/* {showDraftsWarning && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            {t("drafts_warning")}
          </Alert>
        )} */}
        <Typography whiteSpace="pre-line">{t("content_text")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(false)} disabled={disabled}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClose(true)}
          disabled={disabled}
        >
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
