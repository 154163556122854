import {
  IGetAllRecordingPeriodsResponse,
  IRecordingPeriod,
} from "@netcero/netcero-core-api-client";
import { DateTime } from "luxon";

export interface ILocalGetAllRecordingPeriodsResponse
  extends Omit<IGetAllRecordingPeriodsResponse, "recordingPeriods"> {
  recordingPeriods: ILocalRecordingPeriod[];
}

export interface ILocalRecordingPeriod extends Omit<IRecordingPeriod, "startDate" | "endDate"> {
  startDate: Date;
  endDate: Date;
}

export class RecordingPeriodsUtilities {
  /**
   * Converts the API response to a local response.
   * @param response The original API response.
   */
  public static convertAllRecordingPeriodsResponseToLocal(
    response: IGetAllRecordingPeriodsResponse,
  ): ILocalGetAllRecordingPeriodsResponse {
    return {
      ...response,
      recordingPeriods: response.recordingPeriods.map((recordingPeriod) =>
        RecordingPeriodsUtilities.convertApiToLocalRecordingPeriod(recordingPeriod),
      ),
    };
  }

  /**
   * Converts an API recording period to a local recording period.
   * @param recordingPeriod The API recording period to convert to a local recording period.
   */
  public static convertApiToLocalRecordingPeriod(
    recordingPeriod: IRecordingPeriod,
  ): ILocalRecordingPeriod {
    return {
      ...recordingPeriod,
      startDate: new Date(recordingPeriod.startDate),
      endDate: new Date(recordingPeriod.endDate),
    };
  }

  /**
   * Get the default recording period boundaries for a given business year.
   * @param endOfBusinessYear End of the business year set for organization.
   * @param endOfPreviousRecordingPeriod End of the previous recording period. Will be used as startDate if it is after the generated start date.
   * @returns The start and end date of the recording period.
   */
  public static getDefaultRecordingPeriodBoundaries(
    endOfBusinessYear: Date,
    endOfPreviousRecordingPeriod?: Date,
  ) {
    const endOfPreviousRecordingPeriodDateTime =
      endOfPreviousRecordingPeriod !== undefined
        ? DateTime.fromJSDate(endOfPreviousRecordingPeriod)
        : undefined;

    let startDate: DateTime;
    let endDate = DateTime.fromJSDate(endOfBusinessYear)
      .set({ year: DateTime.local().year })
      .endOf("day");

    // Move the end of the recording period is in the past - move to the next year.
    if (endDate < DateTime.local()) {
      startDate = endDate;
      endDate = endDate.plus({ year: 1 });
    } else {
      startDate = endDate.minus({ year: 1 });
    }

    // Set Start Date to the beginning of the next day.
    startDate = startDate.plus({ day: 1 }).startOf("day");

    // Set to endOfPreviousRecordingPeriod if it is defined and is after the start date.
    if (
      endOfPreviousRecordingPeriodDateTime !== undefined &&
      startDate < endOfPreviousRecordingPeriodDateTime
    ) {
      startDate = endOfPreviousRecordingPeriodDateTime;
    }

    return {
      startDate: startDate.toJSDate(),
      endDate: endDate.toJSDate(),
    };
  }

  /**
   * Get the current date within the recording period.
   * If the current date is before the start date of the recording period, the start date will be returned.
   * If the current date is after the end date of the recording period, the end date will be returned.
   * @param recordingPeriod The recording period to get the current date within.
   * @returns The current date within the recording period.
   */
  public static getCurrentDateWithinRecordingPeriod(recordingPeriod: ILocalRecordingPeriod): Date {
    const currentDate = DateTime.local();

    const startDateOfRP = DateTime.fromJSDate(recordingPeriod.startDate);
    const endDateOfRP = DateTime.fromJSDate(recordingPeriod.endDate);

    if (currentDate < startDateOfRP) {
      return startDateOfRP.toJSDate();
    } else if (endDateOfRP < currentDate) {
      return endDateOfRP.toJSDate();
    }

    return currentDate.startOf("day").toJSDate();
  }
}
