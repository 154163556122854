import { IDMACategoryIdentificationData, IESRSTopicIdentificationData } from "./dma.mutations";
import {
  IOptOutOfDmaCategoryRequest,
  IRequestFeedbackForDmaCategoryRequest,
  IUpdateDMACategoryRequest,
  IVerifyStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";
import { getESRSTopicsQueryKey } from "../dma.queries";

interface ICreateDMACategoryData extends IESRSTopicIdentificationData {
  name: string;
  parentId?: string;
}

/**
 * Mutation for adding a tree of DMA categories.
 * @returns Mutation to opt out of a ESRS topic
 */
export const useCreateDMACategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ICreateDMACategoryData) => {
      const req = await CoreApiService.DMACategoryApi.addEsrsCategory(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        { name: data.name, parentId: data.parentId },
      );
      const res = await req();
      return res.data;
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

interface IUpdateDMACategoryData extends IESRSTopicIdentificationData {
  dmaCategoryId: string;
  payload: IUpdateDMACategoryRequest;
}

/**
 * Mutation for updating a single DMA category.
 * @returns Mutation to opt out of a ESRS topic
 */
export const useUpdateDMACategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IUpdateDMACategoryData) => {
      const req = await CoreApiService.DMACategoryApi.updateEsrsCategory(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      );
      const res = await req();
      return res.data;
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

export const useDeleteDMACategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IDMACategoryIdentificationData) => {
      const req = await CoreApiService.DMACategoryApi.deleteEsrsCategory(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
      );
      const res = await req();
      return res.data;
    },
    onSuccess: (data, variables, context) =>
      queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      }),
  });
};

/**
 * OPT OUT MUTATION
 */

interface IOptOutOfCategoryData extends IDMACategoryIdentificationData {
  payload: IOptOutOfDmaCategoryRequest;
}

/**
 * Mutation for opting out of a ESRS topic
 * @returns Mutation to opt out of a ESRS topic
 */
export const useOptOutDMACategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IOptOutOfCategoryData) => {
      return CoreApiService.DMACategoryApi.optOutOfDmaCategory(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

/**
 * REQUEST FEEDBACK MUTATION
 */

interface IRequestCategoryFeedbackMutationData extends IDMACategoryIdentificationData {
  payload: IRequestFeedbackForDmaCategoryRequest;
}

/**
 * Mutation for requesting feedback for a DMA category
 */
export const useRequestCategoryFeedbackMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IRequestCategoryFeedbackMutationData) => {
      return CoreApiService.DMACategoryApi.requestEsrsCategoryFeedback(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

/**
 * Mutation for revoking feedback request for DMA category
 */
export const useRevokeCategoryFeedbackRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IRequestCategoryFeedbackMutationData) => {
      return CoreApiService.DMACategoryApi.revokeEsrsCategoryFeedbackRequest(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

// Validation State

interface IVerifyDMACategoryStakeholderFeedbackMutationData extends IDMACategoryIdentificationData {
  payload: IVerifyStakeholderFeedbackRequest;
}

export const useVerifyDMACategoryStakeholderFeedback = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IVerifyDMACategoryStakeholderFeedbackMutationData) => {
      return CoreApiService.DMACategoryApi.verifyStakeholderFeedback(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};
