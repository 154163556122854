import { FC } from "react";
import { Avatar, Box, SxProps, Theme } from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { NoUserIcon } from "../../common/constants/tabler-icon.constants";
import { UserUtilities } from "@netcero/netcero-common";

type UserAvatarRelevantProps = Pick<IUserRepresentation, "firstName" | "lastName">;

interface IUserAvatarProps {
  user: UserAvatarRelevantProps | null | undefined;
  /** The diameter of the avatar (textSize will be half). DEFAULT: 28 */
  avatarDiameter?: number;
  avatarSx?: SxProps<Theme>;
  innerContainerSx?: SxProps<Theme>;
}

export const UserAvatar: FC<IUserAvatarProps> = ({
  user,
  avatarDiameter = 28,
  avatarSx,
  innerContainerSx,
}) => {
  return (
    <Avatar
      sx={{
        width: avatarDiameter,
        height: avatarDiameter,
        fontSize: avatarDiameter / 2,
        cursor: "pointer",
        bgcolor: user ? UserUtilities.getColorByUser(user) : undefined,
        ...avatarSx,
      }}
    >
      {user ? (
        <Box
          sx={{
            color: "primary.contrastText",
            WebkitTextFillColor: (theme) => theme.palette.primary.contrastText,
            ...innerContainerSx,
          }}
        >
          {user.firstName?.[0]}
          {user.lastName?.[0]}
        </Box>
      ) : (
        <NoUserIcon />
      )}
    </Avatar>
  );
};
