export const mapIfNotUndefined = <T, R>(
  value: T | undefined,
  mapper: (value: T) => R,
): R | undefined => (value === undefined ? undefined : mapper(value));

export const mapIfNotNull = <T, R>(value: T | null, mapper: (value: T) => R): R | null =>
  value === null ? null : mapper(value);

export const mapPromiseIfNotUndefined = <T, R>(
  value: T | undefined,
  mapper: (value: T) => Promise<R>,
): Promise<R | undefined> => (value === undefined ? Promise.resolve(undefined) : mapper(value));

export const mapOrDefault = <T, R extends object>(
  value: T | undefined,
  mapper: (value: T) => R,
  defaultValue: (() => R) | R,
): R => {
  if (value === undefined) {
    if (typeof defaultValue === "function") {
      return defaultValue();
    }
    return defaultValue;
  }
  return mapper(value);
};
