import { RecursiveKeyOf } from "../common";
import { IPolicyData } from "@netcero/netcero-core-api-client";
import { MDRDependencies } from "../mdrs";
import { MDRUtilities } from "../mdrs/mdrs.utilities";

export type KeysForPolicy = RecursiveKeyOf<
  Omit<IPolicyData, "materialImpactIds" | "financialEffectIds">
>;
type IDependenciesForPolicy = MDRDependencies<KeysForPolicy>;

const createBooleanEntry = MDRUtilities.createBooleanMDRDependencyFactory<KeysForPolicy>();

export const DEPENDENCIES_FOR_POLICY: IDependenciesForPolicy = {
  "general.explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementItExplanatory":
    createBooleanEntry(
      "general.policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementIt",
    ),
  "e2.disclosureOfHowPolicyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoilExplanatory":
    createBooleanEntry(
      "e2.policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil",
    ),
  "e2.disclosureOfHowPolicyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcernExplanatory":
    createBooleanEntry(
      "e2.policyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcern",
    ),
  "e2.disclosureOfHowPolicyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironmentExplanatory":
    createBooleanEntry(
      "e2.policyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironment",
    ),
  "e3.disclosureOfHowPolicyAddressesWaterManagementExplanatory": createBooleanEntry(
    "e3.policyAddressesWaterManagement",
  ),
  "e3.policyAddressesUseAndSourcingOfWaterAndMarineResourcesInOwnOperations": createBooleanEntry(
    "e3.policyAddressesWaterManagement",
  ),
  "e3.policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater": createBooleanEntry(
    "e3.policyAddressesWaterManagement",
  ),
  "e3.policyAddressesPreventionAndAbatementOfWaterPollutionResultingFromActivities":
    createBooleanEntry("e3.policyAddressesWaterManagement"),
  "e3.disclosureOfHowPolicyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResourcesExplanatory":
    createBooleanEntry(
      "e3.policyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResources",
    ),
  "e3.disclosureOfHowPolicyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChainExplanatory":
    createBooleanEntry(
      "e3.policyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChain",
    ),
  "e4.disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpactsExplanatory":
    createBooleanEntry(
      "e4.biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpacts",
    ),
  "e4.disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunitiesExplanatory":
    createBooleanEntry(
      "e4.biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunities",
    ),
  "e4.disclosureOfHowBiodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChainExplanatory":
    createBooleanEntry(
      "e4.biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain",
    ),
  "e4.disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory":
    createBooleanEntry(
      "e4.biodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversity",
    ),
  "e4.disclosureOfHowBiodiversityAndEcosystemsRelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpactsExplanatory":
    createBooleanEntry(
      "e4.biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpacts",
    ),
  "e5.disclosureOfHowPolicyAddressesTransitioningAwayFromUseOfVirginResourcesExplanatory":
    createBooleanEntry("e5.policyAddressesTransitioningAwayFromUseOfVirginResources"),
  "e5.disclosureOfHowPolicyAddressesSustainableSourcingAndUseOfRenewableResourcesExplanatory":
    createBooleanEntry("e5.policyAddressesSustainableSourcingAndUseOfRenewableResources"),
  "e5.disclosureOfHowPolicyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatmentExplanatory":
    createBooleanEntry(
      "e5.policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatment",
    ),
  "s.disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory":
    createBooleanEntry(
      "s.specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace",
    ),
  "e5.descriptionOfHowPolicyAddressesWasteHierarchyExplanatory": {
    dependsUpon: "e5.layerInWasteHierarchyThatPolicyAddresses",
    activeWhenValue: {
      type: "array-not-empty",
    },
  },
};
export const POLICY_INPUTS_DEPENDED_UPON: KeysForPolicy[] = Array.from(
  new Set(Object.values(DEPENDENCIES_FOR_POLICY).map((d) => d.dependsUpon)),
);
