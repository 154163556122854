import { Icon } from "@iconify/react";
import { Box, CardContent, Divider, Link, Typography } from "@mui/material";
import { IInputParameterRecordingStructureBase } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { StyledCardWithOnHoverShadow } from "../common/components/styled-card.component";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../content-translation/hooks/translate-content.hook";
import { EvaluationListItem } from "../evaluation/evaluation-list-item.component";

export interface IRecordingStructureCardProps {
  organizationId: string;
  recordingPeriodId: string;
  recordingStructure: IInputParameterRecordingStructureBase;
}
export const RecordingStructureCard: FC<IRecordingStructureCardProps> = ({
  organizationId,
  recordingPeriodId,
  recordingStructure,
}) => {
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();
  return (
    // TODO: Migrate to tabler icons at some point
    <StyledCardWithOnHoverShadow key={recordingStructure.id} sx={{ height: "100%" }}>
      <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Link
          component={RouterLink}
          to={`recording-structures/${recordingStructure.id}`}
          underline="none"
          color="inherit"
          sx={{ height: "100%" }}
        >
          <Box display="flex" alignItems="center" gap={2} height="100%">
            <Box flex={1} alignSelf="start">
              <Typography>
                <Icon icon={recordingStructure.icon} height={32} />
              </Typography>
              <Typography variant="h3">{translateContent(recordingStructure.name)}</Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {translateOptionalContent(recordingStructure.description)}
              </Typography>
            </Box>
            <Box>
              <Icon icon="ci:caret-right-sm" height="3rem" />
            </Box>
          </Box>
        </Link>
        {recordingStructure.evaluations.length > 0 && (
          <Box>
            <Divider sx={{ my: 1 }} />
            {recordingStructure.evaluations.map((evaluation) => (
              <EvaluationListItem
                key={evaluation}
                organizationId={organizationId}
                recordingPeriodId={recordingPeriodId}
                inputParameterRecordingStructureId={recordingStructure.id}
                evaluationKey={evaluation}
              />
            ))}
          </Box>
        )}
      </CardContent>
    </StyledCardWithOnHoverShadow>
  );
};
