import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { FC, ReactNode } from "react";
import { ErrorTextComponent } from "./error-text.component";

interface IConfirmDialogComponentProps {
  open: boolean;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (confirm: boolean) => void;
  text: {
    content: {
      title: ReactNode;
      body: ReactNode;
    };
    buttons: {
      confirm: ReactNode;
      cancel: ReactNode;
    };
  };
}

export const ConfirmDialogComponent: FC<IConfirmDialogComponentProps> = ({
  open,
  loading,
  error,
  disabled,
  onClose,
  text,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{text.content.title}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && (
          <Box pb={1}>
            <ErrorTextComponent error={error} />
          </Box>
        )}
        <DialogContentText>{text.content.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(false)} disabled={disabled}>
          {text.buttons.cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClose(true)}
          disabled={disabled}
        >
          {text.buttons.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
