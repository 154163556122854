/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IRecordingModelsResponse } from '../models';
/**
 * RecordingModelApi - axios parameter creator
 * @export
 */
export const RecordingModelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all RecordingModels
         * @summary Get all RecordingModels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecordingModels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recording-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecordingModelApi - functional programming interface
 * @export
 */
export const RecordingModelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecordingModelApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all RecordingModels
         * @summary Get all RecordingModels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRecordingModels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRecordingModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRecordingModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecordingModelApi - factory interface
 * @export
 */
export const RecordingModelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecordingModelApiFp(configuration)
    return {
        /**
         * Retrieves all RecordingModels
         * @summary Get all RecordingModels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecordingModels(options?: any): AxiosPromise<IRecordingModelsResponse> {
            return localVarFp.getAllRecordingModels(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecordingModelApi - interface
 * @export
 * @interface RecordingModelApi
 */
export interface RecordingModelApiInterface {
    /**
     * Retrieves all RecordingModels
     * @summary Get all RecordingModels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingModelApiInterface
     */
    getAllRecordingModels(options?: AxiosRequestConfig): AxiosPromise<IRecordingModelsResponse>;

}

/**
 * RecordingModelApi - object-oriented interface
 * @export
 * @class RecordingModelApi
 * @extends {BaseAPI}
 */
export class RecordingModelApi extends BaseAPI implements RecordingModelApiInterface {
    /**
     * Retrieves all RecordingModels
     * @summary Get all RecordingModels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingModelApi
     */
    public getAllRecordingModels(options?: AxiosRequestConfig) {
        return RecordingModelApiFp(this.configuration).getAllRecordingModels(options).then((request) => request(this.axios, this.basePath));
    }
}
