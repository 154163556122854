import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ILocalOrganization } from "../../organizations/local-organization.interface";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { ILocalInputParameterRecordingStructureESRS } from "../../input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";
import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
} from "@netcero/netcero-core-api-client";
import { ILocalDataEntryObjectInputParameter } from "../interfaces/local-data-entry-object-values.interfaces";
import { InputParameterRecordingEsrsStructuresUtilities } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { Box, Fade, Grid, Typography } from "@mui/material";
import { DeoEsrsFilterBarComponent } from "./deo-esrs-filter-bar.component";
import { DeoEsrsTableOfContents } from "./deo-esrs-table-of-contents.component";
import {
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
} from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { useSearchParamStateBasic } from "../../common/hooks/use-search-param-state.hook";
import { useDefaultSearchParamsValues } from "../../common/hooks/use-default-search-param-value";
import { useLocation, useNavigate } from "react-router-dom";
import { DeoEsrsValuesOverviewContent } from "./deo-esrs-values-overview-content.component";
import { DeoEsrsInputContextProvider, IDeoEsrsInputContextData } from "./deo-esrs-input.context";
import { useDeoEsrsApiActions } from "./deo-esrs-actions.hook";
import { useScrollPosition } from "../../common/hooks/use-scroll-position.hook";
import { useConditionalDisplayInputParameters } from "../hooks/conditional-display-input-parameters.hook";

const SECTION_ID_QUERY_KEY = "sectionId";

function createDisclosureRequirementOrInputParameterUrl(
  sectionId: string,
  disclosureRequirementOrInputParameterId: string,
) {
  const url = new URL(window.location.toString());
  url.searchParams.set(SECTION_ID_QUERY_KEY, sectionId);
  url.hash = disclosureRequirementOrInputParameterId;
  return url;
}

const createDisclosureRequirementUrl = (
  section: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
  disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
) =>
  createDisclosureRequirementOrInputParameterUrl(section.id, disclosureRequirement.id).toString();

const createInputParameterUrl = (
  section: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
  inputParameter: ILocalDataEntryObjectInputParameter,
) =>
  createDisclosureRequirementOrInputParameterUrl(
    section.id,
    inputParameter.inputParameter.id,
  ).toString();

interface IDataEntryObjectESRSValuesOverviewComponentProps {
  organization: ILocalOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  recordingStructure: ILocalInputParameterRecordingStructureESRS;
  organizationStructure: IDataEntryObject;
  dataEntryObject: IDataEntryObject;
  dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[];
  availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  onChangeDataEntryObject: (dataEntryObjectId: string) => void;
}

export const DeoEsrsValuesOverviewComponent: FC<
  IDataEntryObjectESRSValuesOverviewComponentProps
> = ({
  organization,
  recordingPeriod,
  recordingStructure,
  organizationStructure,
  dataEntryObject,
  dataEntryObjectInputParameters,
  availableDistributionCriteria,
  onChangeDataEntryObject,
}) => {
  const translateContent = useTranslateContent();

  const [expandedDisclosureRequirementIds, setExpandedDisclosureRequirementIds] = useState<
    string[]
  >([]);
  const expandDisclosureRequirement = useCallback(
    (disclosureRequirementId: string) =>
      setExpandedDisclosureRequirementIds((prev) =>
        Array.from(new Set([...prev, disclosureRequirementId])),
      ),
    [],
  );

  const [expandedInputParameterIds, setExpandedInputParameterIds] = useState<string[]>([]);
  const expandInputParameter = useCallback(
    (inputParameterId: string) =>
      setExpandedInputParameterIds((prev) => Array.from(new Set([...prev, inputParameterId]))),
    [],
  );

  // Conditional Input Parameters
  const { conditionalDisplayInputParametersLookup } = useConditionalDisplayInputParameters(
    dataEntryObjectInputParameters,
  );

  // Hydrate the structure with the data entry object input parameters
  const hydratedStructureGroup = useMemo(
    () =>
      InputParameterRecordingEsrsStructuresUtilities.hydrateStructureStructureGroup(
        recordingStructure.structure,
        dataEntryObjectInputParameters,
      ),
    [recordingStructure.structure, dataEntryObjectInputParameters],
  );

  const [completeHydratedStructureGroup /*, incompleteDRs, incompleteIPs */] = useMemo(
    () =>
      InputParameterRecordingEsrsStructuresUtilities.cleanUpStructureGroup(hydratedStructureGroup),
    [hydratedStructureGroup],
  );

  // Handle State Change (Router Query)

  const [viewedSectionId] = useSearchParamStateBasic(
    SECTION_ID_QUERY_KEY,
    hydratedStructureGroup.sections[0]?.id ?? "",
    false,
  );
  const viewedSection = useMemo(
    () => hydratedStructureGroup.sections.find((s) => s.id === viewedSectionId) ?? null,
    [hydratedStructureGroup.sections, viewedSectionId],
  );
  useDefaultSearchParamsValues({
    key: SECTION_ID_QUERY_KEY,
    value: hydratedStructureGroup.sections[0]?.id ?? "",
  });

  // Next and Previous Section

  const [previousSection, nextSection] = useMemo(() => {
    const currentSectionIndex = hydratedStructureGroup.sections.findIndex(
      (s) => s.id === viewedSectionId,
    );
    const previousSection =
      currentSectionIndex > 0 ? hydratedStructureGroup.sections[currentSectionIndex - 1] : null;
    const nextSection =
      currentSectionIndex < hydratedStructureGroup.sections.length - 1
        ? hydratedStructureGroup.sections[currentSectionIndex + 1]
        : null;

    return [previousSection, nextSection];
  }, [hydratedStructureGroup.sections, viewedSectionId]);

  const navigate = useNavigate();

  const handleChangeSection = useCallback(
    (
      newSection: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection | null,
    ) => {
      if (!newSection) {
        return undefined;
      }
      return () => {
        if (newSection.disclosureRequirements.length > 0) {
          const newUrl = createDisclosureRequirementOrInputParameterUrl(
            newSection.id,
            newSection.disclosureRequirements[0].id,
          );
          navigate({
            pathname: newUrl.pathname,
            search: newUrl.search,
            hash: newUrl.hash,
          });
        }
      };
    },
    [navigate],
  );

  // Handle viewed Disclosure Requirement

  const location = useLocation();
  const [viewedDisclosureRequirementId, setViewedDisclosureRequirementId] = useState(
    viewedSection?.disclosureRequirements[0]?.id ?? null,
  );

  const handleChangeViewedDisclosureRequirementOrInputParameter = useCallback(
    (disclosureRequirementOrInputParameterId: string) => {
      // Only works if viewedSection was found
      if (!viewedSection) {
        return;
      }

      const disclosureRequirement = viewedSection.disclosureRequirements.find(
        (dr) =>
          // Find DR with id
          dr.id === disclosureRequirementOrInputParameterId ||
          // If not found, check if IP in DR matches
          dr.inputParameters.some(
            (drIp) => drIp.parameterId === disclosureRequirementOrInputParameterId,
          ),
      );
      if (!disclosureRequirement) {
        return;
      }

      // Ensure the DR is expanded
      expandDisclosureRequirement(disclosureRequirement.id);
      const isDisclosureRequirement =
        disclosureRequirement.id === disclosureRequirementOrInputParameterId;
      if (!isDisclosureRequirement) {
        expandInputParameter(disclosureRequirementOrInputParameterId);
      }

      setTimeout(
        () => {
          // Find Element and scroll to
          const disclosureOrInputParameterCardElement = document.getElementById(
            disclosureRequirementOrInputParameterId,
          );
          if (disclosureOrInputParameterCardElement) {
            // If disclosure requirement, scroll to element directly
            if (isDisclosureRequirement) {
              disclosureOrInputParameterCardElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            } else {
              window.scrollTo({
                top:
                  disclosureOrInputParameterCardElement.getBoundingClientRect().y +
                  window.scrollY -
                  72,
                behavior: "smooth",
              });
            }
          } else {
            // If not found, update the state
            setViewedDisclosureRequirementId(disclosureRequirement.id);
          }
        },
        isDisclosureRequirement ? 0 : 500,
      );
    },
    // This is wanted, since this should only be triggered when the viewedSection actually changes (not the object)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandDisclosureRequirement, expandInputParameter, viewedSection?.id],
  );

  const handleChangeDisclosureRequirementInToC = useCallback(
    (
      _: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
      disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
    ) => handleChangeViewedDisclosureRequirementOrInputParameter(disclosureRequirement.id),
    [handleChangeViewedDisclosureRequirementOrInputParameter],
  );

  // Update Scroll Position on hash change
  useEffect(() => {
    if (location.hash) {
      const disclosureRequirementOrInputParameterId = location.hash.slice(1, 37);
      void handleChangeViewedDisclosureRequirementOrInputParameter(
        disclosureRequirementOrInputParameterId,
      );
    }
  }, [handleChangeViewedDisclosureRequirementOrInputParameter, location.hash]);

  // Find the currently viewed Disclosure Requirement
  const viewedDisclosureRequirement = useMemo(
    () =>
      viewedSection?.disclosureRequirements.find((dr) => dr.id === viewedDisclosureRequirementId) ??
      null,
    [viewedDisclosureRequirementId, viewedSection],
  );

  // Scroll Position Tracking (ToC selected Item)

  const handleScrollPositionChange = useMemo(() => {
    const headerIds = viewedSection?.disclosureRequirements.map((dr) => dr.id) ?? [];
    return () => {
      const disclosureCardElements = headerIds.map((id) => document.getElementById(id));
      const disclosureCardElementsBoundingRects = disclosureCardElements
        .map((el) => el?.getBoundingClientRect())
        .reverse();

      const disclosureCardElementIndex = disclosureCardElementsBoundingRects.findIndex(
        (rect) => rect && rect.top < 56,
      );
      // -1 to get the previous element (currently scrolling inside of this element)
      const activeDisclosureElementIndex =
        disclosureCardElementIndex === -1
          ? 0 // Use first Element if no element is active
          : disclosureCardElementsBoundingRects.length - 1 - disclosureCardElementIndex;
      // Update the viewed disclosure requirement
      setViewedDisclosureRequirementId(
        viewedSection?.disclosureRequirements[activeDisclosureElementIndex]?.id ?? null,
      );
    };
  }, [viewedSection]);
  useScrollPosition(16, handleScrollPositionChange);
  // Trigger on hash change
  useEffect(() => {
    handleScrollPositionChange();
  }, [handleScrollPositionChange, location.hash]);

  // Mutations and similar
  const {
    handleUpdateDisclosureRequirementContributingUserIds,
    handleUpdateInputParameterContributingUserIds,
    handleUpdateDisclosureRequirementResponsibleUserId,
    handleUpdateInputParameterResponsibleUserId,
    handleCreateDisclosureRequirementValue,
    handleUpdateDisclosureRequirementValue,
    handleDeleteDisclosureRequirementValue,
    handleCreateInputParameterValue,
    handleUpdateInputParameterValue,
    handleDeleteInputParameterValue,
    handleUpdateTableValue,
    handleSubmitDisclosureRequirement,
    handleApproveDisclosureRequirement,
    handleRejectDisclosureRequirement,
    handleSubmitInputParameter,
    handleApproveInputParameter,
    handleRejectInputParameter,
    isLoading,
  } = useDeoEsrsApiActions(organization, recordingPeriod, recordingStructure, dataEntryObject);

  const deoEsrsInputContextValue: IDeoEsrsInputContextData = useMemo(
    () => ({
      // Common Data
      organization,
      recordingPeriod,
      organizationStructure,
      dataEntryObject,
      recordingStructureId: recordingStructure.id,
      availableDistributionCriteria,
      conditionalDisplayInputParametersLookup,
      // Handlers
      handleDisclosureRequirementContributingUserIdsChange:
        handleUpdateDisclosureRequirementContributingUserIds,
      handleInputParameterContributingUserIdsChange: handleUpdateInputParameterContributingUserIds,
      handleDisclosureRequirementResponsibleUsersChange:
        handleUpdateDisclosureRequirementResponsibleUserId,
      handleInputParameterResponsibleUsersChange: handleUpdateInputParameterResponsibleUserId,
      handleCreateDrValue: handleCreateDisclosureRequirementValue,
      handleUpdateDrValue: handleUpdateDisclosureRequirementValue,
      handleDeleteDrValue: handleDeleteDisclosureRequirementValue,
      handleCreateIPValue: handleCreateInputParameterValue,
      handleUpdateIPValue: handleUpdateInputParameterValue,
      handleDeleteIPValue: handleDeleteInputParameterValue,
      handleSubmitDisclosureRequirement: handleSubmitDisclosureRequirement,
      handleApproveDisclosureRequirement: handleApproveDisclosureRequirement,
      handleRejectDisclosureRequirement: handleRejectDisclosureRequirement,
      handleUpdateTableValue,
      handleSubmitInputParameter: handleSubmitInputParameter,
      handleApproveInputParameter: handleApproveInputParameter,
      handleRejectInputParameter: handleRejectInputParameter,
      createDisclosureRequirementUrl: (dr) =>
        viewedSection ? createDisclosureRequirementUrl(viewedSection, dr) : "",
      createInputParameterUrl: (ip) =>
        viewedSection ? createInputParameterUrl(viewedSection, ip) : "",
      // State
      expandedDisclosureRequirementIds,
      setExpandedDisclosureRequirementIds,
      expandedInputParameterIds,
      setExpandedInputParameterIds,
      // State
      isLoading,
    }),
    [
      organization,
      recordingPeriod,
      organizationStructure,
      dataEntryObject,
      recordingStructure.id,
      availableDistributionCriteria,
      conditionalDisplayInputParametersLookup,
      handleUpdateDisclosureRequirementContributingUserIds,
      handleUpdateInputParameterContributingUserIds,
      handleUpdateDisclosureRequirementResponsibleUserId,
      handleUpdateInputParameterResponsibleUserId,
      handleCreateDisclosureRequirementValue,
      handleUpdateDisclosureRequirementValue,
      handleDeleteDisclosureRequirementValue,
      handleCreateInputParameterValue,
      handleUpdateInputParameterValue,
      handleDeleteInputParameterValue,
      handleSubmitDisclosureRequirement,
      handleApproveDisclosureRequirement,
      handleRejectDisclosureRequirement,
      handleUpdateTableValue,
      handleSubmitInputParameter,
      handleApproveInputParameter,
      handleRejectInputParameter,
      expandedDisclosureRequirementIds,
      expandedInputParameterIds,
      isLoading,
      viewedSection,
    ],
  );

  return (
    <DeoEsrsInputContextProvider value={deoEsrsInputContextValue}>
      <Box display="flex" flexDirection="column" gap={2} mx="auto" maxWidth={1800}>
        {/* Title */}
        <Typography variant="h4" component="h1">
          {translateContent(recordingStructure.name)}
        </Typography>
        {/* Filter Bar */}
        <DeoEsrsFilterBarComponent
          organizationStructure={organizationStructure}
          dataEntryObject={dataEntryObject}
          onChangeDataEntryObject={onChangeDataEntryObject}
        />
        {/* Main Content */}
        <Grid container spacing={4}>
          {/* Table of Contents */}
          <Grid item xs={3}>
            <Box position="sticky" maxHeight="100dvh" style={{ overflowY: "auto" }} top={0} py={4}>
              <DeoEsrsTableOfContents
                esrsStructure={completeHydratedStructureGroup}
                activeDisclosureRequirementId={viewedDisclosureRequirement?.id ?? null}
                generateDisclosureRequirementUrl={createDisclosureRequirementUrl}
                onChangeDisclosureRequirement={handleChangeDisclosureRequirementInToC}
              />
            </Box>
          </Grid>
          {/* DRs (main Content) */}
          {viewedSection && (
            <Fade key={viewedSection.id} in appear>
              <Grid item xs={9} pb={10}>
                <DeoEsrsValuesOverviewContent
                  section={viewedSection}
                  onPreviousSection={handleChangeSection(previousSection)}
                  onNextSection={handleChangeSection(nextSection)}
                />
              </Grid>
            </Fade>
          )}
        </Grid>
      </Box>
    </DeoEsrsInputContextProvider>
  );
};
