/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IBaseReferenceBudgetData } from '../models';
// @ts-ignore
import { ICreateReferenceBudget400Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget404Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget409Response } from '../models';
// @ts-ignore
import { ICreateReferenceBudget500Response } from '../models';
// @ts-ignore
import { IDeleteReferenceBudget404Response } from '../models';
// @ts-ignore
import { IDeleteReferenceBudget409Response } from '../models';
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from '../models';
// @ts-ignore
import { IGetAllReferenceBudgetsResponse } from '../models';
// @ts-ignore
import { IReferenceBudget } from '../models';
// @ts-ignore
import { IUpdateReferenceBudget404Response } from '../models';
// @ts-ignore
import { IUpdateReferenceBudget409Response } from '../models';
// @ts-ignore
import { IUpdateReferenceBudgetRequest } from '../models';
/**
 * ReferenceBudgetsApi - axios parameter creator
 * @export
 */
export const ReferenceBudgetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint creates a new reference budget with the data from the provided recording periods.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseReferenceBudgetData} [iBaseReferenceBudgetData] Create Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferenceBudget: async (organizationId: string, iBaseReferenceBudgetData?: IBaseReferenceBudgetData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createReferenceBudget', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/reference-budgets`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseReferenceBudgetData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint deletes a given reference budget.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} referenceBudgetId The id of the reference budget to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferenceBudget: async (organizationId: string, referenceBudgetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteReferenceBudget', 'organizationId', organizationId)
            // verify required parameter 'referenceBudgetId' is not null or undefined
            assertParamExists('deleteReferenceBudget', 'referenceBudgetId', referenceBudgetId)
            const localVarPath = `/organizations/{organizationId}/reference-budgets/{referenceBudgetId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"referenceBudgetId"}}`, encodeURIComponent(String(referenceBudgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves all reference budgets for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferenceBudgetsForOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getReferenceBudgetsForOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/reference-budgets`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint updates the reference budget with the given ID. If the recording periods are not specified, they will not be updated.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} referenceBudgetId The id of the reference budget to use.
         * @param {IUpdateReferenceBudgetRequest} [iUpdateReferenceBudgetRequest] Update Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferenceBudget: async (organizationId: string, referenceBudgetId: string, iUpdateReferenceBudgetRequest?: IUpdateReferenceBudgetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateReferenceBudget', 'organizationId', organizationId)
            // verify required parameter 'referenceBudgetId' is not null or undefined
            assertParamExists('updateReferenceBudget', 'referenceBudgetId', referenceBudgetId)
            const localVarPath = `/organizations/{organizationId}/reference-budgets/{referenceBudgetId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"referenceBudgetId"}}`, encodeURIComponent(String(referenceBudgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUpdateReferenceBudgetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceBudgetsApi - functional programming interface
 * @export
 */
export const ReferenceBudgetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceBudgetsApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint creates a new reference budget with the data from the provided recording periods.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseReferenceBudgetData} [iBaseReferenceBudgetData] Create Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReferenceBudget(organizationId: string, iBaseReferenceBudgetData?: IBaseReferenceBudgetData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReferenceBudget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReferenceBudget(organizationId, iBaseReferenceBudgetData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint deletes a given reference budget.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} referenceBudgetId The id of the reference budget to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReferenceBudget(organizationId: string, referenceBudgetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReferenceBudget(organizationId, referenceBudgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint retrieves all reference budgets for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferenceBudgetsForOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllReferenceBudgetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferenceBudgetsForOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint updates the reference budget with the given ID. If the recording periods are not specified, they will not be updated.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} referenceBudgetId The id of the reference budget to use.
         * @param {IUpdateReferenceBudgetRequest} [iUpdateReferenceBudgetRequest] Update Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferenceBudget(organizationId: string, referenceBudgetId: string, iUpdateReferenceBudgetRequest?: IUpdateReferenceBudgetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReferenceBudget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferenceBudget(organizationId, referenceBudgetId, iUpdateReferenceBudgetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferenceBudgetsApi - factory interface
 * @export
 */
export const ReferenceBudgetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceBudgetsApiFp(configuration)
    return {
        /**
         * This endpoint creates a new reference budget with the data from the provided recording periods.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseReferenceBudgetData} [iBaseReferenceBudgetData] Create Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferenceBudget(organizationId: string, iBaseReferenceBudgetData?: IBaseReferenceBudgetData, options?: any): AxiosPromise<IReferenceBudget> {
            return localVarFp.createReferenceBudget(organizationId, iBaseReferenceBudgetData, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint deletes a given reference budget.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} referenceBudgetId The id of the reference budget to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferenceBudget(organizationId: string, referenceBudgetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReferenceBudget(organizationId, referenceBudgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves all reference budgets for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferenceBudgetsForOrganization(organizationId: string, options?: any): AxiosPromise<IGetAllReferenceBudgetsResponse> {
            return localVarFp.getReferenceBudgetsForOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint updates the reference budget with the given ID. If the recording periods are not specified, they will not be updated.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} referenceBudgetId The id of the reference budget to use.
         * @param {IUpdateReferenceBudgetRequest} [iUpdateReferenceBudgetRequest] Update Reference Budget Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferenceBudget(organizationId: string, referenceBudgetId: string, iUpdateReferenceBudgetRequest?: IUpdateReferenceBudgetRequest, options?: any): AxiosPromise<IReferenceBudget> {
            return localVarFp.updateReferenceBudget(organizationId, referenceBudgetId, iUpdateReferenceBudgetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceBudgetsApi - interface
 * @export
 * @interface ReferenceBudgetsApi
 */
export interface ReferenceBudgetsApiInterface {
    /**
     * This endpoint creates a new reference budget with the data from the provided recording periods.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseReferenceBudgetData} [iBaseReferenceBudgetData] Create Reference Budget Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApiInterface
     */
    createReferenceBudget(organizationId: string, iBaseReferenceBudgetData?: IBaseReferenceBudgetData, options?: AxiosRequestConfig): AxiosPromise<IReferenceBudget>;

    /**
     * This endpoint deletes a given reference budget.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} referenceBudgetId The id of the reference budget to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApiInterface
     */
    deleteReferenceBudget(organizationId: string, referenceBudgetId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * This endpoint retrieves all reference budgets for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApiInterface
     */
    getReferenceBudgetsForOrganization(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<IGetAllReferenceBudgetsResponse>;

    /**
     * This endpoint updates the reference budget with the given ID. If the recording periods are not specified, they will not be updated.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} referenceBudgetId The id of the reference budget to use.
     * @param {IUpdateReferenceBudgetRequest} [iUpdateReferenceBudgetRequest] Update Reference Budget Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApiInterface
     */
    updateReferenceBudget(organizationId: string, referenceBudgetId: string, iUpdateReferenceBudgetRequest?: IUpdateReferenceBudgetRequest, options?: AxiosRequestConfig): AxiosPromise<IReferenceBudget>;

}

/**
 * ReferenceBudgetsApi - object-oriented interface
 * @export
 * @class ReferenceBudgetsApi
 * @extends {BaseAPI}
 */
export class ReferenceBudgetsApi extends BaseAPI implements ReferenceBudgetsApiInterface {
    /**
     * This endpoint creates a new reference budget with the data from the provided recording periods.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseReferenceBudgetData} [iBaseReferenceBudgetData] Create Reference Budget Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApi
     */
    public createReferenceBudget(organizationId: string, iBaseReferenceBudgetData?: IBaseReferenceBudgetData, options?: AxiosRequestConfig) {
        return ReferenceBudgetsApiFp(this.configuration).createReferenceBudget(organizationId, iBaseReferenceBudgetData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint deletes a given reference budget.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} referenceBudgetId The id of the reference budget to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApi
     */
    public deleteReferenceBudget(organizationId: string, referenceBudgetId: string, options?: AxiosRequestConfig) {
        return ReferenceBudgetsApiFp(this.configuration).deleteReferenceBudget(organizationId, referenceBudgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves all reference budgets for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApi
     */
    public getReferenceBudgetsForOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return ReferenceBudgetsApiFp(this.configuration).getReferenceBudgetsForOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint updates the reference budget with the given ID. If the recording periods are not specified, they will not be updated.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} referenceBudgetId The id of the reference budget to use.
     * @param {IUpdateReferenceBudgetRequest} [iUpdateReferenceBudgetRequest] Update Reference Budget Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceBudgetsApi
     */
    public updateReferenceBudget(organizationId: string, referenceBudgetId: string, iUpdateReferenceBudgetRequest?: IUpdateReferenceBudgetRequest, options?: AxiosRequestConfig) {
        return ReferenceBudgetsApiFp(this.configuration).updateReferenceBudget(organizationId, referenceBudgetId, iUpdateReferenceBudgetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
