/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ICreateRecordingPeriod409Response } from '../models';
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from '../models';
// @ts-ignore
import { IGetAllRecordingPeriodsResponse } from '../models';
// @ts-ignore
import { IListDistributionCriteria404Response } from '../models';
// @ts-ignore
import { IRecordingPeriod } from '../models';
// @ts-ignore
import { IRecordingPeriodData } from '../models';
// @ts-ignore
import { IUpdateRecordingPeriod409Response } from '../models';
/**
 * RecordingPeriodApi - axios parameter creator
 * @export
 */
export const RecordingPeriodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new recording period for an organization.
         * @summary Create a new recording period for an organization.
         * @param {string} organizationId The id of the organization to create the recording period for.
         * @param {IRecordingPeriodData} [iRecordingPeriodData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecordingPeriod: async (organizationId: string, iRecordingPeriodData?: IRecordingPeriodData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createRecordingPeriod', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/recording-periods`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRecordingPeriodData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all recording periods for an organization.
         * @summary Get all recording periods for an organization.
         * @param {string} organizationId The id of the organization to get the recording periods for.
         * @param {boolean} includeRecordingStructures Whether to also fetch recording structures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecordingPeriods: async (organizationId: string, includeRecordingStructures: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getAllRecordingPeriods', 'organizationId', organizationId)
            // verify required parameter 'includeRecordingStructures' is not null or undefined
            assertParamExists('getAllRecordingPeriods', 'includeRecordingStructures', includeRecordingStructures)
            const localVarPath = `/organizations/{organizationId}/recording-periods`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required

            if (includeRecordingStructures !== undefined) {
                localVarQueryParameter['includeRecordingStructures'] = includeRecordingStructures;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a recording period.
         * @summary Update a recording period.
         * @param {string} organizationId The id of the organization to update the recording period for.
         * @param {string} recordingPeriodId The id of the recording period to update.
         * @param {IRecordingPeriodData} [iRecordingPeriodData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecordingPeriod: async (organizationId: string, recordingPeriodId: string, iRecordingPeriodData?: IRecordingPeriodData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateRecordingPeriod', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateRecordingPeriod', 'recordingPeriodId', recordingPeriodId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRecordingPeriodData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecordingPeriodApi - functional programming interface
 * @export
 */
export const RecordingPeriodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecordingPeriodApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new recording period for an organization.
         * @summary Create a new recording period for an organization.
         * @param {string} organizationId The id of the organization to create the recording period for.
         * @param {IRecordingPeriodData} [iRecordingPeriodData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecordingPeriod(organizationId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRecordingPeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecordingPeriod(organizationId, iRecordingPeriodData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all recording periods for an organization.
         * @summary Get all recording periods for an organization.
         * @param {string} organizationId The id of the organization to get the recording periods for.
         * @param {boolean} includeRecordingStructures Whether to also fetch recording structures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRecordingPeriods(organizationId: string, includeRecordingStructures: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllRecordingPeriodsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRecordingPeriods(organizationId, includeRecordingStructures, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a recording period.
         * @summary Update a recording period.
         * @param {string} organizationId The id of the organization to update the recording period for.
         * @param {string} recordingPeriodId The id of the recording period to update.
         * @param {IRecordingPeriodData} [iRecordingPeriodData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecordingPeriod(organizationId: string, recordingPeriodId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRecordingPeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecordingPeriod(organizationId, recordingPeriodId, iRecordingPeriodData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecordingPeriodApi - factory interface
 * @export
 */
export const RecordingPeriodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecordingPeriodApiFp(configuration)
    return {
        /**
         * Creates a new recording period for an organization.
         * @summary Create a new recording period for an organization.
         * @param {string} organizationId The id of the organization to create the recording period for.
         * @param {IRecordingPeriodData} [iRecordingPeriodData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecordingPeriod(organizationId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: any): AxiosPromise<IRecordingPeriod> {
            return localVarFp.createRecordingPeriod(organizationId, iRecordingPeriodData, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all recording periods for an organization.
         * @summary Get all recording periods for an organization.
         * @param {string} organizationId The id of the organization to get the recording periods for.
         * @param {boolean} includeRecordingStructures Whether to also fetch recording structures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecordingPeriods(organizationId: string, includeRecordingStructures: boolean, options?: any): AxiosPromise<IGetAllRecordingPeriodsResponse> {
            return localVarFp.getAllRecordingPeriods(organizationId, includeRecordingStructures, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a recording period.
         * @summary Update a recording period.
         * @param {string} organizationId The id of the organization to update the recording period for.
         * @param {string} recordingPeriodId The id of the recording period to update.
         * @param {IRecordingPeriodData} [iRecordingPeriodData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecordingPeriod(organizationId: string, recordingPeriodId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: any): AxiosPromise<IRecordingPeriod> {
            return localVarFp.updateRecordingPeriod(organizationId, recordingPeriodId, iRecordingPeriodData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecordingPeriodApi - interface
 * @export
 * @interface RecordingPeriodApi
 */
export interface RecordingPeriodApiInterface {
    /**
     * Creates a new recording period for an organization.
     * @summary Create a new recording period for an organization.
     * @param {string} organizationId The id of the organization to create the recording period for.
     * @param {IRecordingPeriodData} [iRecordingPeriodData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodApiInterface
     */
    createRecordingPeriod(organizationId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: AxiosRequestConfig): AxiosPromise<IRecordingPeriod>;

    /**
     * Returns all recording periods for an organization.
     * @summary Get all recording periods for an organization.
     * @param {string} organizationId The id of the organization to get the recording periods for.
     * @param {boolean} includeRecordingStructures Whether to also fetch recording structures
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodApiInterface
     */
    getAllRecordingPeriods(organizationId: string, includeRecordingStructures: boolean, options?: AxiosRequestConfig): AxiosPromise<IGetAllRecordingPeriodsResponse>;

    /**
     * Updates a recording period.
     * @summary Update a recording period.
     * @param {string} organizationId The id of the organization to update the recording period for.
     * @param {string} recordingPeriodId The id of the recording period to update.
     * @param {IRecordingPeriodData} [iRecordingPeriodData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodApiInterface
     */
    updateRecordingPeriod(organizationId: string, recordingPeriodId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: AxiosRequestConfig): AxiosPromise<IRecordingPeriod>;

}

/**
 * RecordingPeriodApi - object-oriented interface
 * @export
 * @class RecordingPeriodApi
 * @extends {BaseAPI}
 */
export class RecordingPeriodApi extends BaseAPI implements RecordingPeriodApiInterface {
    /**
     * Creates a new recording period for an organization.
     * @summary Create a new recording period for an organization.
     * @param {string} organizationId The id of the organization to create the recording period for.
     * @param {IRecordingPeriodData} [iRecordingPeriodData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodApi
     */
    public createRecordingPeriod(organizationId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: AxiosRequestConfig) {
        return RecordingPeriodApiFp(this.configuration).createRecordingPeriod(organizationId, iRecordingPeriodData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all recording periods for an organization.
     * @summary Get all recording periods for an organization.
     * @param {string} organizationId The id of the organization to get the recording periods for.
     * @param {boolean} includeRecordingStructures Whether to also fetch recording structures
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodApi
     */
    public getAllRecordingPeriods(organizationId: string, includeRecordingStructures: boolean, options?: AxiosRequestConfig) {
        return RecordingPeriodApiFp(this.configuration).getAllRecordingPeriods(organizationId, includeRecordingStructures, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a recording period.
     * @summary Update a recording period.
     * @param {string} organizationId The id of the organization to update the recording period for.
     * @param {string} recordingPeriodId The id of the recording period to update.
     * @param {IRecordingPeriodData} [iRecordingPeriodData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordingPeriodApi
     */
    public updateRecordingPeriod(organizationId: string, recordingPeriodId: string, iRecordingPeriodData?: IRecordingPeriodData, options?: AxiosRequestConfig) {
        return RecordingPeriodApiFp(this.configuration).updateRecordingPeriod(organizationId, recordingPeriodId, iRecordingPeriodData, options).then((request) => request(this.axios, this.basePath));
    }
}
