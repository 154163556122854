import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { FC, ReactNode } from "react";
import { Breakpoint } from "@mui/system";

export interface IOptionsDialogOption {
  label: string;
  onClick: () => void;
  buttonProps?: ButtonProps;
}

export interface IOptionsDialogProps {
  open: boolean;
  title?: string;
  content: ReactNode;
  options: IOptionsDialogOption[];
  loading?: boolean;
  onClose?: () => void;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
}

export const OptionsDialog: FC<IOptionsDialogProps> = ({
  open,
  title,
  content,
  options,
  loading,
  onClose,
  maxWidth,
  fullWidth,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {loading && <LinearProgress />}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {options.map((option) => (
          <Button key={option.label} onClick={option.onClick} {...option.buttonProps}>
            {option.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
