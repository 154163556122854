import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { IOrganizationStructureDetailed } from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CheckIcon,
  IconSize,
  InfoIcon,
  WarningIcon,
} from "../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../common/utilities/format.utilities";
import { CoreApiService } from "../core-api/core-api.service";
import { ILocalOrganization } from "../organizations/local-organization.interface";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import { OrganizationStructureDraftCommitDialog } from "./organization-structure-draft-commit.dialog";
import { OrganizationStructureTreeEditor } from "./organization-structure-tree-editor.component";
import { getRecordingPeriodOrganizationStructureQueryKey } from "./organization-structures.queries";

interface IOrganizationStructureConfigurationComponentProps {
  organization: ILocalOrganization;
  recordingPeriods: ILocalRecordingPeriod[];
  selectedRecordingPeriod: ILocalRecordingPeriod;
  organizationStructure: IOrganizationStructureDetailed;
  onChangeRecordingPeriod: (recordingPeriod: ILocalRecordingPeriod) => void;
}

/**
 * Lists all organization structures and allows the user to select one to edit it.
 */
export const OrganizationStructureConfigurationComponent: FC<
  IOrganizationStructureConfigurationComponentProps
> = ({
  organization,
  recordingPeriods,
  selectedRecordingPeriod,
  organizationStructure,
  onChangeRecordingPeriod,
}) => {
  const { t } = useTranslation("organization_structure_configuration_component");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showCommitDraftDialog, setShowCommitDraftDialog] = useState(false);

  const commitDraftMutation = useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
    }: {
      organizationId: string;
      recordingPeriodId: string;
    }) => {
      return CoreApiService.OrganizationStructureApi.commitOrganizationStructureDraftForRecordingPeriod(
        organizationId,
        recordingPeriodId,
      ).then((req) => req());
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getRecordingPeriodOrganizationStructureQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });

  const handleCommitDraft = () => {
    commitDraftMutation.reset();
    setShowCommitDraftDialog(true);
  };

  const handleConfirmCommitDraft = async (commit: true | null) => {
    if (commit) {
      await commitDraftMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: selectedRecordingPeriod.id,
      });
      // Redirect to Recording Period Dashboard
      navigate(`/organizations/${organization.id}/recording-periods/${selectedRecordingPeriod.id}`);
    }
    setShowCommitDraftDialog(false);
  };

  const handleSelectRecordingPeriod = (event: SelectChangeEvent<string>) => {
    const newId = event.target.value;
    const newRecordingPeriod = recordingPeriods.find((rp) => rp.id === newId);
    onChangeRecordingPeriod(newRecordingPeriod!);
  };

  return (
    <>
      {/* Draft Modals */}
      <OrganizationStructureDraftCommitDialog
        open={showCommitDraftDialog}
        loading={commitDraftMutation.isPending}
        error={commitDraftMutation.isError ? commitDraftMutation.error : undefined}
        onClose={handleConfirmCommitDraft}
        disabled={commitDraftMutation.isPending}
      />

      {/* Content */}
      <Box>
        {/* Organization Structures List */}
        <Card>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="end"
              flexWrap={{ xs: "wrap", lg: "nowrap" }}
              gap={2}
            >
              {/* Structure Select */}
              <FormControl fullWidth>
                <InputLabel id="recording-period-select-label">
                  {t("recording_period_select_label")}
                </InputLabel>
                <Select
                  labelId="recording-period-select-label"
                  id="recording-period-select"
                  value={selectedRecordingPeriod.id}
                  label={t("recording_period_select_label")}
                  onChange={handleSelectRecordingPeriod}
                >
                  {recordingPeriods.map((recordingPeriod) => (
                    <MenuItem key={recordingPeriod.id} value={recordingPeriod.id}>
                      <Box display="flex" gap={1}>
                        <span>{FormatUtilities.formatDate(recordingPeriod.startDate)}</span>
                        <span>-</span>
                        <span>{FormatUtilities.formatDate(recordingPeriod.endDate)}</span>
                        <Box>
                          <Divider orientation="vertical" />
                        </Box>
                        <Box display="flex" gap={0.5}>
                          <Tooltip title={recordingPeriod.description} placement="right">
                            <span>{recordingPeriod.name}</span>
                          </Tooltip>
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {organizationStructure.isDraft && (
                <Tooltip title={t("organization_structure_commit_draft_button_tooltip")}>
                  <Button variant="contained" onClick={handleCommitDraft}>
                    <SvgIcon>
                      <CheckIcon size={IconSize.Medium} />
                    </SvgIcon>
                  </Button>
                </Tooltip>
              )}
            </Box>

            {/* Draft Notice */}
            {organizationStructure.isDraft && (
              <Box display="flex" mt={2}>
                <SvgIcon color="warning">
                  <WarningIcon size={IconSize.Medium} />
                </SvgIcon>
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {t("organization_structure_is_draft_notice")}
                </Typography>
              </Box>
            )}

            {/* Description */}
            {selectedRecordingPeriod.description !== undefined && (
              <>
                <Divider sx={{ my: 2, mx: -3 }} />
                <Box display="flex" gap={1}>
                  <SvgIcon color="info">
                    <InfoIcon />
                  </SvgIcon>
                  <Typography variant="body1">{selectedRecordingPeriod.description}</Typography>
                </Box>
              </>
            )}
          </CardContent>
        </Card>

        {/* Structure Editor/Viewer */}
        <Box mt={2}>
          <OrganizationStructureTreeEditor
            key={organizationStructure.id}
            organization={organization}
            recordingPeriod={selectedRecordingPeriod}
            organizationStructure={organizationStructure}
          />
        </Box>
      </Box>
    </>
  );
};
