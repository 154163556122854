import { Avatar, Box, Chip, ChipProps, Skeleton, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useSearchUsersQuery } from "../users.queries";
import { useKeycloakOrganizationId } from "../hooks/use-keycloak-organization-id.hook";
import { UserUtilities } from "@netcero/netcero-common";

interface IOrganizationUserChipProps extends ChipProps {
  organizationId: string;
  userId: string;
}

export const OrganizationUserChip: FC<IOrganizationUserChipProps> = ({
  organizationId,
  userId,
  ...props
}) => {
  const keycloakOrganizationId = useKeycloakOrganizationId(organizationId);

  const usersQuery = useSearchUsersQuery(keycloakOrganizationId);

  const user = useMemo(
    () => usersQuery.data?.find((user) => user.id === userId),
    [usersQuery.data, userId],
  );

  if (usersQuery.isLoading) {
    return <Skeleton variant="rounded" width={120} height={28} sx={{ borderRadius: 16 }} />;
  }

  if (usersQuery.isError) {
    return <Typography color="error">Error while fetching</Typography>;
  }

  return (
    <Chip
      label={`${user?.firstName} ${user?.lastName}`}
      avatar={
        // Not using user-avatar component here since the Chip applies styles to the avatar that would otherwise break
        <Avatar sx={{ bgcolor: user ? UserUtilities.getColorByUser(user) : "primary.main" }}>
          <Box color="primary.contrastText">
            {user?.firstName?.[0]}
            {user?.lastName?.[0]}
          </Box>
        </Avatar>
      }
      {...props}
    />
  );
};
