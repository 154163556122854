import { Box, FormControlLabel, Switch, TextField, Tooltip } from "@mui/material";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { IMDRBooleanInput, IMDREnumInput, IMDRInput, IMDRTextInput } from "../mdr-input.types";
import { ReactElement, useMemo } from "react";
import { UseFormStateReturn } from "react-hook-form/dist/types";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { HierarchicalDropdownEnum } from "./hierarchical-dropdown.component";

export interface IMDRControlInputProps<T extends FieldValues> extends IMDRInputProps {
  propertyPath: Path<T>;
  control: Control<T>;
  getRuleTranslation: (property: Path<T>, rule: "required") => string;
}

export interface IMDRInputProps {
  label: string;
  isDependedUponByAnother: boolean;
  isConditional: boolean;
  disabled: boolean;
}

export interface IControlRenderProps<T extends FieldValues> {
  field: ControllerRenderProps<T, Path<T>>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
}

export type IMDRControlledInputProps<
  DataType extends FieldValues,
  InputMetaDataType,
> = IMDRInputProps &
  IControlRenderProps<DataType> & {
    valueMetaData: InputMetaDataType;
  };

function TextInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
  disabled,
}: IMDRControlledInputProps<T, IMDRTextInput>) {
  const multilineProps = useMemo(
    () =>
      valueMetaData.multiline
        ? {
            multiline: true,
            minRows: 3,
            maxRows: 5,
          }
        : {},
    [valueMetaData.multiline],
  );

  return (
    <>
      {/*{valueMetaData.isParent && <Typography fontWeight="bold">{label}</Typography>}*/}
      <Box pl={valueMetaData.isChild || isConditional ? 6 : 0} mb={isConditional ? 2 : 0}>
        <TextField
          {...field}
          label={
            <Tooltip title={label}>
              <span style={{ fontWeight: valueMetaData.isParent ? "bold" : undefined }}>
                {label}
              </span>
            </Tooltip>
          }
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error ? error.message : null}
          disabled={disabled}
          {...multilineProps}
        />
      </Box>
      {valueMetaData.isLastChild && <Box />}
    </>
  );
}

function BooleanInput<T extends FieldValues>({
  label,
  field: { onChange, value, ref },
  disabled,
}: IMDRControlledInputProps<T, IMDRBooleanInput>) {
  return (
    <Box>
      <FormControlLabel
        control={<Switch checked={value} onChange={onChange} ref={ref} disabled={disabled} />}
        label={label}
      />
    </Box>
  );
}

function EnumInput<T extends FieldValues>({
  label,
  field: { onChange, value },
  valueMetaData,
  translateEnumValue,
  disabled,
}: IMDRControlledInputProps<T, IMDREnumInput> & {
  translateEnumValue: (value: string) => string;
}) {
  return (
    <HierarchicalDropdownEnum
      label={label}
      translateValueLabel={translateEnumValue}
      values={valueMetaData.values}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export const MdrInputComponentFactory = {
  createComponent<T extends FieldValues>(
    valueMetaData: IMDRInput,
    { control, propertyPath, getRuleTranslation, ...props }: IMDRControlInputProps<T>,
    translateEnumValue: (value: string) => string,
  ) {
    const rules: Omit<
      RegisterOptions<T, Path<T>>,
      "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    > = {};

    // Required Rule
    if (valueMetaData.required) {
      if (valueMetaData.type === "boolean" || valueMetaData.type === "text") {
        rules.required = getRuleTranslation(propertyPath, "required");
      } else if (valueMetaData.type === "enum") {
        rules.validate = (value: string[]) => {
          return value.length > 0 || getRuleTranslation(propertyPath, "required");
        };
      }
    }

    return (
      <Controller
        control={control}
        name={propertyPath}
        rules={rules}
        render={MdrInputComponentFactory.createInputComponentForControl(
          valueMetaData,
          props,
          translateEnumValue,
        )}
      />
    );
  },
  createInputComponentForControl<T extends FieldValues>(
    valueMetaData: IMDRInput,
    props: IMDRInputProps,
    translateEnumValue: (value: string) => string,
  ) {
    return (fieldData: IControlRenderProps<T>): ReactElement => {
      switch (valueMetaData.type) {
        case "text":
          return TextInput<T>({ valueMetaData, ...props, ...fieldData });
        case "boolean":
          return BooleanInput<T>({ valueMetaData, ...props, ...fieldData });
        case "enum":
          return EnumInput<T>({ valueMetaData, ...props, ...fieldData, translateEnumValue });
      }
    };
  },
};
