import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import {
  IBaseManualStakeholderFeedbackData,
  IDMACategoryState,
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IESRSTopic,
  IManualStakeholderFeedback,
  IVerifyStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../../common/components/error-text.component";
import { useResetMutationsOnOpen } from "../../../common/hooks/use-reset-mutations-on-open.hook";
import { useStakeholdersQuery } from "../../../stakeholder/stakeholder.queries";
import { DMACategoryFeedbackUtilities } from "../../common/dma-category-feedback.utilities";
import {
  useCreateManualFeedbackMutation,
  useUpdateManualFeedbackMutation,
} from "../../mutations/dma-category-manual-feedback.mutations";
import { useVerifyDMACategoryStakeholderFeedback } from "../../mutations/dma-category.mutations";
import { DMACategoryManualFeedbackEditDialog } from "../manual-feedback/dma-category-manual-feedback-edit.dialog";
import { DMACategoryManualFeedbackList } from "../manual-feedback/dma-category-manual-feedback-list.component";
import { DMACategoryFeedbackList } from "./dma-category-feedback-list.component";
import { DMACategoryFeedbackRequestDialog } from "./dma-category-feedback-request.dialog";
import { DMACategoryFeedbackWarnings } from "./dma-category-feedback-warnings.component";
import { DMACategoryValidationButtons } from "./dma-category-validation-buttons.component";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";

interface IManualFeedbackDialogState {
  open: boolean;
  manualFeedback?: IManualStakeholderFeedback | null;
}

interface IDMACategoryFeedbackDialogProps {
  open: boolean;
  esrsTopic: IESRSTopic | null;
  dmaCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren | null;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  esrsTopicId: string;
  onClose: () => void;
}

export const DMACategoryFeedbackDialog: FC<IDMACategoryFeedbackDialogProps> = ({
  open,
  dmaCategory,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsTopicId,
  onClose,
}) => {
  const { t } = useTranslation("dma_category_stakeholder_feedback_dialog");

  const stakeholdersQuery = useStakeholdersQuery(organizationId);

  const groupedFeedbackEntries = useMemo(() => {
    return DMACategoryFeedbackUtilities.getOrderedStakeholderFeedbackEntriesArray(
      stakeholdersQuery.data?.stakeholders ?? [],
      dmaCategory?.feedback ?? [],
    );
  }, [stakeholdersQuery.data, dmaCategory?.feedback]);

  const manualFeedbackEntries = dmaCategory?.manualFeedbacks ?? [];

  // Feedback Request Dialog

  const [feedbackRequestDialogOpen, setFeedbackRequestDialogOpen] = useState(false);

  const handleOpenRequestFeedbackDialog = () => {
    setFeedbackRequestDialogOpen(true);
  };

  const handleCloseRequestFeedbackDialog = () => {
    setFeedbackRequestDialogOpen(false);
  };

  // Manual Feedback Dialog

  const [editManualFeedbackDialogState, setEditManualFeedbackState] =
    useState<IManualFeedbackDialogState>({
      open: false,
      manualFeedback: null,
    });

  const handleAddManualFeedback = () => {
    createManualFeedbackMutation.reset();
    updateManualFeedbackMutation.reset();
    setEditManualFeedbackState({ open: true, manualFeedback: null });
  };

  const handleEditManualFeedback = (manualFeedback: IManualStakeholderFeedback) => {
    createManualFeedbackMutation.reset();
    updateManualFeedbackMutation.reset();
    setEditManualFeedbackState({ open: true, manualFeedback });
  };

  const createManualFeedbackMutation = useCreateManualFeedbackMutation();
  const updateManualFeedbackMutation = useUpdateManualFeedbackMutation();

  const handleCloseEditStakeholderFeedbackDialog = async (
    data: IBaseManualStakeholderFeedbackData | null,
  ) => {
    if (data) {
      if (editManualFeedbackDialogState.manualFeedback) {
        // Update existing feedback
        await updateManualFeedbackMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId: dmaCategory?.id ?? "",
          feedbackId: editManualFeedbackDialogState.manualFeedback.id,
          payload: data,
        });
      } else {
        // Add new feedback
        await createManualFeedbackMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId: dmaCategory?.id ?? "",
          payload: data,
        });
      }
    }
    setEditManualFeedbackState((state) => ({ ...state, open: false }));
  };

  // Verify Feedback

  const verifyStakeholderFeedbackMutation = useVerifyDMACategoryStakeholderFeedback();

  const handleVerifyFeedback = async (payload: IVerifyStakeholderFeedbackRequest) => {
    await verifyStakeholderFeedbackMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId,
      dmaCategoryId: dmaCategory?.id ?? "",
      payload,
    });
  };

  useResetMutationsOnOpen(open, verifyStakeholderFeedbackMutation);

  // Render Component

  const disableFeedbackAction =
    dmaCategory?.financialState === IDMACategoryState.Verified &&
    dmaCategory?.materialState === IDMACategoryState.Verified;

  const isLoading = verifyStakeholderFeedbackMutation.isPending;
  const disabled = isLoading;

  const error = verifyStakeholderFeedbackMutation.isError
    ? verifyStakeholderFeedbackMutation.error
    : null;

  const renderName = useRenderDMACategoryName();

  return (
    <>
      {/* Modals */}
      <DMACategoryFeedbackRequestDialog
        open={feedbackRequestDialogOpen}
        dmaCategory={dmaCategory}
        onClose={handleCloseRequestFeedbackDialog}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        esrsTopicId={esrsTopicId}
      />

      <DMACategoryManualFeedbackEditDialog
        open={editManualFeedbackDialogState.open}
        manualFeedback={editManualFeedbackDialogState.manualFeedback}
        dmaCategory={dmaCategory}
        loading={createManualFeedbackMutation.isPending || updateManualFeedbackMutation.isPending}
        disabled={createManualFeedbackMutation.isPending || updateManualFeedbackMutation.isPending}
        error={
          createManualFeedbackMutation.isError
            ? createManualFeedbackMutation.error
            : updateManualFeedbackMutation.isError
            ? updateManualFeedbackMutation.error
            : null
        }
        organizationId={organizationId}
        onClose={handleCloseEditStakeholderFeedbackDialog}
      />

      {/* Actual Dialog */}
      <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="start">
            {t("title", { name: renderName(dmaCategory) })}
          </Box>
        </DialogTitle>
        {isLoading && <LinearProgress />}
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} pb={6}>
            {error && <ErrorTextComponent error={error} />}

            {/* Feedback Warnings */}
            {dmaCategory && <DMACategoryFeedbackWarnings dmaCategory={dmaCategory} />}

            {/* Validation Buttons */}
            {dmaCategory && (
              <DMACategoryValidationButtons
                dmaCategory={dmaCategory}
                disabled={disabled}
                onVerify={handleVerifyFeedback}
              />
            )}

            <Typography variant="h6" mt={6}>
              {t("header_feedback")}
            </Typography>

            {/* List Categories */}
            <DMACategoryFeedbackList
              feedbackEntries={groupedFeedbackEntries}
              recordingPeriodId={recordingPeriodId}
            />

            {/* Empty Notice */}
            {groupedFeedbackEntries.length < 1 && (
              <DialogContentText textAlign="center">{t("empty_notice")}</DialogContentText>
            )}

            {/* Request Feedback Button */}
            <Box ml="auto">
              <Button
                variant="contained"
                onClick={handleOpenRequestFeedbackDialog}
                disabled={disabled || disableFeedbackAction}
              >
                {t("button_request_feedback")}
              </Button>
            </Box>

            {/* Manual Feedback */}
            <Typography variant="h6" mt={6}>
              {t("header_manual_feedback")}
            </Typography>

            {/* Feedback List */}
            <DMACategoryManualFeedbackList
              manualFeedbackEntries={manualFeedbackEntries}
              dmaCategory={dmaCategory}
              organizationId={organizationId}
              recordingPeriodId={recordingPeriodId}
              dataEntryObjectId={dataEntryObjectId}
              esrsTopicId={esrsTopicId}
              onEdit={handleEditManualFeedback}
              disabled={disabled}
            />

            {/* Empty Message */}
            {manualFeedbackEntries.length < 1 && (
              <DialogContentText textAlign="center">
                {t("manual_feedback_empty_notice")}
              </DialogContentText>
            )}

            {/* Add Manual Feedback */}
            <Box ml="auto">
              <Button
                variant="contained"
                onClick={handleAddManualFeedback}
                disabled={disabled || disableFeedbackAction}
              >
                {t("button_add_manual_feedback")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()} disabled={disabled}>
            {t("close", { ns: "buttons" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
