import { FC, ReactNode, useCallback, useMemo } from "react";
import {
  IDMAFinancialEffectWithCalculatedValues,
  IDMAMaterialImpactWithCalculatedValues,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { IROsListUtilities } from "../utilities/iros-list.utilities";
import { IIrosListEntry, IroEffect } from "../impacts-risks-opportunitites.types";
import { useTranslation } from "react-i18next";
import { ESRSTopicUtilities } from "@netcero/netcero-common";
import { FormatUtilities } from "../../../common/utilities/format.utilities";
import {
  MinusIcon,
  PlusIcon,
  PlusMinusIcon,
} from "../../../common/constants/tabler-icon.constants";

export type IFilterIROs = (iro: IIrosListEntry) => boolean;
export type ISortIROs = (iro1: IIrosListEntry, iro2: IIrosListEntry) => number;
export type IIROsOverviewIROClickHandlerData =
  | {
      type: "financialEffect";
      financialEffect: IDMAFinancialEffectWithCalculatedValues;
    }
  | {
      type: "materialImpact";
      materialImpact: IDMAMaterialImpactWithCalculatedValues;
    };
export type IIROsOverviewIROClickHandler = (data: IIROsOverviewIROClickHandlerData) => void;

interface IIROsOverviewListProps {
  esrsTopics: IESRSTopic[];
  noFilteredIROsMessage?: string;
  filterIROs?: IFilterIROs;
  sortIROs?: ISortIROs;
  onClick?: IIROsOverviewIROClickHandler;
}

const INDICATOR_FOR_RESOURCE_TYPES: Record<IroEffect, ReactNode> = {
  positive: <PlusIcon size={14} />,
  negative: <MinusIcon size={14} />,
  neutral: <PlusMinusIcon size={14} />,
};

export const IROsOverviewList: FC<IIROsOverviewListProps> = ({
  esrsTopics,
  noFilteredIROsMessage,
  filterIROs,
  sortIROs,
  onClick,
}) => {
  const { t } = useTranslation("iros_overview_list_component");

  const { irosListEntries, materialImpacts, financialEffects } = useMemo(() => {
    const result = IROsListUtilities.convertTopicsToIroListEntries(esrsTopics);
    result.irosListEntries.sort((iro1, iro2) =>
      iro1.title.toLowerCase().localeCompare(iro2.title.toLowerCase()),
    );
    return result;
  }, [esrsTopics]);
  const handleClickRow = useCallback(
    (entry: IIrosListEntry) => {
      if (entry.type === "impact") {
        onClick?.({
          type: "materialImpact",
          materialImpact: materialImpacts.find((i) => i.id === entry.id)!,
        });
      } else {
        onClick?.({
          type: "financialEffect",
          financialEffect: financialEffects.find((i) => i.id === entry.id)!,
        });
      }
    },
    [financialEffects, materialImpacts, onClick],
  );

  const filteredIROsList = useMemo(
    () => (filterIROs ? irosListEntries.filter(filterIROs) : irosListEntries),
    [filterIROs, irosListEntries],
  );

  const sortedIROsList = useMemo(
    () => (sortIROs ? [...filteredIROsList].sort(sortIROs) : filteredIROsList),
    [sortIROs, filteredIROsList],
  );

  return (
    <Box>
      <Table size="small">
        {/* Header */}
        <TableHead>
          <TableRow>
            <TableCell>{t("table_header_title")}</TableCell>
            <TableCell align="center">{t("table_header_esrs_topic")}</TableCell>
            <TableCell align="center">{t("table_header_type")}</TableCell>
            <Tooltip title={t("table_header_result_tooltip")} placement="bottom">
              <TableCell align="center">{t("table_header_result")}</TableCell>
            </Tooltip>
            <TableCell align="right">{t("table_header_materiality_degree")}</TableCell>
          </TableRow>
        </TableHead>
        {/* Body */}
        <TableBody>
          {/* Normal IROs List */}
          {sortedIROsList.map((iro) => (
            <TableRow
              key={iro.id}
              sx={
                onClick
                  ? { cursor: "pointer", ":hover": { backgroundColor: "#0000000A" } }
                  : undefined
              }
              onClick={() => handleClickRow(iro)}
            >
              <TableCell>{iro.title}</TableCell>
              <TableCell align="center">
                {ESRSTopicUtilities.convertTopicToDisplayTopic(iro.topicIdentifier).split(" ")[1]}
              </TableCell>
              <TableCell align="center">{t(`iro_types.${iro.type}`)}</TableCell>
              <TableCell align="center">
                <Box display="flex" alignItems="center" justifyContent="center" gap={0.5}>
                  {iro.effect.map((effect, index) => INDICATOR_FOR_RESOURCE_TYPES[effect])}
                </Box>
              </TableCell>
              <TableCell align="right">
                {FormatUtilities.formatPercentage(iro.materiality, 1)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* Not IROs at all */}
      {irosListEntries.length === 0 && (
        <Typography textAlign="center" mt={2}>
          {t("notice_no_iros_at_all")}
        </Typography>
      )}
      {irosListEntries.length !== 0 && filteredIROsList.length === 0 && (
        <Typography textAlign="center" mt={2}>
          {noFilteredIROsMessage ?? t("notice_no_iros_for_filters")}
        </Typography>
      )}
    </Box>
  );
};
