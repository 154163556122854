import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IRecordingModelsResponse } from "@netcero/netcero-core-api-client";

export function useRecordingModelsQuery<T = IRecordingModelsResponse>(
  select?: (data: IRecordingModelsResponse) => T,
) {
  return useQuery({
    queryKey: ["recordingModels"],
    queryFn: () =>
      CoreApiService.RecordingModelApi.getAllRecordingModels()
        .then((req) => req())
        .then((res) => res.data),
    select,
  });
}
