import { IDMAConfigurationPotentialExtendSteps } from "@netcero/netcero-core-api-client";
import { IFinancialEffectCalculatorData } from "../types/financial-effect-calculator-data.interface";
import { IBaseCalculator } from "./base-calculator.interface";
import { DMAUtilities } from "../utilities/dma.utilities";
import { DMA_MATERIALITY_MULTIPLIER } from "../constants/dma.constants";

export class FinancialEffectCalculator implements IBaseCalculator {
  private readonly probabilityOfOccurrence: number;
  private readonly potentialExtent: number;

  private static weighting = {
    probabilityOfOccurrence: 0.5,
    potentialExtent: 0.5,
  };

  private readonly materialityMultiplier: number = DMA_MATERIALITY_MULTIPLIER;
  private readonly materialityThreshold: number;

  /**
   * Example: severity of 1.0 (maximum) --> materialityDegree of 5.0 (maximum), severity of 0.5 --> materialityDegree of 2.5
   * Default: 5
   * When the materialityDegree is greater than or equal to this threshold, the effect is considered material.
   * Default: 3
   * @param effect
   */
  constructor(effect: IFinancialEffectCalculatorData) {
    Object.assign(this, effect);
  }

  public calculateSeverity(): number {
    const result =
      (this.probabilityOfOccurrence * FinancialEffectCalculator.weighting.probabilityOfOccurrence +
        this.potentialExtent * FinancialEffectCalculator.weighting.potentialExtent) /
      (FinancialEffectCalculator.weighting.potentialExtent +
        FinancialEffectCalculator.weighting.probabilityOfOccurrence);

    // Workaround to fix floating point precision issue
    // TODO: Investigate if this is enough or if we need something more sophisticated
    return +result.toPrecision(8);
  }

  public calculateSeverityCurrency(
    potentialExtentSteps: IDMAConfigurationPotentialExtendSteps,
  ): number {
    const euroEquivalent = DMAUtilities.getPotentialExtentCurrencyEquivalent(
      potentialExtentSteps,
      this.potentialExtent,
    );

    return euroEquivalent * this.probabilityOfOccurrence;
  }

  public calculateMaterialityDegree(): number {
    return this.calculateSeverity() * this.materialityMultiplier;
  }

  public calculateMateriality(): boolean {
    return this.calculateMaterialityDegree() >= this.materialityThreshold;
  }
}
