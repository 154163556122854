import {
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IStakeholder,
  IStakeholderFeedback,
  IStakeholderFeedbackType,
} from "@netcero/netcero-core-api-client";
import { StakeholderFeedbackUtilities } from "../../stakeholder-feedbacks/utilities/stakeholder-feedback.utitilities";

export interface ICollectedStakeholderFeedbackEntry {
  stakeholderId: string;
  stakeholder: IStakeholder | null;
  materialFeedback: IStakeholderFeedback | null;
  financialFeedback: IStakeholderFeedback | null;
}

export class DMACategoryFeedbackUtilities {
  /**
   * Collects stakeholder feedback entries into a map for each stakeholder
   * @param stakeholders The stakeholders to populate the feedback entries with
   * @param feedbackEntries The feedback entries to collect
   * @param populateWithAllStakeholders Whether to populate the feedback entries with all stakeholders
   * @returns A map of stakeholder feedback entries
   */
  public static collectStakeholderFeedbackEntries(
    stakeholders: IStakeholder[],
    feedbackEntries: IStakeholderFeedback[],
    populateWithAllStakeholders = false,
  ) {
    const initialMap = new Map<string, ICollectedStakeholderFeedbackEntry>();

    if (populateWithAllStakeholders) {
      stakeholders.forEach((stakeholder) => {
        initialMap.set(stakeholder.id, {
          stakeholderId: stakeholder.id,
          stakeholder: stakeholder,
          materialFeedback: null,
          financialFeedback: null,
        });
      });
    }

    return feedbackEntries.reduce((result, currFeedback) => {
      if (!result.has(currFeedback.stakeholderId)) {
        result.set(currFeedback.stakeholderId, {
          stakeholderId: currFeedback.stakeholderId,
          stakeholder: stakeholders.find((s) => s.id === currFeedback.stakeholderId) ?? null,
          materialFeedback: null,
          financialFeedback: null,
        });
      }
      const stakeholderFeedbackEntry = result.get(currFeedback.stakeholderId)!;

      switch (currFeedback.feedbackType) {
        case IStakeholderFeedbackType.Material:
          stakeholderFeedbackEntry.materialFeedback = currFeedback;
          break;
        case IStakeholderFeedbackType.Financial:
          stakeholderFeedbackEntry.financialFeedback = currFeedback;
          break;
        default:
          throw new Error(`Unknown feedback type: ${currFeedback.feedbackType}`);
      }

      return result;
    }, initialMap);
  }

  public static getOrderedStakeholderFeedbackEntriesArray(
    stakeholders: IStakeholder[],
    feedbackEntries: IStakeholderFeedback[],
    populateWithAllStakeholders = false,
  ): ICollectedStakeholderFeedbackEntry[] {
    const feedbackMap = DMACategoryFeedbackUtilities.collectStakeholderFeedbackEntries(
      stakeholders,
      feedbackEntries,
      populateWithAllStakeholders,
    );

    return Array.from(feedbackMap.values()).sort((a, b) => {
      if (!a.stakeholder?.name) {
        return -1;
      } else if (!b.stakeholder?.name) {
        return 1;
      }
      return b.stakeholder!.name.localeCompare(a.stakeholder!.name);
    });
  }

  public static doesCategoryHaveSubmittedFeedbackForType(
    dmaCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
    feedbackType: IStakeholderFeedbackType,
  ) {
    const hasStakeholderFeedback = dmaCategory.feedback
      .filter(StakeholderFeedbackUtilities.hasFeedbackBeenSubmitted) // Only submitted feedback
      .some((feedback) => feedback.feedbackType === feedbackType);
    const hasManualFeedback = dmaCategory.manualFeedbacks.some(
      (manualFeedback) => manualFeedback.feedbackType === feedbackType,
    );
    return hasStakeholderFeedback || hasManualFeedback;
  }
}
