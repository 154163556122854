import { IMDRDependency } from "./mdrs.public-types";

export class MDRUtilities {
  public static createBooleanMDRDependencyFactory<T extends string>() {
    return (dependsUpon: T, toHaveValue: boolean = true): IMDRDependency<T> => {
      return {
        dependsUpon,
        activeWhenValue: {
          type: "equals-value",
          activeWhenValue: toHaveValue,
        },
      };
    };
  }
}
