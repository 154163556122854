import { Box, Skeleton } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { RecordingPeriodRecordingStructuresCardsList } from "../../modules/recording-model-template-groups/recording-period-recording-structures-cards-list.component";
import { RecordingPeriodActionsDashboardSection } from "../../modules/recording-periods/recording-period-actions-dashboard-section.component";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { HideChildrenInProductionComponent } from "../../modules/common/components/hide-children-in-production.component";
import { TypographySelectComponent } from "../../modules/common/components/typography-select.component";

export const RecordingPeriodDashboardPage: FC = () => {
  const { organizationId, recordingPeriodId } = useParams();

  const currentOrganization = useCurrentOrganization();

  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!);

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization!.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${currentOrganization?.id}`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, currentOrganization, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  // Change Recording Period

  const handleSelectRecordingPeriod = (recordingPeriodId: string) => {
    navigate(`/organizations/${organizationId}/recording-periods/${recordingPeriodId}`);
  };

  const dropdownOptions = useMemo(
    () =>
      (recordingPeriodsQuery.data?.recordingPeriods ?? []).map((rp) => ({
        value: rp.id,
        display: rp.name,
      })),
    [recordingPeriodsQuery.data?.recordingPeriods],
  );

  return (
    <Box width={1600} maxWidth="100%" p={4} mx="auto">
      <Box display="flex" alignItems="center" gap={2}>
        {recordingPeriodsQuery.isSuccess ? (
          <TypographySelectComponent
            onValueChange={(value) => handleSelectRecordingPeriod(value)}
            options={dropdownOptions}
            typographyVariant="h1"
            value={recordingPeriodId}
          />
        ) : (
          <Skeleton variant="text" height="56px" width="40rem" />
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={2} py={2}>
        {/* Display card for "Execute DMA" suggestion */}
        <RecordingPeriodActionsDashboardSection
          organizationId={organizationId!}
          recordingPeriodId={recordingPeriodId!}
        />
        {/* Display recording structures */}
        <HideChildrenInProductionComponent>
          <RecordingPeriodRecordingStructuresCardsList
            organizationId={organizationId!}
            recordingPeriodId={recordingPeriodId!}
          />
        </HideChildrenInProductionComponent>
      </Box>
    </Box>
  );
};
