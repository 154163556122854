import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IDMAFinancialEffectWithCalculatedValues,
  IDMAMaterialImpactWithCalculatedValues,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { TreeUtilities } from "../../common/utilities/tree.utilities";
import { DMACategoryUtilities } from "../dialogs/dma-categories/dma-category.utilities";
import { RecursiveUtilities } from "@netcero/netcero-common";

export type IDMATopicState = IDMACategoryState;

export class DMAUtilities {
  /**
   * Gets the total state of a topic
   * @param esrsTopic The topic to get the state from
   * @returns The total state of the topic
   */
  public static getESRSTopicState(esrsTopic: IESRSTopic): IDMATopicState {
    if (!esrsTopic.recordedESRSTopic) {
      return IDMACategoryState.Open;
    }

    // Completed if the topic is opted out
    if (esrsTopic.recordedESRSTopic.optOut) {
      return IDMACategoryState.Verified;
    }

    // Handle empty and NOT opt out
    if (esrsTopic.recordedESRSTopic.dmaCategories.length === 0) {
      return IDMACategoryState.Open;
    }

    const dmaCategories = esrsTopic.recordedESRSTopic.dmaCategories;
    const dmaCategoriesStates = dmaCategories.map(DMACategoryUtilities.getCategoryTotalState);
    const dmaCategoriesStatesWithoutOpen = dmaCategoriesStates.filter(
      (state) => state !== IDMACategoryState.Open,
    );

    // Only if all are open then the topic is open
    if (dmaCategoriesStatesWithoutOpen.length === 0) {
      return IDMACategoryState.Open;
    }

    // If there are any other state than open then the topic is work in progress
    if (dmaCategoriesStatesWithoutOpen.length !== dmaCategoriesStates.length) {
      return IDMACategoryState.WorkInProgress;
    }

    const lowestCategoryState = DMACategoryUtilities.getLowestState(dmaCategoriesStates);

    return lowestCategoryState;
  }

  /**
   * Method to find a category in a topic by its id
   * @param topic The topic to search in
   * @param dmaCategoryId The id of the category to find
   */
  public static findCategoryInTopic(topic: IESRSTopic, dmaCategoryId: string) {
    const categories = topic.recordedESRSTopic?.dmaCategories ?? [];

    for (const category of categories) {
      const foundCategory = TreeUtilities.getChildInTree(
        category,
        (c) => c.children,
        (c) => c.id === dmaCategoryId,
      );
      if (foundCategory) {
        return foundCategory;
      }
    }

    return null;
  }

  /**
   * Extracts all the assigned and responsible users ids from a category and its children
   * @param category The category to extract the assigned users from
   * @param userIdsSet The set to add the user ids to
   */
  public static getAllAssignedAndResponsibleUsersIdsFromCategory(
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
    userIdsSet: Set<string>,
  ) {
    // Extract Material Impacts User Ids
    for (const impact of category.materialImpacts) {
      if (impact.responsibleUserId) {
        userIdsSet.add(impact.responsibleUserId);
      }
      for (const userId of impact.assignedUserIds) {
        userIdsSet.add(userId);
      }
    }

    // Extract Financial Effects User Ids
    for (const effect of category.financialEffects) {
      if (effect.responsibleUserId) {
        userIdsSet.add(effect.responsibleUserId);
      }
      for (const userId of effect.assignedUserIds) {
        userIdsSet.add(userId);
      }
    }

    // Extract from children as well
    for (const child of category.children) {
      DMAUtilities.getAllAssignedAndResponsibleUsersIdsFromCategory(child, userIdsSet);
    }
  }

  /**
   * Gets all the assigned and responsible users ids from a list of topics
   * @param topics The topics to get the assigned users from
   * @returns An array with all the assigned users ids
   */
  public static getAllAssignedAndResponsibleUsersIdsFromTopics(topics: IESRSTopic[]): string[] {
    const resultSet = new Set<string>();

    for (const topic of topics) {
      for (const category of topic.recordedESRSTopic?.dmaCategories ?? []) {
        DMAUtilities.getAllAssignedAndResponsibleUsersIdsFromCategory(category, resultSet);
      }
    }

    return Array.from(resultSet);
  }

  /**
   * Removes all categories, material impacts, financial effects and child
   * categories that are neither assigned to the user nor is the user responsible
   * @param categories The categories to filter
   * @param userIds The user id to filter by
   * @returns The filtered categories
   */
  public static filterCategoriesByUserId(
    categories: IDMACategoryWithEffectsAndManualFeedbacksAndChildren[],
    userIds: string[],
  ) {
    return (
      categories
        .map((category) => {
          const newCategory = { ...category };

          newCategory.materialImpacts = category.materialImpacts.filter(
            (impact) =>
              impact.assignedUserIds.some((id) => userIds.includes(id)) ||
              (impact.responsibleUserId && userIds.includes(impact.responsibleUserId)),
          );
          newCategory.financialEffects = category.financialEffects.filter(
            (effect) =>
              effect.assignedUserIds.some((id) => userIds.includes(id)) ||
              (effect.responsibleUserId && userIds.includes(effect.responsibleUserId)),
          );

          newCategory.children = DMAUtilities.filterCategoriesByUserId(category.children, userIds);

          return newCategory;
        })
        // Only keep categories that have at least one effect or impact or children
        .filter(
          (category) =>
            category.materialImpacts.length > 0 ||
            category.financialEffects.length > 0 ||
            category.children.length > 0,
        )
    );
  }

  /**
   * Filters the topics by the user id. It removes all categories, material impacts,
   * financial effects and child categories that are neither assigned to the user
   * @param topics The topics to filter
   * @param userIds The user id to filter by
   */
  public static filterTopicsByUserIds(topics: IESRSTopic[], userIds: string[]): IESRSTopic[] {
    return topics
      .filter((topic) => topic.recordedESRSTopic)
      .map((topic) => {
        const newTopic = {
          ...topic,
          recordedESRSTopic: { ...topic.recordedESRSTopic! },
        };

        newTopic.recordedESRSTopic.dmaCategories = DMAUtilities.filterCategoriesByUserId(
          newTopic.recordedESRSTopic.dmaCategories,
          userIds,
        );

        return newTopic;
      })
      .filter((topic) => topic.recordedESRSTopic.dmaCategories.length > 0);
  }

  /**
   * Finds all parent names based on current child id.
   * @param root Collection of complete topics
   * @param targetId Child id whose parents we need
   */
  public static findParentsOfTopic(root: IESRSTopic, targetId: string) {
    if (!root.currentTemplate || !root.recordedESRSTopic) {
      return null;
    }

    for (const element of root.recordedESRSTopic.dmaCategories) {
      const path = TreeUtilities.getPathToTreeChild(
        element,
        (node) => node.children,
        (node) => node.id === targetId,
      );
      if (path) {
        return path;
      }
    }
    return null;
  }

  /**
   * Extract all IROs from a collection of DMA categories trees (including their child categories)
   * @param dmaCategories Collection of DMA categories
   */
  public static getAllIROsOfCategory(
    dmaCategories: IDMACategoryWithEffectsAndManualFeedbacksAndChildren[],
  ) {
    const flatDmaCategories = RecursiveUtilities.flattenRecursiveStructureDown(dmaCategories);

    return {
      materialImpacts: flatDmaCategories.reduce(
        (acc, curr) => [...acc, ...curr.materialImpacts],
        [] as IDMAMaterialImpactWithCalculatedValues[],
      ),
      financialEffects: flatDmaCategories.reduce(
        (acc, curr) => [...acc, ...curr.financialEffects],
        [] as IDMAFinancialEffectWithCalculatedValues[],
      ),
    };
  }

  /**
   * Map IROs to their titles
   * @param dmaCategories Collection of DMA categories
   * @param financialEffectIds
   * @param materialImpactIds
   * @return Array of titles corresponding to matching IRO IDs
   */
  public static mapIROIdsToTitles(
    dmaCategories: IDMACategoryWithEffectsAndManualFeedbacksAndChildren[],
    financialEffectIds: string[],
    materialImpactIds: string[],
  ): string[] {
    const { materialImpacts, financialEffects } = this.getAllIROsOfCategory(dmaCategories);

    const all: (
      | IDMAFinancialEffectWithCalculatedValues
      | IDMAMaterialImpactWithCalculatedValues
    )[] = [...materialImpacts, ...financialEffects];

    return all
      .filter((i) => financialEffectIds.includes(i.id) || materialImpactIds.includes(i.id))
      .map((i) => i.title);
  }
}
