import { IMaterialImpactHorizonCalculatorData } from "../types/material-impact-horizon-calculator-data.interface";
import { IMaterialImpactCalculatorData } from "../types/material-impact-calculator-data.interface";
import { IBaseCalculator } from "./base-calculator.interface";
import { MaterialImpactCalculator } from "./material-impact.calculator";

export class MaterialImpactHorizonsCalculator implements IBaseCalculator {
  private readonly shortTerm: IMaterialImpactCalculatorData;
  private readonly mediumTerm: IMaterialImpactCalculatorData;
  private readonly longTerm: IMaterialImpactCalculatorData;

  constructor(horizons: IMaterialImpactHorizonCalculatorData) {
    Object.assign(this, horizons);
  }

  private getCalculators(): MaterialImpactCalculator[] {
    return [this.shortTerm, this.mediumTerm, this.longTerm].map(
      (horizon) => new MaterialImpactCalculator(horizon),
    );
  }

  public calculateSeverity(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateSeverity()));
  }

  public calculateMaterialityDegree(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateMaterialityDegree()));
  }

  public calculateMateriality(): boolean {
    return this.getCalculators()
      .map((calc) => calc.calculateMateriality())
      .some((materiality) => materiality);
  }
}
