import React from "react";
import { TableCell, Tooltip, Typography } from "@mui/material";
import { IPolicy } from "@netcero/netcero-core-api-client";
import { LineClampTypography } from "../../common/components/line-clamp-typography.component";

interface IPolicyTableCellProps {
  showTooltip: boolean;
  setShowTooltip: (showTooltip: boolean) => void;
  policy: IPolicy;
}

export const PolicyTableCell: React.FC<IPolicyTableCellProps> = ({
  showTooltip,
  setShowTooltip,
  policy,
}) => {
  return (
    <Tooltip
      title={
        showTooltip ? (
          <Typography variant="inherit" sx={{ whiteSpace: "pre-wrap" }}>
            {policy.general.name}
          </Typography>
        ) : (
          ""
        )
      }
      placement="bottom-start"
      disableHoverListener={!showTooltip}
    >
      <TableCell>
        <LineClampTypography
          variant="inherit"
          maxLines={3}
          onOverflowChange={setShowTooltip}
          sx={{ whiteSpace: "pre-wrap" }}
        >
          {policy.general.name}
        </LineClampTypography>
      </TableCell>
    </Tooltip>
  );
};
