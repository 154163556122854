import { IDMACategory } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { OptionsDialog } from "../../../common/components/options.dialog";
import { useTranslation } from "react-i18next";
import { Box, DialogContentText } from "@mui/material";
import { ErrorTextComponent } from "../../../common/components/error-text.component";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";

interface IDMACategoryOptBackInConfirmDialogProps {
  open: boolean;
  category: IDMACategory | null;
  loading?: boolean;
  disabled?: boolean;
  error?: Error | null;
  onClose: (data: IDMACategory | null) => void;
}
export const DMACategoryOptBackInConfirmDialog: FC<IDMACategoryOptBackInConfirmDialogProps> = ({
  open,
  category,
  loading,
  error,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation("dma_category_opt_back_in_confirm_dialog");
  const renderName = useRenderDMACategoryName();

  return (
    <OptionsDialog
      open={open}
      title={t("title")}
      loading={loading}
      content={
        <Box>
          {error && <ErrorTextComponent error={error} />}
          <DialogContentText>{t("content", { name: renderName(category) })}</DialogContentText>
        </Box>
      }
      onClose={() => onClose(null)}
      options={[
        {
          label: t("cancel", { ns: "buttons" }),
          onClick: () => onClose(null),
          buttonProps: {
            variant: "outlined",
            disabled,
          },
        },
        {
          label: t("button_confirm"),
          onClick: () => onClose(category),
          buttonProps: {
            variant: "contained",
            disabled,
          },
        },
      ]}
    />
  );
};
