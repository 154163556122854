export class FormatUtilities {
  public static formatNumber(num: number, options?: Intl.NumberFormatOptions): string {
    return Number.isNaN(num) ? "-" : num.toLocaleString(navigator.language, options);
  }

  public static formatFixedDecimal(num: number, decimalPlaces: number): string {
    return Number.isNaN(num)
      ? "-"
      : num.toLocaleString(navigator.language, {
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        });
  }

  public static formatFractionAsPercentage(
    num: number,
    fractionDigits = 2,
    minimumIntegerDigits?: number,
  ) {
    return this.formatPercentage(num * 100, fractionDigits, minimumIntegerDigits);
  }

  /**
   *
   * @param num Value as Percent (0 is 0% and 100 is 100%)
   * @param fractionDigits
   * @param minimumIntegerDigits
   * @returns
   */
  public static formatPercentage(
    num: number,
    fractionDigits = 2,
    minimumIntegerDigits?: number,
  ): string {
    return Number.isNaN(num)
      ? "-"
      : num.toLocaleString(navigator.language, {
          maximumFractionDigits: fractionDigits,
          minimumFractionDigits: fractionDigits,
          minimumIntegerDigits,
        });
  }

  public static formatCurrency(num: number, currency: string = "EUR"): string {
    return num.toLocaleString(navigator.language, { style: "currency", currency });
  }

  public static formatDate(date: Date): string {
    return date.toLocaleDateString(navigator.language);
  }

  public static formatDateTime(date: Date | string): string {
    return new Date(date).toLocaleString(navigator.language);
  }

  /**
   * Shortens a text to a max length
   * @param text The text to limit in length
   * @param maxLength Max length (including the 3 dots)
   * @returns cut string
   * @deprecated Use LineClampText component instead
   */
  public static cutTextAfterN(text: string, maxLength: number) {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + "..." : text;
  }

  public static formatBoolean(
    materiality: boolean | null | undefined,
    textWhenTrue: string,
    textWhenFalse: string,
    textWhenEmpty = "-",
  ): string {
    return materiality !== null && materiality !== undefined
      ? materiality
        ? textWhenTrue
        : textWhenFalse
      : textWhenEmpty;
  }

  /**
   * A method to create dynamic URLs from provided path segments.
   *
   * @param segments - An array of URL segments that will be concatenated.
   * @returns - The assembled URL.
   */
  public static generateDynamicUrl(segments: string[]): string {
    return `/${segments.join("/")}`;
  }
}
