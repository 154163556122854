import { FC, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import {
  useDMAConfigurationQuery,
  useESRSTopicsQuery,
} from "../../modules/double-materiality-assessment/dma.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { Box } from "@mui/material";
import { ESRSTopicsStepperComponent } from "../../modules/double-materiality-assessment/esrs-topics-stepper.component";
import { useTranslation } from "react-i18next";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { IDataEntryObject, IDMAConfiguration } from "@netcero/netcero-core-api-client";
import { DMALanguagePickerComponent } from "../../modules/double-materiality-assessment/dma-language-picker.component";
import { useInitDMAMutation } from "../../modules/double-materiality-assessment/mutations/dma.mutations";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";

export const DMAPage: FC = () => {
  const { organizationId, recordingPeriodId } = useParams();
  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: {
        organizationId: organizationId ?? "",
        onChange: (organizationId) => {
          navigate(`/organizations/${organizationId}`);
        },
      },
      navigationItems: {
        activePath: `/organizations/${organizationId}`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const dmaConfigurationQuery = useDMAConfigurationQuery(organizationId!, recordingPeriodId!);

  return (
    <Box display="flex" justifyContent="center" flex={1} pl={2}>
      <QueryWrapper
        query={organizationStructureQuery}
        build={(organizationStructureResponse) => (
          <OrganizationStructureDraftWrapper
            organizationId={organizationId!}
            recordingPeriodId={recordingPeriodId!}
            organizationStructure={organizationStructureResponse}
            build={(rootDataEntryObject) => (
              // Another wrapper to react to result of dmaConfiguration query
              <QueryWrapper
                query={dmaConfigurationQuery}
                loadingOverride={() => <CenteredCircularProgress minHeight="calc(100vh - 64px)" />}
                build={(dmaConfigurationResponse) =>
                  dmaConfigurationResponse.type === "exists" ? (
                    <DMAForDEO
                      organizationId={organizationId!}
                      recordingPeriodId={recordingPeriodId!}
                      dataEntryObject={rootDataEntryObject}
                      dmaConfiguration={dmaConfigurationResponse.configuration}
                    />
                  ) : (
                    // configuration does not yet exist --> render language picker
                    <DMALanguagePickerComponent
                      organizationId={organizationId!}
                      recordingPeriodId={recordingPeriodId!}
                    />
                  )
                }
              />
            )}
          />
        )}
      />
    </Box>
  );
};

// Internal Component

interface IDMAForRootDEOProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObject: IDataEntryObject;
  dmaConfiguration: IDMAConfiguration;
}

const DMAForDEO: FC<IDMAForRootDEOProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObject,
  dmaConfiguration,
}) => {
  const { t } = useTranslation("double_materiality_assessment_page");

  const esrsTopicsQuery = useESRSTopicsQuery(organizationId, recordingPeriodId, dataEntryObject.id);

  // Initial Categories setup
  const initDMAQuery = useInitDMAMutation();

  // Initially setup categories for ESRSTopics (use default template)
  useEffect(() => {
    if (esrsTopicsQuery.data) {
      const esrsTopics = esrsTopicsQuery.data.esrsTopics;

      // init if at least one topic was not recorded
      const shouldInit =
        esrsTopics.filter((esrsTopic) => esrsTopic.recordedESRSTopic === undefined).length > 0;

      if (shouldInit) {
        initDMAQuery.mutate({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esrsTopicsQuery.data]);
  const haveCategoriesBeenInitialized = useMemo(() => {
    if (esrsTopicsQuery.data) {
      const esrsTopics = esrsTopicsQuery.data.esrsTopics;
      return esrsTopics
        .filter((esrsTopic) => esrsTopic.currentTemplate)
        .every((esrsTopic) => esrsTopic.recordedESRSTopic !== undefined);
    }
    return true;
  }, [esrsTopicsQuery.data]);

  // Prepare for rendering page

  const isLoading = useMemo(
    () => esrsTopicsQuery.isLoading || initDMAQuery.isPending,
    [esrsTopicsQuery.isLoading, initDMAQuery.isPending],
  );

  const queriesError = useMemo(
    () => esrsTopicsQuery.error || initDMAQuery.error,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [esrsTopicsQuery.isError, initDMAQuery.isError],
  );

  // Render Component (states)

  if (isLoading || !haveCategoriesBeenInitialized) {
    return <CenteredCircularProgress />;
  }

  if (queriesError) {
    return <ErrorTextComponent error={queriesError} />;
  }

  const esrsTopicsResponse = esrsTopicsQuery.data!;

  return esrsTopicsResponse.esrsTopics.length > 0 ? (
    <ESRSTopicsStepperComponent
      organizationId={organizationId}
      recordingPeriodId={recordingPeriodId}
      dataEntryObject={dataEntryObject}
      dmaConfiguration={dmaConfiguration}
      esrsTopics={esrsTopicsResponse.esrsTopics}
    />
  ) : (
    <Box>{t("categories_empty")}</Box>
  );
};
