import { ITargetPath, ITargetPathState } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TargetPathGoalsEditListComponent } from "./target-path-goals-edit-list.component";
import { TargetPathDiagramComponent } from "./target-path-diagram.component";
import { TargetPathIsCommittedInfoComponent } from "../../target-paths/components/target-path-is-committed-info.component";
import { TargetPathActionsComponent } from "../../target-paths/components/target-path-actions.component";
import { useTargetPathDialogs } from "../../target-paths/hooks/target-path-dialogs.hook";
import { ILocalReferenceBudget } from "../../reference-budgets/interfaces/reference-budgets.interfaces";
import { ILocalOrganization } from "../../organizations/local-organization.interface";
import { useNavigate } from "react-router-dom";

interface ITargetPathDetailsComponentProps {
  targetPath: ITargetPath;
  organizationTargetPath: ITargetPath | null;
  allTargetPaths: ITargetPath[];
  availableReferenceBudgets: ILocalReferenceBudget[];
  organization: ILocalOrganization;
}

export const TargetPathDetailsComponent: FC<ITargetPathDetailsComponentProps> = ({
  targetPath,
  organization,
  organizationTargetPath,
  allTargetPaths,
  availableReferenceBudgets,
}) => {
  const { t } = useTranslation("target_path_details_component");
  const navigate = useNavigate();

  const { render, handlers } = useTargetPathDialogs(
    organization,
    availableReferenceBudgets,
    allTargetPaths,
    {
      onCloneMutationSuccess: (targetPath) =>
        navigate(`/organizations/${organization.id}/target-paths/${targetPath.id}`),
    },
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Dialogs */}
      {render.renderEditDialog()}
      {render.renderDeleteConfirmDialog()}
      {render.renderToggleConfirmDialog()}
      {render.renderCloneDialog()}

      {/* Title + Details about the path itself */}
      <Typography variant="h1">{t("title", { targetPathName: targetPath.name })}</Typography>

      {/* Actions for current target path */}
      <TargetPathActionsComponent
        targetPath={targetPath}
        onEdit={(targetPath) => {
          handlers.openEditDialog({ targetPath });
        }}
        onToggleCommit={(targetPath) => {
          handlers.openToggleDialog({
            targetPath,
            currentOrganizationTargetPath: organizationTargetPath,
          });
        }}
        onClone={(targetPath) => {
          handlers.openCloneDialog({ targetPath });
        }}
        onDelete={(targetPath) => {
          handlers.openDeleteDialog({ targetPath });
        }}
      />

      {/* Display info that target path is committed*/}
      {targetPath.state === ITargetPathState.Committed && <TargetPathIsCommittedInfoComponent />}

      {/* Diagram of target path */}
      <TargetPathDiagramComponent
        targetPath={targetPath}
        organizationTargetPath={organizationTargetPath}
      />

      {/* Goals*/}
      <TargetPathGoalsEditListComponent targetPath={targetPath} organizationId={organization.id} />
    </Box>
  );
};
