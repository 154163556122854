import { FC, useMemo, useState } from "react";
import { Box, Collapse, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { TreeGroupContainer } from "./tree-group-container.component";
import { TreeUtilities } from "../utilities/tree.utilities";
import { ExpansionIconButton } from "./expansion-icon-button.component";

export interface ITableOfContentsEntry {
  id: string;
  title: string;
  children?: ITableOfContentsEntry[];
}

interface ICommonTocProps {
  onSelectEntry: (entry: ITableOfContentsEntry, pathToEntry: ITableOfContentsEntry[]) => void;
  getLinkForEntry?: (entry: ITableOfContentsEntry, pathToEntry: ITableOfContentsEntry[]) => string;
}

interface ITableOfContentsProps extends ICommonTocProps {
  entries: ITableOfContentsEntry[];
  activeEntryId: string | null;
  maxLevels?: number;
}

export const TableOfContents: FC<ITableOfContentsProps> = ({
  entries,
  activeEntryId,
  onSelectEntry,
  getLinkForEntry,
  maxLevels = 2,
}) => {
  const [expandedTOCSectionId, setExpandedTOCSectionId] = useState<string | null>(null);

  const pathToActiveEntry = useMemo(() => {
    return activeEntryId !== null
      ? TreeUtilities.getPathToTreeChildMultiple(
          entries,
          (entry) => entry.children ?? [],
          (entry) => entry.id === activeEntryId,
        )
      : null;
  }, [entries, activeEntryId]);

  const openRootEntry = useMemo(() => pathToActiveEntry?.[0] ?? null, [pathToActiveEntry]);

  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{ overflowY: "auto" }}>
      {entries.map((rootEntry) => {
        return (
          // Index is fine here since structure does not change unless everything changes
          <Box key={rootEntry.id}>
            <Box display="flex" alignItems="center">
              <ExpansionIconButton
                iconButtonProps={{
                  size: "small",
                  color: "primary",
                }}
                expanded={
                  expandedTOCSectionId === rootEntry.id || openRootEntry?.id === rootEntry.id
                }
                onClick={() => {
                  if (openRootEntry?.id !== rootEntry.id) {
                    setExpandedTOCSectionId((curr) =>
                      curr === rootEntry.id ? null : rootEntry.id,
                    );
                  }
                }}
                disabled={openRootEntry?.id === rootEntry.id}
              />
              {getLinkForEntry ? (
                <Link
                  component={RouterLink}
                  to={getLinkForEntry(rootEntry, [rootEntry])}
                  color="inherit"
                  underline="none"
                  onClick={() => {
                    onSelectEntry?.(rootEntry, [rootEntry]);
                    // Always reset expanded group when clicking on entry (also for link)
                    setExpandedTOCSectionId(null);
                  }}
                >
                  <RootEntryText
                    text={rootEntry.title}
                    isActive={openRootEntry?.id === rootEntry.id}
                  />
                </Link>
              ) : (
                <RootEntryText
                  text={rootEntry.title}
                  isActive={openRootEntry?.id === rootEntry.id}
                  onClick={() => {
                    onSelectEntry?.(rootEntry, [rootEntry]);
                    // Always reset expanded group when clicking on entry (also for link)
                    setExpandedTOCSectionId(null);
                  }}
                />
              )}
            </Box>
            {rootEntry.children && rootEntry.children.length > 0 && (
              <Collapse
                in={expandedTOCSectionId === rootEntry.id || openRootEntry?.id === rootEntry.id}
                sx={{ pl: 1.25 }}
              >
                <Level2AndDownEntries
                  entries={rootEntry.children}
                  pathToCurrentEntries={[rootEntry]}
                  pathToActiveEntry={pathToActiveEntry}
                  maxLevels={maxLevels}
                  level={1}
                  onSelectEntry={onSelectEntry}
                  getLinkForEntry={getLinkForEntry}
                />
              </Collapse>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

interface IEntryTextProps {
  isActive: boolean;
  text: string;
  onClick?: VoidFunction;
}

const RootEntryText: FC<IEntryTextProps> = ({ isActive, text, onClick }) => {
  return (
    <Typography
      fontWeight={isActive ? "bolder" : "normal"}
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};

interface ILevel2AndDownEntriesProps extends ICommonTocProps {
  entries: ITableOfContentsEntry[];
  pathToCurrentEntries: ITableOfContentsEntry[];
  pathToActiveEntry: ITableOfContentsEntry[] | null;
  maxLevels: number;
  level: number;
}

const Level2AndDownEntries: FC<ILevel2AndDownEntriesProps> = ({
  entries,
  pathToCurrentEntries,
  pathToActiveEntry,
  maxLevels,
  level,
  onSelectEntry,
  getLinkForEntry,
}) => {
  return (
    <TreeGroupContainer
      display="flex"
      flexDirection="column"
      gap={1}
      my={1}
      sx={{ borderColor: (theme) => theme.palette.primary.light }}
    >
      {entries.map((entry) => (
        <Box key={entry.id}>
          {/* Entry Item */}
          {getLinkForEntry ? (
            <Link
              component={RouterLink}
              to={getLinkForEntry(entry, [...pathToCurrentEntries, entry])}
              underline="none"
              color="inherit"
              onClick={() => onSelectEntry?.(entry, [...pathToCurrentEntries, entry])}
            >
              <SubItemText
                text={entry.title}
                isActive={pathToActiveEntry?.[level]?.id === entry.id}
              />
            </Link>
          ) : (
            <SubItemText
              text={entry.title}
              isActive={pathToActiveEntry?.[level]?.id === entry.id}
              onClick={() => onSelectEntry?.(entry, [...pathToCurrentEntries, entry])}
            />
          )}
          {/* Entry Children */}
          {entry && entry.children && entry.children.length > 0 && level < maxLevels && (
            <Level2AndDownEntries
              entries={entry.children}
              pathToCurrentEntries={[...pathToCurrentEntries, entry]}
              pathToActiveEntry={pathToActiveEntry}
              maxLevels={maxLevels}
              level={level + 1}
              onSelectEntry={onSelectEntry}
              getLinkForEntry={getLinkForEntry}
            />
          )}
        </Box>
      ))}
    </TreeGroupContainer>
  );
};

const SubItemText: FC<IEntryTextProps> = ({ text, isActive, onClick }) => {
  return (
    <Typography
      variant="body2"
      fontWeight={isActive ? "bold" : undefined}
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};
