import { Box } from "@mui/material";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { ContextBasedSideBar, SideBarContext } from "../contexts/side-bar.context";
import {
  SIDE_BAR_CORNER_RADIUS,
  SIDE_BAR_EXPANDED_WIDTH,
  SIDE_BAR_SHRUNK_WIDTH,
} from "./side-bar.component";

const LS_SIDEBAR_KEY = "sideBarOpen";

/**
 * This component sets up the basic layout of the app (mainly the side bar).
 * The Sidebar uses the SideBarContext which allows children to set the sideBar configuration by using the SideBarContext.
 * (You probably want to use the custom useCustomSideBarContent hooks to set the SideBarContext)
 */
export const AppShellWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(localStorage.getItem(LS_SIDEBAR_KEY) === "true");
  useEffect(() => {
    localStorage.setItem(LS_SIDEBAR_KEY, open.toString());
  }, [open]);

  return (
    <SideBarContext>
      <ContextBasedSideBar
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        ml={`${open ? SIDE_BAR_EXPANDED_WIDTH : SIDE_BAR_SHRUNK_WIDTH}px`}
        sx={{
          transition: "margin-left 300ms",
        }}
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          pl={`${SIDE_BAR_CORNER_RADIUS}px`}
          sx={{
            transition: "padding-left 300ms",
          }}
        >
          {children}
        </Box>
      </Box>
    </SideBarContext>
  );
};
