import { CircularProgress } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactNode } from "react";
import { ErrorTextComponent } from "./error-text.component";

interface IQueryWrapperProps<T> {
  query: UseQueryResult<T> | null;
  build: (result: T) => ReactNode;
  loadingOverride?: (loadingResult: UseQueryResult | null) => ReactNode;
  errorOverride?: (errorResult: UseQueryResult) => ReactNode;
}
export function QueryWrapper<T>({
  query,
  build,
  loadingOverride,
  errorOverride,
}: IQueryWrapperProps<T>) {
  if (query === null || query.isPending || query.isLoading) {
    return loadingOverride?.(query) ?? <CircularProgress />;
  } else if (query.error) {
    return errorOverride?.(query) ?? <ErrorTextComponent error={query.error} />;
  }
  return build(query.data!);
}
