import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  IBaseStakeholderData,
  IGetAllStakeholdersResponse,
  IStakeholder,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { ConfirmDialogComponent } from "../common/components/confirm-dialog.component";
import { HelpIcon } from "../common/constants/tabler-icon.constants";
import { useDialogState } from "../common/hooks/dialog-state.hook";
import { StakeholderEditDialog } from "./stakeholder-edit.dialog";
import {
  useCreateStakeholderMutation,
  useDeleteStakeholderMutation,
  useUpdateStakeholderMutation,
} from "./stakeholder.mutations";
import { StakeholdersEditList } from "./stakeholders-edit-list.component";

const INTERCOM_STAKEHOLDER_INFORMATION_ARTICLE_ID = 9720362;

interface IStakeholdersConfigurationProps {
  organizationId: string;
  stakeholdersResponse: IGetAllStakeholdersResponse;
}

export const StakeholdersConfiguration: FC<IStakeholdersConfigurationProps> = ({
  organizationId,
  stakeholdersResponse,
}) => {
  const { t } = useTranslation("stakeholders_configuration");
  const intercom = useIntercom();

  const stakeholders = stakeholdersResponse.stakeholders;

  // Create Stakeholder Dialog

  const createStakeholderMutation = useCreateStakeholderMutation();

  const [showCreateStakeholderDialog, setShowCreateStakeholderDialog] = useState(false);

  const handleCreateNewStakeholder = () => {
    createStakeholderMutation.reset();
    setShowCreateStakeholderDialog(true);
  };

  const handleCloseCreateStakeholderDialog = async (data: IBaseStakeholderData | null) => {
    if (data) {
      await createStakeholderMutation.mutateAsync({
        organizationId,
        payload: data,
      });
    }

    setShowCreateStakeholderDialog(false);
  };

  // Update Stakeholder Dialog
  const updateStakeholderMutation = useUpdateStakeholderMutation();

  const {
    isOpen: isUpdateDialogOpen,
    openDialog: openUpdateDialog,
    closeDialog: closeUpdateDialog,
    data: updateDialogData,
  } = useDialogState<{ stakeholder: IStakeholder }>();

  const handleEditStakeholder = useCallback(
    (stakeholder: IStakeholder) => {
      updateStakeholderMutation.reset();
      openUpdateDialog({ stakeholder });
    },
    [openUpdateDialog, updateStakeholderMutation],
  );

  const handleCloseEditStakeholderDialog = useCallback(
    async (data: IBaseStakeholderData | null) => {
      if (data !== null && updateDialogData) {
        await updateStakeholderMutation.mutateAsync({
          organizationId,
          stakeholderId: updateDialogData.stakeholder.id,
          payload: data,
        });
      }
      closeUpdateDialog();
    },
    [closeUpdateDialog, organizationId, updateDialogData, updateStakeholderMutation],
  );

  // Delete Stakeholder Dialog
  const deleteStakeholderMutation = useDeleteStakeholderMutation();

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    data,
  } = useDialogState<{ stakeholderId: string }>();

  const handleDeleteStakeholder = useCallback(
    (stakeholder: IStakeholder) => {
      // reset errors in case there were previous errors
      deleteStakeholderMutation.reset();
      openDeleteDialog({ stakeholderId: stakeholder.id });
    },
    [openDeleteDialog, deleteStakeholderMutation],
  );

  const handleCloseDeleteStakeholderDialog = useCallback(
    async (confirm: boolean) => {
      if (confirm && data?.stakeholderId) {
        await deleteStakeholderMutation.mutateAsync({
          organizationId,
          stakeholderId: data.stakeholderId,
        });
      }
      closeDeleteDialog();
    },
    [data, closeDeleteDialog, deleteStakeholderMutation, organizationId],
  );

  // Info Dialog
  // const {
  //   isOpen: isInfoDialogOpen,
  //   openDialog: openInfoDialog,
  //   closeDialog: closeInfoDialog,
  // } = useDialogStateWithoutData();

  return (
    <>
      {/* Create Dialog */}
      <StakeholderEditDialog
        open={showCreateStakeholderDialog}
        loading={createStakeholderMutation.isPending}
        error={createStakeholderMutation.isError ? createStakeholderMutation.error : null}
        disabled={createStakeholderMutation.isPending}
        onClose={handleCloseCreateStakeholderDialog}
      />

      {/* Update Dialog */}
      <StakeholderEditDialog
        open={isUpdateDialogOpen}
        loading={updateStakeholderMutation.isPending}
        error={updateStakeholderMutation.isError ? updateStakeholderMutation.error : null}
        disabled={updateStakeholderMutation.isPending}
        onClose={handleCloseEditStakeholderDialog}
        stakeholder={updateDialogData?.stakeholder}
      />

      {/* Delete Dialog */}
      <ConfirmDialogComponent
        loading={deleteStakeholderMutation.isPending}
        disabled={deleteStakeholderMutation.isPending}
        error={deleteStakeholderMutation.isError ? deleteStakeholderMutation.error : null}
        open={isDeleteDialogOpen}
        text={{
          content: { title: t("delete_dialog_title"), body: t("delete_dialog_body") },
          buttons: { confirm: t("delete_dialog_confirm"), cancel: t("delete_dialog_cancel") },
        }}
        onClose={handleCloseDeleteStakeholderDialog}
      />

      {/* <DMACategoryFeedbackInfoDialog open={isInfoDialogOpen} onClose={closeInfoDialog} /> */}

      {/* Content */}
      <Box maxWidth={1600} mx="auto">
        <Box display="flex" gap={1} mb={2} alignItems="center">
          <Typography variant="h1">{t("title")}</Typography>
          <Box>
            {/* TODO: Working example of showing a intercom article - may be removed in future */}
            <IconButton
              onClick={() => intercom.showArticle(INTERCOM_STAKEHOLDER_INFORMATION_ARTICLE_ID)}
            >
              <HelpIcon />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          {/* Create Button */}
          <Button variant="contained" onClick={handleCreateNewStakeholder} sx={{ mr: "auto" }}>
            {t("button_create")}
          </Button>
          {/* Stakeholders Table / Empty Notice */}
          {stakeholders.length < 1 ? (
            <Typography>{t("empty_notice")}</Typography>
          ) : (
            <StakeholdersEditList
              stakeholders={stakeholders}
              onDelete={handleDeleteStakeholder}
              onEdit={handleEditStakeholder}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
