import { useCallback, useMemo, useRef, useState } from "react";
import { useObserveSize } from "./use-observe-size.hook";

interface ISize {
  width: number;
  height: number;
}

export const useObserveSizeState = () => {
  const [size, setSize] = useState<ISize>({ height: 0, width: 0 });
  const ref = useRef(null);

  const callback = useCallback((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    if (entries.length !== 1) {
      return;
    }
    const entry = entries[0];
    setSize({ height: entry.contentRect.height, width: entry.contentRect.width });
  }, []);

  useObserveSize(ref, callback);

  return useMemo(() => ({ size, ref }), [size]);
};
