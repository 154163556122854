import { useMemo } from "react";
import {
  DataEntryObjectInputParameterValueDefinitionForNestedOptions,
  DataEntryObjectInputParameterValueDefinitionForNestedOptionsMultiple,
  DataEntryObjectInputParameterValueDefinitionForNestedOptionsSingle,
  IRecursiveStructureResult,
  OptionalDefinition,
  RecursiveUtilities,
} from "@netcero/netcero-common";
import {
  IInputParameterValueMetaDataNestedOptions,
  IInputParameterValueMetaDataNestedOptionsOption,
} from "@netcero/netcero-core-api-client";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

interface IProcessOptionsProps {
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForNestedOptions>;
  metaData: IInputParameterValueMetaDataNestedOptions;
}

export const useProcessOptions = ({ value, metaData }: IProcessOptionsProps) => {
  const translateContent = useTranslateContent();

  const parsedValue = useMemo(() => {
    return value === undefined
      ? []
      : !metaData.multiple
      ? [value as DataEntryObjectInputParameterValueDefinitionForNestedOptionsSingle]
      : (value as DataEntryObjectInputParameterValueDefinitionForNestedOptionsMultiple);
  }, [metaData.multiple, value]);

  const flatAndParents = useMemo(() => {
    const parents: Record<string, string> = {};
    const options = metaData.options.flatMap(
      (o) =>
        RecursiveUtilities.flattenRecursiveStructureDownWithLevelCreateParentMap(
          o,
          (option) => option.value,
          parents,
        )[0],
    );
    return { parents, options };
  }, [metaData.options]);

  const flatOptions = useMemo(() => flatAndParents.options, [flatAndParents.options]);

  const optionsPerValue = useMemo(
    () =>
      flatOptions.reduce((acc, curr) => {
        acc[curr.item.value] = curr;
        return acc;
      }, {} as Record<string, IRecursiveStructureResult<IInputParameterValueMetaDataNestedOptionsOption>>),
    [flatOptions],
  );

  const currentNames = useMemo(
    () => parsedValue.map((v) => translateContent(optionsPerValue[v].item.name)),
    [optionsPerValue, parsedValue, translateContent],
  );

  return useMemo(
    () => ({
      currentNames,
      flatOptions,
      parsedValue,
      parents: flatAndParents.parents,
    }),
    [currentNames, flatOptions, parsedValue, flatAndParents.parents],
  );
};
