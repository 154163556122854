import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getSourcesQueryKey } from "../sources/sources.queries";
import {
  ICreateSourceFilesRequest,
  ISignalSuccessfulFileUploadRequest,
} from "@netcero/netcero-core-api-client";
import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = null;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

interface ISourceRequestData {
  organizationId: string;
  id: string;
  payload: ICreateSourceFilesRequest;
  files?: FileList;
}

interface ISourceSignalSuccessData {
  organizationId: string;
  id: string;
  payload: ISignalSuccessfulFileUploadRequest;
}

export const useCreateSourceFileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, id, payload, files }: ISourceRequestData) => {
      const sourceFileCreation = await CoreApiService.SourceFilesApi.createSourceFiles(
        organizationId,
        id,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);

      // init ids to delete
      const failedFileIds = [];

      for (const [index, file] of sourceFileCreation.createdFiles.entries()) {
        try {
          await axiosInstance.put(file.uploadLink, files?.[index], {
            headers: {
              "Content-Type": file.mimeType,
              Authorization: null,
            },
          });

          await CoreApiService.SourceFilesApi.signalSuccessfulFileUpload(organizationId, id, {
            fileIds: [file.id],
          })
            .then((req) => req())
            .then((res) => res.data);
        } catch (error) {
          // add failed ids to delete
          failedFileIds.push(file.id);
        }
      }

      if (failedFileIds.length > 0) {
        // send all failed ids to delete
        await CoreApiService.SourceFilesApi.deleteSourceFiles(organizationId, id, {
          fileIds: failedFileIds,
        })
          .then((req) => req())
          .then((res) => res.data);
        throw Error("Something went wrong with file upload.");
      }

      return sourceFileCreation;
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};

export const useDeleteSourceFileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, id, payload }: ISourceSignalSuccessData) => {
      return CoreApiService.SourceFilesApi.deleteSourceFiles(organizationId, id, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};
