import { FC, useCallback, useMemo, useState } from "react";
import { Box, Card, Collapse, IconButton, SvgIcon, Tooltip, Typography } from "@mui/material";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";
import {
  IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
} from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { ExpansionIconButton } from "../../common/components/expansion-icon-button.component";
import { useDeoEsrsInputContext } from "./deo-esrs-input.context";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { AuditLogIcon, InfoIcon } from "../../common/constants/tabler-icon.constants";
import { EsrsInputParameterTypeIndicator } from "./esrs-input-parameter-type-indicator.component";
import { DrValueEditor } from "./value-editing/dr-value-editor.component";
import { IInputParameterDisplayInformation } from "../hooks/conditional-display-input-parameters.hook";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";
import { useTranslation } from "react-i18next";
import { ConditionalRecordingInfo } from "../components/conditional-recoding-info.component";
import { LinkChip } from "../../common/components/link-chip.component";
import { SingleOrganizationUserAvatarPicker } from "../../user/components/single-organization-user-avatar-picker.component";
import {
  CopyToClipboardButton,
  CopyToClipboardButtonAnimationProps,
} from "../../common/components/copy-to-clipboard-button.component";
import { DataEntryObjectInputParameterStatusIndicatorWithMenu } from "../../data-entry-objects-input-parameters/components/data-entry-object-input-parameter-status-indicator-with-menu.component";
import { Link as RouterLink } from "react-router-dom";
import { IDataEntryObjectInputParameterStatus } from "@netcero/netcero-core-api-client";

const COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME = "copy-ip-to-clipboard-button";

interface IDrInputParametersListProps {
  disclosureRequirementInputParameters: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[];
  drIsExcluded: boolean;
}

export const DrInputParametersList: FC<IDrInputParametersListProps> = ({
  disclosureRequirementInputParameters,
  drIsExcluded,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {disclosureRequirementInputParameters.map((disclosureRequirementInputParameter) => (
        <Box
          key={disclosureRequirementInputParameter.parameterId}
          sx={{ pl: (disclosureRequirementInputParameter.level - 1) * 4 }}
        >
          {disclosureRequirementInputParameter.inputParameter ? (
            <DrInputParameterListItem
              disclosureRequirementInputParameter={
                disclosureRequirementInputParameter as IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP
              }
              drIsExcluded={drIsExcluded}
            />
          ) : (
            "No input parameter"
          )}
        </Box>
      ))}
    </Box>
  );
};

interface IDrInputParameterListItemProps {
  disclosureRequirementInputParameter: IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP;
  drIsExcluded: boolean;
}

const DrInputParameterListItem: FC<IDrInputParameterListItemProps> = ({
  disclosureRequirementInputParameter,
  drIsExcluded,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_common");
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();
  const {
    organization,
    recordingPeriod,
    organizationStructure,
    dataEntryObject,
    recordingStructureId,
    expandedInputParameterIds,
    setExpandedInputParameterIds,
    conditionalDisplayInputParametersLookup,
    handleInputParameterContributingUserIdsChange,
    handleInputParameterResponsibleUsersChange,
    handleCreateIPValue,
    handleUpdateIPValue,
    handleDeleteIPValue,
    handleUpdateTableValue,
    handleSubmitInputParameter,
    handleApproveInputParameter,
    handleRejectInputParameter,
    createInputParameterUrl,
    isLoading,
  } = useDeoEsrsInputContext();

  const conditionalDisplayInfo: IInputParameterDisplayInformation | null = useMemo(
    () =>
      conditionalDisplayInputParametersLookup[disclosureRequirementInputParameter.parameterId] ??
      null,
    [conditionalDisplayInputParametersLookup, disclosureRequirementInputParameter.parameterId],
  );

  const isExpanded = useMemo(
    () => expandedInputParameterIds.includes(disclosureRequirementInputParameter.parameterId),
    [expandedInputParameterIds, disclosureRequirementInputParameter.parameterId],
  );

  const handleChangeExpand = useCallback(
    () =>
      setExpandedInputParameterIds((prev) =>
        prev.includes(disclosureRequirementInputParameter.parameterId)
          ? prev.filter((id) => id !== disclosureRequirementInputParameter.parameterId)
          : [...prev, disclosureRequirementInputParameter.parameterId],
      ),
    [disclosureRequirementInputParameter.parameterId, setExpandedInputParameterIds],
  );

  const translatedTitle = useMemo(
    () => translateContent(disclosureRequirementInputParameter.inputParameter.inputParameter.name),
    [translateContent, disclosureRequirementInputParameter.inputParameter.inputParameter.name],
  );

  const translatedDescription = useMemo(
    () =>
      translateOptionalContent(
        disclosureRequirementInputParameter.inputParameter.inputParameter.description,
      ),
    [
      translateOptionalContent,
      disclosureRequirementInputParameter.inputParameter.inputParameter.description,
    ],
  );

  const translatedLinkToEuLaw = useMemo(
    () =>
      translateOptionalContent(
        disclosureRequirementInputParameter.inputParameter.inputParameter.metaData.esrs?.xbrl
          .linkToRegulation,
      ),
    [
      disclosureRequirementInputParameter.inputParameter.inputParameter.metaData.esrs?.xbrl
        .linkToRegulation,
      translateOptionalContent,
    ],
  );

  // TODO: Remove this once state machine logic is correctly implemented
  const disableInputDueToState = useMemo(
    () =>
      drIsExcluded ||
      !!disclosureRequirementInputParameter.inputParameter.exclude ||
      disclosureRequirementInputParameter.inputParameter.status ===
        IDataEntryObjectInputParameterStatus.Done ||
      disclosureRequirementInputParameter.inputParameter.status ===
        IDataEntryObjectInputParameterStatus.InReview,
    [
      disclosureRequirementInputParameter.inputParameter.exclude,
      disclosureRequirementInputParameter.inputParameter.status,
      drIsExcluded,
    ],
  );

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const copyLinkComponent = useMemo(
    () => (
      <Box display="inline-block" className={COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}>
        <Box sx={{ transform: "translateY(-2px)" }}>
          <CopyToClipboardButton
            size="small"
            disableRipple
            value={createInputParameterUrl(disclosureRequirementInputParameter.inputParameter)}
            tooltip={t("copy_link_tooltip")}
            tooltipSuccess={t("tooltip_copy_link_success", { ns: "buttons" })}
            tooltipPlacement="right"
          />
        </Box>
      </Box>
    ),
    [disclosureRequirementInputParameter.inputParameter, createInputParameterUrl, t],
  );

  return (
    <Card
      id={disclosureRequirementInputParameter.inputParameter.inputParameter.id}
      sx={{
        borderRadius: 2,
        my: isExpanded ? 1 : 0,
        transition: "margin 300ms ease",
        position: "relative",
      }}
    >
      <Box p={1}>
        {/* Header Bar */}
        <Box display="flex" alignItems="center" gap={2}>
          {/* Expansion Button and Title */}
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            gap={1}
            onClick={handleChangeExpand}
            sx={{
              cursor: "pointer",
              ...CopyToClipboardButtonAnimationProps(`.${COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}`),
            }}
          >
            <ExpansionIconButton expanded={isExpanded} />
            <LineClampTypographyWithTooltip
              tooltipOverride={translatedTitle}
              onOverflowChange={setIsTextOverflowing}
              variant="subtitle1"
              fontSize={2}
              component="h4"
              maxLines={2}
              display="inline-block"
              sx={{ fontSize: 2, textWrap: "pretty" }}
            >
              {/* Actual Input Parameter Name */}
              {translatedTitle}
              {/* Copy IP Link - shown here when NOT overflowing (end of text) */}
              {!isTextOverflowing && copyLinkComponent}
            </LineClampTypographyWithTooltip>
            {/* Copy IP Link - shown here when overflowing (would be hidden by lineclamp) */}
            {isTextOverflowing && (
              <Box alignSelf="end" mt={-0.65} ml={-1}>
                {copyLinkComponent}
              </Box>
            )}
          </Box>
          {/* Optional Indicator */}
          {disclosureRequirementInputParameter.inputParameter.inputParameter.metaData.esrs
            ?.required === false && (
            <Typography variant="body2">
              {t("indicator_optional_text", { ns: "data_entry_object_values_overview_common" })}
            </Typography>
          )}
          {/* Description */}
          {translatedDescription && (
            <Tooltip title={translatedDescription} placement="left">
              <SvgIcon
                component={InfoIcon}
                color="action"
                fontSize="small"
                sx={{ mr: 0.5, fill: "transparent" }}
              />
            </Tooltip>
          )}
          {/* Status Indicator */}
          <DataEntryObjectInputParameterStatusIndicatorWithMenu
            type="disclosure-requirement"
            organizationId={organization.id}
            dataEntryObjectId={dataEntryObject.id}
            recordingPeriodId={recordingPeriod.id}
            recordingStructureId={recordingStructureId}
            dataEntryObjectInputParameter={disclosureRequirementInputParameter.inputParameter}
            onSubmit={() =>
              handleSubmitInputParameter(
                disclosureRequirementInputParameter.inputParameter.inputParameter,
              )
            }
            onApprove={() =>
              handleApproveInputParameter(
                disclosureRequirementInputParameter.inputParameter.inputParameter,
              )
            }
            onReject={() =>
              handleRejectInputParameter(
                disclosureRequirementInputParameter.inputParameter.inputParameter,
              )
            }
            disabled={isLoading || drIsExcluded}
            parentIsExcluded={drIsExcluded}
          />
          {/* Responsible Person */}
          <SingleOrganizationUserAvatarPicker
            avatarButtonDiameter={30}
            organizationId={organization.id}
            value={disclosureRequirementInputParameter.inputParameter.responsibleUserId ?? null}
            tooltipNoUserSelected={t("add_responsible_user_tooltip", {
              ns: "data_entry_object_values_overview_common",
            })}
            noUsersAvailableText={t("no_users_available_for_responsible_user", {
              ns: "data_entry_object_values_overview_common",
            })}
            noUserOption
            noUserOptionText={t("no_responsible_user_value_text", {
              ns: "data_entry_object_values_overview_common",
            })}
            onChange={(userId: string | null) =>
              handleInputParameterResponsibleUsersChange(
                disclosureRequirementInputParameter.inputParameter,
                userId,
              )
            }
            disabled={isLoading}
          />
          {/* Type Indicator */}
          <EsrsInputParameterTypeIndicator
            inputParameter={disclosureRequirementInputParameter.inputParameter.inputParameter}
          />
        </Box>
        {/* Collapsable */}
        <Collapse in={isExpanded} unmountOnExit>
          <Box display="flex" flexDirection="column" gap={1} p={1}>
            {/* First Row */}
            <Box display="flex" gap={1}>
              {/* EU Law */}
              {translatedLinkToEuLaw && (
                <LinkChip
                  to={translatedLinkToEuLaw}
                  label={t("eu_law_label", {
                    ns: "data_entry_object_values_overview_esrs_component",
                  })}
                />
              )}
              {/* Spacer */}
              <Box flex={1} />
              {/* Assigned Users */}
              <OrganizationUsersComponent
                values={disclosureRequirementInputParameter.inputParameter.contributingUserIds}
                organizationId={organization.id}
                onChange={(newAssignedUserIds) =>
                  handleInputParameterContributingUserIdsChange(
                    disclosureRequirementInputParameter.inputParameter,
                    newAssignedUserIds,
                  )
                }
                emptyMessageAddButton={t("no_more_contributing_users_to_assign")}
                emptyMessage={t("no_contributing_users")}
                tooltipAddButton={t("add_contributing_user_tooltip", {
                  ns: "data_entry_object_values_overview_common",
                })}
                disabled={isLoading}
              />
              {/* Audit Logs Button */}
              <Box display="flex" alignItems="center">
                <Tooltip title={t("title_audit_logging", { ns: "common" })}>
                  <IconButton
                    size="small"
                    component={RouterLink}
                    to={`/organizations/${organization.id}/configuration/audit-logging`}
                  >
                    <AuditLogIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {/* Info about conditional recording */}
            <ConditionalRecordingInfo displayInformation={conditionalDisplayInfo} />
            {/* Values Input */}
            <DrValueEditor
              deoInputParameter={disclosureRequirementInputParameter.inputParameter}
              availableDistributionCriteria={[]}
              organizationId={organization.id}
              recordingPeriod={recordingPeriod}
              rootDataEntryObjectId={organizationStructure.id}
              dataEntryObjectId={dataEntryObject.id}
              disabled={isLoading || disableInputDueToState}
              onCreate={(data) =>
                handleCreateIPValue(
                  disclosureRequirementInputParameter.inputParameter.inputParameter,
                  data,
                )
              }
              onUpdate={(valueId, data) =>
                handleUpdateIPValue(
                  disclosureRequirementInputParameter.inputParameter.inputParameter,
                  valueId,
                  data,
                )
              }
              onDelete={(valueId) =>
                handleDeleteIPValue(
                  disclosureRequirementInputParameter.inputParameter.inputParameter,
                  valueId,
                )
              }
              onTableUpdate={(created, updated, deleted) =>
                handleUpdateTableValue(
                  disclosureRequirementInputParameter.inputParameter!.inputParameter,
                  created,
                  updated,
                  deleted,
                )
              }
            />
          </Box>
        </Collapse>
      </Box>
    </Card>
  );
};
