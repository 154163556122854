import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
  IInputParameter,
} from "@netcero/netcero-core-api-client";
import { DateTime } from "luxon";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarAddIcon,
  DistributionCriteriaIcon,
  EditIcon,
  MinusIcon,
} from "../../../common/constants/tabler-icon.constants";
import { ComponentMode } from "../../../common/types/component-mode.type";
import { ILocalRecordingPeriod } from "../../../recording-periods/recording-periods.utilities";
import { NoteInputComponent } from "../../../value-acquisition/input-components/note-input.component";
import { useGenerateDateError } from "../../hooks/generate-date-error.hook";
import { useManageDataEntryObjectValue } from "../../hooks/manage-data-entry-object-value.hook";
import { ILocalDataEntryObjectInputParameterValueData } from "../../interfaces/local-data-entry-object-values.interfaces";
import {
  DataEntryObjectValuesUtilities,
  ORDERED_DATA_QUALITY,
} from "../../utilities/data-entry-object-values.utilities";
import { DataEntryObjectDistributionCriteriaDropdown } from "../../input-components/data-entry-object-distribution-criteria-dropdown.component";
import { DataEntryObjectValueActionsComponent } from "../../input-components/data-entry-object-value-actions.component";
import { DataEntryObjectValueDateRangeEditComponent } from "../../input-components/data-entry-object-value-date-range-edit.component";
import { DataEntryObjectValueInputFieldsDirectComponent } from "../../input-components/direct/data-entry-object-value-input-fields-direct.component";
import { DataQualityIcon } from "../../data-quality-icon.component";
import { useTranslatedValuesErrors } from "../../hooks/translate-values-errors.hooks";

interface IDataEntryObjectValueEditComponentProps {
  recordingPeriod: ILocalRecordingPeriod;
  inputParameter: IInputParameter;
  recordedValue: ILocalDataEntryObjectInputParameterValueData;
  availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  onSave?: (value: ILocalDataEntryObjectInputParameterValueData) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  subscribeToChanges?: boolean;
  mode: ComponentMode;
  dataEntryObject: IDataEntryObject;
  rootDataEntryObjectId: string;
  organizationId: string;
}

export const DataEntryObjectValueEditComponent: FC<IDataEntryObjectValueEditComponentProps> = ({
  recordingPeriod,
  inputParameter,
  recordedValue,
  disabled,
  subscribeToChanges,
  mode,
  onSave,
  onDelete,
  onCancel,
  availableDistributionCriteria,
  dataEntryObject,
  rootDataEntryObjectId,
  organizationId,
}) => {
  const { t } = useTranslation("data_entry_object_value_edit_component");

  const { hasChanges, errors, handlers, value, ref, isDirty, show } = useManageDataEntryObjectValue(
    {
      recordedValue,
      inputParameter,
      recordingPeriod,
      availableDistributionCriteria,
      subscribeToChanges,
      onSave,
    },
  );
  const translatedErrors = useTranslatedValuesErrors(errors.errors);

  const isPolicy = useMemo(
    () =>
      inputParameter.values.some(
        (v) =>
          v.valueConfiguration.type === "simple" &&
          v.valueConfiguration.configuration.type === "policy",
      ),
    [inputParameter.values],
  );

  // auto save dirty policies
  useEffect(() => {
    if (isPolicy && isDirty) {
      handlers.handleSave();
    }
    // it is fine because save is not memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isPolicy]);

  const generateDateError = useGenerateDateError(value);

  const Icon = useMemo(() => {
    return value.dataQuality
      ? DataEntryObjectValuesUtilities.getIconForDataQuality(value.dataQuality)
      : MinusIcon;
  }, [value.dataQuality]);

  return (
    <Box display="flex" flexDirection="row" alignItems="start" gap={1}>
      {/* Data quality */}
      {show.dataQuality && (
        <Box display="flex" alignItems="center">
          <Tooltip title={t(`tooltips.data_quality.${value.dataQuality ?? "none"}`)}>
            <span>
              <IconButton
                ref={ref.dataQualityButtonRef}
                onClick={() => handlers.dataQuality.toggleDataQualityPopup()}
                disabled={disabled || !onSave}
                color="inherit"
                sx={{ mt: 1 }}
              >
                <Icon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}

      {/* Data Quality Menu */}
      <Menu
        open={show.dataQualityPopup}
        onClose={() => handlers.dataQuality.toggleDataQualityPopup()}
        anchorEl={ref.dataQualityButtonRef.current}
      >
        <MenuItem onClick={() => handlers.dataQuality.handleChangeDataQuality(null)}>
          <Typography color="grey">{t(`tooltips.data_quality.none`)}</Typography>
        </MenuItem>
        {ORDERED_DATA_QUALITY.map((quality) => (
          <MenuItem
            key={quality}
            onClick={() => handlers.dataQuality.handleChangeDataQuality(quality)}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <DataQualityIcon dataQuality={quality} />
            {t(`tooltips.data_quality.${quality}`)}
          </MenuItem>
        ))}
      </Menu>

      {/* Date */}
      {show.date &&
        (value.enteredForDateStart !== null || value.enteredForDateEnd !== null ? (
          <DataEntryObjectValueDateRangeEditComponent
            start={
              value.enteredForDateStart !== null
                ? DateTime.fromJSDate(value.enteredForDateStart)
                : null
            }
            end={
              value.enteredForDateEnd !== null ? DateTime.fromJSDate(value.enteredForDateEnd) : null
            }
            recordingPeriod={recordingPeriod}
            onStartChange={(value) =>
              handlers.date.handleSetDate(value?.toJSDate() ?? null, "start")
            }
            onEndChange={(value) => handlers.date.handleSetDate(value?.toJSDate() ?? null, "end")}
            onStartError={(error, minDate, maxDate) =>
              handlers.date.handleSetDateError(generateDateError(error, minDate, maxDate), "start")
            }
            onEndError={(error, minDate, maxDate) =>
              handlers.date.handleSetDateError(generateDateError(error, minDate, maxDate), "end")
            }
            onRemoveBothDates={handlers.date.handleRemoveDate}
            startError={errors.dateError.errorStart}
            endError={errors.dateError.errorEnd}
            disabled={disabled || !onSave}
          />
        ) : (
          <Tooltip title={t("tooltips.add_date")}>
            <IconButton onClick={handlers.date.handleAddDate} sx={{ mt: 1 }}>
              <CalendarAddIcon />
            </IconButton>
          </Tooltip>
        ))}
      <Box display="flex" flexDirection="column" gap={2} flex={1}>
        {/* Values */}
        <DataEntryObjectValueInputFieldsDirectComponent
          inputParameter={inputParameter}
          currentValue={value}
          onInputValueChanged={handlers.handleInputChanged}
          disabled={disabled || !onSave}
          errors={isDirty ? translatedErrors : {}}
          organizationId={organizationId}
          rootDataEntryObjectId={rootDataEntryObjectId}
          recordingPeriodId={recordingPeriod.id}
          dataEntryObjectId={dataEntryObject.id}
        />
        {/* Note Input */}
        {value.note !== undefined && (
          <NoteInputComponent
            note={value.note}
            onChange={handlers.handleNoteChanged}
            onClearNote={() => handlers.handleNoteChanged(undefined)}
            toolTipRemoveNote={t("tooltips.remove_note")}
          />
        )}
      </Box>
      {/* Distribution Criteria Dropdown */}
      {show.dcOption && show.dcDropdown && (
        <DataEntryObjectDistributionCriteriaDropdown
          disabled={disabled}
          availableDistributionCriteria={availableDistributionCriteria}
          onRemoveDistributionCriteria={
            handlers.distributionCriterion.handleClickDistributionCriterion
          }
          value={value.distributionCriterionId}
          onChange={handlers.distributionCriterion.handleUpdateDistributionCriteria}
        />
      )}
      {/* action bar for the current value */}
      <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
        {/* Add DC Value Button */}
        {show.dcOption && !show.dcDropdown && (
          <Tooltip title={t("tooltips.use_distribution_criterion")}>
            <span>
              <IconButton
                disabled={disabled}
                size="medium"
                onClick={handlers.distributionCriterion.handleClickDistributionCriterion}
              >
                <DistributionCriteriaIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {/* Only shown for non-policy parameters */}
        {/* Add Note Button */}
        {!isPolicy && (
          <>
            {value.note === undefined && (
              <Tooltip title={t("tooltips.add_note")}>
                <Box alignSelf="center">
                  <IconButton
                    disabled={disabled}
                    size="medium"
                    onClick={() => handlers.handleNoteChanged("")}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            )}

            {/* Actions to save... */}
            <DataEntryObjectValueActionsComponent
              mode={mode}
              disabled={disabled}
              hasChanges={hasChanges}
              hasError={errors.hasError}
              onReset={handlers.handleReset}
              onSave={handlers.handleSave}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
