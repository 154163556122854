import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import { IInputParameterValueMetaData } from "@netcero/netcero-core-api-client";
import {
  DataEntryObjectInputParameterValueDefinitionForBoolean,
  OptionalDataEntryObjectInputParameterValueDefinition,
} from "@netcero/netcero-common";
import { useTranslateBooleanInputValue } from "../../../value-acquisition/input-components/boolean-input.component";
import { FormatUtilities } from "../../../common/utilities/format.utilities";

const NO_VALUE = "-";

interface IRenderTableValueReadonlyProps {
  valueMetaData: IInputParameterValueMetaData;
  value: OptionalDataEntryObjectInputParameterValueDefinition;
}

export const RenderTableValueReadonly: FC<IRenderTableValueReadonlyProps> = ({
  valueMetaData,
  value,
}) => {
  const translateBooleanInputValue = useTranslateBooleanInputValue();

  const renderedValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    switch (valueMetaData.type) {
      case "text":
        return value;
      case "number":
        if (valueMetaData.valueLimits.isPercentage) {
          return FormatUtilities.formatPercentage(+value, valueMetaData.valueLimits.precision);
        } else {
          return FormatUtilities.formatNumber(+value, {
            maximumFractionDigits: valueMetaData.valueLimits.precision,
          });
        }
      case "boolean":
        return translateBooleanInputValue(
          value as DataEntryObjectInputParameterValueDefinitionForBoolean,
        );
      // Future type have to be added once they will be in use
      default:
        return "Unsupported";
    }
  }, [translateBooleanInputValue, value, valueMetaData]);

  return <Typography variant="body2">{renderedValue ?? NO_VALUE}</Typography>;
};
