import {
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { DMA_MATERIALITY_MULTIPLIER } from "@netcero/netcero-dma";
import { IDMACategoryNameRenderer } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import { IESRSTopicNameRenderer } from "../../double-materiality-assessment/hooks/render-esrs-topic-name.hook";

export enum DMAMatrixBubbleType {
  TOPIC = "topic",
  CATEGORY = "category",
  IRO = "iro",
}

export type DMAMatrixSeriesElement = [number, number, number, string, DMAMatrixBubbleType, string];

export class DMAMatrixUtilities {
  // Constants

  public static readonly COLORS = {
    // TODO: Possibly move to theme by extending color palette
    ESRS_TOPICS: {
      ENVIRONMENTAL: "#3CB962",
      SOCIAL: "#E79934",
      GOVERNANCE: "#1976D2",
      OTHER: "#A0A0A0",
    },
    CATEGORY_SELF: "#55555555",
  };

  // General Utilities

  public static checkIsDisplayableSeriesEntryPredicate = (data: DMAMatrixSeriesElement | null) =>
    data !== null && !Number.isNaN(data[0]) && !Number.isNaN(data[1]);

  public static filterSeriesToDisplayableSeries(
    data: (DMAMatrixSeriesElement | null)[],
  ): DMAMatrixSeriesElement[] {
    return data.filter(
      DMAMatrixUtilities.checkIsDisplayableSeriesEntryPredicate,
    ) as DMAMatrixSeriesElement[];
  }

  public static readonly DEFAULT_CHART_PROPS = {
    AXIS_PROPS: {
      type: "value",
      nameLocation: "middle",
      nameGap: 40,
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      max: DMA_MATERIALITY_MULTIPLIER,
    },
  } as const;

  public static getScatterChartSeriesData(
    name: string | undefined,
    data: DMAMatrixSeriesElement[],
    maxMaterialityDegreeSum: number,
    itemSymbolColor: string,
  ) {
    return {
      name,
      type: "scatter",
      data,
      symbolSize: (data: number[]) => {
        return Math.sqrt(data[2] / maxMaterialityDegreeSum) * 75;
      },
      emphasis: {
        focus: "series",
        label: {
          show: true,
          formatter: (param: { data: string[] }) => {
            return param.data[3];
          },
          position: "top",
        },
      },
      itemStyle: {
        color: itemSymbolColor,
      },
    };
  }

  public static getColorForTopic(topic: IESRSTopic) {
    switch (topic.topic.substring(0, 6)) {
      case "ESRS E":
        return DMAMatrixUtilities.COLORS.ESRS_TOPICS.ENVIRONMENTAL;
      case "ESRS S":
        return DMAMatrixUtilities.COLORS.ESRS_TOPICS.SOCIAL;
      case "ESRS G":
        return DMAMatrixUtilities.COLORS.ESRS_TOPICS.GOVERNANCE;
      default:
        return DMAMatrixUtilities.COLORS.ESRS_TOPICS.OTHER;
    }
  }

  // General Converters

  /**
   * Converts the given ESRSTopic to a series data array (for Scatter Chart to use)
   * @param esrsTopic
   * @returns financialMaterialityDegree, materialMaterialityDegree, materialityDegreeSum,
   * Name, Type, resourceIds
   */
  public static convertESRSTopicToSeriesData(
    esrsTopic: IESRSTopic,
    renderName: IESRSTopicNameRenderer,
  ): DMAMatrixSeriesElement | null {
    if (!esrsTopic.recordedESRSTopic) {
      return null;
    }

    const financialMaterialityValues = esrsTopic.recordedESRSTopic.dmaCategories
      .filter((c) => c.materiality.materialityDegreeFinancial !== undefined)
      .map((c) => c.materiality.materialityDegreeFinancial!);
    const materialMaterialityValues = esrsTopic.recordedESRSTopic.dmaCategories
      .filter((c) => c.materiality.materialityDegreeMaterial !== undefined)
      .map((c) => c.materiality.materialityDegreeMaterial!);

    const isEmpty =
      financialMaterialityValues.length === 0 && materialMaterialityValues.length === 0;

    let financialMateriality = NaN;
    let materialMateriality = NaN;

    if (!isEmpty) {
      financialMateriality =
        financialMaterialityValues.length > 0 ? Math.max(...financialMaterialityValues) : 0;
      materialMateriality =
        materialMaterialityValues.length > 0 ? Math.max(...materialMaterialityValues) : 0;
    }

    const summedUpMaterialityDegrees = esrsTopic.recordedESRSTopic.dmaCategories
      .map(DMAMatrixUtilities.getCategoryMaterialityDegreesSum)
      .reduce(
        (sum, curr) => sum + curr.financialMaterialityDegreeSum + curr.materialMaterialityDegreeSum,
        0,
      );

    return [
      financialMateriality,
      materialMateriality,
      summedUpMaterialityDegrees,
      renderName(esrsTopic),
      DMAMatrixBubbleType.TOPIC,
      esrsTopic.id,
    ];
  }

  /**
   * Converts the given category to a series data array (for Scatter Chart to use)
   * @param dmaCategory The category to convert
   * @param render The function to render the category name
   * @returns financialMaterialityDegree, materialMaterialityDegree, materialityDegreeSum, Name, Type, resourceId
   */
  public static convertCategoryToSeriesData(
    dmaCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
    render: IDMACategoryNameRenderer,
  ): DMAMatrixSeriesElement | null {
    // Return null on empty materiality
    if (
      dmaCategory.materiality.materialityDegreeFinancial === undefined &&
      dmaCategory.materiality.materialityDegreeMaterial === undefined
    ) {
      return null;
    }
    const materialityDegreesSum = DMAMatrixUtilities.getCategoryMaterialityDegreesSum(dmaCategory);

    return [
      dmaCategory.materiality.materialityDegreeFinancial ?? 0,
      dmaCategory.materiality.materialityDegreeMaterial ?? 0,
      materialityDegreesSum.financialMaterialityDegreeSum +
        materialityDegreesSum.materialMaterialityDegreeSum,
      render(dmaCategory),
      DMAMatrixBubbleType.CATEGORY,
      dmaCategory.id,
    ];
  }

  // Materiality Degree Sum

  /**
   * Sums up the materialityDegree of all IROs in the given category (and its children)
   * @param category
   * @returns The sum of the materialityDegree
   */
  public static getCategoryMaterialityDegreesSum(
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ): { financialMaterialityDegreeSum: number; materialMaterialityDegreeSum: number } {
    const materialImpactsMaterialityDegreeSum = category.materialImpacts
      .map((mi) => mi.materialityDegree)
      .reduce((sum, curr) => sum + curr, 0);
    const financialEffectsMaterialityDegreeSum = category.financialEffects
      .map((mi) => mi.materialityDegree)
      .reduce((sum, curr) => sum + curr, 0);

    const chilrensResults = category.children.map((c) =>
      DMAMatrixUtilities.getCategoryMaterialityDegreesSum(c),
    );

    const childrensFinancialMaterialityDegreeSum = chilrensResults
      .map((r) => r.financialMaterialityDegreeSum)
      .reduce((sum, curr) => sum + curr, 0);
    const childrensMaterialMaterialityDegreeSum = chilrensResults
      .map((r) => r.materialMaterialityDegreeSum)
      .reduce((sum, curr) => sum + curr, 0);

    return {
      financialMaterialityDegreeSum:
        financialEffectsMaterialityDegreeSum + childrensFinancialMaterialityDegreeSum,
      materialMaterialityDegreeSum:
        materialImpactsMaterialityDegreeSum + childrensMaterialMaterialityDegreeSum,
    };
  }
}
