import { IEvaluationsEnum, ITargetPath } from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { MultiSelectComponent } from "../../common/components/multi-select.component";
import { useTargetPathProgressQuery } from "../queries/target-paths.queries";
import { ILocalOrganization } from "../../organizations/local-organization.interface";
import { TargetPathProgressDiagramComponent } from "./target-path-progress-diagram.component";
import { DateTime } from "luxon";
import { TargetPathUtilities } from "@netcero/netcero-common";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

interface ITargetPathProgressComponentProps {
  organization: ILocalOrganization;
  currentOrganizationTargetPath: ITargetPath;
  allRecordingPeriods: ILocalRecordingPeriod[];
}

export const TargetPathProgressComponent: FC<ITargetPathProgressComponentProps> = ({
  currentOrganizationTargetPath,
  allRecordingPeriods,
  organization,
}) => {
  const { t } = useTranslation("target_path_progress_component");
  const translateContent = useTranslateContent();

  const availableRecordingPeriods = useMemo(() => {
    const referenceYear = currentOrganizationTargetPath.referenceBudget.referenceYear;
    const maxGoalYear = TargetPathUtilities.getMaxGoalYear(currentOrganizationTargetPath);

    // constraints:
    // endDate >= reference year + 1
    // startDate <= maxGoalYear

    return allRecordingPeriods.filter(
      (rp) =>
        DateTime.fromJSDate(rp.endDate).year >= referenceYear + 1 &&
        DateTime.fromJSDate(rp.startDate).year <= maxGoalYear,
    );
  }, [allRecordingPeriods, currentOrganizationTargetPath]);

  const [selectedRecordingStructureIds, setSelectedRecordingStructureIds] = useState<string[]>([]);

  const progressQuery = useTargetPathProgressQuery(organization.id, selectedRecordingStructureIds);

  return (
    <Box display="flex" flexDirection="column" gap={2.5} mt={1} width="100%">
      {/* Subheader */}
      <Typography variant="subtitle1">{t("header")}</Typography>

      {/* Loader for progress query */}
      {progressQuery.isLoading && <LinearProgress sx={{ mb: 1 }} />}

      {/* Recording Structure Select */}
      <MultiSelectComponent
        required
        options={availableRecordingPeriods}
        label={t("labels.recording_structures_for_evaluation")}
        selectedOptions={selectedRecordingStructureIds}
        onValuesChange={(newValues) => setSelectedRecordingStructureIds(newValues)}
        shrinkInputLabel={true}
        config={{
          type: "nested",
          getHeading: (rp) => rp.name,
          getChildren: (rp) =>
            rp.recordingStructures
              // CAREFUL: this might cause issues if a RS used to have type "evaluation" but no longer has it...
              .filter((rs) => rs.evaluations.includes(IEvaluationsEnum.Emissions))
              .map((rs) => ({
                label: translateContent(rs.name),
                value: rs.id,
              })),
        }}
      />

      {/* The actual progress diagram */}
      {progressQuery.data && (
        <TargetPathProgressDiagramComponent
          targetPathToCompare={currentOrganizationTargetPath}
          result={progressQuery.data}
        />
      )}
    </Box>
  );
};
