/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IAddressedMatterS4Enum = {
    InformationrelatedImpactsForConsumersAndOrEndUsersMemberEsrsPrivacyForConsumersAndOrEndUsersMember: 'esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:PrivacyForConsumersAndOrEndUsersMember',
    InformationrelatedImpactsForConsumersAndOrEndUsersMemberEsrsFreedomOfExpressionForConsumersAndOrEndusersMember: 'esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:FreedomOfExpressionForConsumersAndOrEndusersMember',
    InformationrelatedImpactsForConsumersAndOrEndUsersMemberEsrsAccessToQualityInformationMember: 'esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:AccessToQualityInformationMember',
    PersonalSafetyOfConsumersAndOrEndUsersMemberEsrsHealthAndSafetyForConsumersAndOrEndUsersMember: 'esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:HealthAndSafetyForConsumersAndOrEndUsersMember',
    PersonalSafetyOfConsumersAndOrEndUsersMemberEsrsSecurityOfPersonMember: 'esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:SecurityOfPersonMember',
    PersonalSafetyOfConsumersAndOrEndUsersMemberEsrsProtectionOfChildrenMember: 'esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:ProtectionOfChildrenMember',
    SocialInclusionOfConsumersAndOrEndUsersMemberEsrsNondiscriminationMember: 'esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:NondiscriminationMember',
    SocialInclusionOfConsumersAndOrEndUsersMemberEsrsAccessToProductsAndServicesMember: 'esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:AccessToProductsAndServicesMember',
    SocialInclusionOfConsumersAndOrEndUsersMemberEsrsResponsibleMarketingPracticesMember: 'esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:ResponsibleMarketingPracticesMember',
    OtherConsumersAndOrEndusersSubtopicMember: 'esrs:OtherConsumersAndOrEndusersSubtopicMember'
} as const;

export type IAddressedMatterS4Enum = typeof IAddressedMatterS4Enum[keyof typeof IAddressedMatterS4Enum];



