import {
  Box,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { SourcesVerification } from "@netcero/netcero-common";
import { ISource } from "@netcero/netcero-core-api-client";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DeleteIcon,
  EditFilesIcon,
  EditIcon,
  WarningIcon,
} from "../common/constants/tabler-icon.constants";
import { ISourceDropdownEnum } from "./sources-edit.dialog";
import { LineClampTypography } from "../common/components/line-clamp-typography.component";

interface ISourceEditListComponentProps {
  sources: ISource[];
  onEdit: (source: ISource) => void;
  onDelete: (source: ISource) => void;
  onUpload: (source: ISource) => void;
  disabled?: boolean;
}

export const SourceEditListComponent: FC<ISourceEditListComponentProps> = ({
  sources,
  onEdit,
  onDelete,
  onUpload,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const { t } = useTranslation(["sources, sources_list_component"]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t("sources_list_component:headers.name")}</TableCell>
          <TableCell>{t("sources_list_component:headers.description")}</TableCell>
          <TableCell>{t("sources_list_component:headers.source_type")}</TableCell>
          <TableCell></TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {sources?.map((source) => (
          <TableRow key={source.id}>
            <TableCell>{source.name}</TableCell>
            <Tooltip
              title={
                showTooltip ? (
                  <Typography variant="inherit" sx={{ whiteSpace: "pre-wrap" }}>
                    {source.description}
                  </Typography>
                ) : (
                  ""
                )
              }
              placement="bottom"
              disableHoverListener={!showTooltip}
            >
              <TableCell>
                <LineClampTypography
                  variant="inherit"
                  maxLines={3}
                  onOverflowChange={setShowTooltip}
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {source.description ?? "-"}
                </LineClampTypography>
              </TableCell>
            </Tooltip>
            <TableCell>{t(ISourceDropdownEnum[source.sourceType])}</TableCell>
            <TableCell>
              <Tooltip
                title={t("sources_list_component:warning_icon")}
                placement="bottom"
                componentsProps={{
                  tooltip: {
                    sx: { maxWidth: "75vw" },
                  },
                }}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  {SourcesVerification.canSourceBeUsedForValue(source) ||
                  source.files.length > 0 ? null : (
                    <SvgIcon color="warning">
                      <WarningIcon />
                    </SvgIcon>
                  )}
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell sx={{ textAlign: "right", textWrap: "nowrap" }}>
              <IconButton size="small" onClick={() => onEdit(source)}>
                <EditIcon />
              </IconButton>
              <IconButton size="small" onClick={() => onDelete(source)}>
                <DeleteIcon />
              </IconButton>
              <IconButton size="small" onClick={() => onUpload(source)}>
                <EditFilesIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
