import { Box, CircularProgress } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useAuditLoggingQuery } from "../../modules/audit-logging/audit-logging.queries";
import { usePaginationState } from "../../modules/pagination/hooks/pagination-state.hook";
import { useSearchUsersQueryInternal } from "../../modules/user/users.queries";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { AuditLoggingConfiguration } from "../../modules/audit-logging/audit-logging-configuration.component";

interface IAuditLoggingConfigurationProps {}

export const AuditLoggingConfigurationPage: FC<IAuditLoggingConfigurationProps> = () => {
  const { organizationId } = useParams();

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/audit-logging`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/audit-logging`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );

  useCustomSideBarContent(sideBarConfiguration);

  const allUsersQuery = useSearchUsersQueryInternal(organizationId!);
  const result = usePaginationState();

  const auditLoggingQuery = useAuditLoggingQuery(
    organizationId,
    result.state.currentPage + 1,
    result.state.rowsPerPage,
  );

  const isLoading = allUsersQuery.isLoading || auditLoggingQuery.isLoading;
  const isError = allUsersQuery.isError || auditLoggingQuery.isError;
  const error = allUsersQuery.error || auditLoggingQuery.error;

  return (
    <Box p={4}>
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      )}
      {isError && <ErrorTextComponent error={error!} />}
      {!isLoading && !isError && (
        <AuditLoggingConfiguration
          auditLogs={auditLoggingQuery.data!}
          users={allUsersQuery.data!}
          pagination={result}
        />
      )}
    </Box>
  );
};
