import {
  IAddressedMatterE1Enum,
  IAddressedMatterE2Enum,
  IAddressedMatterE3Enum,
  IAddressedMatterE4Enum,
  IAddressedMatterE5Enum,
  IAddressedMatterS1Enum,
  IAddressedMatterS2Enum,
  IAddressedMatterS3Enum,
  IAddressedMatterS4Enum,
  IGroundsForDiscriminationEnum,
  ILayerInWasteHierarchy,
  IPolicyDataE1,
  IPolicyDataE2,
  IPolicyDataE3,
  IPolicyDataE4,
  IPolicyDataE5,
  IPolicyDataS,
  IPolicyDataS1,
  IPolicyDataS2,
  IPolicyDataS3,
  IPolicyDataS4,
  IPolicyGeneralData,
  ISustainableDevelopmentGoalEnum,
} from "@netcero/netcero-core-api-client";
import { IMDRInput } from "../minimum-disclosure-requirements-common/mdr-input.types";
import { WithoutNestedObjects } from "@netcero/netcero-common";
import { ISpecialPolicyTopicInputIdentifier } from "./policy-topic.component";

type GENERAL_KEY = keyof IPolicyGeneralData;
export const GENERAL_ORDER: (GENERAL_KEY | ISpecialPolicyTopicInputIdentifier)[] = [
  "name",
  "iros",
  "descriptionOfKeyContentsOfPolicyExplanatory",
  "descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory",
  "descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory",
  "disclosureOfThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicyExplanatory",
  "descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory",
  "policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementIt",
  "explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementItExplanatory",
];
export const GENERAL: { [key in GENERAL_KEY]: IMDRInput } = {
  name: {
    type: "text",
    initialValue: "",
    required: true,
    multiline: false,
  },
  descriptionOfKeyContentsOfPolicyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  disclosureOfThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicyExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementIt: {
    type: "boolean",
    initialValue: false,
  },
  explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementItExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type E1_KEY = keyof IPolicyDataE1;
export const E1_ORDER: E1_KEY[] = ["addressedMatters"];
export const E1: { [key in E1_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterE1Enum),
  },
};

export type E2_KEY = keyof IPolicyDataE2;
export const E2_ORDER: E2_KEY[] = [
  "addressedMatters",
  "policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil",
  "disclosureOfHowPolicyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoilExplanatory",
  "policyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcern",
  "disclosureOfHowPolicyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcernExplanatory",
  "policyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironment",
  "disclosureOfHowPolicyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironmentExplanatory",
];
export const E2: { [key in E2_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterE2Enum),
  },
  policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowPolicyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoilExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  policyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcern:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowPolicyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcernExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  policyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironment:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfHowPolicyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironmentExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type E3_KEY = keyof IPolicyDataE3;
export const E3_ORDER: E3_KEY[] = [
  "addressedMatters",
  "policyAddressesWaterManagement",
  "disclosureOfHowPolicyAddressesWaterManagementExplanatory",
  "policyAddressesUseAndSourcingOfWaterAndMarineResourcesInOwnOperations",
  "policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater",
  "policyAddressesPreventionAndAbatementOfWaterPollutionResultingFromActivities",
  "policyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResources",
  "disclosureOfHowPolicyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResourcesExplanatory",
  "policyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChain",
  "disclosureOfHowPolicyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChainExplanatory",
  "policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems",
  "policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources",
  "policyAimsAtEnhancedProtectionAndImprovementOfAquaticEnvironment",
  "policyPromotesGoodEnvironmentalStatusOfMarineWater",
  "policyPromotesReductionOfWaterWithdrawalsAndWaterDischarges",
  "policyContributesToGoodEcologicalAndChemicalQualityOfSurfaceWaterBodiesAndGoodChemicalQualityAndQuantityOfGroundwaterBodies",
  "policyMinimisesMaterialImpactsAndRisksAndImplementsMitigationMeasuresThatAimToMaintainValueAndFunctionalityOfPriorityServicesAndToIncreaseResourceEfficiencyOnOwnOperations",
  "policyAvoidsImpactsOnAffectedCommunities",
];
export const E3: { [key in E3_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterE3Enum),
  },
  policyAddressesWaterManagement: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowPolicyAddressesWaterManagementExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  policyAddressesUseAndSourcingOfWaterAndMarineResourcesInOwnOperations: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesPreventionAndAbatementOfWaterPollutionResultingFromActivities: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResources:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowPolicyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResourcesExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  policyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChain:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowPolicyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChainExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems: {
    type: "boolean",
    initialValue: false,
  },
  policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources: {
    type: "boolean",
    initialValue: false,
  },
  policyAimsAtEnhancedProtectionAndImprovementOfAquaticEnvironment: {
    type: "boolean",
    initialValue: false,
  },
  policyPromotesGoodEnvironmentalStatusOfMarineWater: {
    type: "boolean",
    initialValue: false,
  },
  policyPromotesReductionOfWaterWithdrawalsAndWaterDischarges: {
    type: "boolean",
    initialValue: false,
  },
  policyContributesToGoodEcologicalAndChemicalQualityOfSurfaceWaterBodiesAndGoodChemicalQualityAndQuantityOfGroundwaterBodies:
    {
      type: "boolean",
      initialValue: false,
    },
  policyMinimisesMaterialImpactsAndRisksAndImplementsMitigationMeasuresThatAimToMaintainValueAndFunctionalityOfPriorityServicesAndToIncreaseResourceEfficiencyOnOwnOperations:
    {
      type: "boolean",
      initialValue: false,
    },
  policyAvoidsImpactsOnAffectedCommunities: {
    type: "boolean",
    initialValue: false,
  },
};

export type E4_KEY = keyof IPolicyDataE4;
export const E4_ORDER: E4_KEY[] = [
  "addressedMatters",
  "disclosureOfHowPolicyRelatesToSustainabilityMattersForBiodiversityAndEcosystemsExplanatory",
  "disclosureOfHowPolicyRefersToProductionSourcingOrConsumptionOfRawMaterialsExplanatory",
  "disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory",
  "disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory",
  "disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory",
  "sustainableDevelopmentGoalsToWhichPolicyIsConnectedBiodiversityAndEcosystems",
  "disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory",
  "disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory",
  "disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory",
  "disclosureOfHowPolicyEnablesToMitigateContributionToMaterialBiodiversityLossDriversExplanatory",
  "thirdpartyStandardOfConductUsedInPolicyIsObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesAndRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances",
  "thirdpartyStandardOfConductUsedInPolicyIsDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsWithNoGroupHoldingUndueAuthorityOrVetoPowerOverContent",
  "thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime",
  "thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008",
  "thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice",
  "biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpacts",
  "disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpactsExplanatory",
  "biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunities",
  "disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunitiesExplanatory",
  "biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain",
  "disclosureOfHowBiodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChainExplanatory",
  "biodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversity",
  "disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory",
  "biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpacts",
  "disclosureOfHowBiodiversityAndEcosystemsRelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpactsExplanatory",
];
export const E4: { [key in E4_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterE4Enum),
  },
  disclosureOfHowPolicyRelatesToSustainabilityMattersForBiodiversityAndEcosystemsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  disclosureOfHowPolicyRefersToProductionSourcingOrConsumptionOfRawMaterialsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
    isParent: true,
  },
  disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isChild: true,
    },
  disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isChild: true,
    },
  disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isChild: true,
      isLastChild: true,
    },
  sustainableDevelopmentGoalsToWhichPolicyIsConnectedBiodiversityAndEcosystems: {
    type: "enum",
    initialValue: [],
    values: Object.values(ISustainableDevelopmentGoalEnum),
  },
  disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfHowPolicyEnablesToMitigateContributionToMaterialBiodiversityLossDriversExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  thirdpartyStandardOfConductUsedInPolicyIsObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesAndRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyIsDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsWithNoGroupHoldingUndueAuthorityOrVetoPowerOverContent:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice: {
    type: "boolean",
    initialValue: false,
  },
  biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpacts: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpactsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunities:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunitiesExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowBiodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChainExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  biodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversity:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpacts:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowBiodiversityAndEcosystemsRelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpactsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type E5_KEY = keyof IPolicyDataE5;
export const E5_ORDER: E5_KEY[] = [
  "addressedMatters",
  "layerInWasteHierarchyThatPolicyAddresses",
  "descriptionOfHowPolicyAddressesWasteHierarchyExplanatory",
  "policyAddressesTransitioningAwayFromUseOfVirginResources",
  "disclosureOfHowPolicyAddressesTransitioningAwayFromUseOfVirginResourcesExplanatory",
  "policyAddressesSustainableSourcingAndUseOfRenewableResources",
  "disclosureOfHowPolicyAddressesSustainableSourcingAndUseOfRenewableResourcesExplanatory",
  "policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatment",
  "disclosureOfHowPolicyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatmentExplanatory",
];
export const E5: { [key in E5_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterE5Enum),
  },
  layerInWasteHierarchyThatPolicyAddresses: {
    type: "enum",
    initialValue: [],
    values: Object.values(ILayerInWasteHierarchy),
  },
  descriptionOfHowPolicyAddressesWasteHierarchyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  policyAddressesTransitioningAwayFromUseOfVirginResources: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowPolicyAddressesTransitioningAwayFromUseOfVirginResourcesExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  policyAddressesSustainableSourcingAndUseOfRenewableResources: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowPolicyAddressesSustainableSourcingAndUseOfRenewableResourcesExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatment: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowPolicyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatmentExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type S_VALUES_ONLY = WithoutNestedObjects<IPolicyDataS>;
export type S_KEY = keyof S_VALUES_ONLY;
export const S_ORDER: S_KEY[] = [
  "groundsForDiscriminationThatAreSpecificallyCoveredByPolicy",
  "disclosureOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory",
  "specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace",
  "disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsExplanatory",
  "disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory",
];
export const S: { [key in S_KEY]: IMDRInput } = {
  groundsForDiscriminationThatAreSpecificallyCoveredByPolicy: {
    type: "enum",
    initialValue: [],
    values: Object.values(IGroundsForDiscriminationEnum),
  },
  disclosureOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type S1_KEY = keyof IPolicyDataS1;
export const S1_ORDER: S1_KEY[] = ["addressedMatters"];
export const S1: { [key in S1_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterS1Enum),
  },
};

export type S2_KEY = keyof IPolicyDataS2;
export const S2_ORDER: S2_KEY[] = ["addressedMatters"];
export const S2: { [key in S2_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterS2Enum),
  },
};

export type S3_KEY = keyof IPolicyDataS3;
export const S3_ORDER: S3_KEY[] = ["addressedMatters"];
export const S3: { [key in S3_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterS3Enum),
  },
};

export type S4_KEY = keyof IPolicyDataS4;
export const S4_ORDER: S4_KEY[] = ["addressedMatters"];
export const S4: { [key in S4_KEY]: IMDRInput } = {
  addressedMatters: {
    type: "enum",
    initialValue: [],
    values: Object.values(IAddressedMatterS4Enum),
  },
};

export const ALL_SECTIONS_CONFIG = {
  general: GENERAL,
  e1: E1,
  e2: E2,
  e3: E3,
  e4: E4,
  e5: E5,
  s: S,
  s1: S1,
  s2: S2,
  s3: S3,
  s4: S4,
};

export const ALL_SECTIONS_ORDER = {
  general: GENERAL_ORDER,
  e1: E1_ORDER,
  e2: E2_ORDER,
  e3: E3_ORDER,
  e4: E4_ORDER,
  e5: E5_ORDER,
  s: S_ORDER,
  s1: S1_ORDER,
  s2: S2_ORDER,
  s3: S3_ORDER,
  s4: S4_ORDER,
};
