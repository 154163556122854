import { IGetAllOrganizations200Response, IOrganization } from "@netcero/netcero-core-api-client";
import { ILocalOrganization } from "./local-organization.interface";
import { ILocalOrganizationsResponse } from "./local-organizations-response.interface";
import { DateTime } from "luxon";

export class OrganizationsUtilities {
  /**
   * Converts an IGetAllOrganizations200Response to an ILocalOrganizationsResponse
   * @param organizationsResponse The response to convert
   * @returns The converted response
   */
  public static convertToLocalOrganizationsResponse(
    organizationsResponse: IGetAllOrganizations200Response,
  ): ILocalOrganizationsResponse {
    return {
      ...organizationsResponse,
      organizations: organizationsResponse.organizations.map(
        OrganizationsUtilities.convertToLocalOrganization,
      ),
    };
  }

  /**
   * Converts an IOrganization to an ILocalOrganization
   * @param organization The organization to convert
   * @returns The converted organization
   */
  public static convertToLocalOrganization(organization: IOrganization): ILocalOrganization {
    return {
      ...organization,
      endOfBusinessYear: new Date(organization.endOfBusinessYear),
    };
  }

  /**
   * Gets the current business year for an organization.
   * @param organization The organization to get the current business year for.
   * @returns The current business year for the organization.
   */
  public static getCurrentBusinessYearForOrganization(organization: IOrganization): number {
    const endOfBusinessYear = DateTime.fromISO(organization.endOfBusinessYear);
    const now = DateTime.now();

    if (now.month > endOfBusinessYear.month) {
      return now.year + 1;
    }

    return now.year;
  }
}
