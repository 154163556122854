import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getRecordingPeriodRecordingStructuresQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
) => ["inputParameterRecordingStructure", organizationId, recordingPeriodId];
export const useRecordingPeriodRecordingStructuresQuery = (
  organizationId: string,
  recordingPeriodId: string,
) => {
  return useQuery({
    queryKey: getRecordingPeriodRecordingStructuresQueryKey(organizationId!, recordingPeriodId),
    queryFn: () => {
      return CoreApiService.InputParameterRecordingStructureApi.getAllInputParameterRecordingStructures(
        organizationId,
        recordingPeriodId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
  });
};

export const useInputParameterRecordingStructureInputParametersQuery = (
  organizationId: string,
  recordingPeriodId: string,
  recordingStructureId: string,
) => {
  return useQuery({
    queryKey: [
      "inputParameterRecordingStructureInputParameters",
      organizationId,
      recordingPeriodId,
      recordingStructureId,
    ],
    queryFn: () =>
      CoreApiService.InputParameterRecordingStructureApi.getAllInputParametersOfInputParameterRecordingStructure(
        organizationId,
        recordingPeriodId,
        recordingStructureId,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
};
