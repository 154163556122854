import { forwardRef, ForwardRefExoticComponent, RefAttributes, useMemo } from "react";
import { TablerIconsProps } from "@tabler/icons-react";
import { DataEntryObjectValuesUtilities } from "./utilities/data-entry-object-values.utilities";
import { IDEOIPValueDataQuality } from "@netcero/netcero-core-api-client";

interface IDataQualityIconComponentProps extends TablerIconsProps {
  dataQuality: IDEOIPValueDataQuality;
}

export const DataQualityIcon = forwardRef<
  ForwardRefExoticComponent<Omit<TablerIconsProps, "ref"> & RefAttributes<unknown>>,
  IDataQualityIconComponentProps
>(function DataQualityIcon({ dataQuality, ...props }, ref) {
  const IconComponent = useMemo(
    () => DataEntryObjectValuesUtilities.getIconForDataQuality(dataQuality),
    [dataQuality],
  );

  return <IconComponent ref={ref} {...props} />;
});
