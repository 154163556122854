import * as React from "react";
import type { Namespace, ParseKeys, TOptions } from "i18next";
import { _DefaultNamespace } from "react-i18next/TransWithoutContext";
import { Trans, TransProps } from "react-i18next";

type Components =
  | readonly React.ReactElement[]
  | { readonly [tagName: string]: React.ReactElement };

// TODO: add further components once needed
const DefaultComponents: Components = {
  italic: <em />,
  bold: <strong />,
  sub: <sub />,
};

/**
 * This component is used to pre-configure the components used in the translation.
 * It supports (thus far):
 * - italic (creates an <em> tag)
 * - bold (creates a <strong> tag)
 * @param props
 */
export const FormatTranslation = <
  Key extends ParseKeys<Ns, TOpt, KPrefix>,
  Ns extends Namespace = _DefaultNamespace,
  // eslint-disable-next-line @typescript-eslint/ban-types
  TOpt extends TOptions = {},
  KPrefix = undefined,
  E = React.HTMLProps<HTMLDivElement>,
>(
  props: TransProps<Key, Ns, TOpt, KPrefix, E>,
) => {
  const componentsToUse = props.components ?? {};

  const propsToUse = props.components
    ? props
    : {
        ...props,
        components: { ...DefaultComponents, ...componentsToUse },
      };

  return <Trans {...propsToUse} />;
};
