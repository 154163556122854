import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const useInputParameterRecordingStructureTemplatesQuery = (
  recordingModelId: string,
  templatesGroupId?: string | null,
) => {
  return useQuery({
    queryKey: ["inputParameterRecordingStructureTemplates", recordingModelId, templatesGroupId],
    queryFn: () =>
      CoreApiService.InputParameterRecordingStructureTemplateApi.getAllTemplates(
        recordingModelId,
        templatesGroupId!,
      )
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!templatesGroupId,
  });
};

export const useInputParameterRecordingStructureDetailsQuery = (
  organizationId: string,
  recordingPeriodId: string,
  recordingStructureId: string,
) => {
  return useQuery({
    queryKey: [
      "inputParameterRecordingStructureDetails",
      organizationId,
      recordingPeriodId,
      recordingStructureId,
    ],
    queryFn: () =>
      CoreApiService.InputParameterRecordingStructureApi.getInputParameterRecordingStructureDetails(
        organizationId,
        recordingPeriodId,
        recordingStructureId,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
};
