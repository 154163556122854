import { forwardRef, useMemo } from "react";
import { IDataEntryObjectInputParameterStatus } from "@netcero/netcero-core-api-client";
import { Box, Typography } from "@mui/material";
import {
  DATA_ENTRY_OBJECT_INPUT_PARAMETER_EXCLUDED_COLOR,
  DATA_ENTRY_OBJECT_INPUT_PARAMETER_STATUS_COLORS,
} from "../data-entry-object-input-parameter.constants";
import { useTranslation } from "react-i18next";
import {
  CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES,
  ClickableIndicatorWhiteOverlay,
} from "../../common/components/clickable-indicator-white-overlay.component";

interface IDataEntryObjectInputParameterStateIndicatorPropsBase {
  onClick?: VoidFunction;
  disabled?: boolean;
}

type IDataEntryObjectInputParameterStateIndicatorProps =
  IDataEntryObjectInputParameterStateIndicatorPropsBase &
    (
      | {
          status: IDataEntryObjectInputParameterStatus;
          excluded?: false;
        }
      | {
          status?: IDataEntryObjectInputParameterStatus;
          excluded: true;
        }
    );

export const DataEntryObjectInputParameterStatusIndicator = forwardRef<
  HTMLDivElement,
  IDataEntryObjectInputParameterStateIndicatorProps
>(function DataEntryObjectInputParameterStatusIndicator(
  { status, excluded, onClick, disabled },
  ref,
) {
  const { t } = useTranslation("data_entry_object_input_parameter_common");

  const statusColor = useMemo(
    () =>
      excluded
        ? DATA_ENTRY_OBJECT_INPUT_PARAMETER_EXCLUDED_COLOR
        : DATA_ENTRY_OBJECT_INPUT_PARAMETER_STATUS_COLORS[status],
    [excluded, status],
  );

  const isFilled = useMemo(() => !excluded, [excluded]);

  return (
    <Box
      ref={ref}
      onClick={!disabled ? onClick : undefined}
      bgcolor={isFilled ? statusColor : undefined}
      px={2}
      py={0.5}
      borderRadius={5}
      border={excluded ? `1px solid ${statusColor}` : undefined}
      overflow="hidden"
      sx={CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES}
    >
      <Typography variant="body2">
        {t(excluded ? "excluded_status" : `status.${status}.name`)}
      </Typography>
      <ClickableIndicatorWhiteOverlay disabled={disabled || !onClick} />
    </Box>
  );
});
