import React from "react";
import { Box, Typography } from "@mui/material";
import { Control, UseFormWatch } from "react-hook-form";
import { KeysForPolicy, PoliciesUtilities, RecursiveObjectPaths } from "@netcero/netcero-common";
import { MdrInputComponentFactory } from "../minimum-disclosure-requirements-common/components/mdr-input-component.factory";
import { IMDRInput } from "../minimum-disclosure-requirements-common/mdr-input.types";
import {
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IPolicyData,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { AssociatedIROsControllersComponent } from "../minimum-disclosure-requirements-common/components/associated-iros-controllers.component";

/** Special business logic components that will have custom handling */
export type ISpecialPolicyTopicInputIdentifier = "iros"; // Possibly extend this in the future
export type IPolicyTopicPrefix = RecursiveObjectPaths<IPolicyData>;

interface IPolicyTopicComponentProps {
  topicPrefix: IPolicyTopicPrefix;
  sectionTitle?: string;
  control: Control<IPolicyData>;
  watch: UseFormWatch<IPolicyData>;
  inputs: (string | ISpecialPolicyTopicInputIdentifier)[];
  inputsMetaData: Record<string, IMDRInput>;
  disabled: boolean;
  /** Only required if the section contains the special input "iros" */
  dmaCategories?: IDMACategoryWithEffectsAndManualFeedbacksAndChildren[];
}

export function PolicyTopicComponent({
  topicPrefix,
  sectionTitle,
  control,
  watch,
  inputs,
  inputsMetaData,
  disabled,
  dmaCategories,
}: IPolicyTopicComponentProps) {
  const { t } = useTranslation("data_entry_object_policy_component");
  const allProperties = watch();

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={1}>
      {sectionTitle && <Typography variant="h4">{sectionTitle}</Typography>}
      {inputs.map((propertyName) => {
        // Special Inputs
        if (propertyName === "iros") {
          return dmaCategories ? (
            <AssociatedIROsControllersComponent
              key={propertyName}
              dmaCategories={dmaCategories}
              disabled={disabled}
              label={t("iro_dropdown_text")}
            />
          ) : (
            <span key={propertyName}>
              "ERROR: Please provide dmaCategories prop for the special input 'iros'"
            </span>
          );
        }

        // Handle normal Inputs
        const inputMetaData = inputsMetaData[propertyName];
        const fullPropertyPath = `${topicPrefix}.${propertyName}` as KeysForPolicy;
        const isDependedUponByAnother =
          PoliciesUtilities.isInputDependedUponByAnother(fullPropertyPath);
        const isConditional = PoliciesUtilities.isInputConditional(fullPropertyPath);

        const InputComponent = MdrInputComponentFactory.createComponent<IPolicyData>(
          inputMetaData,
          {
            propertyPath: fullPropertyPath,
            label: t(`labels.${fullPropertyPath}`),
            control: control,
            isDependedUponByAnother: isDependedUponByAnother,
            isConditional: isConditional,
            getRuleTranslation: (property, rule) => t(`input_errors.${property}.${rule}`),
            disabled,
          },
          // ns separator is false because the enum key contains ':' which is the default ns separator
          (enumValueKey) => t(`enums.${fullPropertyPath}.${enumValueKey}`, { nsSeparator: false }),
        );

        return (
          PoliciesUtilities.isInputAvailable(fullPropertyPath, allProperties) && (
            <React.Fragment key={propertyName}>{InputComponent}</React.Fragment>
          )
        );
      })}
    </Box>
  );
}
