import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryWrapper } from "../common/components/query-wrapper.component";
import { IconSize, PlusIcon } from "../common/constants/tabler-icon.constants";
import { InputParameterRecordingStructuresUtilities } from "../input-parameter-recording-structures/input-parameter-recording-structures.utilities";
import { useCurrentOrganization } from "../organizations/use-current-organization.hook";
import { useRecordingModelsQuery } from "../recording-models/recording-models.queries";
import { useCurrentRecordingPeriod } from "../recording-periods/use-current-recording-period.hook";
import { AddRecordingStructureToRecordingPeriodDialog } from "../recording-structures/add-recording-structure-to-recording-period.dialog";
import { RecordingStructureCard } from "../recording-structures/recording-structure-card.component";
import { useRecordingPeriodRecordingStructuresQuery } from "../recording-structures/recording-structures.queries";
import { useTranslateOptionalContent } from "../content-translation/hooks/translate-content.hook";

interface IAddRecordingStructureDialogState {
  open: boolean;
  recordingModelId: string | null;
}

interface IRecordingPeriodRecordingStructuresCardsListProps {
  organizationId: string;
  recordingPeriodId: string;
}

export const RecordingPeriodRecordingStructuresCardsList: FC<
  IRecordingPeriodRecordingStructuresCardsListProps
> = ({ organizationId, recordingPeriodId }) => {
  const { t } = useTranslation("recording_period_recording_structures_cards_list");
  const translateContent = useTranslateOptionalContent();
  const currentOrganization = useCurrentOrganization();
  const currentRecordingPeriod = useCurrentRecordingPeriod();

  const recordingModelsQuery = useRecordingModelsQuery();

  const recordingStructuresQuery = useRecordingPeriodRecordingStructuresQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const recordingStructuresGroupedByRecordingModel = useMemo(() => {
    const result = recordingStructuresQuery.data
      ? [
          ...InputParameterRecordingStructuresUtilities.groupRecordingStructuresByRecordingModelId(
            recordingStructuresQuery.data.recordingStructures,
          ),
        ]
      : [];

    // At least add empty array for recording structures without recording model
    if (result.length === 0) {
      result.push([null, []]);
    }

    return result;
  }, [recordingStructuresQuery]);

  const [showAddInputParameterModelDialog, setShowAddInputParameterModelDialog] =
    useState<IAddRecordingStructureDialogState>({ open: false, recordingModelId: null });

  const categoryIdentifiersInUse = useMemo(
    () =>
      new Set(
        recordingStructuresQuery.data?.recordingStructures.map(
          (recordingStructure) => recordingStructure.categoryIdentifier,
        ) || [],
      ),
    [recordingStructuresQuery.data?.recordingStructures],
  );

  // Disabled for now since only ESRS after DMA is possible for now
  const handleOpenAddInputParameterModelDialog = (recordingModelId: string | null) => {
    setShowAddInputParameterModelDialog({
      open: true,
      recordingModelId,
    });
  };

  const orderNumbersInUse = useMemo(
    () =>
      recordingStructuresQuery.data
        ? InputParameterRecordingStructuresUtilities.extractRecordingModelsOrderValues(
            recordingStructuresQuery.data.recordingStructures,
          )
        : new Map<string | null, Set<number>>(),
    [recordingStructuresQuery],
  );

  return (
    <>
      {currentOrganization && currentRecordingPeriod && (
        <AddRecordingStructureToRecordingPeriodDialog
          open={showAddInputParameterModelDialog.open}
          onClose={() =>
            setShowAddInputParameterModelDialog({ open: false, recordingModelId: null })
          }
          organization={currentOrganization}
          recordingPeriod={currentRecordingPeriod}
          categoryIdentifiersInUse={categoryIdentifiersInUse}
          orderNumbersInUse={orderNumbersInUse}
          initiallySelectedRecordingModelId={showAddInputParameterModelDialog.recordingModelId}
        />
      )}
      <QueryWrapper
        query={recordingStructuresQuery}
        loadingOverride={() => (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        )}
        build={() => (
          <>
            {recordingStructuresGroupedByRecordingModel.map(([modelId, structures]) => (
              <Box key={modelId} mb={4}>
                <Typography variant="h2">
                  {translateContent(
                    recordingModelsQuery.data?.recordingModels.find((model) => model.id === modelId)
                      ?.name,
                  ) ?? t("structures_without_recording_model_header")}
                </Typography>
                <Divider />
                {/* Existing recording structures */}
                <Grid container spacing={2} columnSpacing={3} mt={2} alignItems="stretch">
                  {structures.map((recordingStructure) => (
                    <Grid key={recordingStructure.id} item xs={12} sm={6} lg={4}>
                      <RecordingStructureCard
                        organizationId={organizationId ?? ""}
                        recordingPeriodId={recordingPeriodId ?? ""}
                        recordingStructure={recordingStructure}
                      />
                    </Grid>
                  ))}
                  {/* Disabled for now since only ESRS after DMA is possible for now */}
                  {/* Button to add new recording structure */}
                  <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="stretch">
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        display: "flex",
                        gap: 1,
                        borderRadius: "24px",
                        width: "100%",
                        minHeight: "134px",
                      }}
                      onClick={() => handleOpenAddInputParameterModelDialog(modelId)}
                    >
                      <PlusIcon size={IconSize.Larger} />
                      <Typography color="primary">{t("add_category_button")}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        )}
      />
    </>
  );
};
