import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IBaseSourceData, ISource, ISourceType } from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../common/components/error-text.component";

const getFormDefaultValues = (source: IBaseSourceData | null): IBaseSourceData => {
  return {
    name: source?.name ?? "",
    description: source?.description ?? "",
    sourceType: source?.sourceType ?? ISourceType.DataSource,
    tags: source?.tags ?? [],
  };
};

interface ISourcesEditDialogProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  sources: ISource[];
  onClose: (data: IBaseSourceData | null) => void;
  editDialogData: IBaseSourceData | null;
}

export const ISourceDropdownEnum: { [variant in ISourceType]: string } = {
  [ISourceType.DataSource]: "sources:source_type.data_source",
  [ISourceType.CalculationSource]: "sources:source_type.calculation_source",
  [ISourceType.MethodologySource]: "sources:source_type.methodology_source",
} as const;

export const SourcesEditDialog: FC<ISourcesEditDialogProps> = ({
  disabled,
  onClose,
  error,
  open,
  loading,
  editDialogData,
}) => {
  const { t } = useTranslation(["sources", "sources_edit_dialog"]);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: getFormDefaultValues(editDialogData),
  });

  // Ensures fields in edit form are filled with data from proper source
  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(editDialogData));
    }
  }, [open, reset, editDialogData]);

  const handleEmitData = (data: IBaseSourceData) => {
    const payload: IBaseSourceData = {
      name: data.name.trim(),
      description: data.description ? data.description : undefined,
      sourceType: data.sourceType,
      tags: [],
    };
    onClose(payload);
  };

  return (
    <Dialog
      open={open}
      onClose={!isDirty ? () => onClose(null) : undefined}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {t(
          editDialogData !== null
            ? "sources_edit_dialog:title_edit"
            : "sources_edit_dialog:title_create",
        )}
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <Controller
            control={control}
            name="name"
            rules={{ required: t("sources_edit_dialog:errors.name_required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                required
                label={t("sources_edit_dialog:labels.name")}
                {...field}
                error={!!error}
                helperText={error?.message}
                disabled={disabled}
              />
            )}
          />

          <Controller
            name="sourceType"
            control={control}
            rules={{ required: t("sources_edit_dialog:errors.source_type_required") }}
            render={({ field, fieldState: { error } }) => (
              <FormControl variant="outlined" size="medium" error={!!error} fullWidth>
                <InputLabel id={t("sources_edit_dialog:labels.source_type")}>
                  {t("sources_edit_dialog:labels.source_type")}
                </InputLabel>
                <Select
                  required
                  label={t("sources_edit_dialog:labels.source_type")}
                  labelId="labels.source_type"
                  error={!!error}
                  {...field}
                >
                  {Object.keys(ISourceDropdownEnum).map((objectType, index) => (
                    <MenuItem key={objectType} value={objectType}>
                      {t(Object.values(ISourceDropdownEnum)[index])}
                    </MenuItem>
                  ))}
                </Select>
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t("sources_edit_dialog:labels.description")}
                multiline
                minRows={5}
                maxRows={15}
                {...field}
                error={!!error}
                helperText={error?.message}
                disabled={disabled}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose(null);
          }}
          disabled={disabled}
        >
          {t(isDirty ? "cancel" : "close", { ns: "buttons" })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(handleEmitData)}
          disabled={disabled || (editDialogData !== null && !isDirty)}
        >
          {t(editDialogData !== null ? "save" : "create", { ns: "buttons" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
