import { FC, useMemo } from "react";
import { Table, TableBody, TableCell, TableHead, Typography } from "@mui/material";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameter,
} from "@netcero/netcero-core-api-client";
import { useTranslateOptionalContent } from "../../../content-translation/hooks/translate-content.hook";
import {
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import { DataEntryObjectValuesInputTableEnteredValueRow } from "./data-entry-object-values-input-table-entered-value-row.component";
import { InputParametersUtilities } from "@netcero/netcero-common";
import { COLUMN_ALIGNMENTS_BY_TYPE } from "./data-entry-object-value-input-fields-table.constants";
import { IValuesErrorsPerKey } from "../../utilities/data-entry-object-values.utilities";
import { useTranslation } from "react-i18next";

interface IDataEntryObjectValuesInputFieldsTableProps {
  inputParameter: IInputParameter;
  values: (
    | ILocalDataEntryObjectInputParameterValue
    | ILocalDataEntryObjectInputParameterValueData
  )[];
  rowErrors: Partial<IValuesErrorsPerKey[]>;
  onValueChange: (
    rowIndex: number,
    valueKey: string,
    newValue: IDataEntryObjectInputParameterValueValueForKey,
  ) => void;
  onDeleteRow: (rowIndex: number) => void;
  disabled: boolean;
  editMode: boolean;
  onStartEditing: VoidFunction;
}

export const DataEntryObjectValuesInputTable: FC<IDataEntryObjectValuesInputFieldsTableProps> = ({
  inputParameter,
  values,
  rowErrors,
  onValueChange,
  onDeleteRow,
  disabled,
  editMode,
  onStartEditing,
}) => {
  const { t } = useTranslation("data_entry_object_values_input_table");
  const translateOptionalContent = useTranslateOptionalContent();
  const flatInputs = useMemo(
    () => inputParameter.uiConfiguration.rows.flatMap((row) => row.columns),
    [inputParameter.uiConfiguration.rows],
  );

  const valueConfigurationsLookup = useMemo(
    () =>
      InputParametersUtilities.getInputParameterValuesConfigurationLookup(inputParameter.values),
    [inputParameter.values],
  );

  const inputAlignments = useMemo(() => {
    return flatInputs.map((value) => {
      const config = valueConfigurationsLookup[value.valueKey];
      return config.valueConfiguration.type === "simple"
        ? COLUMN_ALIGNMENTS_BY_TYPE[config.valueConfiguration.configuration.type]
        : "center";
    });
  }, [flatInputs, valueConfigurationsLookup]);

  return (
    <>
      <Table size="small">
        <TableHead>
          {flatInputs.map((value, index) => (
            <TableCell key={value.valueKey} align={inputAlignments[index]}>
              {translateOptionalContent(value.label)}
            </TableCell>
          ))}
          {/* Actions */}
          <TableCell />
        </TableHead>
        <TableBody>
          {/* Table Rows */}
          {values.map((enteredValue, rowIndex) => (
            <DataEntryObjectValuesInputTableEnteredValueRow
              key={rowIndex}
              inputParameterValuesPerKey={valueConfigurationsLookup}
              inputs={flatInputs}
              inputAlignments={inputAlignments}
              enteredValue={enteredValue}
              rowErrors={rowErrors[rowIndex] ?? {}}
              editMode={editMode}
              disabled={disabled}
              onDelete={() => onDeleteRow(rowIndex)}
              onValueChange={(valueKey, newValue) => onValueChange(rowIndex, valueKey, newValue)}
              onStartEditing={onStartEditing}
            />
          ))}
        </TableBody>
      </Table>
      {/* Empty Message */}
      {values.length === 0 && (
        <Typography variant="body2" textAlign="center">
          {t("notice_empty")}
        </Typography>
      )}
    </>
  );
};
