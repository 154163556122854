import { Add } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import {
  IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../common/constants/tabler-icon.constants";
import { DMACategoryComponent } from "./dma-category.component";
import { useRenderESRSTopicName } from "./hooks/render-esrs-topic-name.hook";

interface IESRSTopicComponentProps {
  esrsTopic: IESRSTopic;
  showMaterialImpacts: boolean;
  showFinancialEffects: boolean;
  showEmpty: boolean;
  onlyViewCategory?: IDMACategoryWithEffectsAndManualFeedbacksAndChildren | null;
  onCreateMaterialImpact: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
  onCreateFinancialEffect: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
  onEditMaterialImpact: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
    materialImpact: IDMAMaterialImpact,
  ) => void;
  onEditFinancialEffect: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
    financialEffect: IDMAFinancialEffect,
  ) => void;
  onOptOutOfRecordingESRSTopic: (esrsTopic: IESRSTopic) => void;
  onStartDataRecordingForESRSTopic: (esrsTopic: IESRSTopic) => void;
  onEditOptOutReasonOfESRSTopic: (esrsTopic: IESRSTopic) => void;
  onCreateDMACategory: (esrsTopic: IESRSTopic, parentId: string | null) => void;
  onEditDMACategory: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
  onAddChildDMACategory: (
    esrsTopic: IESRSTopic,
    parentCategory: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
  onOptOutOfDMACategory: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
  onEditOptOutOfDMACategory: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
  onOptBackIntoDMACategory: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
  onOpenFeedbackDialog: (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndManualFeedbacksAndChildren,
  ) => void;
}

export const ESRSTopicComponent: FC<IESRSTopicComponentProps> = ({
  esrsTopic,
  showFinancialEffects,
  showMaterialImpacts,
  showEmpty = true,
  onlyViewCategory,
  onCreateMaterialImpact,
  onCreateFinancialEffect,
  onEditMaterialImpact,
  onEditFinancialEffect,
  onOptOutOfRecordingESRSTopic,
  onStartDataRecordingForESRSTopic,
  onEditOptOutReasonOfESRSTopic,
  onCreateDMACategory,
  onEditDMACategory,
  onAddChildDMACategory,
  onOptOutOfDMACategory,
  onEditOptOutOfDMACategory,
  onOptBackIntoDMACategory,
  onOpenFeedbackDialog,
}) => {
  const { t } = useTranslation("dma_esrs_topic_component");
  const [open, setOpen] = useState<boolean>(false);
  const renderTopicName = useRenderESRSTopicName();

  const content = useMemo(() => {
    const recordedESRSTopic = esrsTopic.recordedESRSTopic;

    if (
      // nothing was recorded yet
      !recordedESRSTopic ||
      // there has been a previous recording, but all categories were deleted by the user
      (recordedESRSTopic &&
        !recordedESRSTopic.optOut &&
        recordedESRSTopic.dmaCategories.length === 0)
    ) {
      return (
        <ESRSTopicNotYetRecordedComponent
          esrsTopic={esrsTopic}
          onOptOutOfRecordingESRSTopic={onOptOutOfRecordingESRSTopic}
          onStartDataRecordingForESRSTopic={onStartDataRecordingForESRSTopic}
        />
      );
    }

    if (recordedESRSTopic.optOut) {
      return (
        <ESRSTopicOptedOutComponent
          esrsTopic={esrsTopic}
          onStartDataRecordingForESRSTopic={onStartDataRecordingForESRSTopic}
          onEditOptOutReason={onEditOptOutReasonOfESRSTopic}
        />
      );
    }

    return (
      // This component should never be shown, but it is here for completeness
      <Box display="flex" flexDirection="column" gap={6}>
        {(onlyViewCategory ? [onlyViewCategory] : recordedESRSTopic.dmaCategories).map((child) => (
          <DMACategoryComponent
            key={child.id}
            category={child}
            showEmpty={showEmpty}
            showMaterialImpacts={showMaterialImpacts}
            showFinancialEffects={showFinancialEffects}
            onCreateMaterialImpact={(dmaCategory) => onCreateMaterialImpact(esrsTopic, dmaCategory)}
            onCreateFinancialEffect={(dmaCategory) =>
              onCreateFinancialEffect(esrsTopic, dmaCategory)
            }
            onEditMaterialImpact={(dmaCategory, materialImpact) =>
              onEditMaterialImpact(esrsTopic, dmaCategory, materialImpact)
            }
            onEditFinancialEffect={(dmaCategory, financialEffect) =>
              onEditFinancialEffect(esrsTopic, dmaCategory, financialEffect)
            }
            onEditDMACategory={(dmaCategory) => onEditDMACategory(esrsTopic, dmaCategory)}
            onAddChildDMACategory={(dmaCategory) => onAddChildDMACategory(esrsTopic, dmaCategory)}
            onOptOutOfDMACategory={(dmaCategory) => onOptOutOfDMACategory(esrsTopic, dmaCategory)}
            onEditOptOutOfDMACategory={(dmaCategory) =>
              onEditOptOutOfDMACategory(esrsTopic, dmaCategory)
            }
            onOptBackIntoDMACategory={(dmaCategory) =>
              onOptBackIntoDMACategory(esrsTopic, dmaCategory)
            }
            onClickFeedback={(dmaCategory) => onOpenFeedbackDialog(esrsTopic, dmaCategory)}
            setOpen={setOpen}
            open={open}
          />
        ))}
      </Box>
    );
  }, [
    esrsTopic,
    onlyViewCategory,
    onOptOutOfRecordingESRSTopic,
    onStartDataRecordingForESRSTopic,
    onEditOptOutReasonOfESRSTopic,
    showEmpty,
    showMaterialImpacts,
    showFinancialEffects,
    open,
    onCreateMaterialImpact,
    onCreateFinancialEffect,
    onEditMaterialImpact,
    onEditFinancialEffect,
    onEditDMACategory,
    onAddChildDMACategory,
    onOptOutOfDMACategory,
    onEditOptOutOfDMACategory,
    onOptBackIntoDMACategory,
    onOpenFeedbackDialog,
  ]);

  // Render Component

  return (
    <Box display="flex" gap={2} flexDirection="column">
      {content}
      {!onlyViewCategory && (
        <Tooltip
          title={t("create_category_button_tooltip", { esrsTopicName: renderTopicName(esrsTopic) })}
          placement="right"
        >
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => onCreateDMACategory(esrsTopic, null)}
            sx={{ alignSelf: "start" }}
          >
            {t("create_category_button")}
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

interface IESRSTopicNotYetRecordedComponentProps {
  esrsTopic: IESRSTopic;
  onOptOutOfRecordingESRSTopic: IESRSTopicComponentProps["onOptOutOfRecordingESRSTopic"];
  onStartDataRecordingForESRSTopic: IESRSTopicComponentProps["onStartDataRecordingForESRSTopic"];
}

const ESRSTopicNotYetRecordedComponent: FC<IESRSTopicNotYetRecordedComponentProps> = ({
  esrsTopic,
  onOptOutOfRecordingESRSTopic,
  onStartDataRecordingForESRSTopic,
}) => {
  const { t } = useTranslation("dma_esrs_topic_not_yet_recorded_component");
  const renderName = useRenderESRSTopicName();
  return (
    <Box display="flex" flexDirection="column" gap={1} alignItems="start">
      <Typography variant="subtitle1" component="div">
        {t("header", { name: renderName(esrsTopic), topic: esrsTopic?.topic })}
      </Typography>
      <Box display="flex" flexDirection="row" gap={2}>
        {esrsTopic.currentTemplate && esrsTopic.currentTemplate.categories.length > 0 && (
          <Tooltip title={t("start_collection_button_tooltip")} placement="bottom">
            <Button
              variant="outlined"
              color="success"
              onClick={() => onStartDataRecordingForESRSTopic(esrsTopic)}
            >
              {t("start_collection_button")}
            </Button>
          </Tooltip>
        )}
        <Tooltip title={t("skip_collection_button_tooltip")} placement="bottom">
          <Button
            variant="outlined"
            color="error"
            onClick={() => onOptOutOfRecordingESRSTopic(esrsTopic)}
          >
            {t("skip_collection_button")}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

interface IESRSTopicOptedOutComponentProps {
  esrsTopic: IESRSTopic;
  onStartDataRecordingForESRSTopic: IESRSTopicComponentProps["onStartDataRecordingForESRSTopic"];
  onEditOptOutReason: (esrsTopic: IESRSTopic) => void;
}

const ESRSTopicOptedOutComponent: FC<IESRSTopicOptedOutComponentProps> = ({
  esrsTopic,
  onStartDataRecordingForESRSTopic,
  onEditOptOutReason,
}) => {
  const { t } = useTranslation("dma_esrs_topic_opted_out_component");
  const renderName = useRenderESRSTopicName();
  return (
    <Box display="flex" flexDirection="column" gap={1} alignItems="start">
      {/* Header */}
      <Typography variant="subtitle1" component="div">
        {t("header", { name: renderName(esrsTopic), topic: esrsTopic?.topic })}
      </Typography>
      {/* Opt Out Reason */}
      <Box display="flex" gap={2} mb={1}>
        <Typography fontWeight="bold" mt={0.15}>
          {t("opt_out_reason_prefix")}
        </Typography>
        <Typography mt={0.15}>{esrsTopic.recordedESRSTopic?.optOutReason}</Typography>
        <Tooltip title={t("edit_opt_out_reason_button_tooltip")} placement="right">
          <IconButton size="small" onClick={() => onEditOptOutReason(esrsTopic)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {/* Start Recording - only show if template is available */}
      {esrsTopic.currentTemplate && esrsTopic.currentTemplate.categories.length > 0 && (
        <Tooltip title={t("start_collection_button_tooltip")} placement="right">
          <Button
            variant="outlined"
            color="success"
            onClick={() => onStartDataRecordingForESRSTopic(esrsTopic)}
          >
            {t("start_collection_button")}
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};
