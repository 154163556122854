import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum,
  IPolicy,
  IPolicyData,
  IPolicyDataS,
  IPolicyGeneralData,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import {
  ALL_SECTIONS_CONFIG,
  ALL_SECTIONS_ORDER,
  GENERAL,
  S_VALUES_ONLY,
} from "../policy-inputs.constants";
import { DeleteIcon } from "../../common/constants/tabler-icon.constants";
import { PolicyTopicComponent } from "../policy-topic.component";
import { MdrUtilities } from "../../minimum-disclosure-requirements-common/mdr.utilities";
import { PoliciesUtilities } from "@netcero/netcero-common";
import { useDMACategoriesQuery } from "../../double-materiality-assessment/dma.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";

interface IPolicyEditDialogProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IPolicyData | null) => void;
  onDelete: (policyId: string, confirm?: boolean) => void;
  policy?: IPolicy;
  topicIdentifier: IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum;
  organizationId: string;
  dataEntryObjectId: string;
  recordingPeriodId: string;
}

export type PolicySDataOnly = Omit<IPolicyDataS, "s1" | "s2" | "s3" | "s4">;

export type IPolicyEditDialogData = IPolicyData;

export type IEditDialogSectionKey = "e1" | "e2" | "e3" | "e4" | "e5" | "s1" | "s2" | "s3" | "s4";

function getIdentifierKeyForTopic(
  topicIdentifier: IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum,
): IEditDialogSectionKey {
  switch (topicIdentifier) {
    case "esrs_e1":
      return "e1";
    case "esrs_e2":
      return "e2";
    case "esrs_e3":
      return "e3";
    case "esrs_e4":
      return "e4";
    case "esrs_e5":
      return "e5";
    case "esrs_s1":
      return "s1";
    case "esrs_s2":
      return "s2";
    case "esrs_s3":
      return "s3";
    case "esrs_s4":
      return "s4";
  }
}

function isSTopic(identifier: IEditDialogSectionKey): identifier is "s1" | "s2" | "s3" | "s4" {
  return identifier.startsWith("s");
}

function isETopic(
  identifier: IEditDialogSectionKey,
): identifier is "e1" | "e2" | "e3" | "e4" | "e5" {
  return identifier.startsWith("e");
}

const getFormDefaultValues = (
  policy: IPolicy | undefined,
  identifier: IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum,
): IPolicyEditDialogData => {
  const identifierKey = getIdentifierKeyForTopic(identifier);

  const result: IPolicyEditDialogData = {
    ...(policy ? PoliciesUtilities.convertPolicyToPolicyData(policy) : {}),
    materialImpactIds: policy?.materialImpactIds ?? [],
    financialEffectIds: policy?.financialEffectIds ?? [],
    general: MdrUtilities.getDefaultValuesForConfiguration<IPolicyGeneralData>(
      GENERAL,
      policy?.general,
    ),
  };

  if (
    // S Sections
    isSTopic(identifierKey)
  ) {
    // S Sections general
    result.s = MdrUtilities.getDefaultValuesForConfiguration<PolicySDataOnly>(
      ALL_SECTIONS_CONFIG["s"],
      policy?.s,
    );
    // S Sections specifics
    result.s[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      policy?.s?.[identifierKey],
    ) as never;
  } else if (
    // E Sections
    isETopic(identifierKey)
  ) {
    result[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      policy?.[identifierKey],
    ) as never;
  }

  return result;
};
export const PolicyEditDialog: FC<IPolicyEditDialogProps> = ({
  disabled,
  onClose,
  onDelete,
  error,
  open,
  loading,
  policy,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
}) => {
  const mode = policy ? "edit" : "create";
  const { t } = useTranslation("data_entry_object_policy_component");
  const identifierKey = useMemo(() => getIdentifierKeyForTopic(topicIdentifier), [topicIdentifier]);

  const dmaCategoriesQuery = useDMACategoriesQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
  );

  const useFormResult = useForm<IPolicyEditDialogData>({
    defaultValues: getFormDefaultValues(policy, topicIdentifier),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    watch,
  } = useFormResult;

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(policy, topicIdentifier));
    }
  }, [open, reset, policy, topicIdentifier]);

  const handleEmitData = useCallback(
    (data: IPolicyEditDialogData) => {
      const result = {
        ...data,
        general: MdrUtilities.convertToApiPayload(data.general, GENERAL),
        financialEffectIds: data.financialEffectIds,
        materialImpactIds: data.materialImpactIds,
      };

      if (isSTopic(identifierKey)) {
        result.s = {
          ...data.s!,
          ...MdrUtilities.convertToApiPayload<S_VALUES_ONLY>(data.s!, ALL_SECTIONS_CONFIG["s"]),
          [identifierKey]: MdrUtilities.convertToApiPayload(
            data.s![identifierKey],
            ALL_SECTIONS_CONFIG[identifierKey],
          ),
        };
      } else if (isETopic(identifierKey)) {
        result[identifierKey] = MdrUtilities.convertToApiPayload(
          data[identifierKey],
          ALL_SECTIONS_CONFIG[identifierKey],
        ) as never;
      } else {
        throw new Error("Unknown/Unhandled topic identifier");
      }

      onClose(result);
    },
    [identifierKey, onClose],
  );

  return (
    <Dialog
      open={open}
      onClose={!isDirty ? () => onClose(null) : undefined}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* Actual title*/}
          {t(mode === "create" ? "create_policy" : "edit_policy")}
          {/* Delete button */}
          {policy !== undefined && (
            <Tooltip title={t("delete_policy_tooltip")}>
              <span>
                <IconButton onClick={() => onDelete(policy.id, true)} disabled={loading}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent sx={{ height: "80vh" }}>
        {error && <ErrorTextComponent error={error} />}

        {/* Associated IROs*/}
        <FormProvider {...useFormResult}>
          <QueryWrapper
            query={dmaCategoriesQuery}
            loadingOverride={() => <Skeleton variant="rounded" height={56} sx={{ my: 2 }} />}
            build={(dmaCategories) => (
              <PolicyTopicComponent
                topicPrefix="general"
                control={control}
                inputs={ALL_SECTIONS_ORDER["general"]}
                inputsMetaData={ALL_SECTIONS_CONFIG["general"]}
                watch={watch}
                disabled={loading}
                dmaCategories={dmaCategories}
              />
            )}
          />
        </FormProvider>

        <Divider sx={{ my: 3 }} />

        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        {isSTopic(identifierKey) ? (
          <>
            <PolicyTopicComponent
              sectionTitle={t("section_titles.esrs_s")}
              topicPrefix="s"
              control={control}
              inputs={ALL_SECTIONS_ORDER["s"]}
              inputsMetaData={ALL_SECTIONS_CONFIG["s"]}
              watch={watch}
              disabled={loading}
            />
            <PolicyTopicComponent
              sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
              topicPrefix={`s.${identifierKey}`}
              control={control}
              inputs={ALL_SECTIONS_ORDER[identifierKey]}
              inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
              watch={watch}
              disabled={loading}
            />
          </>
        ) : (
          <PolicyTopicComponent
            sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
            topicPrefix={identifierKey}
            control={control}
            inputs={ALL_SECTIONS_ORDER[identifierKey]}
            inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
            watch={watch}
            disabled={loading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(null)} disabled={disabled}>
          {t(isDirty ? "cancel" : "close", { ns: "buttons" })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(handleEmitData)}
          disabled={disabled || !isDirty}
        >
          {t(mode === "edit" ? "save" : "create", { ns: "buttons" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
