export type IAppSnackbarEnqueueApiMessageAction = IAppSnackbarEnqueueBasicSuccessMessageData;

interface IAppSnackbarEnqueueSuccessMessageDataBase<T extends object> {
  data: T;
}

export enum BasicSnackbarApiActionType {
  // Disclosure Requirements
  UPDATE_DISCLOSURE_REQUIREMENT_VALUE = "UPDATE_DISCLOSURE_REQUIREMENT_VALUE",
  UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS = "UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS",
  UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER = "UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER",
  // DR Submission Process
  SUBMIT_DISCLOSURE_REQUIREMENT = "SUBMIT_DISCLOSURE_REQUIREMENT",
  APPROVE_DISCLOSURE_REQUIREMENT = "APPROVE_DISCLOSURE_REQUIREMENT",
  REJECT_DISCLOSURE_REQUIREMENT = "REJECT_DISCLOSURE_REQUIREMENT",
  SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  // Input Parameter Values
  UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE = "UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE",
  UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS = "UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS",
  UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER = "UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER",
  // Exclusion
  EXCLUDE_DISCLOSURE_REQUIREMENT = "EXCLUDE_DISCLOSURE_REQUIREMENT",
  INCLUDE_DISCLOSURE_REQUIREMENT = "INCLUDE_DISCLOSURE_REQUIREMENT",
  EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  // Phase In Decisions
  UPDATE_PHASE_IN_DECISION = "UPDATE_PHASE_IN_DECISION",
  // DMA
  EXPORT_DMA_IROS = "EXPORT_DMA_IROS",
}

export type SnackbarSuccessMassageType = keyof typeof BasicSnackbarApiActionType;

export interface IAppSnackbarEnqueueBasicSuccessMessageData
  extends Partial<IAppSnackbarEnqueueSuccessMessageDataBase<never>> {
  type: BasicSnackbarApiActionType;
}
