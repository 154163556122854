/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IAddressedMatterS3Enum = {
    CommunitiesEconomicSocialAndCulturalRightsMemberEsrsAdequateHousingForAffectedCommunitiesMember: 'esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateHousingForAffectedCommunitiesMember',
    CommunitiesEconomicSocialAndCulturalRightsMemberEsrsAdequateFoodMember: 'esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateFoodMember',
    CommunitiesEconomicSocialAndCulturalRightsMemberEsrsWaterAndSanitationForAffectedCommunitiesMember: 'esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:WaterAndSanitationForAffectedCommunitiesMember',
    CommunitiesEconomicSocialAndCulturalRightsMemberEsrsLandrelatedImpactsMember: 'esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:LandrelatedImpactsMember',
    CommunitiesEconomicSocialAndCulturalRightsMemberEsrsSecurityrelatedImpactsMember: 'esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:SecurityrelatedImpactsMember',
    CommunitiesCivilAndPoliticalRightsMemberEsrsFreedomOfExpressionForAffectedCommunitiesMember: 'esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfExpressionForAffectedCommunitiesMember',
    CommunitiesCivilAndPoliticalRightsMemberEsrsFreedomOfAssemblyMember: 'esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfAssemblyMember',
    CommunitiesCivilAndPoliticalRightsMemberEsrsImpactsOnHumanRightsDefendersMember: 'esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:ImpactsOnHumanRightsDefendersMember',
    RightsOfIndigenousPeoplesMemberEsrsFreePriorAndInformedConsentMember: 'esrs:RightsOfIndigenousPeoplesMember.esrs:FreePriorAndInformedConsentMember',
    RightsOfIndigenousPeoplesMemberEsrsSelfdeterminationMember: 'esrs:RightsOfIndigenousPeoplesMember.esrs:SelfdeterminationMember',
    RightsOfIndigenousPeoplesMemberEsrsCulturalRightsMember: 'esrs:RightsOfIndigenousPeoplesMember.esrs:CulturalRightsMember',
    OtherAffectedCommunitiesSubtopicMember: 'esrs:OtherAffectedCommunitiesSubtopicMember'
} as const;

export type IAddressedMatterS3Enum = typeof IAddressedMatterS3Enum[keyof typeof IAddressedMatterS3Enum];



