/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IAddEsrsCategory404Response } from '../models';
// @ts-ignore
import { IAddEsrsCategory409Response } from '../models';
// @ts-ignore
import { ICreateDMACategoryRequest } from '../models';
// @ts-ignore
import { ICreateDMACategoryResponse } from '../models';
// @ts-ignore
import { IDMACategory } from '../models';
// @ts-ignore
import { IDeleteEsrsCategory409Response } from '../models';
// @ts-ignore
import { IGetDataEntryObject404Response } from '../models';
// @ts-ignore
import { IOptOutOfDmaCategory409Response } from '../models';
// @ts-ignore
import { IOptOutOfDmaCategoryRequest } from '../models';
// @ts-ignore
import { IRequestEsrsCategoryFeedback404Response } from '../models';
// @ts-ignore
import { IRequestEsrsCategoryFeedback409Response } from '../models';
// @ts-ignore
import { IRequestFeedbackForDmaCategoryRequest } from '../models';
// @ts-ignore
import { IRevokeEsrsCategoryFeedbackRequest404Response } from '../models';
// @ts-ignore
import { IRevokeEsrsCategoryFeedbackRequest409Response } from '../models';
// @ts-ignore
import { IUpdateDMACategoryRequest } from '../models';
// @ts-ignore
import { IUpdateEsrsCategory409Response } from '../models';
// @ts-ignore
import { IVerifyStakeholderFeedback404Response } from '../models';
// @ts-ignore
import { IVerifyStakeholderFeedback409Response } from '../models';
// @ts-ignore
import { IVerifyStakeholderFeedbackRequest } from '../models';
/**
 * DoubleMaterialityAssessmentCategoryApi - axios parameter creator
 * @export
 */
export const DoubleMaterialityAssessmentCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
         * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEsrsCategory: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, iCreateDMACategoryRequest: ICreateDMACategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('addEsrsCategory', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('addEsrsCategory', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('addEsrsCategory', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('addEsrsCategory', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'iCreateDMACategoryRequest' is not null or undefined
            assertParamExists('addEsrsCategory', 'iCreateDMACategoryRequest', iCreateDMACategoryRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCreateDMACategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a single DMA category.
         * @summary Deletes a single DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
         * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
         * @param {string} dmaCategoryId The id of the category to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEsrsCategory: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteEsrsCategory', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('deleteEsrsCategory', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('deleteEsrsCategory', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('deleteEsrsCategory', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('deleteEsrsCategory', 'dmaCategoryId', dmaCategoryId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Frees the user from having to specify effects for the specified data entry object and DMA category.
         * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
         * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
         * @param {string} dmaCategoryId The id of the category to opt out of.
         * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optOutOfDmaCategory: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('optOutOfDmaCategory', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('optOutOfDmaCategory', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('optOutOfDmaCategory', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('optOutOfDmaCategory', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('optOutOfDmaCategory', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'iOptOutOfDmaCategoryRequest' is not null or undefined
            assertParamExists('optOutOfDmaCategory', 'iOptOutOfDmaCategoryRequest', iOptOutOfDmaCategoryRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/opt-out`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOptOutOfDmaCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request feedback for a DMA category.
         * @summary Request feedback for a DMA category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEsrsCategoryFeedback: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('requestEsrsCategoryFeedback', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('requestEsrsCategoryFeedback', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('requestEsrsCategoryFeedback', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('requestEsrsCategoryFeedback', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('requestEsrsCategoryFeedback', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'iRequestFeedbackForDmaCategoryRequest' is not null or undefined
            assertParamExists('requestEsrsCategoryFeedback', 'iRequestFeedbackForDmaCategoryRequest', iRequestFeedbackForDmaCategoryRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/request-feedback`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRequestFeedbackForDmaCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke feedback request for a DMA category.
         * @summary Revoke feedback request for a DMA category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeEsrsCategoryFeedbackRequest: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('revokeEsrsCategoryFeedbackRequest', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('revokeEsrsCategoryFeedbackRequest', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('revokeEsrsCategoryFeedbackRequest', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('revokeEsrsCategoryFeedbackRequest', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('revokeEsrsCategoryFeedbackRequest', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'iRequestFeedbackForDmaCategoryRequest' is not null or undefined
            assertParamExists('revokeEsrsCategoryFeedbackRequest', 'iRequestFeedbackForDmaCategoryRequest', iRequestFeedbackForDmaCategoryRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/revoke-feedback`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRequestFeedbackForDmaCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a single DMA category.
         * @summary Updates a single DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
         * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
         * @param {string} dmaCategoryId The id of the category to update.
         * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEsrsCategory: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iUpdateDMACategoryRequest: IUpdateDMACategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateEsrsCategory', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('updateEsrsCategory', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('updateEsrsCategory', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('updateEsrsCategory', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('updateEsrsCategory', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'iUpdateDMACategoryRequest' is not null or undefined
            assertParamExists('updateEsrsCategory', 'iUpdateDMACategoryRequest', iUpdateDMACategoryRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUpdateDMACategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify the stakeholder feedback for a given DMA category.
         * @summary Verify the stakeholder feedback for a given DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
         * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
         * @param {string} dmaCategoryId The id of the category to verify feedback for.
         * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyStakeholderFeedback: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('verifyStakeholderFeedback', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('verifyStakeholderFeedback', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('verifyStakeholderFeedback', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'esrsTopicId' is not null or undefined
            assertParamExists('verifyStakeholderFeedback', 'esrsTopicId', esrsTopicId)
            // verify required parameter 'dmaCategoryId' is not null or undefined
            assertParamExists('verifyStakeholderFeedback', 'dmaCategoryId', dmaCategoryId)
            // verify required parameter 'iVerifyStakeholderFeedbackRequest' is not null or undefined
            assertParamExists('verifyStakeholderFeedback', 'iVerifyStakeholderFeedbackRequest', iVerifyStakeholderFeedbackRequest)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/verify-stakeholder-feedback`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
                .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
                .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iVerifyStakeholderFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoubleMaterialityAssessmentCategoryApi - functional programming interface
 * @export
 */
export const DoubleMaterialityAssessmentCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoubleMaterialityAssessmentCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
         * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, iCreateDMACategoryRequest: ICreateDMACategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICreateDMACategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, iCreateDMACategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a single DMA category.
         * @summary Deletes a single DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
         * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
         * @param {string} dmaCategoryId The id of the category to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Frees the user from having to specify effects for the specified data entry object and DMA category.
         * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
         * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
         * @param {string} dmaCategoryId The id of the category to opt out of.
         * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optOutOfDmaCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optOutOfDmaCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iOptOutOfDmaCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request feedback for a DMA category.
         * @summary Request feedback for a DMA category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestEsrsCategoryFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestEsrsCategoryFeedback(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iRequestFeedbackForDmaCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Revoke feedback request for a DMA category.
         * @summary Revoke feedback request for a DMA category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeEsrsCategoryFeedbackRequest(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeEsrsCategoryFeedbackRequest(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iRequestFeedbackForDmaCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a single DMA category.
         * @summary Updates a single DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
         * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
         * @param {string} dmaCategoryId The id of the category to update.
         * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iUpdateDMACategoryRequest: IUpdateDMACategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMACategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iUpdateDMACategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verify the stakeholder feedback for a given DMA category.
         * @summary Verify the stakeholder feedback for a given DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
         * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
         * @param {string} dmaCategoryId The id of the category to verify feedback for.
         * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyStakeholderFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyStakeholderFeedback(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iVerifyStakeholderFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoubleMaterialityAssessmentCategoryApi - factory interface
 * @export
 */
export const DoubleMaterialityAssessmentCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoubleMaterialityAssessmentCategoryApiFp(configuration)
    return {
        /**
         * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
         * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, iCreateDMACategoryRequest: ICreateDMACategoryRequest, options?: any): AxiosPromise<ICreateDMACategoryResponse> {
            return localVarFp.addEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, iCreateDMACategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a single DMA category.
         * @summary Deletes a single DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
         * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
         * @param {string} dmaCategoryId The id of the category to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Frees the user from having to specify effects for the specified data entry object and DMA category.
         * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
         * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
         * @param {string} dmaCategoryId The id of the category to opt out of.
         * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optOutOfDmaCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.optOutOfDmaCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iOptOutOfDmaCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Request feedback for a DMA category.
         * @summary Request feedback for a DMA category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEsrsCategoryFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.requestEsrsCategoryFeedback(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iRequestFeedbackForDmaCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke feedback request for a DMA category.
         * @summary Revoke feedback request for a DMA category.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {string} esrsTopicId The id of the ESRS topic to use.
         * @param {string} dmaCategoryId The id of the category to use.
         * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeEsrsCategoryFeedbackRequest(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.revokeEsrsCategoryFeedbackRequest(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iRequestFeedbackForDmaCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a single DMA category.
         * @summary Updates a single DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
         * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
         * @param {string} dmaCategoryId The id of the category to update.
         * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iUpdateDMACategoryRequest: IUpdateDMACategoryRequest, options?: any): AxiosPromise<IDMACategory> {
            return localVarFp.updateEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iUpdateDMACategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify the stakeholder feedback for a given DMA category.
         * @summary Verify the stakeholder feedback for a given DMA category.
         * @param {string} organizationId The id of the organization the data entry object belongs to.
         * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
         * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
         * @param {string} dmaCategoryId The id of the category to verify feedback for.
         * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyStakeholderFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest, options?: any): AxiosPromise<void> {
            return localVarFp.verifyStakeholderFeedback(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iVerifyStakeholderFeedbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoubleMaterialityAssessmentCategoryApi - interface
 * @export
 * @interface DoubleMaterialityAssessmentCategoryApi
 */
export interface DoubleMaterialityAssessmentCategoryApiInterface {
    /**
     * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApiInterface
     */
    addEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, iCreateDMACategoryRequest: ICreateDMACategoryRequest, options?: AxiosRequestConfig): AxiosPromise<ICreateDMACategoryResponse>;

    /**
     * Deletes a single DMA category.
     * @summary Deletes a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
     * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
     * @param {string} dmaCategoryId The id of the category to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApiInterface
     */
    deleteEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to opt out of.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApiInterface
     */
    optOutOfDmaCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Request feedback for a DMA category.
     * @summary Request feedback for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApiInterface
     */
    requestEsrsCategoryFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Revoke feedback request for a DMA category.
     * @summary Revoke feedback request for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApiInterface
     */
    revokeEsrsCategoryFeedbackRequest(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates a single DMA category.
     * @summary Updates a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
     * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
     * @param {string} dmaCategoryId The id of the category to update.
     * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApiInterface
     */
    updateEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iUpdateDMACategoryRequest: IUpdateDMACategoryRequest, options?: AxiosRequestConfig): AxiosPromise<IDMACategory>;

    /**
     * Verify the stakeholder feedback for a given DMA category.
     * @summary Verify the stakeholder feedback for a given DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to verify feedback for.
     * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApiInterface
     */
    verifyStakeholderFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DoubleMaterialityAssessmentCategoryApi - object-oriented interface
 * @export
 * @class DoubleMaterialityAssessmentCategoryApi
 * @extends {BaseAPI}
 */
export class DoubleMaterialityAssessmentCategoryApi extends BaseAPI implements DoubleMaterialityAssessmentCategoryApiInterface {
    /**
     * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApi
     */
    public addEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, iCreateDMACategoryRequest: ICreateDMACategoryRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentCategoryApiFp(this.configuration).addEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, iCreateDMACategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a single DMA category.
     * @summary Deletes a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
     * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
     * @param {string} dmaCategoryId The id of the category to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApi
     */
    public deleteEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentCategoryApiFp(this.configuration).deleteEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to opt out of.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApi
     */
    public optOutOfDmaCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentCategoryApiFp(this.configuration).optOutOfDmaCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iOptOutOfDmaCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request feedback for a DMA category.
     * @summary Request feedback for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApi
     */
    public requestEsrsCategoryFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentCategoryApiFp(this.configuration).requestEsrsCategoryFeedback(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iRequestFeedbackForDmaCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Revoke feedback request for a DMA category.
     * @summary Revoke feedback request for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApi
     */
    public revokeEsrsCategoryFeedbackRequest(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentCategoryApiFp(this.configuration).revokeEsrsCategoryFeedbackRequest(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iRequestFeedbackForDmaCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a single DMA category.
     * @summary Updates a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
     * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
     * @param {string} dmaCategoryId The id of the category to update.
     * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApi
     */
    public updateEsrsCategory(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iUpdateDMACategoryRequest: IUpdateDMACategoryRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentCategoryApiFp(this.configuration).updateEsrsCategory(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iUpdateDMACategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify the stakeholder feedback for a given DMA category.
     * @summary Verify the stakeholder feedback for a given DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to verify feedback for.
     * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoubleMaterialityAssessmentCategoryApi
     */
    public verifyStakeholderFeedback(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, esrsTopicId: string, dmaCategoryId: string, iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest, options?: AxiosRequestConfig) {
        return DoubleMaterialityAssessmentCategoryApiFp(this.configuration).verifyStakeholderFeedback(organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId, dmaCategoryId, iVerifyStakeholderFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
