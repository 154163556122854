/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IBaseESRSTopicPhaseInDecisionData } from '../models';
// @ts-ignore
import { IGetAllPhaseInDecisionsResponse } from '../models';
// @ts-ignore
import { IGetDataEntryObject404Response } from '../models';
// @ts-ignore
import { IModifyPhaseInDecisionForDeo409Response } from '../models';
/**
 * ESRSTopicPhaseInDecisionsApi - axios parameter creator
 * @export
 */
export const ESRSTopicPhaseInDecisionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint retrieves all reference budgets for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhaseInDecisionsForDeo: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getPhaseInDecisionsForDeo', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('getPhaseInDecisionsForDeo', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('getPhaseInDecisionsForDeo', 'dataEntryObjectId', dataEntryObjectId)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/phase-in`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to modify phase in decision for topic
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseESRSTopicPhaseInDecisionData} iBaseESRSTopicPhaseInDecisionData Used to modify phase in decision for topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPhaseInDecisionForDeo: async (organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseESRSTopicPhaseInDecisionData: IBaseESRSTopicPhaseInDecisionData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('modifyPhaseInDecisionForDeo', 'organizationId', organizationId)
            // verify required parameter 'recordingPeriodId' is not null or undefined
            assertParamExists('modifyPhaseInDecisionForDeo', 'recordingPeriodId', recordingPeriodId)
            // verify required parameter 'dataEntryObjectId' is not null or undefined
            assertParamExists('modifyPhaseInDecisionForDeo', 'dataEntryObjectId', dataEntryObjectId)
            // verify required parameter 'iBaseESRSTopicPhaseInDecisionData' is not null or undefined
            assertParamExists('modifyPhaseInDecisionForDeo', 'iBaseESRSTopicPhaseInDecisionData', iBaseESRSTopicPhaseInDecisionData)
            const localVarPath = `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/phase-in`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
                .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseESRSTopicPhaseInDecisionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ESRSTopicPhaseInDecisionsApi - functional programming interface
 * @export
 */
export const ESRSTopicPhaseInDecisionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ESRSTopicPhaseInDecisionsApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint retrieves all reference budgets for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhaseInDecisionsForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllPhaseInDecisionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhaseInDecisionsForDeo(organizationId, recordingPeriodId, dataEntryObjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used to modify phase in decision for topic
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseESRSTopicPhaseInDecisionData} iBaseESRSTopicPhaseInDecisionData Used to modify phase in decision for topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyPhaseInDecisionForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseESRSTopicPhaseInDecisionData: IBaseESRSTopicPhaseInDecisionData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyPhaseInDecisionForDeo(organizationId, recordingPeriodId, dataEntryObjectId, iBaseESRSTopicPhaseInDecisionData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ESRSTopicPhaseInDecisionsApi - factory interface
 * @export
 */
export const ESRSTopicPhaseInDecisionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ESRSTopicPhaseInDecisionsApiFp(configuration)
    return {
        /**
         * This endpoint retrieves all reference budgets for the organization.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhaseInDecisionsForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, options?: any): AxiosPromise<IGetAllPhaseInDecisionsResponse> {
            return localVarFp.getPhaseInDecisionsForDeo(organizationId, recordingPeriodId, dataEntryObjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to modify phase in decision for topic
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
         * @param {string} dataEntryObjectId The id of the data entry object to use.
         * @param {IBaseESRSTopicPhaseInDecisionData} iBaseESRSTopicPhaseInDecisionData Used to modify phase in decision for topic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPhaseInDecisionForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseESRSTopicPhaseInDecisionData: IBaseESRSTopicPhaseInDecisionData, options?: any): AxiosPromise<void> {
            return localVarFp.modifyPhaseInDecisionForDeo(organizationId, recordingPeriodId, dataEntryObjectId, iBaseESRSTopicPhaseInDecisionData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ESRSTopicPhaseInDecisionsApi - interface
 * @export
 * @interface ESRSTopicPhaseInDecisionsApi
 */
export interface ESRSTopicPhaseInDecisionsApiInterface {
    /**
     * This endpoint retrieves all reference budgets for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ESRSTopicPhaseInDecisionsApiInterface
     */
    getPhaseInDecisionsForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, options?: AxiosRequestConfig): AxiosPromise<IGetAllPhaseInDecisionsResponse>;

    /**
     * Used to modify phase in decision for topic
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IBaseESRSTopicPhaseInDecisionData} iBaseESRSTopicPhaseInDecisionData Used to modify phase in decision for topic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ESRSTopicPhaseInDecisionsApiInterface
     */
    modifyPhaseInDecisionForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseESRSTopicPhaseInDecisionData: IBaseESRSTopicPhaseInDecisionData, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ESRSTopicPhaseInDecisionsApi - object-oriented interface
 * @export
 * @class ESRSTopicPhaseInDecisionsApi
 * @extends {BaseAPI}
 */
export class ESRSTopicPhaseInDecisionsApi extends BaseAPI implements ESRSTopicPhaseInDecisionsApiInterface {
    /**
     * This endpoint retrieves all reference budgets for the organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ESRSTopicPhaseInDecisionsApi
     */
    public getPhaseInDecisionsForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, options?: AxiosRequestConfig) {
        return ESRSTopicPhaseInDecisionsApiFp(this.configuration).getPhaseInDecisionsForDeo(organizationId, recordingPeriodId, dataEntryObjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to modify phase in decision for topic
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IBaseESRSTopicPhaseInDecisionData} iBaseESRSTopicPhaseInDecisionData Used to modify phase in decision for topic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ESRSTopicPhaseInDecisionsApi
     */
    public modifyPhaseInDecisionForDeo(organizationId: string, recordingPeriodId: string, dataEntryObjectId: string, iBaseESRSTopicPhaseInDecisionData: IBaseESRSTopicPhaseInDecisionData, options?: AxiosRequestConfig) {
        return ESRSTopicPhaseInDecisionsApiFp(this.configuration).modifyPhaseInDecisionForDeo(organizationId, recordingPeriodId, dataEntryObjectId, iBaseESRSTopicPhaseInDecisionData, options).then((request) => request(this.axios, this.basePath));
    }
}
