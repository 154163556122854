import { CalculationErrorCodes } from "@netcero/netcero-calculation";
import {
  CountryCodeAlpha2Enum,
  generateDMACategoryTranslations,
  generateESRSTopicTranslations,
  ScopeKeys,
} from "@netcero/netcero-common";
import {
  IContentLanguage,
  IDataEntryObjectInputParameterExclusionReason,
  IDataEntryObjectInputParameterStatus,
  IDEOIPValueDataQuality,
  IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum,
  ITargetPathGoalTemplateType,
  ITargetPathState,
} from "@netcero/netcero-core-api-client";
import countryTranslations from "i18n-iso-countries";
import germanCountryTranslation from "i18n-iso-countries/langs/de.json";
import {
  BasicSnackbarApiActionType,
  SnackbarSuccessMassageType,
} from "../modules/app-snackbar/app-snackbar.interfaces";
import { IROType } from "../modules/policies/policies.types";
import { ESRSInputParameterType } from "../modules/data-entry-object-values/esrs/esrs-input-parameter-type-indicator.component";
import { ELIGIBLE_PHASE_IN_DRs } from "../modules/input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";

countryTranslations.registerLocale(germanCountryTranslation);
const CountryNamesByCode = Object.values(CountryCodeAlpha2Enum).reduce((res, code) => {
  const name = countryTranslations.getName(code, "de");
  if (name) {
    res[code] = name;
  }
  return res;
}, {} as Record<CountryCodeAlpha2Enum, string>);

const actionsTranslations: Record<SnackbarSuccessMassageType, string> = {
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_VALUE]:
    "Wert für Offenlegungspflicht aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS]:
    "Mitarbeitende Personen aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER]:
    "Verantwortliche Person aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE]:
    "Wert innerhalb Offenlegungspflicht aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS]:
    "Mitarbeitende Personen aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER]:
    "Verantwortliche Person aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_PHASE_IN_DECISION]: "Phase-In aktualisiert",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT]:
    "Offenlegungspflicht für Überprüfung eingereicht",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT]: "Offenlegungspflicht genehmigt",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT]: "Offenlegungspflicht abgelehnt",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht für Überprüfung eingereicht",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht genehmigt",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht abgelehnt",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT]: "Offenlegungspflicht ausgeschlossen",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT]:
    "Offenlegungspflicht wieder hinzugefügt",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht ausgeschlossen",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht wieder hinzugefügt",
  [BasicSnackbarApiActionType.EXPORT_DMA_IROS]: "Auswirkungen, Risiken und Chancen exportiert",
};

const inputParameterTypesTranslations: Record<ESRSInputParameterType, string> = {
  text: "Text",
  currency: "Währung",
  number: "Zahl",
  boolean: "Ja/Nein",
  date: "Datum",
  "date-range": "Zeitraum",
  options: "Auswahl",
  "nested-options": "Auswahl",
  table: "Tabelle",
  policy: "Policies",
  "phase-in": "Phase-In",
};

const DRs_PHASE_IN_EXPLANATIONS: Record<ELIGIBLE_PHASE_IN_DRs, string> = {
  [ELIGIBLE_PHASE_IN_DRs.SBM_1]:
    "Das Unternehmen meldet die in ESRS 2 SBM-1 Absatz 40 Buchstabe b (Aufschlüsselung der Gesamteinnahmen nach bedeutenden ESRS-Sektoren) und 40 Buchstabe c (Liste der zusätzlichen bedeutenden ESRS-Sektoren) vorgeschriebenen Informationen ab dem in einem gemäß Artikel 29b Absatz 1 Unterabsatz 3 Ziffer ii der Richtlinie 2013/34/EU zu erlassenden delegierten Rechtsakt der Kommission festgelegten Anwendungsdatum.",
  [ELIGIBLE_PHASE_IN_DRs.SBM_3]:
    "Das Unternehmen kann die in ESRS 2 SBM-3 Paragraph 48(e) vorgeschriebenen Angaben (erwartete finanzielle Auswirkungen) für das erste Jahr der Erstellung seiner Nachhaltigkeitserklärung weglassen. Das Unternehmen kann ESRS 2 SBM-3 Paragraph 48(e) einhalten, indem es für die ersten drei Jahre der Erstellung seiner Nachhaltigkeitserklärung nur qualitative Angaben macht, wenn die Erstellung quantitativer Angaben nicht praktikabel ist.",
  [ELIGIBLE_PHASE_IN_DRs.E1_6]:
    "Unternehmen oder Konzerne, die an ihren Bilanzstichtagen die durchschnittliche Zahl von 750 Beschäftigten während des Geschäftsjahres nicht überschreiten (gegebenenfalls auf konsolidierter Basis), können die Datenpunkte zu den Scope-3-Emissionen und den gesamten THG-Emissionen für das erste Jahr der Erstellung ihrer Nachhaltigkeitserklärung weglassen.",
  [ELIGIBLE_PHASE_IN_DRs.E1_9]:
    "Das Unternehmen kann die im ESRS E1-9 vorgeschriebenen Angaben für das erste Jahr der Erstellung seiner Nachhaltigkeitserklärung weglassen. Das Unternehmen kann dem ESRS E1-9 entsprechen, indem es für die ersten drei Jahre der Erstellung seiner Nachhaltigkeitserklärung nur qualitative Angaben macht, wenn die Erstellung quantitativer Angaben nicht praktikabel ist.",
  [ELIGIBLE_PHASE_IN_DRs.E2_6]:
    "Das Unternehmen kann die im ESRS E2-6 vorgeschriebenen Informationen für das erste Jahr der Erstellung seiner Nachhaltigkeitserklärung weglassen. Mit Ausnahme der in Paragraph 40 (b) vorgeschriebenen Informationen über die im Berichtszeitraum getätigten Betriebs- und Investitionsausgaben im Zusammenhang mit größeren Ereignissen und Lagerstätten kann das Unternehmen den ESRS E2-6 erfüllen, indem es in den ersten drei Jahren der Erstellung seiner Nachhaltigkeitserklärung nur qualitative Angaben macht.",
  [ELIGIBLE_PHASE_IN_DRs.E3_5]:
    "Das Unternehmen kann die im ESRS E3-5 vorgeschriebenen Informationen für das erste Jahr der Erstellung seiner Nachhaltigkeitserklärung weglassen und in den ersten drei Jahren der Erstellung seiner Nachhaltigkeitserklärung nur qualitative Angaben machen.",
};

export const TRANSLATIONS_DE = {
  module_access: {
    error_module_not_accessible_to_organization: "Modul nicht für Organisation zugänglich!",
  },
  input_parameter_types: inputParameterTypesTranslations,
  common: {
    logout_button_text: "Abmelden",
    no_changes: "Keine Änderungen",
    title_warning: "Warnung",
    countries: CountryNamesByCode,
    explanation_dialog_title: "Erklärung",
    title_audit_logging: "Aktivitätsprotokoll",
  },
  snack_bar_messages: {
    error_message: "Fehler: {{translatedAction}} - {{translatedError}}",
    actions: actionsTranslations,
  },
  user_picker_components: {
    selected_user_not_found: "Unbekannt",
    no_user_selected: "Kein Benutzer ausgewählt",
  },
  buttons: {
    create: "Erstellen",
    save: "Speichern",
    cancel: "Abbrechen",
    close: "Schließen",
    delete: "Löschen",
    edit: "Bearbeiten",
    confirm: "Bestätigen",
    show_explanation_button_tooltip: "Klicken um Erklärung anzuzeigen",
    add_comment: "Kommentar hinzufügen",
    remove_comment: "Kommentar entfernen",
    tooltip_copy_link_success: "Kopiert!",
  },
  popup_menu_filter_users_section_component: {
    placeholder_users_search: "Suchen...",
    users_search_no_results: "Keine Ergebnisse für Suche",
  },
  side_bar_nav_items: {
    value_input: "Erfassung",
    target_paths: "Zielpfade",
    target_paths_reference_budgets: "Referenzbudgets",
    configuration: "Konfiguration",
    configuration_organization: "Organisation",
    configuration_recording_periods: "Erfassungsperioden",
    configuration_stakeholder: "Stakeholder",
    configuration_distribution_criteria: "Aufteilungsschlüssel",
    configuration_sources: "Quellen",
  },
  organizations_redirect_page: {
    no_associated_organizations: "Ihr Benutzer ist keinen Organisationen zugeordnet.",
  },
  recording_periods_redirect_page: {
    no_recording_periods:
      "Für Ihre Organisation wurden noch keine Erfassungsperioden eingerichtet.",
    button_create_recording_period: "Erfassungsperiode erstellen",
  },
  recording_period_actions_dashboard_section: {
    title_double_materiality_assessment: "Doppelte Wesentlichkeitsanalyse",
    text_double_materiality_assessment:
      "Führen Sie eine doppelte Wesentlichkeitsanalyse durch, um die Auswirkungen und " +
      "Chancen und Risiken Ihrer Organisation zu bestimmen.",
    button_double_materiality_assessment: "Durchführen",
    button_double_materiality_assessment_tooltip:
      "Doppelte Wesentlichkeitsanalyse nach ESRS durchführen",
  },
  recording_period_recording_structures_cards_list: {
    structures_without_recording_model_header: "Ohne Erfassungsmodell",
    add_category_button: "Thema hinzufügen",
  },
  evaluation_list_item: {
    evaluation_name: {
      emissions: "Emissionen auswerten",
    },
  },
  add_recording_structure_to_recording_period_dialog: {
    dialog_title: 'Neues Thema für "{{organizationName}}" erfassen:',
    recording_models_empty: "Keine Erfassungsmodelle vorhanden.",
    choose_recording_model_label: "Erfassungsmodell auswählen",
    choose_recording_model_item_text: "Erfassungsmodell auswählen",
    template_groups_empty:
      'Für das Erfassungsmodell "{{recordingModelName}}" sind keine Themen verfügbar.',
    choose_templates_group_label: "Erfassungsstruktur auswählen",
    choose_templates_group_item_text: "Erfassungsstruktur auswählen",
    choose_template_label: "Vorlage auswählen",
    templates_group_empty: 'Keine Vorlagen für das Thema "{{templatesGroupName}}" verfügbar.',
    choose_template_item_text: "Vorlage auswählen",
    accept_template_button_text: "Vorlage auswählen",
    cancel_button_text: "Abbrechen",
  },
  organization_structure_is_draft_notice: {
    organization_structure_is_draft:
      "Sie müssen die Organisationsstruktur für die aktuelle Erfassungsperiode festlegen und veröffentlichen bevor Sie Werte erfassen können.",
    to_configuration_text: "Zur Konfiguration",
  },
  data_entry_object_values_overview_thg_component: {
    page_title: "Werteerfassung",
    edit_data_entry_object_input_parameters: "Zu erfassende Werte anpassen",
    no_results_found: "Keine zu erfassenden Werte gefunden",
    tooltip_button_filter: "Filter",
    no_filterable_users_available: "Noch keine Personen zugewiesen.",
    redirect_button: "Zu erfassende Werte anpassen",
  },
  data_entry_object_values_overview_esrs_component: {
    eu_law_label: "ESRS Standard",
    application_requirements_label: "Application Requirements",
    confirm_submit_incomplete_dr_title: 'Zugehörige Datenpunkte nicht "Erledigt"',
    confirm_submit_incomplete_dr_body:
      'Einige zugehörige Datenpunkte sind noch nicht als "Erledigt" markiert. Sind Sie sicher, dass Sie die Offenlegungspflicht als "In Prüfung" / "Erledigt" markieren möchten?',
    button_add_table_row: "Zeile hinzufügen",
    dr_eligible_for_phase_in_explanations: DRs_PHASE_IN_EXPLANATIONS,
  },
  data_entry_object_values_overview_common: {
    no_contributing_users: "Keine Mitarbeitenden hinzugefügt",
    add_contributing_user_tooltip: "Mitarbeitende hinzufügen",
    no_more_contributing_users_to_assign: "Keine weiteren Benutzer verfügbar",
    no_users_available_for_responsible_user: "Keine weiteren Benutzer verfügbar",
    add_responsible_user_tooltip: "Verantwortliche Person setzen",
    no_responsible_user_value_text: "Keine verantwortliche Person",
    comment_label: "Kommentar",
    copy_link_tooltip: "Link kopieren",
    dependency_mismatch_info:
      'Die Erfassung dieses Datenpunktes ist von der Angabe bei "{{parameterName}}" abhängig. Aktuell muss dieser Wert nicht erfasst werden, es ist jedoch bereits ein Wert hinterlegt.',
    buttons: {
      previous_section: "Vorheriger Abschnitt",
      next_section: "Nächster Abschnitt",
    },
    indicator_optional_text: "Optional",
  },
  data_entry_object_values_input_table: {
    notice_empty: "Noch keine Werte erfasst",
  },
  options_input_component: {
    name_empty_option: "Keine Auswahl",
  },
  data_entry_object_input_parameter_common: {
    status: {
      [IDataEntryObjectInputParameterStatus.Open]: {
        name: "Offen",
        description: "Es wurden noch keine Daten angegeben.",
        action_needed: "Der Benutzer muss Daten eingeben.",
      },
      [IDataEntryObjectInputParameterStatus.InProgress]: {
        name: "In Bearbeitung",
        description: "Daten wurden angegeben, aber noch nicht abgeschlossen.",
        action_needed: "Der Benutzer muss die Daten überprüfen und einreichen.",
      },
      [IDataEntryObjectInputParameterStatus.InReview]: {
        name: "In Prüfung",
        description: "Daten wurden eingereicht und warten auf Validierung.",
        action_needed: "Der Überprüfer muss die Daten überprüfen und genehmigen/ablehnen.",
      },
      [IDataEntryObjectInputParameterStatus.Done]: {
        name: "Erledigt",
        description: "Daten wurden vom Prüfer überprüft und genehmigt.",
        action_needed: "Keine, die Daten sind fertiggestellt.",
      },
    },
    transition_actions: {
      submit_with_reviewer: "Zu Überprüfung einreichen",
      submit_without_reviewer: "Ohne Überprüfung abschließen",
      approve: "Genehmigen",
      reject: "Ablehnen",
      notice_only_responsible_person_can_approve_or_reject:
        "Nur die verantwortliche Person kann genehmigen oder ablehnen.",
    },
    excluded_status: "Ausgeschlossen",
    exclude_transition_name: "Vom Bericht ausschließen",
    exclusion_reasons: {
      [IDataEntryObjectInputParameterExclusionReason.NonMaterial]: "Nicht wesentlich",
      [IDataEntryObjectInputParameterExclusionReason.NoDataAvailable]: "Keine Daten verfügbar",
      [IDataEntryObjectInputParameterExclusionReason.ApplyPhaseIn]: "Phase-in Bestimmung anwenden",
    },
    notice_exclusion_only_possible_as_responsible:
      "Nur die verantwortliche Person kann Angaben ausschließen.",
    notice_inclusion_only_possible_as_responsible:
      "Nur die verantwortliche Person kann Angaben wieder hinzufügen.",
  },
  data_entry_object_input_parameter_exclusion_dialog: {
    dialog_title_exclude: "Warum wollen Sie die Angabe ausschließen?",
    dialog_title_already_excluded: "Diese Angabe wurde ausgeschlossen",
    label_exclusion_reason: "Grund",
    label_explanation: "Kurze Begründung (optional)",
    button_confirm_exclude: "Ausschließen",
    button_include: "Wieder hinzufügen",
    // Include Confirm Dialog
    include_confirm_dialog_title: "Angabe wieder hinzufügen",
    include_confirm_dialog_body:
      "Sind Sie sicher, dass Sie die Angabe wieder zum Bericht hinzufügen möchten?",
  },
  data_entry_object_input_parameters_dialog: {
    dialog_title: "Zu erfassende Werte:",
    close_dialog: "Schließen",
  },
  data_entry_object_select_group_component: {
    button_settings: "Aktionen",
    button_category_select_all: "Alle direkt untergeordneten Erfassungsobjekte auswählen",
    button_category_deselect_all: "Alle direkt untergeordneten Erfassungsobjekte abwählen",
    button_select_all: "Alle untergeordneten Erfassungsobjekte auswählen",
    button_deselect_all: "Alle untergeordneten Erfassungsobjekte abwählen",
  },
  data_entry_object_input_parameters_select_group_component: {
    button_settings: "Aktionen",
    button_category_select_all: "Alle Werte dieses Themas und all seiner Unterthemen auswählen",
    button_category_deselect_all: "Alle Werte dieses Themas und all seiner Unterthemen abwählen",
    button_select_all: "Alle Werte dieses Themas auswählen",
    button_deselect_all: "Alle Werte dieses Themas abwählen",
  },
  data_entry_object_values_group: {
    category_empty: "Es werden aktuell keine Werte oder Unternehmen erfasst.",
  },
  data_entry_object_values_group_input_parameter_component: {
    no_contributing_users: "Keine Mitarbeitenden hinzugefügt",
    add_contributing_user_tooltip: "Mitarbeitende hinzufügen",
    no_more_contributing_users_to_assign: "Keine weiteren Benutzer verfügbar",
  },
  associated_iros_tab_component: {
    no_entries: {
      materialImpacts: "Sie müssen erst Auswirkungen anlegen, bevor diese zur Auswahl stehen.",
      financialEffects:
        "Sie müssen erst Chancen & Risiken anlegen, bevor diese zur Auswahl stehen.",
    },
    no_results: {
      materialImpacts: "Für die angegebene Suche wurden keine Auswirkungen gefunden.",
      financialEffects: "Für die angegebene Suche wurden keine Chancen oder Risiken gefunden.",
    },
  },
  associated_iros_dialog: {
    title: "Auswirkungen, Chancen & Risiken verknüpfen",
    search_placeholder: "Suchen...",
    tab_titles: {
      materialImpacts: "Auswirkungen",
      financialEffects: "Chancen und Risiken",
    } as { [key in IROType]: string },
  },
  data_entry_object_policy_component: {
    name: "Name",
    button_category_irrelevant: "Keine Policies ergriffen",
    tooltip_add_policy: "Neue Policy erstellen",
    no_policies_defined: "Noch keine Policies angelegt",
    present_policies: "Es gibt Policies für dieses Thema",
    no_present_policies: "Es gibt keine Policies für dieses Thema",
    policies_adopted: "Policies angelegt",
    edit_policy: "Policy bearbeiten",
    create_policy: "Neue Policy erstellen",
    iro_dropdown_text: "Auswirkungen, Chancen & Risiken",
    delete_policy_tooltip: "Policy entfernen",
    confirm_dialog: {
      title: "Policy entfernen",
      body: "Sind Sie sicher, dass Sie die Policy entfernen möchten?",
      button_confirm: "Entfernen",
      button_cancel: "Abbrechen",
    },
    section_titles: {
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E1]: "Angaben zu Klimawandel",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E2]:
        "Angaben zu Umweltverschmutzung",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E3]:
        "Angaben zu Wasser- und Meeresressourcen",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E4]:
        "Angaben zu Biologische Vielfalt und Ökosysteme",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.E5]:
        "Angaben zu Kreislaufwirtschaft",
      esrs_s: "Allgemeine Angaben zu Sozialen Themen",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S1]:
        "Angaben zu Eigene Belegschaft",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S2]:
        "Angaben zu Arbeitskräfte in der Wertschöpfungskette",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S3]:
        "Angaben zu Betroffene Gemeinschaften",
      [IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum.S4]:
        "Angaben zu Verbraucher und Endnutzer",
    },
    labels: {
      general: {
        name: "Bezeichnung",
        descriptionOfKeyContentsOfPolicyExplanatory:
          "Beschreibung von Schlüsselinhalten der Policy",
        descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory:
          "Beschreibung des Geltungsbereichs der Policy oder ihrer Ausschlüsse",
        descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory:
          "Beschreibung der höchsten Ebene in der Organisation, die für die Umsetzung der Policy verantwortlich ist",
        disclosureOfThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicyExplanatory:
          "Beschreibung von Drittanbieterstandards oder -initiativen, die durch die Umsetzung der Policy respektiert werden",
        descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory:
          "Beschreibung der Berücksichtigung der Interessen wichtiger Stakeholder bei der Festlegung der Policy",
        policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementIt:
          "Wird die Policy den potenziell betroffenen Stakeholdern und den Stakeholdern, die bei der Umsetzung helfen müssen, zur Verfügung gestellt?",
        explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndStakeholdersWhoNeedToHelpImplementItExplanatory:
          "Erklärung, wie die Policy den potenziell betroffenen Stakeholdern und den Stakeholdern, die bei der Umsetzung helfen müssen, zur Verfügung gestellt wird",
      },
      e1: {
        addressedMatters: "Behandelte Themen",
      },
      e2: {
        addressedMatters: "Behandelte Themen",
        policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil:
          "Behandelt die Policy die Minderung negativer Auswirkungen im Zusammenhang mit der Verschmutzung von Luft, Wasser und Boden",
        disclosureOfHowPolicyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoilExplanatory:
          "Wie behandelt die Policy die Minderung negativer Auswirkungen im Zusammenhang mit der Verschmutzung von Luft, Wasser und Boden?",
        policyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcern:
          "Behandelt die Policy die Substitution und Minimierung des Einsatzes von bedenklichen Stoffen und das Auslaufen von Stoffen von sehr hoher Bedenken?",
        disclosureOfHowPolicyAddressesSubstitutingAndMinimisingUseOfSubstancesOfConcernAndPhasingOutSubstancesOfVeryHighConcernExplanatory:
          "Wie behandelt die Policy die Substitution und Minimierung des Einsatzes von bedenklichen Stoffen und das Auslaufen von Stoffen von sehr hoher Bedenken?",
        policyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironment:
          "Behandelt die Policy das Vermeiden von Vorfällen und Notfallsituationen und wenn und wann sie auftreten, die Kontrolle und Begrenzung ihrer Auswirkungen auf Menschen und Umwelt?",
        disclosureOfHowPolicyAddressesAvoidingIncidentsAndEmergencySituationsAndIfAndWhenTheyOccurControllingAndLimitingTheirImpactOnPeopleAndEnvironmentExplanatory:
          "Wie behandelt die Policy das Vermeiden von Vorfällen und Notfallsituationen und wenn und wann sie auftreten, die Kontrolle und Begrenzung ihrer Auswirkungen auf Menschen und Umwelt?",
      },
      e3: {
        addressedMatters: "Behandelte Themen",
        policyAddressesWaterManagement: "Behandelt die Policy das Wasserressourcenmanagement?",
        disclosureOfHowPolicyAddressesWaterManagementExplanatory:
          "Wie behandelt die Policy das Wasserressourcenmanagement?",
        policyAddressesUseAndSourcingOfWaterAndMarineResourcesInOwnOperations:
          "Behandelt die Policy die Nutzung und Beschaffung von Wasser und Meeresressourcen in eigenen Betrieben?",
        policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater:
          "Behandelt die Policy die Wasserbehandlung als Schritt zu einer nachhaltigeren Wasserbeschaffung?",
        policyAddressesPreventionAndAbatementOfWaterPollutionResultingFromActivities:
          "Behandelt die Policy die Verhütung und Verminderung der Wasserverschmutzung durch Aktivitäten?",
        policyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResources:
          "Behandelt die Policy das Produkt- und Dienstleistungsdesign im Hinblick auf die Bewältigung von wasserbezogenen Problemen und den Schutz mariner Ressourcen?",
        disclosureOfHowPolicyAddressesProductAndServiceDesignInViewOfAddressingWaterrelatedIssuesAndPreservationOfMarineResourcesExplanatory:
          "Behandelt die Policy das Produkt- und Dienstleistungsdesign im Hinblick auf die Bewältigung von wasserbezogenen Problemen und den Schutz mariner Ressourcen?",
        policyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChain:
          "Behandelt die Policy das Bekenntnis zur Reduzierung des materiellen Wasserverbrauchs in Gebieten mit Wasserrisiken in eigenen Betrieben und entlang der Wertschöpfungskette stromauf- und stromabwärts?",
        disclosureOfHowPolicyAddressesCommitmentToReduceMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndAlongUpstreamAndDownstreamValueChainExplanatory:
          "Wie behandelt die Policy das Bekenntnis zur Reduzierung des materiellen Wasserverbrauchs in Gebieten mit Wasserrisiken in eigenen Betrieben und entlang der Wertschöpfungskette stromauf- und stromabwärts?",
        policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems:
          "Verhindert die Policy eine weitere Verschlechterung und schützt und verbessert den Zustand von Gewässern und aquatischen Ökosystemen?",
        policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources:
          "Fördert die Policy eine nachhaltige Wassernutzung auf der Grundlage des langfristigen Schutzes verfügbarer Wasserressourcen?",
        policyAimsAtEnhancedProtectionAndImprovementOfAquaticEnvironment:
          "Zielt die Policy auf den verbesserten Schutz und die Verbesserung der aquatischen Umwelt ab?",
        policyPromotesGoodEnvironmentalStatusOfMarineWater:
          "Fördert die Policy einen guten Umweltzustand des Meerwassers?",
        policyPromotesReductionOfWaterWithdrawalsAndWaterDischarges:
          "Fördert die Policy die Reduzierung von Wasserentnahmen und -einleitungen?",
        policyContributesToGoodEcologicalAndChemicalQualityOfSurfaceWaterBodiesAndGoodChemicalQualityAndQuantityOfGroundwaterBodies:
          "Trägt die Policy zur guten ökologischen und chemischen Qualität der Oberflächengewässer und zur guten chemischen Qualität und Quantität der Grundwasserkörper bei?",
        policyMinimisesMaterialImpactsAndRisksAndImplementsMitigationMeasuresThatAimToMaintainValueAndFunctionalityOfPriorityServicesAndToIncreaseResourceEfficiencyOnOwnOperations:
          "Minimalisiert die Policy materielle Auswirkungen und Risiken und setzt sie Minderungsmaßnahmen um, die darauf abzielen, den Wert und die Funktionalität von Prioritätsdiensten zu erhalten und die Ressourceneffizienz in eigenen Betrieben zu erhöhen?",
        policyAvoidsImpactsOnAffectedCommunities:
          "Vermeidet die Policy Auswirkungen auf betroffene Gemeinschaften?",
      },
      e4: {
        addressedMatters: "Behandelte Themen",
        disclosureOfHowPolicyRelatesToSustainabilityMattersForBiodiversityAndEcosystemsExplanatory:
          "Wie bezieht sich die Policy auf Nachhaltigkeitsfragen für Biodiversität und Ökosysteme?",
        disclosureOfHowPolicyRefersToProductionSourcingOrConsumptionOfRawMaterialsExplanatory:
          "Wie bezieht sich die Policy auf die Produktion, Beschaffung oder den Verbrauch von Rohstoffen?",
        disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory:
          "Wie beschränkt die Policy die Beschaffung von Lieferanten, die nicht nachweisen können, dass sie nicht zu erheblichen Schäden in geschützten Gebieten oder wichtigen Gebieten der biologischen Vielfalt beitragen?",
        disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory:
          "Wie bezieht sich die Policy auf anerkannte Standards oder Drittanbieterzertifizierungen, die von Regulierungsbehörden überwacht werden?",
        disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
          "Wie behandelt die Policy Rohstoffe, die aus Ökosystemen stammen, die zur Erhaltung oder Verbesserung der Bedingungen für die biologische Vielfalt bewirtschaftet wurden?",
        sustainableDevelopmentGoalsToWhichPolicyIsConnectedBiodiversityAndEcosystems:
          "Sustainable Development Goals, mit denen die Policy in Bezug auf Biodiversität und Ökosysteme verbunden ist",
        disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory:
          "Wie ermöglicht die Policy, negative Auswirkungen auf Biodiversität und Ökosysteme in eigenen Betrieben und in der zugehörigen Wertschöpfungskette zu vermeiden?",
        disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory:
          "Wie ermöglicht die Policy, negative Auswirkungen auf Biodiversität und Ökosysteme in eigenen Betrieben und entlang der Wertschöpfungskette stromauf- und stromabwärts zu reduzieren und zu minimieren, die nicht vermieden werden können?",
        disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory:
          "Wie ermöglicht die Policy, degradierte Ökosysteme wiederherzustellen oder geräumte Ökosysteme nach Exposition gegenüber Auswirkungen, die nicht vollständig vermieden und/oder minimiert werden können, wiederherzustellen?",
        disclosureOfHowPolicyEnablesToMitigateContributionToMaterialBiodiversityLossDriversExplanatory:
          "Wie ermöglicht die Policy, den Beitrag zu Treibern des materiellen Biodiversitätsverlusts zu mindern?",
        thirdpartyStandardOfConductUsedInPolicyIsObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesAndRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances:
          "Ist der in der Policy verwendete Drittanbieter-Verhaltensstandard objektiv und erreichbar, basierend auf einem wissenschaftlichen Ansatz zur Identifizierung von Problemen und realistisch bei der Bewertung, wie diese Probleme unter einer Vielzahl von praktischen Umständen angegangen werden können?",
        thirdpartyStandardOfConductUsedInPolicyIsDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsWithNoGroupHoldingUndueAuthorityOrVetoPowerOverContent:
          "Ist der in der Policy verwendete Drittanbieter-Verhaltensstandard durch einen fortlaufenden Konsultationsprozess mit relevanten Stakeholdern entwickelt oder aufrechterhalten worden, mit ausgewogenem Input aller relevanten Stakeholdergruppen, ohne dass eine Gruppe übermäßige Autorität oder Vetomacht über den Inhalt hat?",
        thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime:
          "Ist der in der Policy verwendete Drittanbieter-Verhaltensstandard ein schrittweiser Ansatz und eine kontinuierliche Verbesserung des Standards und seiner Anwendung von besseren Managementpraktiken und erfordert die Festlegung von sinnvollen Zielen und spezifischen Meilensteinen, um den Fortschritt gegenüber den Prinzipien und Kriterien im Laufe der Zeit anzuzeigen?",
        thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008:
          "Ist der in der Policy verwendete Drittanbieter-Verhaltensstandard über unabhängige Zertifizierungs- oder Verifizierungsstellen überprüfbar, die definierte und strenge Bewertungsverfahren haben, die Interessenkonflikte vermeiden und den ISO-Leitlinien für Akkreditierungs- und Verifizierungsverfahren oder Artikel 52 der Verordnung (EG) Nr. 765/2008 entsprechen?",
        thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice:
          "Entspricht der in der Policy verwendete Drittanbieter-Verhaltensstandard dem ISEAL-Verhaltenskodex?",
        biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpacts:
          "Bezieht sich die Biodiversitäts- und Ökosystembezogene Policy auf materielle Biodiversitäts- und Ökosystembezogene Auswirkungen?",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndEcosystemsrelatedImpactsExplanatory:
          "Wie bezieht sich die Biodiversitäts- und Ökosystembezogene Policy auf materielle Biodiversitäts- und Ökosystembezogene Auswirkungen?",
        biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunities:
          "Wie bezieht sich die Biodiversitäts- und Ökosystembezogene Policy auf materielle Abhängigkeiten und materielle physische und Übergangsrisiken und -chancen?",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyRelatesToMaterialDependenciesAndMaterialPhysicalAndTransitionRisksAndOpportunitiesExplanatory:
          "Wie bezieht sich die Biodiversitäts- und Ökosystembezogene Policy auf materielle Abhängigkeiten und materielle physische und Übergangsrisiken und -chancen?",
        biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain:
          "Unterstützt die Biodiversitäts- und Ökosystembezogene Policy die Rückverfolgbarkeit von Produkten, Komponenten und Rohstoffen mit materiellen tatsächlichen oder potenziellen Auswirkungen auf Biodiversität und Ökosysteme entlang der Wertschöpfungskette?",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChainExplanatory:
          "Wie unterstützt die Biodiversitäts- und Ökosystembezogene Policy die Rückverfolgbarkeit von Produkten, Komponenten und Rohstoffen mit materiellen tatsächlichen oder potenziellen Auswirkungen auf Biodiversität und Ökosysteme entlang der Wertschöpfungskette?",
        biodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversity:
          "Behandelt die Biodiversitäts- und Ökosystembezogene Policy die Produktion, Beschaffung oder den Verbrauch aus Ökosystemen, die zur Erhaltung oder Verbesserung der Bedingungen für die biologische Vielfalt bewirtschaftet werden?",
        disclosureOfHowBiodiversityAndEcosystemsrelatedPolicyAddressesProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
          "Wie behandelt die Biodiversitäts- und Ökosystembezogene Policy die Produktion, Beschaffung oder den Verbrauch aus Ökosystemen, die zur Erhaltung oder Verbesserung der Bedingungen für die biologische Vielfalt bewirtschaftet werden?",
        biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpacts:
          "Behandlet die Biodiversitäts- und Ökosystembezogene Policy die sozialen Folgen von Biodiversitäts- und Ökosystembezogenen Auswirkungen?",
        disclosureOfHowBiodiversityAndEcosystemsRelatedPolicyAddressesSocialConsequencesOfBiodiversityAndEcosystemsrelatedImpactsExplanatory:
          "Wie behandelt die Biodiversitäts- und Ökosystembezogene Policy die sozialen Folgen von Biodiversitäts- und Ökosystembezogenen Auswirkungen?",
      },
      e5: {
        addressedMatters: "Behandelte Themen",
        layerInWasteHierarchyThatPolicyAddresses:
          "Ebene in der Abfallhierarchie, die die Policy behandelt",
        descriptionOfHowPolicyAddressesWasteHierarchyExplanatory:
          "Beschreibung, wie die Policy die Abfallhierarchie behandelt",
        policyAddressesTransitioningAwayFromUseOfVirginResources:
          "Behandelt die Policy den Übergang von der Verwendung von Jungressourcen?",
        disclosureOfHowPolicyAddressesTransitioningAwayFromUseOfVirginResourcesExplanatory:
          "Wie behandelt die Policy den Übergang von der Verwendung von Jungressourcen?",
        policyAddressesSustainableSourcingAndUseOfRenewableResources:
          "Behandelt die Policy die nachhaltige Beschaffung und Nutzung erneuerbarer Ressourcen?",
        disclosureOfHowPolicyAddressesSustainableSourcingAndUseOfRenewableResourcesExplanatory:
          "Wie behandelt die Policy die nachhaltige Beschaffung und Nutzung erneuerbarer Ressourcen?",
        policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatment:
          "Behandelt die Policy die Priorisierung der Vermeidung oder Minimierung von Abfällen gegenüber der Abfallbehandlung?",
        disclosureOfHowPolicyAddressesPrioritisationOfAvoidanceOrMinimisationWasteOverWasteTreatmentExplanatory:
          "Wie behandelt die Policy die Priorisierung der Vermeidung oder Minimierung von Abfällen gegenüber der Abfallbehandlung?",
      },
      s: {
        groundsForDiscriminationThatAreSpecificallyCoveredByPolicy:
          "Diskriminierungsgründe, die speziell von der Policy erfasst werden",
        disclosureOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
          "Aspekte der Policy, die die Anforderungen an die Offenlegung von Policies in Bezug auf die eigene Belegschaft, die Wertschöpfungskettenarbeiter, betroffene Gemeinschaften, Verbraucher und Endnutzer erfüllen",
        specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace:
          "Liegen spezifische Policy-Verpflichtungen in Bezug auf Inklusion oder positive Maßnahmen für Menschen aus Gruppen mit besonderen Risiken der Verletzlichkeit in der eigenen Belegschaft vor?",
        disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsExplanatory:
          "Offenlegung von Kommunikationswerkzeugen und -kanälen, die darauf abzielen, sicherzustellen, dass die Policy zugänglich ist und dass verschiedene Zielgruppen ihre Auswirkungen verstehen",
        disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory:
          "Offenlegung spezifischer Policy-Verpflichtungen in Bezug auf Inklusion oder positive Maßnahmen für Menschen aus Gruppen mit besonderen Risiken der Verletzlichkeit in der eigenen Belegschaft",
        s1: {
          addressedMatters: "Behandelte Themen",
        },
        s2: {
          addressedMatters: "Behandelte Themen",
        },
        s3: {
          addressedMatters: "Behandelte Themen",
        },
        s4: {
          addressedMatters: "Behandelte Themen",
        },
      },
    },
    enums: {
      e1: {
        addressedMatters: {
          "esrs:ClimateChangeAdaptationMember": "Anpassung an den Klimawandel",
          "esrs:ClimateChangeMitigationMember": "Minderung des Klimawandels",
          "esrs:EnergyEfficiencyMember": "Energieeffizienz",
          "esrs:RenewableEnergyDeploymentMember": "Einsatz erneuerbarer Energien",
          "esrs:OtherClimateChangeSubtopicMember": "Sonstige Klimawandel-Themen",
        },
      },
      e2: {
        addressedMatters: {
          "esrs:PollutionOfAirMember": "Luftverschmutzung",
          "esrs:PollutionOfWaterMember": "Wasserverschmutzung",
          "esrs:PollutionOfSoilMember": "Bodenverschmutzung",
          "esrs:PollutionOfLivingOrganismsAndFoodResourcesMember":
            "Verschmutzung von Lebewesen und Nahrungsressourcen",
          "esrs:SubstancesOfConcernMember": "Bedenkliche Stoffe",
          "esrs:SubstancesOfVeryHighConcernMember": "Stoffe von sehr hoher Bedenken",
          "esrs:MicroplasticsMember": "Mikroplastik",
          "esrs:OtherPollutionSubtopicMember": "Sonstige Umweltverschmutzungs-Themen",
        },
      },
      e3: {
        addressedMatters: {
          "esrs:WaterAndMarineResourcesMember": "Wasser- und Meeresressourcen",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterConsumptionMember": "Wasserverbrauch",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterWithdrawalsMember": "Wasserentnahmen",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesMember": "Wassereinleitungen",
          "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesInOceansMember":
            "Wassereinleitungen in Ozeane",
          "esrs:WaterAndMarineResourcesMember.esrs:ExtractionAndUseOfMarineResourcesMember":
            "Extraktion und Nutzung von Meeresressourcen",
          "esrs:OtherWaterAndMarineResourcesSubtopicMember":
            "Sonstige Wasser- und Meeresressourcen-Themen",
        },
      },
      e4: {
        addressedMatters: {
          "esrs:DirectImpactDriversOfBiodiversityLossMember":
            "Direkte Treiber des Biodiversitätsverlusts",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:ClimateChangeMember":
            "Klimawandel",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:LandUseChangeMember":
            "Landnutzungsänderung",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:FreshWateruseChangeMember":
            "Änderung der Süßwassernutzung",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:SeauseChangeMember":
            "Änderung der Meeresnutzung",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:DirectExploitationMember":
            "Direkte Ausbeutung",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:InvasiveAlienSpeciesMember":
            "Invasive Arten",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:PollutionMember": "Verschmutzung",
          "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:OtherDirectImpactDriversOfBiodiversityLossMember":
            "Sonstige direkte Treiber des Biodiversitätsverlusts",
          "esrs:ImpactsOnStateOfSpeciesMember": "Auswirkungen auf den Zustand der Arten",
          "esrs:ImpactsOnExtentAndConditionOfEcosystemsMember":
            "Auswirkungen auf den Umfang und den Zustand der Ökosysteme",
          "esrs:ImpactsAndDependenciesOnEcosystemServicesMember":
            "Auswirkungen und Abhängigkeiten von Ökosystemdienstleistungen",
          "esrs:OtherBiodiversityAndEcosystemsSubtopicMember":
            "Sonstige Biodiversitäts- und Ökosystem-Themen",
        },
        sustainableDevelopmentGoalsToWhichPolicyIsConnectedBiodiversityAndEcosystems: {
          "esrs:EndPovertyInAllItsFormsEverywhereMember":
            "1 - Armut in allen ihren Formen überall beenden",
          "esrs:EndHungerAchieveFoodSecurityAndImprovedNutritionAndPromoteSustainableAgricultureMember":
            "2 - Hunger beenden, Ernährungssicherheit und verbesserte Ernährung erreichen und nachhaltige Landwirtschaft fördern",
          "esrs:EnsureHealthyLivesAndPromoteWellbeingForAllAtAllAgesMember":
            "3 - Gesundes Leben für alle Menschen in jedem Alter gewährleisten",
          "esrs:EnsureInclusiveAndEquitableQualityEducationAndPromoteLifelongLearningOpportunitiesForAllMember":
            "4 - Inklusive und gerechte Qualitätserziehung gewährleisten und lebenslanges Lernen für alle fördern",
          "esrs:AchieveGenderEqualityAndEmpowerAllWomenAndGirlsMember":
            "5 - Geschlechtergleichstellung erreichen und alle Frauen und Mädchen stärken",
          "esrs:EnsureAvailabilityAndSustainableManagementOfWaterAndSanitationForAllMember":
            "6 - Verfügbarkeit und nachhaltige Bewirtschaftung von Wasser und Sanitärversorgung für alle gewährleisten",
          "esrs:EnsureAccessToAffordableReliableSustainableAndModernEnergyForAllMember":
            "7 - Zugang zu bezahlbarer, verlässlicher, nachhaltiger und moderner Energie für alle gewährleisten",
          "esrs:PromoteSustainedInclusiveAndSustainableEconomicGrowthFullAndProductiveEmploymentAndDecentWorkForAllMember":
            "8 - Förderung des dauerhaften, inklusiven und nachhaltigen Wirtschaftswachstums, vollständige und produktive Beschäftigung und menschenwürdige Arbeit für alle",
          "esrs:BuildResilientInfrastructurePromoteInclusiveAndSustainableIndustrializationAndFosterInnovationMember":
            "9 - Aufbau widerstandsfähiger Infrastrukturen, Förderung einer inklusiven und nachhaltigen Industrialisierung und Förderung von Innovationen",
          "esrs:ReduceInequalityWithinAndAmongCountriesMember":
            "10 - Ungleichheit in und zwischen Ländern verringern",
          "esrs:MakeCitiesAndHumanSettlementsInclusiveSafeResilientAndSustainableMember":
            "11 - Städte und menschliche Siedlungen inklusiv, sicher, widerstandsfähig und nachhaltig machen",
          "esrs:EnsureSustainableConsumptionAndProductionPatternsMember":
            "12 - Nachhaltige Konsum- und Produktionsmuster sicherstellen",
          "esrs:TakeUrgentActionToCombatClimateChangeAndItsImpactsMember":
            "13 - Dringende Maßnahmen zur Bekämpfung des Klimawandels und seiner Auswirkungen",
          "esrs:ConserveAndSustainablyUseOceansSeasAndMarineResourcesForSustainableDevelopmentMember":
            "14 - Ozeane, Meere und Meeresressourcen für nachhaltige Entwicklung erhalten und nachhaltig nutzen",
          "esrs:ProtectRestoreAndPromoteSustainableUseOfTerrestrialEcosystemsSustainablyManageForestsCombatDesertificationAndHaltAndReverseLandDegradationAndHaltBiodiversityLossMember":
            "15 - Terrestrische Ökosysteme schützen, wiederherstellen und nachhaltig nutzen, Wälder nachhaltig bewirtschaften, Wüstenbildung bekämpfen und Landdegradierung umkehren und den Biodiversitätsverlust stoppen",
          "esrs:PromotePeacefulAndInclusiveSocietiesForSustainableDevelopmentProvideAccessToJusticeForAllAndBuildEffectiveAccountableAndInclusiveInstitutionsAtAllLevelsMember":
            "16 - Förderung friedlicher und inklusiver Gesellschaften für nachhaltige Entwicklung, Zugang zur Justiz für alle und Aufbau effektiver, rechenschaftspflichtiger und inklusiver Institutionen auf allen Ebenen",
          "esrs:StrengthenMeansOfImplementationAndRevitalizeGlobalPartnershipForSustainableDevelopmentMember":
            "17 - Stärkung der Umsetzungsmittel und Belebung der globalen Partnerschaft für nachhaltige Entwicklung",
        },
      },
      e5: {
        addressedMatters: {
          "esrs:ResourceInflowsMember": "Ressourcenzuflüsse",
          "esrs:ResourceOutflowsRelatedToProductsAndServicesMember":
            "Ressourcenabflüsse im Zusammenhang mit Produkten und Dienstleistungen",
          "esrs:WasteMember": "Abfall",
          "esrs:OtherResourceUseAndCircularEconomySubtopicMember":
            "Sonstige Ressourcennutzung und Kreislaufwirtschaft-Themen",
        },
        layerInWasteHierarchyThatPolicyAddresses: {
          "esrs:PreventionMember": "Vermeidung",
          "esrs:PreparationForReuseMember": "Vorbereitung zur Wiederverwendung",
          "esrs:RecyclingMember": "Recycling",
          "esrs:OtherRecoveryMember": "Sonstige Rückgewinnung",
          "esrs:DisposalMember": "Beseitigung",
        },
      },
      s: {
        groundsForDiscriminationThatAreSpecificallyCoveredByPolicy: {
          "esrs:RacialAndEthnicOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Rassische und ethnische Herkunft",
          "esrs:ColourGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Farbe",
          "esrs:SexGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Geschlecht",
          "esrs:SexualOrientationGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Sexuelle Orientierung",
          "esrs:GenderIdentityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Geschlechtsidentität",
          "esrs:DisabilityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Behinderung",
          "esrs:AgeGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Alter",
          "esrs:ReligionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Religion",
          "esrs:PoliticalOpinionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Politische Meinung",
          "esrs:NationalExtractionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Nationale Herkunft",
          "esrs:SocialOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Soziale Herkunft",
          "esrs:OtherFormsOfDiscriminationCoveredByEuRegulationAndNationalLawAreSpecificallyCoveredInPolicyMember":
            "Sonstige Formen der Diskriminierung, die durch EU-Verordnungen und nationales Recht abgedeckt sind",
        },
        s1: {
          addressedMatters: {
            "esrs:WorkingConditionsForOwnWorkforceMember":
              "Arbeitsbedingungen für die eigene Belegschaft",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SecureEmploymentForOwnWorkforceMember":
              "Sichere Beschäftigung",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorkingTimeForOwnWorkforceMember":
              "Arbeitszeit",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:AdequateWagesForOwnWorkforceMember":
              "Angemessene Löhne",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SocialDialogueForOwnWorkforceMember":
              "Sozialer Dialog",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:FreedomOfAssociationForOwnWorkforceMember":
              "Vereinigungsfreiheit",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:ExistenceOfWorksCouncilsForOwnWorkforceMember":
              "Existenz von Betriebsräten",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:InformationConsultationAndParticipationRightsOfOwnWorkforceMember":
              "Informations-, Anhörungs- und Beteiligungsrechte der eigenen Belegschaft",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:CollectiveBargainingForOwnWorkforceMember":
              "Tarifverhandlungen",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorklifeBalanceForOwnWorkforceMember":
              "Vereinbarkeit von Beruf und Privatleben",
            "esrs:WorkingConditionsForOwnWorkforceMember.esrs:HealthAndSafetyForOwnWorkforceMember":
              "Gesundheit und Sicherheit",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember":
              "Gleichbehandlung und Chancengleichheit für alle",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkforceMember":
              "Geschlechtergleichstellung und gleicher Lohn für gleichwertige Arbeit",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:TrainingAndSkillsDevelopmentForOwnWorkforceMember":
              "Ausbildung und Kompetenzentwicklung",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForOwnWorkforceMember":
              "Beschäftigung und Integration von Menschen mit Behinderungen",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkforceMember":
              "Maßnahmen gegen Gewalt und Belästigung am Arbeitsplatz",
            "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:DiversityForOwnWorkforceMember":
              "Vielfalt",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember":
              "Sonstige arbeitsbezogene Rechte für die eigene Belegschaft",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ChildLabourForOwnWorkforceMember":
              "Kinderarbeit",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ForcedLabourForOwnWorkforceMember":
              "Zwangsarbeit",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:AdequateHousingForOwnWorkforceMember":
              "Angemessene Unterkunft",
            "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:PrivacyForOwnWorkforceMember":
              "Privatsphäre",
            "esrs:OtherOwnWorkforceSubtopicMember": "Sonstige eigene Belegschaft-Themen",
          },
        },
        s2: {
          addressedMatters: {
            "esrs:WorkingConditionsForValueChainWorkersMember":
              "Arbeitsbedingungen für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SecureEmploymentForValueChainWorkersMember":
              "Sichere Beschäftigung für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorkingTimeForValueChainWorkersMember":
              "Arbeitszeit für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:AdequateWagesForValueChainWorkersMember":
              "Angemessene Löhne für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SocialDialogueForValueChainWorkersMember":
              "Sozialer Dialog für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:FreedomOfAssociationForValueChainWorkersMember":
              "Vereinigungsfreiheit für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:CollectiveBargainingForValueChainWorkersMember":
              "Tarifverhandlungen für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorklifeBalanceForValueChainWorkersMember":
              "Vereinbarkeit von Beruf und Privatleben für Wertschöpfungskettenarbeiter",
            "esrs:WorkingConditionsForValueChainWorkersMember.esrs:HealthAndSafetyForValueChainWorkersMember":
              "Gesundheit und Sicherheit für Wertschöpfungskettenarbeiter",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember":
              "Gleichbehandlung und Chancengleichheit für alle für Wertschöpfungskettenarbeiter",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForValueChainWorkersMember":
              "Geschlechtergleichstellung und gleicher Lohn für gleichwertige Arbeit für Wertschöpfungskettenarbeiter",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:TrainingAndSkillsDevelopmentForValueChainWorkersMember":
              "Ausbildung und Kompetenzentwicklung für Wertschöpfungskettenarbeiter",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForValueChainWorkersMember":
              "Beschäftigung und Integration von Menschen mit Behinderungen für Wertschöpfungskettenarbeiter",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForValueChainWorkersMember":
              "Maßnahmen gegen Gewalt und Belästigung am Arbeitsplatz für Wertschöpfungskettenarbeiter",
            "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:DiversityForValueChainWorkersMember":
              "Vielfalt für Wertschöpfungskettenarbeiter",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember":
              "Sonstige arbeitsbezogene Rechte für Wertschöpfungskettenarbeiter",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ChildLabourForValueChainWorkersMember":
              "Kinderarbeit für Wertschöpfungskettenarbeiter",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ForcedLabourForValueChainWorkersMember":
              "Zwangsarbeit für Wertschöpfungskettenarbeiter",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:AdequateHousingForValueChainWorkersMember":
              "Angemessene Unterkunft für Wertschöpfungskettenarbeiter",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:WaterAndSanitationForValueChainWorkersMember":
              "Wasser und Sanitärversorgung für Wertschöpfungskettenarbeiter",
            "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:PrivacyForValueChainWorkersMember":
              "Privatsphäre für Wertschöpfungskettenarbeiter",
            "esrs:OtherWorkersInValueChainSubtopicMember":
              "Sonstige Wertschöpfungskettenarbeiter-Themen",
          },
        },
        s3: {
          addressedMatters: {
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember":
              "Wirtschaftliche, soziale und kulturelle Rechte von Gemeinschaften",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateHousingForAffectedCommunitiesMember":
              "Angemessene Unterkunft für betroffene Gemeinschaften",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateFoodMember":
              "Angemessene Ernährung",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:WaterAndSanitationForAffectedCommunitiesMember":
              "Wasser und Sanitärversorgung für betroffene Gemeinschaften",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:LandrelatedImpactsMember":
              "Landbezogene Auswirkungen",
            "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:SecurityrelatedImpactsMember":
              "Sicherheitsbezogene Auswirkungen",
            "esrs:CommunitiesCivilAndPoliticalRightsMember":
              "Zivil- und politische Rechte von Gemeinschaften",
            "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfExpressionForAffectedCommunitiesMember":
              "Meinungsfreiheit für betroffene Gemeinschaften",
            "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfAssemblyMember":
              "Versammlungsfreiheit",
            "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:ImpactsOnHumanRightsDefendersMember":
              "Auswirkungen auf Menschenrechtsverteidiger",
            "esrs:RightsOfIndigenousPeoplesMember": "Rechte indigener Völker",
            "esrs:RightsOfIndigenousPeoplesMember.esrs:FreePriorAndInformedConsentMember":
              "Freie, vorherige und informierte Zustimmung",
            "esrs:RightsOfIndigenousPeoplesMember.esrs:SelfdeterminationMember": "Selbstbestimmung",
            "esrs:RightsOfIndigenousPeoplesMember.esrs:CulturalRightsMember": "Kulturelle Rechte",
            "esrs:OtherAffectedCommunitiesSubtopicMember":
              "Sonstige betroffene Gemeinschaften-Themen",
          },
        },
        s4: {
          addressedMatters: {
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember":
              "Informationsbezogene Auswirkungen für Verbraucher und/oder Endnutzer",
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:PrivacyForConsumersAndOrEndUsersMember":
              "Privatsphäre für Verbraucher und/oder Endnutzer",
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:FreedomOfExpressionForConsumersAndOrEndusersMember":
              "Meinungsfreiheit für Verbraucher und/oder Endnutzer",
            "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:AccessToQualityInformationMember":
              "Zugang zu qualitativ hochwertigen Informationen",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember":
              "Persönliche Sicherheit von Verbrauchern und/oder Endnutzern",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:HealthAndSafetyForConsumersAndOrEndUsersMember":
              "Gesundheit und Sicherheit für Verbraucher und/oder Endnutzer",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:SecurityOfPersonMember":
              "Sicherheit der Person",
            "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:ProtectionOfChildrenMember":
              "Schutz von Kindern",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember":
              "Soziale Inklusion von Verbrauchern und/oder Endnutzern",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:NondiscriminationMember":
              "Nichtdiskriminierung",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:AccessToProductsAndServicesMember":
              "Zugang zu Produkten und Dienstleistungen",
            "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:ResponsibleMarketingPracticesMember":
              "Verantwortungsvolle Marketingpraktiken",
            "esrs:OtherConsumersAndOrEndusersSubtopicMember":
              "Sonstige Verbraucher und/oder Endnutzer-Themen",
          },
        },
      },
    },
    input_errors: {
      general: {
        name: {
          required: "Bitte geben Sie eine Bezeichnung ein!",
        },
      },
    },
  },
  data_entry_object_value_edit_component: {
    tooltips: {
      add_note: "Notiz hinzufügen",
      remove_note: "Notiz entfernen",
      add_date: "Datum erfassen",
      use_distribution_criterion: "Aufteilungsschlüssel verwenden",
      undo_distribution_criterion: "Aufteilungsschlüssel nicht verwenden",
      data_quality: {
        none: "Keine Angabe",
        [IDEOIPValueDataQuality.Estimated]: "Geschätzt",
        [IDEOIPValueDataQuality.Measured]: "Exakter Wert/Gemessen",
        [IDEOIPValueDataQuality.Calculated]: "Berechnet",
      },
    },
  },
  data_entry_object_value_actions_component: {
    tooltips: {
      cancel: "Abbrechen",
      reset: "Änderungen verwerfen",
      edit: "Speichern",
      create: "Neuen Eintrag speichern",
      delete: "Löschen",
    },
  },
  data_entry_object_values_edit: {
    remove_date_tooltip: "Datum entfernen",
    date_error_invalid: "Ungültiges Datum",
    date_error_before_start: "Das Datum darf nicht kleiner als {{minDate}} sein!",
    date_error_after_end: "Das Datum darf nicht größer als {{maxDate}} sein!",
    date_error_end_before_start: "Das Enddatum darf nicht vor dem Startdatum liegen!",
    value_use_distribution_criterion: "Aufteilungsschlüssel verwenden",
    value_undo_distribution_criterion: "Aufteilungsschlüssel nicht verwenden",
    no_dc_selected: "Keine Auswahl",
    label_dc: "Aufteilungsschlüssel",
    criteria_hint_component: {
      labels: {
        value_in_percent: "Hinterlegter Wert (%):",
        calculated_value: "Berechneter Wert:",
      },
      hints: {
        general:
          'Dieser Wert wird auf die untergeordnete Struktur nach dem "{{dcName}}"-Schlüssel verteilt.',
        value_in_percent:
          'Der für <bold>"{{deoName}}"</bold> hinterlegte Wert ist für diesen Schlüssel <bold>{{keyValue}}</bold>, das entspricht <bold>{{valueInPercent}}%</bold> des Gesamtbetrages von <bold>{{sum}}</bold>.',
      },
    },
  },
  data_entry_object_value_esrs_phase_in_input: {
    use_phase_in_toggle_label: "Phase-in Regelung für Unternehmen bis 750 Mitarbeiter aktivieren",
    information_box_content:
      "Die Anwendung der Schrittweisen Einführung (Phase-in) ersetzt die Angabepflichten des gewählten ESRS Themas durch ein minimales Set neuer Pflichtangaben.",
    notice_only_root_data_entry_object: "Phase In ist nur für das Haupterfassungsobjekt verfügbar",
    notice_phase_in_in_use: "Phase-In Regelung wird verwendet",
  },
  data_entry_object_parent_values_component: {
    title: "Werte von übergeordneten Erfassungsobjekten:",
    title_own_values: "Eigene Werte:",
  },
  data_entry_object_parent_value_component: {
    from: "Von",
  },
  error_dialog: {
    dialog_title: "Fehler",
    button_close: "Schließen",
  },
  data_entry_object_edit_dialog: {
    dialog_title: "Erfassungsobjekt {{dataEntryObjectName}} bearbeiten",
    dialog_title_new: "Neues Erfassungsobjekt erstellen",
    name_required: "Bitte geben Sie einen Namen ein.",
    name_input_label: "Name",
    financially_consolidated_label: "Finanziell konsolidiert?",
    operational_control_label: "Operative Kontrolle?",
    part_of_value_chain_label: "Teil der Wertschöpfungskette?",
    description_input_label: "Beschreibung (optional)",
    button_delete_tooltip: "Erfassungsobjekt entfernen",
    button_change_parent: "In anderes Erfassungsobjekt verschieben",
    button_cancel: "Abbrechen",
    button_save: "Speichern",
    button_create: "Erstellen",
    object_type_select_label: "Art",
    country_select_label: "Land",
    share_held_by_parent_label: "Anteile an diesem Erfassungsobjekt",
    object_type_value_location: "Standort",
    object_type_value_department: "Abteilung",
    object_type_value_team: "Team",
    object_type_value_business_area: "Geschäftsbereich",
    object_type_value_grouping: "Gruppierung",
    object_type_value_organization: "Organisation",
  },
  configuration_dashboard_page: {
    title: "Konfiguration",
    title_recording_periods: "Erfassungsperioden",
    description_recording_periods: "Erfassungsperioden erstellen und verwalten.",
    button_recording_periods: "Erfassungsperioden",
    title_stakeholder: "Stakeholder",
    description_stakeholder:
      "Stakeholder erstellen und verwalten. Über einen gesonderten Freigabelink können externe Stakeholder Aktionen in der Plattform setzen.",
    button_stakeholder: "Stakeholder",
    title_distribution_criteria: "Aufteilungsschlüssel",
    description_distribution_criteria: "Aufteilungsschlüssel erstellen und verwalten.",
    button_distribution_criteria: "Aufteilungsschlüssel",
    title_sources: "Quellen",
    button_sources: "Quellen",
    description_sources: "Quellen erstellen und verwalten.",
  },
  recording_periods_edit_list_component: {
    title: "Erfassungsperioden",
    button_new_recording_period: "Erstellen",
    recording_periods_empty: "Keine Erfassungsperioden vorhanden.",
    button_edit_organization_structure_tooltip: "Organisationsstruktur bearbeiten",
    button_edit_distribution_criteria_tooltip: "Aufteilungsschlüssel bearbeiten",
    button_edit_recording_period_tooltip: "Erfassungsperiode bearbeiten",
  },
  organization_configuration_dashboard_page: {
    title: "Organisation konfigurieren",
    title_organization_structure: "Organisationsstruktur",
    description_organization_structure: "Struktur der Organisation verwalten.",
    button_edit_organization_structure: "Organisationsstruktur bearbeiten",
  },
  organization_structure_configuration_page: {
    title: "Organisationsstruktur",
    button_dc: "Aufteilungsschlüssel",
    button_dc_tooltip: "Aufteilungsschlüssel konfigurieren",
  },
  organization_structure_configuration_component: {
    recording_period_select_label: "Erfassungsperiode auswählen",
    organization_structure_commit_draft_button_tooltip: "Entwurf veröffentlichen",
    organization_structure_is_draft_notice:
      "Die Organisationsstruktur ist aktuell noch ein Entwurf. Sie müssen die Organisationsstruktur " +
      "veröffentlichen bevor Sie Werte erfassen können.",
  },
  organization_structure_tree_editor_component: {
    delete_confirm_dialog_title: "Erfassungsobjekt entfernen",
    delete_confirm_dialog_content:
      "Sind Sie sicher, dass Sie das Erfassungsobjekt {{dataEntryObjectName}} und alle untergeordneten Erfassungsobjekte entfernen möchten?",
    delete_confirm_dialog_button_delete: "Entfernen",
    delete_confirm_dialog_button_cancel: "Abbrechen",
    add_child_tooltip: "Neues Erfassungsobjekt hinzufügen",
    add_child_mode_text: "Wählen Sie das übergeordnete Erfassungsobjekt",
    add_child_cancel_tooltip: "Abbrechen",
    error_cannot_set_deo_as_its_own_parent:
      "Erfassungsobjekt kann nicht sein eigenes übergeordnetes Erfassungsobjekt sein!",
    error_selected_deo_is_already_parent_of_deo_to_be_moved:
      "Das gewählte Erfassungsobjekt ist bereits das aktuell übergeordnete Erfassungsobjekt!",
    error_selected_new_parent_cannot_be_child_of_deo_to_be_moved:
      "Das Erfassungsobjekt kann nicht in ein ihm untergeordnetes Erfassungsobjekt verschoben werden!",
    error_dialog_button_ok: "Verstanden",
    change_parent_mode_text: "Neues übergeordnetes Erfassungsobjekt wählen",
    change_parent_cancel_tooltip: "Abbrechen",
    deo_count_text: "{{currentCount}} / {{maxCount}} Erfassungsobjekten",
    deo_limit_reached: "Maximale Anzahl an Erfassungsobjekten erreicht",
  },
  organization_structure_tree_component: {
    node_type_location: "Standort",
    node_type_department: "Abteilung",
    node_type_team: "Team",
    node_type_business_area: "Geschäftsbereich",
    node_type_grouping: "Gruppierung",
    node_type_company: "Organisation/Unternehmen",
    node_type_organization: "Organisation",
  },
  organization_structure_draft_commit_dialog: {
    title: "Organisationsstruktur veröffentlichen",
    warning_text:
      "Sind Sie sicher, dass Sie den aktuellen Entwurf veröffentlichen möchten? " +
      "Dieser Vorgang kann nicht rückgängig gemacht werden.",
    button_cancel: "Abbrechen",
    button_publish: "Veröffentlichen",
  },
  recording_period_edit_dialog: {
    title_create: "Neue Erfassungsperiode erstellen",
    title_edit: "Erfassungsperiode {{name}} bearbeiten",
    default_name: "Geschäftsjahr {{year}}",
    name_label: "Name",
    name_required: "Bitte geben Sie einen Namen ein.",
    description_label: "Beschreibung (optional)",
    start_date_label: "Startdatum",
    start_date_required: "Bitte geben Sie ein Startdatum ein.",
    start_date_invalid: "Bitte geben Sie ein gültiges Startdatum ein.",
    start_date_min: "Das Startdatum muss nach dem {{minDate}} (vorherige Periode) liegen.",
    start_date_max: "Das Startdatum muss vor dem Enddatum liegen.",
    end_date_required: "Bitte geben Sie ein Enddatum ein.",
    end_date_invalid: "Bitte geben Sie ein gültiges Enddatum ein.",
    end_date_min: "Das Enddatum muss nach dem Startdatum liegen.",
    end_date_max: "Das Enddatum muss vor dem {{maxDate}} (nachfolgende Periode) liegen.",
    end_date_label: "Enddatum",
    button_create: "Erstellen",
    button_save: "Speichern",
    button_cancel: "Abbrechen",
  },
  // Evaluations
  emissions_evaluation_page: {
    title: "Emissionen auswerten",
    notice: {
      text: "Die Emissionsauswertung befindet sich momentan im Umbau und ist deshalb nicht verfügbar.",
      button: "Zurück zum Start",
    },
  },
  emissions_overview: {
    title_organization_unit: "Erfassungsobjekt:",
    notice_unit:
      "Alle Werte in der Auswertung sind in der Einheit <bold>t CO<sub>2</sub>eq</bold> angegeben.",
    title_emission_group: "Emissionsgruppe:",
    title_total_emissions: "Gesamtemissionen:",
    title_total_emissions_by_recording_group: "Nach Emissionsgruppen:",
    title_total_emissions_by_child: "Nach Erfassungsobjekt:",
    title_child_data_entry_objects: "Erfassungsobjekte:",
    title_scopes: "Verteilung Scopes:",
  },
  emissions_direct_and_upstream_component: {
    title_direct_emissions: "Direkte Emissionen",
    title_upstream_emissions: "Vorgelagerte Emissionen",
  },
  total_emissions_pie_chart_component: {
    empty: "Keine Emissionen",
    total_emissions: "Gesamt:",
  },
  total_emissions_bar_percentage_component: {
    empty: "Keine Emissionen",
  },
  emissions_by_scope_bar_charts: {
    empty: "Keine Emissionen",
    button_filter: "Filter",
    title_scope1: "Scope 1",
    title_scope2: "Scope 2",
    title_scope3: "Scope 3",
    title_total: "Gesamt",
    no_deo_with_emissions_notice:
      "Für gewählte Erfassungsobjekte und Emissionsgruppen wurden keine Emissionen erfasst.",
    deo_result_no_emissions: "(keine Emissionen)",
  },
  deo_emissions_by_category_bar_chart: {
    empty: "Keine Emissionen",
    button_filter: "Filter",
    no_deo_with_emissions_notice:
      "Für gewählte Erfassungsobjekte und Emissionsgruppen wurden keine Emissionen erfasst.",
    deo_result_no_emissions: "(keine Emissionen)",
  },
  emissions_evaluation_error_component: {
    title: "Bei der Auswertung ist ein Fehler aufgetreten!",
    subtitle_error_message: "Fehlermeldung:",
    errorMessages: {
      "formula_specific.reference.context_key_not_found":
        "Der geforderte Key wurde im Context nicht gefunden!",
    } as { [key in CalculationErrorCodes]: string },
    affected: {
      subtitle: "Formel & Input Parameter",
      inputParameter: "Input-Parameter: {{inputParameterName}} (ID: {{id}})",
      calculationFormula: "Formel: {{calculationFormulaName}} (ID: {{id}})",
      value: "Wert: {{id}}",
    },
    step: {
      subtitle: "Betroffener Schritt:",
      subtitle_value_key: "Context-Key:",
      subtitle_index: "({{count}}. Schritt innerhalb der Formel)",
      subtitle_formula: "Berechnungs-Formel:",
    },
    context: {
      subtitle: "Context:",
      headers: {
        key: "Context-Key:",
        value: "Wert",
      },
    },
  },
  // Double Materiality Assessment
  dma_common: {
    // Materiality State
    materiality_chip_tooltip: "Wesentlichkeitsgrad / Wesentlichkeit",
    materiality_true_state: "Ja",
    materiality_false_state: "Nein",
    // IRO State
    iro_state_draft: "Entwurf",
    iro_state_shared: "Freigegeben",
    // Category State
    category_state_open: "Offen",
    category_state_workInProgress: "In Bearbeitung",
    category_state_shared: "Freigegeben",
    category_state_verified: "Abgeschlossen",
  },
  dma_configuration: {
    title_threshold: "Bewertung",
    description_threshold:
      "Dieser Wert wird verwendet um zu bestimmen, ob eine Auswirkung, Chance oder ein Risiko materiell " +
      "sind oder nicht. Ist der berechnete Wesentlichkeitsgrad gleich oder größer als dieser Wert, ist die " +
      "Auswirkung, Chance oder ein Risiko materiell.",
    label_threshold: "Schwellenwert",
    error_threshold_max: "Der Schwellenwert darf nicht größer als {{max}} sein.",
    error_threshold_min: "Der Schwellenwert darf nicht kleiner als {{min}} sein.",
    title_potential_extent_steps: "Potentielles Ausmaß",
    description_potential_extent_steps:
      "Das potentielle Ausmaß von Chancen und Risiken wird in 5 Schritten bewertet. Für diese Schritte kann ein " +
      "monetärer Wert hinterlegt werden der anschließend für Berechnung des Schweregrades in Euro verwendet wird.",
    label_set_potential_extent_steps: "Individuelle Werte hinterlegen",
    error_potentialExtentStep_not_ascending: "Die Werte müssen aufsteigend sein.",
    error_potentialExtentStep_required: "Bitte geben Sie einen Wert ein.",
    // probability of occurrence material
    title_probability_of_occurrence_steps_material: "Eintrittswahrscheinlichkeit von Auswirkungen",
    description_probability_of_occurrence_steps_material:
      "Die Eintrittswahrscheinlichkeit von Auswirkungen wird in 5 Schritten bewertet. Für diese Schritte kann ein " +
      "Wert in Prozent hinterlegt werden, der anschließend für Berechnung des Schweregrades / Wesentlichkeitsgrades verwendet wird.",
    // probability of occurrence financial
    title_probability_of_occurrence_steps_financial:
      "Eintrittswahrscheinlichkeit von Risiken und Chancen",
    description_probability_of_occurrence_steps_financial:
      "Die Eintrittswahrscheinlichkeit von Risiken und Chancen wird in 5 Schritten bewertet. Für diese Schritte kann ein " +
      "Wert in Prozent hinterlegt werden, der anschließend für Berechnung des Schweregrades / Wesentlichkeitsgrades verwendet wird.",
    // probability of occurrence common
    label_set_probability_of_occurrence_steps: "Individuelle Werte hinterlegen",
    // errors probability of occurrence
    error_probabilityOfOccurrence_step_not_ascending: "Die Werte müssen aufsteigend sein.",
    error_probabilityOfOccurrence_step_required: "Bitte geben Sie einen Wert ein.",
    error_probabilityOfOccurrence_step_min: "Der Wert muss größer oder gleich 0 sein.",
    error_probabilityOfOccurrence_step_max: "Der Wert darf nicht größer als 100 sein.",
    // Export
    title_export: "Export",
  },
  dma_export_button_component: {
    button_content_iros: "Auswirkungen, Chancen und Risiken exportieren",
    "button_content_stakeholder-feedback": "Stakeholder-Feedback exportieren",
    button_tooltip_iros:
      "Auswirkungen, Chancen und Risiken in gewählter Sprache ({{currentLanguage}}) exportieren",
    "button_tooltip_stakeholder-feedback":
      "Stakeholder-Feedback in gewählter Sprache ({{currentLanguage}}) exportieren",
  },
  double_materiality_assessment_page: {
    categories_empty: "Es wurden noch keine Themen definiert!",
    no_dma_configuration_present:
      "Es wurde noch keine DMA-Konfiguration für diesen Erfassungszeitraum erstellt!",
  },
  double_materiality_assessment_stepper_component: {
    material_impacts_checkbox_label: "Auswirkungen",
    financial_effects_checkbox_label: "Chancen und Risiken",
    filter_by_user_label: "Filtern nach Benutzer",
    previous_step_button: "Zurück",
    next_step_button: "Weiter",
    complete_dma_button: "Abschließen",
    can_not_complete_dma:
      "Die DMA kann momentan nicht abgeschlossen werden, da zumindest ein Thema unvollständig ist. " +
      "Bei allen vollständigen Themen werden neben dem Namen doppelte Checkmarks angezeigt.",
    label_dma_dashboard: "Dashboard",
    button_filter: "Filter",
    no_filterable_users_available:
      "Noch keine Personen als verantwortlich oder zugewiesen hinterlegt.",
    selected_category_empty:
      "Das gewählte Hauptthema enthält keine Subthemen, für die Auswirkungen erfasst werden können.",
    dma_incomplete_notice_title: "Doppelte Wesentlichkeit unvollständig!",
    dma_incomplete_notice_content:
      "Die doppelte Wesentlichkeit kann erst abgeschlossen werden, wenn Sie für jedes Thema, das keine untergeordneten Themen " +
      "besitzt, zumindest eine Auswirkung und eine Chance oder ein Risiko angegeben haben oder bestätigt haben, dass Sie entsprechend " +
      "keine Auswirkungen oder Chancen und Risiken angeben möchten.\n\nBitte vervollständigen Sie die doppelte Wesentlichkeit bevor Sie fortfahren!",
    dma_incomplete_notice_button_accept: "Vervollständigen",
    dma_open_topic:
      "Dieses Thema ist noch offen, da keine Auswirkungen oder Chancen / Risiken erfasst wurden und das Thema nicht als nicht relevant markiert wurde.",
    dma_shared_topic:
      "Für dieses Thema fehlt die Validierung durch Stakeholder und der anschließende Abschluss aller Unterthemen.",
    dma_verified_topic: "Dieses Thema ist abgeschlossen.",
    title_dma_configuration: "Konfiguration",
    label_step_dma_configuration: "Konfiguration",
    button_create_category: "Thema hinzufügen",
  },
  execute_dma_confirm_dialog: {
    title: "Bestimmung der doppelten Wesentlichkeit abschließen?",
    // drafts_warning: "Es gibt noch nicht freigegebene Auswirkungen oder Chancen und Risiken!",
    content_text:
      "Sind Sie sicher, dass Sie die Bestimmung der doppelten Wesentlichkeit abschließen möchten?\n\n" +
      "Dieser Vorgang kann nicht rückgängig gemacht werden.",
    button_cancel: "Abbrechen",
    button_confirm: "Abschließen",
  },
  double_materiality_assessment_sub_category_component: {
    dma_category_actions_component: {
      tooltip_edit: "Thema bearbeiten",
      tooltip_add_child: "Sub-Thema hinzufügen",
      button_category_irrelevant: "Nicht relevant",
      tooltip_stakeholder_feedback: "Stakeholder-Feedback",
    },
    material_impacts_header: "Auswirkungen",
    material_impacts_empty_message: "Noch keine Auswirkungen",
    category_opted_out_notice: "Dieses Thema wurde als nicht relevant markiert.",
    label_category_opted_out_reason: "Begründung:",
    button_edit_opt_out_reason_tooltip: "Begründung bearbeiten",
    button_opt_back_into_category: "Zurücksetzen",
    create_material_impact_tooltip: "Auswirkung hinzufügen",
    edit_material_impact_tooltip: "Auswirkung bearbeiten",
    financial_effects_header: "Chancen und Risiken",
    financial_effects_empty_message: "Noch keine Chancen oder Risiken",
    create_financial_effect_tooltip: "Chance oder Risiko hinzufügen",
    edit_financial_effect_tooltip: "Chance oder Risiko bearbeiten",
    "downstream-value-chain_name": "Nachgelagerte Wertschöpfungskette",
    "own-organization_name": "Eigene Organisation",
    "upstream-value-chain_name": "Vorgelagerte Wertschöpfungskette",
    show_actions: "Aktionen einblenden",
    hide_actions: "Aktionen ausblenden",
  },
  dma_financial_effect_general: {
    effect_type: {
      investment_costs: "Investitionskosten",
      operations_costs: "Operative Kosten",
      capital_costs: "Kapitalkosten",
      assets_value_change: "Wertänderung von Anlagen",
      revenue: "Umsätze",
      access_to_capital: "Kapitalzugang",
    },
  },
  multiple_chip_component: {
    only_more_items: "{{count}} gewählt",
    more_items: "+{{count}} weitere",
  },
  dma_impact_or_effect_edit_dialog_common: {
    // Inputs
    label_title: "Titel",
    error_title_required: "Bitte geben Sie einen Titel ein.",
    label_description: "Beschreibung und Hintergrund (optional)",
    description_explanation:
      "Erklärungen zum Sachverhalt, Beschreibung der zugrundeliegenden Daten, Annahmen und " +
      "Vorgehensweisen zur Bewertung / Berechnung, sowie Auflistung involvierter Bereiche.",
    label_assessmentExplanation: "Erläuterung der Bewertung",
    assessmentExplanation_explanation:
      "Erläuterung der Bewertung und der zugrundeliegenden Annahmen.",
    label_internalCommunication: "Kommentar",
    internalCommunication_info:
      'Nutzen Sie dieses Feld zur internen Kommunikation um Ihren Kolleg:innen Fragen zu stellen, Hinweise oder Feedback zu geben.\n Tipp: Beginnen sie Ihren Kommentar mit: "Eigener Name: Meine Nachricht".',
    error_assessmentExplanation_required: "Bitte geben Sie eine Erläuterung an.",
    // Evaluation Section
    header_horizon_evaluation: "Bewertung",
    header_total_evaluation: "Gesamtbewertung über alle drei Zeithorizonte",
    severity_label: "Schweregrad",
    severity_currency_label: "Schweregrad in Euro",
    no_equivalent_set_1: "Keine individuellen Werte in",
    no_equivalent_set_2: "Konfiguration",
    no_equivalent_set_3: "definiert.",
    materiality_label: "Wesentlich",
    materiality_degree_label: "Wesentlichkeitsgrad",
    materiality_true_state: "Ja",
    materiality_false_state: "Nein",
    edit_materiality_tooltip: "Berechnete Wesentlichkeit manuell überschreiben",
    calculated_materiality_label: "Berechnete Wesentlichkeit",
    custom_materiality_label: "Benutzerdefinierte Wesentlichkeit",
    cancel_edit_materiality_tooltip: "Abbrechen und berechnete Wesentlichkeit heranziehen",
    label_user_materiality_reason: "Begründung für benutzerdefinierte Wesentlichkeit",
    error_user_materiality_reason_required: "Bitte geben Sie eine Begründung an.",
    inherit_form_horizon_select_label: "Bewertung des Zeithorizonts",
    inherit_from_shortTerm: "Werte von Kurzfristig übernehmen",
    inherit_from_mediumTerm: "Werte von Mittelfristig übernehmen",
    inherit_from_longTerm: "Werte von Langfristig übernehmen",
    // inherits_values_itself: "Zeithorizont übernimmt selbst Werte",
    horizon_shortTerm_name: "Kurzfristig",
    horizon_shortTerm_time_span: "1 Jahr",
    horizon_mediumTerm_name: "Mittelfristig",
    horizon_mediumTerm_time_span: "2 - 5 Jahre",
    horizon_longTerm_name: "Langfristig",
    horizon_longTerm_time_span: "> 5 Jahre",
    no_inheritance_name: "Manuell",
    in_use_by_other_horizon: "Andere Zeithorizonte übernehmen Werte von diesem Zeithorizont.",
    horizons_comment_label:
      "Begründung der unterschiedlichen Bewertung der Zeithorizonte (optional)",
    horizons_comment_explanation:
      "Begründen Sie, warum es zu einer unterschiedlichen Bewertung der einzelnen Zeithorizonte " +
      "(kurz-, mittel- und langfristig) kommt und erläutern Sie kurz die Abweichungen.",
    // Areas
    areas_select_label: "Betroffene Bereiche",
    error_effect_area_required: "Bitte wählen Sie zumindest einen Bereich.",
    "downstream-value-chain_name": "Nachgelagerte Wertschöpfungskette",
    "own-organization_name": "Eigene Organisation",
    "upstream-value-chain_name": "Vorgelagerte Wertschöpfungskette",
    // Slider Values
    slider_value_very_low: "Sehr niedrig",
    slider_value_low: "Eher niedrig",
    slider_value_average: "Mittel",
    slider_value_high: "Eher hoch",
    slider_value_very_high: "Sehr hoch",
    // Slider Values Extent
    slider_value_extent_very_low: "Minimal",
    slider_value_extent_low: "Niedrig",
    slider_value_extent_average: "Mittel",
    slider_value_extent_high: "Hoch",
    slider_value_extent_very_high: "Sehr hoch/absolut",
    // Slider Values Scale
    slider_value_scale_very_low: "Limitiert",
    slider_value_scale_low: "Konzentriert",
    slider_value_scale_average: "Mittel",
    slider_value_scale_high: "Weitverbreitet",
    slider_value_scale_very_high: "Global/Total",
    // Slider Values Irreversibility
    slider_value_irreversibility_very_low: "Relativ leicht/kurzfristig umkehrbar",
    slider_value_irreversibility_low: "umkehrbar mit Aufwand",
    slider_value_irreversibility_average: "Schwer/mittelfristig umkehrbar",
    slider_value_irreversibility_high: "Sehr schwer/langfristig umkehrbar",
    slider_value_irreversibility_very_high: "Nicht umkehrbar",
    // Responsible
    label_responsible_user: "Verantwortliche Person",
    // Assigned Users
    label_assigned_users: "Zugewiesene Personen",
    explanation_assigned_users:
      "Zugewiesene Personen haben Zugriff auf dieses IRO und können Daten eingeben sowie die Bewertung verändern, solange die Daten nicht freigegeben wurden.",
    no_assigned_users: "Keine Personen zugewiesen",
    add_assigned_user_tooltip: "Person zuweisen",
    no_more_users_to_assign: "Keine weiteren Benutzer verfügbar",
    confirm_dialog: {
      button_confirm: "$t(buttons:confirm)",
      button_cancel: "$t(buttons:cancel)",
    },
  },
  dma_material_impact_edit_dialog: {
    title_create: "Auswirkung erstellen",
    title_edit: 'Auswirkung <italic>"{{title}}"</italic> bearbeiten',
    delete_tooltip: "Auswirkung entfernen",
    // Impact Inputs
    subtitle_type: "Art der Auswirkung",
    type_actual: "Tatsächliche Auswirkung",
    type_potential: "Potentielle Auswirkung",
    label_hasNegativeEffectOnHumanRights: "Hat potentiell negative Auswirkungen auf Menschenrechte",
    label_probability: "Eintrittswahrscheinlichkeit",
    explanation_probability: "Wie wahrscheinlich tritt die Auswirkung ein?",
    subtitle_result: "Ergebnis der Auswirkung",
    result_positive: "Positiv",
    result_negative: "Negativ",
    label_irreversibility: "Unumkehrbarkeit",
    explanation_irreversibility: `Beschreiben Sie, wie schwer es ist, im Nachhinein die negative Auswirkung auszugleichen oder rückgängig zu machen.
      Ist es sehr leicht den Ursprungszustand wieder herzustellen, dann ist die Unumkehrbarkeit sehr niedrig.
      Ist es besonders schwer oder gar unmöglich, den Ursprungszustand wieder herzustellen, dann ist die Unumkehrbarkeit als sehr hoch einzustufen.`,
    label_extent: "Ausmaß",
    explanation_extent: `Bewerten Sie das Ausmaß / die Höhe der Auswirkung auf den wesentlich betroffenen Umfang.
    Ermitteln Sie dazu zuerst den Umfang der wesentlich Betroffenen und bewerten Sie dann, das Ausmaß auf diese Betroffenengruppe.
    Beispiel: Aufzugerneuerung an einem ihrer Standorte:
    Gibt es zwar einen Lift im verbundenen Nachbargebäude und nur wenige Stockwerke stufenlos verbunden, so kann das Ausmaß für zB. Rollstuhlfahrer als "eher hoch" bewertet werden.`,
    label_scope: "Umfang",
    explanation_scope: `Geben Sie den Umfang an, wie weitreichend die Auswirkung in Bezug auf die entsprechende Gesamtheit ist. Stellen Sie sich dazu folgende 3 Fragen:
      1. Wen könnte die Auswirkung grundsätzlich betreffen?
      2. Wer ist wesentlich davon betroffen?
      3. Wie hoch ist der Anteil/Umfang der wesentlich Betroffenen in Bezug auf mögliche Betroffene?\n
      Beispiel: Aufzugerneuerung an einem ihrer Standorte:
      1. Nur Mitarbeiter:innen, Kunden und Personen, die am Standort sind.
      2. Nur Menschen, die auch auf den Lift angewiesen sind
      3. Gibt es eine Treppe und der Standort wird nur von vereinzelten Menschen mit eingeschränkter Mobilität frequentiert kann der Umfang als "sehr niedrig" bewertet werden.`,
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Auswirkung unvollständig!",
    incomplete_for_shared_notice:
      "Um eine Auswirkung freigeben zu können muss eine Erläuterung der Bewertung angegeben werden.",
    // Buttons
    button_cancel: "Abbrechen",
    button_close: "Schließen",
    button_save: "Speichern",
    button_create: "Erstellen",
    explanations: {
      esrs_e1: {
        extent:
          "Wie stark ist die Wirkung auf den Klimawandel / die Anpassung an den Klimawandel? Wie viel an CO2e wird ausgestoßen? Wie viel Energie wird verbraucht?​ \n\nsehr hoch/ absolut\n...Sehr starke Auswirkungen oder Emissionen und Beschädigung oder vollständige Zerstörung\nhoch\n...Hohe Auswirkungen oder Emissionen und Schäden\nmittel\n...Mittlere Auswirkungen oder Emissionen und Schäden\nniedrig\n...Geringe Auswirkungen oder Emissionen und Schäden\nminimal\n...Minimale Auswirkungen oder Emissionen und Schäden für die Betroffenen, die Wirtschaft oder die Umwelt",
        scope:
          "Wie weitverbreitet ist die Auswirkung? Wo tritt sie überall auf und wie weit verbreitet wirkt sie?\n\nglobal/ total\nweitverbreitet\nmittel\nkonzentriert\nlimitiert",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_e2: {
        extent:
          "Wie stark ist die Wirkung auf die betroffenen Böden/Wasserkörper/Luft? \n\nsehr hoch / absolut\n...Sehr starke Auswirkungen oder Emissionen und Beschädigung oder vollständige Zerstörung\nhoch\n...Hohe Auswirkungen oder Emissionen und Schäden\nmittel\n...Mittlere Auswirkungen oder Emissionen und Schäden\nniedrig\n...Geringe Auswirkungen oder Emissionen und Schäden\nminimal\n...Minimale Auswirkungen oder Emissionen und Schäden für die Betroffenen, die Wirtschaft oder die Umwelt",
        scope:
          "Wie weitverbreitet ist die Auswirkung? Wo tritt sie überall auf in Relation zum gesamten Gebiet, in dem das Unternehmen tätig ist? Wie weitverbreitet wirkt sie? (zB lokale Bodenverschmutzung vs. Verschmutzung von Flüssen, die sich regional verbreitet)​\n\nglobal/ total\nweitverbreitet\nmittel\nkonzentriert\nlimitiert",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_e3: {
        extent:
          "Wie stark ist die Wirkung auf die betroffenen Wasserkörper bzw. die Meeresressourcen? (Verbrauch x Knappheit)\n\nsehr hoch / absolut\n...Sehr starke Auswirkungen oder Emissionen und Beschädigung oder vollständige Zerstörung\nhoch\n...Hohe Auswirkungen oder Emissionen und Schäden\nmittel\n...Mittlere Auswirkungen oder Emissionen und Schäden\nniedrig\n...Geringe Auswirkungen oder Emissionen und Schäden\nminimal\n...Minimale Auswirkungen oder Emissionen und Schäden für die Betroffenen, die Wirtschaft oder die Umwelt",
        scope:
          "Wie weitverbreitet ist die Auswirkung? Wo tritt sie überall auf in Relation zum gesamten Gebiet, in dem das Unternehmen tätig ist? Wie weitverbreitet wirkt sie? (zB lokale Bodenverschmutzung vs. Verschmutzung von Flüssen, die sich regional verbreitet)​​\n\nglobal/ total\nweitverbreitet\nmittel\nkonzentriert\nlimitiert",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_e4: {
        extent:
          "Wie stark ist die Wirkung auf die betroffenen Arten bzw. Ökosysteme?​\n\nsehr hoch/ absolut\n...Sehr starke Auswirkungen oder Emissionen und Beschädigung oder vollständige Zerstörung\nhoch\n...Hohe Auswirkungen oder Emissionen und Schäden\nmittel\n...Mittlere Auswirkungen oder Emissionen und Schäden\nniedrig\n...Geringe Auswirkungen oder Emissionen und Schäden\nminimal\n...Minimale Auswirkungen oder Emissionen und Schäden für die Betroffenen, die Wirtschaft oder die Umwelt",
        scope:
          "Wie weitverbreitet ist die Auswirkung? Wo tritt sie überall auf in Relation zum gesamten Gebiet, in dem das Unternehmen tätig ist? Wie weitverbreitet wirkt sie? (zB lokale Bodenverschmutzung vs. Verschmutzung von Flüssen, die sich regional verbreitet)​\n\nglobal/ total\nweitverbreitet\nmittel\nkonzentriert\nlimitiert",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_e5: {
        extent:
          "Wie 'kritisch' sind die genutzten Ressourcen bzw. Abfälle? zB bezüglich Knappheit, erneuerbare vs. nicht erneuerbare Rohstoffe und Kreislauffähigkeit? Welche Menge an Ressourcen wird bezogen (auch in Relation zur gesamten verfügbaren Menge)? Wie groß ist das Abfallvolumen?​\n\nsehr hoch/ absolut . . . Sehr hoher Ressourcenverbrauch oder kein Recycling dieser Ressourcen\nhoch . . . Hohe Ressourcennutzung oder nur geringes Recycling dieser Ressourcen\nmittel . . . Mittlere Auslastung der Ressourcen oder teilweise Reduzierung dieser Ressourcen\nniedrig . . . Geringe Ressourcennutzung oder weitgehendes Recycling dieser Ressourcen\nminimal . . . Sehr geringe Ressourcennutzung oder vollständiges Recycling dieser Ressourcen",
        scope:
          "Wie weitverbreitet ist die Auswirkung? Wo tritt sie überall auf und wie weit verbreitet wirkt sie?​\n\nglobal / total\nweitverbreitet\nmittel\nkonzentriert\nlimitiert",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_s1: {
        extent:
          "Wie schwerwiegend ist die Wirkung auf die betroffenen Menschen? Werden zB grundlegende Menschenrechte verletzt/eingeschränkt? Oder die körperliche und/oder mentale Gesundheit (maßgeblich) beeinträchtigt?​\n\nsehr hoch / absolut . . . Führt zum Tod oder zu gesundheitlichen Beeinträchtigungen, die zu einer erheblichen Einschränkung der Lebensqualität und/oder Lebenserwartung führen können\nhoch . . . Erhebliche Auswirkungen auf die Arbeitsbedingungen oder kritische Menschenrechtsverletzungen; erhebliche Einschränkungen beim Zugang zu den grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nmittel . . . Einschlägige Auswirkungen auf die Arbeitsbedingungen, das Wohlbefinden und die Gesundheit oder konkrete Menschenrechtsverletzungen beim Zugang zu den grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nniedrig . . . Geringe Auswirkungen auf das Wohlbefinden der Arbeitnehmer, geringe Auswirkungen auf die Menschenrechte, die Gesundheit und geringe Einschränkung/Beeinträchtigung des Zugangs zu grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nminimal . . . Keine erheblichen Auswirkungen auf die Arbeitnehmer und keine Auswirkungen, die das Recht auf Leben, Gesundheit oder Zugang zu den Grundbedürfnissen des Lebens verletzen",
        scope:
          "Wie groß ist der Anteil der betroffenen Menschen in Relation zur jeweiligen Grundgesamtheit? D.h. zB​\nWie groß ist der Anteil der von dieser Auswirkung betroffenen Mitarbeiter:innen/Verbraucher:innen?\nWie viele Arbeitskräfte in der Wertschöpfungskette sind betroffen unter Berücksichtigung des Risikoprofils der Lieferanten​?\nwie viele der Menschen in den Gemeinschaften rund um Betriebs- oder Abbaustätten sind von Auswirkungen betroffen​?\n\nglobal/ total . . . Hohe Anzahl betroffener Mitarbeiter: >50 Prozent der Gesamtbelegschaft \nweitverbreitet . . . Mäßig hohe Zahl der betroffenen Personen: 30-50 Prozent der Gesamtbelegschaft\nmittel . . . Mäßige Anzahl von Betroffenen: 15-30 Prozent der Gesamtbelegschaft\nkonzentriert . . . Geringe bis mäßige Anzahl von Betroffenen: 5-15 Prozent der Gesamtbelegschaft\nlimitiert . . . Keine oder nur eine geringe Anzahl von Personen betroffen: <5 Prozent der Gesamtbelegschaft",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_s2: {
        extent:
          "Wie schwerwiegend ist die Wirkung auf die betroffenen Menschen? Werden zB grundlegende Menschenrechte verletzt/eingeschränkt? Oder die körperliche und/oder mentale Gesundheit (maßgeblich) beeinträchtigt?​\n\nsehr hoch/ absolut . . . Führt zum Tod oder zu gesundheitlichen Beeinträchtigungen, die zu einer erheblichen Einschränkung der Lebensqualität und/oder Lebenserwartung führen können\nhoch . . . Erhebliche Auswirkungen auf die Arbeitsbedingungen oder kritische Menschenrechtsverletzungen; erhebliche Einschränkungen beim Zugang zu den grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nmittel . . . Einschlägige Auswirkungen auf die Arbeitsbedingungen, das Wohlbefinden und die Gesundheit oder konkrete Menschenrechtsverletzungen beim Zugang zu den grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nniedrig . . . Geringe Auswirkungen auf das Wohlbefinden der Arbeitnehmer, geringe Auswirkungen auf die Menschenrechte, die Gesundheit und geringe Einschränkung/Beeinträchtigung des Zugangs zu grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nminimal . . . Keine erheblichen Auswirkungen auf die Arbeitnehmer und keine Auswirkungen, die das Recht auf Leben, Gesundheit oder Zugang zu den Grundbedürfnissen des Lebens verletzen",
        scope:
          "Wie groß ist der Anteil der betroffenen Menschen in Relation zur jeweiligen Grundgesamtheit? D.h. zB​\nWie groß ist der Anteil der von dieser Auswirkung betroffenen Mitarbeiter:innen/Verbraucher:innen?​\nWie viele Arbeitskräfte in der Wertschöpfungskette sind betroffen unter Berücksichtigung des Risikoprofils der Lieferanten​?\nwie viele der Menschen in den Gemeinschaften rund um Betriebs- oder Abbaustätten sind von Auswirkungen betroffen​?\n\nglobal/ total . . . Hohe Anzahl betroffener Mitarbeiter: >50 % der Gesamtbelegschaft\nweitverbreitet . . . Mäßig hohe Zahl der betroffenen Personen: 30-50% der Gesamtbelegschaft\nmittel . . . Mäßige Anzahl von Betroffenen: 15-30 % der Gesamtbelegschaft \nkonzentriert . . . Geringe bis mäßige Anzahl von Betroffenen: 5-15% der Gesamtbelegschaft\nlimitiert . . . Keine oder nur eine geringe Anzahl von Personen betroffen: <5% der Gesamtbelegschaft ",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_s3: {
        extent:
          "Wie schwerwiegend ist die Wirkung auf die betroffenen Menschen? Werden zB grundlegende Menschenrechte verletzt/eingeschränkt? Oder die körperliche und/oder mentale Gesundheit (maßgeblich) beeinträchtigt?​\n\nsehr hoch/ absolut . . . Führt zum Tod oder zu gesundheitlichen Beeinträchtigungen, die zu einer erheblichen Einschränkung der Lebensqualität und/oder Lebenserwartung führen können\nhoch . . . Erhebliche Auswirkungen auf die Arbeitsbedingungen oder kritische Menschenrechtsverletzungen; erhebliche Einschränkungen beim Zugang zu den grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nmittel . . . Relevante Auswirkungen auf das Wohlbefinden und die Gesundheit oder spürbare Menschenrechtsverletzungen beim Zugang zu grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nniedrig . . . Geringe Auswirkungen auf das Wohlbefinden von Menschen, geringe Auswirkungen auf die Menschenrechte, die Gesundheit und geringe Einschränkung/Beeinträchtigung des Zugangs zu grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nminimal . . . Keine erheblichen Auswirkungen auf die Arbeitnehmer und keine Auswirkungen, die das Recht auf Leben, Gesundheit oder Zugang zu den Grundbedürfnissen des Lebens verletzen",
        scope:
          "Wie groß ist der Anteil der betroffenen Menschen in Relation zur jeweiligen Grundgesamtheit? D.h. zB​\nWie groß ist der Anteil der von dieser Auswirkung betroffenen Mitarbeiter:innen/Verbraucher:innen?​\nWie viele Arbeitskräfte in der Wertschöpfungskette sind betroffen unter Berücksichtigung des Risikoprofils der Lieferanten​?\nwie viele der Menschen in den Gemeinschaften rund um Betriebs- oder Abbaustätten sind von Auswirkungen betroffen​?\n\nglobal/ total . . . Hohe Anzahl betroffener Mitarbeiter: >50 % der Gesamtbelegschaft\nweitverbreitet . . . Mäßig hohe Zahl der betroffenen Personen: 30-50% der Gesamtbelegschaft\nmittel . . . Mäßige Anzahl von Betroffenen: 15-30 % der Gesamtbelegschaft \nkonzentriert . . . Geringe bis mäßige Anzahl von Betroffenen: 5-15% der Gesamtbelegschaft\nlimitiert . . . Keine oder nur eine geringe Anzahl von Personen betroffen: <5% der Gesamtbelegschaft ",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_s4: {
        extent:
          "Wie schwerwiegend ist die Wirkung auf die betroffenen Menschen? Werden zB grundlegende Menschenrechte verletzt/eingeschränkt? Oder die körperliche und/oder mentale Gesundheit (maßgeblich) beeinträchtigt?​\n\nsehr hoch/ absolut . . . Führt zum Tod oder zu gesundheitlichen Beeinträchtigungen, die zu einer erheblichen Einschränkung der Lebensqualität und/oder Lebenserwartung führen können\nhoch . . . Erhebliche Auswirkungen auf die Arbeitsbedingungen oder kritische Menschenrechtsverletzungen; erhebliche Einschränkungen beim Zugang zu den grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nmittel . . . Relevante Auswirkungen auf das Wohlbefinden und die Gesundheit oder spürbare Menschenrechtsverletzungen beim Zugang zu grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nniedrig . . . Geringe Auswirkungen auf das Wohlbefinden von Menschen, geringe Auswirkungen auf die Menschenrechte, die Gesundheit und geringe Einschränkung/Beeinträchtigung des Zugangs zu grundlegenden Lebensbedürfnissen (einschließlich Bildung, Lebensunterhalt usw.)\nminimal . . . Keine erheblichen Auswirkungen auf die Arbeitnehmer und keine Auswirkungen, die das Recht auf Leben, Gesundheit oder Zugang zu den Grundbedürfnissen des Lebens verletzen",
        scope:
          "Wie groß ist der Anteil der betroffenen Menschen in Relation zur jeweiligen Grundgesamtheit? D.h. zB​\nWie groß ist der Anteil der von dieser Auswirkung betroffenen Mitarbeiter:innen/Verbraucher:innen?​\nWie viele Arbeitskräfte in der Wertschöpfungskette sind betroffen unter Berücksichtigung des Risikoprofils der Lieferanten​?\nwie viele der Menschen in den Gemeinschaften rund um Betriebs- oder Abbaustätten sind von Auswirkungen betroffen​?\n\nglobal/ total . . . Hohe Anzahl betroffener Mitarbeiter: >50 % der Gesamtbelegschaft\nweitverbreitet . . . Mäßig hohe Zahl der betroffenen Personen: 30-50% der Gesamtbelegschaft\nmittel . . . Mäßige Anzahl von Betroffenen: 15-30 % der Gesamtbelegschaft \nkonzentriert . . . Geringe bis mäßige Anzahl von Betroffenen: 5-15% der Gesamtbelegschaft\nlimitiert . . . Keine oder nur eine geringe Anzahl von Personen betroffen: <5% der Gesamtbelegschaft ",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_g1: {
        extent:
          "Wie schwerwiegend ist die Wirkung auf die Öffentlichkeit bzw. die betroffenen Lieferanten/Geschäftspartner/Mitbewerber?​\n\nsehr hoch/ absolut\nhoch\nmittel\nniedrig\nminimal",
        scope:
          "Wie weitverbreitet ist die Auswirkung in Relation zum gesamten Gebiet, in dem das Unternehmen tätig ist bzw. wie hoch ist der Anteil der betroffenen Stakeholder (zB innerhalb der Lieferanten)?​\n\nglobal/ total . . . Hohe Anzahl von Betroffenen, im Verhältnis zum gesamten Unternehmen \nweitverbreitet . . . Mäßig hohe Anzahl von Betroffenen\nmittel . . . Mäßige Anzahl von Betroffenen \nkonzentriert . . . Geringe bis mäßige Anzahl von Menschen betroffen\nlimitiert . . . Nein bis geringe Anzahl von Betroffenen ",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
      esrs_o: {
        extent:
          "Wie schwerwiegend ist die Wirkung auf das jeweilige Thema?​\n\nsehr hoch/ absolut\nhoch\nmittel\nniedrig\nminimal",
        scope:
          "Wie weitverbreitet ist die Auswirkung? Wo tritt sie überall auf und wie weit verbreitet wirkt sie?​\n\nglobal/ total\nweitverbreitet\nmittel\nkonzentriert\nlimitiert",
        irreversibility:
          "Wie schwer oder leicht sind die Auswirkungen wieder rückgängig machbar?\n\nnicht umkehrbar\nSehr schwer zu ändern oder langfristig umkehrbar\nschwer umkehrbar oder mittelfristig umkehrbar\nMit Aufwand (Zeit und Kosten) änderbar\nRelativ leicht oder kurzfristig zu ändern",
      },
    },
  },
  dma_financial_effect_common: {
    effect_category: {
      select_label: "Kategorie",
      market: "Markt",
      technology: "Technologie",
      policy_and_legal: "Politik und Recht",
      reputation: "Reputation",
      acute_physical: "Akut physisch",
      chronic_physical: "Chronisch physisch",
      resource_efficiency: "Ressourceneffizienz",
      products_services: "Produkte / Dienstleistungen",
      financial_incentives: "Finanzielle Anreize",
      others: "Sonstige",
    },
  },
  dma_financial_effect_edit_dialog: {
    title_create: "Chance oder Risiko erstellen",
    title_edit: 'Chance oder Risiko "<italic>{{title}}</italic>" bearbeiten',
    delete_tooltip: "Chance oder Risiko entfernen",
    // Effect Inputs
    effect_type_select_label: "Chance oder Risiko",
    risk_name: "Risiko",
    chance_name: "Chance",
    indefinable_name: "Keine Zuordnung",
    subtitle_effect_type: "Finanzielle Wirkung auf",
    subtitle_effect_type_info:
      "<p>Ein Nachhaltigkeitsaspekt ist unter finanziellen Gesichtspunkten wesentlich, wenn er wesentliche finanzielle " +
      "Auswirkungen auf das Unternehmen nach sich zieht oder wenn diese nach vernünftigem Ermessen zu erwarten sind.</p>" +
      "<p>Dies trifft zu, wenn durch einen Nachhaltigkeitsaspekt Risiken oder Chancen entstehen, die innerhalb von kurz-, " +
      "mittel- oder langfristigen Zeithorizonten einen wesentlichen Einfluss auf die Entwicklung, die Finanzlage, die " +
      "finanzielle Leistungsfähigkeit, die Cashflows, den Zugang zu Finanzmitteln oder die Kapitalkosten des Unternehmens " +
      "haben (oder wenn ein solcher Einfluss nach vernünftigem Ermessen zu erwarten ist).</p><p>Risiken und Chancen können sich " +
      "aus Ereignissen in der Vergangenheit oder in der Zukunft ergeben. Die finanzielle Wesentlichkeit eines " +
      "Nachhaltigkeitsaspekts ist nicht auf Aspekte beschränkt, die unter die Kontrolle des Unternehmens fallen, " +
      "sondern umfasst auch Informationen über wesentliche Risiken und Chancen, die auf Geschäftsbeziehungen " +
      "außerhalb des bei der Erstellung von Abschlüssen verwendeten Konsolidierungskreises zurückzuführen sind.</p>",
    subtitle_additional_effect_types: "Weitere Arten:",
    error_effect_type_required: "Bitte wählen Sie zumindest eine Art für die Auswirkung.",
    label_potential_extent: "Potenzielles Ausmaß",
    label_probability_of_occurrence: "Eintrittswahrscheinlichkeit",
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Chance/Risiko unvollständig!",
    incomplete_for_shared_notice:
      "Folgende Punkte müssen noch erfüllt werden, damit die Chance / das Risiko freigeben werden kann:",
    incomplete_points: {
      // These don't follow naming convention because they have to match the names
      // of the associated input fields :)
      assessmentExplanation: "Es muss eine Erläuterung der Bewertung angegeben werden.",
      effectType: "Es muss zumindest eine finanzielle Auswirkung angegeben werden.",
    },
    // Buttons
    button_cancel: "Abbrechen",
    button_close: "Schließen",
    button_save: "Speichern",
    button_create: "Erstellen",
  },
  delete_impact_or_effect_confirm_dialog: {
    title_material_impact: 'Auswirkung <italic>"{{name}}"</italic> löschen?',
    title_financial_effect: 'Chance oder Risiko <italic>"{{name}}"</italic> löschen?',
    content_text_material_impact:
      "Sind Sie sicher, dass Sie die Auswirkung {{name}} löschen möchten?",
    content_text_financial_effect:
      "Sind Sie sicher, dass Sie die Chance oder das Risiko {{name}} löschen möchten?",
    button_cancel: "Abbrechen",
    button_delete: "Löschen",
  },
  esrs_topic_opt_out_confirm_dialog: {
    title_opt_out: "Keine Auswirkungen für dieses ESRS-Thema erfassen?",
    title_edit: "Begründung aktualisieren",
    content_text:
      'Sind Sie sicher, dass Sie für das ESRS-Thema "{{topic}}" ({{name}}) weder ' +
      "Auswirkungen noch Chancen oder Risiken erfassen möchten? Sie können Ihre Entscheidung " +
      "jederzeit rückgängig machen.",
    opt_out_reason_label: "Begründung",
    opt_out_reason_required: "Bitte geben Sie eine Begründung an.",
    button_cancel: "Abbrechen",
    button_opt_out: "Bestätigen",
    button_edit: "Speichern",
  },
  // DMA Dashboard
  dma_dashboard: {
    title: "Doppelte Wesentlichkeitsanalyse Dashboard",
    button_back_to_dma: "Zurück zu Eingabe",
    title_materiality_matrix: "Wesentlichkeitsmatrix",
    // Breadcrumbs
    breadcrumbs_item_total: "Gesamt",
    // ### DMA Matrix
    title_overview: "Übersicht",
    // axis labels
    label_axis_financial_materiality: "Finanzieller Wesentlichkeitsgrad",
    label_axis_material_materiality: "Wesentlichkeitsgrad der Auswirkungen",
    // topics
    label_topic_environmental: "Umwelt",
    label_topic_social: "Soziales",
    label_topic_governance: "Governance",
    label_topic_other: "Sonstiges",
    // categories
    no_categories_to_display_notice: "Keine Themen zum Anzeigen",
    label_matrix_children: "Unterthemen",
    // ### Overview Table
    title_overview_table: "Übersichtstabelle",
    label_show_impacts: "Auswirkungen",
    label_show_effects: "Chancen/Risiken",
    button_expand_all: "Alle ausklappen",
    button_collapse_all: "Alle einklappen",
    // Headers
    table_header_material_impacts_group: "Auswirkungen",
    table_header_financial_effects_group: "Chancen/Risiken",
    header_name: "Name",
    header_materiality_short_term: "Kurzfristig",
    header_materiality_medium_term: "Mittelfristig",
    header_materiality_long_term: "Langfristig",
    header_materiality_max: "Maximal",
    header_max_materiality_degree: "Maximal",
    header_material: "Wesentlichkeit",
    // Content
    tooltip_opt_out: "Als nicht wesentlich markiert",
    tooltip_no_children: "Keine Unterthemen",
    materiality_value_true: "Wesentlich",
    materiality_value_false: "Nicht wesentlich",
    // Translations for TableCells
    cells: {
      value_exceeds_threshold: "Dieser Wert übersteigt den Schwellenwert von {{threshold}}.",
    },
    // IRO List
    title_iro_overview_list: "Auswirkungen, Chancen und Risiken",
  },
  // Common DMA Components
  iros_overview_list_component: {
    table_header_title: "Titel",
    table_header_esrs_topic: "Thema",
    table_header_type: "Typ",
    table_header_result: "Wirkung",
    table_header_result_tooltip: "kurzfristig mittelfristig langfristig",
    table_header_materiality_degree: "Wesentlichkeitsgrad",
    iro_types: {
      impact: "Auswirkung",
      opportunity: "Chance",
      risk: "Risiko",
      ro_indefinable: "Chance/Risiko",
    },
    iro_types_plural: {
      impact: "Auswirkungen",
      opportunity: "Chancen",
      risk: "Risiken",
      ro_indefinable: "Chancen/Risiken",
    },
    // Notices
    notice_no_iros_at_all: "Keine Auswirkungen, Chancen oder Risiken erfasst.",
    notice_no_iros_for_filters:
      "Für die gesetzten Filter wurden keine Auswirkungen, Chancen oder Risiken gefunden.",
  },
  fill_in_helper_iros_list_component: {
    title: "Auswirkungen, Chancen und Risiken",
    filter_type: "Filter",
    type_hints: {
      "only-material": "Nur wesentliche Auswirkungen, Chancen und Risiken werden aufgelistet",
      "topic-specific":
        "Nur Auswirkungen, Chancen und Risiken, die dem aktuellen ESRS Thema zugeordnet sind werde aufgelistet",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_common: {
    header_stakeholder: "Stakeholder",
    header_organization: "Organisation",
    header_material: "Auswirkungen",
    header_financial: "Chancen/Risiken",
    title_material: "Auswirkungen",
    title_financial: "Chancen/Risiken",
    tooltip_feedback_not_requested: "Feedback nicht angefragt",
    tooltip_feedback_accepted: "Zugestimmt",
    tooltip_feedback_accepted_with_comment: "Zugestimmt mit Kommentar",
    tooltip_feedback_changes_requested: "Nicht zugestimmt, Änderungen angefragt",
    tooltip_feedback_pending: "Feedback ausstehend",
    notice_feedback_pending: "Noch kein Feedback erhalten",
    tooltip_copy_link: "Freigabelink des Stakeholders zur Feedbackanfrage kopieren",
    // Warnings
    category_open_warning:
      "Das Thema ist weder als nicht relevant markiert noch wurden Auswirkungen oder Chancen/Risiken erfasst. " +
      "Wenn Sie jetzt bereits Stakeholder-Feedback einholen, kann das Ihre Ergebnisse verfälschen und rechtliche Folgen für Sie haben.",
    financial_effects_drafts_warning:
      "Noch nicht alle Chancen/Risiken wurden freigegeben! Wenn Sie jetzt bereits " +
      "Stakeholder-Feedback einholen, kann das Ihre Ergebnisse verfälschen und rechtliche Folgen für Sie haben.",
    material_impacts_drafts_warning:
      "Noch nicht alle Auswirkungen wurden freigegeben! Wenn Sie jetzt bereits " +
      "Stakeholder-Feedback einholen, kann das Ihre Ergebnisse verfälschen und rechtliche Folgen für Sie haben.",
  },
  dma_category_stakeholder_feedback_dialog: {
    title: "Stakeholder-Feedback zum Thema {{name}}",
    header_validation: "Feedback abnehmen",
    button_validate_material: "Auswirkungen abschließen",
    button_validate_financial: "Chancen/Risiken abschließen",
    material_verified: "Auswirkungen abgeschlossen",
    financial_verified: "Chancen/Risiken abgeschlossen",
    tooltip_undo_verified: "Abschluss zurücknehmen",
    tooltip_steps_to_verify_material:
      'Setzen Sie alle Auswirkungen (auch in untergeordneten Themen) auf "Freigegeben" und erhalten Sie von mindestens ' +
      "einem Stakeholder-Feedback oder hinterlegen Sie manuelles Feedback um die Auswirkungen abschließen zu können.",
    tooltip_steps_to_verify_financial:
      'Setzen Sie alle Chancen und Risiken (auch in untergeordneten Themen) auf "Freigegeben" und erhalten Sie von mindestens ' +
      "einem Stakeholder-Feedback oder hinterlegen Sie manuelles Feedback um die Auswirkungen abschließen zu können.",
    header_feedback: "Stakeholder-Feedback",
    title_validation_help: "Erklärung Feedback abschließen",
    content_validation_help:
      "Sobald Sie von mindestens einem Stakeholder-Feedback jeweils zu Auswirkungen und Chancen und Risiken " +
      "erhalten haben oder ein manuelles Feedback hinterlegt haben sowie wenn alle Auswirkungen und Chancen und Risiken " +
      "freigegeben sind, können Sie das Thema im jeweiligen Bereich abschließen. " +
      "Das Thema und alle untergeordneten Themen werden damit abgeschlossen und können nicht mehr bearbeitet werden. Erst wenn Sie " +
      "alle Themen abgeschlossen haben, können Sie die doppelte Wesentlichkeit abschließen.",
    empty_notice: "Noch kein Feedback angefragt",
    button_request_feedback: "Feedback anfragen",
    header_manual_feedback: "Manuelles Feedback",
    manual_feedback_empty_notice: "Noch kein manuelles Feedback erfasst",
    button_add_manual_feedback: "Manuelles Feedback hinzufügen",
  },
  dma_category_stakeholder_feedback_info_dialog: {
    title: "Erklärung Stakeholder",
    content_intro:
      "Bitte berücksichtigen Sie Vertreter aus all jenen Stakeholdergruppen, die Sie für dieses " +
      "Thema für relevant halten. Eine Liste typischer Stakeholdergruppen:",
    item_employees: "Beschäftigte und andere Arbeitskräfte",
    item_suppliers: "Lieferanten",
    item_consumer: "Verbraucher",
    item_customers: "Kunden",
    item_end_users: "Endnutzer",
    item_local_communities: "Lokale Gemeinschaften",
    item_protection_needing_groups: "Schutzbedürftige Gruppen",
    item_ngos: "NGOs",
    item_authorities:
      "Behörden, einschließlich Regulierungsbehörden, Aufsichtsbehörden und Zentralbanken",
    item_investors: "Bestehende und potentielle Investoren",
    item_scientific_constitutions: "Wissenschaftliche Einrichtungen",
    item_creditors: "Kreditgeber und andere Gläubiger, einschließlich Vermögensverwalter",
    item_credit_institutions: "Kreditinstitute",
    item_insurers: "Versicherungsunternehmen",
    item_nature: "Die Natur kann als stiller Stakeholder über Umweltdaten eingebunden werden",
  },
  dma_category_stakeholder_feedback_request_dialog: {
    title: "Feedback einholen",
    no_stakeholders_notice: "Noch keine Stakeholder für Organisation angelegt.",
    tooltip_request_feedback: "Feedback anfragen",
    tooltip_revoke_feedback_request: "Anfrage zurückziehen",
    tooltip_feedback_delivered: "Feedback erhalten - zurückziehen nicht möglich",
    button_manage_stakeholders: "Stakeholder verwalten",
    no_request_due_to_verified_material:
      "Feedback für Auswirkungen kann nicht mehr angefragt werden, da diese bereits abgeschlossen sind.",
    no_request_due_to_verified_financial:
      "Feedback für Chancen und Risiken kann nicht mehr angefragt werden, da diese bereits abgeschlossen sind.",
  },
  dma_stakeholder_feedback_view: {
    prefix_last_changed: "Zuletzt geändert:",
    prefix_assessment: "Bewertung:",
    prefix_comment: "Kommentar:",
  },
  // Manual Stakeholder-Feedback
  manual_stakeholder_feedback_common: {
    type_material: "Auswirkungen",
    type_financial: "Chancen/Risiken",
    source_none: "Keine Quelle",
    source_interview: "Interview",
    source_workshop: "Workshop",
    source_survey: "Umfrage",
    source_discussion: "Diskussionsrunde",
  },
  dma_category_manual_feedback_list: {
    header_stakeholder: "Stakeholder",
    header_type: "Feedback Art",
    header_source: "Quelle",
    title_confirm_delete_dialog: "Manuelles Feedback löschen?",
    content_confirm_delete_dialog:
      "Sind Sie sicher, dass Sie das manuelle Feedback löschen möchten?",
    title_feedback: "Feedback",
  },
  dma_category_manual_feedback_edit_dialog: {
    title_create: "Manuelles Feedback erstellen",
    title_edit: "Manuelles Feedback bearbeiten",
    label_feedback_type: "Feedback Art",
    label_feedback_source: "Quelle",
    label_stakeholders: "Stakeholder:",
    error_stakeholders_required: "Bitte geben Sie zumindest einen Stakeholder an.",
    no_stakeholders_selected_notice: "Keine Stakeholder ausgewählt",
    label_feedback: "Feedback",
    error_feedback_required: "Bitte geben Sie ein Feedback ein.",
    button_manage_stakeholders: "Stakeholder verwalten",
    no_stakeholders_available: "Keine Stakeholder verfügbar",
  },
  dma_language_picker_component: {
    title: "Doppelte Wesentlichkeitsanalyse",
    subtext:
      "Bevor Sie mit der doppelten Wesentlichkeitsanalyse starten können, müssen Sie sich für eine Sprache entscheiden, in der die Erfassung stattfinden soll:",
    button: "Fortfahren",
    confirm: {
      title: "Sprache festlegen?",
      content:
        'Sind Sie sicher, dass Sie die Sprache auf "{{language}}" festlegen möchten?<br/><br/><strong>Diese kann anschließend NICHT mehr geändert werden!</strong>',
    },
  },
  // Content Translation
  content_language_select_component: {
    label: "Sprache",
    translationsPerLanguage: {
      [IContentLanguage.De]: "Deutsch",
      [IContentLanguage.En]: "Englisch",
    } as { [key in IContentLanguage]: string },
  },
  // Audit Logging
  audit_logging_configuration: {
    title: "Aktivitätsprotokoll",
    empty_notice: "Keine Audit Logs vorhanden.",
    header_name: "Name",
    header_organization: "Organisation",
  },
  audit_logging_edit_list_component: {
    headers: {
      timeStamp: "Timestamp",
      userName: "Username",
      stakeholderName: "Stakeholder-Name",
      action: "Aktion",
    },
    events: {
      recording_period_create: "Eine neue Erfassungsperiode wurde erstellt.",
      recording_period_update: "Eine Erfassungsperiode wurde bearbeitet.",
      recorded_esrs_topic_opt_in: "Es wurde mit der Erfassung für das Thema begonnen.",
      recorded_esrs_topic_opt_out: "Die Erfassung für dieses Thema wurde übersprungen.",
      dma_configuration_update: "Die DMA-Konfiguration wurde bearbeitet.",
      dma_category_create: "Eine DMA-Kategorie wurde erstellt.",
      dma_category_update: "Eine DMA-Kategorie wurde bearbeitet.",
      dma_category_delete: "Eine DMA-Kategorie wurde gelöscht.",
      dma_category_opt_in: "Es wurde mit der Erfassung für die Kategorie begonnen.",
      dma_category_opt_out: 'Diese Kategorie wurde als "nicht wesentlich" markiert',
      stakeholder_feedback_request_for_category: "Feedback wurde für diese Kategorie angefragt.",
      stakeholder_feedback_revoke_request_for_category:
        "Die Anfrage für Feedback wurde für diese Kategorie zurückgezogen.",
      dma_category_verify_material: "Die Auswirkungen wurden für diese Kategorie verifiziert.",
      dma_category_verify_financial: "Die Chancen/Risiken wurden für diese Kategorie verifiziert.",
      dma_category_unverify_material:
        "Die Verifizierung der Auswirkungen wurde für diese Kategorie aufgehoben.",
      dma_category_unverify_financial:
        "Die Verifizierung der Chance/Risiken wurde für diese Kategorie aufgehoben.",
      dma_material_impact_create: "Eine neue Auswirkung wurde erstellt.",
      dma_material_impact_update: "Eine Auswirkung wurde bearbeitet.",
      dma_material_impact_delete: "Eine Auswirkung wurde gelöscht.",
      dma_material_impact_state_update: "Der Status der Auswirkung wurde geändert.",
      dma_financial_effect_create: "Eine neue Chance/ein neues Risiko wurde erstellt.",
      dma_financial_effect_update: "Eine Chance / ein Risiko wurde bearbeitet.",
      dma_financial_effect_delete: "Eine Chance / ein Risiko wurde gelöscht.",
      dma_financial_effect_state_update: "Der Status der Chance / des Risikos wurde geupdated.",
      manual_stakeholder_feedback_create:
        "Ein manuelles Feedback wurde für die Kategorie angelegt.",
      manual_stakeholder_feedback_update: "Ein manuelles Feedback wurde bearbeitet.",
      manual_stakeholder_feedback_delete: "manual_stakeholder_feedback_delete",
      dma_stakeholder_submit_feedback: "Ein Stakeholder hat sein Feedback abgegeben.",
      dma_stakeholder_update_feedback: "Ein Stakeholder hat sein Feedback bearbeitet.",
      dma_stakeholder_create: "Ein neuer Stakeholder wurde erstellt.",
      dma_stakeholder_update: "Ein Stakeholder wurde bearbeitet.",
      dma_stakeholder_delete: "Ein Stakeholder wurde gelöscht.",
      organization_structure_create: "Eine neue Organisationsstruktur wurde angelegt.",
      organization_structure_update: "Eine Organisationsstruktur wurde bearbeitet.",
      data_entry_object_create: "Ein neues Erfassungsobjekt wurde erstellt.",
      data_entry_object_update: "Ein Erfassungsobjekt wurde bearbeitet.",
      root_data_entry_object_create: "Ein neues Root-Erfassungsobjekt wurde erstellt.",
      data_entry_object_input_parameter_create:
        "Ein neuer Erfassungsparameter wurde für ein Erfassungsobjekt erstellt.",
    },
  },
  audit_logging_comparison_view_component: {
    headers: {
      before: "Zuvor",
      after: "Danach",
    },
    no_data_available: "Es stehen keine Daten zur Verfügung.",
  },
  audit_logging_expand_component: {
    changelog: "Changelog",
    headers: {
      value_name: "Bezeichnung",
      before_value: "Vorher",
      after_value: "Nacher",
    },
  },
  // Stakeholder
  stakeholders_configuration: {
    title: "Stakeholder",
    empty_notice: "Noch keine Stakeholder für Organisation angelegt.",
    header_name: "Name",
    header_organization: "Organisation",
    button_create_stakeholder: "Stakeholder hinzufügen",
    button_create: "Erstellen",
    delete_dialog_cancel: "$t(buttons:cancel)",
    delete_dialog_confirm: "$t(buttons:delete)",
    delete_dialog_title: "Stakeholder löschen?",
    delete_dialog_body:
      "Sind sie sicher, dass Sie den Stakeholder löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  },
  stakeholder_edit_dialog: {
    title_create: "Stakeholder erstellen",
    title_edit: "Stakeholder bearbeiten",
    label_name: "Name",
    error_name_required: "Bitte geben Sie einen Namen ein.",
    label_organization: "Organisation (optional)",
    create_stakeholder: "Stakeholder erstellen",
  },
  // Distribution Criteria
  distribution_criteria_configuration: {
    title: "Aufteilungsschlüssel",
    empty_notice: "Noch keine Aufteilungsschlüssel für Organisation angelegt.",
    header_name: "Name",
    button_create_stakeholder: "Aufteilungsschlüssel hinzufügen",
    button_create: "Erstellen",
    delete_dialog_cancel: "$t(buttons:cancel)",
    delete_dialog_confirm: "$t(buttons:delete)",
    delete_dialog_title: "Aufteilungsschlüssel löschen?",
    delete_dialog_body:
      "Sind sie sicher, dass Sie den Aufteilungsschlüssel löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  },
  distribution_criteria_edit_dialog: {
    title_create: "Aufteilungsschlüssel erstellen",
    title_edit: "Aufteilungsschlüssel bearbeiten",
    label_name: "Name",
    error_name_required: "Bitte geben Sie einen Namen ein.",
  },
  // Applied Distribution criteria
  applied_distribution_criteria_configuration_page: {
    title: "Aufteilungsschlüssel",
  },
  applied_distribution_criteria_configuration_component: {
    title: "Aufteilungsschlüssel",
    dropdowns: {
      recording_period_dropdown: {
        recording_period_select_label: "Erfassungszeitraum",
      },
      distribution_criterion_dropdown: {
        label: "Aufteilungsschlüssel",
      },
      organization_structure_is_draft_notice:
        "Die Organisationsstruktur ist aktuell noch ein Entwurf. Sie müssen die Organisationsstruktur " +
        "veröffentlichen, bevor Sie Aufteilungsschlüssel anwenden können.",
    },
    no_criteria_configured: {
      text: "Für die Organisation wurden noch keine Aufteilungsschlüssel konfiguriert.",
      button_text: "Zur Konfiguration",
    },
    configure_criterion: {
      text: "Dieser Aufteilungsschlüssel wurde noch nicht für den gewählten Erfassungszeitraum konfiguriert.",
      button_text: "Jetzt konfigurieren",
    },
    alert_info: {
      body: "Änderungen werden automatisch gespeichert, sobald das Eingabefeld verlassen wird!",
      info_already_applied:
        "Der Aufteilungsschlüssel wurde bereits angewandt. Das bedeutet, dass die Werte zwar noch abgeändert, aber nicht mehr komplett entfernt werden können.",
    },
  },
  applied_distribution_criteria_table_component: {
    buttons: {
      expand_all: "Alle ausklappen",
      collapse_all: "Alle einklappen",
      remove_configuration: "Konfiguration entfernen",
      // Apply
      apply_configuration: "Konfiguration anwenden",
      tooltip_not_populated:
        "Die Konfiguration kann noch nicht angewandt werden, da noch nicht alle Werte erfasst wurden.",
      tooltip_populated: "Konfiguration anwenden",
      // Remove application
      remove_application: "Anwendung entfernen",
      tooltip_remove_application: "Anwendung entfernen",
      tooltip_remove_application_disabled:
        "Die Anwendung kann nicht entfernt werden, da bereits Werte erfasst wurden, die auf ihr basieren.",
    },
    table_header: {
      data_entry_object_name: "Name",
      value: "Wert",
    },
    confirm_dialog: {
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "$t(buttons:confirm)",
      title: "Konfiguration entfernen?",
      body: "Sind Sie sicher, dass Sie die Konfiguration entfernen möchten? Sämtliche Werte werden ebenso gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    },
  },
  applied_distribution_criteria_table_row_component: {},
  applied_distribution_criteria_table_cell_component: {
    error_required: "Da der Schlüssel angewandt wurde, muss ein Wert erfasst werden.",
  },
  // Sources
  sources: {
    source_type: {
      data_source: "Datenquelle",
      calculation_source: "Berechnungsquelle",
      methodology_source: "Methodikquelle",
    },
  },
  sources_configuration_page: {
    title: "Quellen",
  },
  sources_configuration_component: {
    empty_notice: "Es wurden noch keine Quellen für diese Organisation angelegt.",
    buttons: {
      create: "Erstellen",
    },
    confirm: {
      title: "Quelle entfernen?",
      body: "Sind Sie sicher, dass Sie diese Quelle entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden!",
    },
  },
  sources_edit_dialog: {
    title_create: "Quelle erstellen",
    title_edit: "Quelle bearbeiten",
    labels: {
      name: "Name",
      description: "Beschreibung der oder Link zur Quelle",
      source_type: "Art der Quelle",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein",
      source_type_required: "Bitte wählen Sie eine Option",
    },
  },
  sources_list_component: {
    headers: {
      name: "Name",
      description: "Beschreibung",
      source_type: "Art der Quelle",
    },
    warning_icon:
      "Diese Quelle kann derzeit keinem Datenpunkt zugewiesen werden, weil sie keine Beschreibung enthält oder keine Dateien hochgeladen wurden.",
  },
  sources_files_dialog: {
    title: "Dateien bearbeiten",
    name: "Datei",
    type: "Dateityp",
    delete_title: "Datei von Quelle {{sourceName}} entfernen?",
    delete_body:
      "Sind Sie sicher, dass Sie diese Datei entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden!",
    upload_file: "Hochladen",
    no_files_uploaded: "Es wurden noch keine Dateien hochgeladen.",
  },
  // DMA Categories
  dma_category_delete_confirm_dialog: {
    title: 'Thema "{{name}}" löschen?',
    content_text: 'Sind Sie sicher, dass Sie das Thema "{{name}}" löschen möchten?',
    button_cancel: "Abbrechen",
    button_delete: "Löschen",
  },
  dma_category_edit_dialog: {
    title_create: "Neues Thema erstellen",
    title_edit: "Thema {{name}} bearbeiten",
    delete_tooltip: "Thema entfernen",
    cannot_delete_tooltip:
      "Ein Thema kann nur gelöscht werden, wenn es keine untergeordneten " +
      "Themen und Auswirkungen, Chancen oder Risiken besitzt und kein Feedback angefordert bzw. manuell eingetragen wurde.",
    error_name_required: "Bitte geben Sie einen Namen ein.",
    name_label: "Name",
    button_cancel: "Abbrechen",
    button_create: "Erstellen",
    button_save: "Speichern",
  },
  dma_category_opt_out_dialog: {
    title_opt_out: "Dieses Thema als nicht relevant markieren?",
    title_edit: "Begründung aktualisieren",
    content_text:
      'Sind Sie sicher, dass Sie das Thema "{{name}}" und alle untergeordneten Themen als nicht relevant markieren möchten? ' +
      'Gehen Sie in Ihrer Begründung darauf ein, warum "{{name}}" und ggf. die zugehörigen Unterthemen für Ihre Organisation nicht relevant sind. ' +
      "Sie können Ihre Entscheidung jederzeit rückgängig machen.",
    label_opt_out_reason: "Begründung",
    error_opt_out_reason_required: "Bitte geben Sie eine Begründung an.",
    button_cancel: "Abbrechen",
    button_opt_out: "Bestätigen",
    button_edit: "Speichern",
  },
  dma_category_opt_back_in_confirm_dialog: {
    title: "Thema auf relevant zurücksetzen?",
    content:
      'Sind Sie sicher, dass Sie das Thema "{{name}}" und alle untergeordneten Themen ' +
      "wieder auf relevant zurücksetzen möchten?",
    button_confirm: "Zurücksetzen",
  },
  // ESRS Topic
  dma_esrs_topic_component: {
    create_category_button: "Thema erstellen",
    create_category_button_tooltip:
      'Ein neues Thema zum übergeordneten Thema "{{esrsTopicName}}" erstellen.',
  },
  dma_esrs_topic_not_yet_recorded_component: {
    header: "Für dieses Thema wurde noch nichts erfasst.",
    start_collection: "Sie können nun entweder mit der",
    start_collection_button: "Vorlage verwenden",
    start_collection_button_tooltip:
      "Themen aus Standardvorlage für die Erfassung von Auswirkungen oder Chancen / Risiken für dieses Thema verwenden. " +
      "Alternative können Sie über die nachfolgenden Schaltfläche selbst Themen erstellen",
    skip_collection: "oder die",
    skip_collection_button: "Keine Auswirkungen",
    skip_collection_button_tooltip:
      "Keine Auswirkungen oder Chancen / Risiken für dieses Thema erfassen",
  },
  dma_esrs_topic_opted_out_component: {
    header: "Sie haben sich gegen die Erfassung für dieses Thema entschieden.",
    opt_out_reason_prefix: "Begründung:",
    start_collection_button: "Vorlage verwenden",
    start_collection_button_tooltip:
      "Themen aus Standardvorlage für die Erfassung von Auswirkungen oder Chancen / Risiken für dieses Thema verwenden. " +
      "Alternative können Sie über die nachfolgenden Schaltfläche selbst Themen erstellen",
    edit_opt_out_reason_button_tooltip: "Begründung bearbeiten",
  },
  // Tabular Data
  tabular_input_component: {
    header_dynamic_table: "Aktionen",
    header_required_tooltip: "Werte dieser Spalte müssen verpflichtend erfasst werden.",
  },
  dynamic_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Neue Zeile hinzufügen",
      button_add_row: "Hinzufügen",
      tooltip_save: "Änderungen speichern",
      button_save: "Speichern",
    },
    row: {
      tooltip_remove_row: "Zeile löschen",
      tooltip_save_row: "Zeile speichern",
      tooltip_undo_row: "Zeile zurücksetzen",
      tooltip_add_note: "Notiz hinzufügen",
      tooltip_remove_note: "Notiz entfernen",
    },
  },
  static_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Neue Zeile hinzufügen",
      button_add_row: "Hinzufügen",
      tooltip_save: "Tabelle speichern",
      tooltip_reset_table: "Tabelle zurücksetzen",
      button_save: "Speichern",
      tooltip_remove_note: "Notiz entfernen",
      tooltip_add_note: "Notiz hinzufügen",
    },
    confirm_dialog: {
      title: "Tabelle zurücksetzen?",
      body: "Sind sie sicher, dass Sie die Tabelle zurücksetzen möchten? Hierbei gehen sämtliche Daten verloren. Diese Aktion kann nicht rückgängig gemacht werden.",
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "Löschen",
    },
    row: {
      tooltip_undo_row: "Zeile zurücksetzen",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_overview_component: {
    title: 'Feedback für "{{name}}"',
    subtitle_no_rps:
      'Für die Organisation "{{organizationName}}" wurden noch keine Erfassungsperioden angelegt, weshalb Stakeholder-Feedback noch nicht möglich ist',
  },
  stakeholder_feedback_details_component: {
    select_title: "Erfassungszeitraum:",
  },
  stakeholder_feedbacks_component: {
    no_feedback_requested:
      "Für den gewählten Erfassungszeitraum wurde noch kein Feedback angefordert.",
  },
  stakeholder_feedback_category_component: {
    material_impacts_header: "Auswirkungen",
    financial_effects_header: "Chancen und Risiken",
    button_add_feedback: "Feedback abgeben",
    button_edit_feedback: "Feedback bearbeiten",
    alert_not_yet_shared: {
      title: "Thema wurde noch nicht freigegeben",
      content:
        "Das Thema wurde noch nicht freigegeben, es kann daher noch zu Änderungen der " +
        "Datenlage kommen, nachdem Sie Ihr Feedback abgegeben haben. Bitte klären Sie den " +
        "Datenstand vor Ihrer Feedbackabgabe mit Ihrer Kontaktperson ab.",
    },
    alert_category_open: {
      title: "Thema noch nicht erfasst",
      content: "Für dieses Thema wurden noch keine Auswirkungen oder Chancen/Risiken erfasst.",
    },
    alert_no_iro_material: "Zu diesem Thema wurden keine Auswirkungen identifiziert.",
    alert_no_iro_financial: "Zu diesem Thema wurden keine Chancen/Risiken identifiziert.",
    category_verified_notice_material:
      "Auswirkungen abgeschlossen - Feedback kann daher nicht mehr verändert werden.",
    category_verified_notice_financial:
      "Chancen und Risiken abgeschlossen - Feedback kann daher nicht mehr verändert werden.",
    feedback_indicator: {
      status_submitted: "Feedback abgegeben",
      "status_not-submitted": "Feedback ausständig",
      "status_parent-submitted":
        "Thema wurde im Feedback des übergeordneten Themas berücksichtigt.",
      "status_parent-not-submitted":
        "Thema ist im Feedback des übergeordneten Themas zu berücksichtigen.",
    },
  },
  stakeholder_feedback_dialog: {
    title_material: "Feedback für Auswirkungen - {{name}}",
    title_financial: "Feedback für Chancen und Risiken - {{name}}",
    label_agreed: "Ich teile die Einschätzung.",
    label_feedback: "Feedback",
    label_assessment: "Bewertung",
    label_add_divergent_assessment: "Abweichende Bewertung vorschlagen",
    explanation_material_assessment: "Wie bewerten Sie diese Auswirkung?",
    explanation_financial_assessment: "Wie bewerten Sie diese Chance / dieses Risiko?",
    // Slider values
    slider_value_very_low: "Sehr niedrig",
    slider_value_low: "Eher niedrig",
    slider_value_average: "Mittel",
    slider_value_high: "Eher hoch",
    slider_value_very_high: "Sehr hoch",
    // Buttons
    button_cancel: "$t(buttons:cancel)",
    button_save: "$t(buttons:save)",
    button_create: "Feedback abgeben",
    button_close: "$t(buttons:close)",
    // Validation
    error_at_least_one_form_of_feedback_required:
      "Es muss zumindest eine Form des Feedbacks (Feedback oder abweichende Bewertung) angegeben werden.",
    // Materiality details
    materiality_materiality_degree_label: "Wesentlichkeitsgrad",
    materiality_material_label: "Wesentlich?",
    material_yes: "$t(dma_common:materiality_true_state)",
    material_no: "$t(dma_common:materiality_false_state)",
    materiality_opt_out_title: "Thema als nicht wesentlich markiert:",
    materiality_tooltip_materiality_degree: "Wesentlichkeitsgrad (Maximalwert: 5)",
  },
  // Reference Budgets
  reference_budgets_page: {
    title: "Referenzbudgets",
  },
  reference_budgets_component: {
    empty_notice: "Es wurden noch keine Referenzbudgets für die Organisation angelegt.",
    buttons: {
      create: "Erstellen",
    },
    confirm: {
      title: "Referenzbudget entfernen?",
      body: "Sind Sie sicher, dass Sie das Referenzbudget entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    },
    refresh: {
      title: "Daten für Referenzbudget aktualisieren?",
      body: "Sind Sie sicher, dass Sie die Daten für das Referenzbudget aktualisieren möchten? Die alten Daten werden hiermit überschrieben und sind anschließend nicht mehr zugänglich!",
    },
  },
  reference_budgets_edit_list_component: {
    headers: {
      name: "Name",
      values_fetched_at: "Datenstand von",
      reference_year: "Referenzjahr",
      reference_revenue: "Referenzumsatz",
      budget_per_year: "Jahresbudget",
    },
    action_tooltips: {
      edit: "Referenzbudget bearbeiten",
      delete: "Referenzbudget löschen",
      refresh: "Daten für das Referenzbudget aktualisieren",
    },
  },
  reference_budget_edit_dialog: {
    title_create: "Referenzbudget erstellen",
    title_edit: "Referenzbudget bearbeiten",
    labels: {
      name: "Name",
      recording_periods: "Erfassungsstrukturen für Referenzbudget",
      reference_revenue: "Referenzumsatz",
      reference_year: "Referenzjahr",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein.",
      recording_periods_required: "Es muss zumindest eine Erfassungsstruktur ausgewählt werden.",
    },
    notices: {
      recording_periods_have_changed:
        "Die Erfassungsstrukturen wurden angepasst - somit wird das Budget neu berechnet. Dies kann nicht rückgängig gemacht werden!",
    },
  },
  // Target Paths
  target_paths_configuration_page: {
    title: "Zielpfade",
  },
  target_paths_configuration_component: {
    empty_notice: "Es wurden noch keine Zielpfade für die Organisation angelegt.",
    notice_no_reference_budget: {
      text: "Sie müssen zumindest ein Referenzbudget erstellen, bevor Sie Zielpfade konfigurieren können.",
      button_text: "Zu den Referenzbudgets",
    },
    subheaders: {
      current_organization_path: "Festgelegter Unternehmenszielpfad",
      paths_in_progress: "Zielpfade in Arbeit",
    },
    buttons: {
      create: "Erstellen",
    },
    tooltips: {},
  },
  target_path_dialogs: {
    confirm: {
      title: "Zielpfad entfernen?",
      body: "Sind Sie sicher, dass Sie den Zielpfad entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    },
    confirm_toggle: {
      for_state: {
        [ITargetPathState.Committed]: {
          title: "Zielpfad auf Entwurf zurückstufen?",
          body: "Sind sie sicher, dass die den Zielpfad auf Entwurf zurückstufen möchten? Diese Aktion kann jederzeit rückgängig gemacht werden.",
        },
        [ITargetPathState.Draft]: {
          title: "Zielpfad als Organisationszielpfad festlegen?",
          body_1:
            'Sind Sie sicher, dass sie "{{targetPathName}}" als Organisationszielpfad festlegen möchten?',
          body_2: 'Durch diese Aktion werden folgende Pfade auf "Entwurf" zurückgestuft:',
        },
      },
    },
  },
  target_path_progress_component: {
    header: "Fortschritt",
    labels: {
      recording_structures_for_evaluation: "Erfassungsstrukturen für die Auswertung",
    },
  },
  target_paths_edit_list_component: {
    headers: {
      name: "Name",
      reference_budget_name: "Gewähltes Referenzbudget",
      state: "Status",
    },
    action_tooltips: {
      edit: "Zielpfad bearbeiten",
      edit_disabled:
        "Der Zielpfad kann nicht mehr bearbeitet werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      delete: "Zielpfad löschen",
      delete_disabled:
        "Der Zielpfad kann nicht mehr gelöscht werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      commit: "Als Organisationszielpfad festlegen",
      commit_disabled:
        "Dieser Pfad kann nicht als Organisationspfad festgelegt werden, da keine Ziele hinterlegt wurden!",
      cease: "Auf Entwurf zurückstufen",
      clone: "Zielpfad kopieren",
    },
  },
  target_path_actions_component: {
    action_tooltips: {
      edit: "Zielpfad bearbeiten",
      edit_disabled:
        "Der Zielpfad kann nicht mehr bearbeitet werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      delete: "Zielpfad löschen",
      delete_disabled:
        "Der Zielpfad kann nicht mehr gelöscht werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      commit: "Als Organisationszielpfad festlegen",
      commit_disabled:
        "Dieser Pfad kann nicht als Organisationspfad festgelegt werden, da keine Ziele hinterlegt wurden!",
      cease: "Auf Entwurf zurückstufen",
      clone: "Zielpfad kopieren",
    },
  },
  target_path_edit_dialog: {
    title_create: "Zielpfad erstellen",
    title_edit: "Zielpfad bearbeiten",
    labels: {
      name: "Name",
      reference_budget: "Referenzbudget für Zielpfad",
      use_template: "Template für Ziele verwenden?",
      template: "Zieltemplate",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein.",
      name_unique: "Der Name muss innerhalb der Organisation eindeutig sein.",
    },
    tooltips: {
      use_template:
        "Es werden automatisch Beispielziele erstellt, die Sie bei der Konfiguration unterstützen.",
    },
    goals: {
      [ITargetPathGoalTemplateType.ParisConform]: "Paris-konformer Standard-Zielpfad",
    },
  },
  target_path_clone_dialog: {
    title: "Zielpfad kopieren",
    labels: {
      name: "Neuer Name",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein.",
      name_unique: "Der Name muss innerhalb der Organisation eindeutig sein.",
    },
  },
  target_path_state_chip_component: {
    text_by_state: {
      [ITargetPathState.Draft]: "Entwurf",
      [ITargetPathState.Committed]: "Festgelegt",
      [ITargetPathState.Fulfilled]: "Erfüllt",
    },
    tooltip_text_by_state: {
      [ITargetPathState.Draft]:
        "Dieser Zielpfad befindet sich noch im Entwicklungsstadium. Änderungen sind jederzeit möglich.",
      [ITargetPathState.Committed]:
        "Dieser Zielpfad wurde als Richtung für das Unternehmen gewählt und kann deshalb nicht bearbeitet werden.",
      [ITargetPathState.Fulfilled]:
        "Die aus diesem Pfad abgeleiteten Ziele wurden bereits erfüllt. Er kann nicht mehr bearbeitet werden.",
    },
  },
  target_path_is_committed_info_component: {
    title: "Info",
    text: "Dieser Zielpfad wurde als Organisationszielpfad festgelegt. Er kann deshalb nicht bearbeitet werden.",
  },
  // Target Path Goals
  target_path_details_component: {
    title: 'Zielpfad "{{targetPathName}}"',
  },
  target_path_goals_edit_list_component: {
    title: "Ziele",
    headers: {
      target_year: "Zieljahr",
      reduction_to_reference_year: "Reduktion/Steigerung zu Referenzjahr",
      emissions_in_target_year: "Emissionen im Zieljahr (Gesamt)",
    },
    buttons: {
      create: "Hinzufügen",
    },
    confirm: {
      title: "Ziel entfernen?",
      body: "Sind Sie sicher, dass Sie das Ziel entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    },
    action_tooltips: {
      edit: "Ziel bearbeiten",
      delete: "Ziel löschen",
    },
  },
  target_path_goal_edit_dialog_component: {
    title_create: "Ziel erstellen",
    title_edit: "Ziel bearbeiten",
    labels: {
      target_year: "Zieljahr",
    },
    errors: {
      date: {
        minDate:
          "Das ausgewählte Zieljahr darf nicht vor dem Referenzjahr liegen ({{referenceYear}}).",
        targetForYearAlreadyDefined:
          "Es wurde bereits ein Ziel für das Zieljahr {{targetYear}} festgelegt.",
      },
    },
  },
  target_path_goal_edit_component: {
    toggle: {
      text_absolute: "Absolut",
      text_relative: "Relativ",
    },
    confirm_dialog: {
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "$t(buttons:confirm)",
      title: "Erfassungsart ändern?",
      body: "Sind Sie sicher, dass Sie die Erfassungsart ändern möchten? Sämtliche bis jetzt eingegebene Werte werden gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    },
  },
  target_path_goal_value_change_input_component: {
    labels: {
      change: "Reduktion",
      change_type: "Änderungsart",
    },
  },
  absolute_target_path_goal_edit_component: {
    translations: {
      in_percent: "Reduktion in [%]",
      by_value: "Reduktion um [tCO\u2082eq]",
      to_value: "Reduktion auf [tCO\u2082eq]",
    },
    helper_text: "Referenzbudget: {{referenceBudget}}",
    helper_text_no_value: "Kein Referenzbudget vorhanden",
    scopes: {
      scope_reductionScope1: "Reduktion Scope 1",
      scope_reductionScope2: "Reduktion Scope 2",
      scope_reductionScope3: "Reduktion Scope 3",
    },
    tooltips: {
      reduction_as_percent: "Das entspricht einer {{reductionTerm}} von {{percentage}} %.",
      reduction_as_by_value: "Das entspricht einer {{reductionTerm}} von {{value}} t CO\u2082eq.",
      no_value:
        "Es kann kein Wert eingetragen werden, da kein Referenzbudget zu diesem Scope existiert.",
    },
  },
  relative_target_path_goal_edit_component: {
    translations: {
      in_percent: "Jährliches Umsatzwachstum in [%]",
      by_value: "Wachstum um [€]",
      to_value: "Erwarteter Umsatz im Zieljahr",
    },
    headings: {
      revenue: "Umsatz",
      intensity: "Intensität",
    },
    revenue: {
      textfield_label_growth: "Umsatzwachstum",
      textfield_label_absolute: "Zielumsatz",
      helper_text: "Referenzumsatz: {{referenceRevenue}}",
      tooltip_dropdown_no_revenue:
        "Es kann nur ein konkretes Umsatzziel (und keine Veränderung) eingeben werden, da kein Referenzumsatz hinterlegt wurde.",
    },
  },
  target_intensity_input_component: {
    helper_text_no_value: "Kein Referenzbudget vorhanden",
    tooltip_no_value:
      "Es kann kein Wert eingetragen werden, da kein Referenzbudget zu diesem Scope existiert.",
    tooltip_value:
      "Dies entspricht Emissionen von {{absoluteValue}} t CO\u2082eq bzw. einer {{reductionTerm}} von {{relativeReduction}} %.",
    scopes: {
      intensityScope1: "Zielintensität Scope 1",
      intensityScope2: "Zielintensität Scope 2",
      intensityScope3: "Zielintensität Scope 3",
    },
  },
  reference_intensity_component: {
    content: "Die {{textSum}} Referenzintensität beträgt: {{referenceIntensity}} tCO\u2082eq / €.",
  },
  target_path_diagram_component: {
    title: "Diagram",
    title_for_organization_path: "Unternehmenszielpfad",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    } as const,
  },
  target_path_general: {
    terms: {
      reduction: "Reduktion",
      increase: "Steigerung",
    },
  },
  target_path_progress_diagram_component: {
    title_for_organization_path: "Unternehmenszielpfad",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    },
  },
  // Input Components
  list_value_input_component: {
    empty_option: "Kein Wert",
  },
  nested_options_input_component: {
    buttons: {
      close: "Schließen",
      clear: "Auswahl zurücksetzen",
    },
    search: {
      placeholder: "Suchen...",
    },
  },
  // Input Validation
  value_validation_errors: {
    required: "Bitte geben Sie einen Wert für dieses Feld ein.",
    number: {
      base: "Bitte geben Sie eine Zahl an.",
      precision: "Der Wert darf höchstens {{limit}} Nachkommastellen haben.",
      min: "Der Wert muss größer oder gleich {{limit}} sein.",
      max: "Der Wert muss kleiner oder gleich {{limit}} sein.",
      unsafe: "Der Wert muss eine sichere Zahl sein.",
    },
    boolean: {
      base: "Bitte wählen Sie einen Wert.",
    },
    string: {
      empty: "Bitte geben Sie einen Text ein.",
    },
    array: {
      includesRequiredUnknowns: "Es muss zumindest eine Option gewählt werden.",
      sparse: "Bitte füllen Sie alle Felder aus.",
    },
    date: {
      format: "Bitte geben Sie ein gültiges Datum ein.",
      min: "Das Datum muss nach dem {{limit}} liegen.",
      max: "Das Datum muss vor dem {{limit}} liegen.",
      greater: "Das Startdatum muss vor dem Enddatum liegen.",
    },
    any: {
      only: "Bitte wählen Sie eine Option.",
    },
  },
  number_input: {
    placeholder: "Zahl eingeben",
  },
  currency_input: {
    placeholder: "Betrag eingeben",
  },
  text_input: {
    placeholder: "Text eingeben",
  },
  boolean_input: {
    value_true: "Ja",
    value_false: "Nein",
  },
  multi_text_input_component: {
    add_entry: "Eintrag hinzufügen",
    remove_entry: "Eintrag entfernen",
  },
  date_input_component: {
    remove_date_tooltip: "Datum entfernen",
  },
  // Intercom
  intercom_reference_widget_component: {
    tooltip: "Hilfe öffnen",
  },
  // Errors
  error: {
    axios: {
      connection: "Verbindungsfehler - Bitte überprüfen Sie Ihre Internetverbindung",
    },
    generic: {
      "400": "Fehlerhafte Anfrage",
      "401": "Nicht autorisiert",
      "403": "Keine Berechtigung",
      "404": "Nicht gefunden",
      "409": "Konflikt",
      "500": "Interner Serverfehler",
      "502": "API nicht verfügbar",
    },
    general: {
      entity_not_found: "Nicht gefunden.",
      internal_server_error: "Interner Serverfehler.",
    },
    auth: {
      unauthorized:
        "Ihre Sitzung ist abgelaufen oder Ihre Zugangsdaten sind ungültig. Bitte melden Sie sich erneut an.",
    },
    input_parameter: {
      not_found: "Der Wert konnte nicht gefunden werden.",
    },
    organization: {
      not_found: "Die Organisation konnte nicht gefunden werden.",
    },
    recording_period: {
      not_found: "Die Erfassungsperiode konnte nicht gefunden werden.",
      previous_structure_is_draft:
        "Die vorherige Organisationsstruktur ist ein Entwurf. Sie muss veröffentlicht werden bevor eine neue Erfassungsperiode erstellt werden kann.",
      value_would_move_outside_scope:
        "Es wurden bereits Werte für die Erfassungsperiode außerhalb des neuen Zeitraums angegeben!",
      overlaps_with_existing_recording_period:
        "Der angegebene Zeitraum überschneidet sich mit einer bereits existierenden Erfassungsperiode!",
    },
    recording_model: {
      not_found: "Das Erfassungsmodell konnte nicht gefunden werden.",
    },
    recording_model_templates_group: {
      not_found: "Das Thema konnte nicht gefunden werden.",
    },
    input_parameter_recording_structure_template: {
      not_found: "Die Vorlage konnte nicht gefunden werden.",
    },
    input_parameter_recording_structure: {
      not_found: "Die Erfassungsstruktur konnte nicht gefunden werden.",
      input_parameter_used_more_than_once:
        "Ein Wert darf nur einmal innerhalb der Erfassungsstruktur erfasst werden!",
      input_parameter_not_found:
        "In der Erfassungsstruktur wurde zumindest ein unbekannter Wert zur Erfassung angegeben!",
      order_in_use:
        "Die angegebene Reihung der Erfassungsstruktur innerhalb des Erfassungsmodells wird bereits verwendet!",
    },
    organization_structure: {
      not_found: "Die Organisation Struktur konnte nicht gefunden werden.",
      not_yet_committed: "Die Organisation Struktur wurde noch nicht veröffentlicht.",
      already_committed: "Der Entwurf wurde bereits veröffentlicht.",
      duplicate_ids:
        "Unerwarteter Fehler! Die Werte eines vergangenen " +
        "Erfassungsobjekts werden an mehrere neue Erfassungsobjekte vererbt!",
      id_doesnt_belong_to_previous_structure:
        "Unerwarteter Fehler! Es wurde versucht, ein Erfassungsobjekt zu vererben, " +
        "das nicht Teil der vorherigen Organisationsstruktur war!",
    },
    input_parameter_recording_method: {
      not_found: "Verwendete Erfassungsmethode für Wert nicht gefunden.",
    },
    data_entry_object: {
      not_found: "Das Datenobjekt konnte nicht gefunden werden.",
      next_already_set:
        "Das Datenobjekt wird bereits von einem anderen Datenobjekt als Vorgänger " +
        "(erfasste Werte werden vererbt) verwendet.",
    },
    data_entry_object_input_parameter_value: {
      bad_value: "Fehlerhafter Wert!",
      value_already_recorded_for_period:
        "Wert wurde für den gegebenen Erfassungszeitraum bereits erfasst.",
      not_found: "Wert wird für das gegebene Erfassungsobjekt nicht erfasst.",
      value_outside_recording_period:
        "Das Datum für den Wert liegt außerhalb der Erfassungsperiode für die er erfasst wurde!",
    },

    dma_category: {
      not_found: "Das Thema konnte nicht gefunden werden.",
      has_children: "Aktion nicht möglich: Das Thema hat untergeordnete Themen!",
      has_associated_financial_effects:
        "Aktion nicht möglich: Das Thema hat Chancen und Risiken untergeordnet!",
      has_associated_material_impacts:
        "Aktion nicht möglich: Das Thema hat Auswirkungen untergeordnet!",
      opt_out_of_category_with_iros:
        "Opt Out nicht möglich: Das Thema hat Auswirkungen, Chancen oder Risiken untergeordnet!",
      opt_out_without_reason: "Als nicht relevant markieren ohne Begründung nicht möglich",
      opt_in_with_reason:
        "Als nicht relevant markieren rückgängig machen mit Begründung nicht möglich",
      has_opted_out: "Bereits als nicht relevant markiert",
      no_feedback_found: "Kein Stakeholder-Feedback vorhanden",
      in_invalid_state: "Status des Themas für Abschluss ist ungültig",
      already_verified: "Thema ist bereits abgeschlossen",
    },
    dma_effect: {
      reason_was_not_provided: "",
      reason_was_provided_without_override: "",
    },
    dma_financial_effect: {
      not_found: "Chance/Risiko nicht gefunden",
      not_responsible_user: "Ihr Benutzer ist nicht für die Chance/Risiko verantwortlich",
      already_published:
        "Chance/Risiko ist bereits freigegeben und kann nicht mehr verändert werden",
      incomplete_for_shared:
        "Um eine Chance/Risiko freizugeben müssen Sie eine Erläuterung der Bewertung angeben.",
      referenced_in_policies:
        "Die Chance/Risiko wird von einer Policy referenziert. Deshalb kann ihr Status nicht mehr zu 'Entwurf' geändert werden.",
    },
    dma_material_impact: {
      not_found: "Auswirkung nicht gefunden.",
      not_responsible_user: "Keine verantwortliche Person für die Auswirkung angegeben.",
      already_published: "Bereits freigegeben.",
      incomplete_for_shared:
        "Um eine Chance/Risiko freizugeben müssen Sie eine Erläuterung der Bewertung angeben.",
      referenced_in_policies:
        "Die Auswirkung wird von einer Policy referenziert. Deshalb kann ihr Status nicht mehr zu 'Entwurf' geändert werden.",
    },
    esrs_topic: {
      not_found: "ESRS-Thema nicht gefunden",
      does_not_support_custom_categories:
        "Für das gewählte ESRS-Thema können keine benutzerdefinierten Themen erstellt werden",
    },
    recorded_esrs_topic: {
      opt_out_of_topic_with_categories:
        "Keine Auswirkungen setzen für dieses Thema nicht möglich, da bereits Sub-Themen erstellt wurden",
      does_not_exist:
        "Das angegebene ESRS-Thema oder das verwendete Erfassungsobjekt existiert nicht",
      category_is_not_child: "Das angegebene Thema ist nicht Teil des angegebenen ESRS Themas",
      opt_out_without_reason:
        "Um keine Themen für ein ESRS Thema zu wählen müssen Sie eine Begründung angeben",
      opt_in_with_reason:
        "Um keine Themen rückgängig zu machen darf keine Begründung angegeben werden",
    },
    dma_execution: {
      invalid_category: "Ungültiges Thema",
      feedback_missing: "Feedback fehlt",
    },
    data_entry_object_input_parameter: {
      attribution_reason_not_present: "",
    },
    stakeholder: {
      not_found: "Stakeholder nicht gefunden",
      has_associated_feedback:
        "Der Stakeholder konnte nicht gelöscht werden, da er Feedback hinterlegt hat.",
    },
    stakeholder_feedback: {
      not_found: "Stakeholder-Feedback nicht gefunden",
      invalid:
        "Feedback fehlerhaft - entweder muss zugestimmt werden und nur ein Kommentar " +
        "(ohne anderen Bewertungsvorschlag) oder, wenn die Bewertung nicht geteilt wurde, " +
        "zumindest ein anderer Bewertungsvorschlag oder ein Feedback Text angegeben werden.",
      already_submitted: "Feedback wurde bereits abgegeben",
    },
    manual_stakeholder_feedback: {
      not_found: "Manuelles Stakeholder-Feedback nicht gefunden",
    },
    // Distribution Criterion
    distribution_criterion: {
      not_found: "Aufteilungsschlüssel nicht gefunden",
      already_in_use:
        "Der Aufteilungsschlüssel wird bereits verwendet und kann daher nicht gelöscht werden.",
    },
    // Reference Budget
    reference_budget: {
      is_used_in_target_path:
        "Das Referenzbudget wird in einem Zielpfad verwendet und kann daher nicht gelöscht werden.",
      not_all_target_paths_in_draft_state:
        "Das Budget wird bereits von Zielpfaden referenziert, die festgelegt wurden. Das berechnete CO\u2082 Budget kann deshalb nicht mehr verändert werden.",
    },
    unknown: "Unbekannter Fehler",
  },
  not_found: {
    title: "Diese Seite wurde nicht gefunden",
    go_to_dashboard: "Zurück zur Startseite",
  },
  dma_categories: generateDMACategoryTranslations(IContentLanguage.De),
  esrs_topics: generateESRSTopicTranslations(IContentLanguage.De),
};
