import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddIcon, IconSize } from "../../common/constants/tabler-icon.constants";
import { PolicyEditDialog } from "./policy-edit.dialog";
import { ConfirmDialogComponent } from "../../common/components/confirm-dialog.component";
import {
  IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum,
  IPolicy,
  IPolicyData,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useState } from "react";
import { useDialogState, useDialogStateWithoutData } from "../../common/hooks/dialog-state.hook";
import {
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  useEditPolicyMutation,
} from "../policies.mutations";
import { useTranslation } from "react-i18next";
import { DataEntryObjectInputParameterValueDefinitionForPolicy } from "@netcero/netcero-common";
import { PolicyTableCell } from "./policy-table-cell.component";

const HOVER_BACKGROUND_COLOR = "#0000000A";

interface IPoliciesTableProps {
  policies: IPolicy[];
  topicIdentifier: IInputParameterValueMetaDataPolicyEsrsTopicIdentifierEnum;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForPolicy) => void;
}

export const PoliciesTable: FC<IPoliciesTableProps> = ({
  policies,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  onChange,
}) => {
  const { t } = useTranslation("data_entry_object_policy_component");

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const createPolicyMutation = useCreatePolicyMutation();
  const editPolicyMutation = useEditPolicyMutation();
  const deletePolicyMutation = useDeletePolicyMutation();

  const hasPolicies = policies && policies.length > 0;

  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();

  const {
    isOpen: isEditOpen,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
    data: policyData,
  } = useDialogState<IPolicy>();

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialogDialog,
    closeDialog: closeDeleteDialog,
    data: deletePolicyId,
  } = useDialogState<string>();

  const handleCreate = useCallback(
    async (payload: IPolicyData | null) => {
      if (payload !== null) {
        await createPolicyMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          payload,
        });
      }
      closeCreateDialog();
    },
    [closeCreateDialog, createPolicyMutation, dataEntryObjectId, organizationId, recordingPeriodId],
  );

  const handleEdit = useCallback(
    async (payload: IPolicyData | null) => {
      if (payload !== null) {
        await editPolicyMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          policyId: policyData?.id ?? "",
          payload,
        });
      }
      closeEditDialog();
    },
    [
      closeEditDialog,
      dataEntryObjectId,
      editPolicyMutation,
      organizationId,
      policyData?.id,
      recordingPeriodId,
    ],
  );

  const handleDelete = useCallback(
    async (policyId: string | null, confirm: boolean | undefined) => {
      if (!policyId || !confirm) {
        closeDeleteDialog();
        return;
      }
      await deletePolicyMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        policyId,
      });
      closeDeleteDialog();
      closeEditDialog();
    },
    [
      closeDeleteDialog,
      closeEditDialog,
      dataEntryObjectId,
      deletePolicyMutation,
      organizationId,
      recordingPeriodId,
    ],
  );

  return (
    <>
      {/* Dialogs */}
      <PolicyEditDialog
        open={isCreateOpen}
        loading={createPolicyMutation.isPending}
        onClose={handleCreate}
        onDelete={(policyId: string, confirm: boolean | undefined) =>
          handleDelete(policyId, confirm)
        }
        error={createPolicyMutation.error}
        disabled={createPolicyMutation.isPending}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        dataEntryObjectId={dataEntryObjectId}
        recordingPeriodId={recordingPeriodId}
      />
      <PolicyEditDialog
        open={isEditOpen}
        loading={editPolicyMutation.isPending}
        onClose={handleEdit}
        onDelete={(policyId: string) => openDeleteDialogDialog(policyId)}
        error={editPolicyMutation.error}
        disabled={editPolicyMutation.isPending}
        policy={policyData}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        dataEntryObjectId={dataEntryObjectId}
        recordingPeriodId={recordingPeriodId}
      />
      <ConfirmDialogComponent
        open={isDeleteDialogOpen}
        error={deletePolicyMutation.error}
        loading={deletePolicyMutation.isPending}
        disabled={deletePolicyMutation.isPending}
        onClose={(confirm: boolean) => handleDelete(deletePolicyId!, confirm)}
        text={{
          content: { title: t("confirm_dialog.title"), body: t("confirm_dialog.body") },
          buttons: {
            confirm: t("confirm_dialog.button_confirm"),
            cancel: t("confirm_dialog.button_cancel"),
          },
        }}
      />

      {/* Content */}
      {hasPolicies ? (
        <Box display="flex" flexDirection="column" gap={2} width="100%">
          <Table size="small">
            <TableHead>
              <TableCell>{t("name")}</TableCell>
            </TableHead>
            <TableBody>
              {policies.map((policy) => (
                <TableRow
                  key={policy.id}
                  onClick={() => openEditDialog(policy)}
                  sx={{
                    cursor: "pointer",
                    ":hover": { bgcolor: HOVER_BACKGROUND_COLOR },
                  }}
                >
                  <PolicyTableCell
                    policy={policy}
                    showTooltip={showTooltip}
                    setShowTooltip={setShowTooltip}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Tooltip title={t("tooltip_add_policy")}>
            <span style={{ width: "min-content" }}>
              <IconButton size="small" onClick={() => openCreateDialog()}>
                <AddIcon size={IconSize.Medium} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" flexDirection="column" gap={2}>
          <Typography>{t("no_policies_defined")}</Typography>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              onChange(false);
            }}
            sx={{ minWidth: 124 }}
          >
            {t("button_category_irrelevant")}
          </Button>
          <Tooltip title={t("tooltip_add_policy")}>
            <span style={{ width: "min-content" }}>
              <IconButton size="small" onClick={() => openCreateDialog()}>
                <AddIcon size={IconSize.Medium} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
    </>
  );
};
