import { useQuery } from "@tanstack/react-query";
import { PhaseTwoApiService } from "../phase-two-api/phase-two-api.service";
import { useKeycloakOrganizationId } from "./hooks/use-keycloak-organization-id.hook";

const getSearchUsersQueryKey = (keycloakOrganizationId: string, search?: string) => [
  "users",
  keycloakOrganizationId,
  search,
];
export const useSearchUsersQuery = (
  keycloakOrganizationId: string | null | undefined,
  search?: string,
) => {
  return useQuery({
    queryKey: getSearchUsersQueryKey(keycloakOrganizationId!, search),
    queryFn: async () => {
      // TODO: Add pagination
      return PhaseTwoApiService.OrganizationMembershipsApi.getOrganizationMemberships(
        PhaseTwoApiService.RealmId,
        keycloakOrganizationId!,
        search,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled: !!keycloakOrganizationId,
  });
};

export const useSearchUsersQueryInternal = (organizationId: string, search?: string) => {
  const keycloakOrganizationId = useKeycloakOrganizationId(organizationId);
  return useSearchUsersQuery(keycloakOrganizationId, search);
};
