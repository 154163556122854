import { FC, useCallback, useMemo } from "react";
import { ILocalOrganization } from "../organizations/local-organization.interface";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ILocalReferenceBudget } from "./interfaces/reference-budgets.interfaces";
import { ReferenceBudgetsEditListComponent } from "./reference-budgets-edit-list.component";
import { useDialogState, useDialogStateWithoutData } from "../common/hooks/dialog-state.hook";
import { ReferenceBudgetEditDialog } from "./reference-budget-edit-dialog.component";
import {
  useCreateReferenceBudgetMutation,
  useDeleteReferenceBudgetMutation,
  useUpdateReferenceBudgetMutation,
} from "./mutations/reference-budgets.mutations";
import { IBaseReferenceBudgetData, IRecordingPeriodData } from "@netcero/netcero-core-api-client";
import { ConfirmDialogComponent } from "../common/components/confirm-dialog.component";
import { useMergeRecordingPeriods } from "./hooks/merge-recording-periods.hook";
import { objectToRecord } from "@netcero/netcero-common";

export interface IReferenceBudgetsComponentProps {
  organization: ILocalOrganization;
  recordingPeriods: ILocalRecordingPeriod[];
  referenceBudgets: ILocalReferenceBudget[];
}

interface IEditDialogState {
  referenceBudget: ILocalReferenceBudget;
}

type IDeleteDialogState = IEditDialogState;
type IRefreshDialogState = IEditDialogState;

export const ReferenceBudgetsComponent: FC<IReferenceBudgetsComponentProps> = ({
  referenceBudgets,
  recordingPeriods,
  organization,
}) => {
  const { t } = useTranslation("reference_budgets_component");

  const availableRecordingPeriods = useMemo(() => {
    // TODO: implement filter logic once recording periods can be marked as complete
    return recordingPeriods;
  }, [recordingPeriods]);

  // Mutations
  const createReferenceBudgetMutation = useCreateReferenceBudgetMutation();
  const updateReferenceBudgetMutation = useUpdateReferenceBudgetMutation();
  const deleteReferenceBudgetMutation = useDeleteReferenceBudgetMutation();

  const resetMutations = useCallback(() => {
    createReferenceBudgetMutation.reset();
    updateReferenceBudgetMutation.reset();
    deleteReferenceBudgetMutation.reset();
  }, [createReferenceBudgetMutation, deleteReferenceBudgetMutation, updateReferenceBudgetMutation]);

  // Component state
  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();

  const {
    isOpen: isEditDialogOpen,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
    data: editDialogData,
  } = useDialogState<IEditDialogState>();

  const {
    isOpen: isRefreshDialogOpen,
    openDialog: openRefreshDialog,
    closeDialog: closeRefreshDialog,
    data: refreshDialogData,
  } = useDialogState<IRefreshDialogState>();

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    data: deleteDialogData,
  } = useDialogState<IDeleteDialogState>();

  const isLoading =
    createReferenceBudgetMutation.isPending ||
    updateReferenceBudgetMutation.isPending ||
    deleteReferenceBudgetMutation.isPending;

  // Event handlers
  const handleCreate = useCallback(
    async (data: IBaseReferenceBudgetData | null) => {
      if (data !== null) {
        await createReferenceBudgetMutation.mutateAsync({
          organizationId: organization.id,
          payload: data,
        });
      }
      closeCreateDialog();
    },
    [createReferenceBudgetMutation, closeCreateDialog, organization.id],
  );

  const handleUpdate = useCallback(
    async (
      payload: { data: IBaseReferenceBudgetData; haveRecordingPeriodsChanged: boolean } | null,
    ) => {
      if (payload !== null && editDialogData !== undefined) {
        await updateReferenceBudgetMutation.mutateAsync({
          organizationId: organization.id,
          referenceBudgetId: editDialogData.referenceBudget.id,
          payload: {
            ...payload.data,
            // make sure to only submit recording periods if they have actually changed.
            referencedRecordingStructureIds: payload.haveRecordingPeriodsChanged
              ? payload.data.referencedRecordingStructureIds
              : undefined,
          },
        });
      }
      closeEditDialog();
    },
    [closeEditDialog, editDialogData, organization.id, updateReferenceBudgetMutation],
  );

  const handleDelete = useCallback(
    async (confirm: boolean) => {
      if (confirm && deleteDialogData !== undefined) {
        await deleteReferenceBudgetMutation.mutateAsync({
          organizationId: organization.id,
          referenceBudgetId: deleteDialogData.referenceBudget.id,
        });
      }
      closeDeleteDialog();
    },
    [closeDeleteDialog, deleteDialogData, deleteReferenceBudgetMutation, organization.id],
  );

  const handleRefresh = useCallback(
    async (confirm: boolean) => {
      if (confirm && refreshDialogData !== undefined) {
        await updateReferenceBudgetMutation.mutateAsync({
          organizationId: organization.id,
          referenceBudgetId: refreshDialogData.referenceBudget.id,
          // Just call update endpoint with the same data to trigger a refresh
          payload: {
            name: refreshDialogData.referenceBudget.name,
            referencedRecordingStructureIds:
              refreshDialogData.referenceBudget.referencedRecordingStructureIds,
            referenceYear: refreshDialogData.referenceBudget.referenceYear,
            referenceRevenue: refreshDialogData.referenceBudget.referenceRevenue,
          },
        });
      }
      closeRefreshDialog();
    },
    [closeRefreshDialog, organization.id, refreshDialogData, updateReferenceBudgetMutation],
  );

  const mergeRecordingPeriods = useMergeRecordingPeriods(availableRecordingPeriods);

  return (
    <Box>
      {/* Create Dialog */}
      <ReferenceBudgetEditDialog
        mode="create"
        referenceBudget={null}
        open={isCreateOpen}
        loading={isLoading}
        onClose={handleCreate}
        availableRecordingPeriods={availableRecordingPeriods}
        error={createReferenceBudgetMutation.error}
        disabled={isLoading}
      />

      {/* Edit Dialog */}
      {editDialogData !== undefined && (
        <ReferenceBudgetEditDialog
          mode="edit"
          referenceBudget={editDialogData.referenceBudget}
          open={isEditDialogOpen}
          loading={isLoading}
          onClose={handleUpdate}
          availableRecordingPeriods={mergeRecordingPeriods(
            objectToRecord<IRecordingPeriodData>(
              editDialogData.referenceBudget.calculatedValues.recordingPeriods,
            ),
          )}
          error={updateReferenceBudgetMutation.error}
          disabled={isLoading}
        />
      )}

      {/* Delete Confirm Dialog */}
      <ConfirmDialogComponent
        open={isDeleteDialogOpen}
        error={deleteReferenceBudgetMutation.error}
        loading={isLoading}
        disabled={isLoading}
        onClose={handleDelete}
        text={{
          content: {
            title: t("confirm.title"),
            body: t("confirm.body"),
          },
          buttons: {
            confirm: t("confirm", { ns: "buttons" }),
            cancel: t("cancel", { ns: "buttons" }),
          },
        }}
      />

      {/* Refresh Confirm Dialog */}
      <ConfirmDialogComponent
        open={isRefreshDialogOpen}
        error={updateReferenceBudgetMutation.error}
        loading={isLoading}
        disabled={isLoading}
        onClose={handleRefresh}
        text={{
          content: {
            title: t("refresh.title"),
            body: t("refresh.body"),
          },
          buttons: {
            confirm: t("confirm", { ns: "buttons" }),
            cancel: t("cancel", { ns: "buttons" }),
          },
        }}
      />

      {/* The actual page content */}
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        {/* Create Button */}
        <Button
          variant="contained"
          onClick={() => {
            resetMutations();
            openCreateDialog();
          }}
          sx={{ mr: "auto" }}
        >
          {t("buttons.create")}
        </Button>

        {/* List of reference budgets*/}
        {referenceBudgets.length === 0 ? (
          <Typography>{t("empty_notice")}</Typography>
        ) : (
          <ReferenceBudgetsEditListComponent
            referenceBudgets={referenceBudgets}
            onEdit={(rb) => {
              resetMutations();
              openEditDialog({ referenceBudget: rb });
            }}
            onRefresh={(rb) => {
              resetMutations();
              openRefreshDialog({ referenceBudget: rb });
            }}
            onDelete={(rb) => {
              resetMutations();
              openDeleteDialog({ referenceBudget: rb });
            }}
            disabled={isLoading}
          />
        )}
      </Box>
    </Box>
  );
};
