import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_DE } from "./de.translations";
import { TRANSLATIONS_EN } from "./en.translations";

export const CURRENT_LOCALE_KEY = "i18nextLng";

const FALLBACK_LANGUAGE = "de";

export const AVAILABLE_LANGUAGES = {
  de: "Deutsch",
  en: "English",
};

// TODO: Once necessary, add a language detector and more languages here
// TODO: Once necessary, split translations into multiple namespaces

void i18n.use(initReactI18next).init({
  resources: {
    de: TRANSLATIONS_DE,
    en: TRANSLATIONS_EN,
  },
  defaultNS: "common",
  lng: localStorage.getItem(CURRENT_LOCALE_KEY) || navigator.language || FALLBACK_LANGUAGE,
  supportedLngs: Object.keys(AVAILABLE_LANGUAGES),
  fallbackLng: FALLBACK_LANGUAGE,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

i18n.on("languageChanged", (lng) => {
  localStorage.setItem(CURRENT_LOCALE_KEY, lng);
});

export default i18n;
