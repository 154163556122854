import {
  IDataEntryObjectInputParameterExclusionReason,
  IDataEntryObjectInputParameterStatus,
} from "@netcero/netcero-core-api-client";

export const DATA_ENTRY_OBJECT_INPUT_PARAMETER_STATUS_COLORS: Record<
  IDataEntryObjectInputParameterStatus,
  string
> = {
  [IDataEntryObjectInputParameterStatus.Open]: "rgba(0, 0, 0, 0.10)",
  [IDataEntryObjectInputParameterStatus.InProgress]: "rgba(255, 204, 0, 0.40)",
  [IDataEntryObjectInputParameterStatus.InReview]: "rgba(28, 159, 57, 0.15)",
  [IDataEntryObjectInputParameterStatus.Done]: "rgba(28, 159, 57, 0.40)",
};
export const DATA_ENTRY_OBJECT_INPUT_PARAMETER_EXCLUDED_COLOR = "rgba(0, 0, 0, 0.54)";

export const ORDERED_DEO_IP_EXCLUSION_REASONS: IDataEntryObjectInputParameterExclusionReason[] = [
  IDataEntryObjectInputParameterExclusionReason.NoDataAvailable,
  IDataEntryObjectInputParameterExclusionReason.NonMaterial,
  IDataEntryObjectInputParameterExclusionReason.ApplyPhaseIn,
];
