/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IBaseDistributionCriterionData } from '../models';
// @ts-ignore
import { IDeleteDistributionCriterion409Response } from '../models';
// @ts-ignore
import { IDistributionCriterion } from '../models';
// @ts-ignore
import { IGetAllDistributionCriteriaResponse } from '../models';
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from '../models';
// @ts-ignore
import { IListDistributionCriteria404Response } from '../models';
// @ts-ignore
import { IUpdateDistributionCriterion404Response } from '../models';
/**
 * DistributionCriteriaApi - axios parameter creator
 * @export
 */
export const DistributionCriteriaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new distribution criterion
         * @summary Create a new distribution criterion
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to create a distribution criterion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDistributionCriterion: async (organizationId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createDistributionCriterion', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/distribution-criteria`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseDistributionCriterionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a single distribution criteria.
         * @summary Deletes a single distribution criteria.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} distributionCriteriaId The id of the distribution criteria to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDistributionCriterion: async (organizationId: string, distributionCriteriaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteDistributionCriterion', 'organizationId', organizationId)
            // verify required parameter 'distributionCriteriaId' is not null or undefined
            assertParamExists('deleteDistributionCriterion', 'distributionCriteriaId', distributionCriteriaId)
            const localVarPath = `/organizations/{organizationId}/distribution-criteria/{distributionCriteriaId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"distributionCriteriaId"}}`, encodeURIComponent(String(distributionCriteriaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List distribution criteria for a given organization
         * @summary List distribution criteria for a given organization
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistributionCriteria: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listDistributionCriteria', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/distribution-criteria`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a single distribution criteria.
         * @summary Updates a single distribution criteria.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} distributionCriteriaId The id of the distribution criteria to use.
         * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to update a distribution criterion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDistributionCriterion: async (organizationId: string, distributionCriteriaId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateDistributionCriterion', 'organizationId', organizationId)
            // verify required parameter 'distributionCriteriaId' is not null or undefined
            assertParamExists('updateDistributionCriterion', 'distributionCriteriaId', distributionCriteriaId)
            const localVarPath = `/organizations/{organizationId}/distribution-criteria/{distributionCriteriaId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"distributionCriteriaId"}}`, encodeURIComponent(String(distributionCriteriaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseDistributionCriterionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistributionCriteriaApi - functional programming interface
 * @export
 */
export const DistributionCriteriaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistributionCriteriaApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new distribution criterion
         * @summary Create a new distribution criterion
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to create a distribution criterion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDistributionCriterion(organizationId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDistributionCriterion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDistributionCriterion(organizationId, iBaseDistributionCriterionData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a single distribution criteria.
         * @summary Deletes a single distribution criteria.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} distributionCriteriaId The id of the distribution criteria to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDistributionCriterion(organizationId: string, distributionCriteriaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDistributionCriterion(organizationId, distributionCriteriaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List distribution criteria for a given organization
         * @summary List distribution criteria for a given organization
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDistributionCriteria(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllDistributionCriteriaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDistributionCriteria(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a single distribution criteria.
         * @summary Updates a single distribution criteria.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} distributionCriteriaId The id of the distribution criteria to use.
         * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to update a distribution criterion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDistributionCriterion(organizationId: string, distributionCriteriaId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDistributionCriterion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDistributionCriterion(organizationId, distributionCriteriaId, iBaseDistributionCriterionData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DistributionCriteriaApi - factory interface
 * @export
 */
export const DistributionCriteriaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistributionCriteriaApiFp(configuration)
    return {
        /**
         * Create a new distribution criterion
         * @summary Create a new distribution criterion
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to create a distribution criterion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDistributionCriterion(organizationId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: any): AxiosPromise<IDistributionCriterion> {
            return localVarFp.createDistributionCriterion(organizationId, iBaseDistributionCriterionData, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a single distribution criteria.
         * @summary Deletes a single distribution criteria.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} distributionCriteriaId The id of the distribution criteria to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDistributionCriterion(organizationId: string, distributionCriteriaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDistributionCriterion(organizationId, distributionCriteriaId, options).then((request) => request(axios, basePath));
        },
        /**
         * List distribution criteria for a given organization
         * @summary List distribution criteria for a given organization
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistributionCriteria(organizationId: string, options?: any): AxiosPromise<IGetAllDistributionCriteriaResponse> {
            return localVarFp.listDistributionCriteria(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a single distribution criteria.
         * @summary Updates a single distribution criteria.
         * @param {string} organizationId The id of the organization the entity belongs to.
         * @param {string} distributionCriteriaId The id of the distribution criteria to use.
         * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to update a distribution criterion.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDistributionCriterion(organizationId: string, distributionCriteriaId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: any): AxiosPromise<IDistributionCriterion> {
            return localVarFp.updateDistributionCriterion(organizationId, distributionCriteriaId, iBaseDistributionCriterionData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistributionCriteriaApi - interface
 * @export
 * @interface DistributionCriteriaApi
 */
export interface DistributionCriteriaApiInterface {
    /**
     * Create a new distribution criterion
     * @summary Create a new distribution criterion
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to create a distribution criterion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApiInterface
     */
    createDistributionCriterion(organizationId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: AxiosRequestConfig): AxiosPromise<IDistributionCriterion>;

    /**
     * Deletes a single distribution criteria.
     * @summary Deletes a single distribution criteria.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApiInterface
     */
    deleteDistributionCriterion(organizationId: string, distributionCriteriaId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * List distribution criteria for a given organization
     * @summary List distribution criteria for a given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApiInterface
     */
    listDistributionCriteria(organizationId: string, options?: AxiosRequestConfig): AxiosPromise<IGetAllDistributionCriteriaResponse>;

    /**
     * Updates a single distribution criteria.
     * @summary Updates a single distribution criteria.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to update a distribution criterion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApiInterface
     */
    updateDistributionCriterion(organizationId: string, distributionCriteriaId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: AxiosRequestConfig): AxiosPromise<IDistributionCriterion>;

}

/**
 * DistributionCriteriaApi - object-oriented interface
 * @export
 * @class DistributionCriteriaApi
 * @extends {BaseAPI}
 */
export class DistributionCriteriaApi extends BaseAPI implements DistributionCriteriaApiInterface {
    /**
     * Create a new distribution criterion
     * @summary Create a new distribution criterion
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to create a distribution criterion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApi
     */
    public createDistributionCriterion(organizationId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: AxiosRequestConfig) {
        return DistributionCriteriaApiFp(this.configuration).createDistributionCriterion(organizationId, iBaseDistributionCriterionData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a single distribution criteria.
     * @summary Deletes a single distribution criteria.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApi
     */
    public deleteDistributionCriterion(organizationId: string, distributionCriteriaId: string, options?: AxiosRequestConfig) {
        return DistributionCriteriaApiFp(this.configuration).deleteDistributionCriterion(organizationId, distributionCriteriaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List distribution criteria for a given organization
     * @summary List distribution criteria for a given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApi
     */
    public listDistributionCriteria(organizationId: string, options?: AxiosRequestConfig) {
        return DistributionCriteriaApiFp(this.configuration).listDistributionCriteria(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a single distribution criteria.
     * @summary Updates a single distribution criteria.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {IBaseDistributionCriterionData} [iBaseDistributionCriterionData] The request to update a distribution criterion.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributionCriteriaApi
     */
    public updateDistributionCriterion(organizationId: string, distributionCriteriaId: string, iBaseDistributionCriterionData?: IBaseDistributionCriterionData, options?: AxiosRequestConfig) {
        return DistributionCriteriaApiFp(this.configuration).updateDistributionCriterion(organizationId, distributionCriteriaId, iBaseDistributionCriterionData, options).then((request) => request(this.axios, this.basePath));
    }
}
