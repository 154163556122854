import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, ReactNode } from "react";
import { IDataEntryObjectRecordingInputParameter } from "../../../data-entry-objects-input-parameters/data-entry-object-input-parameters.utilities";
import { DataEntryObjectSelectGroupComponent } from "./data-entry-object-select-group.component";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { OrganizationStructureNavigationUtilities } from "../../../organization-structures/navigation/organization-structur-navigation.utilities";

interface IDataEntryOrganizationDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (confirm: boolean) => void;
  text: {
    content: {
      title: ReactNode;
    };
    buttons: {
      confirm: ReactNode;
      cancel: ReactNode;
    };
  };
  dataEntryObject: IDataEntryObject;
  checkedIds: string[];
  fieldChange: (event: string[]) => void;
  formChanged: boolean;
}

interface IDataEntryObjectGroupInfo {
  name: string;
}

export interface IDataEntryObjectGroup {
  groupInfo: IDataEntryObjectGroupInfo;
  children: IDataEntryObjectGroup[];
  inputParameterConfigurations?: IDataEntryObjectRecordingInputParameter[];
}

const filterIds = (
  id: string,
  checkedIds: string[],
  checkedParents: {
    id: string;
    anyChildChecked: boolean;
  }[],
) => {
  const newCheckedIds = checkedIds.includes(id)
    ? checkedIds.filter((i) => i !== id)
    : [...checkedIds, id];

  for (const checkedParent of checkedParents) {
    if (!checkedParent.anyChildChecked && !newCheckedIds.includes(checkedParent.id)) {
      newCheckedIds.push(checkedParent.id);
    }
  }

  return newCheckedIds;
};

function areAnyChildrenChecked(dataEntries: IDataEntryObject[], checkedIds: string[]) {
  function checkChildren(entry: IDataEntryObject) {
    if (entry.children && entry.children.length > 0) {
      for (const child of entry.children) {
        if (checkedIds.includes(child.id)) {
          return true;
        }
        if (checkChildren(child)) {
          return true;
        }
      }
    }
    return false;
  }

  return dataEntries.slice(0, -1).map((entry: IDataEntryObject) => ({
    id: entry.id,
    anyChildChecked: checkChildren(entry),
  }));
}

export const DataEntryObjectPickerDialog: FC<IDataEntryOrganizationDialogProps> = ({
  open,
  onClose,
  text,
  dataEntryObject,
  checkedIds,
  fieldChange,
  formChanged,
  disabled,
}) => {
  const handleCheckboxChange = (id: string) => {
    const parentTree = OrganizationStructureNavigationUtilities.getDEOsPathForTreeToChild(
      dataEntryObject,
      id,
    );
    const checkedParents = areAnyChildrenChecked(parentTree!, checkedIds);

    fieldChange(filterIds(id, checkedIds, checkedParents));
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle>{text.content.title}</DialogTitle>
      <DialogContent>
        <DataEntryObjectSelectGroupComponent
          dataEntryGroup={dataEntryObject!}
          checkedIds={checkedIds}
          fieldChange={fieldChange}
          handleCheckBoxChange={handleCheckboxChange}
          disabled={disabled}
          initialExpanded
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(false)}>
          {text.buttons.cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose(true);
          }}
          disabled={!formChanged}
        >
          {text.buttons.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
