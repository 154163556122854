import { IFinancialEffectHorizonCalculatorData } from "../types/financial-effect-horizon-calculator-data.interface";
import { IFinancialEffectCalculatorData } from "../types/financial-effect-calculator-data.interface";
import { IBaseCalculator } from "./base-calculator.interface";
import { FinancialEffectCalculator } from "./financial-effect.calculator";

export class FinancialEffectHorizonsCalculator implements IBaseCalculator {
  private readonly shortTerm: IFinancialEffectCalculatorData;
  private readonly mediumTerm: IFinancialEffectCalculatorData;
  private readonly longTerm: IFinancialEffectCalculatorData;

  constructor(horizons: IFinancialEffectHorizonCalculatorData) {
    Object.assign(this, horizons);
  }

  private getCalculators(): FinancialEffectCalculator[] {
    return [this.shortTerm, this.mediumTerm, this.longTerm].map(
      (horizon) => new FinancialEffectCalculator(horizon),
    );
  }

  public calculateSeverity(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateSeverity()));
  }

  public calculateMaterialityDegree(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateMaterialityDegree()));
  }

  public calculateMateriality(): boolean {
    return this.getCalculators()
      .map((calc) => calc.calculateMateriality())
      .some((materiality) => materiality);
  }
}
