/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IIntercomEntity } from '../models';
/**
 * IntercomReferencesApi - axios parameter creator
 * @export
 */
export const IntercomReferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all references for the target intercom entity
         * @param {IIntercomEntity} [intercomEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntercomReferences: async (intercomEntityName?: IIntercomEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/intercom-references`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication openId required

            if (intercomEntityName !== undefined) {
                localVarQueryParameter['intercomEntityName'] = intercomEntityName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntercomReferencesApi - functional programming interface
 * @export
 */
export const IntercomReferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntercomReferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all references for the target intercom entity
         * @param {IIntercomEntity} [intercomEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntercomReferences(intercomEntityName?: IIntercomEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number | undefined; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntercomReferences(intercomEntityName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntercomReferencesApi - factory interface
 * @export
 */
export const IntercomReferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntercomReferencesApiFp(configuration)
    return {
        /**
         * Retrieves all references for the target intercom entity
         * @param {IIntercomEntity} [intercomEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntercomReferences(intercomEntityName?: IIntercomEntity, options?: any): AxiosPromise<{ [key: string]: number | undefined; }> {
            return localVarFp.getIntercomReferences(intercomEntityName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntercomReferencesApi - interface
 * @export
 * @interface IntercomReferencesApi
 */
export interface IntercomReferencesApiInterface {
    /**
     * Retrieves all references for the target intercom entity
     * @param {IIntercomEntity} [intercomEntityName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntercomReferencesApiInterface
     */
    getIntercomReferences(intercomEntityName?: IIntercomEntity, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: number | undefined; }>;

}

/**
 * IntercomReferencesApi - object-oriented interface
 * @export
 * @class IntercomReferencesApi
 * @extends {BaseAPI}
 */
export class IntercomReferencesApi extends BaseAPI implements IntercomReferencesApiInterface {
    /**
     * Retrieves all references for the target intercom entity
     * @param {IIntercomEntity} [intercomEntityName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntercomReferencesApi
     */
    public getIntercomReferences(intercomEntityName?: IIntercomEntity, options?: AxiosRequestConfig) {
        return IntercomReferencesApiFp(this.configuration).getIntercomReferences(intercomEntityName, options).then((request) => request(this.axios, this.basePath));
    }
}
