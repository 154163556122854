import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IESRSTopic } from "@netcero/netcero-core-api-client";

type IESRSTopicForTranslate = Pick<IESRSTopic, "identifier">;

export type IESRSTopicNameRenderer = (
  esrsTopic: IESRSTopicForTranslate | null | undefined,
) => string;

export const useRenderESRSTopicName: () => IESRSTopicNameRenderer = () => {
  const { t } = useTranslation("esrs_topics");
  return useCallback(
    (esrsTopic: IESRSTopicForTranslate | null | undefined) => {
      if (esrsTopic === null || esrsTopic === undefined) {
        return "";
      }
      return t(esrsTopic.identifier);
    },
    [t],
  );
};
